import { useLoadOnceOnClientSide } from '@jelmoli/hooks/src/useLoadOnceOnClientSide/useLoadOnceOnClientSide';
import React, { useEffect, useMemo, useState } from 'react';
import './qr-code.scss';
export var QrCode = React.memo(function (_a) {
    var value = _a.value, color = _a.color;
    var opacity = 0.4;
    var dataUrl = useQrCode(value, 255, '#ffffff', "#000000" + Math.round(opacity * 255).toString(16));
    return (React.createElement("div", { className: "a-qr-code a-qr-code--" + (color || 'sea-green') },
        React.createElement("img", { alt: "QR-Code", className: "a-qr-code__image", src: dataUrl, loading: "lazy" })));
});
var useQrCode = function (value, size, color, background) {
    var canvas = useMemo(function () { return (typeof document !== 'undefined' ? document.createElement('canvas') : undefined); }, []);
    var QRCode = useLoadOnceOnClientSide(function () { return import(/* webpackChunkName: "qrcode" */ 'qrcode'); });
    // During load return a 1x1 white pixel:
    var _a = useState('data:image/gif;base64,R0lGODdhAQABAIABAP///wAAACwAAAAAAQABAAACAkQBADs='), dataUrl = _a[0], setDataUrl = _a[1];
    useEffect(function () {
        if (!canvas || !QRCode) {
            return;
        }
        QRCode.toCanvas(canvas, value, {
            margin: 0,
            width: size,
            color: {
                light: color,
                dark: background,
                errorCorrectionLevel: 'H',
            },
        }, function (error) {
            if (!error) {
                setDataUrl(canvas.toDataURL());
            }
        });
    }, [QRCode, canvas, value]);
    return dataUrl;
};
