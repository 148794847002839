var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Column, Row } from '@jelmoli/bootstrap-grid';
import { useLoadNamedOnceInViewport } from '@jelmoli/hooks/src/useLoadOnceInViewport/useLoadOnceInViewport';
import { HotSpotButton } from '@jelmoli/ui-components/src/atoms/HotSpotButton/HotSpotButton';
import { Image } from '@jelmoli/ui-components/src/atoms/Image/Image';
import { Container } from '@jelmoli/ui-components/src/helpers/Container/Container';
import { useIsSitecoreEditMode } from '@jelmoli/ui-components/src/helpers/EditMode/editMode';
import { FieldEditFrame } from '@jelmoli/ui-components/src/helpers/FieldEditFrame/FieldEditFrame';
import { onlyTagsWithInnerHTML, RichTextRenderer, } from '@jelmoli/ui-components/src/helpers/RichTextRenderer/RichTextRenderer';
import { ScreenSizeDetector } from '@jelmoli/ui-components/src/helpers/ScreenSizeDetector/ScreenSizeDetector';
import { hasLinkFieldValue, hasTextFieldValue } from '@jelmoli/ui-components/src/helpers/SitecoreTypes/SitecoreTypes';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { Visibility } from '@jelmoli/ui-components/src/helpers/Visibility/Visibility';
import Fade from '@material-ui/core/Fade';
import classnames from 'classnames';
import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import './hot-spot-image.scss';
export var HotSpotImageComponent = function (_a) {
    var fields = _a.fields;
    var _b = useState(0), isActive = _b[0], setIsActive = _b[1];
    var sitecoreContext = {
        pageEditing: true,
        route: {
            databaseName: 'master',
        },
    };
    return (React.createElement(Container, { size: "s", backgroundFill: true },
        React.createElement(Row, null,
            React.createElement(Column, { size: 12 },
                React.createElement(Visibility, { show: 'xs' },
                    React.createElement("div", { className: "feat-hotspot" },
                        React.createElement(EditorCoordinateHelper, null,
                            React.createElement("div", { onClick: function () { return setIsActive(0); } },
                                React.createElement(Image, { value: fields.HotSpotSmallImage.value })),
                            fields.HotSpotImageSmallProducts.map(function (product, index) { return (React.createElement(HotSpot, __assign({ isActive: isActive, setIsActive: setIsActive, key: index, index: index }, product))); })))),
                React.createElement(Visibility, { hide: 'xs' },
                    React.createElement("div", { className: "feat-hotspot" },
                        React.createElement(EditorCoordinateHelper, null,
                            React.createElement("div", { onClick: function () { return setIsActive(0); } },
                                React.createElement(Image, { value: fields.HotSpotLargeImage.value })),
                            fields.HotSpotImageLargeProducts.map(function (product, index) { return (React.createElement(HotSpot, __assign({ isActive: isActive, setIsActive: setIsActive, key: index, index: index }, product))); })))))),
        React.createElement(VerticalSpacer, { size: "xxl" })));
};
var HotSpot = function (props) {
    var _a = React.useState(), anchorEl = _a[0], setAnchorEl = _a[1];
    var _b = useState(-1), isCurrentActive = _b[0], setIsCurrentActive = _b[1];
    var _c = useState(false), isXSViewport = _c[0], setIsXSViewport = _c[1];
    var _d = useLoadNamedOnceInViewport('default', function () { return import(/* webpackChunkName: "Popper" */ '@material-ui/core/Popper'); }, function (popperProps) { return null; }), wrapperRef = _d[0], Popper = _d[1];
    var position = definePosition(props.fields.HotSpotCoordinateX.value, props.fields.HotSpotCoordinateY.value);
    // New rules needed to remove spacers added by default bellow each element and adjust the typings to the design
    var hotSpotRules = {
        B: onlyTagsWithInnerHTML(function (content) { return (React.createElement(Typography, { wrap: "regular", size: "xs", type: "medium", tag: "span", responsive: false }, content)); }),
        P: onlyTagsWithInnerHTML(function (content) { return (React.createElement("p", { className: "feat-hotspot__teaser" },
            React.createElement(Typography, { wrap: "regular", size: "xs", responsive: false }, content))); }),
        STRONG: onlyTagsWithInnerHTML(function (content) { return (React.createElement(Typography, { wrap: "regular", size: "xs", type: "medium", tag: "span", responsive: false }, content)); }),
    };
    var offset = isXSViewport
        ? Number(props.fields.HotSpotCoordinateX.value) >= 50
            ? '-16, 4'
            : '16, 4'
        : Number(props.fields.HotSpotCoordinateX.value) >= 50
            ? '-36, 4'
            : '36, 4';
    return (React.createElement("div", { ref: wrapperRef, className: "feat-hotspot__button", style: {
            top: props.fields.HotSpotCoordinateY.value + "%",
            left: props.fields.HotSpotCoordinateX.value + "%",
        } },
        React.createElement(FieldEditFrame, { itemId: props.id, fields: ['HotSpotCoordinateX', 'HotSpotCoordinateY', 'HotSpotTeaserbox', 'HotSpotTeaserLink'], hideIfNotPageEditing: false, databaseName: 'master', language: 'en' },
            React.createElement(ScreenSizeDetector, { viewports: ['xs'], onViewportChange: function (value) {
                    setIsXSViewport(value);
                } }),
            React.createElement(HotSpotButton, { isActive: props.isActive === isCurrentActive, handleClick: function (element) {
                    setAnchorEl(element.currentTarget);
                    props.setIsActive(props.isActive === isCurrentActive ? 0 : props.index + 1);
                    setIsCurrentActive(props.isActive === isCurrentActive ? -1 : props.index + 1);
                } }),
            React.createElement(CSSTransition, { in: props.isActive === isCurrentActive, classNames: classnames("feat-hotspot__popper--" + position), timeout: 350 },
                React.createElement(Popper, { open: props.isActive === isCurrentActive, anchorEl: anchorEl, placement: position, transition: true, disablePortal: true, modifiers: {
                        flip: {
                            enabled: true,
                        },
                        preventOverflow: {
                            enabled: true,
                            boundariesElement: 'scrollParent',
                        },
                        offset: {
                            offset: offset,
                        },
                    } }, function (_a) {
                    var TransitionProps = _a.TransitionProps;
                    return (React.createElement(Fade, __assign({}, TransitionProps, { timeout: 350 }),
                        React.createElement("div", { className: classnames('feat-hotspot__popper', "feat-hotspot__popper--" + position) },
                            React.createElement("a", __assign({ className: "feat-hotspot__link" }, (hasLinkFieldValue(props.fields.HotSpotTeaserLink) && {
                                href: props.fields.HotSpotTeaserLink.value.href,
                            })), hasTextFieldValue(props.fields.HotSpotTeaserbox) && (React.createElement(RichTextRenderer, { html: props.fields.HotSpotTeaserbox.value, replaceRules: hotSpotRules }))))));
                })))));
};
function definePosition(coordinateX, coordinateY) {
    switch (Number(coordinateY) <= 50) {
        case true: {
            return Number(coordinateX) >= 50 ? 'bottom-end' : 'bottom-start';
        }
        case false: {
            return Number(coordinateX) > 50 ? 'top-end' : 'top-start';
        }
        default:
            return 'bottom';
    }
}
var EditorCoordinateHelper = function (props) {
    var editMode = useIsSitecoreEditMode();
    var _a = React.useState(''), position = _a[0], setPosition = _a[1];
    var _b = React.useState(false), overImage = _b[0], setOverImage = _b[1];
    if (!editMode) {
        return React.createElement(React.Fragment, null, props.children);
    }
    return (React.createElement("div", { onMouseEnter: function () { return setOverImage(true); }, onMouseLeave: function () { return setOverImage(false); }, onMouseMove: function (e) {
            var target = e.target;
            var _a = target.getClientRects()[0], x = _a.x, y = _a.y, width = _a.width, height = _a.height;
            setPosition(Math.round(((e.clientX - x) / width) * 100) + " - " + Math.round(((e.clientY - y) / height) * 100));
        } },
        props.children,
        overImage && (React.createElement("div", { style: { background: 'black', color: 'white', position: 'fixed', left: 0, bottom: 0 } }, position))));
};
