var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { POPTeaser } from '@jelmoli/ui-components/src/organism/POPTeaser/POPTeaser';
import React from 'react';
export var POPTeaserSmallComponent = function (_a) {
    var fields = _a.fields;
    return (React.createElement(POPTeaser, { fields: __assign(__assign({}, fields), { PopTeaserImageLarge: fields.PopTeaserImageSmall }) }));
};
