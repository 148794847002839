var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { trackNavigation } from '@jelmoli/sitecore-services/src/Tracking/Navigation/NavigationTracking';
import { ChevronRightIcon } from '@jelmoli/ui-components/src/atoms/Icon/ChevronRightIcon';
import { A } from '@jelmoli/ui-components/src/helpers/A/A';
import { Container } from '@jelmoli/ui-components/src/helpers/Container/Container';
import { HorizontalScrollWrapper } from '@jelmoli/ui-components/src/helpers/HorizontalScrollWrapper/HorizontalScrollWrapper';
import { HorizontalSpacer } from '@jelmoli/ui-components/src/helpers/HorizontalSpacer/HorizontalSpacer';
import { ScreenSizeDetector } from '@jelmoli/ui-components/src/helpers/ScreenSizeDetector/ScreenSizeDetector';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import classnames from 'classnames';
import React, { useState } from 'react';
import './breadcrumb-component.scss';
export var BreadcrumbComponent = function (props) {
    var _a = useState(false), isSmallScreen = _a[0], setIsSmallScreen = _a[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(ScreenSizeDetector, { viewports: ['xs'], onViewportChange: function (isSmallerScreen) {
                setIsSmallScreen(isSmallerScreen);
            } }),
        React.createElement(Container, { size: "m", backgroundFill: true },
            React.createElement(VerticalSpacer, { size: "m", hide: ['xs', 'sm'] }),
            React.createElement("ul", { className: "feat-breadcrumb-component" }, isSmallScreen ? (React.createElement(HorizontalScrollWrapper, { fullWidth: true, leftArrow: React.createElement("div", { className: "feat-breadcrumb-component__scroller-overlay feat-breadcrumb-component__scroller-overlay--left" }), rightArrow: React.createElement("div", { className: "feat-breadcrumb-component__scroller-overlay" }) }, props.fields.navigationLevels.length > 3 ? (React.createElement(ExpandableList, { expandableListItems: props.fields.navigationLevels })) : (props.fields.navigationLevels.map(function (_a, index, navigationLevels) {
                var title = _a.title, link = _a.link, disablePrefetching = _a.disablePrefetching;
                return (React.createElement(BreadcrumbLink, { key: index, disablePrefetching: disablePrefetching, title: title, link: link, isFirst: index === 0, isLast: index === navigationLevels.length - 1 }));
            })))) : (props.fields.navigationLevels.map(function (_a, index, navigationLevels) {
                var title = _a.title, link = _a.link, disablePrefetching = _a.disablePrefetching;
                return (React.createElement(BreadcrumbLink, { key: index, title: title, disablePrefetching: disablePrefetching, link: link, isFirst: index === 0, isLast: index === navigationLevels.length - 1 }));
            }))),
            React.createElement(VerticalSpacer, { size: "l" }))));
};
export var ExpandableList = function (_a) {
    var expandableListItems = _a.expandableListItems;
    var _b = useState(false), isListExpanded = _b[0], setIsListExpanded = _b[1];
    return (React.createElement("ul", { className: "feat-breadcrumb-component__wrapper" },
        React.createElement(BreadcrumbLink, { key: 0, title: expandableListItems[0].title, link: expandableListItems[0].link, disablePrefetching: expandableListItems[0].disablePrefetching, isFirst: true, isLast: false }),
        React.createElement("li", { className: classnames('feat-breadcrumb-component__inner-list', {
                'feat-breadcrumb-component__inner-list-expanded': isListExpanded,
            }) },
            React.createElement("ul", { className: "feat-breadcrumb-component__inner-list-wrapper" }, expandableListItems.map(function (_a, index, navigationLevels) {
                var title = _a.title, link = _a.link, disablePrefetching = _a.disablePrefetching;
                if (index === 0 || index === navigationLevels.length - 1) {
                    return;
                }
                return (React.createElement(BreadcrumbLink, { key: index, title: title, disablePrefetching: disablePrefetching, link: link, isFirst: false, isLast: false }));
            }))),
        !isListExpanded && (React.createElement(BreadcrumbLink, { key: expandableListItems.length - 1, title: '...', link: 'dummy', disablePrefetching: false, isFirst: false, isLast: false, setIsExtended: setIsListExpanded })),
        React.createElement(BreadcrumbLink, { key: expandableListItems.length, disablePrefetching: expandableListItems[expandableListItems.length - 1].disablePrefetching, title: expandableListItems[expandableListItems.length - 1].title, link: expandableListItems[expandableListItems.length - 1].link, isFirst: false, isLast: true })));
};
export var BreadcrumbLink = function (_a) {
    var title = _a.title, link = _a.link, disablePrefetching = _a.disablePrefetching, isFirst = _a.isFirst, isLast = _a.isLast, setIsExtended = _a.setIsExtended;
    return (React.createElement("li", { className: classnames('feat-breadcrumb-component__breadcrumb', {
            'feat-breadcrumb-component__breadcrumb--last': isLast,
        }) },
        !isFirst && React.createElement(HorizontalSpacer, { size: "xxs" }),
        !isLast ? (React.createElement(React.Fragment, null,
            React.createElement("span", { onClick: function () {
                    trackNavigation({
                        currentLevel: 'Breadcrumb',
                        trackLabel: title,
                    });
                } },
                React.createElement(A, __assign({ className: "feat-breadcrumb-component__breadcrumb-link" }, (!disablePrefetching && { href: link }), (setIsExtended && {
                    onClick: function (event) {
                        event.preventDefault();
                        setIsExtended(true);
                    },
                }), (disablePrefetching && {
                    onClick: function (event) {
                        event.preventDefault();
                        window.location.href = link;
                    },
                })),
                    React.createElement(Typography, { wrap: "nowrap", size: "xxs" }, title))),
            React.createElement(HorizontalSpacer, { size: "xxs" }),
            React.createElement(ChevronRightIcon, { className: "feat-breadcrumb-component__breadcrumb-separator" }))) : (React.createElement(Typography, { wrap: "nowrap", size: "xxs" }, title))));
};
