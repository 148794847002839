var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useFormValidationShowAllErrors, useIsFormValid } from 'nested-form-validation';
import React from 'react';
import { scrollToFirstElement } from '../../helpers/ScrollToFirstElement/ScrollToFirstElement';
import { PrimaryButton } from '../PrimaryButton/PrimaryButton';
export var PrimarySubmitButton = function (props) {
    var isValid = useIsFormValid();
    var showAllErrors = useFormValidationShowAllErrors();
    var onClick = props.onClick;
    var type = props.type || 'submit';
    return (React.createElement(PrimaryButton, __assign({}, props, { type: type, onClick: function (event) {
            showAllErrors();
            event.preventDefault();
            var form = event.currentTarget.closest('form');
            // Timeout is needed so that scrollIntoView gets triggered after error message gets inserted into DOM
            setTimeout(function () {
                scrollToFirstElement('.a-form-field-error-message--inline', form, props.scrollPosition);
            }, 50);
            if (isValid && onClick) {
                return onClick(event);
            }
        } })));
};
