import { Separator } from '@jelmoli/ui-components/src/atoms/Separator/Separator';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import React from 'react';
import { MyAccountBrandsComponent } from './MyAccountBrands/MyAccountBrandsComponent';
import { MyAccountCategoriesComponent } from './MyAccountCategories/MyAccountCategoriesComponent';
export var MyAccountPreferencesComponent = function (_a) {
    var fields = _a.fields;
    return (React.createElement(React.Fragment, null,
        React.createElement(MyAccountCategoriesComponent, { labels: fields }),
        React.createElement(VerticalSpacer, { size: "xl" }),
        React.createElement(Separator, null),
        React.createElement(VerticalSpacer, { size: "xl" }),
        React.createElement(MyAccountBrandsComponent, { labels: fields }),
        React.createElement(VerticalSpacer, { size: "xxl" })));
};
