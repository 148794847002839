var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useLoadNamedOnceInViewport } from '@jelmoli/hooks/src/useLoadOnceInViewport/useLoadOnceInViewport';
import { trackNavigation } from '@jelmoli/sitecore-services/src/Tracking/Navigation/NavigationTracking';
import { preventOutline } from '@jelmoli/ui-components/src/helpers/A/A';
import { HorizontalSpacer } from '@jelmoli/ui-components/src/helpers/HorizontalSpacer/HorizontalSpacer';
import { extractFieldValues, hasLinkFieldValue, } from '@jelmoli/ui-components/src/helpers/SitecoreTypes/SitecoreTypes';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { CookieDisclaimerBanner } from '@jelmoli/ui-components/src/organism/CookieDisclaimerBanner/CookieDisclaimerBanner';
import { Link as SitecoreLink } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';
import './legal-links.scss';
export var FooterLegalLinks = function (_a) {
    var fields = _a.fields;
    var linkArray = extractFieldValues(fields, ['LegalLink1', 'LegalLink2', 'LegalLink3']).filter(function (link) {
        return hasLinkFieldValue(link);
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "feat-legal-link" },
            React.createElement(CopyrightComponent, null),
            linkArray.map(function (link, index, arr) { return (React.createElement("div", { key: "link" + index, className: "feat-legal-link__item" },
                index === arr.length - 1 ? (React.createElement(HorizontalSpacer, { hide: "xs", size: { xs: 'm', sm: 'l', md: 'l', lg: 'xs', xl: 'xs' } })) : (React.createElement(HorizontalSpacer, { size: { xs: 'm', sm: 'l', md: 'l', lg: 'xs', xl: 'xs' } })),
                React.createElement(Typography, { size: "xs", responsive: false },
                    React.createElement(SitecoreLink, __assign({ className: "feat-legal-link__link", field: link }, preventOutline(), { onClick: function () {
                            return trackNavigation({
                                currentLevel: 'Footer',
                                trackLabel: link.value.text || '',
                            });
                        } })),
                    index !== arr.length - 1 && React.createElement(VerticalSpacer, { size: "l", show: ['xs'] })))); })),
        React.createElement(VerticalSpacer, { size: "xxl" }),
        React.createElement(CookieDisclaimerBanner, { fields: fields })));
};
var year = new Date().getFullYear();
var CopyrightComponent = function () {
    var _a = useLoadNamedOnceInViewport('DevDetails', function () { return import(/* webpackChunkName: "FooterLegalLinkExtras" */ './footerDebugUtils'); }, function (props) { return props.children; }), ref = _a[0], CopyrightWrapper = _a[1];
    return (React.createElement(Typography, { type: "regular", size: "s", responsive: false },
        React.createElement("span", { ref: ref },
            React.createElement(CopyrightWrapper, null,
                "\u00A9 Jelmoli ",
                year))));
};
