import { useBasicScroll } from '@jelmoli/hooks/src/useBasicScroll/useBasicScroll';
import React, { useRef } from 'react';
import { useIsSitecoreEditMode } from '../EditMode/editMode';
import './scroll-fader.scss';
export var ScrollFader = function (_a) {
    var children = _a.children, enableInEditMode = _a.enableInEditMode;
    var inEditMode = useIsSitecoreEditMode();
    var isEnabled = enableInEditMode || !inEditMode;
    return !isEnabled ? React.createElement(React.Fragment, null, children) : React.createElement(ScrollFaderBasicScroll, null, children);
};
var ScrollFaderBasicScroll = function (props) {
    var beaconRef = useRef(null);
    var targetRef = useRef(null);
    useBasicScroll(beaconRef, targetRef, {
        // The first part of the value describes the element position,
        // the last part describes the viewport position: <element>-<viewport>.
        // middle-bottom in from specifies that the animation starts when the middle of the element reaches
        // the bottom of the viewport.
        // Known relative values:
        // top-top, top-middle, top-bottom, middle-top, middle-middle,
        // middle-bottom, bottom-top, bottom-middle, bottom-bottom
        from: 'top-bottom',
        to: 'bottom-bottom',
        props: {
            '--opacity': {
                from: 0.5,
                to: 1,
            },
        },
    });
    return (React.createElement("div", { className: "h-scroll-fader", ref: targetRef },
        React.createElement("div", { className: "h-scroll-fader__beacon" },
            React.createElement("div", { className: "h-scroll-fader__beacon-inner", ref: beaconRef })),
        props.children));
};
