import { Column, Row } from '@jelmoli/bootstrap-grid';
import { A } from '@jelmoli/ui-components/src/helpers/A/A';
import { Container } from '@jelmoli/ui-components/src/helpers/Container/Container';
import { HorizontalScrollWrapper } from '@jelmoli/ui-components/src/helpers/HorizontalScrollWrapper/HorizontalScrollWrapper';
import { HorizontalSpacer } from '@jelmoli/ui-components/src/helpers/HorizontalSpacer/HorizontalSpacer';
import { hasTextFieldValue } from '@jelmoli/ui-components/src/helpers/SitecoreTypes/SitecoreTypes';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import classnames from 'classnames';
import React, { useState } from 'react';
import './button-navigation.scss';
export var ButtonNavigationComponent = function (_a) {
    var fields = _a.fields;
    var _b = useState(0), selectedCategoryIndex = _b[0], setSelectedCategoryIndex = _b[1];
    return (React.createElement(Container, { size: "s", backgroundFill: true },
        React.createElement(Row, null,
            React.createElement(Column, { size: 12 },
                React.createElement(VerticalSpacer, { size: "l" }),
                React.createElement(HorizontalScrollWrapper, { leftArrow: React.createElement(HorizontalSpacerButton, null), rightArrow: React.createElement(HorizontalSpacerButton, { orientation: "right" }) },
                    React.createElement("div", { className: "feat-button-navigation" },
                        hasTextFieldValue(fields.ButtonNavigationTitle) && (React.createElement(Typography, { size: "xs", type: "light", uppercase: true, field: fields.ButtonNavigationTitle, wrap: "nowrap" })),
                        fields.ButtonNavigationCategories &&
                            fields.ButtonNavigationCategories.map(function (category, index) { return (React.createElement(React.Fragment, { key: index },
                                React.createElement(HorizontalSpacer, { size: "s" }),
                                React.createElement("div", { className: classnames('feat-button-navigation__category', selectedCategoryIndex === index &&
                                        'feat-button-navigation__category--active'), onClick: function () { return setSelectedCategoryIndex(index); } }, hasTextFieldValue(category.fields.ButtonNavigationItemTitle) && (React.createElement(Typography, { size: "s", type: "medium", uppercase: true, field: category.fields.ButtonNavigationItemTitle, wrap: "nowrap" }))))); }))))),
        React.createElement(VerticalSpacer, { size: "m" }),
        React.createElement(Row, null,
            React.createElement(Column, { size: 12 },
                React.createElement(HorizontalScrollWrapper, { leftArrow: React.createElement(HorizontalSpacerButton, null), rightArrow: React.createElement(HorizontalSpacerButton, { orientation: "right" }) },
                    React.createElement("div", { className: "feat-button-navigation__links-wrapper" }, fields.ButtonNavigationCategories &&
                        fields.ButtonNavigationCategories[selectedCategoryIndex] &&
                        fields.ButtonNavigationCategories[selectedCategoryIndex].fields.ButtonNavigationItemPages.map(function (page, pageIndex) { return (React.createElement(React.Fragment, { key: pageIndex },
                            pageIndex > 0 && React.createElement(HorizontalSpacer, { size: "xs" }),
                            hasTextFieldValue(page.fields.NavigationTitle) && (React.createElement(A, { href: page.url, "aria-label": page.fields.NavigationTitle.value, className: "feat-button-navigation__link" },
                                React.createElement(Typography, { size: "s", responsive: false, field: page.fields.NavigationTitle, wrap: "nowrap" }))))); }))))),
        React.createElement(VerticalSpacer, { size: "xxl" })));
};
var HorizontalSpacerButton = function (_a) {
    var orientation = _a.orientation;
    return (React.createElement("div", { className: classnames('feat-button-navigation__scroller-button', orientation === 'right' && 'feat-button-navigation__scroller-button--right') }));
};
