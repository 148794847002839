export function getCSRFToken() {
    if (typeof document === 'undefined') {
        return '';
    }
    var input = document.querySelector('body>input[name="__RequestVerificationToken"]');
    if (!input) {
        throw new Error('Token element missing');
    }
    return input.value;
}
