var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Column, Row } from '@jelmoli/bootstrap-grid';
import { useLoadNamedOnceOnClientSide } from '@jelmoli/hooks/src/useLoadOnceOnClientSide/useLoadOnceOnClientSide';
import { getApiKeys } from '@jelmoli/sitecore-services/src/Security/getApiKeys/getApiKeys';
import { H2 } from '@jelmoli/ui-components/src/atoms/Headlines/Headlines';
import { Spinner } from '@jelmoli/ui-components/src/atoms/Spinner/Spinner';
import { Container } from '@jelmoli/ui-components/src/helpers/Container/Container';
import { callApi, formatRecoDataToMetchProductTileData, scarabPushes, } from '@jelmoli/ui-components/src/helpers/RecoEngineConfig/RecoEngineConfig';
import { Slide, Slider } from '@jelmoli/ui-components/src/helpers/Slider/Slider';
import React, { useEffect, useState } from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
import './product-carousel.scss';
export var ProductCarouselRelatedComponent = function (props) {
    var ProductTile = useLoadNamedOnceOnClientSide('ProductTile', function () {
        return import(/* webpackChunkName: "ProductTile" */ '@jelmoli/ui-components/src/organism/ProductTile/ProductTile');
    });
    var apiKeys = useGetEndPointLazy(getApiKeys, {});
    var _a = useState([]), recoProducts = _a[0], setRecoProducts = _a[1];
    var _b = useState(false), isLoaded = _b[0], setIsLoaded = _b[1];
    useEffect(function () {
        if (apiKeys.hasData && !isLoaded) {
            setIsLoaded(true);
            setDataToWindowObj();
            callApi(apiKeys.value.RecoEngine);
            scarabPushes();
        }
    }, [apiKeys]);
    var setDataToWindowObj = function () {
        window.getProductsFromRecoEngine = function (products) {
            if (products.page && products.page.products) {
                var data = formatRecoDataToMetchProductTileData(products.page.products);
                setRecoProducts(data);
            }
        };
        window.recoEngineConfig = {
            limit: props.fields.MaxNumberOfProductShown.value || '',
            productCategory: props.fields.ProductCategory.value || '',
            recoOption: props.fields.RecoOption.value || '',
        };
    };
    return (React.createElement(Container, { size: "m", backgroundFill: true },
        React.createElement(Row, null,
            React.createElement(Column, { size: 12 },
                React.createElement("div", { className: "feat-product-carousel__title" },
                    React.createElement(H2, { type: "light", size: "m", field: props.fields.ProductCarouselTitle })),
                React.createElement("div", { id: "personal-recs" }),
                React.createElement(Slider, { minSlideAmount: { xl: 4, lg: 4, md: 3, sm: 2, xs: 2 }, productSlider: true }, function (getRef) { return (React.createElement(React.Fragment, null, recoProducts.map(function (item, index) {
                    return (React.createElement(Slide, { ref: getRef(), sliderColumnCount: { xs: 6, sm: 6, md: 4, lg: 3 }, key: index }, ProductTile ? (React.createElement(ProductTile, __assign({}, item, { currentPlacement: index, giftcardPrice: '', inTheCartText: props.fields.InTheCartText.value || '', productCarouselTile: true, hideColorPicker: true, hideAddToCart: true }))) : (React.createElement(Spinner, null))));
                }))); })))));
};
