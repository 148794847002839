import { bindTranslations } from '@jelmoli/i18n/index';
import { getCart } from '@jelmoli/sitecore-services/src/Cart/getCart/getCart';
import { DeliveryOptionConstants } from '@jelmoli/sitecore-services/src/Cart/getDeliveryOptions/getDeliveryOptions';
import { getCheckoutLabels } from '@jelmoli/sitecore-services/src/Checkout/getCheckoutLabels/getCheckoutLabels';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
export var CheckoutStepAddressSummary = observer(function () {
    var checkoutLabelsData = useGetEndPointLazy(getCheckoutLabels).value;
    var getCartData = useGetEndPointLazy(getCart).value;
    if (!checkoutLabelsData ||
        !getCartData ||
        !getCartData.orderInformation ||
        !('billingAddress' in getCartData.orderInformation)) {
        return null;
    }
    var t = bindTranslations(checkoutLabelsData);
    var addressSettings = getCartData.orderInformation;
    var deliveryOptionAddress = getCartData.deliveryOption &&
        getCartData.deliveryOption.deliveryOptionExternalId !== DeliveryOptionConstants.home;
    return (React.createElement(React.Fragment, null,
        addressSettings.sameAsBillingAddress && (React.createElement(React.Fragment, null,
            deliveryOptionAddress ? (React.createElement(Typography, { type: "medium", size: "s", tag: "p", responsive: false }, t('CheckoutAdressBillingAdressLabel'))) : (React.createElement(Typography, { type: "medium", size: "s", tag: "p", responsive: false },
                t('CheckoutAdressShippingAdressLabel'),
                ", ",
                t('CheckoutAdressBillingAdressLabel'))),
            React.createElement(Typography, { size: "s", responsive: false },
                addressSettings.billingAddress.firstName,
                " ",
                addressSettings.billingAddress.lastName),
            addressSettings.billingAddress.company && (React.createElement(Typography, { size: "s", responsive: false },
                ", ",
                addressSettings.billingAddress.company)),
            React.createElement(Typography, { size: "s", responsive: false },
                ", ",
                addressSettings.billingAddress.address1,
                ",",
                addressSettings.billingAddress.address2 ? (React.createElement(React.Fragment, null,
                    " ",
                    addressSettings.billingAddress.address2,
                    ", ")) : (' '),
                addressSettings.billingAddress.zipPostalCode,
                " ",
                addressSettings.billingAddress.city))),
        !addressSettings.sameAsBillingAddress && (React.createElement(React.Fragment, null,
            React.createElement(Typography, { type: "medium", size: "s", tag: "p", responsive: false }, t('CheckoutAdressBillingAdressLabel')),
            React.createElement(Typography, { size: "s", responsive: false },
                addressSettings.billingAddress.firstName,
                " ",
                addressSettings.billingAddress.lastName),
            addressSettings.billingAddress.company && (React.createElement(Typography, { size: "s", responsive: false },
                ", ",
                addressSettings.billingAddress.company)),
            React.createElement(Typography, { size: "s", responsive: false },
                ", ",
                addressSettings.billingAddress.address1,
                ",",
                addressSettings.billingAddress.address2 ? (React.createElement(React.Fragment, null,
                    " ",
                    addressSettings.billingAddress.address2,
                    ", ")) : (' '),
                addressSettings.billingAddress.zipPostalCode,
                " ",
                addressSettings.billingAddress.city),
            addressSettings.shippingAddress && !deliveryOptionAddress && (React.createElement(React.Fragment, null,
                React.createElement(VerticalSpacer, { size: "s", responsive: false }),
                React.createElement(Typography, { type: "medium", size: "s", tag: "p", responsive: false }, t('CheckoutAdressShippingAdressLabel')),
                React.createElement(Typography, { size: "s", responsive: false },
                    addressSettings.shippingAddress.firstName,
                    " ",
                    addressSettings.shippingAddress.lastName),
                addressSettings.shippingAddress.company && (React.createElement(Typography, { size: "s", responsive: false },
                    ", ",
                    addressSettings.shippingAddress.company)),
                React.createElement(Typography, { size: "s", responsive: false },
                    ", ",
                    addressSettings.shippingAddress.address1,
                    ",",
                    addressSettings.shippingAddress.address2 ? (React.createElement(React.Fragment, null,
                        " ",
                        addressSettings.shippingAddress.address2,
                        ", ")) : (' '),
                    addressSettings.shippingAddress.zipPostalCode,
                    " ",
                    addressSettings.shippingAddress.city))))),
        deliveryOptionAddress && (React.createElement(React.Fragment, null,
            React.createElement(VerticalSpacer, { size: "s", responsive: false }),
            React.createElement(Typography, { type: "medium", size: "s", tag: "p", responsive: false }, t('CheckoutAdressShippingAdressLabel')),
            React.createElement(DeliveryAddress, null))),
        React.createElement(VerticalSpacer, { size: "s", responsive: false }),
        React.createElement(Typography, { type: "medium", size: "s", tag: "p", responsive: false },
            t('CheckoutAdressTitleNotificationLabel'),
            ":",
            ' '),
        React.createElement(Typography, { size: "s", responsive: false }, addressSettings.email),
        addressSettings.mobile && (React.createElement(Typography, { size: "s", responsive: false },
            ", ",
            addressSettings.mobile)),
        addressSettings.createAccount && (React.createElement(React.Fragment, null,
            React.createElement(VerticalSpacer, { size: "s", responsive: false }),
            React.createElement(Typography, { type: "medium", size: "s", tag: "p", responsive: false }, t('CheckoutAdressAccountTitleLabel')),
            React.createElement(Typography, { size: "s", responsive: false }, t('CheckoutStepperAddressCreatedAccountLabel')))),
        React.createElement(VerticalSpacer, { size: "l", responsive: false })));
});
var DeliveryAddress = function () {
    var cartData = useGetEndPointLazy(getCart).value;
    if (!cartData || !cartData.deliveryOption || !cartData.orderInformation) {
        return null;
    }
    switch (cartData.deliveryOption.deliveryOptionExternalId) {
        case DeliveryOptionConstants.post:
            return (React.createElement(Typography, { size: "s", responsive: false },
                cartData.deliveryOption.deliveryAddress.company,
                " ",
                cartData.deliveryOption.userId,
                ",",
                ' ',
                cartData.deliveryOption.address.street,
                ", ",
                cartData.deliveryOption.address.zip,
                ' ',
                cartData.deliveryOption.address.city));
        case DeliveryOptionConstants.pickup:
            return (React.createElement(Typography, { size: "s", responsive: false },
                cartData.deliveryOption.deliveryAddress.company,
                ",",
                ' ',
                cartData.deliveryOption.deliveryAddress.address1));
        case DeliveryOptionConstants.digital:
            return (React.createElement(Typography, { size: "s", responsive: false }, cartData.orderInformation.email));
        default:
            return null;
    }
};
