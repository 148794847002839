import { useIsVisibleInViewportEffect } from '@jelmoli/hooks/src/useKeyboardEffect/useIsVisibleInViewport/useIsVisibleInViewport';
import React, { useEffect, useState } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { Visibility } from '../Visibility/Visibility';
import './screen-size-detector.scss';
/**
 * Component that tracks current viewport and execute passed actions
 */
export var ScreenSizeDetector = function (props) {
    var ref = useIsVisibleInViewportEffect(props.onViewportChange);
    return (React.createElement(Visibility, { show: props.viewports },
        React.createElement("div", { className: "h-screen-size-detector__fixed-indicator", ref: ref })));
};
var latestViewport;
/**
 * Returns the current viewport
 *
 * Warning: on the server this will always return undefined
 * If possible use media query based approaches instead
 *
 * Will be undefined if the current viewport is unknown
 */
export var useScreenSize = function () {
    var _a = useState(latestViewport), currentViewport = _a[0], setViewport = _a[1];
    useEffect(function () {
        // This hook has no effect on ssr - it will always return undefined
        if (typeof window === 'undefined') {
            return;
        }
        return addScreenSizeListener(setViewport);
    }, []);
    return currentViewport;
};
/**
 * Returns wether the given viewport is currently active
 *
 * Warning: on the server this will always return false
 * If possible use media query based approaches instead
 *
 * @example
 * ```
 * const onSmallScreens = useIsScreenSize(['xs', 'sm']);
 * ```
 */
export var useIsScreenSize = function (viewports) {
    var currentViewport = useScreenSize();
    return viewports.indexOf(currentViewport) !== -1;
};
var listeners = new Set();
function addScreenSizeListener(onViewportUpdate) {
    if (listeners.size === 0) {
        startScreenSizeListening();
    }
    listeners.add(onViewportUpdate);
    return function () {
        listeners.delete(onViewportUpdate);
        if (listeners.size === 0) {
            stopScreenSizeListening();
        }
    };
}
var screenSizeListenerWrapper;
function startScreenSizeListening() {
    var wrapper = document.createElement('div');
    screenSizeListenerWrapper = wrapper;
    document.body.appendChild(wrapper);
    wrapper.setAttribute('data-name', 'ScreenSizeListener');
    wrapper.setAttribute('class', 'h-screen-size-detector');
    var onViewportChange = function (viewport) {
        return function (isInview) {
            if (isInview) {
                listeners.forEach(function (listener) {
                    latestViewport = viewport;
                    listener(viewport);
                });
            }
        };
    };
    var viewportSizes = {
        xs: ['xs'],
        sm: ['sm'],
        md: ['md'],
        lg: ['lg'],
        xl: ['xl'],
    };
    var Detector = React.memo(function () { return (React.createElement(React.Fragment, null, Object.keys(viewportSizes).map(function (size) { return (React.createElement(ScreenSizeDetector, { key: size, viewports: viewportSizes[size], onViewportChange: onViewportChange(size) })); }))); });
    render(React.createElement(Detector, null), wrapper);
}
function stopScreenSizeListening() {
    if (screenSizeListenerWrapper) {
        unmountComponentAtNode(screenSizeListenerWrapper);
        document.body.removeChild(screenSizeListenerWrapper);
        latestViewport = undefined;
    }
    screenSizeListenerWrapper = undefined;
}
