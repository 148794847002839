import { Row } from '@jelmoli/bootstrap-grid';
import { Container } from '@jelmoli/ui-components/src/helpers/Container/Container';
import { hasTextFieldValue } from '@jelmoli/ui-components/src/helpers/SitecoreTypes/SitecoreTypes';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import React from 'react';
import { Column } from 'react-bootstrap-grid-component/dist/Column';
import './seaction-title.scss';
export var SectionTitleComponent = function (_a) {
    var fields = _a.fields;
    return (React.createElement(Container, { size: "s", backgroundFill: true },
        React.createElement(Row, null,
            React.createElement(Column, { size: 12, horizontalAlignment: "center", className: "feat-section-title" },
                React.createElement(Typography, { type: "light", size: "m", tag: "h2", field: fields.ContentSectionTitle }),
                hasTextFieldValue(fields.ContentSectionTitleDescription) && (React.createElement(React.Fragment, null,
                    React.createElement(VerticalSpacer, { size: "xs" }),
                    React.createElement(Typography, { size: "l", field: fields.ContentSectionTitleDescription }))),
                React.createElement(VerticalSpacer, { size: "xxl" })))));
};
