import { useRef } from 'react';
var empty = {};
/**
 * Similar to useRef but with an init function:
 *
 * `const div = useRefInit(() => document.createElement('div'));`
 *
 * The init function will be called only on the first render
 */
export function useRefInit(initFunction) {
    var ref = useRef(empty);
    if (ref.current === empty) {
        ref.current = initFunction();
    }
    return ref.current;
}
