import { A } from '@jelmoli/ui-components/src/helpers/A/A';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import React from 'react';
import './my-account-linkbox-mobile-component.scss';
export var MyAccountLinkboxMobileComponent = function (_a) {
    var fields = _a.fields;
    return (React.createElement(React.Fragment, null,
        React.createElement(Typography, { size: "m", type: "light" }, fields.myAccountLinkboxMobileTitle),
        React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
        React.createElement("div", null, fields.links.map(function (link, index) { return (React.createElement("div", { key: index + " " + link.url },
            React.createElement(A, { href: link.url, className: 'feat-my-account-linkbox-mobile-component__item', onClick: function (event) {
                    event.preventDefault();
                    window.location.href = "" + window.location.origin.replace(/(\?|#).+$/, '') + link.url;
                } },
                React.createElement(Typography, { size: "l", type: "medium", responsive: false }, link.text)),
            fields.links.length - 1 !== index && React.createElement(VerticalSpacer, { size: "s", responsive: false }))); }))));
};
