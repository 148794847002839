import { bindTranslations, I18N } from '@jelmoli/i18n';
import { Link } from '@sitecore-jss/sitecore-jss-react';
import classnames from 'classnames';
import React from 'react';
import { Typography } from '../../helpers/Typography/Typography';
import './header-infoline.scss';
export var HeaderInfolineElement = function (props) {
    var backgroundcolorul = props.fields.InfolineColor.value;
    var textcolorli = props.fields.InfolineTextColor.value;
    var t = bindTranslations(props.fields);
    return (React.createElement("div", { className: classnames('h-header-infoline', "h-header-infoline--" + backgroundcolorul, "h-header-infoline--li-" + textcolorli) },
        React.createElement(Typography, { type: "regular", size: "xs" },
            React.createElement(I18N, { text: t('InfolineText'), replacements: {
                    Link: React.createElement(HeaderInfolineElementLink, { fields: props.fields }),
                } }))));
};
var HeaderInfolineElementLink = function (props) { return (React.createElement(Typography, { type: "regular", size: "xs" },
    React.createElement(Link, { className: "h-header-infoline__link", field: props.fields.InfolineLink, editable: false }, props.fields.InfolineLinkText.value))); };
