var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Column, Row } from '@jelmoli/bootstrap-grid';
import { useLoadNamedOnceOnClientSide } from '@jelmoli/hooks/src/useLoadOnceOnClientSide/useLoadOnceOnClientSide';
import { Spinner } from '@jelmoli/ui-components/src/atoms/Spinner/Spinner';
import { Container } from '@jelmoli/ui-components/src/helpers/Container/Container';
import { urlManager } from '@jelmoli/urlmanager/UrlManager';
import React from 'react';
export var OrderDetailComponent = function () {
    var MyAccountOrderDetail = useLoadNamedOnceOnClientSide('MyAccountOrderDetail', function () {
        return import(
        /* webpackChunkName: "MyAccountOrderDetail" */ '../../Account/MyAccountOrdersComponent/MyAccountOrderDetail/MyAccountOrderDetail');
    });
    var queryStrings = __assign({}, urlManager.queryString);
    var orderId = queryStrings.orderId;
    var token = queryStrings.token;
    return (React.createElement(Container, { size: "m" },
        React.createElement(Row, null,
            React.createElement(Column, { size: { sm: 12, md: 8 }, offset: { sm: 0, md: 2 } }, MyAccountOrderDetail ? (React.createElement(MyAccountOrderDetail, { orderId: orderId, token: token, type: "guest" })) : (React.createElement(Spinner, null))))));
};
