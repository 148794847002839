var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useAsyncUiEvent } from '@jelmoli/hooks/src/useAsyncUiEvent/useAsyncUiEvent';
import classnames from 'classnames';
import React from 'react';
import { HorizontalSpacer } from '../../helpers/HorizontalSpacer/HorizontalSpacer';
import { Typography } from '../../helpers/Typography/Typography';
import { Spinner } from '../Spinner/Spinner';
import './button-secondary.scss';
export var SecondaryButton = function (props) {
    var Tag = props.type === 'link' ? 'a' : 'button';
    var _a = useAsyncUiEvent(props.onClick), onClick = _a[0], isWaitingForAsyncOperation = _a[1];
    var isLoading = Boolean(props.loading) || isWaitingForAsyncOperation;
    return (React.createElement(Tag, __assign({}, (props.type !== 'link'
        ? { type: props.type, disabled: props.disabled || isLoading, value: props.text }
        : { href: props.href }), { className: classnames('a-button-secondary', props.size && "a-button-secondary--" + props.size, props.disabled && 'a-button-secondary--disabled', isLoading && !props.disabled && 'a-button-secondary--loading', props.fullWidth && 'a-button-secondary--fullwidth', props.icon && 'a-button-secondary--icon', props.href && 'a-button-secondary--link') }, (props.rel && { rel: props.rel }), { onClick: onClick, onMouseDown: preventDefault }, (props.target && { target: props.target })),
        React.createElement("div", { className: "a-button-secondary__text-wrapper" },
            React.createElement("span", { className: "a-button-secondary__content" },
                props.icon && (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: "a-button-secondary__icon" }, props.icon),
                    React.createElement(HorizontalSpacer, { size: "xxs" }))),
                React.createElement(Typography, { size: props.fontSize || 'xs', type: props.fontType || 'medium', uppercase: props.uppercase !== false, responsive: Boolean(props.fontResponsive) }, props.text)),
            isLoading && (React.createElement("span", { className: "a-button-secondary__spinner" },
                React.createElement(Spinner, { size: "small", color: "inherit" }))))));
};
var preventDefault = function (e) { return e.preventDefault(); };
