var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { default as MaterialUIDrawer } from '@material-ui/core/Drawer';
import React, { useEffect } from 'react';
import './base-drawer.scss';
export var BaseDrawer = React.forwardRef(function (props, ref) {
    useEffect(function () {
        if (props.open) {
            setTimeout(function () {
                var drawerBackground = document.querySelector('.h-base-drawer__backdrop');
                if (drawerBackground) {
                    drawerBackground.addEventListener('touchstart', closeDrawer);
                    drawerBackground.addEventListener('mousedown', closeDrawer);
                }
            }, 0);
        }
    }, [props.open]);
    var closeDrawer = function (e) {
        props.onClose(e);
    };
    return (React.createElement(MaterialUIDrawer, { anchor: props.anchor, open: props.open, onClose: props.onClose, BackdropProps: { classes: { root: 'h-base-drawer__backdrop' } }, classes: __assign({ paper: 'h-base-drawer__paper' }, props.classes), elevation: props.elevation || 0, PaperProps: { ref: ref }, transitionDuration: { enter: 300, exit: 300 } }, props.children));
});
