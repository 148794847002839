import { useComponentSize } from '@jelmoli/hooks/src/useComponentSize/useComponentSize';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import './base-image.scss';
var emptyImageUrl = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
var ratios = {
    '2-1': 2 / 1,
    '16-9': 16 / 9,
    '3-2': 3 / 2,
    '1-1': 1 / 1,
    '4-5': 4 / 5,
    '5-7': 5 / 7,
    '2-3': 2 / 3,
};
export var useImageRatio = function (props) {
    var imageSize = {
        width: props.value['image-width'],
        height: props.value['image-height'],
    };
    return getImageRatio(Number(imageSize.width), Number(imageSize.height));
};
function getImageRatio(width, height) {
    var ratioNames = Object.keys(ratios);
    for (var _i = 0, ratioNames_1 = ratioNames; _i < ratioNames_1.length; _i++) {
        var ratioName = ratioNames_1[_i];
        // If height multiplied by the ratio equals the width by ±10px
        // return this ratio
        if (Math.abs(ratios[ratioName] * height - width) <= 10) {
            return ratioName;
        }
    }
    return 'native';
}
var responsiveImageWidths = [300, 585, 870, 1155, 1440];
/**
 * The base image is a plain image component to display
 * sitecore images without any styling
 */
export var BaseImage = React.memo(function (props) {
    var ratio = useImageRatio({ value: props.value });
    if (props.value.src === '') {
        return null;
    }
    return (React.createElement(ResponsiveImage, { className: props.className, ratio: ratio, src: props.value.src, alt: props.value.alt, reuseImage: props.reuseImage === undefined ? true : props.reuseImage, widthInit: props.value['image-width'], heightInit: props.value['image-height'] }));
});
var ResponsiveImage = function (props) {
    var _a = useComponentSize(), ref = _a.ref, width = _a.width;
    // If the image may be reused we compare null with null so it will never evaluate to true
    var didSrcPropertyChange = useDidPropertyChange(props.reuseImage ? null : props.src);
    var src = props.src, ratio = props.ratio, alt = props.alt, className = props.className;
    var urls = generateImageUrls(src || '', ratio, responsiveImageWidths);
    var seoUrl = urls[urls.length - 1];
    // As long as we don't know the size or if the image
    // is to small to show anything provide an empty image
    var isLoading = width <= 5;
    var useSrcSet = urls.length > 1;
    var srcSet = isLoading
        ? emptyImageUrl
        : urls.map(function (url, i) { return escapeSrcSetUrl(url) + " " + responsiveImageWidths[i] + "w"; }).join(', ');
    // If the image srcChanged (requires reuseImage === false) render an empty element for a short moment
    // to prevent the browser from showing the previous src
    return didSrcPropertyChange ? (React.createElement(React.Fragment, null)) : ratio === 'native' ? (React.createElement("img", { ref: ref, className: classnames(className, isLoading && 'h-base-image', "h-base-image--" + ratio), src: seoUrl, sizes: useSrcSet ? width + "px" : undefined, srcSet: useSrcSet ? srcSet : undefined, alt: alt, loading: "lazy", width: props.widthInit, height: props.heightInit })) : (React.createElement("span", { className: classnames('h-base-image', "h-base-image--" + ratio) },
        React.createElement("img", { ref: ref, className: classnames('h-base-image--ratio', className), src: seoUrl, sizes: useSrcSet ? width + "px" : undefined, srcSet: useSrcSet ? srcSet : undefined, alt: alt, loading: "lazy", width: props.widthInit, height: props.heightInit })));
};
/**
 * Prepare the url for SiteCore
 */
function generateImageUrls(baseUrl, ratio, widths) {
    // Disable backend resizing for svgs for faster image loading, only apply resizing to /dfsmedia urls
    if ((ratio === 'native' && baseUrl.substr(-4) === '.svg') || !baseUrl.startsWith('/dfsmedia')) {
        return [baseUrl];
    }
    var urlParts = baseUrl.split('/');
    var imageName;
    // Remove last element which is sometimes the name of the image
    if (urlParts.length > 4) {
        imageName = urlParts.splice(-1, 1);
        return widths.map(function (width) {
            return urlParts.concat(["resize/" + width + "x0/compress/40/options/keepaspectratio"], imageName).join('/');
        });
    }
    return widths.map(function (width) { return urlParts.concat(["resize/" + width + "x0/compress/40/options/keepaspectratio"]).join('/'); });
}
function useDidPropertyChange(prop) {
    var _a = useState(prop), lastPropValue = _a[0], setLastPropValue = _a[1];
    var didChange = lastPropValue !== prop;
    useEffect(function () {
        if (!didChange || !requestAnimationFrame) {
            return;
        }
        var animationFrame = requestAnimationFrame(function () { return setLastPropValue(prop); });
        return function () { return cancelAnimationFrame(animationFrame); };
    }, [prop]);
    return didChange;
}
/**
 * The DAM sometimes delivers urls which are not properly encoded and will
 * therefore fail inside the srcSet
 *
 * "Failed parsing 'srcset' attribute value since it has an unknown descriptor."
 * @see https://jira.namics.com/browse/JELMOLI2025-2795
 */
function escapeSrcSetUrl(url) {
    return url.replace(/ /g, '%20');
}
