export var showSuccessNotification = function (content) {
    showNotification('success', content);
};
export var showErrorNotification = function (content) {
    if (!content) {
        var technicalErrorMessage = getTechnicalError();
        showNotification('error', technicalErrorMessage);
        return;
    }
    showNotification('error', content);
};
var enqueueSnackbarPromise;
export var showNotification = function (notificationType, content) {
    enqueueSnackbarPromise =
        enqueueSnackbarPromise ||
            import(/* webpackChunkName: "NotificationStack" */ './NotificationStack').then(function (_a) {
                var createSnackbarFunction = _a.createSnackbarFunction;
                return createSnackbarFunction();
            });
    return enqueueSnackbarPromise.then(function (enqueueSnackbar) { return enqueueSnackbar(content, notificationType); });
};
/**
 * Some flows like login require a reload to continue
 * This method allows to reload and show a notification message
 */
export var showNotificationAndReload = function (notificationType, notificationKey, options) {
    return showNotificationAndGotoPage(notificationType, notificationKey, document.location.href, options);
};
/**
 * Some flows like login require sending the user to a new page
 * and show a notification on the next page
 *
 * This works toghether with the NotificationComponent
 */
export var showNotificationAndGotoPage = function (notificationType, notificationKey, url, options) {
    var delimiter = url.indexOf('?') > -1 ? '&' : '?';
    var newHistoryEntry = options && options.newHistoryEntry;
    if (typeof notificationType === 'string') {
        var parameterName = notificationType === 'success' ? 'statusMsg' : 'errorMsg';
        document.location[newHistoryEntry ? 'assign' : 'replace']("" + url + delimiter + parameterName + "=" + notificationKey);
    }
    else {
        var splitIndex = notificationKey.indexOf('error');
        document.location[newHistoryEntry ? 'assign' : 'replace']("" + url + delimiter + "statusMsg=" + notificationKey.slice(0, splitIndex) + "&errorMsg=" + notificationKey.slice(splitIndex + 1));
    }
    return new Promise(function () {
        /* will never finish until the page was switched to keep */
    });
};
/**
 *  This function returns the hardcoded technical error when
 *  services stop working
 *
 *  Message is shown based on the set language. Currently supports en/de
 */
function getTechnicalError() {
    var htmlElement = document.querySelector('html');
    var language = htmlElement ? htmlElement.getAttribute('lang') : 'de';
    switch (language) {
        case 'de':
            return 'Ein technischer Fehler ist aufgetreten. Bitte kontaktieren Sie den Kundendientst.';
        case 'en':
            return 'A technical error has occurred. Please contact customer service.';
        default:
            return 'Ein technischer Fehler ist aufgetreten. Bitte kontaktieren Sie den Kundendientst.';
    }
}
