import { toQueryString } from '@jelmoli/querystring';
import { createGetEndpoint } from 'request-registry';
export var getReservationDetail = createGetEndpoint({
    url: function (_a) {
        var reservationId = _a.reservationId;
        return "/jelmoli-api/JelmoliMyAccountOrders/GetReservationDetail?" + toQueryString({
            reservationConfirmationId: reservationId,
        });
    },
});
