import { Column, Row } from '@jelmoli/bootstrap-grid';
import { Image } from '@jelmoli/ui-components/src/atoms/Image/Image';
import { Slide, Slider } from '@jelmoli/ui-components/src/helpers/Slider/Slider';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { Visibility } from '@jelmoli/ui-components/src/helpers/Visibility/Visibility';
import React, { useState } from 'react';
import './product-images.scss';
import { ProductImageOverlay } from './ProductImageOverlay/ProductImageOverlay';
export var ProductImages = function (props) {
    var _a = useState(0), imageOverlayIndex = _a[0], setImageOverlayIndex = _a[1];
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    return (React.createElement("div", { style: { width: '100%' } },
        React.createElement(ProductImageOverlay, { images: props.images, productName: props.productName, open: isOpen, onClose: function () { return setIsOpen(false); }, selectedImageIndex: imageOverlayIndex, onIndexChange: setImageOverlayIndex }),
        React.createElement(Visibility, { show: ['xs', 'sm'] },
            React.createElement(MobileProductImages, { images: props.images.filter(function (image) { return image !== null; }), setImageOverlayIndex: setImageOverlayIndex, setIsOpen: setIsOpen, productName: props.productName })),
        React.createElement(Visibility, { hide: ['xs', 'sm'] },
            React.createElement(DesktopProductImages, { images: props.images.filter(function (image) { return image !== null; }), setImageOverlayIndex: setImageOverlayIndex, setIsOpen: setIsOpen, productName: props.productName }))));
};
var MobileProductImages = function (_a) {
    var images = _a.images, productName = _a.productName, setIsOpen = _a.setIsOpen, setImageOverlayIndex = _a.setImageOverlayIndex;
    return (React.createElement("div", { className: "feat-product-images__slider-wrapper" },
        images.length > 0 && (React.createElement(Slider, { minSlideAmount: { xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }, paginationPosition: "bottom" }, function (getRef) { return (React.createElement(React.Fragment, null, images.map(function (image, index) { return (React.createElement(Slide, { key: index, ref: getRef(), sliderColumnCount: 12 },
            React.createElement("div", { onClick: function () {
                    setImageOverlayIndex(index);
                    setIsOpen(true);
                } },
                React.createElement(Image, { value: {
                        src: image,
                        'image-width': 500,
                        'image-height': 700,
                        alt: productName,
                    } })))); }))); })),
        images.length === 0 && (React.createElement(React.Fragment, null,
            React.createElement(VerticalSpacer, { size: "mx" }),
            React.createElement("div", { className: "feat-product-images__image-offline" })))));
};
var DesktopProductImages = function (_a) {
    var images = _a.images, productName = _a.productName, setIsOpen = _a.setIsOpen, setImageOverlayIndex = _a.setImageOverlayIndex;
    return (React.createElement(Row, null,
        React.createElement("div", { className: "feat-product-images__wrapper" },
            images.map(function (image, index) { return (React.createElement(Column, { key: index, size: setColumnNumber(images.length, index), offset: typeof setColumnNumber(images.length, index) === 'object' ? { md: 0, lg: 1 } : 0 },
                React.createElement("div", { className: "feat-product-images__image", onClick: function () {
                        setImageOverlayIndex(index);
                        setIsOpen(true);
                    } },
                    React.createElement(Image, { value: { src: image, 'image-width': 500, 'image-height': 700, alt: productName } })),
                React.createElement(VerticalSpacer, { size: "m" }))); }),
            images.length === 0 && (React.createElement(Column, { size: { md: 12, lg: 10 }, offset: { md: 0, lg: 1 } },
                React.createElement("div", { className: "feat-product-images__image" },
                    React.createElement("div", { className: "feat-product-images__image-offline" })),
                React.createElement(VerticalSpacer, { size: "m" })))),
        React.createElement(VerticalSpacer, { size: "l" })));
};
var setColumnNumber = function (length, itemIndex) {
    var oddLenght = length % 2 !== 0;
    var evenIndex = itemIndex % 2 === 0;
    if (evenIndex && oddLenght) {
        // Case when there is only 1 item
        if (length === 1) {
            return { md: 12, lg: 10 };
        }
        // Case whent there are 3 or 5 items
        if ((length === 3 || length === 5) && itemIndex === 2) {
            return { md: 12, lg: 10 };
        }
    }
    return 6;
};
