var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Row } from '@jelmoli/bootstrap-grid';
import { Container } from '@jelmoli/ui-components/src/helpers/Container/Container';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import React, { useEffect, useState } from 'react';
import { Column } from 'react-bootstrap-grid-component/dist/Column';
import './registration.scss';
import { RegistrationFirstStep } from './RegistrationFirstStep';
import { RegistrationSecondStep } from './RegistrationSecondStep';
export var RegistrationComponent = function (props) {
    var _a = useState(false), isfinishedFirstStep = _a[0], setIsFinishedFirstStep = _a[1];
    useEffect(function () {
        if (typeof document !== undefined) {
            // this is needed for the case when user gets to this page from a drawer
            // removing of the style enables scrolling once again
            document.getElementsByTagName('body')[0].removeAttribute('style');
            setIsFinishedFirstStep(new URLSearchParams(document.location.search).get('step') === 'step_2');
        }
    }, []);
    return (React.createElement(Container, { size: "s", backgroundFill: true },
        React.createElement(Row, null,
            React.createElement(Column, { size: { xs: 12, sm: 8, lg: 6 }, offset: { xs: 0, sm: 2, lg: 3 }, horizontalAlignment: "center" },
                React.createElement(VerticalSpacer, { size: "xl" }),
                !isfinishedFirstStep && React.createElement(RegistrationFirstStep, __assign({}, props)),
                isfinishedFirstStep && React.createElement(RegistrationSecondStep, __assign({}, props)),
                React.createElement(VerticalSpacer, { size: "xxxl" })))));
};
