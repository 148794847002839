var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { RichText as SitecoreRichText, Text as SitecoreText } from '@sitecore-jss/sitecore-jss-react';
import classnames from 'classnames';
import React from 'react';
import './typography.scss';
/**
 * Generate all css classes depending on the typography options
 */
function typographyStyleClasses(options) {
    var responsiveness = options.responsive === false ? 'static' : 'responsive';
    var fontType = options.type || 'regular';
    var ellipsis = options.wrap === 'ellipsis';
    var nowrap = options.wrap === 'nowrap';
    var multiline = !options.wrap || options.wrap === 'multiline';
    /** Span elements have display: inline and therefore don't have a width or height */
    var isBlockElement = options.tag !== 'span';
    /** The ellipsis effect requires the element to have a width */
    var needsToBeBlockElement = ellipsis;
    return classnames('h-typography', 
    // Font size e.g. light or bold
    "h-typography--" + fontType, 
    // Font size
    "h-typography--" + responsiveness + "-" + fontType + "-" + options.size, {
        // Multiline will make line breaks like \n visible
        // if set to false only <br> tags will create visible line breaks
        // Ellipsis has to be always single-line - therefore multiline
        // can't be used with ellipsis
        'h-typography--multiline': multiline,
        // Don't break text in multilines, e.g. used when have text in Horizontal Scroller
        'h-typography--nowrap': nowrap,
        // Ellipsis will chop texts if they are to long e.g. Internati...
        'h-typography--ellipsis': ellipsis,
        // Ellipsis elements have to use the display inline-block or block
        'h-typography--block': needsToBeBlockElement && !isBlockElement,
        // Turns all letters to be UPPERCASE using css
        'h-typography--uppercase': options.uppercase,
    }, 
    // Define the display options
    options.display && "h-typography--" + options.display);
}
/**
 * Base Text Element to render text
 * Usage:
 * <PureText tag="span" type="bold">Hello Moon</PureText>
 */
export var PureText = function (props) {
    var uppercase = props.uppercase, tag = props.tag, size = props.size, type = props.type, ref = props.ref, wrap = props.wrap, responsive = props.responsive, display = props.display, childProps = __rest(props, ["uppercase", "tag", "size", "type", "ref", "wrap", "responsive", "display"]);
    var Tag = tag || 'span';
    var className = typographyStyleClasses({ uppercase: uppercase, size: size, type: type, wrap: wrap, responsive: responsive, tag: Tag, display: display });
    return React.createElement(Tag, __assign({}, childProps, { className: className }));
};
/**
 * Render an HTML Element which gets its data from sitecore
 * and is editable for the autor using a Text field
 */
export var CmsText = function (props) {
    var uppercase = props.uppercase, type = props.type, size = props.size, wrap = props.wrap, responsive = props.responsive, childProps = __rest(props, ["uppercase", "type", "size", "wrap", "responsive"]);
    var className = typographyStyleClasses({ uppercase: uppercase, size: size, type: type, wrap: wrap, responsive: responsive, tag: 'span' });
    return React.createElement(SitecoreText, __assign({ tag: "span" }, childProps, { className: className }));
};
/**
 * Render an HTML Element which gets its data from sitecore
 * and is editable for the autor using a Wysiwyg Editor
 */
export var CmsRichText = function (props) {
    var className = 'h-typography h-typography--richtext';
    return React.createElement(SitecoreRichText, __assign({ tag: "div" }, props, { className: className }));
};
/**
 * Base Text Element to render text from Cms fields or strings
 * Usage:
 * <Typography tag="span" type="bold">Hello Moon</PureText>
 * or
 * <Typography tag="span" field={fields.heading} />
 */
export var Typography = function (props) {
    if ('field' in props) {
        return CmsText(__assign({ tag: 'span' }, props));
    }
    return PureText(props);
};
/**
 * Returns the size of a cms field
 */
export function getFieldTextLength(content) {
    if (typeof content !== 'object' || content.value === undefined) {
        return 0;
    }
    return content.value.length;
}
