import { Column, Row } from '@jelmoli/bootstrap-grid';
import { Container } from '@jelmoli/ui-components/src/helpers/Container/Container';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { ShopTheLookOverlayContent } from '@jelmoli/ui-components/src/organism/ShopTheLookOverlayContent/ShopTheLookOverlayContent';
import React from 'react';
export var ShopTheLookOverlayContentComponent = function (_a) {
    var fields = _a.fields;
    return (React.createElement(Container, { size: "m", backgroundFill: true },
        React.createElement(Container, { size: "l" },
            React.createElement(VerticalSpacer, { size: "mx" }),
            React.createElement(Row, null,
                React.createElement(Column, null,
                    React.createElement(Typography, { size: "s", type: "light", field: fields.ShopTheLookContentTitle })))),
        React.createElement(ShopTheLookOverlayContent, { contentStaffPickLabel: fields.ShopTheLookContentStaffPickLabel, contentQuoteText: fields.ShopTheLookContentQuoteText, contentStaffMemberLink: fields.ShopTheLookContentStaffMemberLink, contentImage: fields.ShopTheLookContentImage, contentImageCaption: fields.ShopTheLookContentImageCaption, contentDownload: fields.ShopTheLookContentDownload, contentBrandLink1: fields.ShopTheLookContentBrandLink1, contentBrandLink2: fields.ShopTheLookContentBrandLink2, contentBrandLink3: fields.ShopTheLookContentBrandLink3, contentShopTheLookProducts: fields.ShopTheLookProducts, inTheCartText: fields.InTheCartText, giftCardPrice: fields.GiftcardPrice })));
};
