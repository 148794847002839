import { Column, Row } from '@jelmoli/bootstrap-grid';
import { bindTranslations } from '@jelmoli/i18n/index';
import React, { useState } from 'react';
import { PersonalShoppingDrawerComponent } from '../../../../../sitecore-features/src/CustomerService/PersonalShoppingDrawerComponent/PersonalShoppingDrawerComponent';
import { Divider } from '../../../atoms/Divider/Divider';
import { Image } from '../../../atoms/Image/Image';
import { SecondaryButton } from '../../../atoms/SecondaryButton/SecondaryButton';
import { Typography } from '../../../helpers/Typography/Typography';
import { VerticalSpacer } from '../../../helpers/VerticalSpacer/VerticalSpacer';
import { Visibility } from '../../../helpers/Visibility/Visibility';
export var ServiceTeaserItem = function (props) {
    var categoryLength = props.ServiceItem.fields.ServiceCategory.length;
    var _a = useState(false), isDrawerOpen = _a[0], setIsDrawerOpen = _a[1];
    var t = bindTranslations(props);
    return (React.createElement(React.Fragment, null,
        React.createElement(PersonalShoppingDrawerComponent, { isOpen: isDrawerOpen, openDrawer: function () { return setIsDrawerOpen(false); }, serviceId: props.ServiceItem.fields.ServiceId, serviceStuffMemberId: props.serviceStaffMemberId }),
        React.createElement(Row, null,
            React.createElement(Column, { size: 12 },
                React.createElement(Visibility, { show: ['xs'] },
                    React.createElement(Divider, { heightSize: "xxxxs", widthSize: "xxs" }),
                    React.createElement(VerticalSpacer, { size: "s" }),
                    props.ServiceItem.fields.ServiceCategory &&
                        props.ServiceItem.fields.ServiceCategory.map(function (item, i) { return (React.createElement(Typography, { type: "light", uppercase: true, size: "xs", key: i },
                            item.fields.CategoryName.value,
                            categoryLength > 1 && categoryLength !== i + 1
                                ? categoryLength === i + 2
                                    ? ' & '
                                    : ', '
                                : '')); }),
                    React.createElement(Typography, { tag: 'p', type: "medium", size: "xl", field: props.ServiceItem.fields.ServiceName }),
                    React.createElement(VerticalSpacer, { size: "xs" }),
                    React.createElement(Typography, { tag: 'p', type: "medium", size: "xs" },
                        props.ServiceItem.fields.ServiceDurationTime.value,
                        ' ',
                        props.ServiceItem.fields.ServiceCostDescription.value),
                    React.createElement(Typography, { type: "regular", size: "xxs", field: props.ServiceItem.fields.ServiceShortDescription }),
                    React.createElement(VerticalSpacer, { size: "s" }))),
            React.createElement(Column, { offset: { xs: 0, md: 1 }, size: { xs: 12, sm: 6, md: 4, lg: 5 } },
                React.createElement(Image, { value: props.ServiceItem.fields.ServiceImage.value })),
            React.createElement(Column, { size: { xs: 12, sm: 6, lg: 5 } },
                React.createElement(Visibility, { show: ['sm', 'md'] },
                    React.createElement(Divider, { heightSize: "xxxxs", widthSize: "xxs" })),
                React.createElement(Visibility, { show: "lg" },
                    React.createElement(Divider, { heightSize: "xxxs", widthSize: "xs" })),
                React.createElement(Visibility, { show: "xl" },
                    React.createElement(Divider, { heightSize: "xxs", widthSize: "s" })),
                React.createElement(Visibility, { hide: "xs" },
                    React.createElement(VerticalSpacer, { size: "s" }),
                    props.ServiceItem.fields.ServiceCategory &&
                        props.ServiceItem.fields.ServiceCategory.map(function (item, i) { return (React.createElement(Typography, { type: "light", uppercase: true, size: "xs", key: i },
                            item.fields.CategoryName.value,
                            categoryLength > 1 && categoryLength !== i + 1
                                ? categoryLength === i + 2
                                    ? ' & '
                                    : ', '
                                : '')); }),
                    React.createElement(Typography, { tag: 'p', type: "medium", size: "xl", field: props.ServiceItem.fields.ServiceName }),
                    React.createElement(VerticalSpacer, { size: "xs" }),
                    React.createElement(Typography, { type: "medium", size: "xs" },
                        props.ServiceItem.fields.ServiceDurationTime.value,
                        ' ',
                        props.ServiceItem.fields.ServiceCostDescription.value),
                    React.createElement(Typography, { tag: "div", type: "regular", size: "xxs", field: props.ServiceItem.fields.ServiceShortDescription })),
                React.createElement(VerticalSpacer, { size: "s" }),
                React.createElement(Typography, { type: "regular", size: "s", field: props.ServiceItem.fields.ServiceTeaserDescription }),
                React.createElement(VerticalSpacer, { size: "m" }),
                React.createElement(Visibility, { hide: "xs" },
                    React.createElement(SecondaryButton, { type: "button", text: t('ServiceTeaserBookingButton'), fullWidth: false, onClick: function () {
                            setIsDrawerOpen(!isDrawerOpen);
                        } })),
                React.createElement(Visibility, { show: "xs" },
                    React.createElement(SecondaryButton, { type: "button", text: t('ServiceTeaserBookingButton'), fullWidth: true, onClick: function () {
                            setIsDrawerOpen(!isDrawerOpen);
                        } }))))));
};
