import { createPutEndpoint } from 'request-registry';
import { getCSRFToken } from '../../Security/getCSRFToken/getCSRFToken';
import { getAddressbook } from '../getAddressbook/getAddressbook';
/**
 * This API add/edits address in the database
 * https://wiki.namics.com/display/JELMOLI2025/205-01-08+MyAccount+Profile+Interfaces#id-205-01-08MyAccountProfileInterfaces-EditAddress
 */
export var editAddress = createPutEndpoint({
    url: function () { return '/jelmoli-api/JelmoliMyAccountProfile/EditAddress'; },
    headers: {
        'X-RequestVerificationToken': function () { return getCSRFToken(); },
        'X-Requested-With': 'XMLHttpRequest',
    },
    afterSuccess: function () { return Promise.all([getAddressbook.refresh()]); },
});
