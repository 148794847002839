import { Column, Row } from '@jelmoli/bootstrap-grid';
import { useLoadNamedOnceOnClientSide } from '@jelmoli/hooks/src/useLoadOnceOnClientSide/useLoadOnceOnClientSide';
import { SeoLink } from '@jelmoli/ui-components/src/atoms/SEOLink/SeoLink';
import { SeoTextComponent } from '@jelmoli/ui-components/src/atoms/SEOText/SeoText';
import { Spinner } from '@jelmoli/ui-components/src/atoms/Spinner/Spinner';
import { Container } from '@jelmoli/ui-components/src/helpers/Container/Container';
import React from 'react';
export var ProductSearchComponent = function (props) {
    var ProductSearchLazy = useLoadNamedOnceOnClientSide('ProductSearchLazy', function () {
        return import(/* webpackChunkName: "ProductSearchLazy" */ './ProductSearchLazy');
    });
    return (React.createElement(Container, { size: "m", backgroundFill: true }, ProductSearchLazy ? (React.createElement(React.Fragment, null,
        React.createElement(ProductSearchLazy, { searchConfigurationId: props.fields.productSearchConfigurationId, facetNames: props.fields.productSearchFacetNames, productSortOptions: props.fields.sortOptions, POPDefaultStageCategoryTitle: props.fields.popDefaultStageCategoryTitle, productSearchCategories: props.fields.productSearchCategories, initialResult: props.fields.initialResult, popSeo: props.fields.popSeo }),
        props.fields.initialResult.items.map(function (item, index) {
            return (React.createElement(SeoLink, { key: index, linkText: (item.brand || '') + " " + (item.name || ''), linkHref: item.variants[0].sizes[0].url }));
        }))) : (React.createElement(Row, null,
        React.createElement(Column, { size: 12, horizontalAlignment: "center" },
            React.createElement(Spinner, null),
            React.createElement(SeoTextComponent, { fields: {
                    popSeoText: props.fields.popSeo.popSeoText,
                    popSeoTitle: props.fields.popSeo.popSeoTitle,
                } }),
            props.fields.initialResult.items.map(function (item, index) {
                return (React.createElement(SeoLink, { key: index, linkText: (item.brand || '') + " " + (item.name || ''), linkHref: item.variants[0].sizes[0].url }));
            }))))));
};
