import { createDeleteEndpoint } from 'request-registry';
import { getCSRFToken } from '../../Security/getCSRFToken/getCSRFToken';
import { getAddressbook } from '../getAddressbook/getAddressbook';
/**
 * This API removes address from the database
 * https://wiki.namics.com/display/JELMOLI2025/205-01-08+MyAccount+Profile+Interfaces#id-205-01-08MyAccountProfileInterfaces-DeleteAddress
 */
export var deleteAddress = createDeleteEndpoint({
    url: function (_a) {
        var id = _a.id;
        return "/jelmoli-api/JelmoliMyAccountProfile/DeleteAddress/" + id;
    },
    headers: {
        'X-RequestVerificationToken': function () { return getCSRFToken(); },
        'X-Requested-With': 'XMLHttpRequest',
    },
    afterSuccess: function () { return Promise.all([getAddressbook.refresh()]); },
});
