function loadCaptchaScript() {
    var grecaptcha = window.grecaptcha;
    if (grecaptcha && grecaptcha.execute) {
        return Promise.resolve(grecaptcha);
    }
    return new Promise(function (resolve) {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.onload = function () {
            grecaptcha = window.grecaptcha;
            grecaptcha.ready(function () {
                resolve(grecaptcha);
            });
        };
        script.src = '//www.google.com/recaptcha/api.js';
        document.getElementsByTagName('head')[0].appendChild(script);
    });
}
var wasErrorShown = false;
var captchaIds = new Set();
function createCaptcha(siteKey) {
    var id = "gCaptcha_" + Math.floor(Math.random() * 100000);
    if (captchaIds.has(id)) {
        return createCaptcha(siteKey);
    }
    captchaIds.add(id);
    // Create catpcha element for google
    // https://developers.google.com/recaptcha/docs/invisible#examples
    var div = document.createElement('div');
    div.setAttribute('id', id);
    // Hide the div
    div.setAttribute('style', 'width:0;height:0;overflow:hidden;position:fixed;left:10vw;top:10vh');
    document.body.appendChild(div);
    var cleanup = function () {
        captchaIds.delete(id);
        document.body.removeChild(div);
        delete window[id];
    };
    var execute = function () {
        return loadCaptchaScript().then(function (grecaptcha) {
            // If the captcha was cleaned up in the meantime stop
            // execution
            if (!captchaIds.has(id)) {
                return Promise.reject('canceled');
            }
            return new Promise(function (resolve, reject) {
                var widget = grecaptcha.render(id, {
                    sitekey: siteKey,
                    size: 'invisible',
                    badge: 'inline',
                    callback: id,
                });
                // Register a global function which will be called by google
                window[id] = function (token) {
                    cleanup();
                    resolve(token);
                };
                // Run the challenge
                grecaptcha.execute(widget).then(function () {
                    /* for unknown reasons this function will alway receive null */
                }, function () {
                    // Keep the div as it might have further error details
                    // for the very first captach execution
                    if (wasErrorShown) {
                        cleanup();
                    }
                    else {
                        div.setAttribute('style', '');
                        wasErrorShown = true;
                    }
                    reject("captcha failed for " + siteKey);
                });
            });
        });
    };
    return {
        execute: execute,
        cleanup: cleanup,
    };
}
export var invisibleCaptcha = function (siteKey) {
    return createCaptcha(siteKey).execute();
};
