import { useComponentSize } from '@jelmoli/hooks/src/useComponentSize/useComponentSize';
import React, { useEffect, useState } from 'react';
import './keep-in-view.scss';
export var KeepInView = function (_a) {
    var children = _a.children, marginTop = _a.marginTop, pageType = _a.pageType;
    var _b = useComponentSize(), ref = _b.ref, height = _b.height, width = _b.width;
    var screenHeight = useScreenHeight();
    var isContentLargetThanScreenHeight = screenHeight && height > screenHeight;
    // Top should be set to 0 until the server side renderiing is finalized
    // after rendering set value needs to be unset
    var _c = useState({}), style = _c[0], setStyle = _c[1];
    useEffect(function () {
        if (screenHeight === undefined) {
            setStyle({ top: 0 });
            return;
        }
        setStyle({ top: '', '--component-height': screenHeight - height + "px" });
    }, [screenHeight]);
    useEffect(function () {
        if (!document || pageType !== 'pdp') {
            return;
        }
        document.documentElement.style.setProperty('--keep-in-view-width', width + "px");
        return function () {
            document.documentElement.style.removeProperty('--keep-in-view-width');
        };
    }, [width]);
    return (React.createElement("div", { className: "h-keep-in-view", style: marginTop !== undefined ? { '--keep-in-view-margin': marginTop + "px" } : undefined },
        isContentLargetThanScreenHeight && React.createElement("div", { className: "h-keep-in-view__spacer" }),
        React.createElement("div", { className: isContentLargetThanScreenHeight ? 'h-keep-in-view__sticky--bottom' : 'h-keep-in-view__sticky--top', ref: ref, style: style }, children)));
};
var useScreenHeight = function () {
    var _a = useState(typeof window === 'undefined' ? undefined : window.innerHeight), screenHeight = _a[0], setScreenHeight = _a[1];
    useEffect(function () {
        var callback = function () {
            setScreenHeight(window.innerHeight);
        };
        window.addEventListener('resize', callback);
        return function () { return window.removeEventListener('resize', callback); };
    }, []);
    return screenHeight;
};
