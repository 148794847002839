import { Column, Row } from '@jelmoli/bootstrap-grid';
import { Image } from '@jelmoli/ui-components/src/atoms/Image/Image';
import { ProductFeature } from '@jelmoli/ui-components/src/atoms/ProductFeature/ProductFeature';
import { A } from '@jelmoli/ui-components/src/helpers/A/A';
import { Container } from '@jelmoli/ui-components/src/helpers/Container/Container';
import { hoverAnchorClassName } from '@jelmoli/ui-components/src/helpers/HoverAnchor/HoverAnchor';
import { hasTextFieldValue, } from '@jelmoli/ui-components/src/helpers/SitecoreTypes/SitecoreTypes';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import classnames from 'classnames';
import React from 'react';
import './inspiration-tile-large.scss';
export var InspirationTileLargeComponent = function (props) {
    return (React.createElement(A, { onClick: function () {
            if (props.savePosition) {
                props.savePosition();
            }
        }, className: classnames('feat-inspiration-tile-l', hoverAnchorClassName), href: props.fields.PageTeaserLink },
        React.createElement(Container, { size: "s" },
            React.createElement(Row, null,
                React.createElement(Column, { size: { xs: 12, sm: 8, md: 8, lg: 8, xl: 8 } }, props.fields.PageTeaserImage.value && (React.createElement(Image, { value: props.fields.PageTeaserImage.value, hoverable: true }))),
                React.createElement(Column, { size: { xs: 12, sm: 4, md: 4, lg: 4, xl: 4 } },
                    React.createElement(VerticalSpacer, { size: "m" }),
                    React.createElement(Typography, { size: "xs", type: "light", uppercase: true }, props.fields.PageTeaserSubtitle.value),
                    React.createElement("div", { className: "feat-inspiration-tile-l__title" },
                        React.createElement(Typography, { type: "bold", size: "m", field: props.fields.PageTeaserTitle, editable: false })),
                    props.fields.PageTeaserLabel &&
                        hasTextFieldValue(props.fields.PageTeaserLabel.fields.InspirationLabelTitle) && (React.createElement(React.Fragment, null,
                        React.createElement(VerticalSpacer, { size: "s" }),
                        React.createElement("div", { className: "feat-inspiration-tile-l__label-wrapper" },
                            React.createElement(ProductFeature, null, props.fields.PageTeaserLabel.fields.InspirationLabelTitle.value))))))),
        React.createElement(VerticalSpacer, { size: "xxxl" })));
};
