export var isModelStateException = function (exception) {
    if (typeof exception !== 'object' || !exception) {
        return false;
    }
    return 'ModelState' in exception;
};
export var isInvalidPasswordException = function (exception) {
    if (!isModelStateException(exception)) {
        return false;
    }
    return 'changePassword.OldPassword' in exception.ModelState;
};
export var getModalStateErrorMessage = function (exception, path) {
    if (!isModelStateException(exception)) {
        return false;
    }
    return exception.ModelState[path][0];
};
