import { toQueryString } from '@jelmoli/querystring';
import { createDeleteEndpoint } from 'request-registry';
import { getCSRFToken } from '../../Security/getCSRFToken/getCSRFToken';
import { getSavedCategories } from '../getSavedCategories/getSavedCategories';
export var removeCategory = createDeleteEndpoint({
    url: function (_a) {
        var rootCategory = _a.rootCategory, category = _a.category;
        return "/jelmoli-api/JelmoliMyAccountPreference/DeselectCategory?" + toQueryString({ rootCategory: rootCategory, category: category });
    },
    headers: {
        'X-RequestVerificationToken': function () { return getCSRFToken(); },
        'X-Requested-With': 'XMLHttpRequest',
    },
    afterSuccess: function () { return Promise.all([getSavedCategories.refresh()]); },
});
