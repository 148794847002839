var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Column } from '@jelmoli/bootstrap-grid';
import { useLoadNamedOnceOnClientSide } from '@jelmoli/hooks/src/useLoadOnceOnClientSide/useLoadOnceOnClientSide';
import { bindTranslations, I18N } from '@jelmoli/i18n';
import { getProfile } from '@jelmoli/sitecore-services/src/Account/getProfile/getProfile';
import { getProfileLabels } from '@jelmoli/sitecore-services/src/Account/getProfileLabels/getProfileLabels';
import { isUserLoggedInLazy } from '@jelmoli/sitecore-services/src/Account/isUserLoggedIn/isUserLoggedInLazy';
import { updateOrderJCard } from '@jelmoli/sitecore-services/src/Account/updateOrderJCard/updateOrderJCard';
import { CheckCard } from '@jelmoli/ui-components/src/atoms/CheckCard/CheckCard';
import { Modal } from '@jelmoli/ui-components/src/atoms/Modal/Modal';
import { showErrorNotification } from '@jelmoli/ui-components/src/atoms/Notification/Notification';
import { PrimaryButton } from '@jelmoli/ui-components/src/atoms/PrimaryButton/PrimaryButton';
import { QrCode } from '@jelmoli/ui-components/src/atoms/QrCode/QrCode';
import { SecondaryButton } from '@jelmoli/ui-components/src/atoms/SecondaryButton/SecondaryButton';
import { Separator } from '@jelmoli/ui-components/src/atoms/Separator/Separator';
import { Spinner } from '@jelmoli/ui-components/src/atoms/Spinner/Spinner';
import { TextLink } from '@jelmoli/ui-components/src/atoms/TextLink/TextLink';
import { TextSkewBackgroundHighlight } from '@jelmoli/ui-components/src/atoms/TextSkewBackgroundHighlight/TextSkewBackgroundHighlight';
import { WalletButton } from '@jelmoli/ui-components/src/atoms/WalletButton/WalletButton';
import { A } from '@jelmoli/ui-components/src/helpers/A/A';
import { useScreenSize } from '@jelmoli/ui-components/src/helpers/ScreenSizeDetector/ScreenSizeDetector';
import { hasTextFieldValue, } from '@jelmoli/ui-components/src/helpers/SitecoreTypes/SitecoreTypes';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { Visibility } from '@jelmoli/ui-components/src/helpers/Visibility/Visibility';
import React, { useMemo, useState } from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
import { MyAccountProfileMainAddressModalContent } from '../MyAccountProfileComponent/MyAccountProfileMainAddress/MyAccountProfileMainAddressModalContent';
import { MyAccountTitle } from '../MyAccountTitleComponent/MyAccountTitle';
import './my-account-loyalty-component.scss';
export var MyAccountLoyaltyComponent = function (_a) {
    var fields = _a.fields;
    var isLoggedIn = isUserLoggedInLazy();
    var profile = useGetEndPointLazy(getProfile, {}, isLoggedIn).value;
    if (!profile) {
        return (React.createElement(Column, { horizontalAlignment: "center" },
            React.createElement(Spinner, null)));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(MyAccountTitle, { fields: fields, getProfileData: true }),
        hasTextFieldValue(fields.YourScoreLabel) && (React.createElement(React.Fragment, null,
            React.createElement(MyAccountLoyaltyPoints, { yourScoreLabel: fields.YourScoreLabel.value, loyalityPointAmount: profile.loyalty.pointsCurrent }),
            React.createElement(VerticalSpacer, { size: "m" }))),
        React.createElement(MyAccountLoyaltyCard, { fields: fields }),
        React.createElement(VerticalSpacer, { size: "l" }),
        React.createElement(MyAccountLoyaltyTeasetText, { fields: fields }),
        React.createElement(VerticalSpacer, { size: "xl" }),
        React.createElement(Separator, { shade: "two" }),
        React.createElement(VerticalSpacer, { size: "xl" }),
        React.createElement(MyAccountLoyaltyReorderCard, { fields: fields }),
        React.createElement(VerticalSpacer, { size: "xl" })));
};
var MyAccountLoyaltyPoints = function (_a) {
    var yourScoreLabel = _a.yourScoreLabel, loyalityPointAmount = _a.loyalityPointAmount;
    var loyalityPointAmountString = useMemo(function () { return loyalityPointAmount.toLocaleString('en').replace(/,/g, "'"); }, [
        loyalityPointAmount,
    ]);
    return (React.createElement(Typography, { size: "xl", type: "medium" },
        React.createElement(I18N, { text: yourScoreLabel, replacements: {
                pointsCurrent: (React.createElement(TextSkewBackgroundHighlight, null, loyalityPointAmountString)),
            } })));
};
var MyAccountLoyaltyCard = function (_a) {
    var fields = _a.fields;
    var screenSize = useScreenSize();
    var largeScreens = screenSize === 'lg' || screenSize === 'xl';
    var profile = useGetEndPointLazy(getProfile).value;
    var _b = useState(false), qrModalIsOpen = _b[0], setQrModalIsOpen = _b[1];
    var t = bindTranslations(fields);
    var QrModal = useLoadNamedOnceOnClientSide('QrModal', function () { return import('./MyAccountLoyaltyComponentModal'); }, true, function (props) { return null; });
    // Add spaces to the card number
    // Format:
    // 1234 1234 123 12
    var jelmoliCardWithSpaces = useMemo(function () {
        return ((profile && profile.loyalty.jelmoliCardNumber) || '')
            .split(/(\d{4})(\d{4})(\d{0,3})(\d{0,3})/)
            .filter(function (v) { return v; })
            .join(' ');
    }, [profile]);
    var loyalityStatus = useMemo(function () {
        if (!profile) {
            return;
        }
        switch (profile.loyalty.statusCode) {
            case 'PREMIUM':
                return 'premium';
            case 'MEMBER':
                return 'classic';
            case 'ROYAL':
                return 'royal';
            default:
                throw new Error('Unknown statusCode');
        }
    }, [profile]);
    if (!profile || !loyalityStatus || !profile.loyalty.jelmoliCardNumber) {
        return null;
    }
    return (React.createElement("div", { className: "feat-myaccount-loyalty__card-panel" },
        React.createElement(CheckCard, { onClick: function () { return setQrModalIsOpen(largeScreens ? false : true); }, variant: loyalityStatus, firstRow: profile.firstName + " " + profile.lastName, secondRow: jelmoliCardWithSpaces }),
        React.createElement(Visibility, { show: ['xs', 'sm', 'md'] },
            React.createElement(VerticalSpacer, { size: "m" }),
            React.createElement(A, { className: "feat-myaccount-loyalty__qr", "aria-label": "qr code", onClick: function () { return setQrModalIsOpen(true); } },
                React.createElement(QrCode, { value: profile.loyalty.jelmoliCardNumber })),
            React.createElement(VerticalSpacer, { size: "mx" }),
            React.createElement(Separator, { shade: "two" })),
        false && (React.createElement(WalletButton, { appleWalletLink: "/" }, function (walletButton) { return (React.createElement("div", { className: "feat-myaccount-loyalty__wallet" },
            React.createElement(VerticalSpacer, { size: "mx" }),
            walletButton)); })),
        React.createElement(QrModal, { isOpen: qrModalIsOpen, onOpenChange: function () { return setQrModalIsOpen(false); }, jelmoliCardNumber: profile.loyalty.jelmoliCardNumber })));
};
var MyAccountLoyaltyTeasetText = function (props) {
    var userProfile = useGetEndPointLazy(getProfile).value;
    var labels = useMemo(function () {
        if (!userProfile) {
            return;
        }
        switch (userProfile.loyalty.statusCode) {
            case 'PREMIUM':
                return props.fields.JCardPremium.fields;
            case 'MEMBER':
                return props.fields.JCardClassic.fields;
            case 'ROYAL':
                return props.fields.JCardRoyal.fields;
            default:
                throw new Error('Unknown statusCode');
        }
    }, [userProfile]);
    if (!userProfile || !labels) {
        return null;
    }
    var t = bindTranslations(__assign(__assign({}, props.fields), labels));
    return (React.createElement(React.Fragment, null,
        React.createElement(Typography, { size: "xs", type: "medium", uppercase: true }, t('YourStatusTitle')),
        React.createElement(VerticalSpacer, { size: "xxs" }),
        React.createElement(Typography, { size: "l", type: "medium" },
            React.createElement(TextSkewBackgroundHighlight, null, userProfile.loyalty.statusTitle)),
        React.createElement(VerticalSpacer, { size: "mx" }),
        React.createElement(Typography, { size: "l" },
            React.createElement(I18N, { text: t('ExpireStatusMessage'), replacements: {
                    date: userProfile.loyalty.statusExpire,
                    status: (React.createElement(Typography, { size: "l", type: "medium" },
                        React.createElement(TextSkewBackgroundHighlight, null, userProfile.loyalty.statusTitle))),
                    pointsUntilUpgrade: (React.createElement(Typography, { size: "l", type: "medium" },
                        React.createElement(TextSkewBackgroundHighlight, null, userProfile.loyalty.pointsUntilUpgrade))),
                    statusExpireDate: (React.createElement(Typography, { size: "l", type: "medium" },
                        React.createElement(TextSkewBackgroundHighlight, null, userProfile.loyalty.statusExpire))),
                } })),
        React.createElement(VerticalSpacer, { size: "l" }),
        React.createElement(Typography, { size: "xs", type: "medium", uppercase: true }, t('PointTitle')),
        React.createElement(VerticalSpacer, { size: "xxs" }),
        React.createElement(Typography, { size: "l" }, t('PointDescription')),
        React.createElement(VerticalSpacer, { size: "m" }),
        React.createElement(Typography, { size: "xxs", type: "medium" }, t('CollectPointTitle')),
        React.createElement(VerticalSpacer, { size: "xs" }),
        false && (React.createElement(React.Fragment, null,
            React.createElement("div", null,
                React.createElement(TextLink, { "aria-label": t('LinkTaskLabel'), href: props.fields.LinkTasks.value.href },
                    React.createElement(Typography, { size: "l" }, t('LinkTaskLabel')))),
            React.createElement(VerticalSpacer, { size: "xs" }))),
        React.createElement("div", null,
            React.createElement(TextLink, { "aria-label": t('LinkShoppingInspirationLabel'), href: props.fields.LinkShoppingInspiration.value.href },
                React.createElement(Typography, { size: "l" }, t('LinkShoppingInspirationLabel')))),
        React.createElement(VerticalSpacer, { size: "xl" }),
        React.createElement(SecondaryButton, { type: "link", text: t('ButtonAdvantagesText'), href: props.fields.ButtonAdvantagesLink.value.href })));
};
var MyAccountLoyaltyReorderCard = function (_a) {
    var fields = _a.fields;
    var _b = useState(false), isOverlayOpen = _b[0], setIsOverlayOpen = _b[1];
    var _c = useState(false), isLoading = _c[0], setIsLoading = _c[1];
    var isLoggedIn = isUserLoggedInLazy();
    var getProfileData = useGetEndPointLazy(getProfile, {}, isLoggedIn).value;
    var getProfileLabelsData = useGetEndPointLazy(getProfileLabels).value;
    if (!getProfileLabelsData || !getProfileData) {
        return null;
    }
    var t = bindTranslations(fields);
    var l = bindTranslations(getProfileLabelsData);
    var country = 'countryCode' in getProfileData.mainAddress ? getProfileData.mainAddress.countryCode : '';
    var countryName = getProfileLabelsData.CountryList &&
        'countryCode' in getProfileData.mainAddress &&
        getProfileLabelsData.CountryList.find(function (item) { return item.fields['Country Code'].value === country; }).fields.Name
            .value;
    return (React.createElement(React.Fragment, null,
        React.createElement(Typography, { size: "xs", uppercase: true, type: "medium" }, t('OrderPhysicalCardsTitle')),
        React.createElement(VerticalSpacer, { size: "xxs", responsive: true }),
        getProfileData.isOrderJCardAvailable ? (React.createElement(Typography, { size: "l", type: "regular" }, t('OrderPhysicalCardsCopy'))) : (React.createElement(Typography, { size: "l", type: "regular" }, t('OrderPhysicalCardsCopyConfirmation'))),
        React.createElement(VerticalSpacer, { size: "s" }),
        'address1' in getProfileData.mainAddress ? (React.createElement(React.Fragment, null,
            React.createElement(Typography, { tag: "p", size: "l", type: "regular" }, t('OrderPhysicalCardsMainAddressTitle')),
            React.createElement(Typography, { tag: "p", size: "l", type: "regular" },
                getProfileData.mainAddress.company && getProfileData.mainAddress.company + ", ",
                getProfileData.mainAddress.address1,
                ",",
                ' ',
                getProfileData.mainAddress.address2 && getProfileData.mainAddress.address2 + ", ",
                getProfileData.mainAddress.zipPostalCode,
                " ",
                getProfileData.mainAddress.city,
                ", ",
                countryName),
            React.createElement(VerticalSpacer, { size: "s" }),
            getProfileData.isOrderJCardAvailable && !isLoading && (React.createElement(Typography, { size: "l", type: "regular" },
                React.createElement(TextLink, { "aria-label": t('OrderPhysicalCardsEditMainAddressLink'), underlined: true, onClick: function () { return setIsOverlayOpen(true); } }, t('OrderPhysicalCardsEditMainAddressLink')))))) : (React.createElement(Typography, { size: "l", type: "regular" },
            React.createElement(I18N, { text: t('OrderPhysicalCardsMainAddressText'), replacements: {
                    link: (React.createElement(Typography, { size: "l", type: "regular" },
                        React.createElement(TextLink, { "aria-label": t('OrderPhysicalCardsMainAddressLink'), underlined: true, onClick: function () { return setIsOverlayOpen(true); } }, t('OrderPhysicalCardsMainAddressLink')))),
                } }))),
        React.createElement(VerticalSpacer, { size: "mx", responsive: true }),
        getProfileData.isOrderJCardAvailable && (React.createElement(PrimaryButton, { type: 'button', disabled: !('address1' in getProfileData.mainAddress), text: t('OrderPhysicalCardsOrderButton'), loading: isLoading, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            setIsLoading(true);
                            return [4 /*yield*/, updateOrderJCard({}, { orderJCard: true })
                                    .then(function () {
                                    getProfile.refresh();
                                    setIsLoading(false);
                                })
                                    .catch(function (response) {
                                    showErrorNotification(response.globalMessage);
                                    setIsLoading(false);
                                })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); } })),
        React.createElement(Modal, { open: isOverlayOpen, title: l('ChangeMainAddress'), onClose: function () { return setIsOverlayOpen(false); } },
            React.createElement(MyAccountProfileMainAddressModalContent, __assign({ onClose: function () { return setIsOverlayOpen(false); } }, getProfileLabelsData)))));
};
