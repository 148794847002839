import { useCreatePortalInBody } from '@jelmoli/hooks/src/useCreatePortalInBody/useCreatePortalInBody';
import { useCssVariable } from '@jelmoli/hooks/src/useCssVariable/useCssVariable';
import { useIsVisibleInViewport } from '@jelmoli/hooks/src/useKeyboardEffect/useIsVisibleInViewport/useIsVisibleInViewport';
import classnames from 'classnames';
import React from 'react';
import { CSSTransition } from 'react-transition-group';
import './sticky-wrapper.scss';
export var StickyWrapper = function (props) {
    var _a = useIsVisibleInViewport(props.treshold || 0), isVisibleInViewport = _a.isVisibleInViewport, ref = _a.ref;
    return (React.createElement("div", { className: props.className, ref: ref },
        isVisibleInViewport !== undefined && (React.createElement(Sticky, { cssVariableName: props.cssVariableName, visible: !isVisibleInViewport }, props.stickyContent)),
        props.children));
};
var Sticky = function (_a) {
    var visible = _a.visible, children = _a.children, cssVariableName = _a.cssVariableName;
    var createBodyPortal = useCreatePortalInBody();
    var wrapperDivRef = useCssVariable(cssVariableName);
    return createBodyPortal(React.createElement("div", { className: "h-sticky-wrapper__sticky" },
        React.createElement(CSSTransition, { in: visible, timeout: 500, className: classnames('h-sticky-wrapper__animation', {
                'h-sticky-wrapper__animation--hidden': !visible,
            }), classNames: {
                enter: 'h-sticky-wrapper__animation--enter',
                enterActive: 'h-sticky-wrapper__animation--enter-active',
                exitActive: 'h-sticky-wrapper__animation--exit-active',
            } },
            React.createElement("div", { ref: wrapperDivRef }, children))));
};
