var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { bindTranslations } from '@jelmoli/i18n/index';
import { getCart } from '@jelmoli/sitecore-services/src/Cart/getCart/getCart';
import { ShippingMethodConstants, } from '@jelmoli/sitecore-services/src/Cart/getShippingMethods/getShippingMethods';
import { getCheckoutLabels } from '@jelmoli/sitecore-services/src/Checkout/getCheckoutLabels/getCheckoutLabels';
import { Checkbox } from '@jelmoli/ui-components/src/atoms/Checkbox/Checkbox';
import { FormFieldErrorMessage, FormFieldErrorMessageText, } from '@jelmoli/ui-components/src/atoms/FormFieldErrorMessage/FormFieldErrorMessage';
import { TextFieldWithFormSetting } from '@jelmoli/ui-components/src/atoms/TextFieldValidatable/TextFieldValidatable';
import { getLabelsFromFormFields } from '@jelmoli/ui-components/src/helpers/SitecoreTypes/SitecoreTypes';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import React, { useEffect } from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
export var ShippingMethodInstallationService = function (props) {
    var cartData = useGetEndPointLazy(getCart).value;
    var checkoutLabels = useGetEndPointLazy(getCheckoutLabels).value;
    useEffect(function () {
        if (props.isInstallationServiceSelected) {
            props.form.setValues(__assign(__assign({}, props.form.values), { shippingMethod: ShippingMethodConstants.installationService }));
        }
        else {
            props.form.setValues(__assign(__assign({}, props.form.values), { shippingMethod: ShippingMethodConstants.standard }));
            props.setIsSelectedService([]);
        }
    }, [props.isInstallationServiceSelected]);
    useEffect(function () {
        props.setIsSavePhone(props.isMobileAdded);
    }, [props.isMobileAdded]);
    useEffect(function () {
        if (checkoutLabels) {
            checkoutLabels.Mobile.fields.IsRequired = { value: props.isSavePhone };
        }
    }, [props.isSavePhone]);
    if (!cartData || !checkoutLabels || !props.installationService) {
        return null;
    }
    checkoutLabels.Mobile.fields.IsRequired = { value: props.isSavePhone };
    var t = bindTranslations(__assign(__assign({}, checkoutLabels), getLabelsFromFormFields(checkoutLabels)));
    var productsInstallationServiceOptional = cartData.lines.filter(function (line) { return line.fulfillmentFlags && line.fulfillmentFlags.installationServiceAvailable; });
    return (React.createElement(React.Fragment, null,
        React.createElement(Checkbox, { label: props.installationService.name, value: props.isInstallationServiceSelected, onChange: function () {
                props.setIsInstallationServiceSelected(!props.isInstallationServiceSelected);
            } }),
        React.createElement(VerticalSpacer, { size: "xl", responsive: false }),
        props.isInstallationServiceSelected && (React.createElement(React.Fragment, null,
            productsInstallationServiceOptional.map(function (product, i) {
                var priceLabel = product.quantity + "x " + product.displayName + " " + product.brandName + " (" + (props.installationService && props.installationService.price) + ") " + t('CheckoutPerProductLabel');
                var singleProductPriceLabel = product.displayName + " " + product.brandName + " (" + (props.installationService && props.installationService.price) + ")";
                return (React.createElement(React.Fragment, { key: product.productId + "_" + product.variantId },
                    React.createElement(Checkbox, { error: props.error && props.isSelectedService.length === 0, label: product.quantity > 1 ? priceLabel : singleProductPriceLabel, value: props.isSelectedService.some(function (item) { return item === product.cartLineId; }), onChange: function () {
                            var isSelected = props.isSelectedService.some(function (item) { return item === product.cartLineId; });
                            if (isSelected) {
                                props.setIsSelectedService(props.isSelectedService.filter(function (item) { return item !== product.cartLineId; }));
                                return;
                            }
                            props.setIsSelectedService(__spreadArrays(props.isSelectedService, [product.cartLineId]));
                        } }),
                    productsInstallationServiceOptional.length > i + 1 && (React.createElement(VerticalSpacer, { size: "xs", responsive: false }))));
            }),
            props.error && props.isSelectedService.length === 0 && (React.createElement(FormFieldErrorMessageText, { name: "installationService" },
                React.createElement(VerticalSpacer, { size: "xxs", responsive: false }),
                t('CheckoutDeliveryOptionsErrorInstallationService'))),
            React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
            props.installationService && (React.createElement(React.Fragment, null,
                React.createElement(Typography, { responsive: false, size: "s" }, t('CheckoutDeliveryOptionsEmailNotification')),
                React.createElement(VerticalSpacer, { size: "s", responsive: false }),
                React.createElement(Checkbox, { label: t('TelephoneContactRequest'), value: props.isSavePhone, onChange: function () { return props.setIsSavePhone(!props.isSavePhone); } }),
                React.createElement(VerticalSpacer, { size: "s", responsive: false }),
                props.isSavePhone ? (React.createElement(React.Fragment, null,
                    React.createElement(TextFieldWithFormSetting, __assign({ disabled: !props.isSavePhone, variant: "standard", type: "tel", name: "mobile", formSetting: checkoutLabels.Mobile, fullWidth: true }, props.form.fieldProps.mobile)),
                    React.createElement(FormFieldErrorMessage, { name: "mobile", verticalSpaceTop: "xxs" }))) : (React.createElement(TextFieldWithFormSetting, __assign({ disabled: !props.isSavePhone, variant: "standard", type: "tel", name: "mobile", formSetting: checkoutLabels.Mobile, fullWidth: true }, props.form.fieldProps.mobile))),
                React.createElement(VerticalSpacer, { size: "l", responsive: false }))))),
        React.createElement(Typography, { type: "medium", size: "xs", responsive: false }, t('CheckoutSummaryShippingLabel')),
        React.createElement(VerticalSpacer, { size: "mx", responsive: false })));
};
