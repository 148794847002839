var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { editAddress } from '@jelmoli/sitecore-services/src/Account/editAddress/editAddress';
import { isUserLoggedInLazy } from '@jelmoli/sitecore-services/src/Account/isUserLoggedIn/isUserLoggedInLazy';
import { subscribeNewsletter } from '@jelmoli/sitecore-services/src/Account/subscribeNewsletter/subscribeNewsletter';
import { createNewAccountFromOrder } from '@jelmoli/sitecore-services/src/Checkout/createNewAccountFromOrder/createNewAccountFromOrder';
import { trackRegistration } from '@jelmoli/sitecore-services/src/Tracking/Account/AccountTracking';
import { trackNewsletterRegistration } from '@jelmoli/sitecore-services/src/Tracking/Account/NewsletterTracking';
import { trackPurchase } from '@jelmoli/sitecore-services/src/Tracking/Checkout/PurchaseTracking';
import { trackError } from '@jelmoli/sitecore-services/src/Tracking/Error/ErrorTesting';
import { IcoBoxAlert } from '@jelmoli/ui-components/src/atoms/Icon/IcoBoxAlert';
import { showErrorNotification } from '@jelmoli/ui-components/src/atoms/Notification/Notification';
import { PrimaryButton } from '@jelmoli/ui-components/src/atoms/PrimaryButton/PrimaryButton';
import { HorizontalSpacer } from '@jelmoli/ui-components/src/helpers/HorizontalSpacer/HorizontalSpacer';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { Visibility } from '@jelmoli/ui-components/src/helpers/Visibility/Visibility';
import React, { useEffect, useState } from 'react';
export var ConfirmationComponentSignInSection = function (props) {
    // 'button' state - Renders the 'create account' button.
    // 'created' state - Account has been sucessfully created.
    // 'error' state - There was an error while trying to create the account.
    // 'loggedIn' state - User is already logged.
    // 'busy' state - The 'newaccount' method is being executed, and we're waiting for a response.
    var _a = useState('button'), showComponent = _a[0], setShowComponent = _a[1];
    var isLoggedIn = isUserLoggedInLazy();
    var onCreateAccount = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, createNewAccountFromOrder({}, {
                            email: props.order.orderInformation.email,
                            orderId: props.order.orderConfirmationId,
                        })
                            .then(function () {
                            trackRegistration({ success: true });
                            if (props.order.orderInformation && props.order.orderInformation.newsletter) {
                                trackNewsletterRegistration(true);
                            }
                            return setShowComponent('created');
                        })
                            .catch(function (response) {
                            trackError({
                                page: 'Error occured in creation of the account on Checkout Confirmation page',
                                errorMessage: response.GlobalMessage,
                            });
                            trackRegistration({ success: false });
                            showErrorNotification(response.GlobalMessage);
                            return setShowComponent('error');
                        })];
                case 1:
                    _b.sent();
                    return [3 /*break*/, 3];
                case 2:
                    _a = _b.sent();
                    return [2 /*return*/, setShowComponent('error')];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    // Save address if user has made that choice in checkout
    var onSaveAddress = function (orderInformation) { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 5, , 6]);
                    if (!orderInformation.saveBillingAddress) return [3 /*break*/, 2];
                    return [4 /*yield*/, editAddress({}, __assign(__assign({}, orderInformation.billingAddress), { company: orderInformation.billingAddress.company || '', salutation: orderInformation.billingAddress.salutationCode, country: orderInformation.billingAddress.countryCode, address2: orderInformation.billingAddress.address2 || '', standardBilling: false, standardDelivery: false })).catch(function () {
                            return trackError({
                                page: 'Error occured while saving the billing address on Checkout Confirmation page',
                                errorMessage: 'Ups, error occures',
                            });
                        })];
                case 1:
                    _b.sent();
                    _b.label = 2;
                case 2:
                    if (!(!orderInformation.sameAsBillingAddress &&
                        orderInformation.saveShippingAddress &&
                        orderInformation.shippingAddress)) return [3 /*break*/, 4];
                    return [4 /*yield*/, editAddress({}, __assign(__assign({}, orderInformation.shippingAddress), { company: orderInformation.shippingAddress.company || '', salutation: orderInformation.shippingAddress.salutationCode, country: orderInformation.shippingAddress.countryCode, address2: orderInformation.shippingAddress.address2 || '', standardBilling: false, standardDelivery: false })).catch(function () {
                            return trackError({
                                page: 'Error occured while saving the shipping address Checkout Confirmation page',
                                errorMessage: 'Ups, error occured',
                            });
                        })];
                case 3:
                    _b.sent();
                    _b.label = 4;
                case 4: return [3 /*break*/, 6];
                case 5:
                    _a = _b.sent();
                    return [2 /*return*/, setShowComponent('error')];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    // Subscribe user to newsletter if user has made that choice in checkout
    var saveNewsletter = function (orderInformation) { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, , 4]);
                    if (!orderInformation.newsletter) return [3 /*break*/, 2];
                    return [4 /*yield*/, subscribeNewsletter({}, { newsletter: orderInformation.newsletter })
                            .then(function () {
                            trackNewsletterRegistration(true);
                        })
                            .catch(function (response) {
                            trackNewsletterRegistration(false);
                            trackError({
                                page: 'Error occured while subscibsion to the newsletter on Checkout Confirmation page',
                                errorMessage: response.GlobalMessage,
                            });
                            showErrorNotification(response.GlobalMessage);
                        })];
                case 1:
                    _b.sent();
                    _b.label = 2;
                case 2: return [3 /*break*/, 4];
                case 3:
                    _a = _b.sent();
                    return [2 /*return*/, setShowComponent('error')];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var sendAnalytic = function (orderData) {
        var _a;
        var sentIds = localStorage.getItem('sentAnalyticOrderIds');
        if (!sentIds) {
            var data = (_a = {}, _a[orderData.orderConfirmationId] = true, _a);
            localStorage.setItem('sentAnalyticOrderIds', JSON.stringify(data));
            trackPurchase(orderData);
            return;
        }
        var parsedData = JSON.parse(sentIds);
        if (!parsedData[orderData.orderConfirmationId]) {
            parsedData[orderData.orderConfirmationId] = true;
            localStorage.setItem('sentAnalyticOrderIds', JSON.stringify(parsedData));
            trackPurchase(orderData);
            return;
        }
    };
    // Executed only once, when user lands on the page.
    useEffect(function () {
        // We don't want to have "flickering" on the page,
        // so we set the state to something which won't render anything ('busy' state)
        if (props.order.orderInformation.createAccount) {
            setShowComponent('busy');
            onCreateAccount();
        }
        // IF logged in user is displayed
        if (isUserLoggedInLazy()) {
            setShowComponent('loggedIn');
            if (props.order.orderInformation) {
                onSaveAddress(props.order.orderInformation);
                saveNewsletter(props.order.orderInformation);
            }
        }
        sendAnalytic(props.order);
    }, []);
    {
        /* Hided because of the JELMOLI2025-5582 ticket */
    }
    return (React.createElement(React.Fragment, null));
};
var AccountAlreadyCreated = function (props) { return (React.createElement(React.Fragment, null,
    React.createElement(VerticalSpacer, { size: "l" }),
    React.createElement(Typography, { size: "s", type: "medium", tag: "p", responsive: false }, props.setPasswordLabel),
    React.createElement(Typography, { size: "s", responsive: false }, props.descriptionLabel))); };
var CreateAccount = function (props) { return (React.createElement(React.Fragment, null,
    React.createElement(VerticalSpacer, { size: "l" }),
    React.createElement(Typography, { size: "s", type: "medium", tag: "p", responsive: false }, props.titleLabel),
    React.createElement(Typography, { size: "s", responsive: false }, props.descriptionLabel),
    React.createElement(VerticalSpacer, { size: "s" }),
    React.createElement(React.Fragment, null,
        React.createElement(Visibility, { show: ['xs', 'sm'] },
            React.createElement(PrimaryButton, { fullWidth: true, onClick: props.onCreateAccount, type: "button", text: props.buttonLabel })),
        React.createElement(Visibility, { hide: ['xs', 'sm'] },
            React.createElement(PrimaryButton, { onClick: props.onCreateAccount, type: "button", text: props.buttonLabel }))))); };
var CreateAccountFailedNotificationBox = function (props) { return (React.createElement(React.Fragment, null,
    React.createElement(VerticalSpacer, { size: "l" }),
    React.createElement("div", { className: "feat-confirmation__create-account-failed-notification" },
        React.createElement(VerticalSpacer, { size: "m", responsive: false }),
        React.createElement("div", { className: "feat-confirmation__create-account-failed-notification-text-wrapper" },
            React.createElement(IcoBoxAlert, { width: 42, keepOriginalColors: true }),
            React.createElement(HorizontalSpacer, { size: "s", responsive: false }),
            React.createElement("div", { className: "feat-confirmation__create-account-failed-notification-text" },
                React.createElement(Typography, { size: "xs", responsive: false }, props.notificationTextLabel))),
        React.createElement(VerticalSpacer, { size: "m", responsive: false })),
    React.createElement(VerticalSpacer, { size: "l", responsive: false }))); };
