import { track } from '@jelmoli/google-tag-manager';
export function proudctDetailImpressionTracking(_a) {
    var variant = _a.variant, colorSwitch = _a.colorSwitch, sizeSwitch = _a.sizeSwitch, availability = _a.availability;
    var selectedSize = sizeSwitch && sizeSwitch.find(function (size) { return size.variantId === variant.variantId; });
    var selectedColor = colorSwitch && colorSwitch.find(function (colour) { return colour.variantId === variant.variantId; });
    track({
        event: 'EECproductDetailView',
        ecommerce: {
            currencyCode: ('currentPrice' in variant && variant.currentPrice && variant.currentPrice.split(' ')[0]) || '',
            detail: {
                actionField: { list: '' },
                products: [
                    {
                        // Product ID --> Mandatory
                        id: variant.baseId,
                        name: variant.name || '',
                        brand: variant.brand || '',
                        price: ('currentPrice' in variant &&
                            variant.currentPrice &&
                            parseFloat(variant.currentPrice.split(' ')[1].replace("'", ''))) ||
                            undefined,
                        category: variant.category || '',
                        variant: variant.variantId,
                        position: 0,
                        dimension33: '',
                        dimension34: '',
                        dimension55: (!!variant.originalPrice).toString(),
                        dimension58: (selectedColor && selectedColor.colorName) || '',
                        dimension59: (selectedSize && selectedSize.size) || '',
                        dimension60: variant.attributesValue && 'val_MATERIAL' in variant.attributesValue
                            ? variant.attributesValue.val_MATERIAL.values
                                .map(function (material) { return material.displayName; })
                                .join()
                            : '',
                        dimension61: availability ? availability.toString(10) : '0',
                    },
                ],
            },
        },
    });
}
