var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { track } from '@jelmoli/google-tag-manager';
import { getCart } from '../../Cart/getCart/getCart';
export function trackCheckoutStepComplete(props) {
    return __awaiter(this, void 0, void 0, function () {
        var cartValue, vouchers;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getCart({})];
                case 1:
                    cartValue = _a.sent();
                    vouchers = cartValue.vouchers;
                    track({
                        event: 'EECcheckout',
                        ecommerce: {
                            currencyCode: cartValue.lines.length > 0 && cartValue.lines[0].lineTotal
                                ? cartValue.lines[0].lineTotal.split(' ')[0]
                                : '',
                            list: '',
                            checkout: {
                                actionField: {
                                    step: props.step,
                                    option: props.name,
                                },
                                products: cartValue.lines.map(function (product) { return ({
                                    /** Product Id -> Mandatory */
                                    id: product.productId,
                                    name: product.displayName,
                                    brand: product.brandName || '',
                                    variant: product.variantId,
                                    price: product.lineTotal ? parseFloat(product.lineTotal.split(' ')[1].replace("'", '')) : undefined,
                                    quantity: product.quantity,
                                    dimension33: cartValue.deliveryOption ? getDeliveryMethod(cartValue.deliveryOption) : '',
                                    dimension34: cartValue.deliveryOption ? calculateTimeToDelivery(cartValue.deliveryOption) : '',
                                    dimension35: cartValue.paymentMethod ? cartValue.paymentMethod.paymentMethodDisplayName : '',
                                    dimension36: vouchers && vouchers.length > 0 ? vouchers[0].voucherCode : '',
                                    dimension37: vouchers && vouchers.length > 0 ? vouchers[0].label : '',
                                    dimension38: '',
                                    dimension40: cartValue.uniqueId || '',
                                    dimension51: product.category,
                                    dimension55: (!!product.originalLineTotal).toString(),
                                    dimension58: product.color.value,
                                    dimension59: product.size.value,
                                    dimension60: product.materials && product.materials.length > 0
                                        ? product.materials.map(function (item) { return item; }).join('/')
                                        : '',
                                    dimension61: product.labels.map(function (item) { return item.label; }).join('/') || '',
                                }); }),
                            },
                        },
                    });
                    return [2 /*return*/];
            }
        });
    });
}
function getDeliveryMethod(deliveryMethod) {
    return 'shippingMethodDisplayName' in deliveryMethod ? deliveryMethod.shippingMethodDisplayName : '';
}
function calculateTimeToDelivery(deliveryOptions) {
    switch (deliveryOptions.deliveryOptionExternalId) {
        case '0e47aa36-7814-4ed7-8d19-49dc9b319979':
            // code block click and collect
            return undefined;
        case '3817f8d5-994b-4fbc-8bbe-4c342ec7553a':
            // code block  home
            switch (deliveryOptions.shippingMethodExternalId) {
                case 'e50832c3-929e-43b3-9b9b-42704f71c249':
                    var today = new Date();
                    var dd = String(today.getDate()).padStart(2, '0');
                    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                    var yyyy = today.getFullYear();
                    var todayString = mm + "." + dd + "." + yyyy;
                    return dateDiff(parseDate(todayString), parseDate(deliveryOptions.scheduledDeliveryDay)).toString(10);
                case '9b3c1129-09bd-49ea-85d9-c7ce31e734ce':
                    return '1 day';
                case 'cf0af82a-e1b8-45c2-91db-7b9847af287c':
                default:
                    return '3-5 days';
            }
        case '50cb2500-ca29-4132-9f2e-81ddc5403c5d':
            // code block post
            return undefined;
        case '3878b502-85ac-41b5-9203-e0f8712a854b':
            // code block digital
            return undefined;
        default:
            return undefined;
    }
}
function parseDate(str) {
    var mdy = str.split('.');
    return new Date(mdy[2], mdy[1], mdy[0] - 1);
}
function dateDiff(first, second) {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
}
