var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { track } from '@jelmoli/google-tag-manager';
import { getDeliveryOptions } from '../../Cart/getDeliveryOptions/getDeliveryOptions';
export function trackPurchase(order) {
    return __awaiter(this, void 0, void 0, function () {
        var deliveryOptions, vouchers, priceReduction, shippingTotal;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getDeliveryOptions({})];
                case 1:
                    deliveryOptions = _a.sent();
                    vouchers = order.vouchers;
                    priceReduction = order.lines && order.lines.some(function (line) { return line.originalLineTotal; });
                    shippingTotal = order.shippingTotal && order.shippingTotal.indexOf(order.total.split(' ')[0]) !== -1
                        ? order.shippingTotal.split(' ')[1].replace("'", '')
                        : order.shippingTotal;
                    track({
                        event: 'EECpurchase',
                        ecommerce: {
                            currencyCode: order.total ? order.total.split(' ')[0] : '',
                            purchase: {
                                actionField: {
                                    /** Transaction Id (transaction id of giftcard and also from datatrans is something private and not for analytics) -> mandatory */
                                    // use orderConfirmationId instead
                                    id: order.orderConfirmationId,
                                    revenue: order.total ? order.total.split(' ')[1].replace("'", '') : undefined,
                                    shipping: shippingTotal,
                                    coupon: vouchers && vouchers.length > 0 ? vouchers[0].voucherCode : '',
                                    tax: '0',
                                    affiliation: '',
                                },
                                products: order.lines
                                    ? order.lines.map(function (product) { return ({
                                        name: product.displayName,
                                        id: product.productId,
                                        price: product.lineTotal
                                            ? parseFloat(product.lineTotal.split(' ')[1].replace("'", ''))
                                            : undefined,
                                        brand: product.brandName || '',
                                        variant: product.variantId,
                                        quantity: product.quantity,
                                        coupon: product.discounts.length > 0
                                            ? product.discounts.map(function (item) { return item.label; }).join('/')
                                            : '',
                                        category: product.category || '',
                                        dimension51: product.category || '',
                                        dimension58: product.color.value,
                                        dimension59: product.size.value,
                                        dimension60: product.materials && product.materials.length > 0
                                            ? product.materials.map(function (item) { return item; }).join('/')
                                            : '',
                                    }); })
                                    : [],
                            },
                        },
                        shippingType: order.deliveryOption
                            ? getShippingType({
                                orderDeliveryOptionId: order.deliveryOption.deliveryOptionExternalId,
                                deliveryOptions: deliveryOptions,
                            })
                            : '',
                        shippingMethod: order.deliveryOption ? showShippingMethod(order.deliveryOption) : '',
                        priceReduction: (!!priceReduction).toString(),
                        couponName: vouchers && vouchers.length > 0 ? vouchers[0].label : '',
                        productCouponCode: vouchers && vouchers.length > 0 ? vouchers[0].voucherCode : '',
                        couponValue: '',
                        paymentMethod: order.paymentMethod ? order.paymentMethod.paymentMethodDisplayName : '',
                    });
                    return [2 /*return*/];
            }
        });
    });
}
function showShippingMethod(deliveryOption) {
    if (deliveryOption && 'locationDisplayName' in deliveryOption) {
        return deliveryOption.locationDisplayName;
    }
    if (!('shippingMethodDisplayName' in deliveryOption)) {
        return 'Digital';
    }
    return deliveryOption.shippingMethodDisplayName;
}
function getShippingType(_a) {
    var orderDeliveryOptionId = _a.orderDeliveryOptionId, deliveryOptions = _a.deliveryOptions;
    var selectedOption = deliveryOptions.find(function (option) { return option.externalId === orderDeliveryOptionId; });
    return (selectedOption && selectedOption.name) || '';
}
