import { track } from '@jelmoli/google-tag-manager';
export function trackLogin(props) {
    track({
        event: 'gaEvent',
        event_name: 'login_success',
        login_success: {
            status: props.success ? 'success' : 'fail',
            event_category: 'account',
            event_action: 'login',
            event_label: props.success ? 'success' : 'fail',
            loginMethod: props.loginMethod,
        },
    });
}
export function trackLogout() {
    track({
        event: 'gaEvent',
        event_name: 'logout',
        logout: {
            event_category: 'account',
            event_action: 'logout',
            event_label: undefined,
        },
    });
}
export function trackRegistration(props) {
    track({
        event: 'gaEvent',
        event_name: 'registration',
        registration: {
            status: props.success ? 'success' : 'fail',
            event_category: 'account',
            event_action: 'registration',
            event_label: props.success ? 'success' : 'fail',
        },
    });
}
