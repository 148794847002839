var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useClickOutsideOfElement } from '@jelmoli/hooks/src/useClickOutsideOfElement/useClickOutsideOfElement';
import { useIsVisibleInViewport } from '@jelmoli/hooks/src/useKeyboardEffect/useIsVisibleInViewport/useIsVisibleInViewport';
import { usePath } from '@jelmoli/hooks/src/usePath/usePath';
import { trackNavigation } from '@jelmoli/sitecore-services/src/Tracking/Navigation/NavigationTracking';
import { ChevronLeftIcon } from '@jelmoli/ui-components/src/atoms/Icon/ChevronLeftIcon';
import { SeoLink } from '@jelmoli/ui-components/src/atoms/SEOLink/SeoLink';
import { preventOutline } from '@jelmoli/ui-components/src/helpers/A/A';
import { HorizontalScrollWrapper } from '@jelmoli/ui-components/src/helpers/HorizontalScrollWrapper/HorizontalScrollWrapper';
import { StickyWrapper } from '@jelmoli/ui-components/src/helpers/StickyWrapper/StickyWrapper';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { Visibility } from '@jelmoli/ui-components/src/helpers/Visibility/Visibility';
import { DesktopShopMainNavigation } from '@jelmoli/ui-components/src/organism/DesktopShopMainNavigation/DesktopShopMainNavigation';
import { Link as SitecoreLink } from '@sitecore-jss/sitecore-jss-react';
import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { ShopHeaderSearchComponent } from '../../Website/ShopHeaderSearchComponent/ShopHeaderSearchComponent';
import { ShopHeaderCollapsedComponent } from '../ShopHeaderCollapsedComponent/ShopHeaderCollapsedComponent';
import './shop-header-navigation.scss';
export var ShopHeaderNavigationComponent = function (props) {
    var _a;
    var _b = useState(false), isFlyoutOpen = _b[0], setIsFlyoutOpen = _b[1];
    var _c = useState({}), loadFlyoutContent = _c[0], setLoadFlyoutContent = _c[1];
    var _d = useState(''), activeNavLink = _d[0], setActiveNavLink = _d[1];
    var _e = useState(''), activeNavigationPath = _e[0], setActiveNavigationPath = _e[1];
    var _f = useState(false), hideSearchField = _f[0], setHideSearchField = _f[1];
    var urlPath = usePath();
    var navigationRef = useRef(null);
    var leftOffset = (_a = navigationRef.current) === null || _a === void 0 ? void 0 : _a.offsetLeft;
    var isVisibleInViewport = useIsVisibleInViewport(1, navigationRef).isVisibleInViewport;
    var ref = useClickOutsideOfElement(function (path) {
        if (navigationRef.current && path.indexOf(navigationRef.current) === -1) {
            setIsFlyoutOpen(false);
            setActiveNavLink('');
        }
    });
    useEffect(function () {
        if (isVisibleInViewport) {
            leftOffset = 0;
        }
    }, [isVisibleInViewport]);
    useEffect(function () {
        if (window) {
            var activePath = "/" + window.location.pathname.split('/')[1];
            setActiveNavigationPath(activePath);
        }
    }, []);
    useEffect(function () {
        var shouldHIdeSearchField = /search/g.test(urlPath.toLowerCase()) || /my-account/g.test(urlPath.toLowerCase());
        if (shouldHIdeSearchField) {
            setHideSearchField(true);
        }
        else {
            setHideSearchField(false);
        }
    }, [urlPath]);
    var createArrowElement = function (direction) {
        return (React.createElement("div", { className: "feat-shop-header__navigation__arrow feat-shop-header__navigation__arrow--" + direction },
            React.createElement("div", { className: "feat-shop-header__navigation-icon-wrapper" },
                React.createElement(ChevronLeftIcon, { height: 14 }))));
    };
    var handleNavigationObjClick = function (event, navigationObj) {
        event.preventDefault();
        event.stopPropagation();
        window.localStorage.setItem('path', navigationObj.href);
        trackNavigation({
            currentLevel: 'Main Navigation',
            trackLabel: navigationObj.text,
        });
        var clickOnActiveFlyout = navigationObj.text === activeNavLink;
        if (!clickOnActiveFlyout && navigationObj.flyoutItems && navigationObj.flyoutItems[0]) {
            event.preventDefault();
            setActiveNavLink(navigationObj.text);
            setIsFlyoutOpen(true);
        }
        if (clickOnActiveFlyout) {
            setIsFlyoutOpen(!isFlyoutOpen);
        }
    };
    var renderSeoLinks = function (item) {
        return item.flyoutItems.map(function (flyoutItem, index) {
            return (React.createElement("span", { key: index },
                React.createElement(SeoLink, { linkHref: flyoutItem.titleLink, linkText: flyoutItem.title }),
                flyoutItem.links.map(function (link, i) { return (React.createElement(SeoLink, { key: i, linkHref: link.href, linkText: link.text })); })));
        });
    };
    var handleHover = function (navigationObj) {
        if (navigationObj.flyoutItems && navigationObj.flyoutItems[0]) {
            var loadedFlyouts = __assign({}, loadFlyoutContent);
            loadedFlyouts["" + navigationObj.text] = true;
            setLoadFlyoutContent(loadedFlyouts);
        }
    };
    return (React.createElement("div", { className: "feat-shop-header__navigation-background" },
        props.fields.items && props.fields.items.map(function (item) { return renderSeoLinks(item); }),
        React.createElement("div", { className: "feat-shop-header__navigation" },
            React.createElement(Visibility, { className: "feat-shop-header__navigation__items-container", hide: ['xs', 'sm', 'md'] },
                React.createElement(HorizontalScrollWrapper, __assign({ leftArrow: createArrowElement('left'), rightArrow: createArrowElement('right'), fullWidth: true, checkArrowsOnlyOnce: true }, (leftOffset && {
                    scrollTo: leftOffset,
                })), props.fields.items &&
                    props.fields.items.map(function (navigationObj, index) {
                        return (React.createElement("div", __assign({ className: classnames('feat-shop-header__navigation-item', isFlyoutOpen &&
                                navigationObj.text === activeNavLink &&
                                'feat-shop-header__navigation-item--open', {
                                'feat-shop-header__navigation-item--active': activeNavigationPath === navigationObj.href,
                                'feat-shop-header__navigation-item__first': index === 0,
                            }), key: index, onClick: function (event) { return handleNavigationObjClick(event, navigationObj); }, onMouseEnter: function () { return handleHover(navigationObj); } }, (activeNavigationPath === navigationObj.href ||
                            activeNavLink === navigationObj.text
                            ? { ref: navigationRef }
                            : '')),
                            React.createElement("div", { className: "feat-shop-header__navigation-item-effect" },
                                React.createElement(Typography, { type: "medium", size: "s", responsive: false, uppercase: true },
                                    React.createElement(SitecoreLink, __assign({ field: navigationObj, className: "feat-shop-header__navigation-item-link" }, preventOutline()))))));
                    }))),
            hideSearchField ? (React.createElement(Visibility, { hide: ['xs', 'sm', 'md'] },
                React.createElement(ShopHeaderSearchComponent, { closeFlyout: function () { return setIsFlyoutOpen(false); }, searchText: props.fields.searchText }))) : (React.createElement(ShopHeaderSearchComponent, { closeFlyout: function () { return setIsFlyoutOpen(false); }, searchText: props.fields.searchText }))),
        React.createElement(StickyWrapper, { stickyContent: React.createElement(ShopHeaderCollapsedComponent, { items: props.fields.items, headerLocation: props.fields.headerLocation, searchText: props.fields.searchText }), cssVariableName: "--sticky-header-height" },
            React.createElement(Visibility, { hide: ['xs', 'sm', 'md'] }, props.fields.items &&
                props.fields.items.map(function (item, index) {
                    if (loadFlyoutContent[item.text]) {
                        var isOpen = (item.text === activeNavLink && isFlyoutOpen) || false;
                        return (React.createElement("div", { ref: ref, key: index },
                            React.createElement(DesktopShopMainNavigation, { isOpen: isOpen, closeNavigation: function () { return setIsFlyoutOpen(false); }, flyoutItems: item.flyoutItems, allItemsText: item.allItemsText, flyoutTeaser: item.teaser, firstLevelNavText: item.text, allItemsLink: item.href })));
                    }
                }))),
        React.createElement(Visibility, { show: ['xs', 'sm', 'md'] },
            React.createElement(VerticalSpacer, { size: "m", hide: ['lg', 'xl'] }))));
};
