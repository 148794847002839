import { getUserLanguage } from '@jelmoli/sitecore-services/src/Account/getUserLanguage/getUserLanguage';
import React, { useEffect, useState } from 'react';
import { A } from '../../helpers/A/A';
/** Displays a button to add a voucher to your mobile wallet
 *  @see https://developer.apple.com/wallet/
 */
export var WalletButton = function (props) {
    var appleWalletImageUrl = useLocalizedAppleWalletLogo();
    var renderFunction = props.children || (function (walletButton) { return walletButton; });
    return (React.createElement(React.Fragment, null, appleWalletImageUrl &&
        renderFunction(React.createElement(A, { "aria-label": "apple wallet button", className: "a-wallet-button", href: props.appleWalletLink, onMouseDown: preventFocusOutline },
            React.createElement("img", { alt: "Apple Wallet", className: "a-wallet-button__image", src: appleWalletImageUrl, loading: "lazy" })))));
};
var preventFocusOutline = function (e) {
    e.preventDefault();
};
/** Mapping for the svgs from https://developer.apple.com/wallet/ */
var appleWalletSvgMapping = {
    de: require('./Add-to-Apple-Wallet/Add to Apple Wallet Badges/DE/web_092815/Add_to_Apple_Wallet_rgb_DE.svg'),
    en: require('./Add-to-Apple-Wallet/Add to Apple Wallet Badges/US UK/web/Add_to_Apple_Wallet_rgb_US-UK.svg'),
};
var useLocalizedAppleWalletLogo = function () {
    var _a = useState(), url = _a[0], setUrl = _a[1];
    useEffect(function () {
        if (typeof document === 'undefined') {
            return;
        }
        // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
        // Why not MSStream
        // Microsoft injected the word iPhone in IE11's userAgent in order to try and fool Gmail somehow.
        // Therefore we need to exclude it.
        var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !('MSStream' in window);
        if (!isIOS) {
            return;
        }
        setUrl(appleWalletSvgMapping[getUserLanguage()]);
    }, []);
    return url;
};
