import { bindTranslations } from '@jelmoli/i18n';
import { getCart } from '@jelmoli/sitecore-services/src/Cart/getCart/getCart';
import { getCheckoutLabels } from '@jelmoli/sitecore-services/src/Checkout/getCheckoutLabels/getCheckoutLabels';
import { PrimaryButton } from '@jelmoli/ui-components/src/atoms/PrimaryButton/PrimaryButton';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import React from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
export var CheckoutStepDeliveryOptionsDigital = function (props) {
    var labels = useGetEndPointLazy(getCheckoutLabels).value;
    var cart = useGetEndPointLazy(getCart).value;
    if (!props.visible || !cart || !labels) {
        return null;
    }
    var t = bindTranslations(labels);
    var email = cart.orderInformation && cart.orderInformation.email;
    return (React.createElement(React.Fragment, null,
        React.createElement(Typography, { size: "s", responsive: false }, t('CheckoutDeliveryOptionDigitalLabel', { email: email || '' })),
        React.createElement(VerticalSpacer, { size: "xxl", responsive: false }),
        React.createElement(PrimaryButton, { onClick: props.onComplete, type: "button", size: "tiny", text: t('CheckoutStepperSaveStepCtaButtonText') })));
};
