var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { bindTranslations } from '@jelmoli/i18n/index';
import { FormValidationContext } from 'nested-form-validation';
import React from 'react';
import { FormFieldErrorMessage } from '../../atoms/FormFieldErrorMessage/FormFieldErrorMessage';
import { PasswordStrengthFieldWithHints } from '../../atoms/PasswordStrengthField/PasswordStrengthField';
import { TextFieldWithFormSetting } from '../../atoms/TextFieldValidatable/TextFieldValidatable';
import { VerticalSpacer } from '../../helpers/VerticalSpacer/VerticalSpacer';
import { NameForm } from '../NameForm/NameForm';
import './registration-form.scss';
export var RegistrationForm = function (_a) {
    var _b, _c;
    var fields = _a.fields, fieldValues = _a.fieldValues;
    var t = bindTranslations(fields);
    return (React.createElement(FormValidationContext, null,
        React.createElement(NameForm, { fields: fields, fieldValues: fieldValues }),
        React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
        React.createElement(TextFieldWithFormSetting, __assign({ variant: "standard", name: "email", type: "email", formSetting: fields.Email }, fieldValues.email, { fullWidth: true })),
        React.createElement(FormFieldErrorMessage, { name: "email", verticalSpaceTop: "xxs" }),
        React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
        React.createElement(PasswordStrengthFieldWithHints, __assign({ fullWidth: true, name: "password", variant: "standard", hints: {
                introductionText: fields.Password.fields.IntroductionText.value,
                minimumNumberOfCharactersRule: (_b = fields.Password.fields.MinLengthError) === null || _b === void 0 ? void 0 : _b.value,
                letterRule: fields.Password.fields.LetterRule.value,
                numberRule: fields.Password.fields.NumberRule.value,
                specialCharacterRule: fields.Password.fields.SpecialCharacterRule.value,
            }, minNumberCharacters: (_c = fields.Password.fields.MinLength) === null || _c === void 0 ? void 0 : _c.value, label: fields.Password.fields.FieldLabel.value }, fieldValues.password)),
        React.createElement(VerticalSpacer, { size: "xl", responsive: false })));
};
