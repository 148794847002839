var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Column, Row } from '@jelmoli/bootstrap-grid';
import { isUserLoggedInLazy } from '@jelmoli/sitecore-services/src/Account/isUserLoggedIn/isUserLoggedInLazy';
import { addBrandToFavoritesEndpoint } from '@jelmoli/sitecore-services/src/Brand/addBrandToFavorites/addBrandToFavorites';
import { getAllBrandsEndpoint } from '@jelmoli/sitecore-services/src/Brand/getAllBrands/getAllBrands';
import { getFavoritesEndpoint } from '@jelmoli/sitecore-services/src/Brand/getFavorites/getFavorites';
import { removeBrandFromFavoritesEndpoint } from '@jelmoli/sitecore-services/src/Brand/removeBrandFromFavorites/removeBrandFromFavorites';
import { trackAddBrand, trackRemoveBrand } from '@jelmoli/sitecore-services/src/Tracking/Account/PreferencesTracking';
import { trackError } from '@jelmoli/sitecore-services/src/Tracking/Error/ErrorTesting';
import { BookmarkIcon } from '@jelmoli/ui-components/src/atoms/Icon/BookmarkIcon';
import { InteractiveIcon } from '@jelmoli/ui-components/src/atoms/InteractiveIcon/InteractiveIcon';
import { showErrorNotification, showSuccessNotification, } from '@jelmoli/ui-components/src/atoms/Notification/Notification';
import { A } from '@jelmoli/ui-components/src/helpers/A/A';
import { BaseImage } from '@jelmoli/ui-components/src/helpers/BaseImage/BaseImage';
import { Container } from '@jelmoli/ui-components/src/helpers/Container/Container';
import { HorizontalSpacer } from '@jelmoli/ui-components/src/helpers/HorizontalSpacer/HorizontalSpacer';
import { hoverAnchorClassName } from '@jelmoli/ui-components/src/helpers/HoverAnchor/HoverAnchor';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { Visibility } from '@jelmoli/ui-components/src/helpers/Visibility/Visibility';
import { waitForLogin } from '@jelmoli/ui-components/src/organism/LoginDrawer/LoginDrawer';
import classnames from 'classnames';
import React from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
import './brand-logo.scss';
export var BrandLogoComponent = function (props) {
    return (React.createElement(Container, { size: "s", backgroundFill: true },
        React.createElement(Row, null,
            React.createElement(Column, { size: 12 },
                React.createElement("div", { className: "a-brand-logo" },
                    React.createElement("hr", { className: "a-brand-logo__horizontal-line" }),
                    React.createElement(HorizontalSpacer, { size: "s" }),
                    props.fields.logoImageUrl && (React.createElement(React.Fragment, null,
                        React.createElement(BaseImage, { className: "a-brand-logo__image", editable: false, value: {
                                src: props.fields.logoImageUrl,
                                alt: props.fields.brandName || '',
                            } }),
                        React.createElement(HorizontalSpacer, { size: "s" }))),
                    React.createElement("div", { className: "a-brand-logo__after-logo" },
                        React.createElement("hr", { className: "a-brand-logo__horizontal-line" }),
                        React.createElement(HorizontalSpacer, { size: "xs" }),
                        React.createElement(Bookmark, { bookmarkTextAdd: props.fields.bookmarkTextAdd, bookmarkTextRemove: props.fields.bookmarkTextRemove, brandId: props.fields.brandId }))),
                React.createElement(VerticalSpacer, { size: "xl" })))));
};
var Bookmark = function (_a) {
    var bookmarkTextAdd = _a.bookmarkTextAdd, bookmarkTextRemove = _a.bookmarkTextRemove, brandId = _a.brandId;
    var isLogedIn = isUserLoggedInLazy();
    var favBrandsIds = useGetEndPointLazy(getFavoritesEndpoint, {}, isLogedIn).value;
    var isBrandFavorite = favBrandsIds ? !!favBrandsIds.entries[brandId] : false;
    return (React.createElement(A, { role: "button", tabIndex: 0, className: classnames('a-brand-logo__bookmark', hoverAnchorClassName), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
            var allBrands, brand;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!isLogedIn) return [3 /*break*/, 6];
                        return [4 /*yield*/, getAllBrandsEndpoint({})];
                    case 1:
                        allBrands = _a.sent();
                        brand = allBrands.items.find(function (item) { return item.id === brandId; });
                        if (!!isBrandFavorite) return [3 /*break*/, 3];
                        trackAddBrand(brand ? brand.title : '');
                        return [4 /*yield*/, addBrandToFavoritesEndpoint({}, { brandId: brandId })
                                .then(function (response) {
                                showSuccessNotification(response.globalMessage);
                            })
                                .catch(function (response) {
                                trackError({
                                    page: 'Error occured during removal of the brand on brand whildcard page',
                                    errorMessage: response.GlobalMessage,
                                });
                                showErrorNotification(response.GlobalMessage);
                            })];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        trackRemoveBrand(brand ? brand.title : '');
                        return [4 /*yield*/, removeBrandFromFavoritesEndpoint({ id: brandId })
                                .then(function (response) {
                                showSuccessNotification(response.globalMessage);
                            })
                                .catch(function (response) {
                                trackError({
                                    page: 'Error occured during removal of the brand on brand whildcard page',
                                    errorMessage: response.GlobalMessage,
                                });
                                showErrorNotification(response.GlobalMessage);
                            })];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 8];
                    case 6: return [4 /*yield*/, waitForLogin()];
                    case 7:
                        _a.sent();
                        _a.label = 8;
                    case 8: return [2 /*return*/];
                }
            });
        }); } },
        React.createElement(Visibility, { hide: "xs" },
            React.createElement("div", { className: "a-brand-logo__label-wrapper" },
                React.createElement(Typography, { responsive: false, type: "medium", size: "xs", field: isBrandFavorite ? { value: bookmarkTextRemove } : { value: bookmarkTextAdd } }))),
        React.createElement(InteractiveIcon, { areaLabelText: "Toogle is brand favorite", tag: "span", active: isBrandFavorite, width: 30 },
            React.createElement(BookmarkIcon, { height: 20 }))));
};
