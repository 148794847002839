var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import classnames from 'classnames';
import React from 'react';
import { Checkbox } from '../Checkbox/Checkbox';
import { ChevronDownIcon } from '../Icon/ChevronDownIcon';
import './filter-dropdown.scss';
export var FilterDropdown = function (_a) {
    var onChange = _a.onChange, value = _a.value, isActive = _a.isActive, disabled = _a.disabled, small = _a.small, children = _a.children;
    return (React.createElement(Select, { value: value, MenuProps: { elevation: 1, classes: { paper: 'a-filter-dropdown__flyout' } }, disabled: disabled, inputProps: { isActive: isActive, small: small }, input: React.createElement(FilterDropdownInputBase, null), onChange: onChange }, children));
};
/** An option of the FilterDropdown */
export var FilterDropdownItem = React.forwardRef(function (props, ref) {
    var customStyles = { style: {} };
    if (props.hidden) {
        customStyles.style.display = 'none';
    }
    return (React.createElement(MenuItem, __assign({ classes: { root: 'a-filter-dropdown__item', selected: 'a-filter-dropdown__item--selected' }, ref: ref }, props, customStyles),
        React.createElement("div", { className: "a-filter-dropdown__item-content-wrapper" }, props.children)));
});
export var FilterDropdownMultiple = function (props) { return (React.createElement(Select, { displayEmpty: true, value: props.value, MenuProps: { elevation: 1, classes: { paper: 'a-filter-dropdown__flyout' } }, disabled: props.disabled, inputProps: { isActive: props.isActive, small: props.small }, input: React.createElement(FilterDropdownInputBase, null), onChange: props.onChange, multiple: true, renderValue: function () { return props.placeholder; } }, props.children)); };
export var FilterDropdownCheckboxItem = React.forwardRef(function (props, ref) {
    return (React.createElement(MenuItem, __assign({ classes: { root: 'a-filter-dropdown__item', selected: 'a-filter-dropdown__item--selected' } }, props, { ref: ref }),
        React.createElement(Checkbox, { value: props.selected, onChange: function () { return null; }, label: props.children })));
});
var FilterDropdownInputBase = React.forwardRef(function (props, ref) {
    var _a = (props.inputProps && props.inputProps) || {
        isActive: false,
        small: false,
    }, isActive = _a.isActive, small = _a.small, inputProps = __rest(_a, ["isActive", "small"]);
    var baseClassName = classnames('a-filter-dropdown', !props.disabled && 'a-filter-dropdown--hoverable');
    var inputClassName = classnames('a-filter-dropdown__input', isActive && 'a-filter-dropdown__input--active', small && 'a-filter-dropdown__input--small');
    var baseProps = __assign(__assign({}, props), { inputProps: __assign(__assign({}, inputProps), { IconComponent: function () { return React.createElement(FilterDropdownIcon, { small: small }); }, className: inputClassName }) });
    return React.createElement(InputBase, __assign({ ref: ref }, baseProps, { className: baseClassName }));
});
/** The icon of the FilterDropdown */
var FilterDropdownIcon = function (_a) {
    var small = _a.small;
    return (React.createElement(ChevronDownIcon, { className: classnames('a-filter-dropdown__icon', small && 'a-filter-dropdown__icon--small'), width: 14 }));
};
