import { createPutEndpoint } from 'request-registry';
import { getCSRFToken } from '../../Security/getCSRFToken/getCSRFToken';
import { getSavedCategories } from '../getSavedCategories/getSavedCategories';
export var addCategory = createPutEndpoint({
    url: function () { return '/jelmoli-api/JelmoliMyAccountPreference/SelectCategory'; },
    headers: {
        'X-RequestVerificationToken': function () { return getCSRFToken(); },
        'X-Requested-With': 'XMLHttpRequest',
    },
    afterSuccess: function () { return Promise.all([getSavedCategories.refresh()]); },
});
