var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ProductFeature } from '@jelmoli/ui-components/src/atoms/ProductFeature/ProductFeature';
import { WishlistHeartButton } from '@jelmoli/ui-components/src/atoms/WishlistHeartButton/WishlistHeartButton';
import { A } from '@jelmoli/ui-components/src/helpers/A/A';
import { HorizontalSpacer } from '@jelmoli/ui-components/src/helpers/HorizontalSpacer/HorizontalSpacer';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import React from 'react';
export var ECommerceBoxTop = function (_a) {
    var variant = _a.variant;
    var productLabels = variant.attributesEnum &&
        'enum_30000000' in variant.attributesEnum &&
        variant.attributesEnum.enum_30000000.values;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "o-ecommerce-box__wishlist-wrapper" },
            React.createElement(WishlistHeartButton, { productId: variant.baseId, variantIds: [variant.variantId], productName: variant.name, brandName: variant.brand })),
        React.createElement(React.Fragment, null,
            React.createElement("div", { className: "o-ecommerce-box__top o-ecommerce-box__top--width" },
                variant.saleLabel && (React.createElement(React.Fragment, null,
                    React.createElement(ProductFeature, { border: "red" }, variant.saleLabel),
                    React.createElement(HorizontalSpacer, { size: "xxxs", responsive: false }))),
                productLabels &&
                    productLabels.length > 0 &&
                    productLabels.map(function (label, index) { return (React.createElement(React.Fragment, { key: label.code },
                        React.createElement(ProductFeature, null, label.displayName),
                        productLabels.length - 1 !== index && (React.createElement(HorizontalSpacer, { size: "xxxs", responsive: false })))); })),
            React.createElement(VerticalSpacer, { size: "s" })),
        variant.brand && (React.createElement("div", __assign({}, (!productLabels && { className: 'o-ecommerce-box__top--width' })),
            React.createElement(A, { className: "o-ecommerce-box__top-brand", href: variant.brandUrl },
                React.createElement(Typography, { size: "xs", type: "medium", uppercase: true }, variant.brand)))),
        variant.name && variant.brand && React.createElement(VerticalSpacer, { size: "xxxs" }),
        variant.name && (React.createElement("div", __assign({}, (!productLabels && { className: 'o-ecommerce-box__top--width' })),
            React.createElement(Typography, { size: "m", tag: "h1" }, variant.name))),
        React.createElement(VerticalSpacer, { size: "s" }),
        React.createElement("div", { className: "o-ecommerce-box__price-wrapper" },
            React.createElement(Typography, { size: "xs", type: "medium" },
                variant.originalPrice && variant.originalPrice !== variant.currentPrice && (React.createElement("span", { className: "o-ecommerce-box__org-price" }, variant.originalPrice)),
                React.createElement("span", { className: "o-ecommerce-box__curr-price " + (variant.originalPrice ? 'o-ecommerce-box__curr-price--sale' : '') }, variant.currentPrice)),
            React.createElement("div", { className: "o-ecommerce-box__numbers-wrapper " },
                variant.manufacturerId && React.createElement(Typography, { size: "xxs" }, variant.manufacturerId),
                variant.sku && React.createElement(Typography, { size: "xxs" }, variant.sku)))));
};
