var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Column, Row } from '@jelmoli/bootstrap-grid';
import { useLoadNamedOnceOnClientSide } from '@jelmoli/hooks/src/useLoadOnceOnClientSide/useLoadOnceOnClientSide';
import { H2 } from '@jelmoli/ui-components/src/atoms/Headlines/Headlines';
import { Spinner } from '@jelmoli/ui-components/src/atoms/Spinner/Spinner';
import { Container } from '@jelmoli/ui-components/src/helpers/Container/Container';
import { useIsSitecoreEditMode } from '@jelmoli/ui-components/src/helpers/EditMode/editMode';
import { Slide, Slider } from '@jelmoli/ui-components/src/helpers/Slider/Slider';
import React from 'react';
import './product-carousel.scss';
export var ProductCarouselComponent = function (props) {
    var ProductTile = useLoadNamedOnceOnClientSide('ProductTile', function () {
        return import(/* webpackChunkName: "ProductTile" */ '@jelmoli/ui-components/src/organism/ProductTile/ProductTile');
    });
    var editMode = useIsSitecoreEditMode();
    if (props.fields.ProductCarouselProducts.length === 0 && !editMode) {
        return null;
    }
    return (React.createElement(Container, { size: "m", backgroundFill: true },
        React.createElement(Row, null,
            React.createElement(Column, { size: 12 },
                React.createElement("div", { className: "feat-product-carousel__title" },
                    React.createElement(H2, { type: "light", size: "m", field: props.fields.ProductCarouselTitle })),
                React.createElement(Slider, { minSlideAmount: { xl: 4, lg: 4, md: 3, sm: 2, xs: 2 }, productSlider: true }, function (getRef) { return (React.createElement(React.Fragment, null, props.fields.ProductCarouselProducts.map(function (item, index) { return (React.createElement(Slide, { ref: getRef(), sliderColumnCount: { xs: 6, sm: 6, md: 4, lg: 3 }, key: index }, ProductTile ? (React.createElement(ProductTile, __assign({}, item, { currentPlacement: index, giftcardPrice: props.fields.GiftcardPrice.value || '', inTheCartText: props.fields.InTheCartText.value || '', productCarouselTile: true, salePriceFromLabel: props.fields.SaleFrom.value || '' }))) : (React.createElement(Spinner, null)))); }))); })))));
};
