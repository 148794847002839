import { Column, Row } from '@jelmoli/bootstrap-grid';
import { bindTranslations } from '@jelmoli/i18n';
import { getOrders } from '@jelmoli/sitecore-services/src/Account/getOrders/getOrders';
import { getPendingOrders } from '@jelmoli/sitecore-services/src/Account/getPendingOrders/getPendingOrders';
import { isUserLoggedInLazy } from '@jelmoli/sitecore-services/src/Account/isUserLoggedIn/isUserLoggedInLazy';
import { SecondaryButton } from '@jelmoli/ui-components/src/atoms/SecondaryButton/SecondaryButton';
import { Separator } from '@jelmoli/ui-components/src/atoms/Separator/Separator';
import { Spinner } from '@jelmoli/ui-components/src/atoms/Spinner/Spinner';
import { TextLink } from '@jelmoli/ui-components/src/atoms/TextLink/TextLink';
import { hasLinkFieldValue, } from '@jelmoli/ui-components/src/helpers/SitecoreTypes/SitecoreTypes';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { OrderItem } from '@jelmoli/ui-components/src/organism/OrderItem/OrderItem';
import React from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
import { MyAccountTitle } from '../MyAccountTitleComponent/MyAccountTitle';
export var MyAccountOverviewComponent = function (_a) {
    var fields = _a.fields;
    var isLoggedIn = isUserLoggedInLazy();
    var orderData = useGetEndPointLazy(getOrders, { offset: 0, pageSize: 1 }, isLoggedIn).value;
    var pendingOrderData = useGetEndPointLazy(getPendingOrders, {}, isLoggedIn);
    var pendingOrders = pendingOrderData.value;
    var totalOrdersCount = orderData && orderData.totalItemCount;
    var totalPendingOrdersCount = pendingOrders && pendingOrders.totalItemCount;
    if (!pendingOrderData.hasData) {
        return (React.createElement(React.Fragment, null,
            React.createElement(MyAccountTitle, { fields: fields, getProfileData: true }),
            React.createElement(Column, { horizontalAlignment: "center" },
                React.createElement(Spinner, null))));
    }
    if (!pendingOrders || !orderData) {
        return React.createElement(MyAccountTitle, { fields: fields, getProfileData: true });
    }
    var t = bindTranslations(fields);
    return (React.createElement(React.Fragment, null,
        React.createElement(MyAccountTitle, { fields: fields, getProfileData: true }),
        React.createElement(Separator, null),
        React.createElement(VerticalSpacer, { size: "l" }),
        React.createElement(Typography, { size: "m", type: "light" }, t('OrderPreviewTitle')),
        React.createElement(VerticalSpacer, { size: "m" }),
        !totalOrdersCount || !totalPendingOrdersCount ? (React.createElement(EmptyState, { labels: fields, totalOrders: totalOrdersCount, totalPendingOrders: totalPendingOrdersCount })) : (pendingOrders.orders.map(function (order) { return (React.createElement(React.Fragment, { key: order.confirmationId },
            React.createElement(OrderItem, { data: {
                    ArticalNumber: Number(order.itemCount),
                    Date: order.date,
                    OrderNumber: order.confirmationId,
                    Price: order.amount,
                    OrderImage: order.imageUrl,
                    OrderStatus: order.status,
                    reservedUntilDays: order.reservedUntilDays,
                    variant: order.orderType === 'Reservation' || order.orderType === 'OfflineReservation'
                        ? ['reservation']
                        : ['order'],
                    isOffline: order.orderType === 'OfflineOrder',
                }, onClick: function () {
                    if (hasLinkFieldValue(fields.OrderPreviewButtonLink)) {
                        var idLabel = order.orderType === 'Reservation' || order.orderType === 'OfflineReservation'
                            ? 'reservationId'
                            : 'orderId';
                        window.location.assign(fields.OrderPreviewButtonLink.value.href + "?" + idLabel + "=" + order.confirmationId);
                    }
                } }),
            React.createElement(VerticalSpacer, { size: "xl" }))); })),
        orderData.totalItemCount > 0 && hasLinkFieldValue(fields.OrderPreviewButtonLink) && (React.createElement(Row, null,
            React.createElement(Column, { size: { xs: 9, sm: 7, lg: 5, xl: 6 } },
                React.createElement(SecondaryButton, { type: "link", text: t('OrderPreviewButtonLabel'), href: fields.OrderPreviewButtonLink.value.href })))),
        React.createElement(VerticalSpacer, { size: "xxl" })));
};
var EmptyState = function (_a) {
    var labels = _a.labels, totalOrders = _a.totalOrders, totalPendingOrders = _a.totalPendingOrders;
    var t = bindTranslations(labels);
    return (React.createElement(React.Fragment, null,
        React.createElement(Typography, { size: "l", type: "medium" }, t('NoOrderTitle')),
        React.createElement(VerticalSpacer, { size: "s", responsive: false }),
        React.createElement(Typography, { size: "l" },
            !totalPendingOrders && !!totalOrders && t('NoPendingOrders'),
            !totalPendingOrders && !totalOrders && t('NoHistoryOrders')),
        React.createElement(VerticalSpacer, { size: "s" }),
        React.createElement("div", null,
            React.createElement(TextLink, { "aria-label": "continue shopping", href: labels.ContinueShoppingLink.value.href },
                React.createElement(Typography, { size: "l" }, t('ContinueShoppingLabel')))),
        React.createElement(VerticalSpacer, { size: "mx" }),
        React.createElement(Separator, null),
        !totalPendingOrders && !!totalOrders && React.createElement(VerticalSpacer, { size: "mx" })));
};
