import { createPutEndpoint } from 'request-registry';
import { getCheckoutProgress } from '../../Checkout/getCheckoutProgress';
import { getCSRFToken } from '../../Security/getCSRFToken/getCSRFToken';
import { getCart } from '../getCart/getCart';
/**
 * Set checkout delivery options like package material and post24 address
 * https://wiki.namics.com/display/JELMOLI2025/205-01-06+Checkout+Interfaces#id-205-01-06CheckoutInterfaces-SetDeliveryOptionMyPost
 */
export var setDeliveryOptionMyPost = createPutEndpoint({
    url: function () { return '/jelmoli-api/JelmoliCheckout/SetDeliveryOptionMyPost'; },
    headers: {
        'X-RequestVerificationToken': function () { return getCSRFToken(); },
        'X-Requested-With': 'XMLHttpRequest',
    },
    afterSuccess: function () { return Promise.all([getCart.refresh(), getCheckoutProgress.refresh()]); },
});
