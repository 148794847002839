var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useIsVisibleInViewportEffect } from '@jelmoli/hooks/src/useKeyboardEffect/useIsVisibleInViewport/useIsVisibleInViewport';
import { bindTranslations } from '@jelmoli/i18n';
import { Bag } from '@jelmoli/ui-components/src/atoms/Icon/Bag';
import { Modal } from '@jelmoli/ui-components/src/atoms/Modal/Modal';
import { PrimaryButton } from '@jelmoli/ui-components/src/atoms/PrimaryButton/PrimaryButton';
import { ProductFulfillmentData } from '@jelmoli/ui-components/src/atoms/ProductItem/ProductItem';
import { TextLink } from '@jelmoli/ui-components/src/atoms/TextLink/TextLink';
import { preventOutline } from '@jelmoli/ui-components/src/helpers/A/A';
import { BaseImage } from '@jelmoli/ui-components/src/helpers/BaseImage/BaseImage';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import React, { useState } from 'react';
import { addToCart } from '../AddToCartFunctions/AddToCartFunctions';
import './e-commerce-box.scss';
import { ECommerceBoxDeliveriAndCostDetails } from './ECommerceBoxParts/ECommerceBoxDeliveriAndCostDetails';
import { ECommerceBoxReservationDetails } from './ECommerceBoxParts/ECommerceBoxReservationDetails';
import { ECommerceBoxSizePicker } from './ECommerceBoxParts/ECommerceBoxSizePicker';
import { ECommerceBoxTop } from './ECommerceBoxParts/ECommerceBoxTop';
import { ECommerceBoxColorPicker } from './ECommerceBoxParts/ECommereceBoxColorPicker';
export var ECommerceBox = function (_a) {
    var product = _a.data, quantityValue = _a.quantityValue, onButtonVisiblityChange = _a.onButtonVisiblityChange, onAddedToCart = _a.onAddedToCart, onClickScrollChange = _a.onClickScrollChange, updateQuantityValue = _a.updateQuantityValue;
    var sizeSwitch = product.sizeSwitch;
    var ref = useIsVisibleInViewportEffect(function (isVisible) {
        onButtonVisiblityChange(isVisible);
    });
    var sizeIndex = sizeSwitch.findIndex(function (size) { return size.variantId === product.variant.variantId; });
    var availability = sizeIndex > -1 ? sizeSwitch[sizeIndex].availability : Infinity;
    if (!product.labels) {
        return null;
    }
    var t = bindTranslations(product.labels);
    var showProductFulfillmentData = Object.values(product.variant.fulfillmentFlags).find(function (item) { return item === true; });
    var productDetailDeliveryInfoLink = product.labels.ProductDetailDeliveryInfoLink;
    return (React.createElement("div", null,
        React.createElement(VerticalSpacer, { size: "xs" }),
        React.createElement(ECommerceBoxTop, __assign({}, product)),
        availability > 0 && showProductFulfillmentData && (React.createElement(React.Fragment, null,
            React.createElement(VerticalSpacer, { size: "s" }),
            React.createElement(ProductFulfillmentData, { fulfillmentFlags: __assign({ installationServiceSelected: false }, product.variant.fulfillmentFlags), type: "large", fragileLabel: t('ProductDetailFragileLabel'), bulkyLabel: t('ProductDetailBulkyLabel'), installationServiceAvailableLabel: t('ProductDetailInstallationServiceAvailableLabel'), installationServiceMandatoryLabel: t('ProductDetailInstallationServiceMandatoryLabel') }))),
        React.createElement(VerticalSpacer, { size: "mx" }),
        React.createElement(ECommerceBoxColorPicker, { colors: product.colorSwitch, sizes: product.sizeSwitch, selectedVariant: product.variant.variantId || '' }),
        product.sizeChartUrl && (React.createElement(ECommerceBoxSizeTable, { gender: product.gender, sizeChartUrl: product.sizeChartUrl, labels: product.labels })),
        React.createElement(ECommerceBoxSizePicker, { selectedSizeType: "Default", sizeSwitch: sizeSwitch, sizeIndex: sizeIndex, availableLabel: function (availableQuantity) {
                if (availableQuantity > 0) {
                    if (availableQuantity <= 5) {
                        return t('ProductDetailLowInStockLabel', { number: availableQuantity });
                    }
                    return '';
                }
                else {
                    return t('ProductDetailNotAvailableLabel');
                }
            }, sizeLabel: t('ProductDetailSizeLabel'), quantityValue: quantityValue, updateQuantityValue: function (quantity) { return updateQuantityValue(quantity); }, availability: availability }),
        React.createElement(VerticalSpacer, { size: "m" }),
        React.createElement(ECommerceBoxDeliveriAndCostDetails, { detailsLabel: t('ProductDeliveryAndCost') }),
        React.createElement(VerticalSpacer, { size: "xxs" }),
        productDetailDeliveryInfoLink ? (React.createElement("span", { className: "o-ecommerce-box__delivery-info-link" },
            React.createElement(TextLink, { "aria-label": productDetailDeliveryInfoLink.text, underlined: true, href: productDetailDeliveryInfoLink.href, target: productDetailDeliveryInfoLink.target, rel: productDetailDeliveryInfoLink.rel, type: productDetailDeliveryInfoLink.linktype },
                React.createElement(Typography, { size: "xxs" }, t('ProductDetailDeliveryInfoLabel'))))) : (React.createElement(React.Fragment, null)),
        React.createElement(VerticalSpacer, { size: "m" }),
        React.createElement(PrimaryButton, { type: "button", uppercase: true, text: t('ProductDetailBasketLabel'), icon: React.createElement(Bag, { width: 16 }), fullWidth: true, fontResponsive: false, onClick: function () {
                if (quantityValue === 0) {
                    return;
                }
                return addToCart(product.variant.baseId, product.variant.variantId, quantityValue, onAddedToCart);
            }, disabled: availability === 0 }),
        onButtonVisiblityChange && React.createElement("div", { ref: ref }),
        React.createElement(VerticalSpacer, { size: "m" }),
        React.createElement(ECommerceBoxReservationDetails, { isSelectedSize: true, brandName: product.variant.brand, productId: product.variant.baseId, productVariantId: product.variant.variantId }),
        React.createElement("button", __assign({ className: "o-ecommerce-box__more-info", onClick: function () { return onClickScrollChange(true); } }, preventOutline()),
            React.createElement("span", { className: "o-ecommerce-box__more-info-text" },
                React.createElement(Typography, { size: "xs", responsive: false }, t('ProductDetailMoreInformationLabel')))),
        React.createElement(VerticalSpacer, { hide: ['xs', 'sm'], size: "l" }),
        React.createElement(VerticalSpacer, { show: ['xs', 'sm'], size: "xl" })));
};
var ECommerceBoxSizeTable = function (_a) {
    var gender = _a.gender, sizeChartUrl = _a.sizeChartUrl, labels = _a.labels;
    var _b = useState(false), modalOpen = _b[0], setModalOpen = _b[1];
    var t = bindTranslations(labels);
    return (React.createElement(React.Fragment, null,
        React.createElement(TextLink, { underlined: true, isButton: true, onClick: function () { return setModalOpen(true); } },
            React.createElement(Typography, { size: "xxs" }, t('ProductDetailSizeTableLabel'))),
        React.createElement(VerticalSpacer, { size: "xs" }),
        React.createElement(Modal, { open: modalOpen, title: t('ProductDetailSizeTableTitleLabel', { gender: gender }), onClose: function () { return setModalOpen(false); }, fullWidth: true },
            React.createElement("div", { className: "o-ecommerce-box__size-table-wrapper" },
                React.createElement(BaseImage, { editable: false, value: {
                        src: sizeChartUrl,
                        alt: t('ProductDetailSizeTableTitleLabel', { gender: gender }) || '',
                    }, className: "o-ecommerce-box__size-table-image" })))));
};
