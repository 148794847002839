import { createPutEndpoint } from 'request-registry';
import { getCSRFToken } from '../../Security/getCSRFToken/getCSRFToken';
/**
 * Endpoint to set a new password
 *
 * @see https://wiki.namics.com/display/JELMOLI2025/205-01-08+MyAccount+Profile+Interfaces#id-205-01-08MyAccountProfileInterfaces-Changepassword
 */
export var changePassword = createPutEndpoint({
    url: function () { return '/jelmoli-api/JelmoliMyAccountProfile/ChangePassword'; },
    headers: {
        'X-RequestVerificationToken': function () { return getCSRFToken(); },
        'X-Requested-With': 'XMLHttpRequest',
    },
});
