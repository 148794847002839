var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useIsVisibleInViewportEffect } from '@jelmoli/hooks/src/useKeyboardEffect/useIsVisibleInViewport/useIsVisibleInViewport';
import { bindTranslations } from '@jelmoli/i18n';
import { PenIcon } from '@jelmoli/ui-components/src/atoms/Icon/Pen';
import { ProductFulfillmentData } from '@jelmoli/ui-components/src/atoms/ProductItem/ProductItem';
import { SecondaryButton } from '@jelmoli/ui-components/src/atoms/SecondaryButton/SecondaryButton';
import { preventOutline } from '@jelmoli/ui-components/src/helpers/A/A';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { Cart } from '@jelmoli/ui-components/src/organism/Cart/Cart';
import { GiftWrappingPaperDrawer } from '@jelmoli/ui-components/src/organism/GiftWrappingPaperDrawer/GiftWrappingPaper';
import React, { useState } from 'react';
import './e-commerce-box.scss';
import { ECommerceBoxSizePicker } from './ECommerceBoxParts/ECommerceBoxSizePicker';
import { ECommerceBoxTop } from './ECommerceBoxParts/ECommerceBoxTop';
import { ECommerceBoxColorPicker } from './ECommerceBoxParts/ECommereceBoxColorPicker';
export var ECommerceBoxGiftWrappingPaper = function (_a) {
    var product = _a.data, quantityValue = _a.quantityValue, onButtonVisiblityChange = _a.onButtonVisiblityChange, onAddedToCart = _a.onAddedToCart, onClickScrollChange = _a.onClickScrollChange, updateQuantityValue = _a.updateQuantityValue;
    var _b = useState(false), isDrawerOpen = _b[0], setIsDrawerOpen = _b[1];
    var _c = useState(false), cartDrawerOpen = _c[0], setCartDrawerOpen = _c[1];
    var sizeSwitch = product.sizeSwitch;
    var ref = useIsVisibleInViewportEffect(function (isVisible) {
        onButtonVisiblityChange(isVisible);
    });
    var sizeIndex = sizeSwitch.findIndex(function (size) { return size.variantId === product.variant.variantId; });
    var availability = sizeIndex > -1 ? sizeSwitch[sizeIndex].availability : Infinity;
    if (!product.labels) {
        return null;
    }
    var t = bindTranslations(product.labels);
    var showProductFulfillmentData = Object.values(product.variant.fulfillmentFlags).find(function (item) { return item === true; });
    return (React.createElement("div", null,
        React.createElement(GiftWrappingPaperDrawer, { isOpen: isDrawerOpen, openDrawer: setIsDrawerOpen, openCartDrawer: setCartDrawerOpen, labels: product.labels, quantityValue: quantityValue, onAddedToCart: onAddedToCart, baseId: product.variant.baseId, variantId: product.variant.variantId }),
        React.createElement(Cart, { isOpen: cartDrawerOpen, openDrawer: setCartDrawerOpen }),
        React.createElement(VerticalSpacer, { size: "xs" }),
        React.createElement(ECommerceBoxTop, __assign({}, product)),
        availability > 0 && showProductFulfillmentData && (React.createElement(React.Fragment, null,
            React.createElement(VerticalSpacer, { size: "s" }),
            React.createElement(ProductFulfillmentData, { fulfillmentFlags: __assign({ installationServiceSelected: false }, product.variant.fulfillmentFlags), type: "large", fragileLabel: t('ProductDetailFragileLabel'), bulkyLabel: t('ProductDetailBulkyLabel'), installationServiceAvailableLabel: t('ProductDetailInstallationServiceAvailableLabel'), installationServiceMandatoryLabel: t('ProductDetailInstallationServiceMandatoryLabel') }))),
        React.createElement(VerticalSpacer, { size: "mx" }),
        React.createElement(ECommerceBoxColorPicker, { colors: product.colorSwitch, sizes: product.sizeSwitch, selectedVariant: product.variant.variantId || '' }),
        React.createElement(ECommerceBoxSizePicker, { selectedSizeType: "Default", sizeSwitch: sizeSwitch, sizeIndex: sizeIndex, availableLabel: function (availableQuantity) {
                if (availableQuantity > 0) {
                    if (availableQuantity <= 5) {
                        return t('ProductDetailLowInStockLabel', { number: availableQuantity });
                    }
                    return '';
                }
                else {
                    return t('ProductDetailNotAvailableLabel');
                }
            }, sizeLabel: t('ProductDetailSizeLabel'), quantityValue: quantityValue, updateQuantityValue: function (quantity) { return updateQuantityValue(quantity); }, availability: availability }),
        React.createElement(VerticalSpacer, { size: "m" }),
        React.createElement(Typography, { size: "xs", type: "medium", responsive: false, tag: "span" }, t('WrappingPaperPersonalizeLabel')),
        React.createElement(VerticalSpacer, { size: "s" }),
        React.createElement(SecondaryButton, { type: "button", uppercase: true, text: t('WrappingPaperPersonalizeButtonLabel'), icon: React.createElement(PenIcon, { width: 16 }), fullWidth: true, fontResponsive: false, onClick: function () {
                setIsDrawerOpen(!isDrawerOpen);
            }, disabled: availability === 0 }),
        React.createElement(VerticalSpacer, { size: "m" }),
        React.createElement("button", __assign({ className: "o-ecommerce-box__more-info", onClick: function () { return onClickScrollChange(true); } }, preventOutline()),
            React.createElement("span", { className: "o-ecommerce-box__more-info-text" },
                React.createElement(Typography, { size: "xs", responsive: false }, t('ProductDetailMoreInformationLabel')))),
        React.createElement(VerticalSpacer, { hide: ['xs', 'sm'], size: "l" }),
        React.createElement(VerticalSpacer, { show: ['xs', 'sm'], size: "xl" })));
};
