import { ProductFeature } from '@jelmoli/ui-components/src/atoms/ProductFeature/ProductFeature';
import { A } from '@jelmoli/ui-components/src/helpers/A/A';
import { hasTextFieldValue } from '@jelmoli/ui-components/src/helpers/SitecoreTypes/SitecoreTypes';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import React from 'react';
import './inspiration-tile-text-only.scss';
export var InspirationTileTextOnlyComponent = function (props) {
    return (React.createElement(A, { "aria-label": props.fields.PageTeaserTitle.value, className: "feat-inspiration-tile", href: props.fields.PageTeaserLink },
        React.createElement(Typography, { size: "xs", type: "light", uppercase: true }, props.fields.PageTeaserSubtitle.value),
        React.createElement("div", { className: "feat-inspiration-tile__title" },
            React.createElement(Typography, { size: "l", type: "bold", field: props.fields.PageTeaserTitle })),
        props.fields.PageTeaserLabel &&
            hasTextFieldValue(props.fields.PageTeaserLabel.fields.InspirationLabelTitle) && (React.createElement(React.Fragment, null,
            React.createElement(VerticalSpacer, { size: "s" }),
            React.createElement(ProductFeature, null, props.fields.PageTeaserLabel.fields.InspirationLabelTitle.value))),
        React.createElement(VerticalSpacer, { size: "xxxl" })));
};
