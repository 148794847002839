import classnames from 'classnames';
import React from 'react';
import './visibility.scss';
var toViewportArray = function (hide) { return (typeof hide === 'string' ? [hide] : hide); };
function getVisibilityClasses(type, viewports) {
    return viewports.map(function (viewport) { return "h-" + type + "-" + viewport; }).join(' ');
}
export var Visibility = function (props) {
    var Tag = props.tag || 'div';
    var visibilityClassNames = 'show' in props
        ? "h-visibility__show " + getVisibilityClasses('show', toViewportArray(props.show))
        : getVisibilityClasses('hide', toViewportArray(props.hide));
    return React.createElement(Tag, { className: classnames(props.className, visibilityClassNames) }, props.children);
};
