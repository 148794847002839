import { Column, Row } from '@jelmoli/bootstrap-grid';
import { useLoadNamedOnceInViewport } from '@jelmoli/hooks/src/useLoadOnceInViewport/useLoadOnceInViewport';
import { ChevronDownIcon } from '@jelmoli/ui-components/src/atoms/Icon/ChevronDownIcon';
import { Separator } from '@jelmoli/ui-components/src/atoms/Separator/Separator';
import { TextLink } from '@jelmoli/ui-components/src/atoms/TextLink/TextLink';
import { Container } from '@jelmoli/ui-components/src/helpers/Container/Container';
import { HorizontalSpacer } from '@jelmoli/ui-components/src/helpers/HorizontalSpacer/HorizontalSpacer';
import { useScreenSize } from '@jelmoli/ui-components/src/helpers/ScreenSizeDetector/ScreenSizeDetector';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import React, { useEffect, useState } from 'react';
import { CollapsedComponentControlsPortal } from '../../Navigation/ShopHeaderCollapsedComponent/ShopHeaderCollapsedComponent';
import './my-account-linkbox-flyout-component.scss';
export var MyAccountLinkboxFlyoutComponent = function (props) {
    var screenSize = useScreenSize();
    var showDropdown = screenSize === 'sm' || screenSize === 'xs';
    var _a = useState(false), showStickyNav = _a[0], setShowStickyNaw = _a[1];
    useEffect(function () {
        showDropdown ? setShowStickyNaw(true) : setShowStickyNaw(false);
    }, [showDropdown]);
    return (React.createElement("div", null,
        React.createElement(CollapsedComponentControlsPortal, null, showStickyNav ? (React.createElement(React.Fragment, null,
            React.createElement(Separator, null),
            React.createElement(Container, { size: "m", backgroundFill: true },
                React.createElement(VerticalSpacer, { size: "xxxs", responsive: false }),
                React.createElement(MyAccountLinkboxFlyoutController, { fields: props.fields }),
                React.createElement(VerticalSpacer, { size: "xxxs", responsive: false })))) : (React.createElement("div", null))),
        React.createElement(Row, null,
            React.createElement(Column, { size: { xs: 12, sm: 8 }, offset: { xs: 0, sm: 2 } },
                React.createElement(MyAccountLinkboxFlyoutController, { fields: props.fields })))));
};
var MyAccountLinkboxFlyoutController = function (_a) {
    var fields = _a.fields;
    var _b = useState(false), isFlyoutOpen = _b[0], setIsFlyoutOpen = _b[1];
    var _c = useLoadNamedOnceInViewport('MyAccountLinkboxFlyoutLazy', function () { return import(/* webpackChunkName: "MyAccountLinkboxFlyoutLazy" */ './MyAccountLinkboxFlyoutLazy'); }, function () { return null; }), ref = _c[0], MyAccountLinkboxFlyoutLazy = _c[1];
    var selectedLink = fields.links.find(function (link) { return link.selected; });
    var anchorEl = ref.current;
    return (React.createElement("div", { ref: ref },
        selectedLink && (React.createElement(TextLink, { "aria-label": selectedLink.text, underlined: false, isButton: true, onClick: function () { return setIsFlyoutOpen(true); } },
            React.createElement(Typography, { size: "s", type: "regular", responsive: false },
                selectedLink.text,
                React.createElement(HorizontalSpacer, { size: "xxs", responsive: false }),
                React.createElement(ChevronDownIcon, { height: 16 })))),
        anchorEl && (React.createElement(MyAccountLinkboxFlyoutLazy, { anchorEl: anchorEl, fields: fields, isOpen: isFlyoutOpen, onClose: function () { return setIsFlyoutOpen(false); } }))));
};
