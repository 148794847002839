import { Avatar } from '@jelmoli/ui-components/src/atoms/Avatar/Avatar';
import { HorizontalSpacer } from '@jelmoli/ui-components/src/helpers/HorizontalSpacer/HorizontalSpacer';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import React from 'react';
import './product-quote.scss';
export var ProductQuote = function (_a) {
    var quote = _a.quote;
    return (React.createElement(React.Fragment, null,
        quote.quoteText && (React.createElement("div", { className: "a-product-quote__text--fuscous-gray" },
            React.createElement(Typography, { type: "light", size: "l" },
                "\u201C",
                quote.quoteText,
                "\u201D"))),
        quote.quoteAuthor && (React.createElement(React.Fragment, null,
            React.createElement(VerticalSpacer, { size: "l" }),
            React.createElement(Author, { quoteAvatar: quote.quoteAuthor.staffMemberAvatar, quoteAuthorName: quote.quoteAuthor.staffMemberName, quoteJobDescription: quote.quoteAuthor.staffMemberJobDescription }))),
        React.createElement(VerticalSpacer, { size: 'quoteAuthor' in quote ? 'xxl' : 'l' })));
};
var Author = function (props) { return (React.createElement("div", { className: "a-product-quote__author" },
    props.quoteAvatar && (React.createElement(Avatar, { className: "a-product-quote__avatar", field: { value: { src: props.quoteAvatar, 'image-width': 100, 'image-height': 100 } } })),
    props.quoteAvatar && React.createElement(HorizontalSpacer, { size: "s" }),
    React.createElement("div", { className: "a-product-quote__author-text" },
        React.createElement("div", { className: "a-product-quote__author-item" },
            React.createElement(Typography, { size: "l", type: "medium" }, props.quoteAuthorName)),
        React.createElement(HorizontalSpacer, { size: "xxs", hide: "xs" }),
        React.createElement("div", { className: "a-product-quote__author-item" },
            React.createElement(Typography, { size: "l" }, props.quoteJobDescription))))); };
