var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import classnames from 'classnames';
import React from 'react';
import { Avatar } from '../../atoms/Avatar/Avatar';
import { Image } from '../../atoms/Image/Image';
import { A } from '../../helpers/A/A';
import { HorizontalSpacer } from '../../helpers/HorizontalSpacer/HorizontalSpacer';
import { hoverAnchorClassName } from '../../helpers/HoverAnchor/HoverAnchor';
import { hasImageFieldValue, hasTextFieldValue, } from '../../helpers/SitecoreTypes/SitecoreTypes';
import { Typography } from '../../helpers/Typography/Typography';
import { VerticalSpacer } from '../../helpers/VerticalSpacer/VerticalSpacer';
import './shop-the-look-teaser.scss';
export var ShopTheLookTeaser = function (props) { return (React.createElement(React.Fragment, null,
    Boolean(props.onClick) ? (React.createElement(A, { href: props.teaserUrl, className: hoverAnchorClassName + " feat-shop-the-look-teaser__link", onClick: function (event) {
            event.preventDefault();
            if (props.onClick) {
                props.onClick();
            }
        } },
        React.createElement(ShopTheLookTeaserImage, { teaserImage: props.teaserImage, teaserImageFrame: props.teaserImageFrame, teaserImageSolidFrameColor: props.teaserImageSolidFrameColor, teaserImageBlendFrameStyle: props.teaserImageBlendFrameStyle, teaserImageBlendFrameColor: props.teaserImageBlendFrameColor, teaserLabel: props.teaserLinkLabel, teaserLabelColor: props.teaserLinkTextColour, teaserStaffMember: props.teaserStaffMemberLink, staffPickLabel: props.teaserStaffPickLabel }))) : (React.createElement(ShopTheLookTeaserImage, { teaserImage: props.teaserImage, teaserImageFrame: props.teaserImageFrame, teaserImageSolidFrameColor: props.teaserImageSolidFrameColor, teaserImageBlendFrameStyle: props.teaserImageBlendFrameStyle, teaserImageBlendFrameColor: props.teaserImageBlendFrameColor, teaserLabel: props.teaserLinkLabel, teaserLabelColor: props.teaserLinkTextColour, teaserStaffMember: props.teaserStaffMemberLink, staffPickLabel: props.teaserStaffPickLabel })),
    hasTextFieldValue(props.teaserCaption) && (React.createElement(React.Fragment, null,
        React.createElement(VerticalSpacer, { size: "s" }),
        React.createElement(Typography, { size: "s", responsive: false, field: props.teaserCaption }))),
    React.createElement(VerticalSpacer, { size: "xxl" }))); };
var ShopTheLookTeaserImage = function (props) { return (React.createElement(React.Fragment, null, props.teaserStaffMember && hasImageFieldValue(props.teaserStaffMember.fields.StaffMemberAvatar) ? (React.createElement(ImageWithAvatar, { teaserImage: props.teaserImage, teaserImageFrame: props.teaserImageFrame, teaserImageSolidFrameColor: props.teaserImageSolidFrameColor, teaserImageBlendFrameStyle: props.teaserImageBlendFrameStyle, teaserImageBlendFrameColor: props.teaserImageBlendFrameColor, avatarField: props.teaserStaffMember.fields.StaffMemberAvatar, authorName: props.teaserStaffMember.fields.StaffMemberName, staffPickLabel: props.staffPickLabel })) : (React.createElement(ImageWithLabel, { teaserImage: props.teaserImage, teaserImageFrame: props.teaserImageFrame, teaserImageSolidFrameColor: props.teaserImageSolidFrameColor, teaserImageBlendFrameStyle: props.teaserImageBlendFrameStyle, teaserImageBlendFrameColor: props.teaserImageBlendFrameColor, teaserLabel: props.teaserLabel, teaserLabelColor: props.teaserLabelColor })))); };
var ImageWithAvatar = function (props) {
    var imageProps = getImageProps(props);
    return (React.createElement(Image, __assign({}, imageProps),
        React.createElement("div", { className: "feat-shop-the-look-teaser__author" },
            React.createElement(Avatar, { className: "feat-shop-the-look-teaser__avatar", field: props.avatarField, display: "inline", verticalAlignment: "bottom" }),
            React.createElement(HorizontalSpacer, { size: "s" }),
            React.createElement("div", { className: "feat-shop-the-look-teaser__author-text" },
                hasTextFieldValue(props.staffPickLabel) && (React.createElement(Typography, { size: "s", tag: "div", type: "medium", uppercase: true, field: props.staffPickLabel })),
                React.createElement(Typography, { size: "l", tag: "div", type: "medium", field: props.authorName })),
            React.createElement(VerticalSpacer, { size: "s" }))));
};
var ImageWithLabel = function (props) {
    var imageProps = getImageProps(props);
    return (React.createElement(Image, __assign({}, imageProps), hasTextFieldValue(props.teaserLabel) && (React.createElement("span", { className: classnames('feat-shop-the-look-teaser__label', "feat-shop-the-look-teaser__label--" + props.teaserLabelColor) },
        React.createElement(Typography, { type: "medium", size: "xs", responsive: false, field: props.teaserLabel, uppercase: true })))));
};
function getImageProps(props) {
    var imageProps = {
        value: props.teaserImage.value,
        hoverable: true,
    };
    switch (props.teaserImageFrame) {
        case 'solid': {
            return __assign(__assign({}, imageProps), { borderStyle: 'solid', borderColor: props.teaserImageSolidFrameColor });
        }
        case 'blend': {
            return __assign(__assign({}, imageProps), { borderStyle: props.teaserImageBlendFrameStyle, borderColor: props.teaserImageBlendFrameColor });
        }
        default:
            return imageProps;
    }
}
