import { createGetEndpoint } from 'request-registry';
export var DeliveryOptionConstantMap = {
    home: '3817f8d5-994b-4fbc-8bbe-4c342ec7553a',
    post: '50cb2500-ca29-4132-9f2e-81ddc5403c5d',
    pickup: '0e47aa36-7814-4ed7-8d19-49dc9b319979',
    digital: '3878b502-85ac-41b5-9203-e0f8712a854b',
};
export var DeliveryOptionConstants = DeliveryOptionConstantMap;
/**
 * https://wiki.namics.com/display/JELMOLI2025/205-01-06+Checkout+Interfaces#id-205-01-06CheckoutInterfaces-GetDeliveryOptions
 */
export var getDeliveryOptions = createGetEndpoint({
    url: function () { return '/jelmoli-api/JelmoliCheckout/GetDeliveryOptions'; },
});
