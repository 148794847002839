/**
 * Get all cms components from the @jelmoli/cms-components package
 */
export function loadAllSitecoreFeatureComponents() {
    var componentMap = {};
    var context = require.context('./src/', true, /[\\\/]([^\\\/]+Component)[\\\/]\1\.tsx$/);
    context.keys().forEach(function (key) {
        var name = key
            .replace('.tsx', '')
            .replace(/^.\//, '')
            .replace(/^.+[\\/]([^\\/]+)$/, '$1');
        var module = context(key);
        var component = module[name];
        componentMap[name] = component;
    });
    return componentMap;
}
