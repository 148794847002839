import React from 'react';
import { HorizontalSpacer } from '../../helpers/HorizontalSpacer/HorizontalSpacer';
import { VerticalSpacer } from '../../helpers/VerticalSpacer/VerticalSpacer';
import { Visibility } from '../../helpers/Visibility/Visibility';
import { PrimarySubmitButton } from '../PrimarySubmitButton/PrimarySubmitButton';
import { SecondaryButton } from '../SecondaryButton/SecondaryButton';
/**
 * Default responsive behaviour for two buttons to submit a form
 * inside the modal layout
 */
export var ModalConfirmationBar = function (_a) {
    var submitTitle = _a.submitTitle, cancelTitle = _a.cancelTitle, submitAction = _a.submitAction, cancelAction = _a.cancelAction;
    return (React.createElement(React.Fragment, null,
        React.createElement(Visibility, { hide: ['xs', 'sm'] },
            React.createElement(SecondaryButton, { fontResponsive: false, type: "button", text: cancelTitle, onClick: cancelAction }),
            React.createElement(HorizontalSpacer, { size: "s", responsive: false }),
            React.createElement(PrimarySubmitButton, { fontResponsive: false, text: submitTitle, onClick: submitAction })),
        React.createElement(Visibility, { show: ['xs', 'sm'] },
            React.createElement(PrimarySubmitButton, { fontResponsive: false, text: submitTitle, fullWidth: true, onClick: submitAction }),
            React.createElement(VerticalSpacer, { size: "xxs", responsive: false }),
            React.createElement(SecondaryButton, { fontResponsive: false, type: "button", text: cancelTitle, fullWidth: true, onClick: cancelAction }))));
};
