var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { track } from '@jelmoli/google-tag-manager';
import { getProfile } from '../../Account/getProfile/getProfile';
import { getCart } from '../../Cart/getCart/getCart';
import { sha256 } from '../../Security/sha/sha';
export function trackPageMeta() {
    return __awaiter(this, void 0, void 0, function () {
        var cartData, pageType, category;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getCart({})];
                case 1:
                    cartData = _a.sent();
                    if (typeof document === 'undefined') {
                        return [2 /*return*/, false];
                    }
                    document.querySelectorAll('meta').forEach(function (tag) {
                        if (tag.hasAttribute('property') && tag.getAttribute('property') === 'template') {
                            pageType = tag.getAttribute('content');
                        }
                    });
                    category = pageType === 'POP' ? getCategories() : undefined;
                    track({
                        event: 'Page Meta API',
                        employee: 'false',
                        gender: undefined,
                        age: undefined,
                        newsletter_subscriber: 'false',
                        mail: undefined,
                        customer_type: 'Guest',
                        cartItems: cartData.lines.map(function (item) {
                            return {
                                item: item.productId + "_" + item.variantId,
                                quantity: item.quantity,
                                price: parseFloat((parseFloat(item.lineTotal.replace(/[']+/g, '').split(' ')[1]) / item.quantity).toFixed(2)),
                            };
                        }),
                        category: category || undefined,
                    });
                    return [2 /*return*/];
            }
        });
    });
}
export function trackPageMetaLogedIn() {
    return __awaiter(this, void 0, void 0, function () {
        var profileData, cartData, pageType, category, _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0: return [4 /*yield*/, getProfile({})];
                case 1:
                    profileData = _d.sent();
                    return [4 /*yield*/, getCart({})];
                case 2:
                    cartData = _d.sent();
                    if (typeof document === 'undefined') {
                        return [2 /*return*/, false];
                    }
                    document.querySelectorAll('meta').forEach(function (tag) {
                        if (tag.hasAttribute('property') && tag.getAttribute('property') === 'template') {
                            pageType = tag.getAttribute('content');
                        }
                    });
                    category = pageType === 'POP' ? getCategories() : undefined;
                    _a = track;
                    _b = {
                        event: 'Page Meta API',
                        employee: profileData ? (profileData.employeeStatus === 'Employee').toString() : 'false',
                        gender: profileData ? getGender(profileData.salutationCode) : undefined,
                        age: profileData ? getYears(profileData.birthday) : undefined,
                        newsletter_subscriber: profileData ? profileData.newsletter.toString() : undefined
                    };
                    if (!profileData) return [3 /*break*/, 4];
                    return [4 /*yield*/, sha256(profileData.email)];
                case 3:
                    _c = _d.sent();
                    return [3 /*break*/, 5];
                case 4:
                    _c = undefined;
                    _d.label = 5;
                case 5:
                    _a.apply(void 0, [(_b.mail = _c,
                            _b.customer_type = profileData ? profileData.loyalty.statusCode : undefined,
                            _b.cartItems = cartData.lines.map(function (item) {
                                return {
                                    item: item.productId + "_" + item.variantId,
                                    quantity: item.quantity,
                                    price: parseFloat((parseFloat(item.lineTotal.replace(/[']+/g, '').split(' ')[1]) / item.quantity).toFixed(2)),
                                };
                            }),
                            _b.category = category || undefined,
                            _b)]);
                    return [2 /*return*/];
            }
        });
    });
}
function getYears(birthday) {
    if (!birthday) {
        return undefined;
    }
    var datesArray = birthday.split('.');
    var userYear = datesArray[datesArray.length - 1];
    var currentYear = new Date().getFullYear();
    var age = currentYear - Number(userYear);
    if (age > 18 && age < 25) {
        return '18-24';
    }
    if (age > 24 && age < 35) {
        return '25-34';
    }
    if (age > 34 && age < 45) {
        return '35-44';
    }
    if (age > 44 && age < 55) {
        return '45-54';
    }
    if (age > 54 && age < 65) {
        return '55-64';
    }
    if (age > 64 && age < 75) {
        return '65-74';
    }
    if (age > 74 && age < 85) {
        return '75-84';
    }
    if (age > 84) {
        return '84+';
    }
    return undefined;
}
function getGender(salutationCode) {
    switch (salutationCode) {
        case 'mr':
            return 'male';
        case 'ms':
            return 'female';
        case 'na':
            return 'not defined';
        default:
            return 'not defined';
    }
}
function getCategories() {
    var category = '';
    function capitalize(string) {
        return "" + string[0].toUpperCase() + string.slice(1);
    }
    function setCategoryValue(cat) {
        if (!category.length) {
            category += capitalize(cat);
        }
        else {
            category += ">" + capitalize(cat);
        }
    }
    document.location.pathname
        .split('/')
        .slice(2)
        .map(function (cat) {
        var splitedCat = cat.split('-');
        if (splitedCat.length > 1) {
            var catValue_1 = '';
            splitedCat.map(function (splitCat) {
                if (splitCat !== 'und') {
                    if (!catValue_1.length) {
                        catValue_1 += capitalize(splitCat);
                    }
                    else {
                        catValue_1 += " " + capitalize(splitCat);
                    }
                }
                else {
                    catValue_1 += ' &';
                }
            });
            setCategoryValue(catValue_1);
        }
        else {
            setCategoryValue(cat);
        }
    });
    return category;
}
