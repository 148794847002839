var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Column, Row } from '@jelmoli/bootstrap-grid';
import { Container } from '@jelmoli/ui-components/src/helpers/Container/Container';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import React from 'react';
export var OpeningHoursComponent = function (_a) {
    var fields = _a.fields;
    return (React.createElement(Container, { size: "s", backgroundFill: true },
        React.createElement(Row, null,
            React.createElement(Column, { size: 12, horizontalAlignment: "center" },
                React.createElement(Typography, { field: fields.LocationAreasTitle, type: "light", size: "m", tag: "h2" })),
            React.createElement(VerticalSpacer, { size: "mx" }),
            fields.LocationAreasItems.map(function (location, index) { return (React.createElement(Column, __assign({ size: { xs: 12, sm: 12, md: fields.LocationAreasItems.length === 1 ? 12 : 6 }, key: index }, (fields.LocationAreasItems.length === 1 && { horizontalAlignment: 'center' })),
                React.createElement(Typography, { field: location.fields.AreaName, type: "medium", size: "l", editable: false }),
                React.createElement(Typography, { field: location.fields.AreaDescription, size: "l", editable: false }),
                fields.LocationAreasItems.length - 1 !== index && React.createElement(VerticalSpacer, { size: "m" }))); }),
            React.createElement(VerticalSpacer, { size: "xxl" }))));
};
