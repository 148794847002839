import React, { useMemo } from 'react';
import './text-skew-background-highlight.scss';
export var TextSkewBackgroundHighlight = function (_a) {
    var children = _a.children, _b = _a.color, color = _b === void 0 ? 'jelmoli-green' : _b;
    var trimmedWords = useMemo(function () {
        if (!children) {
            return [];
        }
        return String(children)
            .trim()
            .split(' ')
            .map(function (word) { return word.trim(); })
            .filter(function (word) { return Boolean(word); });
    }, [children]);
    return trimmedWords.length ? (React.createElement("span", { className: "a-text-skew-background-highlight a-text-skew-background-highlight--color-" + color }, trimmedWords.map(function (word, index) { return (React.createElement("span", { key: index + " " + word, className: "a-text-skew-background-highlight__word" }, word)); }))) : null;
};
