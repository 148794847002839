var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Column, Row } from '@jelmoli/bootstrap-grid';
import { AutoplayVideo } from '@jelmoli/ui-components/src/atoms/AutoplayVideo/AutoplayVideo';
import { Image } from '@jelmoli/ui-components/src/atoms/Image/Image';
import { LightButton } from '@jelmoli/ui-components/src/atoms/LightButton/LightButton';
import { PrimaryButton } from '@jelmoli/ui-components/src/atoms/PrimaryButton/PrimaryButton';
import { TextLink } from '@jelmoli/ui-components/src/atoms/TextLink/TextLink';
import { Container } from '@jelmoli/ui-components/src/helpers/Container/Container';
import { getNodeAttributes, onlyTagsWithInnerHTML, RichTextRenderer, } from '@jelmoli/ui-components/src/helpers/RichTextRenderer/RichTextRenderer';
import { hasImageFieldValue, } from '@jelmoli/ui-components/src/helpers/SitecoreTypes/SitecoreTypes';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { Visibility } from '@jelmoli/ui-components/src/helpers/Visibility/Visibility';
import React from 'react';
import './multimedia-content.scss';
export var MultimediaContentComponent = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement(MultimediaContent, __assign({}, props)),
        React.createElement(VerticalSpacer, { size: "xl" })));
};
export var MultimediaContent = function (_a) {
    var fields = _a.fields, parameters = _a.parameters, type = _a.type;
    var coverColor = fields.MultimediaContentCoverColorOption.value;
    var textColor = coverColor.includes('darken') ? 'white' : 'outer-space';
    var StageImage = function () {
        return hasImageFieldValue(fields.MultimediaContentImage) ? (React.createElement(MultimediaImage, { imageField: fields.MultimediaContentImage, overlayColor: coverColor })) : null;
    };
    return (React.createElement(Container, { size: "l", backgroundFill: true },
        React.createElement("div", { className: "feat-multimedia-content__wrapper" },
            hasFieldValue(fields.MultimediaContentVideo) || hasFieldValue(fields.MultimediaContentVideoSmall) ? (React.createElement(React.Fragment, null,
                React.createElement(Visibility, { show: "sm" }, hasFieldValue(fields.MultimediaContentVideoSmall) ? (React.createElement(OverlayVideo, { overlayColor: coverColor, src: fields.MultimediaContentVideoSmall.value.src })) : (React.createElement(StageImage, null))),
                React.createElement(Visibility, { hide: "sm" }, hasFieldValue(fields.MultimediaContentVideo) ? (React.createElement(OverlayVideo, { overlayColor: coverColor, src: fields.MultimediaContentVideo.value.src })) : (React.createElement(StageImage, null))))) : (React.createElement(StageImage, null)),
            React.createElement("div", { className: "feat-multimedia-content__text" },
                React.createElement(VerticalSpacer, { show: "xs", size: "s" }),
                React.createElement(Row, null,
                    React.createElement(Column, { size: { xs: 10, sm: 8, md: 10, lg: 8, xl: 8 }, offset: { xs: 1, sm: 2, md: 1, lg: 2, xl: 2 } },
                        React.createElement(MultimediaText, { size: parameters.size, categoryTitle: {
                                field: fields.MultimediaContentCategoryTitle,
                                color: textColor,
                            }, title: {
                                field: fields.MultimediaContentTitle,
                                color: textColor,
                            }, richtext: fields.MultimediaContentLinks, type: type, ctaLinks: fields.MultimediaCtaButtons, ctaColor: fields.MultimediaCtaButtonColor })))))));
};
var MultimediaText = function (_a) {
    var categoryTitle = _a.categoryTitle, title = _a.title, size = _a.size, richtext = _a.richtext, type = _a.type, ctaLinks = _a.ctaLinks, ctaColor = _a.ctaColor;
    var multimedaTextRichtextRules = {
        P: onlyTagsWithInnerHTML(function (content) { return (React.createElement(Typography, { size: "l", type: "medium", tag: "p" }, content)); }),
        A: onlyTagsWithInnerHTML(function (content, node) { return (React.createElement(React.Fragment, null,
            React.createElement(Visibility, { hide: 'xs', tag: "span" },
                React.createElement(TextLink, __assign({ "aria-label": "multimedia content link", color: title.color }, getNodeAttributes(node)), content)),
            React.createElement(Visibility, { show: 'xs', tag: "span" },
                React.createElement(TextLink, __assign({ "aria-label": "multimedia content link", color: "outer-space" }, getNodeAttributes(node)), content)))); }),
    };
    // Needed for cases when authors wrongly copy content(e.g. <p>&nbsp;</p>)
    var hasRichtextLinks = richtext && richtext.value.includes('</a>');
    var Tag = type && type === 'stage' ? 'h1' : 'span';
    return (React.createElement(React.Fragment, null,
        React.createElement("span", { className: "feat-multimedia-content__text--" + categoryTitle.color },
            React.createElement(Typography, { size: size === 'l' ? 'l' : 'm', field: categoryTitle.field })),
        React.createElement(VerticalSpacer, { size: 'xxs', responsive: false }),
        React.createElement(Tag, { className: "feat-multimedia-content__heading feat-multimedia-content__text--" + title.color },
            React.createElement(Typography, { size: "xl", type: size === 'l' ? 'bold' : 'medium', field: title.field })),
        richtext && hasRichtextLinks && (React.createElement(React.Fragment, null,
            React.createElement(VerticalSpacer, { size: 's' }),
            React.createElement("span", { className: "feat-multimedia-content__text--" + title.color },
                React.createElement(RichTextRenderer, { html: richtext.value, replaceRules: multimedaTextRichtextRules })))),
        ctaLinks && (React.createElement(React.Fragment, null,
            React.createElement(VerticalSpacer, { size: 'xs' }),
            React.createElement("span", { className: "feat-multimedia-content__ctalinks" },
                React.createElement(MultimediaCtaLinks, { ctaLinks: ctaLinks, ctaColor: ctaColor.value }))))));
};
var MultimediaImage = function (_a) {
    var overlayColor = _a.overlayColor, imageField = _a.imageField;
    var overlayClass = overlayColor && "feat-multimedia-content__cover feat-multimedia-content__cover--" + overlayColor;
    return (React.createElement("div", { className: "feat-multimedia-content__image-wrapper" },
        React.createElement(Image, { fitObject: true, value: imageField.value },
            React.createElement("div", { className: overlayClass }))));
};
var OverlayVideo = function (_a) {
    var overlayColor = _a.overlayColor, src = _a.src;
    var overlayClass = overlayColor && "feat-multimedia-content__cover feat-multimedia-content__cover--" + overlayColor;
    return (React.createElement("div", { className: "feat-multimedia-content__video-wrapper" },
        React.createElement(AutoplayVideo, { src: src, fitObject: true }),
        React.createElement(Visibility, { hide: "xs" },
            React.createElement("div", { className: overlayClass }))));
};
var MultimediaCtaLinks = function (_a) {
    var ctaLinks = _a.ctaLinks, ctaColor = _a.ctaColor;
    var renderCtaLinks = ctaLinks.map(function (ctaLink) {
        return (React.createElement("span", { className: "feat-multimedia-content__ctalink-wrapper", key: ctaLink.id },
            React.createElement(VerticalSpacer, { size: "xs", responsive: false }),
            ctaColor === 'primary' ? (React.createElement(PrimaryButton, { type: "link", text: ctaLink.fields.ButtonText.value, href: ctaLink.fields.ButtonLink.value.href, target: ctaLink.fields.ButtonLink.value.target })) : (React.createElement(LightButton, { type: "link", text: ctaLink.fields.ButtonText.value, href: ctaLink.fields.ButtonLink.value.href, classNames: "feat-multimedia-content__ctalink" }))));
    });
    return renderCtaLinks;
};
var hasFieldValue = function (field) {
    return Boolean(field !== null && field.value && typeof field.value.src === 'string');
};
