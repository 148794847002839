import { bindTranslations } from '@jelmoli/i18n/index';
import { extractFieldValues, hasTextFieldValue, } from '@jelmoli/ui-components/src/helpers/SitecoreTypes/SitecoreTypes';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import classnames from 'classnames';
import React from 'react';
import './footer-delivery-methods.scss';
export var FooterDeleveryMethods = function (_a) {
    var fields = _a.fields;
    var shippingIconArray = extractFieldValues(fields, [
        'ShippingMethodsSvg1',
        'ShippingMethodsSvg2',
        'ShippingMethodsSvg3',
        'ShippingMethodsSvg4',
        'ShippingMethodsSvg5',
    ]).filter(function (propName) { return hasTextFieldValue(propName); });
    var t = bindTranslations(fields);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "feat-delivery-methods" },
            shippingIconArray.length ? (React.createElement("span", { className: "feat-delivery-methods__label" },
                t('ShippingMethodsTitle'),
                ":")) : (React.createElement("span", null)),
            shippingIconArray.map(function (icon, index) {
                return (React.createElement(React.Fragment, { key: "PaymentMethodsSvg" + index }, hasTextFieldValue(icon) && (React.createElement("div", null,
                    React.createElement("div", { dangerouslySetInnerHTML: { __html: icon.value }, className: classnames('feat-delivery-methods__icon', {
                            'feat-delivery-methods__icon--no-margin-left': index === 0,
                        }) }),
                    index < 5 && React.createElement(VerticalSpacer, { size: "s", show: 'xs' })))));
            })),
        React.createElement(VerticalSpacer, { size: "xxl", show: ['xs', 'sm'] }),
        React.createElement(VerticalSpacer, { size: "m", hide: ['xs', 'sm'] })));
};
