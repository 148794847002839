var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Column, Row } from '@jelmoli/bootstrap-grid';
import { useLoadNamedOnceOnClientSide } from '@jelmoli/hooks/src/useLoadOnceOnClientSide/useLoadOnceOnClientSide';
import React from 'react';
import { Avatar } from '../../atoms/Avatar/Avatar';
import { Download } from '../../atoms/Icon/Download';
import { Image } from '../../atoms/Image/Image';
import { SecondaryButton } from '../../atoms/SecondaryButton/SecondaryButton';
import { Spinner } from '../../atoms/Spinner/Spinner';
import { TextLink } from '../../atoms/TextLink/TextLink';
import { Container } from '../../helpers/Container/Container';
import { HorizontalSpacer } from '../../helpers/HorizontalSpacer/HorizontalSpacer';
import { KeepInView } from '../../helpers/KeepInView/KeepInView';
import { hasImageFieldValue, hasLinkFieldValue, hasTextFieldValue, } from '../../helpers/SitecoreTypes/SitecoreTypes';
import { Typography } from '../../helpers/Typography/Typography';
import { VerticalSpacer } from '../../helpers/VerticalSpacer/VerticalSpacer';
import './shop-the-look-overlay-content.scss';
export var ShopTheLookOverlayContent = function (props) {
    return (React.createElement(Container, { size: "l", backgroundFill: true },
        React.createElement(Row, null,
            React.createElement(Column, { size: { xs: 12, md: 6, lg: 5 }, order: { xs: 3, md: 2 }, verticalAlignment: "justify" },
                React.createElement(KeepInView, null,
                    React.createElement(VerticalSpacer, { size: "mx" }),
                    React.createElement(Description, { staffPick: props.contentStaffPickLabel, quoteText: props.contentQuoteText, staffMember: props.contentStaffMemberLink, image: props.contentImage, imageCaption: props.contentImageCaption, downloadLink: props.contentDownload, brandLink1: props.contentBrandLink1, brandLink2: props.contentBrandLink2, brandLink3: props.contentBrandLink3 }))),
            React.createElement(Column, { size: { xs: 12, md: 4, lg: 6 }, order: { xs: 2, md: 3 }, offset: { md: 1, lg: 1 } },
                React.createElement(VerticalSpacer, { size: "mx" }),
                React.createElement(Gallery, { productArray: props.contentShopTheLookProducts, giftCardPrice: props.giftCardPrice, inTheCartText: props.inTheCartText })))));
};
var Description = function (props) { return (React.createElement("div", null,
    React.createElement(Typography, { uppercase: true, type: "medium", size: "s", field: props.staffPick }),
    hasTextFieldValue(props.staffPick) && React.createElement(VerticalSpacer, { size: "s" }),
    hasTextFieldValue(props.quoteText) && (React.createElement(React.Fragment, null,
        React.createElement(ShopTheLookQuote, { quoteText: props.quoteText, staffMember: props.staffMember }),
        React.createElement(VerticalSpacer, { size: "xxl" }))),
    React.createElement(Image, { value: props.image.value }),
    React.createElement(VerticalSpacer, { size: "s" }),
    React.createElement(Typography, { size: "m", field: props.imageCaption }),
    React.createElement(VerticalSpacer, { size: "l" }),
    hasLinkFieldValue(props.downloadLink) && (React.createElement(React.Fragment, null,
        React.createElement(SecondaryButton, { uppercase: true, type: "link", fontSize: "xs", fontType: "medium", icon: React.createElement(Download, { height: 16 }), text: props.downloadLink.value.text, href: props.downloadLink.value.href, fontResponsive: false }),
        React.createElement(VerticalSpacer, { size: "l" }))),
    hasLinkFieldValue(props.brandLink1) && (React.createElement(Typography, { size: "m" },
        React.createElement(TextLink, { "aria-label": props.brandLink1.value.text, href: props.brandLink1.value.href }, props.brandLink1.value.text))),
    hasLinkFieldValue(props.brandLink2) && (React.createElement(React.Fragment, null,
        React.createElement(VerticalSpacer, { size: "s" }),
        React.createElement(Typography, { size: "m" },
            React.createElement(TextLink, { "aria-label": props.brandLink2.value.text, href: props.brandLink2.value.href }, props.brandLink2.value.text)))),
    hasLinkFieldValue(props.brandLink3) && (React.createElement(React.Fragment, null,
        React.createElement(VerticalSpacer, { size: "s" }),
        React.createElement(Typography, { size: "m" },
            React.createElement(TextLink, { "aria-label": props.brandLink3.value.text, href: props.brandLink3.value.href }, props.brandLink3.value.text)))),
    React.createElement(VerticalSpacer, { size: "xxl" }))); };
var ShopTheLookQuote = function (_a) {
    var quoteText = _a.quoteText, staffMember = _a.staffMember;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", null,
            React.createElement(Typography, { type: "light", size: "m" }, "\u201C"),
            React.createElement(Typography, { type: "light", size: "m", field: quoteText }),
            React.createElement(Typography, { type: "light", size: "m" }, "\u201D"),
            React.createElement(VerticalSpacer, { size: "l" })),
        staffMember && (React.createElement("div", { className: "o-shop-the-look__quote" },
            hasImageFieldValue(staffMember.fields.StaffMemberAvatar) && (React.createElement(React.Fragment, null,
                React.createElement(Avatar, { size: "l", verticalAlignment: "bottom", field: staffMember.fields.StaffMemberAvatar, display: "inline" }),
                React.createElement(HorizontalSpacer, { size: "s" }))),
            React.createElement("div", { className: "o-shop-the-look__quote-author-text" },
                staffMember.fields.StaffMemberName && (React.createElement(React.Fragment, null,
                    React.createElement(Typography, { size: "l", type: "medium", field: staffMember.fields.StaffMemberName }),
                    React.createElement(HorizontalSpacer, { size: "xxs", show: "xl" }))),
                staffMember.fields.StaffMemberJobDescription && (React.createElement(Typography, { size: "l", field: staffMember.fields.StaffMemberJobDescription })))))));
};
var GallerySingleProduct = function (_a) {
    var product = _a.product, inTheCartText = _a.inTheCartText, giftCardPrice = _a.giftCardPrice;
    var ProductTile = useLoadNamedOnceOnClientSide('ProductTile', function () {
        return import(/* webpackChunkName: "ProductTile" */ '../ProductTile/ProductTile');
    });
    return (React.createElement(Column, { offset: { xs: 0, md: 1, xl: 2 }, size: { xs: 12, md: 10, xl: 8 } }, ProductTile ? (React.createElement(ProductTile, __assign({ currentPlacement: 0 }, product, { inTheCartText: inTheCartText.value, giftcardPrice: giftCardPrice.value }))) : (React.createElement(Spinner, null))));
};
var GalleryMultiProduct = function (_a) {
    var productArray = _a.productArray, inTheCartText = _a.inTheCartText, giftCardPrice = _a.giftCardPrice;
    var ProductTile = useLoadNamedOnceOnClientSide('ProductTile', function () {
        return import(/* webpackChunkName: "ProductTile" */ '../ProductTile/ProductTile');
    });
    return (React.createElement(React.Fragment, null, productArray.map(function (product, index) { return (React.createElement(Column, { key: "product" + index, size: { xs: 12, sm: 6, md: 12, lg: 6 } }, ProductTile ? (React.createElement(ProductTile, __assign({ currentPlacement: index }, product, { inTheCartText: inTheCartText.value, giftcardPrice: giftCardPrice.value }))) : (React.createElement(Spinner, null)))); })));
};
var Gallery = function (_a) {
    var productArray = _a.productArray, inTheCartText = _a.inTheCartText, giftCardPrice = _a.giftCardPrice;
    return (React.createElement(Container, { size: "l" },
        React.createElement(Row, null, productArray.length === 1 ? (React.createElement(GallerySingleProduct, { product: productArray[0], inTheCartText: inTheCartText, giftCardPrice: giftCardPrice })) : (React.createElement(GalleryMultiProduct, { productArray: productArray, giftCardPrice: giftCardPrice, inTheCartText: inTheCartText })))));
};
