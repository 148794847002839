var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useMiniFormik } from '@jelmoli/hooks/src/useMiniFormik/useMiniFormik';
import { editAddress } from '@jelmoli/sitecore-services/src/Account/editAddress/editAddress';
import { getAddress } from '@jelmoli/sitecore-services/src/Account/getAddress/getAddress';
import { trackError } from '@jelmoli/sitecore-services/src/Tracking/Error/ErrorTesting';
import { showErrorNotification, showSuccessNotification, } from '@jelmoli/ui-components/src/atoms/Notification/Notification';
import React, { useEffect } from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
import { MyAccountAddressForm } from './MyAccountAddressForm/MyAccountAddressForm';
export var MyAccountAddressComponentModalContent = function (props) {
    var form = useMiniFormik({
        initialValues: {
            salutation: '',
            firstName: '',
            lastName: '',
            company: '',
            address1: '',
            address2: '',
            zipPostalCode: '',
            city: '',
            country: 'CH',
            standardDelivery: false,
            standardBilling: false,
        },
    });
    // Set initialValues to correspond the default values
    // This is needed when user is switching between Edit and AddNewAddress Overlay content
    useEffect(function () {
        if (!props.id) {
            form.setValues();
        }
    }, [props.id]);
    return (React.createElement(React.Fragment, null, props.id ? (React.createElement(MyAccountAddressModalEdit, __assign({ form: form, id: props.id }, props))) : (React.createElement(MyAccountModalContent, __assign({ form: form }, props)))));
};
/**
 * Edit Address overlay that populates input fields with the corresponding data
 */
var MyAccountAddressModalEdit = function (_a) {
    var form = _a.form, props = __rest(_a, ["form"]);
    var addressData = useGetEndPointLazy(getAddress, { id: props.id }).value;
    // setInitial values to correspondent the data retrived from API when Edit overlay is opened
    useEffect(function () {
        form.setValues(addressData);
    }, [addressData]);
    return React.createElement(MyAccountModalContent, __assign({ form: form }, props));
};
/**
 * Content of both AddNewAddress and Edit the Overlay
 */
var MyAccountModalContent = function (_a) {
    var form = _a.form, props = __rest(_a, ["form"]);
    return (React.createElement(React.Fragment, null,
        React.createElement(MyAccountAddressForm, { FirstName: props.FirstName, LastName: props.LastName, Salutation: props.Salutation, SalutationSource: props.SalutationSource, Company: props.Company, Address1: props.Address1, Address2: props.Address2, Country: props.Country, City: props.City, ZipPostalCode: props.ZipPostalCode, CountryList: props.CountryList, AddressBookSaveButtonLabel: props.AddressBookSaveButtonLabel, AddressBookCancelButtonLabel: props.AddressBookCancelButtonLabel, AddressBookStandardBillingAddressLabel: props.AddressBookStandardBillingAddressLabel, AddressBookStandardDeliveryAddressLabel: props.AddressBookStandardDeliveryAddressLabel, onCancel: props.onClose, onSubmit: function () { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, editAddress({}, __assign(__assign({}, form.values), { id: props.id }))
                                .then(function (response) {
                                showSuccessNotification(response.globalMessage);
                            })
                                .catch(function (response) {
                                trackError({
                                    page: 'Error in updating adderss in overlay on MyAccount Addresses',
                                    errorMessage: response.GlobalMessage,
                                });
                                showErrorNotification(response.GlobalMessage);
                            })];
                        case 1:
                            _a.sent();
                            if (!props.id) {
                                form.setValues();
                            } // Clear form after user adds new address into addressbook
                            props.onClose();
                            return [2 /*return*/];
                    }
                });
            }); }, form: form.fieldProps })));
};
