var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { bindTranslations } from '@jelmoli/i18n/index';
import { getDrawerByService } from '@jelmoli/sitecore-services/src/CustomerService/getDrawerByService';
import { Checkbox } from '@jelmoli/ui-components/src/atoms/Checkbox/Checkbox';
import { FormFieldErrorMessage } from '@jelmoli/ui-components/src/atoms/FormFieldErrorMessage/FormFieldErrorMessage';
import { ChevronLeftIcon } from '@jelmoli/ui-components/src/atoms/Icon/ChevronLeftIcon';
import { ChevronRightIcon } from '@jelmoli/ui-components/src/atoms/Icon/ChevronRightIcon';
import { RadioButtonImage } from '@jelmoli/ui-components/src/atoms/RadioButtonImage/RadioButtonImage';
import { RadioButtonsValidable } from '@jelmoli/ui-components/src/atoms/RadioButtonsValidatable/RadioButtonsValidatable';
import { HorizontalScrollWrapper } from '@jelmoli/ui-components/src/helpers/HorizontalScrollWrapper/HorizontalScrollWrapper';
import { getLabelsFromFormFields, } from '@jelmoli/ui-components/src/helpers/SitecoreTypes/SitecoreTypes';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import classnames from 'classnames';
import { FormValidationContext } from 'nested-form-validation/index';
import React, { useMemo } from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
import './personal-shopping-drawer.scss';
export var PersonalShoppingDrawerShoppersSlider = function (props) {
    var personalsShoppersData = useGetEndPointLazy(getDrawerByService, { serviceId: props.serviceId }).value;
    useMemo(function () {
        if (props.form.values.randomPersonalShopperSelection) {
            props.form.setValues(__assign(__assign({}, props.form.values), { staffMemberId: '' }));
        }
    }, [props.form.values.randomPersonalShopperSelection]);
    var t = bindTranslations(__assign(__assign({}, props), getLabelsFromFormFields(props)));
    if (!personalsShoppersData) {
        return null;
    }
    return (React.createElement("div", null,
        React.createElement(FormValidationContext, null,
            React.createElement(Checkbox, __assign({ label: t('RandomPersonalShopperSelectionCheckbox') }, props.form.fieldProps.randomPersonalShopperSelection, (!props.form.fieldProps.randomPersonalShopperSelection.value &&
                !props.form.fieldProps.staffMemberId.value && { autoFocus: true }))),
            React.createElement(VerticalSpacer, { size: "s", responsive: false }),
            React.createElement("div", { className: classnames('feat-personal-shopping-drawer__slider-wrapper', {
                    'feat-personal-shopping-drawer__slider-wrapper--arrows-shown': personalsShoppersData.staffMembers.length > 2,
                }) },
                React.createElement("div", { className: classnames(props.form.fieldProps.randomPersonalShopperSelection.value &&
                        'feat-personal-shopping-drawer__slider-overlay') }),
                React.createElement(FormValidationContext, { validate: !props.form.fieldProps.randomPersonalShopperSelection.value },
                    React.createElement(RadioButtonsValidable, __assign({ row: true, name: "shopperRandom" }, props.form.fieldProps.staffMemberId, { errormsg: props.PersonalShopperSelection.fields.IsRequiredError &&
                            props.PersonalShopperSelection.fields.IsRequiredError.value }),
                        React.createElement(HorizontalScrollWrapper, { fullWidth: true, isSlider: true, leftArrow: React.createElement("div", { className: classnames('feat-personal-shopping-drawer__arrow feat-personal-shopping-drawer__arrow--left', personalsShoppersData.staffMembers.length <= 2 &&
                                    'feat-personal-shopping-drawer__arrow--hide') },
                                React.createElement(ChevronLeftIcon, { height: 12 })), rightArrow: React.createElement("div", { className: classnames('feat-personal-shopping-drawer__arrow feat-personal-shopping-drawer__arrow--right', personalsShoppersData.staffMembers.length <= 2 &&
                                    'feat-personal-shopping-drawer__arrow--hide') },
                                React.createElement(ChevronRightIcon, { height: 12 })) },
                            React.createElement("div", { className: "feat-personal-shopping-drawer__slider " }, personalsShoppersData.staffMembers.map(function (staffMember, index) {
                                {
                                    var categoryLength_1 = staffMember.categories.length;
                                    return (React.createElement(RadioButtonImage, { value: staffMember.id, image: { value: { src: staffMember.avatar } }, key: "staff-member--" + index, circle: true, autoFocus: staffMember.id === props.serviceStuffMemberId },
                                        React.createElement(Typography, { tag: "p", size: "l", type: "medium", responsive: false }, staffMember.name),
                                        staffMember.categories.map(function (item, i) { return (React.createElement(Typography, { type: "regular", uppercase: true, size: "xs", key: i, responsive: false },
                                            item,
                                            categoryLength_1 > 1 && categoryLength_1 !== i + 1
                                                ? categoryLength_1 === i + 2
                                                    ? ' & '
                                                    : ', '
                                                : '')); }),
                                        React.createElement("div", { className: "feat-personal-shopping-drawer--text-gray" },
                                            React.createElement(Typography, { tag: "p", size: "xs", type: "regular", responsive: false }, staffMember.availablity))));
                                }
                            })))),
                    React.createElement(FormFieldErrorMessage, { name: "shopperRandom", verticalSpaceTop: "xxs" }))))));
};
