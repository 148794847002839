import { createGetEndpoint } from 'request-registry';
/**
 * This API retireves address from the database based on id
 * https://wiki.namics.com/display/JELMOLI2025/205-01-08+MyAccount+Profile+Interfaces#id-205-01-08MyAccountProfileInterfaces-GetAddress
 */
export var getAddress = createGetEndpoint({
    url: function (_a) {
        var id = _a.id;
        return "/jelmoli-api/JelmoliMyAccountProfile/GetAddress/" + id;
    },
});
