var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useMiniFormik } from '@jelmoli/hooks/src/useMiniFormik/useMiniFormik';
import { bindTranslations } from '@jelmoli/i18n';
import { getCart } from '@jelmoli/sitecore-services/src/Cart/getCart/getCart';
import { getCheckoutLabels } from '@jelmoli/sitecore-services/src/Checkout/getCheckoutLabels/getCheckoutLabels';
import { setOrderInformation } from '@jelmoli/sitecore-services/src/Checkout/setOrderInformation/setOrderInformation';
import { trackError } from '@jelmoli/sitecore-services/src/Tracking/Error/ErrorTesting';
import { Checkbox } from '@jelmoli/ui-components/src/atoms/Checkbox/Checkbox';
import { CheckboxWithFormFieldSetting } from '@jelmoli/ui-components/src/atoms/CheckboxValidatable/CheckboxValidatable';
import { FormFieldErrorMessage } from '@jelmoli/ui-components/src/atoms/FormFieldErrorMessage/FormFieldErrorMessage';
import { showErrorNotification } from '@jelmoli/ui-components/src/atoms/Notification/Notification';
import { PrimarySubmitButton } from '@jelmoli/ui-components/src/atoms/PrimarySubmitButton/PrimarySubmitButton';
import { TextFieldWithFormSetting } from '@jelmoli/ui-components/src/atoms/TextFieldValidatable/TextFieldValidatable';
import { getLabelsFromFormFields, } from '@jelmoli/ui-components/src/helpers/SitecoreTypes/SitecoreTypes';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { AddressForm } from '@jelmoli/ui-components/src/organism/AddressForm/AddressForm';
import { NameForm } from '@jelmoli/ui-components/src/organism/NameForm/NameForm';
import { FormValidationContext } from 'nested-form-validation/index';
import React, { useEffect, useMemo } from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
import './checkout-step-address.scss';
export var CheckoutStepOrderAddress = function (props) {
    var getCheckoutAddressLabel = useGetEndPointLazy(getCheckoutLabels).value;
    var getCheckoutCartData = useGetEndPointLazy(getCart).value;
    var form = useMiniFormik({
        initialValues: {
            salutation: '',
            firstName: '',
            lastName: '',
            company: '',
            address1: '',
            address2: '',
            zipPostalCode: '',
            city: '',
            country: 'CH',
            reuseShippingAddressForDelivery: true,
            deliverySalutation: '',
            deliveryFirstName: '',
            deliveryLastName: '',
            deliveryCompany: '',
            deliveryAddress1: '',
            deliveryAddress2: '',
            deliveryZipPostalCode: '',
            deliveryCity: '',
            deliveryCountry: 'CH',
            email: '',
            mobile: '',
            newsletter: true,
            createAccount: false,
        },
    });
    useEffect(function () {
        if (!getCheckoutCartData || !getCheckoutCartData.orderInformation) {
            return;
        }
        var orderData = getCheckoutCartData.orderInformation;
        var shippingAddress = orderData.shippingAddress || {};
        if (!('billingAddress' in orderData)) {
            return;
        }
        form.setValues({
            salutation: orderData.billingAddress.salutationCode,
            firstName: orderData.billingAddress.firstName,
            lastName: orderData.billingAddress.lastName,
            company: orderData.billingAddress.company,
            address1: orderData.billingAddress.address1,
            address2: orderData.billingAddress.address2,
            zipPostalCode: orderData.billingAddress.zipPostalCode,
            city: orderData.billingAddress.city,
            country: orderData.billingAddress.countryCode,
            reuseShippingAddressForDelivery: orderData.sameAsBillingAddress,
            deliverySalutation: shippingAddress.salutationCode,
            deliveryFirstName: shippingAddress.firstName,
            deliveryLastName: shippingAddress.lastName,
            deliveryCompany: shippingAddress.company,
            deliveryAddress1: shippingAddress.address1,
            deliveryAddress2: shippingAddress.address2,
            deliveryZipPostalCode: shippingAddress.zipPostalCode,
            deliveryCity: shippingAddress.city,
            deliveryCountry: shippingAddress.countryCode,
            email: orderData.email,
            mobile: orderData.mobile,
            newsletter: orderData.newsletter,
            createAccount: orderData.createAccount,
        });
    }, [getCheckoutCartData]);
    var t = useMemo(function () {
        return getCheckoutAddressLabel &&
            bindTranslations(__assign(__assign({}, getCheckoutAddressLabel), getLabelsFromFormFields(getCheckoutAddressLabel)));
    }, [getCheckoutAddressLabel]);
    if (!getCheckoutAddressLabel || !t) {
        return null;
    }
    return (React.createElement("div", null,
        React.createElement(Typography, { size: "xs", type: "medium", responsive: false }, t('CheckoutAdressBillingAdressLabel')),
        React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
        React.createElement(Typography, { size: "xxs", type: "medium", responsive: false }, t('Salutation')),
        React.createElement(VerticalSpacer, { size: "xs", responsive: false }),
        React.createElement(FormValidationContext, null,
            React.createElement(CheckoutAddressForm, { fieldValues: form.fieldProps, fields: getCheckoutAddressLabel }),
            React.createElement(Typography, { size: "xs", type: "medium", responsive: false }, t('CheckoutAdressShippingAdressLabel')),
            React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
            React.createElement(Checkbox, __assign({ label: t('CheckoutAdressSameAdressLabel') }, form.fieldProps.reuseShippingAddressForDelivery)),
            form.fieldProps.reuseShippingAddressForDelivery.value && (React.createElement(VerticalSpacer, { size: "xl", responsive: false })),
            !form.fieldProps.reuseShippingAddressForDelivery.value && (React.createElement(React.Fragment, null,
                React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
                React.createElement(Typography, { size: "xxs", type: "medium", responsive: false }, t('Salutation')),
                React.createElement(VerticalSpacer, { size: "xs", responsive: false }),
                React.createElement(CheckoutAddressForm, { fieldValues: {
                        salutation: form.fieldProps.deliverySalutation,
                        firstName: form.fieldProps.deliveryFirstName,
                        lastName: form.fieldProps.deliveryLastName,
                        company: form.fieldProps.deliveryCompany,
                        address1: form.fieldProps.deliveryAddress1,
                        address2: form.fieldProps.deliveryAddress2,
                        zipPostalCode: form.fieldProps.deliveryZipPostalCode,
                        city: form.fieldProps.deliveryCity,
                        country: form.fieldProps.deliveryCountry,
                    }, fields: getCheckoutAddressLabel }))),
            React.createElement(Notifications, { fields: getCheckoutAddressLabel, fieldValues: form.fieldProps }),
            React.createElement(VerticalSpacer, { size: "xl", responsive: false }),
            React.createElement(PrimarySubmitButton, { text: t('CheckoutStepperSaveStepCtaButtonText'), size: "tiny", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, setOrderInformation({}, formatDataForSubmit(form.values))
                                    .then(function () { return props.onComplete(); })
                                    .catch(function (response) {
                                    trackError({
                                        page: 'Error occurs during saving of the address as guest user in Chekcout',
                                        errorMessage: response.GlobalMessage,
                                    });
                                    showErrorNotification(response.GlobalMessage);
                                })];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); } })),
        React.createElement(VerticalSpacer, { size: "xl", responsive: false })));
};
export var formatDataForSubmit = function (data) {
    return {
        sameAsBillingAddress: data.reuseShippingAddressForDelivery,
        billingAddress: {
            salutationCode: data.salutation,
            firstName: data.firstName,
            lastName: data.lastName,
            company: data.company,
            address1: data.address1,
            address2: data.address2,
            countryCode: data.country,
            city: data.city,
            zipPostalCode: data.zipPostalCode,
        },
        shippingAddress: data.reuseShippingAddressForDelivery
            ? null
            : {
                salutationCode: data.deliverySalutation,
                firstName: data.deliveryFirstName,
                lastName: data.deliveryLastName,
                company: data.deliveryCompany,
                address1: data.deliveryAddress1,
                address2: data.deliveryAddress2,
                countryCode: data.deliveryCountry,
                city: data.deliveryCity,
                zipPostalCode: data.deliveryZipPostalCode,
            },
        email: data.email,
        mobile: data.mobile,
        newsletter: data.newsletter,
        createAccount: data.createAccount,
    };
};
export var CheckoutAddressForm = function (_a) {
    var fields = _a.fields, fieldValues = _a.fieldValues, removeLastSpacing = _a.removeLastSpacing;
    return (React.createElement(FormValidationContext, null,
        React.createElement(NameForm, { fields: fields, fieldValues: fieldValues }),
        React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
        React.createElement(AddressForm, { fields: fields, fieldValues: fieldValues, removeLastSpacing: removeLastSpacing || false })));
};
var Notifications = function (_a) {
    var fields = _a.fields, fieldValues = _a.fieldValues;
    var t = bindTranslations(fields);
    return (React.createElement(FormValidationContext, null,
        React.createElement(Typography, { size: "xs", type: "medium", responsive: false }, t('CheckoutAdressTitleNotificationLabel')),
        React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
        React.createElement(TextFieldWithFormSetting, __assign({ variant: "standard", type: "email", name: "email", formSetting: fields.Email }, fieldValues.email, { fullWidth: true })),
        React.createElement(FormFieldErrorMessage, { name: "email", verticalSpaceTop: "xxs" }),
        React.createElement(VerticalSpacer, { size: "s", responsive: false }),
        React.createElement(TextFieldWithFormSetting, __assign({ variant: "standard", type: "tel", name: "mobile", formSetting: fields.Mobile }, fieldValues.mobile, { fullWidth: true })),
        React.createElement(VerticalSpacer, { size: "xs" }),
        React.createElement("span", { className: "feat-checkout-step-address__sms-notification" },
            React.createElement(Typography, { size: "xxs", responsive: false }, t('CheckoutAdressReceiveSmsNotificationText'))),
        React.createElement(VerticalSpacer, { size: "xl", responsive: false }),
        React.createElement(Typography, { type: "medium", size: "xs", responsive: false }, t('CheckoutNewsletterTitleLabel')),
        React.createElement(VerticalSpacer, { size: "s", responsive: false }),
        React.createElement(CheckboxWithFormFieldSetting, __assign({ name: "newsletter", formSetting: fields.Newsletter }, fieldValues.newsletter))));
};
