var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import MenuItem from '@material-ui/core/MenuItem';
import classnames from 'classnames';
import React from 'react';
import { HorizontalSpacer } from '../../helpers/HorizontalSpacer/HorizontalSpacer';
import { Typography } from '../../helpers/Typography/Typography';
import './pdp-dropdown-item.scss';
export var PDPDropdownItem = React.forwardRef(function (_a, ref) {
    var outOfStock = _a.outOfStock, props = __rest(_a, ["outOfStock"]);
    return (React.createElement(MenuItem, __assign({ ref: ref }, props, { classes: {
            root: 'a-pdp-dropdown-item',
            selected: 'a-pdp-dropdown-item--selected',
        }, value: props.value, className: classnames((outOfStock || props.disabled) && 'a-pdp-dropdown-item--disabled') }),
        React.createElement("div", { className: "a-pdp-dropdown-item__text-wrapper" },
            React.createElement("div", { className: "a-pdp-dropdown-item__size-wrapper" },
                React.createElement("div", { className: "a-pdp-dropdown-item__shirt-size" },
                    React.createElement(Typography, { size: "s", responsive: false, uppercase: true, type: props.selected ? 'medium' : 'regular' }, props.shirtsize)),
                React.createElement("div", { className: "a-pdp-dropdown-item__vendor-size" },
                    React.createElement(HorizontalSpacer, { responsive: false, size: "m" }),
                    React.createElement(Typography, { size: "s", responsive: false, type: props.selected ? 'medium' : 'regular' }, props.vendorsize))),
            React.createElement("div", { className: "a-pdp-dropdown-item__availability" },
                React.createElement(Typography, { size: "s", responsive: false }, props.availability)))));
});
