import { bindTranslations } from '@jelmoli/i18n';
import { CheckedType, } from '@jelmoli/sitecore-services/src/Category/CategoryStore/CategoryStore';
import { trackAddTopic, trackRemoveTopic } from '@jelmoli/sitecore-services/src/Tracking/Account/PreferencesTracking';
import { Checkbox } from '@jelmoli/ui-components/src/atoms/Checkbox/Checkbox';
import { Drawer, DrawerContent, DrawerFooter, DrawerHeader } from '@jelmoli/ui-components/src/atoms/Drawer/Drawer';
import { PrimaryButton } from '@jelmoli/ui-components/src/atoms/PrimaryButton/PrimaryButton';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { observer } from 'mobx-react-lite';
import React from 'react';
export var MyAccountCategoriesDrawer = observer(function (props) {
    var t = bindTranslations(props.labels);
    var subCategoryList = props.store.subCategories(props.selectedCategory.value);
    if (!props.isOpen || subCategoryList.length === 0) {
        return (React.createElement(Drawer, { isOpen: false, openDrawer: function () {
                /* */
            } },
            React.createElement("span", null)));
    }
    return (React.createElement(Drawer, { isOpen: props.isOpen, openDrawer: props.closeDrawer },
        React.createElement(DrawerHeader, null,
            React.createElement(Typography, { type: "medium", size: "m", responsive: false }, t('title', { category: props.selectedCategory.label }))),
        React.createElement(DrawerContent, null,
            subCategoryList.map(function (subCategory) { return (React.createElement("div", { key: subCategory.value },
                React.createElement(Checkbox, { value: subCategory.selected !== CheckedType.unchecked, intermediate: subCategory.selected === CheckedType.intermediate, label: subCategory.label, onChange: function () {
                        if (subCategory.selected === CheckedType.unchecked) {
                            trackAddTopic(props.selectedCategory.label, subCategory.label);
                            props.store.saveCategory({
                                rootCategory: props.selectedCategory.value,
                                category: subCategory.value,
                            });
                        }
                        else {
                            trackRemoveTopic(props.selectedCategory.label, subCategory.label);
                            props.store.removeSubCategory({
                                rootCategory: props.selectedCategory.value,
                                category: subCategory.value,
                            });
                        }
                    } }),
                React.createElement(VerticalSpacer, { size: "xs", responsive: false }),
                (subCategory.selected !== CheckedType.unchecked || isAnyChildSelected(subCategory)) && (React.createElement(Subcategories, { list: subCategory.children, store: props.store, rootCategoryName: props.selectedCategory.label, rootCategory: props.selectedCategory.value })))); }),
            React.createElement(VerticalSpacer, { size: "xl", responsive: false })),
        React.createElement(DrawerFooter, null, function () { return (React.createElement(PrimaryButton, { type: "button", text: t('close'), fontSize: "xs", fontType: "medium", fontResponsive: false, uppercase: true, onClick: props.closeDrawer, fullWidth: true })); })));
});
var Subcategories = observer(function (props) {
    return (React.createElement("div", { className: "feat-myaccount-categories__drawer-subcategory-wrapper" }, props.list.map(function (listItem) { return (React.createElement(React.Fragment, { key: listItem.value },
        React.createElement(Checkbox, { value: listItem.selected !== CheckedType.unchecked, intermediate: listItem.selected === CheckedType.intermediate, label: listItem.label, onChange: function () {
                if (listItem.selected === CheckedType.unchecked) {
                    trackAddTopic(props.rootCategoryName, listItem.label);
                    props.store.saveCategory({
                        rootCategory: props.rootCategory,
                        category: listItem.value,
                    });
                }
                else {
                    trackRemoveTopic(props.rootCategoryName, listItem.label);
                    props.store.removeSubCategory({
                        rootCategory: props.rootCategory,
                        category: listItem.value,
                    });
                }
            } }),
        React.createElement(VerticalSpacer, { size: "xs", responsive: false }),
        (listItem.selected !== CheckedType.unchecked || isAnyChildSelected(listItem)) && (React.createElement(Subcategories, { list: listItem.children, rootCategoryName: props.rootCategoryName, rootCategory: props.rootCategory, store: props.store })))); })));
});
/** Check if any child of the list is selected */
function isAnyChildSelected(list) {
    return list.children.some(function (child) {
        if (child.selected !== CheckedType.unchecked) {
            return true;
        }
        return isAnyChildSelected(child);
    });
}
