var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { processRichTextHtml } from '@jelmoli/ui-components/src/helpers/RichTextRenderer/RichTextRenderer';
import { RichTextComponent as FrontendRichTextComponent } from './RichTextComponent';
export var RichTextComponent = FrontendRichTextComponent;
/**
 * Small Server Side Rendering Wrapper to prepare the html before rendering
 */
export var ssrPreprocessor = function (props) {
    return __assign(__assign({}, props), { fields: __assign(__assign({}, props.fields), { RichTextContent: __assign(__assign({}, props.fields.RichTextContent), { value: processRichTextHtml(props.fields.RichTextContent.value || '') }) }) });
};
