var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useMiniFormik } from '@jelmoli/hooks/src/useMiniFormik/useMiniFormik';
import { bindTranslations } from '@jelmoli/i18n/index';
import { getProfile } from '@jelmoli/sitecore-services/src/Account/getProfile/getProfile';
import { isUserLoggedInLazy } from '@jelmoli/sitecore-services/src/Account/isUserLoggedIn/isUserLoggedInLazy';
import { getDrawerByService } from '@jelmoli/sitecore-services/src/CustomerService/getDrawerByService';
import { getDrawerByShopper } from '@jelmoli/sitecore-services/src/CustomerService/getDrawerByShopper';
import { getSettingsPersonalShoppingDrawer } from '@jelmoli/sitecore-services/src/CustomerService/getSettingsPersonalShoppingDrawer';
import { submitService } from '@jelmoli/sitecore-services/src/CustomerService/SubmitService/submitService';
import { blacklist, getCaptchaToken } from '@jelmoli/sitecore-services/src/Security/getBlacklistApis/getBlacklistApis';
import { trackError } from '@jelmoli/sitecore-services/src/Tracking/Error/ErrorTesting';
import { trackPersonalShopping } from '@jelmoli/sitecore-services/src/Tracking/PersonalShopping/PersonalShopping';
import { Divider } from '@jelmoli/ui-components/src/atoms/Divider/Divider';
import { Drawer, DrawerContent, DrawerHeader } from '@jelmoli/ui-components/src/atoms/Drawer/Drawer';
import { showErrorNotification, showSuccessNotification, } from '@jelmoli/ui-components/src/atoms/Notification/Notification';
import { PrimarySubmitButton } from '@jelmoli/ui-components/src/atoms/PrimarySubmitButton/PrimarySubmitButton';
import { Separator } from '@jelmoli/ui-components/src/atoms/Separator/Separator';
import { getLabelsFromFormFields } from '@jelmoli/ui-components/src/helpers/SitecoreTypes/SitecoreTypes';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { LoginNotification } from '@jelmoli/ui-components/src/organism/LoginNotification/LoginNotification';
import { FormValidationContext } from 'nested-form-validation/index';
import React, { useEffect, useMemo } from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
import { PersonalShoppingDrawerFormComponent } from '../PersonalShoppingDrawerFormComponent/PersonalShoppingDrawerFormComponent';
import './personal-shopping-drawer.scss';
import { PersonalShoppingDrawerServicesSlider } from './PersonalShoppingDrawerServicesSlider';
import { PersonalShoppingDrawerShoppersSlider } from './PersonalShoppingDrawerShoppersSlider';
export var PersonalShoppingDrawerComponent = function (props) {
    var isLoggedIn = isUserLoggedInLazy();
    var personalShoppingDrawerSettingsData = useGetEndPointLazy(getSettingsPersonalShoppingDrawer).value;
    var getProfileData = useGetEndPointLazy(getProfile, {}, isLoggedIn && props.isOpen).value;
    var serviceDrawerConfiguration = useGetEndPointLazy(getDrawerByService, { serviceId: props.serviceId || '' }, Boolean(props.serviceId) && props.isOpen).value;
    var shopperDrawerConfiguration = useGetEndPointLazy(getDrawerByShopper, { staffMemberId: props.staffMemberId || '' }, Boolean(props.staffMemberId) && props.isOpen).value;
    var drawerApiData = serviceDrawerConfiguration || shopperDrawerConfiguration;
    var form = useMiniFormik({
        initialValues: {
            comment: '',
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            randomPersonalShopperSelection: false,
            shoppingServiceId: props.serviceId ? props.serviceId : '',
            staffMemberId: props.staffMemberId
                ? props.staffMemberId
                : props.serviceStuffMemberId
                    ? props.serviceStuffMemberId
                    : '',
            preferredDate: '',
            preferredTime: '',
            privacyPolicy: false,
        },
    });
    useEffect(function () {
        if (!getProfileData) {
            return;
        }
        form.setValues({
            firstName: getProfileData.firstName,
            lastName: getProfileData.lastName,
            email: getProfileData.email,
            phoneNumber: getProfileData.mobile,
            privacyPolicy: true,
        });
    }, [getProfileData]);
    var setCheckboxToAutofocus = useMemo(function () {
        if (props.staffMemberId) {
            return form.fieldProps.shoppingServiceId.value ? true : false;
        }
        if (props.serviceId && !form.fieldProps.randomPersonalShopperSelection.value) {
            return form.fieldProps.staffMemberId.value ? true : false;
        }
        return !!(props.serviceId && form.fieldProps.randomPersonalShopperSelection.value);
    }, [props.isOpen]);
    if (!personalShoppingDrawerSettingsData || !drawerApiData) {
        // Switching from null to Drawer will not trigger an animation
        // Therefore we already prepare a closed drawer as long as no
        // data is available
        return (React.createElement(Drawer, { isOpen: false, openDrawer: function () {
                /* */
            } },
            React.createElement("span", null)));
    }
    var t = bindTranslations(__assign(__assign({}, personalShoppingDrawerSettingsData), getLabelsFromFormFields(personalShoppingDrawerSettingsData)));
    return (React.createElement(Drawer, { isOpen: props.isOpen, openDrawer: props.openDrawer },
        React.createElement(DrawerHeader, null, t('DrawerTitleDefault')),
        React.createElement(DrawerContent, null,
            React.createElement(FormValidationContext, null,
                React.createElement(LoginNotification, { loginNotificationMessage: t('LogInNotificationDescription'), loginNotificationLoginLinkText: t('LogInNotificationLink') }),
                isOfTypeServiceDrawerService(drawerApiData) && (React.createElement(DrawerServiceInfoSection, __assign({}, drawerApiData.service))),
                React.createElement(Typography, { size: "xs", responsive: false, type: "medium" }, isOfTypeServiceDrawerService(drawerApiData)
                    ? t('PersonalShopperSelection')
                    : t('ServiceSelection', { staffMemberName: drawerApiData.staffMember.name })),
                React.createElement(VerticalSpacer, { size: "s", responsive: false }),
                isOfTypeServiceDrawerService(drawerApiData)
                    ? props.serviceId && (React.createElement(PersonalShoppingDrawerShoppersSlider, { form: form, serviceId: props.serviceId, serviceStuffMemberId: props.serviceStuffMemberId, PersonalShopperSelection: personalShoppingDrawerSettingsData.PersonalShopperSelection, RandomPersonalShopperSelectionCheckbox: personalShoppingDrawerSettingsData.RandomPersonalShopperSelectionCheckbox }))
                    : props.staffMemberId && (React.createElement(PersonalShoppingDrawerServicesSlider, { ServiceSelection: personalShoppingDrawerSettingsData.ServiceSelection, stafMemberId: props.staffMemberId, fieldValues: form.fieldProps })),
                React.createElement(VerticalSpacer, { size: "xl", responsive: false }),
                React.createElement(PersonalShoppingDrawerFormComponent, { fieldValues: form.fieldProps, fields: drawerApiData, isAutoFocused: setCheckboxToAutofocus }),
                React.createElement(VerticalSpacer, { size: "s", responsive: false }),
                React.createElement(PrimarySubmitButton, { text: t('BookingConfirmationButton'), fullWidth: true, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        var _a, _b;
                        return __generator(this, function (_c) {
                            switch (_c.label) {
                                case 0:
                                    _a = submitService;
                                    _b = {};
                                    return [4 /*yield*/, getCaptchaToken(blacklist.personalShopping)];
                                case 1: return [4 /*yield*/, _a.apply(void 0, [(_b.token = _c.sent(), _b), __assign(__assign({}, form.values), { phoneNumber: form.values.phoneNumber, privacyPolicy: form.values.privacyPolicy })])
                                        .then(function (response) {
                                        trackPersonalShopping({
                                            shopperId: form.values.staffMemberId,
                                            serviceId: form.values.shoppingServiceId,
                                        });
                                        showSuccessNotification(response.globalMessage);
                                        props.openDrawer(false);
                                    })
                                        .catch(function (response) {
                                        trackError({
                                            page: 'Personal Shopping Drawer',
                                            errorMessage: response.GlobalMessage,
                                        });
                                        showErrorNotification(response.GlobalMessage);
                                    })];
                                case 2:
                                    _c.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); } }),
                React.createElement(VerticalSpacer, { size: "xl", responsive: false })))));
};
var DrawerServiceInfoSection = function (props) {
    var categoryLength = props.categories.length;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "feat-personal-shopping-drawer__service-info" },
            React.createElement(Divider, { heightSize: "xxxxs", widthSize: "xxs" }),
            React.createElement(VerticalSpacer, { size: "s", responsive: false }),
            React.createElement("div", null, props.categories.map(function (category, i) { return (React.createElement(Typography, { type: "regular", uppercase: true, size: "xs", key: i, responsive: false },
                category,
                categoryLength > 1 && categoryLength !== i + 1
                    ? categoryLength === i + 2
                        ? ' & '
                        : ', '
                    : '')); })),
            React.createElement(Typography, { tag: 'p', type: "medium", size: "xl", responsive: false }, props.name),
            React.createElement(VerticalSpacer, { size: "xs", responsive: false }),
            React.createElement(Typography, { type: "medium", size: "xs", responsive: false }, props.cost),
            React.createElement(VerticalSpacer, { size: "xxs", responsive: false }),
            React.createElement(Typography, { tag: "div", type: "regular", size: "xs", responsive: false }, props.duration),
            React.createElement(VerticalSpacer, { size: "s", responsive: false }),
            React.createElement(Typography, { type: "regular", size: "s", responsive: false }, props.shortDescription)),
        React.createElement(VerticalSpacer, { size: "xl", responsive: false }),
        React.createElement(Separator, null),
        React.createElement(VerticalSpacer, { size: "xl", responsive: false })));
};
var isOfTypeServiceDrawerService = function (object) {
    return 'service' in object;
};
