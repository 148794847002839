var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useRefInit } from '@jelmoli/hooks/src/useRefInit/useRefInit';
import { bindTranslations } from '@jelmoli/i18n';
import { CategoryStore } from '@jelmoli/sitecore-services/src/Category/CategoryStore/CategoryStore';
import { trackAddTopic, trackRemoveTopic } from '@jelmoli/sitecore-services/src/Tracking/Account/PreferencesTracking';
import { Chip } from '@jelmoli/ui-components/src/atoms/Chip/Chip';
import { confirmDialog } from '@jelmoli/ui-components/src/atoms/Dialog/Dialog';
import { BookmarkIcon } from '@jelmoli/ui-components/src/atoms/Icon/BookmarkIcon';
import { InteractiveIcon } from '@jelmoli/ui-components/src/atoms/InteractiveIcon/InteractiveIcon';
import { SecondaryButton } from '@jelmoli/ui-components/src/atoms/SecondaryButton/SecondaryButton';
import { TextLink } from '@jelmoli/ui-components/src/atoms/TextLink/TextLink';
import { preventOutline } from '@jelmoli/ui-components/src/helpers/A/A';
import { HorizontalScrollWrapper } from '@jelmoli/ui-components/src/helpers/HorizontalScrollWrapper/HorizontalScrollWrapper';
import { HorizontalSpacer } from '@jelmoli/ui-components/src/helpers/HorizontalSpacer/HorizontalSpacer';
import { hoverAnchorClassName } from '@jelmoli/ui-components/src/helpers/HoverAnchor/HoverAnchor';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { Visibility } from '@jelmoli/ui-components/src/helpers/Visibility/Visibility';
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { MyAccountCategoriesDrawer } from './Drawer/MyAccountCategoriesDrawer';
import './myaccount-categories.scss';
export var MyAccountCategoriesLazy = observer(function (_a) {
    var labels = _a.labels;
    var store = useRefInit(function () {
        var categoryStore = new CategoryStore();
        return [categoryStore];
    })[0];
    var t = bindTranslations(labels);
    var _b = useState(false), isDrawerOpen = _b[0], setIsDrawerOpen = _b[1];
    var _c = useState({ label: '', value: '' }), selectedCategory = _c[0], setSelectedCategory = _c[1];
    var chips = function (parentValue) {
        var rootElement = store.allChips.find(function (root) { return root && root.value === parentValue; });
        if (!rootElement) {
            return [];
        }
        return rootElement.children;
    };
    var toggleRootCategoryState = function (category) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!!store.allSavedCategories[category.value]) return [3 /*break*/, 1];
                    trackAddTopic(category.label);
                    store.saveCategory({ rootCategory: category.value, category: '' });
                    return [3 /*break*/, 4];
                case 1:
                    if (!(store.allSavedCategories[category.value].length > 0)) return [3 /*break*/, 3];
                    return [4 /*yield*/, confirmDialog(t('DeleteTopicTitle', { category: category.label }), t('SaveLabel'), t('AbortLabel'), t('DeleteTopicDescription'))];
                case 2:
                    if (_a.sent()) {
                        chips(category.value).map(function (chip) {
                            trackRemoveTopic(category.label, chip.label);
                        });
                        trackRemoveTopic(category.label);
                        store.removeRootCategory({ rootCategory: category.value });
                    }
                    return [3 /*break*/, 4];
                case 3:
                    trackRemoveTopic(category.label);
                    store.removeRootCategory({ rootCategory: category.value });
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(React.Fragment, null,
        React.createElement(Typography, { size: "m", type: "light" }, t('TopicLabel')),
        React.createElement(VerticalSpacer, { size: "m" }),
        store.rootCategories.map(function (category) { return (React.createElement(React.Fragment, { key: category.value },
            React.createElement("div", { className: "feat-myaccount-categories__category" },
                React.createElement(Typography, { type: "medium", size: "l" }, category.label),
                React.createElement("div", __assign({ tabIndex: 0, className: classnames('feat-myaccount-categories__bookmark', hoverAnchorClassName), onClick: function () { return toggleRootCategoryState(category); } }, preventOutline()),
                    React.createElement(Typography, { size: "xs", responsive: false }, store.allSavedCategories[category.value] ? t('RemoveLabel') : t('AddLabel')),
                    React.createElement(HorizontalSpacer, { size: "xxs", responsive: false }),
                    React.createElement(InteractiveIcon, { areaLabelText: "Toogle is preference favorite", active: store.allSavedCategories[category.value], width: 30 },
                        React.createElement(BookmarkIcon, { height: 18 })))),
            React.createElement(VerticalSpacer, { size: "xs", responsive: false }),
            store.allChips.some(function (root) { return root && root.value === category.value; }) && (React.createElement(React.Fragment, null,
                chips(category.value).length > 0 ? (React.createElement(React.Fragment, null,
                    React.createElement(VerticalSpacer, { size: "xs" }),
                    React.createElement(SubCategories, { label: t('DeleteAllCategoriesLabel'), list: chips(category.value), removeChip: function (value, label) {
                            trackRemoveTopic(category.label, label);
                            store.removeSubCategory({ rootCategory: category.value, category: value });
                        }, removeAllSubcategories: function () {
                            chips(category.value).map(function (chip) {
                                trackRemoveTopic(category.label, chip.label);
                            });
                            store.removeAllSubCategory({ rootCategory: category.value });
                        } }))) : (React.createElement(React.Fragment, null,
                    React.createElement(Typography, { type: "regular", size: "l", responsive: false }, t('NoCategoriesAddedLabel')),
                    React.createElement(VerticalSpacer, { size: "m" }))),
                React.createElement(Visibility, { show: ['xs', 'sm'] },
                    React.createElement(SecondaryButton, { type: "button", size: "tiny", fontSize: "xs", fontType: "medium", fontResponsive: false, fullWidth: true, text: t('AddSubcategoriesLabel'), onClick: function () {
                            setSelectedCategory({ label: category.label, value: category.value });
                            setIsDrawerOpen(true);
                        } })),
                React.createElement(Visibility, { hide: ['xs', 'sm'] },
                    React.createElement(SecondaryButton, { type: "button", size: "tiny", fontSize: "xs", fontType: "medium", fontResponsive: false, text: t('AddSubcategoriesLabel'), onClick: function () {
                            setSelectedCategory({ label: category.label, value: category.value });
                            setIsDrawerOpen(true);
                        } })),
                React.createElement(VerticalSpacer, { size: "l" }))))); }),
        React.createElement(MyAccountCategoriesDrawer, { labels: {
                title: labels.CategoriesForLabel,
                close: labels.CloseLabel,
            }, isOpen: isDrawerOpen, closeDrawer: function () { return setIsDrawerOpen(false); }, store: store, selectedCategory: selectedCategory })));
});
var SubCategories = observer(function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement(Visibility, { hide: ['xs', 'sm'] },
            React.createElement("div", { className: "feat-myaccount-categories__subcategories-wrapper" },
                props.list.map(function (item, index) { return (React.createElement("div", { key: item.value },
                    React.createElement("div", { className: "feat-myaccount-categories__subcategories-chip" },
                        React.createElement(Chip, { label: item.label, handleDelete: function () { return props.removeChip(item.value, item.label); } }),
                        props.list.length - 1 === index ? (React.createElement(HorizontalSpacer, { size: "xs", responsive: false })) : (React.createElement(HorizontalSpacer, { size: "xxs", responsive: false }))),
                    React.createElement(VerticalSpacer, { size: "xs", responsive: false }))); }),
                React.createElement("div", null,
                    React.createElement("div", { className: "feat-myaccount-categories__subcategories-remove-link" },
                        React.createElement(TextLink, { "aria-label": "remove all subcategories", underlined: true, color: "outer-space-neutral", onClick: props.removeAllSubcategories },
                            React.createElement(Typography, { type: "medium", size: "xxs", responsive: false }, props.label))),
                    React.createElement(VerticalSpacer, { size: "m" })))),
        React.createElement(Visibility, { show: ['xs', 'sm'] },
            React.createElement(HorizontalScrollWrapper, { leftArrow: React.createElement("div", { className: "feat-myaccount-categories__scroller-overlay feat-myaccount-categories__scroller-overlay--left" }), rightArrow: React.createElement("div", { className: "feat-myaccount-categories__scroller-overlay" }), fullWidth: true },
                props.list.map(function (item, index) { return (React.createElement(React.Fragment, { key: item.value },
                    React.createElement(Chip, { label: item.label, handleDelete: function () { return props.removeChip(item.value, item.label); } }),
                    props.list.length - 1 === index ? (React.createElement(HorizontalSpacer, { size: "xs", responsive: false })) : (React.createElement(HorizontalSpacer, { size: "xxs", responsive: false })))); }),
                props.list.length < 3 && (React.createElement("div", { className: "feat-myaccount-categories__subcategories-remove-link" },
                    React.createElement(TextLink, { "aria-label": "remove all subcategories", underlined: true, color: "outer-space-neutral", onClick: props.removeAllSubcategories },
                        React.createElement(Typography, { type: "medium", size: "xxs", responsive: false, wrap: "nowrap" }, props.label))))),
            props.list.length > 2 && (React.createElement(React.Fragment, null,
                React.createElement(VerticalSpacer, { size: "m" }),
                React.createElement("div", { className: "feat-myaccount-categories__subcategories-remove-link" },
                    React.createElement(TextLink, { "aria-label": "remove all subcategories", underlined: true, color: "outer-space-neutral", onClick: props.removeAllSubcategories },
                        React.createElement(Typography, { type: "medium", size: "xxs", responsive: false, wrap: "nowrap" }, props.label))))),
            React.createElement(VerticalSpacer, { size: "m" }))));
});
