var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useMiniFormik } from '@jelmoli/hooks/src/useMiniFormik/useMiniFormik';
import { bindTranslations } from '@jelmoli/i18n/index';
import { updateEmail } from '@jelmoli/sitecore-services/src/Account/updateEmail/updateEmail';
import { trackError } from '@jelmoli/sitecore-services/src/Tracking/Error/ErrorTesting';
import { FormFieldErrorMessage } from '@jelmoli/ui-components/src/atoms/FormFieldErrorMessage/FormFieldErrorMessage';
import { IconBoxInfo } from '@jelmoli/ui-components/src/atoms/Icon/IconBoxInfo';
import { showErrorNotification, showSuccessNotification, } from '@jelmoli/ui-components/src/atoms/Notification/Notification';
import { PasswordStrengthFieldWithHints } from '@jelmoli/ui-components/src/atoms/PasswordStrengthField/PasswordStrengthField';
import { PrimarySubmitButton } from '@jelmoli/ui-components/src/atoms/PrimarySubmitButton/PrimarySubmitButton';
import { SecondaryButton } from '@jelmoli/ui-components/src/atoms/SecondaryButton/SecondaryButton';
import { TextFieldWithFormSetting } from '@jelmoli/ui-components/src/atoms/TextFieldValidatable/TextFieldValidatable';
import { HorizontalSpacer } from '@jelmoli/ui-components/src/helpers/HorizontalSpacer/HorizontalSpacer';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { Visibility } from '@jelmoli/ui-components/src/helpers/Visibility/Visibility';
import { FormValidationContext } from 'nested-form-validation/index';
import React from 'react';
export var MyAccountProfileEmailModalContent = function (props) {
    var _a, _b;
    var t = bindTranslations(props);
    var form = useMiniFormik({
        initialValues: {
            newEmailAddress: '',
            currentPassword: '',
        },
    });
    return (React.createElement("div", null,
        React.createElement("div", { className: "o-myaccount-email__change-email-notification" },
            React.createElement(VerticalSpacer, { size: "m", responsive: false }),
            React.createElement("div", { className: "o-myaccount-email__change-email-notification-text-wrapper" },
                React.createElement("div", null,
                    React.createElement(IconBoxInfo, { width: 42, keepOriginalColors: true })),
                React.createElement(HorizontalSpacer, { size: "s", responsive: false }),
                React.createElement("div", { className: "o-myaccount-email__change-email-notification-text" },
                    React.createElement(Typography, { size: "xs", responsive: false }, t('OverlayChangeEmailInfo')))),
            React.createElement(VerticalSpacer, { size: "m", responsive: false })),
        React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
        React.createElement("span", { className: "o-myaccount-email-overlay__text--gray" },
            React.createElement(Typography, { size: "xs", uppercase: true, type: "medium", responsive: false }, t('OverlayCurrentEmailTitle'))),
        React.createElement(Typography, { tag: "div", size: "l", type: "medium", responsive: false }, props.currentEmailValue),
        React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
        React.createElement(FormValidationContext, null,
            React.createElement(TextFieldWithFormSetting, __assign({ name: "email", fullWidth: true, variant: "standard", formSetting: props.Email }, form.fieldProps.newEmailAddress)),
            React.createElement(FormFieldErrorMessage, { name: "email", verticalSpaceTop: "xxs" }),
            React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
            React.createElement(PasswordStrengthFieldWithHints, __assign({ variant: "outlined", name: "newPassword" }, form.fieldProps.currentPassword, { label: props.Password.fields.FieldLabel.value, fullWidth: true, hints: {
                    introductionText: props.Password.fields.IntroductionText.value,
                    minimumNumberOfCharactersRule: (_a = props.Password.fields.MinLengthError) === null || _a === void 0 ? void 0 : _a.value,
                    letterRule: props.Password.fields.LetterRule.value,
                    numberRule: props.Password.fields.NumberRule.value,
                    specialCharacterRule: props.Password.fields.SpecialCharacterRule.value,
                }, minNumberCharacters: (_b = props.Password.fields.MinLength) === null || _b === void 0 ? void 0 : _b.value })),
            React.createElement(VerticalSpacer, { size: "xl", responsive: false }),
            React.createElement(Visibility, { hide: ['xs', 'sm'] },
                React.createElement(SecondaryButton, { type: "button", text: t('CancelButton'), onClick: props.onClose }),
                React.createElement(HorizontalSpacer, { size: "s", responsive: false }),
                React.createElement(PrimarySubmitButton, { text: t('SaveButton'), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, updateEmail({}, {
                                        currentPassword: form.values.currentPassword,
                                        newEmailAddress: form.values.newEmailAddress,
                                    })
                                        .then(function (response) {
                                        showSuccessNotification(response.globalMessage);
                                    })
                                        .catch(function (response) {
                                        trackError({
                                            page: 'Error in updating email on MyAccount Profile page',
                                            errorMessage: response.GlobalMessage || 'Error',
                                        });
                                        showErrorNotification(response.GlobalMessage);
                                    })];
                                case 1:
                                    _a.sent();
                                    props.onClose();
                                    return [2 /*return*/];
                            }
                        });
                    }); } })),
            React.createElement(Visibility, { show: ['xs', 'sm'] },
                React.createElement(PrimarySubmitButton, { text: t('SaveButton'), fullWidth: true, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, updateEmail({}, {
                                        currentPassword: form.values.currentPassword,
                                        newEmailAddress: form.values.newEmailAddress,
                                    })
                                        .then(function (response) {
                                        showSuccessNotification(response.globalMessage);
                                    })
                                        .catch(function (response) {
                                        trackError({
                                            page: 'Error in updating email on MyAccount Profile page',
                                            errorMessage: response.GlobalMessage || 'Error',
                                        });
                                        showErrorNotification(response.GlobalMessage);
                                    })];
                                case 1:
                                    _a.sent();
                                    props.onClose();
                                    return [2 /*return*/];
                            }
                        });
                    }); } }),
                React.createElement(VerticalSpacer, { size: "xxs", responsive: false }),
                React.createElement(SecondaryButton, { type: "button", text: t('CancelButton'), fullWidth: true, onClick: props.onClose })))));
};
