import { useLoadNamedOnceOnClientSide } from '@jelmoli/hooks/src/useLoadOnceOnClientSide/useLoadOnceOnClientSide';
import { portalFactory } from '@jelmoli/ui-components/src/helpers/PortalFactory/PortalFactory';
import React from 'react';
var shopHeaderCollapsedComponentPortals = portalFactory(['CollapsedComponentControlsPortal']);
export var CollapsedComponentControlsPortal = shopHeaderCollapsedComponentPortals.portals.CollapsedComponentControlsPortal;
export var ShopHeaderCollapsedComponent = function (props) {
    var ShopHeaderCollapsedComponentLazy = useLoadNamedOnceOnClientSide('ShopHeaderCollapsedComponentLazy', function () {
        return import(/* webpackChunkName: "ShopHeaderCollapsedComponentLazy" */ './ShopHeaderCollapsedComponentLazy');
    });
    return ShopHeaderCollapsedComponentLazy ? (React.createElement(ShopHeaderCollapsedComponentLazy, { items: props.items, portalExits: shopHeaderCollapsedComponentPortals.portalExits, headerLocation: props.headerLocation, searchText: props.searchText })) : (React.createElement(React.Fragment, null));
};
