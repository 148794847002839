var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { useMiniFormik } from '@jelmoli/hooks/src/useMiniFormik/useMiniFormik';
import { bindTranslations, I18N } from '@jelmoli/i18n';
import { getCart } from '@jelmoli/sitecore-services/src/Cart/getCart/getCart';
import { DeliveryOptionConstants } from '@jelmoli/sitecore-services/src/Cart/getDeliveryOptions/getDeliveryOptions';
import { getPackagingOptions, PackagingOptionsConstants, } from '@jelmoli/sitecore-services/src/Cart/getPackagingOptions/getPackagingOptions';
import { getShippingMethods, ShippingMethodConstants, } from '@jelmoli/sitecore-services/src/Cart/getShippingMethods/getShippingMethods';
import { getCheckoutLabels } from '@jelmoli/sitecore-services/src/Checkout/getCheckoutLabels/getCheckoutLabels';
import { getDeliveryModeLabels } from '@jelmoli/sitecore-services/src/Checkout/getDeliveryLabels/getDeliveryModeSettings';
import { IconBoxInfo } from '@jelmoli/ui-components/src/atoms/Icon/IconBoxInfo';
import { InfoMessageBox } from '@jelmoli/ui-components/src/atoms/InfoMessageBox/InfoMessageBox';
import { PrimarySubmitButton } from '@jelmoli/ui-components/src/atoms/PrimarySubmitButton/PrimarySubmitButton';
import { getLabelsFromFormFields } from '@jelmoli/ui-components/src/helpers/SitecoreTypes/SitecoreTypes';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { FormValidationContext } from 'nested-form-validation';
import React, { useEffect, useState } from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
import { CheckoutPackagingOptions } from '../CheckoutPackagingOptions/CheckoutPackagingOptions';
import { setDeliveryOptionHomeUtil } from '../util/setDeliveryOption';
import { ShippingMethodBulkyProducts } from './HomeShippingMethods/ShippingMethodBulkyProducts';
import { DefaultShippingMethods } from './HomeShippingMethods/ShippingMethodDefault';
import { ShippingMethodInstallationService } from './HomeShippingMethods/ShippingMethodInstallationService';
export var CheckoutStepDeliveryOptionsHome = function (_a) {
    var visible = _a.visible, onComplete = _a.onComplete, zipCode = _a.zipCode;
    var packagingOptions = useGetEndPointLazy(getPackagingOptions).value;
    var cartData = useGetEndPointLazy(getCart).value;
    var shippingMethods = useGetEndPointLazy(getShippingMethods, { zipCode: zipCode }).value;
    var checkoutLabels = useGetEndPointLazy(getCheckoutLabels).value;
    var deliverySettingsLabels = useGetEndPointLazy(getDeliveryModeLabels).value;
    var _b = useState(false), deliveryNotification = _b[0], setDeliveryNotification = _b[1];
    var _c = useState(false), isInstalationServiceMobileEntered = _c[0], setisInstalationServiceMobileEntered = _c[1];
    var form = useMiniFormik({
        initialValues: {
            shippingMethod: ShippingMethodConstants.standard,
            packagingOptions: PackagingOptionsConstants.standard,
            scheduledDeliveryDay: '',
            scheduledDeliveryTimeslot: '',
            mobile: '',
        },
    });
    var _d = useState(false), isInstallationServiceSelected = _d[0], setIsInstallationServiceSelected = _d[1];
    var _e = useState([]), isSelectedService = _e[0], setIsSelectedService = _e[1];
    var _f = useState(false), noServiceIsSelected = _f[0], setNoServiceIsSelected = _f[1];
    var _g = useState(false), isSavePhone = _g[0], setIsSavePhone = _g[1];
    useEffect(function () {
        var deliveryOption = cartData && cartData.deliveryOption;
        var orderInformation = cartData && cartData.orderInformation;
        var lines = cartData && cartData.lines;
        if (!shippingMethods) {
            return;
        }
        /** Set methods for bulky or installationService */
        if (lines) {
            var bulky_1 = lines.some(function (line) { return line.fulfillmentFlags && line.fulfillmentFlags.bulky; });
            if (bulky_1) {
                form.setValues(__assign(__assign({}, form.values), { shippingMethod: ShippingMethodConstants.bulky, mobile: orderInformation === null || orderInformation === void 0 ? void 0 : orderInformation.mobile }));
            }
            lines.forEach(function (line) {
                if (line.fulfillmentFlags && line.fulfillmentFlags.installationServiceSelected) {
                    setIsSelectedService(__spreadArrays(isSelectedService, [line.cartLineId]));
                    setIsInstallationServiceSelected(!bulky_1);
                }
            });
            var hasMandatoryInstallationService = lines.some(function (line) { return line.fulfillmentFlags && line.fulfillmentFlags.installationServiceMandatory; });
            if (hasMandatoryInstallationService || isSelectedService.length > 0) {
                form.setValues(__assign(__assign({}, form.values), { shippingMethod: ShippingMethodConstants.installationService }));
            }
        }
        /** Set intial values for scheduled delivery option */
        if (!form.values.scheduledDeliveryDay) {
            var scheduledDeliveryMehod = shippingMethods.find(function (method) { return method.externalId === ShippingMethodConstants.nextDay; });
            if (scheduledDeliveryMehod &&
                scheduledDeliveryMehod.scheduledDeliveryDays &&
                scheduledDeliveryMehod.timeslots &&
                scheduledDeliveryMehod.timeslots.length > 0) {
                form.setValues(__assign(__assign({}, form.values), { scheduledDeliveryDay: scheduledDeliveryMehod.scheduledDeliveryDays[0].date, scheduledDeliveryTimeslot: scheduledDeliveryMehod.timeslots[0]
                        .externalId }));
            }
        }
        if (deliveryOption && deliveryOption.deliveryOptionExternalId === DeliveryOptionConstants.home) {
            var deliveryOptionFromCart = shippingMethods.find(function (option) { return option.externalId === deliveryOption.shippingMethodExternalId; });
            if (deliveryOption.installationServicePhoneNumber) {
                setisInstalationServiceMobileEntered(true);
                form.setValues({ mobile: deliveryOption.installationServicePhoneNumber });
            }
            if (deliveryOptionFromCart && !deliveryOptionFromCart.available) {
                setDeliveryNotification(true);
            }
            else {
                /** Prevent overruling of intial values for scheduled delivery option when it's not saved as an option in cart */
                if (ShippingMethodConstants.nextDay === deliveryOption.shippingMethodExternalId) {
                    form.setValues({
                        shippingMethod: deliveryOption.shippingMethodExternalId,
                        packagingOptions: deliveryOption.packagingOptionExternalId,
                        scheduledDeliveryDay: deliveryOption.scheduledDeliveryDay,
                        scheduledDeliveryTimeslot: deliveryOption.scheduledDeliveryTimeslotId,
                    });
                }
                else {
                    form.setValues(__assign(__assign({}, form.values), { shippingMethod: deliveryOption.shippingMethodExternalId, packagingOptions: deliveryOption.packagingOptionExternalId }));
                }
            }
        }
    }, [cartData, shippingMethods]);
    // Update shipping method when user deselects/selects installation service
    useEffect(function () {
        var isAnyProductBulky = cartData &&
            cartData.lines.some(function (line) {
                return line.fulfillmentFlags &&
                    (line.fulfillmentFlags.bulky || line.fulfillmentFlags.installationServiceMandatory);
            });
        if (!isAnyProductBulky) {
            return;
        }
        if (isSelectedService.length === 0) {
            form.setValues(__assign(__assign({}, form.values), { shippingMethod: ShippingMethodConstants.bulky }));
            return;
        }
        form.setValues(__assign(__assign({}, form.values), { shippingMethod: ShippingMethodConstants.installationService }));
    }, [isSelectedService.length]);
    if (!visible || !shippingMethods || !packagingOptions || !checkoutLabels || !deliverySettingsLabels || !cartData) {
        return null;
    }
    var t = bindTranslations(__assign(__assign({}, checkoutLabels), getLabelsFromFormFields(checkoutLabels)));
    var b = bindTranslations(deliverySettingsLabels);
    var oldMethodName = cartData.deliveryOption &&
        cartData.deliveryOption.deliveryOptionExternalId === DeliveryOptionConstants.home &&
        cartData.deliveryOption.shippingMethodDisplayName;
    var installationService = shippingMethods.find(function (method) { return method.externalId === ShippingMethodConstants.installationService; });
    var bulky = shippingMethods.find(function (method) { return method.externalId === ShippingMethodConstants.bulky; });
    var productHaveOptionalInstallationService = cartData.lines.some(function (line) {
        if (line.fulfillmentFlags && !line.fulfillmentFlags.installationServiceMandatory) {
            return line.fulfillmentFlags && line.fulfillmentFlags.installationServiceAvailable;
        }
    });
    var isAnyProductBulky = cartData.lines.some(function (line) {
        return line.fulfillmentFlags && (line.fulfillmentFlags.bulky || line.fulfillmentFlags.installationServiceMandatory);
    });
    return (React.createElement(FormValidationContext, null,
        deliveryNotification && oldMethodName && (React.createElement(React.Fragment, null,
            React.createElement(InfoMessageBox, { text: React.createElement(Typography, { size: "xs", type: "regular", responsive: false },
                    React.createElement(I18N, { text: b('DeliveryModeCurrentlyNotAvailable'), replacements: {
                            oldMethodName: React.createElement(React.Fragment, null,
                                "\"",
                                oldMethodName,
                                "\""),
                        } })), icon: React.createElement(IconBoxInfo, { width: 42, keepOriginalColors: true }) }),
            React.createElement(VerticalSpacer, { size: "mx", responsive: false }))),
        isAnyProductBulky ? (React.createElement(ShippingMethodBulkyProducts, { form: form, installationService: installationService, bulky: bulky, isSelectedService: isSelectedService, setIsSelectedService: setIsSelectedService, isMobileAdded: isInstalationServiceMobileEntered, isSavePhone: isSavePhone, setIsSavePhone: setIsSavePhone })) : (React.createElement(React.Fragment, null,
            productHaveOptionalInstallationService && (React.createElement(ShippingMethodInstallationService, { error: noServiceIsSelected, isSelectedService: isSelectedService, installationService: installationService, setIsSelectedService: setIsSelectedService, isInstallationServiceSelected: isInstallationServiceSelected, setIsInstallationServiceSelected: setIsInstallationServiceSelected, isMobileAdded: isInstalationServiceMobileEntered, form: form, isSavePhone: isSavePhone, setIsSavePhone: setIsSavePhone })),
            React.createElement(DefaultShippingMethods, { isDisabled: isInstallationServiceSelected, form: form, filteredShippingMethod: shippingMethods.filter(function (method) { return method.isTechnical === false; }) }),
            React.createElement(VerticalSpacer, { size: "xl", responsive: false }),
            React.createElement(CheckoutPackagingOptions, { fields: form.fieldProps, isDisabled: isInstallationServiceSelected }))),
        React.createElement(VerticalSpacer, { size: "xl", responsive: false }),
        React.createElement(PrimarySubmitButton, { onClick: function () {
                var isAnyInstallationServiceSelected = isInstallationServiceSelected && isSelectedService.length === 0;
                if (isAnyInstallationServiceSelected) {
                    return;
                }
                return setDeliveryOptionHomeUtil(onComplete, formatDataForSubmit(cartData, isSelectedService, form, zipCode, isSavePhone));
            }, type: "button", size: "tiny", text: t('CheckoutStepperSaveStepCtaButtonText') })));
};
var formatDataForSubmit = function (cartData, isSelectedService, form, zipCode, isSavePhone) {
    var savedInstallationService = [];
    cartData.lines.forEach(function (line) {
        var isMandatorySaved = isSelectedService.some(function (service) {
            return line.fulfillmentFlags &&
                line.fulfillmentFlags.installationServiceMandatory &&
                service === line.cartLineId;
        });
        if (isMandatorySaved || isSelectedService.some(function (service) { return service === line.cartLineId; })) {
            savedInstallationService.push(line.cartLineId);
        }
        else {
            line.fulfillmentFlags &&
                line.fulfillmentFlags.installationServiceMandatory &&
                savedInstallationService.push(line.cartLineId);
        }
    });
    var formatedData = __assign(__assign({ shippingMethodExternalId: savedInstallationService.length > 0
            ? ShippingMethodConstants.installationService
            : form.values.shippingMethod, packagingOptionExternalId: form.values.packagingOptions, scheduledDeliveryDay: form.values.shippingMethod !== ShippingMethodConstants.nextDay ? '' : form.values.scheduledDeliveryDay, scheduledDeliveryTimeslotId: form.values.shippingMethod !== ShippingMethodConstants.nextDay ? '' : form.values.scheduledDeliveryTimeslot, installationServiceSelected: savedInstallationService }, (ShippingMethodConstants.sameDaySwitzerland === form.values.shippingMethod && {
        postalCode: zipCode,
    })), { installationServicePhoneNumber: isSavePhone ? form.fieldProps.mobile.value : '' });
    return formatedData;
};
