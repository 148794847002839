// Import Processor Dependencies:
import '@jelmoli/ui-components/src/atoms/Divider/Divider';
import '@jelmoli/ui-components/src/atoms/OrderedList/OrderedList';
import '@jelmoli/ui-components/src/atoms/TextLink/TextLink';
import '@jelmoli/ui-components/src/atoms/UnorderedList/UnorderedList';
import { useIsSitecoreEditMode } from '@jelmoli/ui-components/src/helpers/EditMode/editMode';
import '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect, useState } from 'react';
import { ssrPreprocessor } from './RichTextComponent.ssr';
export var RichTextComponent = function (props) {
    var editMode = useIsSitecoreEditMode();
    var _a = useState(false), isPrefetch = _a[0], setIsPrefetch = _a[1];
    // Lazyload editmode styles if in editmode
    useEffect(function () {
        if (editMode) {
            import('./EditMode/EditModeStyle');
        }
    }, [editMode]);
    useEffect(function () {
        if (typeof document === undefined) {
            return;
        }
        var prefetch = document.head.getAttribute('prefetch');
        if (prefetch) {
            setIsPrefetch(true);
        }
    }, []);
    return (React.createElement(RichText, { field: isPrefetch ? ssrPreprocessor(props).fields.RichTextContent : props.fields.RichTextContent, className: editMode ? 'feat-rich-text-component--edit-mode' : '' }));
};
