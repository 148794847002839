var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { default as MUiTab } from '@material-ui/core/Tab';
import React from 'react';
import { Typography } from '../../helpers/Typography/Typography';
import { VerticalSpacer } from '../../helpers/VerticalSpacer/VerticalSpacer';
import './tab.scss';
export var Tab = function (props) { return (React.createElement(MUiTab, __assign({ classes: { root: 'a-tab', selected: 'a-tab--selected', disabled: 'a-tab--disabled' }, disableFocusRipple: true, disableRipple: true }, props, { label: typeof props.label === 'string' ? (React.createElement(React.Fragment, null,
        React.createElement("span", { className: "a-tab__text" },
            React.createElement(Typography, { size: "m", type: "medium" }, props.label)),
        React.createElement(VerticalSpacer, { size: "s" }))) : (props.label) }))); };
