import { bindTranslations } from '@jelmoli/i18n';
import { getCartLabels } from '@jelmoli/sitecore-services/src/Cart/getCartLabels/getCartLabels';
import { getCartTotalCount } from '@jelmoli/sitecore-services/src/Cart/getCartTotalCount/getCartTotalCount';
import { HeaderButton, HeaderButtonBadge } from '@jelmoli/ui-components/src/atoms/HeaderButton/HeaderButton';
import { Bag } from '@jelmoli/ui-components/src/atoms/Icon/Bag';
import { Cart } from '@jelmoli/ui-components/src/organism/Cart/Cart';
import React, { useState } from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
export var HeaderCartButtonComponent = function () {
    var _a = useState(false), cartDrawerOpen = _a[0], setCartDrawerOpen = _a[1];
    var cartTotalCountData = useGetEndPointLazy(getCartTotalCount, {});
    var cartAmount = cartTotalCountData.hasData ? cartTotalCountData.value.productTotalCount : 0;
    var itemsInCart = cartAmount > 0 ? cartAmount : '';
    var cartLabelsData = useGetEndPointLazy(getCartLabels, {}).value;
    var t = bindTranslations(cartLabelsData);
    return (React.createElement(React.Fragment, null,
        React.createElement(HeaderButton, { Icon: Bag, title: t('CartIconHoverText'), onClick: function () { return setCartDrawerOpen(true); } },
            React.createElement(HeaderButtonBadge, null, itemsInCart)),
        React.createElement(Cart, { isOpen: cartDrawerOpen, openDrawer: setCartDrawerOpen })));
};
