var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Link as SitecoreLink } from '@sitecore-jss/sitecore-jss-react';
import classNames from 'classnames';
import React from 'react';
import { preventOutline } from '../../helpers/A/A';
import { useIsSitecoreEditMode } from '../../helpers/EditMode/editMode';
import { hoverAnchorClassName } from '../../helpers/HoverAnchor/HoverAnchor';
import { hasLinkFieldValue } from '../../helpers/SitecoreTypes/SitecoreTypes';
import { Typography } from '../../helpers/Typography/Typography';
import './link.scss';
export var Link = function (props) {
    var inEditMode = useIsSitecoreEditMode();
    var size = props.size, type = props.type, uppercase = props.uppercase, field = props.field, editable = props.editable, responsive = props.responsive;
    var renderLink = (inEditMode && props.editable !== false) || hasLinkFieldValue(props.field);
    return (React.createElement(Typography, { size: size, type: type, responsive: responsive, uppercase: uppercase }, renderLink ? (React.createElement(SitecoreLink, __assign({ field: field, className: classNames('a-link', hoverAnchorClassName), editable: editable }, preventOutline()), props.children)) : (props.children)));
};
