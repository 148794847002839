var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { bindTranslations } from '@jelmoli/i18n/index';
import { Separator } from '@jelmoli/ui-components/src/atoms/Separator/Separator';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import React from 'react';
import { MyAccountDeactivateLoyalityProgram } from './MyAccountDeactivateLoyalityProgram/MyAccountDeactivateLoyalityProgram';
import { MyAccountProfileEmail } from './MyAccountProfileEmail/MyAccountProfileEmail';
import { MyAccountProfileGetMyData } from './MyAccountProfileGetMyData/MyAccountProfileGetMyData';
import { MyAccountProfileJMagazin } from './MyAccountProfileJMagazin/MyAccountProfileJMagazinComponent';
import { MyAccountProfileMainAddress } from './MyAccountProfileMainAddress/MyAccountProfileMainAddress';
import { MyAccountProfileNewsletter } from './MyAccountProfileNewsletter/MyAccountProfileNewsletter';
import { MyAccountProfileOverview } from './MyAccountProfileOverview/MyAccountProfileOverview';
import { MyAccountProfilePassword } from './MyAccountProfilePassword/MyAccountProfilePassword';
export var MyAccountProfileComponent = function (props) {
    var t = bindTranslations(props.fields);
    return (React.createElement("div", null,
        React.createElement(MyAccountProfileOverview, __assign({}, props.fields)),
        React.createElement(VerticalSpacer, { size: "xl" }),
        React.createElement(Separator, { shade: "two" }),
        React.createElement(VerticalSpacer, { size: "xl" }),
        React.createElement(MyAccountProfileMainAddress, __assign({}, props.fields)),
        React.createElement(VerticalSpacer, { size: "xl" }),
        React.createElement(Separator, { shade: "two" }),
        React.createElement(VerticalSpacer, { size: "xl" }),
        React.createElement(MyAccountProfileEmail, __assign({}, props.fields)),
        React.createElement(VerticalSpacer, { size: "xl" }),
        React.createElement(Separator, { shade: "two" }),
        React.createElement(VerticalSpacer, { size: "xl" }),
        React.createElement(MyAccountProfilePassword, __assign({}, props.fields)),
        React.createElement(VerticalSpacer, { size: "xxl" }),
        React.createElement(Separator, { shade: "two" }),
        React.createElement(VerticalSpacer, { size: "xxl" }),
        React.createElement(Typography, { size: "m", type: "light" }, t('SubscriptionsTitle')),
        React.createElement(VerticalSpacer, { size: "mx" }),
        React.createElement(MyAccountProfileNewsletter, __assign({}, props.fields)),
        React.createElement(VerticalSpacer, { size: "xl" }),
        React.createElement(Separator, { shade: "two" }),
        React.createElement(VerticalSpacer, { size: "xl" }),
        React.createElement(MyAccountProfileJMagazin, __assign({}, props.fields)),
        React.createElement(VerticalSpacer, { size: "xl" }),
        React.createElement(Separator, { shade: "two" }),
        React.createElement(VerticalSpacer, { size: "xl" }),
        React.createElement(MyAccountDeactivateLoyalityProgram, __assign({}, props.fields)),
        React.createElement(VerticalSpacer, { size: "xxl" }),
        false && (React.createElement(React.Fragment, null,
            React.createElement(Separator, { shade: "two" }),
            React.createElement(VerticalSpacer, { size: "xxl" }),
            React.createElement(Typography, { size: "m", type: "light" }, t('MyDataTitle')),
            React.createElement(VerticalSpacer, { size: "mx" }),
            React.createElement(MyAccountProfileGetMyData, __assign({}, props.fields)))),
        React.createElement(VerticalSpacer, { size: "xl" })));
};
