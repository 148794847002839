import { Column, Row } from '@jelmoli/bootstrap-grid';
import { Separator } from '@jelmoli/ui-components/src/atoms/Separator/Separator';
import { Container } from '@jelmoli/ui-components/src/helpers/Container/Container';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { Visibility } from '@jelmoli/ui-components/src/helpers/Visibility/Visibility';
import React from 'react';
import { FooterDeleveryMethods } from './FooterDeleveryMethods/FooterDeleveryMethods';
import { FooterLegalLinks } from './FooterLegalLinks/FooterLegalLinks';
import { FooterLinks } from './FooterLinks/FooterLinks';
import { FooterNewsletter } from './FooterNewsletter/FooterNewsletter';
import { FooterPaymentMethods } from './FooterPaymentMethods/FooterPaymentMethods';
export var FooterComponent = function (_a) {
    var fields = _a.fields;
    return (React.createElement(Container, { size: "s" },
        React.createElement(Row, null,
            React.createElement(Column, { size: 12, horizontalAlignment: "center" },
                React.createElement(VerticalSpacer, { size: "xxl" }),
                React.createElement(Typography, { size: "m", type: "bold" }, fields.FooterIntroTitle.value),
                React.createElement(VerticalSpacer, { size: "m" })),
            React.createElement(Column, { size: 12 },
                React.createElement(FooterNewsletter, { fields: fields })),
            React.createElement(Column, { size: 12 },
                React.createElement(FooterLinks, { fields: fields }),
                React.createElement(VerticalSpacer, { size: 'xxl', show: ['xs', 'sm'] }),
                React.createElement(Visibility, { hide: ['xs', 'sm'] },
                    React.createElement(VerticalSpacer, { size: 'l' }),
                    React.createElement(Separator, { shade: "green" }),
                    React.createElement(VerticalSpacer, { size: 'l' }))),
            React.createElement(Column, { size: 12 },
                React.createElement(FooterPaymentMethods, { fields: fields })),
            React.createElement(Column, { size: 12 },
                React.createElement(FooterDeleveryMethods, { fields: fields })),
            React.createElement(Column, { size: 12 },
                React.createElement(FooterLegalLinks, { fields: fields })))));
};
