import { createPutEndpoint } from 'request-registry';
import { getCSRFToken } from '../../Security/getCSRFToken/getCSRFToken';
import { getProfile } from '../getProfile/getProfile';
export var updateEmail = createPutEndpoint({
    url: function () { return '/jelmoli-api/JelmoliChangeEmail/ChangeRequest'; },
    headers: {
        'X-RequestVerificationToken': function () { return getCSRFToken(); },
        'X-Requested-With': 'XMLHttpRequest',
    },
    afterSuccess: function () { return Promise.all([getProfile.refresh()]); },
});
