import classnames from 'classnames';
import React from 'react';
import './number-input.scss';
export var NumberInput = function (_a) {
    var value = _a.value, type = _a.type, _b = _a.increment, increment = _b === void 0 ? 1 : _b, _c = _a.max, max = _c === void 0 ? Infinity : _c, min = _a.min, onChange = _a.onChange, disableIncrease = _a.disableIncrease, disableDecrease = _a.disableDecrease, disabled = _a.disabled;
    return (React.createElement("label", { className: classnames('a-number-input', type && "a-number-input--" + type, disabled && 'a-number-input--disabled') },
        React.createElement("button", { type: "button", "aria-label": "button decrease", className: classnames('a-number-input__btn', 'a-number-input__btn-decrease', {
                'a-number-input__btn--disabled': disableDecrease,
            }), onClick: function () { return onChange(numberWithin(value - increment, min, max)); }, disabled: disableDecrease }),
        React.createElement("input", { type: "text", className: "a-number-input__input", value: value, onChange: function (event) {
                if (disableIncrease === true && disableDecrease === true) {
                    return;
                }
                if (isNaN(Number(event.currentTarget.value))) {
                    onChange(1);
                    return;
                }
                onChange(Number(event.currentTarget.value));
            }, onBlur: function () {
                if (disableIncrease === true && disableDecrease === true) {
                    return;
                }
                if (Number(value) !== numberWithin(value, min, max)) {
                    onChange(numberWithin(value, min, max));
                }
            } }),
        React.createElement("button", { type: "button", "aria-label": "button increase", className: classnames('a-number-input__btn', 'a-number-input__btn-increase', {
                'a-number-input__btn--disabled': disableIncrease,
            }), onClick: function () { return onChange(numberWithin(value + increment, min, max)); }, disabled: disableIncrease })));
};
var numberWithin = function (value, min, max) {
    return Math.min(max, Math.max(min, Number(value || 0)));
};
