var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { bindTranslations } from '@jelmoli/i18n/index';
import { getCart } from '@jelmoli/sitecore-services/src/Cart/getCart/getCart';
import { DeliveryOptionConstants } from '@jelmoli/sitecore-services/src/Cart/getDeliveryOptions/getDeliveryOptions';
import { ShippingMethodConstantMap, ShippingMethodConstants, } from '@jelmoli/sitecore-services/src/Cart/getShippingMethods/getShippingMethods';
import { getCheckoutLabels } from '@jelmoli/sitecore-services/src/Checkout/getCheckoutLabels/getCheckoutLabels';
import { getDeliveryModeLabels } from '@jelmoli/sitecore-services/src/Checkout/getDeliveryLabels/getDeliveryModeSettings';
import { Dropdown } from '@jelmoli/ui-components/src/atoms/Dropdown/Dropdown';
import { DropdownItem } from '@jelmoli/ui-components/src/atoms/DropdownItem/DropdownItem';
import { IcoBoxAlert } from '@jelmoli/ui-components/src/atoms/Icon/IcoBoxAlert';
import { InfoMessageBox } from '@jelmoli/ui-components/src/atoms/InfoMessageBox/InfoMessageBox';
import { RadioButtons } from '@jelmoli/ui-components/src/atoms/RadioButtons/RadioButtons';
import { RadioButtonsGroup } from '@jelmoli/ui-components/src/atoms/RadioButtonsGroup/RadioButtonsGroup';
import { defaultReplacements, onlyTagsWithInnerHTML, RichTextRenderer, } from '@jelmoli/ui-components/src/helpers/RichTextRenderer/RichTextRenderer';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
export var DefaultShippingMethods = function (props) {
    var checkoutLabels = useGetEndPointLazy(getCheckoutLabels).value;
    var deliverySettingsLabels = useGetEndPointLazy(getDeliveryModeLabels).value;
    var _a = useState(undefined), shipingPriceTotal = _a[0], setShipingPriceTotal = _a[1];
    var cartData = useGetEndPointLazy(getCart).value;
    var t = bindTranslations(checkoutLabels);
    var b = bindTranslations(deliverySettingsLabels);
    useEffect(function () {
        if (!cartData) {
            return;
        }
        var shippingValue = cartData.shippingTotal.match(/\d+/);
        if (shippingValue) {
            setShipingPriceTotal(parseInt(shippingValue[0], 10));
        }
        var deliveryOption = cartData.deliveryOption;
        if (!deliveryOption || deliveryOption.deliveryOptionExternalId !== DeliveryOptionConstants.home) {
            return;
        }
    }, [cartData]);
    if (!cartData) {
        return null;
    }
    var isAnyProductZyseMe = cartData.lines.some(function (product) { return product.typeOfGood === 'ZyseMe'; });
    return (React.createElement(React.Fragment, null,
        isAnyProductZyseMe && (React.createElement(React.Fragment, null,
            React.createElement(InfoMessageBox, { text: React.createElement(Typography, { size: "xs", type: "regular", responsive: false }, t('CheckoutDeliverOptionsZyseMeNote')), icon: React.createElement(IcoBoxAlert, { width: 42, keepOriginalColors: true }) }),
            React.createElement(VerticalSpacer, { size: "mx", responsive: false }))),
        React.createElement(RadioButtonsGroup, __assign({ name: "shippingMethod", row: false }, props.form.fieldProps.shippingMethod), props.filteredShippingMethod.map(function (shippingMethod) {
            var shippingMethodTranslation = bindTranslations(shippingMethod);
            var selectedTimeslotIndex = shippingMethod.timeslots &&
                shippingMethod.timeslots.findIndex(function (timelslot) { return timelslot.externalId === props.form.values.scheduledDeliveryTimeslot; });
            var selectedTimeslot = shippingMethod.timeslots &&
                typeof selectedTimeslotIndex === 'number' &&
                shippingMethod.timeslots[selectedTimeslotIndex];
            return (React.createElement(RadioButtons, { type: "fullwidth", disabled: !shippingMethod.available || props.isDisabled, value: shippingMethod.externalId, key: shippingMethod.externalId }, shippingMethod.externalId === ShippingMethodConstants.nextDay ? (React.createElement(React.Fragment, null,
                React.createElement("span", { className: "feat-checkout-step-delivery-option__text" }, shippingMethodTranslation('name', {
                    price: "(" + (selectedTimeslot ? selectedTimeslot.price : t('FreeLabel')) + ")",
                })),
                React.createElement("span", { className: "feat-checkout-step-delivery-option__text" }, shippingMethod.available
                    ? shippingMethod.description
                    : b('DeliveryModeNotAvailable')),
                shippingMethod.available && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: classnames('feat-checkout-step-delivery-options__dropdowns', {
                            'feat-checkout-step-delivery-options__dropdowns--visible': props.form.values.shippingMethod ===
                                ShippingMethodConstants.nextDay,
                        }) },
                        React.createElement(VerticalSpacer, { size: "xs", responsive: false }),
                        React.createElement(Dropdown, __assign({ type: "fullwidth", label: "" }, props.form.fieldProps.scheduledDeliveryDay), shippingMethod.scheduledDeliveryDays &&
                            shippingMethod.scheduledDeliveryDays.map(function (deliveryDays) { return (React.createElement(DropdownItem, { value: deliveryDays.date, key: deliveryDays.date },
                                deliveryDays.displayName,
                                ", ",
                                deliveryDays.date)); })),
                        React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
                        React.createElement(Dropdown, __assign({ type: "fullwidth", label: "" }, props.form.fieldProps.scheduledDeliveryTimeslot), shippingMethod.timeslots &&
                            shippingMethod.timeslots.map(function (timeslot) {
                                var translateLabel = bindTranslations(timeslot);
                                return (React.createElement(DropdownItem, { value: timeslot.externalId, key: timeslot.externalId }, translateLabel('name', {
                                    price: timeslot.price || t('FreeLabel'),
                                })));
                            }))),
                    shippingMethod.scheduledDeliveryDays &&
                        props.form.values.shippingMethod ===
                            ShippingMethodConstantMap.nextDay &&
                        shippingMethod.scheduledDeliveryDays.find(function (day) {
                            return props.form.values.scheduledDeliveryDay === day.date &&
                                day.isSaturday;
                        }) && (React.createElement(React.Fragment, null,
                        React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
                        React.createElement(RichTextRenderer, { replaceRules: {
                                P: onlyTagsWithInnerHTML(function (content) { return (React.createElement(Typography, { wrap: "regular", size: "l", tag: "p" },
                                    content,
                                    React.createElement(VerticalSpacer, { size: "xl" }))); }),
                                A: defaultReplacements.A,
                            }, html: b('SaturdayDeliveryInformation'), className: "feat-checkout-step-delivery-option__saturday" }))))))) : (React.createElement(React.Fragment, null,
                React.createElement("span", { className: "feat-checkout-step-delivery-option__text" }, shippingMethodTranslation('name', {
                    price: shippingMethod.price
                        ? "(" + shippingMethod.price + ")"
                        : !shippingMethod.available &&
                            shippingMethod.externalId === ShippingMethodConstants.sameDay
                            ? ''
                            : "(" + t('FreeLabel') + ")",
                })),
                React.createElement("span", { className: "feat-checkout-step-delivery-option__text" }, shippingMethod.available
                    ? shippingMethod.description
                    : b('DeliveryModeNotAvailable')),
                shippingMethod.externalId === ShippingMethodConstants.standard &&
                    shipingPriceTotal &&
                    shipingPriceTotal > 0 && (React.createElement("span", { className: "feat-checkout-step-delivery-option__text feat-checkout-step-delivery-option__text--small" }, t('FreeShippingThresholdLabel')))))));
        }))));
};
