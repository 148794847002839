var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import InputAdornment from '@material-ui/core/InputAdornment';
import classnames from 'classnames';
import React, { useState } from 'react';
import { TextFieldValidatable, TextFieldWithFormSetting, } from '../TextFieldValidatable/TextFieldValidatable';
import './password-field.scss';
export var PasswordFieldValidatable = function (props) {
    var _a = useState(false), showPassword = _a[0], setShowPassword = _a[1];
    return (React.createElement(TextFieldValidatable, __assign({}, props, { type: !showPassword ? 'password' : 'text', InputProps: {
            endAdornment: (React.createElement(PasswordFieldVisiblityButton, { showPassword: showPassword, setShowPassword: setShowPassword })),
        } })));
};
export var PasswordFieldValidatableWithFormSetting = function (props) {
    var _a = useState(false), showPassword = _a[0], setShowPassword = _a[1];
    return (React.createElement(TextFieldWithFormSetting, __assign({}, props, { type: !showPassword ? 'password' : 'text', InputProps: {
            endAdornment: (React.createElement(PasswordFieldVisiblityButton, { showPassword: showPassword, setShowPassword: setShowPassword })),
        } })));
};
var PasswordFieldVisiblityButton = function (_a) {
    var showPassword = _a.showPassword, setShowPassword = _a.setShowPassword;
    return (React.createElement(InputAdornment, { position: "end" },
        React.createElement("span", { className: classnames('a-password__visibility-icon', {
                'a-password__visibility-icon--visible': showPassword,
            }), onClick: function () { return setShowPassword(!showPassword); } })));
};
