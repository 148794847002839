import { bindTranslations } from '@jelmoli/i18n/index';
import { ScrollAnchor } from '@jelmoli/ui-components/src/helpers/ScrollAnchor/ScrollAnchor';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import React, { useState } from 'react';
import './my-account-deactivate-loyality-program.scss';
export var MyAccountDeactivateLoyalityProgram = function (props) {
    var _a = useState(false), isOverlayOpen = _a[0], setIsOverlayOpen = _a[1];
    var t = bindTranslations(props);
    return (React.createElement(React.Fragment, null,
        React.createElement(ScrollAnchor, { id: 'deactivate' }),
        React.createElement(Typography, { size: "l", type: "medium" }, t('UnsubscribeLoyaltyProgramTitle')),
        React.createElement(VerticalSpacer, { size: "s" }),
        React.createElement(Typography, { tag: "div", size: "l", type: "regular" }, t('UnsubscribeLoyaltyProgramDescription')),
        React.createElement(VerticalSpacer, { size: "mx" })));
};
