import { track } from '@jelmoli/google-tag-manager';
export function trackingClickAndReserve(props) {
    track({
        event: 'gaEvent',
        event_name: 'click_and_reserve',
        click_and_reserve: {
            product_id: props.productId,
            event_category: 'click_and_reserve',
            event_action: props.productId || props.brandName || undefined,
            event_label: props.location,
            brand_name: props.brandName,
        },
    });
}
