var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Column, Row } from '@jelmoli/bootstrap-grid';
import { useLoadNamedOnceOnClientSide } from '@jelmoli/hooks/src/useLoadOnceOnClientSide/useLoadOnceOnClientSide';
import { Spinner } from '@jelmoli/ui-components/src/atoms/Spinner/Spinner';
import React from 'react';
import { MyAccountCategoriesLazy } from './MyAccountCategoriesLazy';
export var MyAccountCategoriesComponent = function (props) {
    var MyAccountCategories = useLoadNamedOnceOnClientSide('MyAccountCategoriesLazy', function () {
        return import(/* webpackChunkName: "MyAccountCategoriesLazy" */ './MyAccountCategoriesLazy');
    });
    return (React.createElement(React.Fragment, null, MyAccountCategories ? (React.createElement(MyAccountCategoriesLazy, __assign({}, props))) : (React.createElement(Row, null,
        React.createElement(Column, { size: 12, horizontalAlignment: "center" },
            React.createElement(Spinner, null))))));
};
