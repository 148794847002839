var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useAsyncUiEvent } from '@jelmoli/hooks/src/useAsyncUiEvent/useAsyncUiEvent';
import { isUserLoggedInLazy } from '@jelmoli/sitecore-services/src/Account/isUserLoggedIn/isUserLoggedInLazy';
import { trackError } from '@jelmoli/sitecore-services/src/Tracking/Error/ErrorTesting';
import { trackAddToWishlist, trackOpenWishlistDrawer, trackRemoveFromWishlist, } from '@jelmoli/sitecore-services/src/Tracking/Wishlist/Wishlist';
import { addProductToWishlist } from '@jelmoli/sitecore-services/src/Wishlist/addProductToWishlist/addProductToWishlist';
import { getWishlistIds } from '@jelmoli/sitecore-services/src/Wishlist/getWishlist/getWishlist';
import { removeProductFromWishlist } from '@jelmoli/sitecore-services/src/Wishlist/removeProductFromWishlist/removeProductFromWishlist';
import React, { useEffect, useState } from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
import { waitForLogin } from '../../organism/LoginDrawer/LoginDrawer';
import { Heart } from '../Icon/Heart';
import { InteractiveIcon } from '../InteractiveIcon/InteractiveIcon';
import { showErrorNotification, showSuccessNotification } from '../Notification/Notification';
/**
 * Shows a Heart button for a product
 *
 * If logged out and clicked it will open the wishlist drawer
 *
 * If logged in and clicked it will add the product to the wishlist
 */
export var WishlistHeartButton = function (props) {
    return isUserLoggedInLazy() ? (React.createElement(WishlistHeartButtonLoggedIn, __assign({}, props))) : (React.createElement(WishlistHeartButtonLoggedOut, __assign({}, props)));
};
var WishlistHeartButtonLoggedOut = function (props) {
    var productId = props.productId, productName = props.productName, brandName = props.brandName;
    return (React.createElement(WishlistHeartUiButton, { isFavorited: false, onClick: function () {
            trackOpenWishlistDrawer(productId, productName, brandName);
            waitForLogin();
        } }));
};
var WishlistHeartButtonLoggedIn = function (props) {
    var _a = useState(false), isFavorited = _a[0], setIsFavorited = _a[1];
    var firstVariantId = props.variantIds[0];
    var wishilstIds = useGetEndPointLazy(getWishlistIds);
    useEffect(function () {
        if (wishilstIds.hasData) {
            var wishlistIdEntries = wishilstIds.value.entries;
            setIsFavorited(Boolean((wishlistIdEntries[props.productId.toLocaleLowerCase()] &&
                wishlistIdEntries[props.productId.toLocaleLowerCase()][firstVariantId]) ||
                (wishlistIdEntries[props.productId.toLocaleUpperCase()] &&
                    wishlistIdEntries[props.productId.toLocaleUpperCase()][firstVariantId])));
        }
    }, [wishilstIds.hasData, wishilstIds.value, firstVariantId]);
    var onClickHandler = useAsyncUiEvent(function () { return __awaiter(void 0, void 0, void 0, function () {
        var productId, variantIds, productName, brandName, newFavoritedState;
        return __generator(this, function (_a) {
            productId = props.productId, variantIds = props.variantIds, productName = props.productName, brandName = props.brandName;
            newFavoritedState = !isFavorited;
            if (newFavoritedState) {
                trackAddToWishlist(productId, productName, brandName);
                addProductToWishlist({}, {
                    productId: productId,
                    variantId: variantIds[0],
                })
                    .then(function (response) {
                    showSuccessNotification(response.globalMessage);
                })
                    .catch(function (response) {
                    trackError({
                        page: 'Error occured while adding product to wishlist',
                        errorMessage: response.GlobalMessage,
                    });
                    showErrorNotification(response.GlobalMessage);
                });
            }
            else {
                trackRemoveFromWishlist(productId, productName, brandName);
                removeProductFromWishlist({}, {
                    productId: productId,
                    variantIds: variantIds,
                })
                    .then(function (response) {
                    showSuccessNotification(response.globalMessage);
                })
                    .catch(function (response) {
                    trackError({
                        page: 'Error occured while adding product from wishlist',
                        errorMessage: response.GlobalMessage,
                    });
                    showErrorNotification(response.GlobalMessage);
                });
            }
            return [2 /*return*/];
        });
    }); })[0];
    return React.createElement(WishlistHeartUiButton, { isFavorited: isFavorited, onClick: onClickHandler });
};
/** Pure UI Implmentation to ensure that LoggedIn and LoggedOut looks identical */
var WishlistHeartUiButton = function (_a) {
    var isFavorited = _a.isFavorited, onClick = _a.onClick;
    return (React.createElement(InteractiveIcon, { areaLabelText: "Wishlist toggle button", active: isFavorited, onClick: onClick, width: 30 },
        React.createElement(Heart, { height: 20 })));
};
