import { track } from '@jelmoli/google-tag-manager';
export function trackFileDownload(fileType, fileName) {
    track({
        event: 'gaEvent',
        event_name: 'download',
        download: {
            file_type: fileType,
            file_name: fileName,
            event_category: 'download',
            event_action: fileType,
            event_label: fileName,
        },
    });
}
