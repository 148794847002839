import { createGetEndpoint } from 'request-registry';
var PackagingOptionsConstantsMap = {
    standard: 'fc4140f8-36b4-4a7f-8567-7a8ec1746e66',
    premium: 'f4e33ecf-71ed-4bb7-932f-1901c2ba2859',
};
export var PackagingOptionsConstants = PackagingOptionsConstantsMap;
/**
 * https://wiki.namics.com/display/JELMOLI2025/205-01-06+Checkout+Interfaces#id-205-01-06CheckoutInterfaces-GetPackagingOptions
 */
export var getPackagingOptions = createGetEndpoint({
    url: function () { return ' /jelmoli-api/JelmoliCheckout/GetPackagingOptions'; },
});
