/**
 * Returns true if the drop down field is not empty
 */
export function hasDropDownFieldValue(field) {
    return typeof field.value === 'string' && field.value !== '';
}
/**
 * Returns true if the text field is not empty
 */
export var hasTextFieldValue = hasDropDownFieldValue;
/**
 * Returns true if the image field is not empty
 */
export function hasImageFieldValue(field) {
    return Boolean(field.value.src);
}
/**
 * Returns true if the reference field is not empty
 */
export function hasReferenceFieldValue(field) {
    return field !== null;
}
/**
 * Returns true if the link field is not empty
 */
export function hasLinkFieldValue(field) {
    return typeof field.value.href === 'string' && field.value.href !== '';
}
export function extractFieldValues(fields, fieldNames) {
    return fieldNames.map(function (fieldName) { return fields[fieldName]; });
}
export var getLabelsFromFormFields = function (formFields) {
    var labels = {};
    var keys = Object.keys(formFields || {});
    keys.forEach(function (key) {
        var formField = formFields[key];
        if (formField &&
            typeof formField === 'object' &&
            typeof formField.fields === 'object' &&
            typeof formField.fields.FieldLabel === 'object') {
            labels[key] = formField.fields.FieldLabel.value;
        }
    });
    return labels;
};
