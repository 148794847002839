var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { A, preventOutline } from '@jelmoli/ui-components/src/helpers/A/A';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { Visibility } from '@jelmoli/ui-components/src/helpers/Visibility/Visibility';
import React from 'react';
import './checkout-back-button.scss';
export var CheckoutBackButtonComponent = function (props) {
    return (React.createElement(A, __assign({ onClick: function () {
            window.history.back();
        }, className: "feat-checkout-back-btn" }, preventOutline()),
        React.createElement(Visibility, { hide: ['xs', 'sm'] },
            React.createElement("div", { className: "feat-checkout-back-btn__text-wrapper" },
                React.createElement(Typography, { size: "s", responsive: false }, props.fields.CheckoutBackToShopLink.value.text)))));
};
