var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useLoadNamedOnceOnClientSide } from '@jelmoli/hooks/src/useLoadOnceOnClientSide/useLoadOnceOnClientSide';
import React from 'react';
/**
 * Modal is the global default dialog component
 *
 * It isused for direct user form interactions like changing the
 * E-Mail address
 *
 * @see
 * https://www.figma.com/file/18oTtEmFazzRQen9h31Ake/1---Global-Components?node-id=20227%3A380
 */
export var Modal = function (props) {
    // Load the modal javascript and css code once
    // `open` is set to true
    var ModalLazy = useLoadNamedOnceOnClientSide('ModalLazy', function () { return import(/* webpackChunkName: "Modal" */ './ModalLazy'); }, props.open, function () { return React.createElement(React.Fragment, null); });
    return React.createElement(ModalLazy, __assign({}, props));
};
