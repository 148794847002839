var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { bindTranslations } from '@jelmoli/i18n';
import { isUserLoggedInLazy } from '@jelmoli/sitecore-services/src/Account/isUserLoggedIn/isUserLoggedInLazy';
import { getClickAndReserveLabels } from '@jelmoli/sitecore-services/src/ClickAndReserve/getClickAndReserveLabels/getClickAndReserveLabels';
import { submitReservation } from '@jelmoli/sitecore-services/src/ClickAndReserve/submitReservation/submitReservation';
import { trackError } from '@jelmoli/sitecore-services/src/Tracking/Error/ErrorTesting';
import { trackingClickAndReserve } from '@jelmoli/sitecore-services/src/Tracking/Product/ClickAndReserve';
import { FormValidationContext } from 'nested-form-validation';
import React, { useEffect, useState } from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
import { Drawer, DrawerContent, DrawerHeader } from '../../atoms/Drawer/Drawer';
import { Dropdown } from '../../atoms/Dropdown/Dropdown';
import { DropdownItem } from '../../atoms/DropdownItem/DropdownItem';
import { showErrorNotification, showSuccessNotification } from '../../atoms/Notification/Notification';
import { PrimarySubmitButton } from '../../atoms/PrimarySubmitButton/PrimarySubmitButton';
import { getLabelsFromFormFields } from '../../helpers/SitecoreTypes/SitecoreTypes';
import { Typography } from '../../helpers/Typography/Typography';
import { VerticalSpacer } from '../../helpers/VerticalSpacer/VerticalSpacer';
import { LoginDrawer } from '../LoginDrawer/LoginDrawer';
import './click-and-reserve-drawer.scss';
export var ClickAndReserveDrawer = function (props) {
    if (!props.isOpen) {
        return null;
    }
    return React.createElement(ClickAndReserveContent, __assign({}, props));
};
var ClickAndReserveContent = function (props) {
    var clickAndReserveLabels = useGetEndPointLazy(getClickAndReserveLabels).value;
    var _a = useState(''), selectedLocationId = _a[0], setSelectedLocationId = _a[1];
    var _b = useState(false), showLoginDrawer = _b[0], setShowLoginDrawer = _b[1];
    useEffect(function () {
        if (props.locations.locations && props.locations.locations[0] && props.locations.locations[0].locationId) {
            setSelectedLocationId(props.locations.locations[0].locationId);
        }
    }, []);
    if (!props.locations.locations || !props.locations.locations[0] || !clickAndReserveLabels) {
        return null;
    }
    var handleReservationClick = function () {
        var _a;
        var isUserLoggedIn = isUserLoggedInLazy();
        if (!isUserLoggedIn) {
            setShowLoginDrawer(true);
            return;
        }
        var reservationZoneCode = (_a = props.locations.locations.find(function (location) { return location.locationId === selectedLocationId; })) === null || _a === void 0 ? void 0 : _a.reservationZone.reservationZoneCode;
        if (selectedLocationId) {
            submitReservation({}, {
                productId: props.productId,
                variantId: props.variantId,
                locationCode: selectedLocationId,
                reservationZoneCode: reservationZoneCode ? reservationZoneCode : '',
                mobileNumber: '',
            })
                .then(function (response) {
                trackingClickAndReserve({
                    productId: props.productId,
                    location: selectedLocationId,
                    brandName: props.brandName,
                });
                props.openDrawer();
                showSuccessNotification(response.globalMessage);
            })
                .catch(function (response) {
                trackError({
                    page: 'Click&Reserve functionality on PDP page',
                    errorMessage: 'Reaservation could not be saved',
                });
                showErrorNotification(response.GlobalMessage);
            });
        }
    };
    var t = bindTranslations(__assign(__assign({}, clickAndReserveLabels), getLabelsFromFormFields(clickAndReserveLabels)));
    if (showLoginDrawer) {
        return React.createElement(LoginDrawer, __assign({}, props, { clickAndReserveLogin: true }));
    }
    return (React.createElement(Drawer, { isOpen: props.isOpen, openDrawer: props.openDrawer },
        React.createElement(DrawerHeader, null, t('ClickAndReserveTitle')),
        React.createElement(DrawerContent, null,
            React.createElement(Typography, { type: "medium", size: "l", responsive: false }, t('ClickAndReserveLocationTitle')),
            React.createElement(VerticalSpacer, { size: "m", responsive: false }),
            props.locations &&
                props.locations.locations.map(function (location) { return (React.createElement(React.Fragment, { key: location.locationId }, location.locationInventory > 0 && (React.createElement(Typography, { size: "xs", className: "test", tag: "p", responsive: false },
                    React.createElement("span", { className: "o-click-and-reserve-drawer__checkmark-icon" }),
                    location.locationName,
                    location.reservationZone.reservationZoneTitle
                        ? ", " + location.reservationZone.reservationZoneTitle
                        : '')))); }),
            React.createElement(FormValidationContext, null,
                React.createElement(VerticalSpacer, { size: "xl", responsive: false }),
                React.createElement(Typography, { size: "l", type: "medium", responsive: false }, t('ClickAndReserveSubtitle')),
                React.createElement(VerticalSpacer, { size: "m", responsive: false }),
                React.createElement(Typography, { size: "l", responsive: false }, t('ClickAndReserveDescription')),
                props.locations && props.locations.locations.length > 1 && (React.createElement(React.Fragment, null,
                    React.createElement(VerticalSpacer, { size: "l", responsive: false }),
                    React.createElement(Dropdown, { label: t('ClickAndReserveReservedSubtitle'), disableAutoFocusItem: true, value: selectedLocationId, type: "fullwidth", onChange: function (e) {
                            setSelectedLocationId(e.target.value);
                        } }, props.locations.locations.map(function (location, index) { return (React.createElement(DropdownItem, { value: location.locationId, key: location.locationId },
                        location.locationName,
                        location.reservationZone.reservationZoneTitle
                            ? ", " + location.reservationZone.reservationZoneTitle
                            : '')); })))),
                React.createElement(VerticalSpacer, { size: "xl", responsive: false }),
                React.createElement(PrimarySubmitButton, { type: "submit", fullWidth: true, text: t('ClickAndReserveButtonLabel'), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, handleReservationClick()];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); } })))));
};
