import { Column, Row } from '@jelmoli/bootstrap-grid';
import { Container } from '@jelmoli/ui-components/src/helpers/Container/Container';
import React from 'react';
import { MyAccountTitle } from './MyAccountTitle';
export var MyAccountTitleComponent = function (_a) {
    var fields = _a.fields;
    if (!('MyAccountTitle' in fields) && !('MyAccountDescription' in fields)) {
        return null;
    }
    return (React.createElement(Container, { size: "s" },
        React.createElement(Row, null,
            React.createElement(Column, { size: 12 },
                React.createElement(MyAccountTitle, { fields: fields })))));
};
