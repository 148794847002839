import { createDeleteEndpoint } from 'request-registry';
import { getCSRFToken } from '../../Security/getCSRFToken/getCSRFToken';
import { getSavedCategories } from '../getSavedCategories/getSavedCategories';
export var removeRootCategory = createDeleteEndpoint({
    url: function (rootCategory) {
        return "/jelmoli-api/JelmoliMyAccountPreference/DeselectRootCategory?rootCategory=" + rootCategory.rootCategory;
    },
    headers: {
        'X-RequestVerificationToken': function () { return getCSRFToken(); },
        'X-Requested-With': 'XMLHttpRequest',
    },
    afterSuccess: function () { return Promise.all([getSavedCategories.refresh()]); },
});
