import { Column, Row } from '@jelmoli/bootstrap-grid';
import { HorizontalSpacer } from '@jelmoli/ui-components/src/helpers/HorizontalSpacer/HorizontalSpacer';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import classnames from 'classnames';
import React, { useState } from 'react';
import './pdp-specification.scss';
export var PDPSpecification = function (_a) {
    var washingLabels = _a.washingLabels, bulletList = _a.bulletList, washingLabelsInstruction = _a.washingLabelsInstruction;
    var _b = useState(-1), isClickedNumber = _b[0], setIsClickedNumber = _b[1];
    if (!washingLabels && !bulletList) {
        return null;
    }
    return (React.createElement(Row, null,
        bulletList && bulletList.length > 0 && (React.createElement(Column, { size: { xs: 12, sm: 12, md: 7, xl: 6 } },
            React.createElement("ul", { className: classnames('feat-pdp-specification__list', {
                    'feat-pdp-specification__list-one': bulletList.length === 1,
                }) }, bulletList.map(function (info, index) { return (React.createElement("li", { key: index, className: "feat-pdp-specification__list-item" },
                React.createElement(Typography, { size: "l", type: "regular" }, info))); })),
            React.createElement(VerticalSpacer, { size: "l" }))),
        washingLabels && washingLabels.length > 0 && (React.createElement(Column, { size: { xs: 12, sm: 12, md: 5, xl: 6 } },
            React.createElement("div", { className: "feat-pdp-specification__wrapper" },
                React.createElement(Typography, { size: "l", type: "medium" }, washingLabelsInstruction),
                React.createElement(VerticalSpacer, { size: "s" }),
                React.createElement("div", { className: "feat-pdp-specification__images" }, washingLabels.map(function (washingLabel, index) { return (React.createElement(React.Fragment, { key: index },
                    React.createElement("div", { className: "feat-pdp-specification__image-wrapper", onClick: function () {
                            var selectedNumber = isClickedNumber === index ? -1 : index;
                            setIsClickedNumber(selectedNumber);
                        } },
                        React.createElement("img", { src: washingLabel.image, alt: washingLabel.title || '', loading: "lazy" }),
                        washingLabel.title && (React.createElement("div", { className: classnames('feat-pdp-specification__image-label', {
                                'feat-pdp-specification__image-label-open': isClickedNumber === index,
                            }) },
                            React.createElement(Typography, { size: "xxs", type: "medium", responsive: false, wrap: "nowrap" }, washingLabel.title)))),
                    React.createElement(HorizontalSpacer, { size: "xxs" }))); }))),
            React.createElement(VerticalSpacer, { size: "l" })))));
};
