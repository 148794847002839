var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useMiniFormik } from '@jelmoli/hooks/src/useMiniFormik/useMiniFormik';
import { changePassword } from '@jelmoli/sitecore-services/src/Account/changePassword/changePassword';
import { trackResetPassword } from '@jelmoli/sitecore-services/src/Tracking/Account/ForgotPasswordTracking';
import { trackError } from '@jelmoli/sitecore-services/src/Tracking/Error/ErrorTesting';
import { FormFieldErrorMessageText } from '@jelmoli/ui-components/src/atoms/FormFieldErrorMessage/FormFieldErrorMessage';
import { ModalConfirmationBar } from '@jelmoli/ui-components/src/atoms/Modal/ModalConfirmationBar';
import { showNotificationAndReload } from '@jelmoli/ui-components/src/atoms/Notification/Notification';
import { PasswordFieldValidatableWithFormSetting } from '@jelmoli/ui-components/src/atoms/PasswordFieldValidatable/PasswordFieldValidatable';
import { PasswordStrengthFieldWithHints, } from '@jelmoli/ui-components/src/atoms/PasswordStrengthField/PasswordStrengthField';
import { isInvalidPasswordException } from '@jelmoli/ui-components/src/helpers/SitecoreErrorResponses/SitecoreErrorResponses';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { FormValidationContext } from 'nested-form-validation/index';
import React, { useState } from 'react';
export var MyAccountProfilePasswordModalContent = function (_a) {
    var _b;
    var saveTitle = _a.saveTitle, cancelTitle = _a.cancelTitle, onClose = _a.onClose, passwordField = _a.passwordField, forgotPasswordHint = _a.forgotPasswordHint, newPasswordField = _a.newPasswordField, passwordHints = _a.passwordHints;
    var _c = useState(false), showForgetPasswordLink = _c[0], setShowForgetPasswordLink = _c[1];
    var form = useMiniFormik({
        initialValues: {
            oldPassword: '',
            newPassword: '',
        },
        onChange: function (fieldName) {
            if (fieldName === 'oldPassword') {
                setShowForgetPasswordLink(false);
            }
        },
    });
    return (React.createElement(FormValidationContext, null,
        React.createElement(PasswordFieldValidatableWithFormSetting, __assign({ autoFocus: true, name: "oldPassword" }, form.fieldProps.oldPassword, { formSetting: passwordField, fullWidth: true, error: showForgetPasswordLink })),
        showForgetPasswordLink && React.createElement(FormFieldErrorMessageText, null, forgotPasswordHint),
        React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
        React.createElement(PasswordStrengthFieldWithHints, __assign({ variant: "outlined", name: "newPassword" }, form.fieldProps.newPassword, { label: newPasswordField.fields.FieldLabel.value, fullWidth: true, hints: passwordHints, minNumberCharacters: (_b = newPasswordField.fields.MinLength) === null || _b === void 0 ? void 0 : _b.value })),
        React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
        React.createElement(ModalConfirmationBar, { submitTitle: saveTitle, submitAction: function () { return __awaiter(void 0, void 0, void 0, function () {
                var result, ajaxError_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, changePassword({}, form.values)];
                        case 1:
                            result = _a.sent();
                            trackResetPassword(true);
                            showNotificationAndReload('success', result.globalMessage);
                            return [3 /*break*/, 3];
                        case 2:
                            ajaxError_1 = _a.sent();
                            trackResetPassword(false);
                            trackError({
                                page: 'Error while changing password on My Account profile page',
                                errorMessage: 'Sorry, error occured',
                            });
                            // Handle invalid oldPassword exceptions
                            // by showing a custom label
                            if (isInvalidPasswordException(ajaxError_1)) {
                                setShowForgetPasswordLink(true);
                                return [2 /*return*/];
                            }
                            // Rethrow all unknown ajax errors
                            throw ajaxError_1;
                        case 3: return [2 /*return*/];
                    }
                });
            }); }, cancelTitle: cancelTitle, cancelAction: function () { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    onClose();
                    return [2 /*return*/];
                });
            }); } })));
};
