import { bindTranslations } from '@jelmoli/i18n';
import { getCart } from '@jelmoli/sitecore-services/src/Cart/getCart/getCart';
import { getCartLabels } from '@jelmoli/sitecore-services/src/Cart/getCartLabels/getCartLabels';
import { getCheckoutLabels } from '@jelmoli/sitecore-services/src/Checkout/getCheckoutLabels/getCheckoutLabels';
import { AlertBoxIcon } from '@jelmoli/ui-components/src/atoms/Icon/AlertBoxIcon';
import { PrimaryButton } from '@jelmoli/ui-components/src/atoms/PrimaryButton/PrimaryButton';
import { HorizontalSpacer } from '@jelmoli/ui-components/src/helpers/HorizontalSpacer/HorizontalSpacer';
import { defaultReplacements, onlyTagsWithInnerHTML, RichTextRenderer, } from '@jelmoli/ui-components/src/helpers/RichTextRenderer/RichTextRenderer';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { Visibility } from '@jelmoli/ui-components/src/helpers/Visibility/Visibility';
import React from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
import './checkout-empty.scss';
var emptyCheckoutRichtextRules = {
    P: onlyTagsWithInnerHTML(function (content) { return (React.createElement(Typography, { responsive: false, size: "l", tag: "p" }, content)); }),
    A: defaultReplacements.A,
};
export var CheckoutEmptyState = function () {
    var cartLabelsData = useGetEndPointLazy(getCartLabels, {}).value;
    var checkoutLabelsData = useGetEndPointLazy(getCheckoutLabels, {}).value;
    var t = bindTranslations(cartLabelsData);
    var cartData = useGetEndPointLazy(getCart).value;
    var checkoutTranslations = bindTranslations(checkoutLabelsData);
    var areAllProductsUnavailable = cartData && cartData.lines.length > 0 && !cartData.lines.some(function (line) { return line.availability !== 0; });
    if (!checkoutLabelsData) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(VerticalSpacer, { size: "xxl" }),
        areAllProductsUnavailable && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "feat-checkout__error-notification" },
                React.createElement(VerticalSpacer, { size: "m", responsive: false }),
                React.createElement("div", { className: "feat-checkout__error-notification-text-wrapper" },
                    React.createElement(AlertBoxIcon, { width: 38, keepOriginalColors: true }),
                    React.createElement(HorizontalSpacer, { size: "s", responsive: false }),
                    React.createElement("div", { className: "feat-checkout__error-notification-text" },
                        React.createElement(Typography, { size: "xs", responsive: false }, checkoutTranslations('CheckoutStepperCartCleanupEmptyCartNotification')))),
                React.createElement(VerticalSpacer, { size: "m", responsive: false })),
            React.createElement(VerticalSpacer, { size: "l" }))),
        React.createElement("div", { className: "feat-checkout-empty__wrapper" },
            React.createElement("div", { className: "feat-checkout-empty" },
                React.createElement("div", { className: "feat-checkout-empty__icon-container" },
                    React.createElement("div", { className: "feat-checkout-empty__icon" })),
                React.createElement(VerticalSpacer, { responsive: false, size: "m" }),
                React.createElement(Typography, { responsive: false, type: "medium", size: "xl", uppercase: true }, t('CartEmptyCartStateTitle')),
                React.createElement(VerticalSpacer, { responsive: false, size: "l" }),
                React.createElement("div", { className: "feat-checkout-empty__rich-text" },
                    React.createElement(RichTextRenderer, { html: t('CartEmptyCartStateMessageText'), replaceRules: emptyCheckoutRichtextRules })),
                React.createElement(VerticalSpacer, { size: "xxl" }),
                React.createElement(Visibility, { show: ['xs', 'sm'] },
                    React.createElement(PrimaryButton, { fullWidth: true, type: "link", uppercase: true, href: checkoutLabelsData.CheckoutConfirmationContinueShoppingLink.value.href, text: t('CartEmptyCartContinueShoppingCtaButtonText') })),
                React.createElement(Visibility, { hide: ['xs', 'sm'] },
                    React.createElement(PrimaryButton, { type: "link", uppercase: true, href: checkoutLabelsData.CheckoutConfirmationContinueShoppingLink.value.href, text: t('CartEmptyCartContinueShoppingCtaButtonText') })))),
        React.createElement(VerticalSpacer, { size: "xxl" })));
};
