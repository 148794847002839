var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Column, Row } from '@jelmoli/bootstrap-grid';
import { MailIcon } from '@jelmoli/ui-components/src/atoms/Icon/MailIcon';
import { MapIcon } from '@jelmoli/ui-components/src/atoms/Icon/MapIcon';
import { PhoneIcon } from '@jelmoli/ui-components/src/atoms/Icon/PhoneIcon';
import { IconList } from '@jelmoli/ui-components/src/atoms/IconList/IconList';
import { Image } from '@jelmoli/ui-components/src/atoms/Image/Image';
import { TextLink } from '@jelmoli/ui-components/src/atoms/TextLink/TextLink';
import { Container } from '@jelmoli/ui-components/src/helpers/Container/Container';
import { HorizontalSpacer } from '@jelmoli/ui-components/src/helpers/HorizontalSpacer/HorizontalSpacer';
import { hasLinkFieldValue, hasTextFieldValue, } from '@jelmoli/ui-components/src/helpers/SitecoreTypes/SitecoreTypes';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import React from 'react';
export var LocationStageComponent = function (props) { return (React.createElement(Container, { size: "s", backgroundFill: true },
    React.createElement(Row, null,
        React.createElement(Column, { size: { xs: 12, sm: 8 }, offset: { xs: 0, sm: 2, md: 0 } },
            React.createElement(Image, { value: __assign({}, props.fields.LocationItemFields.fields.LocationImage.value) }),
            React.createElement(VerticalSpacer, { size: { xs: 'l', sm: 'l', md: 'xxl', lg: 'xxl', xl: 'xxl' } })),
        React.createElement(Column, { size: { xs: 12, sm: 8, md: 4 }, offset: { xs: 0, sm: 2, md: 0 } },
            React.createElement(LocationStageLinks, { phoneNumberField: props.fields.LocationPhoneNumber, emailField: props.fields.LocationEmail, mapLinkField: props.fields.LocationGoogleMapLink, mapTextField: props.fields.LocationGoogleMapLInkText, locationAdditionalLinks: props.fields.LocationAdditionalLinks }),
            React.createElement(Typography, { size: "l", type: "medium", field: props.fields.LocationItemFields.fields.LocationName }),
            React.createElement(Typography, { size: "l" },
                props.fields.LocationItemFields.fields.LocationAddressStreet.value,
                ' ',
                props.fields.LocationItemFields.fields.LocationAddressHouseNumber.value),
            React.createElement(Typography, { size: "l" },
                props.fields.LocationItemFields.fields.LocationAddressPostalCode.value,
                ' ',
                props.fields.LocationItemFields.fields.LocationAddressCity.value),
            React.createElement(VerticalSpacer, { size: "xxl" }))))); };
var LocationStageLinks = function (props) { return (React.createElement(React.Fragment, null,
    hasLinkFieldValue(props.phoneNumberField) && (React.createElement(React.Fragment, null,
        React.createElement(IconTextLink, { linkField: props.phoneNumberField, iconElement: React.createElement(PhoneIcon, { width: 20 }) }),
        React.createElement(VerticalSpacer, { size: "xs" }))),
    hasLinkFieldValue(props.emailField) && (React.createElement(React.Fragment, null,
        React.createElement(IconTextLink, { linkField: props.emailField, iconElement: React.createElement(MailIcon, { width: 20 }) }),
        React.createElement(VerticalSpacer, { size: "xs" }))),
    hasLinkFieldValue(props.mapLinkField) && hasTextFieldValue(props.mapTextField) && (React.createElement(React.Fragment, null,
        React.createElement(IconTextLink, { linkField: props.mapLinkField, linkText: props.mapTextField.value, iconElement: React.createElement(MapIcon, { width: 20 }) }),
        props.locationAdditionalLinks && props.locationAdditionalLinks.length > 0 && (React.createElement(VerticalSpacer, { size: "xs" })))),
    props.locationAdditionalLinks && React.createElement(IconList, { noEndSpacer: true, list: props.locationAdditionalLinks }),
    React.createElement(VerticalSpacer, { size: "m" }))); };
var IconTextLink = function (props) { return (React.createElement(TextLink, { "aria-label": "navigate to " + (props.linkText ? props.linkText : props.linkField.value.text), href: props.linkField.value.href, underlined: false },
    props.iconElement,
    React.createElement(HorizontalSpacer, { size: "xxs" }),
    React.createElement(Typography, { size: "m", type: "regular" }, props.linkText ? props.linkText : props.linkField.value.text))); };
