import { Container } from '@jelmoli/ui-components/src/helpers/Container/Container';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { Visibility } from '@jelmoli/ui-components/src/helpers/Visibility/Visibility';
import React from 'react';
import { HeaderAccountButtonComponent } from '../../Account/HeaderAccountButtonComponent/HeaderAccountButtonComponent';
import { HeaderLogoComponent } from '../../Identity/HeaderLogoComponent/HeaderLogoComponent';
import { HeaderLinkComponent } from '../../Navigation/HeaderLinkComponent/HeaderLinkComponent';
import { ShopHeaderMobileNavigationComponent } from '../../Navigation/ShopHeaderMobileNavigationComponent/ShopHeaderMobileNavigationComponent';
import { ShopHeaderNavigationComponent } from '../../Navigation/ShopHeaderNavigationComponent/ShopHeaderNavigationComponent';
import { HeaderLanguageSelectorComponent } from '../HeaderLanguageSelectorComponent/HeaderLanguageSelectorComponent';
import { HeaderLocationButtonComponent } from '../HeaderLocationButtonComponent/HeaderLocationButtonComponent';
import './header-component.scss';
export var HeaderComponent = function () {
    var fields = {
        HeaderLink: {
            value: {
                href: '#',
                text: 'Jelmoli Versand',
                target: '',
            },
        },
        HeaderLinkWarningCancelButton: {
            value: 'ABBRECHEN',
        },
        HeaderLinkWarningDescription: {
            value: 'Willst du wirklich zu www.jelmoli-shop.ch wechseln…bH und mit Jelmoli konzernmässig nicht verbunden.',
        },
        HeaderLinkWarningOkButton: {
            value: 'OK',
        },
        HeaderLinkWarningTitle: {
            value: 'SIE VERLASSEN DIE WEBSITE!',
        },
        LocationLink: {
            value: {
                href: '#',
                text: 'Standorte',
            },
        },
        LocationText: {
            value: 'Standorte',
        },
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, { size: "m" },
            React.createElement(VerticalSpacer, { size: { xs: 'm', sm: 'm', md: 'm', lg: 's', xl: 's' } }),
            React.createElement("div", { className: "feat-header__wrapper" },
                React.createElement(Visibility, { hide: ['lg', 'xl'], className: "feat-header__left-side" },
                    React.createElement(ShopHeaderMobileNavigationComponent, { fields: {
                            items: [
                                {
                                    href: '#',
                                    text: 'Produkte',
                                    teaser: {
                                        text: 'ButtonPDF',
                                        headline: 'ButtonPDF',
                                        pageLink: '/buttonpdf',
                                        imageLink: '/placeholder/img-1-1.jpg',
                                        type: 1,
                                    },
                                    flyoutItems: [
                                        {
                                            title: 'Bekleidung',
                                            links: [
                                                {
                                                    text: 'Mäntel',
                                                    href: '/produkte/herren/bekleidung/maentel',
                                                    target: '',
                                                },
                                                {
                                                    text: 'Jacken',
                                                    href: '/produkte/herren/bekleidung/jacken',
                                                    target: '',
                                                },
                                                {
                                                    text: 'Anzüge',
                                                    href: '/produkte/herren/bekleidung/anzuege',
                                                    target: '',
                                                },
                                                {
                                                    text: 'Hosen',
                                                    href: '/produkte/herren/bekleidung/hosen',
                                                    target: '',
                                                },
                                                {
                                                    text: 'Jeans',
                                                    href: '/produkte/herren/bekleidung/jeans',
                                                    target: '',
                                                },
                                                {
                                                    text: 'Sweatshirts-und-Pullover',
                                                    href: '/produkte/herren/bekleidung/sweatshirts-und-pullover',
                                                    target: '',
                                                },
                                                {
                                                    text: 'T-Shirts-und-Shirts',
                                                    href: '/produkte/herren/bekleidung/t-shirts-und-shirts',
                                                    target: '',
                                                },
                                                {
                                                    text: 'Waesche',
                                                    href: '/produkte/herren/bekleidung/waesche',
                                                    target: '',
                                                },
                                                {
                                                    text: 'Bademode',
                                                    href: '/produkte/herren/bekleidung/bademode',
                                                    target: '',
                                                },
                                            ],
                                            column: '1',
                                        },
                                        {
                                            title: 'Accessories',
                                            links: [
                                                {
                                                    text: 'Mäntel',
                                                    href: '/produkte/herren/bekleidung/maentel',
                                                    target: '',
                                                },
                                                {
                                                    text: 'Jacken',
                                                    href: '/produkte/herren/bekleidung/jacken',
                                                    target: '',
                                                },
                                                {
                                                    text: 'Anzüge',
                                                    href: '/produkte/herren/bekleidung/anzuege',
                                                    target: '',
                                                },
                                                {
                                                    text: 'Hosen',
                                                    href: '/produkte/herren/bekleidung/hosen',
                                                    target: '',
                                                },
                                            ],
                                            column: '2',
                                        },
                                        {
                                            title: 'Test',
                                            links: [
                                                {
                                                    text: 'Mäntel',
                                                    href: '/produkte/herren/bekleidung/maentel',
                                                    target: '',
                                                },
                                                {
                                                    text: 'Jacken',
                                                    href: '/produkte/herren/bekleidung/jacken',
                                                    target: '',
                                                },
                                                {
                                                    text: 'Anzüge',
                                                    href: '/produkte/herren/bekleidung/anzuege',
                                                    target: '',
                                                },
                                                {
                                                    text: 'Hosen',
                                                    href: '/produkte/herren/bekleidung/hosen',
                                                    target: '',
                                                },
                                                {
                                                    text: 'Jeans',
                                                    href: '/produkte/herren/bekleidung/jeans',
                                                    target: '',
                                                },
                                                {
                                                    text: 'Sweatshirts-und-Pullover',
                                                    href: '/produkte/herren/bekleidung/sweatshirts-und-pullover',
                                                    target: '',
                                                },
                                                {
                                                    text: 'T-Shirts-und-Shirts',
                                                    href: '/produkte/herren/bekleidung/t-shirts-und-shirts',
                                                    target: '',
                                                },
                                                {
                                                    text: 'Waesche',
                                                    href: '/produkte/herren/bekleidung/waesche',
                                                    target: '',
                                                },
                                                {
                                                    text: 'Bademode',
                                                    href: '/produkte/herren/bekleidung/bademode',
                                                    target: '',
                                                },
                                            ],
                                            column: '2',
                                        },
                                        {
                                            title: 'Test',
                                            links: [
                                                {
                                                    text: 'Jacken',
                                                    href: '/produkte/herren/bekleidung/jacken',
                                                    target: '',
                                                },
                                                {
                                                    text: 'Anzüge',
                                                    href: '/produkte/herren/bekleidung/anzuege',
                                                    target: '',
                                                },
                                                {
                                                    text: 'Hosen',
                                                    href: '/produkte/herren/bekleidung/hosen',
                                                    target: '',
                                                },
                                            ],
                                            column: '3',
                                        },
                                    ],
                                },
                                {
                                    href: '/inspiration',
                                    text: 'Inspiration',
                                    flyoutItems: [],
                                },
                                {
                                    href: '#',
                                    text: 'Marken',
                                    allItemsText: 'All brands',
                                    teaser: {
                                        text: 'ButtonPDF',
                                        headline: 'ButtonPDF',
                                        pageLink: '/buttonpdf',
                                        imageLink: '/placeholder/img-1-1.jpg',
                                        type: 0,
                                    },
                                    flyoutItems: [
                                        {
                                            title: 'Bekleidung',
                                            links: [
                                                {
                                                    text: 'Mäntel',
                                                    href: '/produkte/herren/bekleidung/maentel',
                                                    target: '',
                                                },
                                                {
                                                    text: 'Jacken',
                                                    href: '/produkte/herren/bekleidung/jacken',
                                                    target: '',
                                                },
                                                {
                                                    text: 'Anzüge',
                                                    href: '/produkte/herren/bekleidung/anzuege',
                                                    target: '',
                                                },
                                                {
                                                    text: 'Hosen',
                                                    href: '/produkte/herren/bekleidung/hosen',
                                                    target: '',
                                                },
                                                {
                                                    text: 'Jeans',
                                                    href: '/produkte/herren/bekleidung/jeans',
                                                    target: '',
                                                },
                                                {
                                                    text: 'Sweatshirts-und-Pullover',
                                                    href: '/produkte/herren/bekleidung/sweatshirts-und-pullover',
                                                    target: '',
                                                },
                                                {
                                                    text: 'T-Shirts-und-Shirts',
                                                    href: '/produkte/herren/bekleidung/t-shirts-und-shirts',
                                                    target: '',
                                                },
                                                {
                                                    text: 'Waesche',
                                                    href: '/produkte/herren/bekleidung/waesche',
                                                    target: '',
                                                },
                                                {
                                                    text: 'Bademode',
                                                    href: '/produkte/herren/bekleidung/bademode',
                                                    target: '',
                                                },
                                            ],
                                            column: '1',
                                        },
                                        {
                                            title: 'Accessories',
                                            links: [
                                                {
                                                    text: 'Mäntel',
                                                    href: '/produkte/herren/bekleidung/maentel',
                                                    target: '',
                                                },
                                                {
                                                    text: 'Jacken',
                                                    href: '/produkte/herren/bekleidung/jacken',
                                                    target: '',
                                                },
                                                {
                                                    text: 'Anzüge',
                                                    href: '/produkte/herren/bekleidung/anzuege',
                                                    target: '',
                                                },
                                                {
                                                    text: 'Hosen',
                                                    href: '/produkte/herren/bekleidung/hosen',
                                                    target: '',
                                                },
                                            ],
                                            column: '2',
                                        },
                                        {
                                            title: 'Test',
                                            links: [
                                                {
                                                    text: 'Jacken',
                                                    href: '/produkte/herren/bekleidung/jacken',
                                                    target: '',
                                                },
                                                {
                                                    text: 'Anzüge',
                                                    href: '/produkte/herren/bekleidung/anzuege',
                                                    target: '',
                                                },
                                                {
                                                    text: 'Hosen',
                                                    href: '/produkte/herren/bekleidung/hosen',
                                                    target: '',
                                                },
                                            ],
                                            column: '3',
                                        },
                                    ],
                                },
                                {
                                    href: '#',
                                    text: 'Standorte',
                                    flyoutItems: [],
                                },
                            ],
                            searchText: 'search',
                            headerLocation: {
                                locationText: 'Location',
                                locationUrl: '#',
                            },
                        } })),
                React.createElement(Visibility, { hide: ['xs', 'sm', 'md'], className: "feat-header__left-side" },
                    React.createElement("div", { className: "feat-header__language-selector-wrapper" },
                        React.createElement(HeaderLanguageSelectorComponent, null)),
                    React.createElement(HeaderLinkComponent, { fields: fields })),
                React.createElement("div", { className: "feat-header__middle" },
                    React.createElement(VerticalSpacer, { size: "xs", hide: ['xs', 'sm', 'md'] }),
                    React.createElement(HeaderLogoComponent, null)),
                React.createElement("div", { className: "feat-header__right-side" },
                    React.createElement(Visibility, { show: ['xs'] },
                        React.createElement("div", { className: "feat-header__right-side-list--small" },
                            React.createElement(HeaderAccountButtonComponent, null),
                            React.createElement(HeaderLocationButtonComponent, { fields: fields }))),
                    React.createElement(Visibility, { hide: ['xs'] },
                        React.createElement("div", { className: "feat-header__right-side-list" },
                            React.createElement(HeaderAccountButtonComponent, null),
                            React.createElement(HeaderLocationButtonComponent, { fields: fields }))))),
            React.createElement(VerticalSpacer, { size: { xs: 'm', sm: 'm', md: 'm' }, hide: ['lg', 'xl'] })),
        React.createElement(VerticalSpacer, { size: "mx", hide: ['xs', 'sm', 'md'] }),
        React.createElement(ShopHeaderNavigationComponent, { fields: {
                items: [
                    {
                        href: '#',
                        text: 'Produkte',
                        teaser: {
                            text: 'ButtonPDF',
                            headline: 'ButtonPDF',
                            pageLink: '/buttonpdf',
                            imageLink: '/placeholder/img-1-1.jpg',
                            type: 1,
                        },
                        flyoutItems: [
                            {
                                title: 'Bekleidung',
                                titleLink: '#',
                                links: [
                                    {
                                        text: 'Mäntel',
                                        href: '/produkte/herren/bekleidung/maentel',
                                        target: '',
                                    },
                                    {
                                        text: 'Jacken',
                                        href: '/produkte/herren/bekleidung/jacken',
                                        target: '',
                                    },
                                    {
                                        text: 'Anzüge',
                                        href: '/produkte/herren/bekleidung/anzuege',
                                        target: '',
                                    },
                                    {
                                        text: 'Hosen',
                                        href: '/produkte/herren/bekleidung/hosen',
                                        target: '',
                                    },
                                    {
                                        text: 'Jeans',
                                        href: '/produkte/herren/bekleidung/jeans',
                                        target: '',
                                    },
                                    {
                                        text: 'Sweatshirts-und-Pullover',
                                        href: '/produkte/herren/bekleidung/sweatshirts-und-pullover',
                                        target: '',
                                    },
                                    {
                                        text: 'T-Shirts-und-Shirts',
                                        href: '/produkte/herren/bekleidung/t-shirts-und-shirts',
                                        target: '',
                                    },
                                    {
                                        text: 'Waesche',
                                        href: '/produkte/herren/bekleidung/waesche',
                                        target: '',
                                    },
                                    {
                                        text: 'Bademode',
                                        href: '/produkte/herren/bekleidung/bademode',
                                        target: '',
                                    },
                                ],
                                column: '1',
                            },
                            {
                                title: 'Accessories',
                                links: [
                                    {
                                        text: 'Mäntel',
                                        href: '/produkte/herren/bekleidung/maentel',
                                        target: '',
                                    },
                                    {
                                        text: 'Jacken',
                                        href: '/produkte/herren/bekleidung/jacken',
                                        target: '',
                                    },
                                    {
                                        text: 'Anzüge',
                                        href: '/produkte/herren/bekleidung/anzuege',
                                        target: '',
                                    },
                                    {
                                        text: 'Hosen',
                                        href: '/produkte/herren/bekleidung/hosen',
                                        target: '',
                                    },
                                ],
                                column: '2',
                            },
                            {
                                title: 'Test',
                                links: [
                                    {
                                        text: 'Jacken',
                                        href: '/produkte/herren/bekleidung/jacken',
                                        target: '',
                                    },
                                    {
                                        text: 'Anzüge',
                                        href: '/produkte/herren/bekleidung/anzuege',
                                        target: '',
                                    },
                                    {
                                        text: 'Hosen',
                                        href: '/produkte/herren/bekleidung/hosen',
                                        target: '',
                                    },
                                ],
                                column: '3',
                            },
                        ],
                    },
                    {
                        href: '/inspiration',
                        text: 'Inspiration',
                        flyoutItems: [],
                    },
                    {
                        href: '#',
                        text: 'Marken',
                        allItemsText: 'All brands',
                        teaser: {
                            text: 'ButtonPDF',
                            headline: 'ButtonPDF',
                            pageLink: '/buttonpdf',
                            imageLink: '/placeholder/img-1-1.jpg',
                            type: 0,
                        },
                        flyoutItems: [
                            {
                                title: 'Bekleidung',
                                links: [
                                    {
                                        text: 'Mäntel',
                                        href: '/produkte/herren/bekleidung/maentel',
                                        target: '',
                                    },
                                    {
                                        text: 'Jacken',
                                        href: '/produkte/herren/bekleidung/jacken',
                                        target: '',
                                    },
                                    {
                                        text: 'Anzüge',
                                        href: '/produkte/herren/bekleidung/anzuege',
                                        target: '',
                                    },
                                    {
                                        text: 'Hosen',
                                        href: '/produkte/herren/bekleidung/hosen',
                                        target: '',
                                    },
                                    {
                                        text: 'Jeans',
                                        href: '/produkte/herren/bekleidung/jeans',
                                        target: '',
                                    },
                                    {
                                        text: 'Sweatshirts-und-Pullover',
                                        href: '/produkte/herren/bekleidung/sweatshirts-und-pullover',
                                        target: '',
                                    },
                                    {
                                        text: 'T-Shirts-und-Shirts',
                                        href: '/produkte/herren/bekleidung/t-shirts-und-shirts',
                                        target: '',
                                    },
                                    {
                                        text: 'Waesche',
                                        href: '/produkte/herren/bekleidung/waesche',
                                        target: '',
                                    },
                                    {
                                        text: 'Bademode',
                                        href: '/produkte/herren/bekleidung/bademode',
                                        target: '',
                                    },
                                ],
                                column: '1',
                            },
                            {
                                title: 'Accessories',
                                links: [
                                    {
                                        text: 'Mäntel',
                                        href: '/produkte/herren/bekleidung/maentel',
                                        target: '',
                                    },
                                    {
                                        text: 'Jacken',
                                        href: '/produkte/herren/bekleidung/jacken',
                                        target: '',
                                    },
                                    {
                                        text: 'Anzüge',
                                        href: '/produkte/herren/bekleidung/anzuege',
                                        target: '',
                                    },
                                    {
                                        text: 'Hosen',
                                        href: '/produkte/herren/bekleidung/hosen',
                                        target: '',
                                    },
                                ],
                                column: '2',
                            },
                            {
                                title: 'Test',
                                links: [
                                    {
                                        text: 'Jacken',
                                        href: '/produkte/herren/bekleidung/jacken',
                                        target: '',
                                    },
                                    {
                                        text: 'Anzüge',
                                        href: '/produkte/herren/bekleidung/anzuege',
                                        target: '',
                                    },
                                    {
                                        text: 'Hosen',
                                        href: '/produkte/herren/bekleidung/hosen',
                                        target: '',
                                    },
                                ],
                                column: '3',
                            },
                        ],
                    },
                    {
                        href: '#',
                        text: 'Standorte',
                        flyoutItems: [],
                    },
                ],
                searchText: 'search',
                headerLocation: {
                    locationText: 'Location',
                    locationUrl: '#',
                },
            } })));
};
