var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { default as MUIRadioGroup } from '@material-ui/core/RadioGroup';
import React from 'react';
import './radio-buttons-group.scss';
export var RadioButtonsGroup = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement(MUIRadioGroup, __assign({ "data-name": props.name }, props, { onChange: function (e) { return props.onChange(e); }, classes: { root: 'a-radio-buttons-group' } }), props.children)));
};
