var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { hasEverBeenVisibleInViewport } from '@jelmoli/hooks/src/useKeyboardEffect/useIsVisibleInViewport/useIsVisibleInViewport';
import { bindTranslations } from '@jelmoli/i18n/index';
import { getProfile } from '@jelmoli/sitecore-services/src/Account/getProfile/getProfile';
import { isUserLoggedInLazy } from '@jelmoli/sitecore-services/src/Account/isUserLoggedIn/isUserLoggedInLazy';
import { Divider } from '@jelmoli/ui-components/src/atoms/Divider/Divider';
import { PrimaryButton } from '@jelmoli/ui-components/src/atoms/PrimaryButton/PrimaryButton';
import { hasLinkFieldValue, } from '@jelmoli/ui-components/src/helpers/SitecoreTypes/SitecoreTypes';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { NewsletterSignupDrawer } from '@jelmoli/ui-components/src/organism/NewsletterSignupDrawer/NewsletterSignupDrawer';
import React, { useState } from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
import './footer-newsletter.scss';
export var FooterNewsletter = function (props) {
    var isLoggedIn = isUserLoggedInLazy();
    var t = bindTranslations(props.fields);
    return (React.createElement("div", { className: "feat-footer-newsletter__wrapper" },
        React.createElement(Divider, { display: "block" }),
        React.createElement(VerticalSpacer, { size: "s", responsive: false }),
        isLoggedIn ? (React.createElement(UserLoggedIn, { text: t('NewsletterRegistrationText'), buttonText: t('RegisterButton'), buttonLink: props.fields.MyAccountProfileLink, textSubscribed: t('PreferencesText'), buttonLinkSubscribed: props.fields.MyAccountPreferenceLink, buttonTextSubscribed: t('PreferencesButton') })) : (React.createElement(AnonymousUser, { text: t('NewsletterRegistrationText'), buttonText: t('RegisterButton') })),
        React.createElement(VerticalSpacer, { size: "l" })));
};
var AnonymousUser = function (props) {
    var _a = useState(false), isDrawerOpen = _a[0], setIsDrawerOpen = _a[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(NewsletterSignupDrawer, { isOpen: isDrawerOpen, openDrawer: function () { return setIsDrawerOpen(false); } }),
        React.createElement(Typography, { size: "m", type: "regular" }, props.text),
        React.createElement(VerticalSpacer, { size: "s" }),
        React.createElement(PrimaryButton, { type: "button", text: props.buttonText, onClick: function () {
                setIsDrawerOpen(true);
            } })));
};
var UserLoggedIn = function (props) {
    var _a = hasEverBeenVisibleInViewport(), hasBeenVisible = _a.hasBeenVisible, ref = _a.ref;
    var getProfileData = useGetEndPointLazy(getProfile, {}, hasBeenVisible);
    var isSubscribed = Boolean(getProfileData.value && getProfileData.value.newsletter);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { ref: ref, className: "feat-footer-newsletter__spinner-detector" }),
        React.createElement(Typography, { size: "m", type: "regular" }, isSubscribed ? props.textSubscribed : props.text),
        React.createElement(VerticalSpacer, { size: "s" }),
        React.createElement(PrimaryButton, { type: "button", text: isSubscribed ? props.buttonTextSubscribed : props.buttonText, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                var profileData;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, getProfile({})];
                        case 1:
                            profileData = _a.sent();
                            if (!hasLinkFieldValue(props.buttonLinkSubscribed) || !hasLinkFieldValue(props.buttonLink)) {
                                throw new Error('Missing link');
                            }
                            if (profileData.newsletter) {
                                window.location.href = props.buttonLinkSubscribed.value.href;
                            }
                            else {
                                window.location.href = props.buttonLink.value.href;
                            }
                            return [2 /*return*/];
                    }
                });
            }); } })));
};
