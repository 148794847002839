import { track } from '@jelmoli/google-tag-manager';
export function trackNavigation(props) {
    track({
        event: 'gaEvent',
        event_name: 'navigation',
        navigation: {
            navigation_level: props.currentLevel,
            navigation_item: props.trackLabel,
            event_action: props.currentLevel,
            event_category: 'navigation',
            event_label: props.trackLabel,
        },
    });
}
// JELMOLI2025-4743 adjusted function trackNavigation with function trackFooter
/** Track Navigation within Footer */
// export function trackFooter(linkTitle: string) {
// 	track({
// 		event: 'gaEvent',
// 		event_name: 'navigation',
// 		navigation: {
// 			navigation_level: 'Footer',
// 			navigation_item: linkTitle, // {title}: {clicked navigation} - eg. Über Jelmoli: Karriere
// 			event_action: 'Footer',
// 			event_category: 'navigation',
// 			event_label: linkTitle, // {title}: {clicked navigation} - eg. Über Jelmoli: Karriere
// 		},
// 	});
// }
