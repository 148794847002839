import { bindTranslations } from '@jelmoli/i18n/index';
import { extractFieldValues, hasTextFieldValue, } from '@jelmoli/ui-components/src/helpers/SitecoreTypes/SitecoreTypes';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import classnames from 'classnames';
import React from 'react';
import './footer-payment-methods.scss';
export var FooterPaymentMethods = function (_a) {
    var fields = _a.fields;
    var paymentIconArray = extractFieldValues(fields, [
        'PaymentMethodsSvg1',
        'PaymentMethodsSvg2',
        'PaymentMethodsSvg3',
        'PaymentMethodsSvg4',
        'PaymentMethodsSvg5',
        'PaymentMethodsSvg6',
        'PaymentMethodsSvg7',
        'PaymentMethodsSvg8',
        'PaymentMethodsSvg9',
        'PaymentMethodsSvg10',
    ]).filter(function (propName) { return hasTextFieldValue(propName); });
    var t = bindTranslations(fields);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "feat-payment-methods" },
            React.createElement("span", { className: "feat-payment-methods__label" },
                t('PaymentMethodsTitle'),
                ":"),
            paymentIconArray.map(function (icon, index) {
                return (React.createElement(React.Fragment, { key: "PaymentMethodsSvg" + index }, hasTextFieldValue(icon) && (React.createElement("div", null,
                    React.createElement("div", { dangerouslySetInnerHTML: { __html: icon.value }, className: classnames('feat-payment-methods__icon', {
                            'feat-payment-methods__icon--no-margin-left': index === 0,
                        }) }),
                    index < 5 && React.createElement(VerticalSpacer, { size: "s", show: ['xs', 'sm'] })))));
            })),
        React.createElement(VerticalSpacer, { size: "m", responsive: false })));
};
