var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Column, Row } from '@jelmoli/bootstrap-grid';
import { useMiniFormik } from '@jelmoli/hooks/src/useMiniFormik/useMiniFormik';
import { bindTranslations } from '@jelmoli/i18n';
import { resetPassword } from '@jelmoli/sitecore-services/src/Account/resetPassword/resetPassword';
import { trackResetPassword } from '@jelmoli/sitecore-services/src/Tracking/Account/ForgotPasswordTracking';
import { trackError } from '@jelmoli/sitecore-services/src/Tracking/Error/ErrorTesting';
import { showErrorNotification, showNotificationAndGotoPage, } from '@jelmoli/ui-components/src/atoms/Notification/Notification';
import { PasswordStrengthFieldWithHints } from '@jelmoli/ui-components/src/atoms/PasswordStrengthField/PasswordStrengthField';
import { PrimarySubmitButton } from '@jelmoli/ui-components/src/atoms/PrimarySubmitButton/PrimarySubmitButton';
import { Container } from '@jelmoli/ui-components/src/helpers/Container/Container';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { Visibility } from '@jelmoli/ui-components/src/helpers/Visibility/Visibility';
import { FormValidationContext } from 'nested-form-validation';
import React, { useEffect } from 'react';
export var ResetPasswordComponent = function (_a) {
    var _b, _c;
    var fields = _a.fields;
    var form = useMiniFormik({
        initialValues: {
            password: '',
        },
    });
    var redirectUrl = fields.ResetPasswordTokenExpiredRedirectLink.value.url;
    useEffect(function () {
        if (!fields.IsTokenValid && redirectUrl) {
            showNotificationAndGotoPage('error', 'ResetPasswordTokenExpiredMessage', redirectUrl, {
                newHistoryEntry: true,
            });
        }
    });
    if (!fields.IsTokenValid && redirectUrl) {
        return null;
    }
    var onSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, resetPassword({}, {
                        username: fields.Username,
                        password: form.values.password,
                    })
                        .then(function () {
                        trackResetPassword(true);
                        var loginUrl = fields.ResetPasswordLoginLink.value.url;
                        if (fields.ResetPasswordLoginLink.value.url) {
                            document.location.replace(loginUrl);
                        }
                    })
                        .catch(function (response) {
                        trackResetPassword(false);
                        trackError({
                            page: 'Error occured during reset of the password',
                            errorMessage: response.GlobalMessage,
                        });
                        showErrorNotification(response.GlobalMessage);
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var t = bindTranslations(fields);
    return (React.createElement(Container, { size: "s", backgroundFill: true },
        React.createElement(Row, null,
            React.createElement(Column, { size: { xs: 12, sm: 8, lg: 6 }, offset: { xs: 0, sm: 2, lg: 3 } },
                React.createElement(VerticalSpacer, { size: "xl" }),
                React.createElement(FormValidationContext, null,
                    React.createElement(Typography, { size: "xl", type: "medium", field: fields.ResetPasswordTitle }),
                    React.createElement(VerticalSpacer, { size: "m" }),
                    React.createElement(Typography, { size: "l", responsive: false, field: fields.ResetPasswordDescription }),
                    React.createElement(VerticalSpacer, { size: "l", responsive: false }),
                    React.createElement(PasswordStrengthFieldWithHints, __assign({ fullWidth: true, name: "password", variant: "standard", hints: {
                            introductionText: fields.ResetPasswordPassword.fields.IntroductionText.value,
                            minimumNumberOfCharactersRule: (_b = fields.ResetPasswordPassword.fields.MinLengthError) === null || _b === void 0 ? void 0 : _b.value,
                            letterRule: fields.ResetPasswordPassword.fields.LetterRule.value,
                            numberRule: fields.ResetPasswordPassword.fields.NumberRule.value,
                            specialCharacterRule: fields.ResetPasswordPassword.fields.SpecialCharacterRule.value,
                        }, minNumberCharacters: (_c = fields.ResetPasswordPassword.fields.MinLength) === null || _c === void 0 ? void 0 : _c.value, label: fields.ResetPasswordPassword.fields.FieldLabel.value }, form.fieldProps.password)),
                    React.createElement(VerticalSpacer, { size: "xl", responsive: false }),
                    React.createElement(Visibility, { show: ['xs', 'sm'] },
                        React.createElement(PrimarySubmitButton, { text: t('ResetPasswordSubmitButtonText'), uppercase: true, fontResponsive: false, fullWidth: true, onClick: function () { return onSubmit(); } })),
                    React.createElement(Visibility, { hide: ['xs', 'sm'] },
                        React.createElement(PrimarySubmitButton, { text: t('ResetPasswordSubmitButtonText'), uppercase: true, fontResponsive: false, onClick: function () { return onSubmit(); } })),
                    React.createElement(VerticalSpacer, { size: "xxxl" }))))));
};
