import { Column } from '@jelmoli/bootstrap-grid';
import { useLoadNamedOnceOnClientSide } from '@jelmoli/hooks/src/useLoadOnceOnClientSide/useLoadOnceOnClientSide';
import { bindTranslations } from '@jelmoli/i18n';
import { getOrders } from '@jelmoli/sitecore-services/src/Account/getOrders/getOrders';
import { getOrdersLabels } from '@jelmoli/sitecore-services/src/Account/getOrdersLabels/getOrdersLabels';
import { isUserLoggedInLazy } from '@jelmoli/sitecore-services/src/Account/isUserLoggedIn/isUserLoggedInLazy';
import { InfinityScroll } from '@jelmoli/ui-components/src/atoms/InfinityScroll/InfinityScroll';
import { SecondaryButton } from '@jelmoli/ui-components/src/atoms/SecondaryButton/SecondaryButton';
import { Spinner } from '@jelmoli/ui-components/src/atoms/Spinner/Spinner';
import { ScrollIntoView } from '@jelmoli/ui-components/src/helpers/ScrollIntoView/ScrollIntoView';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { Visibility } from '@jelmoli/ui-components/src/helpers/Visibility/Visibility';
import { OrderItem } from '@jelmoli/ui-components/src/organism/OrderItem/OrderItem';
import { urlManager } from '@jelmoli/urlmanager/UrlManager';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
import { MyAccountTitle } from '../MyAccountTitleComponent/MyAccountTitle';
import { MyAccountReservationDetail } from './MyAccountResevationDetail/MyAccountReservationDetail';
export var MyAccountOrdersComponent = observer(function (_a) {
    var fields = _a.fields;
    var MyAccountOrderDetail = useLoadNamedOnceOnClientSide('MyAccountOrderDetail', function () {
        return import(/* webpackChunkName: "MyAccountOrderDetail" */ './MyAccountOrderDetail/MyAccountOrderDetail');
    });
    var MyAccountOfflineOrderDetail = useLoadNamedOnceOnClientSide('MyAccountOfflineOrderDetail', function () {
        return import(
        /* webpackChunkName: "MyAccountOfflineOrderDetail" */ './MyAccountOfflineOrderDetail/MyAccountOfflineOrderDetail');
    });
    var isLoggedIn = isUserLoggedInLazy();
    var ordersData = useGetEndPointLazy(getOrders, { offset: 0, pageSize: 6 }, isLoggedIn);
    var ordersList = ordersData.value;
    var ordersLabels = useGetEndPointLazy(getOrdersLabels).value;
    var t = bindTranslations(fields);
    var _b = useState(false), scrollUp = _b[0], setScrollUp = _b[1];
    var orderId = urlManager.queryStringFlat.orderId;
    var offlineOrderId = urlManager.queryStringFlat.offlineOrderId;
    var reservationId = urlManager.queryStringFlat.reservationId;
    // Show Order Detail
    if (orderId) {
        return (React.createElement(React.Fragment, null,
            React.createElement(ScrollIntoView, { smooth: true, inView: scrollUp, onScroll: function () { return setScrollUp(false); } }),
            MyAccountOrderDetail ? (React.createElement(MyAccountOrderDetail, { orderId: orderId, onClose: function () {
                    urlManager.removeFromQueryString(['orderId'], { newHistoryEntry: true });
                } })) : (React.createElement(Column, { horizontalAlignment: "center" },
                React.createElement(Spinner, null)))));
    }
    // Show Offline Order Detail
    if (offlineOrderId) {
        return (React.createElement(React.Fragment, null,
            React.createElement(ScrollIntoView, { smooth: true, inView: scrollUp, onScroll: function () { return setScrollUp(false); } }),
            MyAccountOfflineOrderDetail ? (React.createElement(MyAccountOfflineOrderDetail, { orderId: offlineOrderId, onClose: function () {
                    urlManager.removeFromQueryString(['offlineOrderId'], { newHistoryEntry: true });
                } })) : (React.createElement(Column, { horizontalAlignment: "center" },
                React.createElement(Spinner, null)))));
    }
    // Show Reservation Detail
    if (reservationId) {
        return (React.createElement(React.Fragment, null,
            React.createElement(ScrollIntoView, { smooth: true, inView: scrollUp, onScroll: function () { return setScrollUp(false); } }),
            React.createElement(MyAccountReservationDetail, { reservationId: reservationId, onClose: function () {
                    urlManager.removeFromQueryString(['reservationId'], { newHistoryEntry: true });
                } })));
    }
    // Show spinner until data is loaded
    if (!ordersData.hasData) {
        return (React.createElement(React.Fragment, null,
            React.createElement(MyAccountTitle, { fields: fields }),
            React.createElement(Column, { horizontalAlignment: "center" },
                React.createElement(Spinner, null))));
    }
    if (!ordersList || !ordersLabels) {
        return React.createElement(MyAccountTitle, { fields: fields });
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(MyAccountTitle, { fields: fields }),
        ordersList.orders.length === 0 ? (React.createElement(React.Fragment, null,
            React.createElement(Visibility, { hide: ['xs', 'sm'] },
                React.createElement(SecondaryButton, { text: t('OrderContinueShoppingButtonLabel'), type: "link", href: fields.OrderContinueShoppingButtonLink.value.href })),
            React.createElement(Visibility, { show: ['xs', 'sm'] },
                React.createElement(SecondaryButton, { text: t('OrderContinueShoppingButtonLabel'), type: "link", href: fields.OrderContinueShoppingButtonLink.value.href, fullWidth: true })))) : (React.createElement(MyAccountOrders, { orders: ordersList.orders, currentPage: ordersList.currentPage, pageCount: ordersList.pageCount, onClick: function (orderConfrmationId, orderType) {
                setScrollUp(true);
                if (orderType === 'OfflineOrder') {
                    urlManager.extendQueryString({ offlineOrderId: orderConfrmationId }, { newHistoryEntry: true });
                }
                else {
                    if (orderType === 'Reservation' || orderType === 'OfflineReservation') {
                        urlManager.extendQueryString({ reservationId: orderConfrmationId }, { newHistoryEntry: true });
                    }
                    else {
                        urlManager.extendQueryString({ orderId: orderConfrmationId }, { newHistoryEntry: true });
                    }
                }
            } }))));
});
var MyAccountOrders = function (props) {
    var offset = (props.currentPage + 1) * props.orders.length;
    return (React.createElement(React.Fragment, null,
        props.orders.map(function (order) { return (React.createElement(React.Fragment, { key: order.confirmationId },
            React.createElement(OrderItem, { data: {
                    ArticalNumber: Number(order.itemCount),
                    Date: order.date,
                    OrderNumber: order.confirmationId,
                    Price: order.amount,
                    OrderImage: order.imageUrl,
                    OrderStatus: order.status,
                    reservedUntilDays: order.reservedUntilDays,
                    variant: setOrderVariant(order),
                    isOffline: order.orderType === 'OfflineOrder',
                }, onClick: function () { return props.onClick(order.confirmationId, order.orderType); } }),
            React.createElement(VerticalSpacer, { size: "xl" }))); }),
        props.currentPage + 1 < props.pageCount && React.createElement(NextOrdersPage, { offset: offset, onClick: props.onClick })));
};
var NextOrdersPage = function (_a) {
    var offset = _a.offset, onClick = _a.onClick;
    return (React.createElement(InfinityScroll, { loader: function () {
            return getOrders({
                offset: offset,
                pageSize: 6,
            });
        } }, function (response) { return (React.createElement(MyAccountOrders, { orders: response.orders, currentPage: response.currentPage, pageCount: response.pageCount, onClick: onClick })); }));
};
/** Return the type of the order based on the order data */
function setOrderVariant(order) {
    if (order.orderType === 'Reservation' || order.orderType === 'OfflineReservation') {
        return ['reservation'];
    }
    if (order.itemCancelledCount > 0 &&
        order.itemCount > order.itemCancelledCount &&
        order.itemReturnedCount > 0 &&
        order.itemCount > order.itemReturnedCount) {
        return ['partially-cancelled', 'partially-returned'];
    }
    if (order.itemCount === order.itemCancelledCount) {
        return ['cancelled'];
    }
    if (order.itemCancelledCount > 0 && order.itemCount > order.itemCancelledCount) {
        return ['partially-cancelled'];
    }
    if (order.itemCount === order.itemReturnedCount) {
        return ['returned'];
    }
    if (order.itemReturnedCount > 0 && order.itemCount > order.itemReturnedCount) {
        return ['partially-returned'];
    }
    return ['order'];
}
