var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Visibility } from '../Visibility/Visibility';
import './vertical-spacer.scss';
function getSpacerClassNames(size, responsive) {
    if (responsive === false) {
        return "h-vertical-spacer--static-size-" + size;
    }
    if (typeof size === 'string') {
        return "h-vertical-spacer--size-" + size;
    }
    return Object.keys(size)
        .map(function (breakpoint) { return "h-vertical-spacer--size-" + breakpoint + "-" + size[breakpoint]; })
        .join(' ');
}
/**
 * A component to add vertical spaces
 * See https://www.figma.com/file/K1cqBqFgvgemFZCCqglfuD4h/Design-Direction?node-id=505%3A1361 for details
 */
export var VerticalSpacer = function (props) {
    var className = "h-vertical-spacer " + getSpacerClassNames(props.size, props.responsive);
    var visibilitySettings = 'hide' in props
        ? { hide: props.hide }
        : 'show' in props
            ? { show: props.show }
            : false;
    return visibilitySettings ? (React.createElement(Visibility, __assign({}, visibilitySettings, { className: className }))) : (React.createElement("span", { className: className }));
};
