import { A } from '@jelmoli/ui-components/src/helpers/A/A';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import React from 'react';
import { Column } from 'react-bootstrap-grid-component/dist/Column';
import './brand-categories.scss';
export var BrandCategoriesComponent = function (props) {
    return (React.createElement(React.Fragment, null, props.fields.brandCategories.map(function (category, index) { return (React.createElement(Column, { size: { xs: 12, sm: 4 }, key: category.categoryName },
        React.createElement("div", null,
            React.createElement(A, { className: "feat-brand-categories__category-name", onClick: function (event) {
                    event.preventDefault();
                    if (event.metaKey || event.ctrlKey || event.shiftKey) {
                        window.open(category.categoryLink);
                        return;
                    }
                    window.location.href = category.categoryLink;
                } },
                React.createElement(Typography, { size: "l", type: "medium" }, category.categoryName)),
            category.salesAreas.length > 0 && (React.createElement(React.Fragment, null,
                React.createElement(VerticalSpacer, { size: "m" }),
                React.createElement("div", null, category.salesAreas.map(function (area) { return (React.createElement(Typography, { key: area.salesAreaName, size: "l", type: "regular", tag: "p" }, area.salesAreaName)); })),
                React.createElement(VerticalSpacer, { size: "m" }))),
            category.salesAreas.length > 0 && React.createElement(VerticalSpacer, { size: "l" }),
            category.salesAreas.length === 0 && (React.createElement(VerticalSpacer, { size: props.fields.brandCategories.length === index + 1 ? 'xxxl' : 's' }))))); })));
};
