var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import MaterialUiStep from '@material-ui/core/Step';
import MaterialUIStepConnector from '@material-ui/core/StepConnector';
import MaterialUiStepContent from '@material-ui/core/StepContent';
import MaterialUiStepIcon from '@material-ui/core/StepIcon';
import MaterialUiStepLabel from '@material-ui/core/StepLabel';
import MaterialUiStepper from '@material-ui/core/Stepper';
import classnames from 'classnames';
import React from 'react';
import { CheckmarkIcon } from '../Icon/CheckmarkIcon';
import './stepper.scss';
export var Stepper = function (props) { return (React.createElement(MaterialUiStepper, __assign({}, props, { connector: React.createElement(MaterialUIStepConnector, { classes: { line: 'a-stepper__connector' } }) }), props.children)); };
export var Step = function (props) { return (React.createElement(MaterialUiStep, __assign({}, props, { classes: { root: props.completed || props.disabled ? 'a-step' : '' } }))); };
export var StepLabel = function (props) { return (React.createElement(MaterialUiStepLabel, __assign({}, props, { classes: { iconContainer: 'a-stepper__icon-wrapper' }, StepIconComponent: StepIcon }))); };
export var StepContent = function (props) { return (React.createElement(MaterialUiStepContent, __assign({}, props, { classes: { root: 'a-stepper__content' } }))); };
export var StepSummary = function (_a) {
    var visible = _a.visible, props = __rest(_a, ["visible"]);
    return (React.createElement(MaterialUiStepContent, __assign({}, props, { TransitionProps: {
            in: visible,
        }, transitionDuration: 0, classes: {
            root: classnames('a-stepper__summary', "a-stepper__summary--" + (visible ? 'visible' : 'hidden')),
        } })));
};
var StepIcon = function (props) {
    return (React.createElement(MaterialUiStepIcon, __assign({}, props, { classes: {
            root: 'a-stepper__icon',
            active: 'a-stepper__icon--active',
            completed: 'a-stepper__icon--completed',
            text: 'a-stepper__icon-text',
        } }, (props.completed && {
        icon: (React.createElement("span", { className: "a-stepper__icon a-stepper__icon--completed" },
            React.createElement(CheckmarkIcon, { height: 14, width: 14 }))),
    }))));
};
