var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { createContext, useCallback, useContext, useEffect, useMemo, useReducer, useRef } from 'react';
/** Create a uniqueSymbol */
function createRuntimeIdSymbol() {
    return Object.create(null);
}
var FormStateActionNames;
(function (FormStateActionNames) {
    FormStateActionNames[FormStateActionNames["setValue"] = 0] = "setValue";
    FormStateActionNames[FormStateActionNames["addField"] = 1] = "addField";
    FormStateActionNames[FormStateActionNames["removeField"] = 2] = "removeField";
    FormStateActionNames[FormStateActionNames["touchedField"] = 3] = "touchedField";
    FormStateActionNames[FormStateActionNames["touchedAllFields"] = 4] = "touchedAllFields";
})(FormStateActionNames || (FormStateActionNames = {}));
function attachFieldToForm(form, formId, fieldName, initialTouchedState, validator, validatorFieldDependencies) {
    var fields = form.fields.get(formId);
    if (!fields) {
        fields = new Map();
        form.fields.set(formId, fields);
    }
    var _a = fields.get(fieldName) || { validationState: 'unknown', touched: initialTouchedState }, validationState = _a.validationState, touched = _a.touched;
    fields.set(fieldName, {
        validator: validator,
        validatorFieldDependencies: validatorFieldDependencies || [fieldName],
        validationState: validationState,
        touched: touched,
    });
}
function removeFieldFromForm(form, formId, fieldName) {
    var fields = form.fields.get(formId);
    if (fields) {
        fields.delete(fieldName);
    }
}
/**
 * Return all validators which need to be executed
 * to get the latest validation state
 */
function getValidators(formFields, values, previousValues) {
    var fieldNames = Array.from(formFields.keys());
    // Find out which fields changed
    var changedFields = fieldNames.filter(function (fieldName) {
        return formFields.get(fieldName).validationState === 'unknown' || values[fieldName] !== previousValues[fieldName];
    });
    // Based on the changed fields find the validators
    // and return them
    var fieldNamesWithValidator = changedFields.filter(function (fieldName) {
        var validatorFieldDependencies = formFields.get(fieldName).validatorFieldDependencies;
        return validatorFieldDependencies === true || validatorFieldDependencies.includes(fieldName);
    });
    return fieldNamesWithValidator.map(function (fieldName) { return [fieldName, formFields.get(fieldName).validator]; });
}
/**
 * Run all validators and return a new form object if a validation result changed
 */
function validateForm(form, formId, values, previousValues) {
    // Get all validators which need to be run
    // for the latest form value changes
    var fields = form.fields.get(formId);
    var validators = fields && getValidators(fields, values, previousValues);
    // If no watched form value changes exist stop the validation
    if (!fields || !validators || validators.length === 0) {
        return form;
    }
    var changed = false;
    // Run all validators and upate the internal validation
    // state for every field
    validators.forEach(function (_a) {
        var fieldName = _a[0], validator = _a[1];
        var field = fields.get(fieldName);
        if (field) {
            var validationResult = validator(values[fieldName], values, previousValues);
            // Ignore the error message if validation was successful
            var _b = validationResult === undefined
                ? [undefined, 'valid']
                : [validationResult || ' ', 'invalid'], newErrorMessage = _b[0], newValidationState = _b[1];
            changed = changed || field.validationErrorMessage !== newErrorMessage;
            field.validationErrorMessage = newErrorMessage;
            field.validationState = newValidationState;
            fields.set(fieldName, __assign({}, field));
        }
    });
    // Return a new object if anything change
    return changed ? Object.assign({}, form) : form;
}
var FormStateContext = createContext(null);
/**
 * The formState reducer updates the state
 * will trigger a rerender if it a new formRootState
 * instance is returned
 */
function formStateReducer(formRootState, action) {
    var _a;
    switch (action.type) {
        case FormStateActionNames.addField:
            attachFieldToForm(formRootState, action.formId, action.fieldName, action.touched, action.validator, action.validatorFieldDependencies);
            return __assign({}, formRootState);
        case FormStateActionNames.removeField:
            removeFieldFromForm(formRootState, action.formId, action.fieldName);
            return __assign({}, formRootState);
        case FormStateActionNames.touchedField:
            var fields = formRootState.fields.get(action.formId);
            var field = fields && fields.get(action.fieldName);
            // Don't rerender if this field is unknown
            // or was alsready touched before
            if (!field || field.touched) {
                return formRootState;
            }
            field.touched = true;
            return __assign({}, formRootState);
        case FormStateActionNames.touchedAllFields:
            return touchAllFields(formRootState, action.formId) ? __assign({}, formRootState) : formRootState;
        case FormStateActionNames.setValue:
            // Values are mutable and will not trigger a rerender
            // because of that they are silently added so devs
            // won't rely on it during render
            var previousValues = formRootState.values.get(action.formId) || {};
            var newValues = __assign(__assign({}, previousValues), (_a = {}, _a[action.fieldName] = action.value, _a));
            formRootState.values.set(action.formId, newValues);
            return validateForm(formRootState, action.formId, newValues, previousValues);
        default:
            // Return the same object to not trigger a rerender
            return formRootState;
    }
}
/**
 * Small helper hook to execute the callback only
 * once and store the result
 */
function useInitOnce(initCallback) {
    var ref = useRef(useInitOnce);
    if (ref.current === useInitOnce) {
        ref.current = initCallback();
    }
    return ref.current;
}
/**
 * The form validation context allows to detect if multiple
 * form elements are valid
 *
 * Can be nested inside another FormValidationContext to create
 * subforms
 */
export var FormValidationContext = function (_a) {
    var children = _a.children, validate = _a.validate, formField = _a.formField;
    var parentContext = useContext(FormStateContext);
    var formId = useInitOnce(createRuntimeIdSymbol);
    // Asume that there will forever be either a parentContext or no context at all
    // and that this will never switch during runtime
    var _b = parentContext
        ? [parentContext.rootState, parentContext.dispatch]
        : useReducer(formStateReducer, {
            children: new Map(),
            fields: new Map(),
            values: new Map(),
        }), formRootState = _b[0], dispatch = _b[1];
    // Turn the state into the context value
    // useMemo will make sure that no rerendering is triggered
    // as long as the rootState did not change
    var formContextValues = useMemo(function () {
        if (parentContext) {
            var childrenOfParentContext = formRootState.children.get(parentContext.id);
            if (childrenOfParentContext) {
                childrenOfParentContext.add(formId);
            }
        }
        if (!formRootState.children.get(formId)) {
            formRootState.children.set(formId, new Set());
        }
        var fieldsForCurrentFormId = formRootState.fields.get(formId);
        if (!fieldsForCurrentFormId) {
            fieldsForCurrentFormId = new Map();
            formRootState.fields.set(formId, fieldsForCurrentFormId);
        }
        return {
            id: formId,
            // Validate is true by default
            validate: validate !== false,
            nesting: parentContext ? parentContext.nesting + 1 : 0,
            fields: fieldsForCurrentFormId,
            dispatch: dispatch,
            rootState: formRootState,
        };
    }, [formId, formRootState, dispatch, validate]);
    // Cleanup children mappings on unmount
    useEffect(function () {
        return function () {
            if (parentContext) {
                var childrenOfParentContext = formRootState.children.get(parentContext.id);
                if (childrenOfParentContext) {
                    childrenOfParentContext.delete(formId);
                }
            }
            formRootState.children.delete(formId);
        };
    }, []);
    var context = React.createElement(FormStateContext.Provider, { value: formContextValues }, children);
    if (formField === 'none') {
        return context;
    }
    var formFieldElement = !formField || formField === 'default' ? (parentContext ? 'fieldset' : 'form') : formField;
    if (formFieldElement === 'fieldset') {
        return (React.createElement("fieldset", { style: { padding: 'unset', margin: 'unset', border: 'unset', minInlineSize: 'inherit' } }, context));
    }
    return React.createElement("form", null, context);
};
/**
 * Register the current field for the current form context
 *
 * Usage:
 *
 * ```ts
 * const isRequired = (value) => value === '' ? 'This field is required' : undefined;
 * const [errorMessage, touched, onBlur] = useFormValidation('email', currentValue, isRequired);
 * ```
 */
export var useFormValidation = function (fieldName, value, validator, validatorFieldDependencies) {
    var context = useFormContext();
    var validate = context.validate;
    var touchedRef = useRef(false);
    var contextRef = useRef(null);
    contextRef.current = context;
    // Register / unregister this field for the given name
    useEffect(function () {
        if (validate !== false) {
            contextRef.current.dispatch({
                type: FormStateActionNames.addField,
                formId: contextRef.current.id,
                fieldName: fieldName,
                touched: touchedRef.current,
                validator: validator,
                validatorFieldDependencies: validatorFieldDependencies,
            });
            contextRef.current.dispatch({
                type: FormStateActionNames.setValue,
                fieldName: fieldName,
                formId: contextRef.current.id,
                value: value,
            });
        }
        return function () {
            return contextRef.current.dispatch({
                type: FormStateActionNames.removeField,
                formId: contextRef.current.id,
                fieldName: fieldName,
            });
        };
    }, [fieldName, validate]);
    // Update the store and trigger the validation
    // whenever the value changes
    useEffect(function () {
        contextRef.current.dispatch({
            formId: contextRef.current.id,
            type: FormStateActionNames.setValue,
            fieldName: fieldName,
            value: value,
        });
    }, [value]);
    // an error message should only be visible
    // once the user interacted with a field
    var onBlur = useCallback(function () {
        touchedRef.current = true;
        contextRef.current.dispatch({
            formId: contextRef.current.id,
            type: FormStateActionNames.touchedField,
            fieldName: fieldName,
        });
    }, [fieldName]);
    // Try to return the values for the given
    // field name
    var field = context.fields.get(fieldName);
    return ((field && [field.validationErrorMessage, field.touched, onBlur]) ||
        [undefined, false, onBlur]);
};
/**
 * Returns true if all form fields and sub forms of the current
 * context are valid
 *
 * Usage:
 *
 * ```ts
 * const isFormValid = useIsFormValid();
 * ```
 */
export var useIsFormValid = function () {
    var formContext = useFormContext();
    var validity = searchRecursivelyToContainOnlyValidFields(formContext.rootState, formContext.id);
    return validity === 'valid';
};
/**
 * Returns true as long as no form or subform of the current
 * form context display any error messages.
 *
 * Usage:
 *
 * ```ts
 * const hasVisibleErrors = useIsFormWithoutVisibleErrorMessages();
 * ```
 */
export var useIsFormWithoutVisibleErrorMessages = function () {
    var formContext = useContext(FormStateContext);
    if (formContext === null) {
        return true;
    }
    var validity = searchRecursivelyToContainOnlyValidFields(formContext.rootState, formContext.id, true);
    return validity === 'valid';
};
/**
 * Returns true if all form fields and sub forms of the current
 * context are valid
 *
 * Usage:
 *
 * ```ts
 * const showAllErrors = useFormValidationShowAllErrors();
 * return <button onClick={() => showAllErrors()}>Save</button>
 * ```
 */
export var useFormValidationShowAllErrors = function () {
    var formContext = useFormContext();
    return function () { return formContext.dispatch({ type: FormStateActionNames.touchedAllFields, formId: formContext.id }); };
};
/**
 * Small helper function to allow acting once the valid state
 * switches from valid to invalid
 */
export var useIsFormValidEffect = function (callback) {
    var isValid = useIsFormValid();
    useEffect(function () {
        return callback(isValid);
    }, [isValid]);
};
/**
 * Returns the current field error mesage
 */
export var useFormValidationFieldErrorMessage = function (fieldName, returnAlsoIfUntouched) {
    var context = useFormContext();
    // Try to return the values for the given
    // field name
    var field = context.fields.get(fieldName);
    return field && (field.touched || returnAlsoIfUntouched) ? field.validationErrorMessage : undefined;
};
/**
 * Iterates recursively over the forms fields and all subform fields.
 * Returns `invalid` if any field is `invalid`
 * Returns `unknown` if any field is `unknown` but no field is `invalid`
 * Returns `valid` if all fields are valid
 */
function searchRecursivelyToContainOnlyValidFields(form, formId, onlyTouched) {
    var fields = form.fields.get(formId);
    if (!fields) {
        return 'valid';
    }
    // Check own fields
    var fieldValues = Array.from(fields.values());
    var isAnyFieldUnknown = false;
    for (var _i = 0, fieldValues_1 = fieldValues; _i < fieldValues_1.length; _i++) {
        var field = fieldValues_1[_i];
        if (!onlyTouched || field.touched) {
            if (field.validationState === 'invalid') {
                return field.validationState;
            }
            else if (field.validationState === 'unknown') {
                isAnyFieldUnknown = true;
            }
        }
    }
    // Check child fields
    var children = Array.from(form.children.get(formId) || []);
    if (children.length) {
        for (var _a = 0, children_1 = children; _a < children_1.length; _a++) {
            var childId = children_1[_a];
            var childValidationState = searchRecursivelyToContainOnlyValidFields(form, childId, onlyTouched);
            if (childValidationState === 'invalid') {
                return childValidationState;
            }
            else if (childValidationState === 'unknown') {
                isAnyFieldUnknown = true;
            }
        }
    }
    return isAnyFieldUnknown ? 'unknown' : 'valid';
}
/**
 * Set all fields for this form id and all child forms to be touched
 * returns true if anything was changed
 */
function touchAllFields(formRootState, formId) {
    var wasAnythingChanged = false;
    var fields = formRootState.fields.get(formId);
    var children = formRootState.children.get(formId);
    if (fields) {
        var fieldEntries = Array.from(fields.values());
        fieldEntries.forEach(function (field) {
            wasAnythingChanged = wasAnythingChanged || !field.touched;
            field.touched = true;
        });
    }
    if (children) {
        Array.from(children.values()).forEach(function (childId) {
            var wasChildChanged = touchAllFields(formRootState, childId);
            wasAnythingChanged = wasAnythingChanged || wasChildChanged;
        });
    }
    return wasAnythingChanged;
}
var useFormContext = function () {
    var context = useContext(FormStateContext);
    if (!context) {
        throw new Error('Could not find FormValidationContext context');
    }
    return context;
};
