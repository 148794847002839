import { bindTranslations } from '@jelmoli/i18n/index';
import { trackVersandClick } from '@jelmoli/sitecore-services/src/Tracking/Navigation/VersandLinkTracking';
import React, { useState } from 'react';
import { Dialog } from '../../../../vendors/material-ui/src/components/Dialog/Dialog';
import { PrimaryButton } from '../../atoms/PrimaryButton/PrimaryButton';
import { SecondaryButton } from '../../atoms/SecondaryButton/SecondaryButton';
import { HorizontalSpacer } from '../../helpers/HorizontalSpacer/HorizontalSpacer';
import { ScreenSizeDetector } from '../../helpers/ScreenSizeDetector/ScreenSizeDetector';
import { hasLinkFieldValue } from '../../helpers/SitecoreTypes/SitecoreTypes';
import { Typography } from '../../helpers/Typography/Typography';
import { VerticalSpacer } from '../../helpers/VerticalSpacer/VerticalSpacer';
import './header-link-overlay.scss';
export var HeaderLinkOverlay = function (props) {
    var _a = useState(false), isSmallScreen = _a[0], setIsSmallScreen = _a[1];
    var t = bindTranslations(props);
    return (React.createElement(React.Fragment, null,
        React.createElement(ScreenSizeDetector, { viewports: ['xs'], onViewportChange: function (isSmallerScreen) {
                setIsSmallScreen(isSmallerScreen);
            } }),
        React.createElement(Dialog, { onClose: props.onClose, open: props.open, fullScreen: isSmallScreen, PaperProps: { elevation: 0, square: true }, maxWidth: false, classes: { paper: 'o-header-link-overlay' } },
            React.createElement(Typography, { size: "l", responsive: false, type: "medium" }, props.HeaderLinkWarningTitle.value),
            React.createElement(VerticalSpacer, { size: "xs" }),
            React.createElement("div", { className: "o-header-link-overlay__description" },
                React.createElement(Typography, { size: "s", responsive: false, type: "regular" }, props.HeaderLinkWarningDescription.value)),
            React.createElement(VerticalSpacer, { size: "l", responsive: false }),
            React.createElement("div", { className: "o-header-link-overlay__buttons" },
                React.createElement("div", { className: "o-header-link-overlay__button-wrapper" },
                    React.createElement(SecondaryButton, { type: "button", size: "tiny", fullWidth: true, fontResponsive: false, text: t('HeaderLinkWarningCancelButton'), onClick: props.onClose })),
                React.createElement(HorizontalSpacer, { size: "xs" }),
                React.createElement("div", { className: "o-header-link-overlay__button-wrapper" },
                    React.createElement(PrimaryButton, { type: "button", size: "tiny", fontResponsive: false, fullWidth: true, text: t('HeaderLinkWarningOkButton'), onClick: function () {
                            if (hasLinkFieldValue(props.HeaderLink)) {
                                trackVersandClick(props.HeaderLink.value.href);
                                window.open(props.HeaderLink.value.href);
                            }
                        } }))))));
};
