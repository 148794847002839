var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Column, Row } from '@jelmoli/bootstrap-grid';
import { bindTranslations } from '@jelmoli/i18n/index';
import { getProfile } from '@jelmoli/sitecore-services/src/Account/getProfile/getProfile';
import { isUserLoggedInLazy } from '@jelmoli/sitecore-services/src/Account/isUserLoggedIn/isUserLoggedInLazy';
import { Modal } from '@jelmoli/ui-components/src/atoms/Modal/Modal';
import { SecondaryButton } from '@jelmoli/ui-components/src/atoms/SecondaryButton/SecondaryButton';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { Visibility } from '@jelmoli/ui-components/src/helpers/Visibility/Visibility';
import React, { useState } from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
import './my-account-profile.scss';
import { MyAccountProfileOverviewModalContent } from './MyAccountProfileOverviewModalContent';
export var MyAccountProfileOverview = function (props) {
    var _a = useState(false), isOverlayOpen = _a[0], setIsOverlayOpen = _a[1];
    var isLoggedIn = isUserLoggedInLazy();
    var getProfileData = useGetEndPointLazy(getProfile, {}, isLoggedIn).value;
    var t = bindTranslations(props);
    var titleSelected = !(getProfileData &&
        (getProfileData.titleCode === 'na' || getProfileData.titleCode === undefined));
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, null,
            titleSelected && getProfileData && getProfileData.titleCode && (React.createElement(Column, { size: 6 },
                React.createElement("div", { className: "feat-my-account-profile__label" },
                    React.createElement(Typography, { size: "xs", uppercase: true, type: "medium" }, props.JobTitle.fields.FieldLabel.value)),
                React.createElement(Typography, { size: "l", type: "medium", tag: "p" }, getProfileData.titleName))),
            React.createElement(Column, { size: 6 },
                React.createElement("div", { className: "feat-my-account-profile__label" },
                    React.createElement(Typography, { size: "xs", uppercase: true, type: "medium" }, props.Salutation.fields.FieldLabel.value)),
                React.createElement(Typography, { size: "l", type: "medium", tag: "p" }, getProfileData && getProfileData.salutationName ? getProfileData.salutationName : '-'))),
        React.createElement(VerticalSpacer, { size: "m" }),
        React.createElement(Row, null,
            React.createElement(Column, { size: 6 },
                React.createElement("div", { className: "feat-my-account-profile__label" },
                    React.createElement(Typography, { size: "xs", uppercase: true, type: "medium" }, props.FirstName.fields.FieldLabel.value)),
                React.createElement(Typography, { size: "l", type: "medium", tag: "p" }, getProfileData && getProfileData.firstName ? getProfileData.firstName : '-')),
            React.createElement(Column, { size: 6 },
                React.createElement("div", { className: "feat-my-account-profile__label" },
                    React.createElement(Typography, { size: "xs", uppercase: true, type: "medium" }, props.LastName.fields.FieldLabel.value)),
                React.createElement(Typography, { size: "l", type: "medium", tag: "p" }, getProfileData && getProfileData.lastName ? getProfileData.lastName : '-'))),
        React.createElement(VerticalSpacer, { size: "m" }),
        React.createElement(Row, null,
            React.createElement(Column, { size: 6 },
                React.createElement("div", { className: "feat-my-account-profile__label" },
                    React.createElement(Typography, { size: "xs", uppercase: true, type: "medium" }, props.BirthDate.fields.FieldLabel.value)),
                React.createElement(Typography, { size: "l", type: "medium", tag: "p" }, getProfileData && getProfileData.birthday ? getProfileData.birthday : '-')),
            React.createElement(Column, { size: 6 },
                React.createElement("div", { className: "feat-my-account-profile__label" },
                    React.createElement(Typography, { size: "xs", uppercase: true, type: "medium" }, props.MobileNumber.fields.FieldLabel.value)),
                React.createElement(Typography, { size: "l", type: "medium", tag: "p" }, getProfileData && getProfileData.mobile ? getProfileData.mobile : '-'))),
        React.createElement(VerticalSpacer, { size: "l" }),
        React.createElement(Visibility, { show: ['xs', 'sm'] },
            React.createElement(SecondaryButton, { fontResponsive: false, type: "button", text: t('ProfileEditLabel'), fullWidth: true, size: "tiny", onClick: function () { return setIsOverlayOpen(true); } })),
        React.createElement(Visibility, { hide: ['xs', 'sm'] },
            React.createElement(SecondaryButton, { fontResponsive: false, type: "button", size: "tiny", text: t('ProfileEditLabel'), onClick: function () { return setIsOverlayOpen(true); } })),
        React.createElement(Modal, { open: isOverlayOpen, title: t('ProfileEditLabel'), onClose: function () { return setIsOverlayOpen(false); } },
            React.createElement(MyAccountProfileOverviewModalContent, __assign({ onClose: function () { return setIsOverlayOpen(false); } }, props)))));
};
