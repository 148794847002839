var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { bindTranslations, I18N } from '@jelmoli/i18n/index';
import { getCart } from '@jelmoli/sitecore-services/src/Cart/getCart/getCart';
import { getCheckoutLabels } from '@jelmoli/sitecore-services/src/Checkout/getCheckoutLabels/getCheckoutLabels';
import { Checkbox } from '@jelmoli/ui-components/src/atoms/Checkbox/Checkbox';
import { FormFieldErrorMessage } from '@jelmoli/ui-components/src/atoms/FormFieldErrorMessage/FormFieldErrorMessage';
import { TextFieldWithFormSetting } from '@jelmoli/ui-components/src/atoms/TextFieldValidatable/TextFieldValidatable';
import { getLabelsFromFormFields } from '@jelmoli/ui-components/src/helpers/SitecoreTypes/SitecoreTypes';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import classnames from 'classnames';
import React, { useEffect } from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
export var ShippingMethodBulkyProducts = function (props) {
    var cartData = useGetEndPointLazy(getCart).value;
    var checkoutLabels = useGetEndPointLazy(getCheckoutLabels).value;
    useEffect(function () {
        props.setIsSavePhone(props.isMobileAdded);
    }, [props.isMobileAdded]);
    useEffect(function () {
        if (checkoutLabels) {
            checkoutLabels.Mobile.fields.IsRequired = { value: props.isSavePhone };
        }
    }, [props.isSavePhone]);
    if (!cartData || !checkoutLabels || !props.bulky) {
        return null;
    }
    checkoutLabels.Mobile.fields.IsRequired = { value: props.isSavePhone };
    var t = bindTranslations(__assign(__assign({}, checkoutLabels), getLabelsFromFormFields(checkoutLabels)));
    var shippingMethodTranslation = bindTranslations(props.bulky);
    var productsInstallationServiceOptional = cartData.lines.filter(function (line) {
        return line.fulfillmentFlags &&
            line.fulfillmentFlags.installationServiceAvailable &&
            !line.fulfillmentFlags.installationServiceMandatory;
    });
    var productsInstallationServiceRequired = cartData.lines.filter(function (line) { return line.fulfillmentFlags && line.fulfillmentFlags.installationServiceMandatory; });
    return (React.createElement(React.Fragment, null,
        React.createElement(Typography, { size: "s", responsive: false },
            React.createElement(I18N, { text: shippingMethodTranslation('name'), replacements: {
                    price: (React.createElement("span", { className: classnames('feat-checkout-step-delivery-options__bulky-price', {
                            'feat-checkout-step-delivery-options__bulky-price--crossed': productsInstallationServiceRequired.length > 0 ||
                                props.isSelectedService.length > 0,
                        }) },
                        "(",
                        props.bulky.price || t('FreeLabel'),
                        ")")),
                } })),
        React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
        props.installationService && (React.createElement(React.Fragment, null,
            productsInstallationServiceRequired.length > 0 && (React.createElement(React.Fragment, null,
                React.createElement(Typography, { size: "xs", type: "medium", responsive: false }, t('CheckoutNotificationInstallationService')),
                React.createElement(VerticalSpacer, { size: "m", responsive: false }),
                productsInstallationServiceRequired.map(function (product, index) {
                    var priceLabel = product.quantity + "x " + product.displayName + " " + product.brandName + " (" + (props.installationService && props.installationService.price) + ") " + t('CheckoutPerProductLabel');
                    return (React.createElement(React.Fragment, { key: product.productId },
                        React.createElement(Typography, { size: "m", responsive: false }, priceLabel),
                        productsInstallationServiceRequired.length > index + 1 && (React.createElement(VerticalSpacer, { size: "xs", responsive: false }))));
                }),
                React.createElement(VerticalSpacer, { size: "l", responsive: false }))),
            productsInstallationServiceOptional.length > 0 && (React.createElement(React.Fragment, null,
                React.createElement(Typography, { size: "xs", responsive: false, type: "medium" }, props.installationService.name),
                React.createElement(VerticalSpacer, { size: "m", responsive: false }),
                productsInstallationServiceOptional.map(function (product, i) {
                    var priceLabel = product.quantity + "x " + product.displayName + " " + product.brandName + " (" + (props.installationService && props.installationService.price) + ") " + t('CheckoutPerProductLabel');
                    var singleProductPriceLabel = product.displayName + " " + product.brandName + " (" + (props.installationService && props.installationService.price) + ")";
                    return (React.createElement(React.Fragment, { key: product.productId },
                        React.createElement(Checkbox, { label: product.quantity > 1 ? priceLabel : singleProductPriceLabel, value: props.isSelectedService.some(function (item) { return item === product.cartLineId; }), onChange: function () {
                                var isSelected = props.isSelectedService.some(function (item) { return item === product.cartLineId; });
                                if (isSelected) {
                                    props.setIsSelectedService(props.isSelectedService.filter(function (item) { return item !== product.cartLineId; }));
                                    return;
                                }
                                props.setIsSelectedService(__spreadArrays(props.isSelectedService, [
                                    product.cartLineId,
                                ]));
                            } }),
                        productsInstallationServiceOptional.length > i + 1 && (React.createElement(VerticalSpacer, { size: "xs", responsive: false }))));
                }),
                React.createElement(VerticalSpacer, { size: "mx", responsive: false }))),
            React.createElement(Typography, { size: "s", responsive: false }, t('CheckoutDeliveryOptionsEmailNotification')),
            React.createElement(VerticalSpacer, { size: "s", responsive: false }),
            React.createElement(Checkbox, { label: t('TelephoneContactRequest'), value: props.isSavePhone, onChange: function () { return props.setIsSavePhone(!props.isSavePhone); } }),
            React.createElement(VerticalSpacer, { size: "s", responsive: false }),
            props.isSavePhone && (React.createElement(TextFieldWithFormSetting, __assign({ disabled: !props.isSavePhone, variant: "standard", type: "tel", name: "mobile", formSetting: checkoutLabels.Mobile, fullWidth: true }, props.form.fieldProps.mobile))),
            !props.isSavePhone && (React.createElement(TextFieldWithFormSetting, __assign({ disabled: !props.isSavePhone, variant: "standard", type: "tel", name: "mobile", formSetting: checkoutLabels.Mobile, fullWidth: true }, props.form.fieldProps.mobile))),
            props.isSavePhone && React.createElement(FormFieldErrorMessage, { name: "mobile", verticalSpaceTop: "xxs" })))));
};
