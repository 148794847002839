var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { observer } from 'mobx-react-lite';
import { FormValidationContext } from 'nested-form-validation';
import React from 'react';
import { FormFieldErrorMessage } from '../../atoms/FormFieldErrorMessage/FormFieldErrorMessage';
import { PasswordFieldValidatableWithFormSetting } from '../../atoms/PasswordFieldValidatable/PasswordFieldValidatable';
import { TextFieldWithFormSetting } from '../../atoms/TextFieldValidatable/TextFieldValidatable';
import { VerticalSpacer } from '../../helpers/VerticalSpacer/VerticalSpacer';
import './login-form.scss';
export var LoginForm = observer(function (_a) {
    var fields = _a.fields, fieldValues = _a.fieldValues, focusInput = _a.focusInput;
    return (React.createElement(FormValidationContext, null,
        React.createElement(TextFieldWithFormSetting, __assign({ name: "username", formSetting: fields.Username }, fieldValues.inputUsername, { fullWidth: true, autoFocus: focusInput })),
        React.createElement(FormFieldErrorMessage, { name: "username", verticalSpaceTop: "xxs" }),
        React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
        React.createElement(PasswordFieldValidatableWithFormSetting, __assign({ name: "password", formSetting: fields.Password }, fieldValues.inputPassword, { fullWidth: true })),
        React.createElement(FormFieldErrorMessage, { name: "password", verticalSpaceTop: "xxs" }),
        React.createElement(VerticalSpacer, { size: "xl", responsive: false })));
});
