import { HeaderInfolineElement } from '@jelmoli/ui-components/src/helpers/HeaderInfoline/HeaderInfoline';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import './infoline-component.scss';
export var InfolineComponent = function (props) {
    var infolineLength = props.fields.InfolineItems.length;
    var _a = useState(0), activeIndex = _a[0], setActiveIndex = _a[1];
    useEffect(function () {
        if (infolineLength > 1) {
            var intervalId_1 = setInterval(function () {
                setActiveIndex(function (prevCount) {
                    if (infolineLength - 1 === prevCount) {
                        return 0;
                    }
                    return prevCount + 1;
                });
            }, 5000);
            return function () { return clearInterval(intervalId_1); };
        }
    }, []);
    return (React.createElement(React.Fragment, null, infolineLength ? (React.createElement("div", { className: "feat-header-infoline__component" }, props.fields.InfolineItems.map(function (item, index) {
        return (React.createElement("div", { className: classnames('feat-header-infoline__component--hide', {
                'feat-header-infoline__component--display': index === activeIndex,
            }), key: item.id },
            React.createElement(HeaderInfolineElement, { fields: item.fields })));
    }))) : (React.createElement(React.Fragment, null))));
};
