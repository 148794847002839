var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useMiniFormik } from '@jelmoli/hooks/src/useMiniFormik/useMiniFormik';
import { bindTranslations } from '@jelmoli/i18n';
import { updateMagazineSettings } from '@jelmoli/sitecore-services/src/Account/updateMagazineSettings/updateMagazineSettings';
import { updateMainAddress } from '@jelmoli/sitecore-services/src/Account/updateMainAddress/updateMainAddress';
import { updateOrderJCard } from '@jelmoli/sitecore-services/src/Account/updateOrderJCard/updateOrderJCard';
import { trackMagazineSignUp } from '@jelmoli/sitecore-services/src/Tracking/Account/MagazineSignUpTracking';
import { trackError } from '@jelmoli/sitecore-services/src/Tracking/Error/ErrorTesting';
import { CheckboxWithFormFieldSetting } from '@jelmoli/ui-components/src/atoms/CheckboxValidatable/CheckboxValidatable';
import { Heart } from '@jelmoli/ui-components/src/atoms/Icon/Heart';
import { Profile } from '@jelmoli/ui-components/src/atoms/Icon/Profile';
import { showErrorNotification, showNotificationAndGotoPage, showSuccessNotification, } from '@jelmoli/ui-components/src/atoms/Notification/Notification';
import { PrimarySubmitButton } from '@jelmoli/ui-components/src/atoms/PrimarySubmitButton/PrimarySubmitButton';
import { TextLink } from '@jelmoli/ui-components/src/atoms/TextLink/TextLink';
import { HorizontalSpacer } from '@jelmoli/ui-components/src/helpers/HorizontalSpacer/HorizontalSpacer';
import { getNodeAttributes, onlyTagsWithInnerHTML, RichTextRenderer, } from '@jelmoli/ui-components/src/helpers/RichTextRenderer/RichTextRenderer';
import { getLabelsFromFormFields } from '@jelmoli/ui-components/src/helpers/SitecoreTypes/SitecoreTypes';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { Visibility } from '@jelmoli/ui-components/src/helpers/Visibility/Visibility';
import { AddressForm } from '@jelmoli/ui-components/src/organism/AddressForm/AddressForm';
import { TelephoneField } from '@jelmoli/ui-components/src/organism/TelephoneField/TelephoneField';
import { FormValidationContext } from 'nested-form-validation';
import React, { useState } from 'react';
export var RegistrationSecondStep = function (_a) {
    var fields = _a.fields;
    var _b = useState(false), isLoading = _b[0], setIsLoading = _b[1];
    var form = useMiniFormik({
        initialValues: {
            company: '',
            address1: '',
            address2: '',
            secondAddress: '',
            zipPostalCode: '',
            city: '',
            country: 'CH',
            jCard: false,
            magazine: false,
            mobile: '',
        },
    });
    var t = bindTranslations(__assign(__assign({}, fields), getLabelsFromFormFields(fields)));
    // New rules needed to remove spacers added by default bellow each element
    var registrationRules = {
        A: onlyTagsWithInnerHTML(function (content, node) { return (React.createElement(TextLink, __assign({ "aria-label": "navigation from registration" }, getNodeAttributes(node)), content)); }),
        B: onlyTagsWithInnerHTML(function (content) { return (React.createElement(Typography, { wrap: "regular", size: "l", type: "medium", tag: "span" }, content)); }),
        P: onlyTagsWithInnerHTML(function (content) { return (React.createElement(Typography, { wrap: "regular", size: "l", tag: "p" }, content)); }),
        STRONG: onlyTagsWithInnerHTML(function (content) { return (React.createElement(Typography, { wrap: "regular", size: "l", type: "medium", tag: "span" }, content)); }),
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(RichTextRenderer, { html: t('RegistrationConfirmationText'), replaceRules: registrationRules }),
        React.createElement(VerticalSpacer, { size: "mx" }),
        React.createElement("div", { className: "feat-registration__inspiration" },
            React.createElement("div", { className: "feat-registration__heart" },
                React.createElement(Heart, { width: 20 })),
            React.createElement(HorizontalSpacer, { size: "xs" }),
            React.createElement(RichTextRenderer, { html: t('RegistrationInspirationTeaserText'), replaceRules: registrationRules })),
        React.createElement(VerticalSpacer, { size: "mx" }),
        React.createElement("div", { className: "feat-registration__divider" },
            React.createElement("span", { className: "feat-registration__divider-line" }),
            React.createElement("div", null,
                React.createElement(HorizontalSpacer, { size: "xxs" })),
            React.createElement(Typography, { size: "s", responsive: false, field: fields.RegistrationSeparationalTitle }),
            React.createElement("div", null,
                React.createElement(HorizontalSpacer, { size: "xxs" })),
            React.createElement("span", { className: "feat-registration__divider-line" })),
        React.createElement(VerticalSpacer, { size: "mx" }),
        React.createElement("div", { className: "feat-registration__intro" },
            React.createElement("div", { className: "feat-registration__profile" },
                React.createElement(Profile, { width: 18 })),
            React.createElement(HorizontalSpacer, { size: "xs" }),
            React.createElement(RichTextRenderer, { html: t('RegistrationMainAdressIntroText'), replaceRules: registrationRules })),
        React.createElement(VerticalSpacer, { size: "mx" }),
        React.createElement(FormValidationContext, null,
            React.createElement(AddressForm, { fieldValues: form.fieldProps, fields: fields, removeLastSpacing: true }),
            React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
            React.createElement(TelephoneField, { PrefixLabel: fields.MobilePrefix, FieldValue: form.fieldProps, CountryListTelephone: fields.MobilePrefixList }),
            React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
            React.createElement(CheckboxWithFormFieldSetting, __assign({ name: "Magazine", formSetting: fields.Magazine }, form.fieldProps.magazine)),
            React.createElement(VerticalSpacer, { size: "m" }),
            React.createElement(CheckboxWithFormFieldSetting, __assign({ name: "JCard", formSetting: fields.JCard }, form.fieldProps.jCard)),
            React.createElement(VerticalSpacer, { size: "xl", responsive: false }),
            React.createElement(Visibility, { show: ['xs', 'sm'] },
                React.createElement(PrimarySubmitButton, { text: t('RegistrationSaveAddressButtonLabel'), uppercase: true, loading: isLoading, fullWidth: true, fontResponsive: false, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    setIsLoading(true);
                                    return [4 /*yield*/, updateMainAddress({}, formatDataForSubmit(form.values))
                                            .then(function () { return __awaiter(void 0, void 0, void 0, function () {
                                            return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0: return [4 /*yield*/, updateMagazineSettings({}, { magazine: form.values.magazine, print: false })
                                                            .then(function (response) {
                                                            if (form.values.magazine) {
                                                                trackMagazineSignUp(true);
                                                            }
                                                            showSuccessNotification(response.globalMessage);
                                                        })
                                                            .catch(function (response) {
                                                            if (form.values.magazine) {
                                                                trackMagazineSignUp(false);
                                                            }
                                                            trackError({
                                                                page: 'Error in updating subsciption to JMagazine on during registration',
                                                                errorMessage: response.GlobalMessage,
                                                            });
                                                            showErrorNotification(response.GlobalMessage);
                                                        })];
                                                    case 1:
                                                        _a.sent();
                                                        return [4 /*yield*/, updateOrderJCard({}, { orderJCard: form.values.jCard })
                                                                .then(function () {
                                                                setIsLoading(false);
                                                                return showNotificationAndGotoPage('success', 'RegistrationMainAddressSuccess', fields.RegistrationRedirectLink.value.href || '/', {
                                                                    newHistoryEntry: true,
                                                                });
                                                            })
                                                                .catch(function (response) {
                                                                trackError({
                                                                    page: 'Error in updating order for JCard on registration page',
                                                                    errorMessage: response.globalMessage,
                                                                });
                                                                setIsLoading(false);
                                                            })];
                                                    case 2:
                                                        _a.sent();
                                                        return [2 /*return*/];
                                                }
                                            });
                                        }); })
                                            .catch(function (response) {
                                            trackError({
                                                page: 'Error in updating main address on registration page',
                                                errorMessage: response.GlobalMessage,
                                            });
                                            showErrorNotification(response.GlobalMessage);
                                            setIsLoading(false);
                                        })];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); } })),
            React.createElement(Visibility, { hide: ['xs', 'sm'] },
                React.createElement(PrimarySubmitButton, { text: t('RegistrationSaveAddressButtonLabel'), uppercase: true, loading: isLoading, fontResponsive: false, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    setIsLoading(true);
                                    return [4 /*yield*/, updateMainAddress({}, formatDataForSubmit(form.values))
                                            .then(function () { return __awaiter(void 0, void 0, void 0, function () {
                                            return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0: return [4 /*yield*/, updateMagazineSettings({}, { magazine: form.values.magazine, print: false })
                                                            .then(function (response) {
                                                            if (form.values.magazine) {
                                                                trackMagazineSignUp(true);
                                                            }
                                                            showSuccessNotification(response.globalMessage);
                                                        })
                                                            .catch(function (response) {
                                                            if (form.values.magazine) {
                                                                trackMagazineSignUp(false);
                                                            }
                                                            trackError({
                                                                page: 'Error in updating subsciption to JMagazine on during registration',
                                                                errorMessage: response.GlobalMessage,
                                                            });
                                                            showErrorNotification(response.GlobalMessage);
                                                        })];
                                                    case 1:
                                                        _a.sent();
                                                        return [4 /*yield*/, updateOrderJCard({}, { orderJCard: form.values.jCard })
                                                                .then(function () {
                                                                setIsLoading(false);
                                                                return showNotificationAndGotoPage('success', 'RegistrationMainAddressSuccess', fields.RegistrationRedirectLink.value.href || '/', {
                                                                    newHistoryEntry: true,
                                                                });
                                                            })
                                                                .catch(function (response) {
                                                                trackError({
                                                                    page: 'Error in updating order for JCard on registration page',
                                                                    errorMessage: response.globalMessage,
                                                                });
                                                            })];
                                                    case 2:
                                                        _a.sent();
                                                        setIsLoading(false);
                                                        return [2 /*return*/];
                                                }
                                            });
                                        }); })
                                            .catch(function (response) {
                                            trackError({
                                                page: 'Error in updating main address on registration page',
                                                errorMessage: response.GlobalMessage,
                                            });
                                            showErrorNotification(response.GlobalMessage);
                                            setIsLoading(false);
                                        })];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); } })))));
};
function formatDataForSubmit(data) {
    return __assign(__assign({}, data), { countryCode: data.country, addToAddressBook: true });
}
