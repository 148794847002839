var _a;
import { DeliveryOptionConstants, } from '@jelmoli/sitecore-services/src/Cart/getDeliveryOptions/getDeliveryOptions';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import classname from 'classnames';
import React from 'react';
var IconMap = (_a = {},
    _a[DeliveryOptionConstants.home] = 'home',
    _a[DeliveryOptionConstants.post] = 'post24',
    _a[DeliveryOptionConstants.pickup] = 'pickup',
    _a[DeliveryOptionConstants.digital] = 'digital',
    _a);
export var CheckoutDeliveryOptionButton = function (_a) {
    var children = _a.children, checked = _a.checked, disabled = _a.disabled, id = _a.id;
    return (React.createElement("span", { className: classname('feat-checkout-step-delivery-options__option', {
            'feat-checkout-step-delivery-options__option--checked': checked,
            'feat-checkout-step-delivery-options__option--disabled': disabled,
        }) },
        React.createElement(CheckoutDeliveryOptionIcon, { icon: IconMap[id] }),
        React.createElement(Typography, { size: "xs", responsive: false, type: "regular" }, children)));
};
var CheckoutDeliveryOptionIcon = function (_a) {
    var icon = _a.icon;
    return (React.createElement("span", { className: "feat-checkout-step-delivery-options__icon feat-checkout-step-delivery-options__icon--" + icon }));
};
