var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import classnames from 'classnames';
import React from 'react';
import { preventOutline } from '../../helpers/A/A';
import './interactive-icon.scss';
export var InteractiveIcon = function (props) {
    var Tag = props.tag || 'a';
    return (React.createElement(Tag, __assign({ "aria-label": props.areaLabelText, role: "button", tabIndex: 0, onClick: function () {
            if (props.onClick) {
                props.onClick();
            }
        } }, preventOutline(), { className: classnames('a-interactive-icon', {
            'a-interactive-icon--selected': props.active,
        }), style: { width: props.width } }),
        React.createElement("span", { className: "a-interactive-icon__padding" },
            React.createElement("span", { className: "a-interactive-icon__inner" }, props.children))));
};
