// This package is very similar to https://www.npmjs.com/package/query-string
// however as URLSearchParams is now supported by the browser it is way smaller
/**
 * Generates a querystring out of a given object
 * with proper escaping
 */
export function toQueryString(data, baseQueryString) {
    if (baseQueryString === void 0) { baseQueryString = ''; }
    // https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
    // Supported by all browsers but IE11
    if (typeof URLSearchParams === 'undefined') {
        return '';
    }
    var queryString = new URLSearchParams(baseQueryString);
    Object.keys(data).forEach(function (parameterName) {
        var parameterValue = data[parameterName];
        if (Array.isArray(parameterValue)) {
            queryString.delete(parameterName);
            parameterValue.forEach(function (value) {
                queryString.append(parameterName, String(value));
            });
        }
        else {
            queryString.set(parameterName, String(parameterValue));
        }
    });
    return queryString.toString();
}
/**
 * Parses a string to a querystring object
 */
export function parseQueryString() {
    var result = {};
    // https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
    // Supported by all browsers but IE11
    if (typeof URLSearchParams === 'undefined') {
        return result;
    }
    var queryString = new URLSearchParams(location.search);
    // Handle the following queryString cases
    // ?page=4                     --->   { page: "4" }
    // ?likes=cherries&likes=pears --->   { likes: ["cherries", "pears"] }
    queryString.forEach(function (_value, key) {
        if (result[key] !== undefined) {
            return;
        }
        var arrayValue = queryString.getAll(key);
        result[key] = arrayValue.length > 1 ? arrayValue : arrayValue[0];
    });
    return result;
}
