import classnames from 'classnames';
import React from 'react';
export var hoverAnchorClassName = "h-hover-anchor";
export var focusAnchorClassName = "h-hover-anchor--focus";
/**
 * The hover anchor helper allows to share hover effects to
 * decouple children from their parents
 * See: https://codepen.io/jantimon/pen/zXqyOy?editors=1100
 *
 * In this example the Hello World text would change the color
 * once the wrapper is hovered:
 *
 * @example
 * ```scss
 * .demo {
 *   @include hoverAnchor() {
 *    color: red
 *  }
 * }
 * ```
 * ```jsx
 * <HoverAnchor>
 *    <div class="demo">Hello world</div>
 * </HoverAnchor>
 *```
 */
export var HoverAnchor = function (props) { return (React.createElement("div", { className: classnames(props.className, hoverAnchorClassName) }, props.children)); };
