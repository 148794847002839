var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { getUserLanguage } from '@jelmoli/sitecore-services/src/Account/getUserLanguage/getUserLanguage';
import { getNotificationLabels, } from '@jelmoli/sitecore-services/src/Notifications/getNotificationLabels/getNotificationLabels';
import { showErrorNotification, showNotification } from '@jelmoli/ui-components/src/atoms/Notification/Notification';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { waitForLogin } from '@jelmoli/ui-components/src/organism/LoginDrawer/LoginDrawer';
import { urlManager } from '@jelmoli/urlmanager/UrlManager';
import React from 'react';
import { onUnhandledRequestRegistyError } from 'request-registry-errors';
import { CustomMessage } from './CustomMessage';
/**
 * This Component doesn't render anything
 *
 * To make it easier for backend integration it is build similar
 * to a renderable component but it will execute as a site effect
 */
export var NotificationComponent = function () {
    return React.createElement(React.Fragment, null);
};
// Usually components should NOT have any site effects during boot
// Global Notification is special as it doesn't have any rendering
// and will be executed on every page
runOnBootAsSideEffect();
function runOnBootAsSideEffect() {
    return __awaiter(this, void 0, void 0, function () {
        var statusMessageIds, errorMessageIds, labels, notificationMessages, errorMessages;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    // Ignore on ServerSide
                    if (typeof window === 'undefined') {
                        return [2 /*return*/];
                    }
                    // Show error messages for ajax errors
                    handleAjaxErrors();
                    statusMessageIds = extractAndRemoveParameterFromQueryString('statusMsg');
                    errorMessageIds = extractAndRemoveParameterFromQueryString('errorMsg');
                    // Stop if no messages were found
                    if (!statusMessageIds && !errorMessageIds) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, getNotificationLabels({})];
                case 1:
                    labels = _a.sent();
                    notificationMessages = getTextForNotificationLabels(statusMessageIds, labels);
                    errorMessages = getTextForNotificationLabels(errorMessageIds, labels);
                    if (Array.isArray(errorMessages)) {
                        errorMessages.forEach(function (error) { return showNotification('error', error); });
                    }
                    else {
                        showNotification('error', errorMessages);
                    }
                    if (Array.isArray(notificationMessages)) {
                        notificationMessages.forEach(function (notification) { return showNotification('success', notification); });
                    }
                    else {
                        showNotification('error', notificationMessages);
                    }
                    return [2 /*return*/];
            }
        });
    });
}
function extractAndRemoveParameterFromQueryString(parameterName) {
    var queryString = __assign({}, urlManager.queryString);
    var value = urlManager.queryStringArray[parameterName];
    delete queryString[parameterName];
    urlManager.replaceQueryString(queryString, { newHistoryEntry: false });
    return value;
}
function getTextForNotificationLabels(msgKeys, notificationMapping) {
    var result = [];
    if (!msgKeys) {
        return result;
    }
    var newarray = msgKeys[0].split(',');
    newarray.forEach(function (msgKey) {
        // In case that a custom message is needed for notification on page reload
        // this component will handle all variations for both error and success messages
        // type attribute will be used to distinguish the behavior that the message should have
        if (msgKey.indexOf('/') !== -1) {
            result.push(React.createElement(CustomMessage, { type: "retriggerEmail", message: msgKey }));
        }
        var message = notificationMapping[msgKey];
        if (!message) {
            // Show a message if the message key from the url can not be found
            // in the backend notification labels
            // without this log the error would be silently ignored
            // eslint-disable-next-line no-console
            console.error('Invalid key', msgKeys);
            return;
        }
        result.push(message);
    });
    return result;
}
/*
    This map tracks all timers to throttle error messages
    The key is the error notification text
    The value is the timer to allow further messages of the given type
*/
var errorHistory = new Map();
var fallbackLanguage = 'de';
var defaultMessages = {
    de: 'Ein technischer Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
    en: 'An internal technical error has occurred. Please try again later.',
};
function handleAjaxErrors() {
    onUnhandledRequestRegistyError(function (error) {
        var status = error.response.status;
        if (Number(status) === 401) {
            waitForLogin();
            return true;
        }
        var defaultMessage = defaultMessages[getUserLanguage()] || defaultMessages[fallbackLanguage];
        var responseContent = error.responseContent;
        var errorNotification = (responseContent && responseContent.GlobalMessage) || defaultMessage;
        var guid = responseContent && responseContent.Guid;
        // Block errors with the same notification for 3000ms
        var wasErrorShownTimer = errorHistory.get(errorNotification);
        clearTimeout(wasErrorShownTimer);
        errorHistory.set(errorNotification, window.setTimeout(function () {
            errorHistory.delete(errorNotification);
        }, 3000));
        if (wasErrorShownTimer) {
            return;
        }
        showErrorNotification(React.createElement("div", null,
            React.createElement(Typography, { size: "m", tag: "span", responsive: false }, errorNotification),
            React.createElement(Typography, { size: "s", tag: "span", responsive: false },
                "\u00A0(",
                guid ? guid + " - " : '',
                status,
                ")")));
    });
}
