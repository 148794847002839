import { toQueryString } from '@jelmoli/querystring';
import { createGetEndpoint } from 'request-registry';
export var ShippingMethodConstantMap = {
    nextDay: 'e50832c3-929e-43b3-9b9b-42704f71c249',
    sameDay: '9b3c1129-09bd-49ea-85d9-c7ce31e734ce',
    standard: 'cf0af82a-e1b8-45c2-91db-7b9847af287c',
    installationService: '59ed0d29-969f-444a-af1e-44740ecc8407',
    bulky: 'aca23514-c145-48e3-b0ae-709c8bc9af56',
    sameDaySwitzerland: 'b4d6cd1d-17e1-48a0-8392-0857cb506ac3',
    sameDayCanton: '3d3b30ee-772a-4f23-b1ed-c39dfc63c45',
};
export var ShippingMethodConstants = ShippingMethodConstantMap;
/**
 * https://wiki.namics.com/display/JELMOLI2025/205-01-06+Checkout+Interfaces#id-205-01-06CheckoutInterfaces-GetShippingMethods
 */
export var getShippingMethods = createGetEndpoint({
    url: function (_a) {
        var zipCode = _a.zipCode;
        return "/jelmoli-api/JelmoliCheckout/GetShippingMethods?" + toQueryString({ shippingPostalCode: zipCode });
    },
});
