import { createGetEndpoint } from 'request-registry';
import { getCart } from '../Cart/getCart/getCart';
import { getCheckoutPaymentMethods } from './getCheckoutPaymentMethods/getCheckoutPaymentMethods';
export var getCheckoutProgress = createGetEndpoint({
    url: function () { return "/jelmoli-api/JelmoliCheckout/CheckoutProgress"; },
    afterSuccess: function (result) {
        var invalidationPromises = [];
        // If the cart is invalid a cleanup might have invalidated the cart
        if (!result.steps.cart.isValid) {
            invalidationPromises.push(getCart.refresh());
        }
        // If the paymentMethod is invalid because of the changes made in the cart
        if (!result.steps.payment.isValid) {
            invalidationPromises.push(getCheckoutPaymentMethods.refresh());
        }
        return invalidationPromises;
    },
});
