import { Column, Row } from '@jelmoli/bootstrap-grid';
import { bindTranslations, I18N } from '@jelmoli/i18n';
import { getOrdersLabels } from '@jelmoli/sitecore-services/src/Account/getOrdersLabels/getOrdersLabels';
import classnames from 'classnames';
import React, { useMemo } from 'react';
import { useGetEndPoint } from 'request-registry-react';
import { Badge } from '../../atoms/Badge/Badge';
import { Image } from '../../atoms/Image/Image';
import { TextSkewBackgroundHighlight } from '../../atoms/TextSkewBackgroundHighlight/TextSkewBackgroundHighlight';
import { A } from '../../helpers/A/A';
import { hoverAnchorClassName } from '../../helpers/HoverAnchor/HoverAnchor';
import { Typography } from '../../helpers/Typography/Typography';
import { VerticalSpacer } from '../../helpers/VerticalSpacer/VerticalSpacer';
import './order-item.scss';
export var OrderItem = function (_a) {
    var data = _a.data, onClick = _a.onClick;
    var orderLabels = useGetEndPoint(getOrdersLabels).value;
    var t = bindTranslations(orderLabels);
    var isOfflineOrder = data.isOffline;
    var isReservation = data.variant.length === 1 && data.variant[0] === 'reservation';
    var isOrderCancelled = data.variant.length === 1 && (data.variant[0] === 'cancelled' || data.variant[0] === 'returned');
    var isOrderPartial = data.variant[0] === 'partially-cancelled' || data.variant[0] === 'partially-returned';
    return (React.createElement(A, { className: classnames('o-order-item', hoverAnchorClassName, {
            'o-order-item--cancelled': isOrderCancelled && data.OrderStatus !== 'Shipped',
        }), onClick: onClick },
        React.createElement(Row, null,
            React.createElement(Column, null,
                React.createElement("span", { className: "o-order-item__status" },
                    React.createElement(Typography, { size: "l", type: "medium" }, isOfflineOrder ? (t('OfflineOrderTitle')) : isReservation ? (React.createElement(ReservationTitle, { reservedUntilDays: data.reservedUntilDays, status: data.OrderStatus })) : (OrderTitle(data.OrderStatus, data.variant)))))),
        React.createElement(Row, null,
            React.createElement(Column, { size: { xs: 6, sm: 8, md: 9 } },
                React.createElement(Row, null,
                    React.createElement(Column, { size: 12 },
                        data.ArticalNumber > 0 && (React.createElement(React.Fragment, null,
                            React.createElement(VerticalSpacer, { size: "xs" }),
                            React.createElement(Typography, { size: "xxs", type: "light", uppercase: true }, t('OrderArticle', { number: data.ArticalNumber })))),
                        React.createElement(VerticalSpacer, { size: "mx" })),
                    React.createElement(Column, { size: 12 },
                        React.createElement("div", { className: "o-order-item__details-wrapper" },
                            React.createElement("div", { className: "o-order-item__detail" },
                                React.createElement("span", { className: "o-order-item__section-title" },
                                    React.createElement(Typography, { size: "xxs", type: "medium", uppercase: true },
                                        isOfflineOrder && t('PurchaseDateLabel'),
                                        isReservation && t('ReservationDate'),
                                        !isOfflineOrder && !isReservation && t('OrderDate'))),
                                React.createElement(Typography, { size: "m" }, data.Date),
                                React.createElement(VerticalSpacer, { size: "xs", hide: ['xl'] })),
                            data.Price && !isReservation && (React.createElement("div", { className: "o-order-item__detail" },
                                React.createElement("span", { className: "o-order-item__section-title" },
                                    React.createElement(Typography, { size: "xxs", type: "medium", uppercase: true }, t('OrderPrice'))),
                                React.createElement(Typography, { size: "l" }, data.Price),
                                React.createElement(VerticalSpacer, { size: "xs", hide: ['xl'] }))),
                            React.createElement("div", { className: "o-order-item__detail" },
                                React.createElement("span", { className: "o-order-item__section-title" },
                                    React.createElement(Typography, { size: "xxs", type: "medium", uppercase: true }, t('OrderNumber'))),
                                React.createElement(Typography, { size: "m" }, data.OrderNumber))),
                        (isOrderCancelled || isOrderPartial) && data.OrderStatus !== 'Shipped' && (React.createElement(React.Fragment, null,
                            React.createElement(VerticalSpacer, { size: "mx" }),
                            data.variant.map(function (variant, index) { return (React.createElement(React.Fragment, { key: variant },
                                React.createElement(StatusLabelWithBadge, { variant: variant }),
                                index !== data.variant.length - 1 && React.createElement(VerticalSpacer, { size: "xs" }))); })))),
                    React.createElement(VerticalSpacer, { size: "xl" }))),
            React.createElement(Column, { size: { xs: 6, sm: 4, md: 3 }, horizontalAlignment: "right" },
                React.createElement(VerticalSpacer, { size: { xs: 'xxl', sm: 'xxl', md: 'xxl', lg: 'xxl', xl: 'mx' } }),
                React.createElement("div", { className: classnames('o-order-item__image-wrapper', { 'o-order-item__image-wrapper--single': data.ArticalNumber === 2 }, { 'o-order-item__image-wrapper--double': data.ArticalNumber === 3 }, { ' o-order-item__image-wrapper--tripple': data.ArticalNumber > 3 }) }, data.OrderImage ? (React.createElement(Image, { value: { src: data.OrderImage, alt: data.OrderNumber } })) : (React.createElement("div", { className: "o-order-item__image-wrapper--offline-order" }))),
                React.createElement(VerticalSpacer, { size: "xl" })))));
};
export var StatusLabelWithBadge = function (_a) {
    var variant = _a.variant;
    var orderLabels = useGetEndPoint(getOrdersLabels).value;
    var t = bindTranslations(orderLabels);
    var label = useMemo(function () {
        switch (variant) {
            case 'cancelled':
                return t('ItemCancelledLabel');
            case 'partially-cancelled':
                return t('ItemPartiallyCancelledLabel');
            case 'returned':
                return t('ItemReturnedLabel');
            case 'partially-returned':
                return t('ItemPartiallyReturnedLabel');
            default:
                return t('ItemCancelledLabel');
        }
    }, [variant, t]);
    return (React.createElement("div", null,
        React.createElement(Badge, { color: "functional-red", size: 6 }),
        React.createElement("span", { className: "a-order-item__label" },
            React.createElement(Typography, { size: "xs", type: "medium", responsive: false }, label))));
};
export var ReservationTitle = function (_a) {
    var reservedUntilDays = _a.reservedUntilDays, status = _a.status;
    var orderLabels = useGetEndPoint(getOrdersLabels).value;
    var t = bindTranslations(orderLabels);
    var _b = useMemo(function () {
        if (status === 'Pending' && orderLabels) {
            return { label: orderLabels.ReservationTitleList.value.Pending };
        }
        if (status === 'PickedUp' && orderLabels) {
            return { label: orderLabels.ReservationTitleList.value.PickedUp };
        }
        if (status === 'Cancelled' && orderLabels) {
            return { label: orderLabels.ReservationTitleList.value.Cancelled };
        }
        if (status === 'Expired' && orderLabels) {
            return { label: t('ReservationExpiredTitle'), days: t('ExpiredLabel') };
        }
        if (!reservedUntilDays && orderLabels) {
            return { label: orderLabels.ReservationTitleList.value.Pending };
        }
        switch (true) {
            case reservedUntilDays > 1:
                return { label: t('ReservationActiveTitle'), days: t('DaysLabel') };
            case reservedUntilDays === 1:
                return { label: t('ReservationActiveTitle'), days: t('DayLabel') };
            case reservedUntilDays < 0:
                return { label: t('ReservationExpiredTitle'), days: t('ExpiredLabel') };
            case reservedUntilDays === 0:
                return { label: t('ReservationActiveTitle'), days: t('TodayLabel') };
            default:
                return { label: t('ReservationActiveTitle'), days: t('DaysLabel') };
        }
    }, [reservedUntilDays, status, orderLabels]), label = _b.label, days = _b.days;
    return (React.createElement(React.Fragment, null,
        React.createElement(I18N, { text: label, replacements: {
                status: React.createElement(TextSkewBackgroundHighlight, { color: "functional-red" }, days),
                daysStatus: (React.createElement(React.Fragment, null,
                    reservedUntilDays > 0 && (React.createElement(TextSkewBackgroundHighlight, null, reservedUntilDays)),
                    React.createElement(TextSkewBackgroundHighlight, null, days))),
            } })));
};
var OrderTitle = function (status, type) {
    var orderLabels = useGetEndPoint(getOrdersLabels).value;
    var t = bindTranslations(orderLabels ? orderLabels.OrderTitleList.value : orderLabels);
    var label = useMemo(function () {
        switch (type[0]) {
            case 'cancelled':
                return t('Cancelled');
            case 'partially-cancelled':
                return t('Shipped');
            case 'returned':
                return t('Returned');
            case 'partially-returned':
                return t('Shipped');
            case 'order':
                return t(status);
            default:
                return t('Shipped');
        }
    }, [type[0], t]);
    return label;
};
