import { useEffect, useState } from 'react';
/**
 * This hook returns the current path in the url
 */
export function usePath() {
    var _a = useState(typeof window !== 'undefined' ? window.location.pathname : ''), path = _a[0], setPath = _a[1];
    var listenToPopstate = function () {
        var winPath = window.location.pathname;
        setPath(winPath);
    };
    useEffect(function () {
        if (typeof window === 'undefined') {
            return;
        }
        window.addEventListener('popstate', listenToPopstate);
        return function () {
            window.removeEventListener('popstate', listenToPopstate);
        };
    }, []);
    return path;
}
