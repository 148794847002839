var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Image } from '@jelmoli/ui-components/src/atoms/Image/Image';
import { ProductFulfillmentData } from '@jelmoli/ui-components/src/atoms/ProductItem/ProductItem';
import { Separator } from '@jelmoli/ui-components/src/atoms/Separator/Separator';
import { HorizontalSpacer } from '@jelmoli/ui-components/src/helpers/HorizontalSpacer/HorizontalSpacer';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import React from 'react';
export var ConfirmationComponentSummaryBox = function (props) {
    var hasAnyProductInstallationService = props.order.lines.some(function (line) {
        return (line.fulfillmentFlags && line.fulfillmentFlags.installationServiceMandatory) ||
            (line.fulfillmentFlags && line.fulfillmentFlags.installationServiceSelected);
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "feat-confirmation__summary-box" },
            React.createElement(VerticalSpacer, { size: "m", responsive: false }),
            props.order.lines.map(function (product) { return (React.createElement(React.Fragment, { key: product.cartLineId },
                React.createElement(ProductItem, __assign({}, product, { CheckoutSummaryFragileLabel: props.CheckoutSummaryFragileLabel, CheckoutSummaryBulkyLabel: props.CheckoutSummaryBulkyLabel, CheckoutSummaryInstallationServiceSelectedLabel: props.CheckoutSummaryInstallationServiceSelectedLabel, CheckoutCustomMeasure: props.CheckoutCustomMeasure, CheckoutZyseMeLabel: props.CheckoutZyseMeLabel })),
                React.createElement(VerticalSpacer, { size: "m", responsive: false }))); }),
            React.createElement(Separator, { shade: "two" }),
            props.order.giftWrapping && (React.createElement(React.Fragment, null,
                React.createElement(VerticalSpacer, { size: "m", responsive: false }),
                React.createElement("div", { className: "feat-confirmation__total" },
                    React.createElement(Typography, { size: "s", responsive: false, wrap: "ellipsis" }, props.order.giftWrapping.greetingMessage
                        ? props.GiftSummaryWithTextLabel
                        : props.GiftSummaryNoTextLabel),
                    React.createElement("div", { className: "feat-confirmation__price-wrapper" },
                        React.createElement(Typography, { size: "s", type: "medium", responsive: false }, props.order.giftWrapping.giftWrappingTotal))),
                React.createElement(VerticalSpacer, { size: "m", responsive: false }),
                React.createElement(Separator, { shade: "two" }))),
            !props.order.giftWrapping && (React.createElement(React.Fragment, null,
                React.createElement(VerticalSpacer, { size: "m", responsive: false }),
                React.createElement("div", { className: "feat-confirmation__total" },
                    React.createElement(Typography, { size: "s", responsive: false, wrap: "ellipsis" }, props.GiftSummaryEmptyLabel)),
                React.createElement(VerticalSpacer, { size: "m", responsive: false }),
                React.createElement(Separator, { shade: "two" }))),
            props.order.discounts.length > 0 && (React.createElement(React.Fragment, null,
                React.createElement(VerticalSpacer, { size: "m", responsive: false }),
                props.order.discounts.map(function (discount) { return (React.createElement("div", { className: "feat-myaccount-order-detail__text-alignement", key: discount.label },
                    React.createElement(Typography, { size: "s", responsive: false }, discount.label),
                    React.createElement(Typography, { size: "xs", type: "medium", responsive: false }, discount.discountTotal))); }),
                React.createElement(VerticalSpacer, { size: "m", responsive: false }),
                React.createElement(Separator, { shade: "two" }))),
            props.order.vouchers.length > 0 && (React.createElement(React.Fragment, null,
                React.createElement(VerticalSpacer, { size: "m", responsive: false }),
                React.createElement("div", { className: "feat-myaccount-order-detail__text-alignement" },
                    React.createElement(Typography, { size: "s", responsive: false }, props.order.vouchers[0].label)),
                React.createElement(VerticalSpacer, { size: "m", responsive: false }),
                React.createElement(Separator, { shade: "two" }))),
            (props.order.packagingTotal ||
                props.order.shippingTotal ||
                props.order.fragileTotal ||
                hasAnyProductInstallationService) && (React.createElement(React.Fragment, null,
                React.createElement(VerticalSpacer, { size: "m", responsive: false }),
                props.order.shippingTotal && !hasAnyProductInstallationService && (React.createElement("div", { className: "feat-confirmation__total" },
                    React.createElement(Typography, { tag: "p", size: "s", responsive: false, wrap: "ellipsis" }, props.CheckoutSummaryShippingLabel),
                    React.createElement(Typography, { tag: "p", size: "s", type: "medium", responsive: false }, props.order.shippingTotal))),
                hasAnyProductInstallationService && (React.createElement("div", { className: "feat-confirmation__total" },
                    React.createElement(Typography, { tag: "p", size: "s", responsive: false, wrap: "ellipsis" }, props.CheckoutSummaryInstallationServiceLabel),
                    React.createElement(Typography, { tag: "p", size: "s", type: "medium", responsive: false }, props.order.shippingTotal))),
                ((props.order.packagingTotal && props.order.shippingTotal) ||
                    (props.order.fragileTotal && hasAnyProductInstallationService)) && (React.createElement(VerticalSpacer, { size: "xs", responsive: false })),
                props.order.packagingTotal && !props.order.fragileTotal && (React.createElement("div", { className: "feat-confirmation__total" },
                    React.createElement(Typography, { tag: "p", size: "s", responsive: false, wrap: "ellipsis" }, props.CheckoutSummaryPackingLabel),
                    React.createElement(Typography, { tag: "p", size: "s", type: "medium", responsive: false }, props.order.packagingTotal))),
                props.order.fragileTotal && (React.createElement("div", { className: "feat-confirmation__total" },
                    React.createElement(Typography, { tag: "p", size: "s", responsive: false, wrap: "ellipsis" }, props.CheckoutSummaryFragilePackingLabel),
                    React.createElement(Typography, { tag: "p", size: "s", type: "medium", responsive: false }, props.order.fragileTotal))))),
            React.createElement(VerticalSpacer, { size: "m", responsive: false }),
            props.order.giftCardPayment && (React.createElement(React.Fragment, null,
                React.createElement(Separator, { shade: "two" }),
                React.createElement(VerticalSpacer, { size: "m", responsive: false }),
                React.createElement("div", { className: "feat-confirmation__total" },
                    React.createElement(Typography, { tag: "p", size: "s", responsive: false, wrap: "ellipsis" },
                        props.GiftCardSummaryLabel,
                        " ",
                        props.order.giftCardPayment.giftCardNumberMasked),
                    React.createElement(Typography, { tag: "p", size: "s", type: "medium", responsive: false }, props.order.giftCardPayment.giftCardValue)),
                React.createElement(VerticalSpacer, { size: "m", responsive: false }))),
            React.createElement(Separator, { shade: "one" }),
            React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
            React.createElement("div", { className: "feat-confirmation__total" },
                React.createElement(Typography, { size: "s", type: "medium", responsive: false, uppercase: true, wrap: "ellipsis" }, props.CheckoutSummaryTotalAmountLabel),
                React.createElement("div", { className: "feat-confirmation__price-wrapper" },
                    React.createElement(Typography, { size: "s", type: "medium", responsive: false }, props.order.total && props.order.total !== '0.00' ? props.order.total : 'kostenlos'))),
            React.createElement(VerticalSpacer, { size: "mx", responsive: false })),
        React.createElement(VerticalSpacer, { size: "mx", responsive: false })));
};
var ProductItem = function (props) {
    return (React.createElement("div", { className: "feat-confirmation__summary-box-item" },
        React.createElement("div", { className: "feat-confirmation__image-wrapper" }, props.imageUrl ? (React.createElement(Image, { value: { src: props.imageUrl } })) : (React.createElement("div", { className: "feat-confirmation__image-offline" }))),
        React.createElement("div", { className: "feat-confirmation__text-wrapper" },
            React.createElement(Typography, { type: "medium", size: "xs", uppercase: true, responsive: false }, props.brandName),
            React.createElement(VerticalSpacer, { size: "xs", responsive: false }),
            React.createElement(Typography, { size: "xs", responsive: false }, props.displayName),
            React.createElement(VerticalSpacer, { size: "xxs", responsive: false }),
            React.createElement("div", null,
                React.createElement(Typography, { size: "xs", responsive: false },
                    props.size.label,
                    ":",
                    ' ',
                    props.typeOfGood === 'ZyseMe' ? props.CheckoutZyseMeLabel : props.size.value),
                React.createElement(HorizontalSpacer, { size: "xs", responsive: false }),
                React.createElement(Typography, { size: "xs", responsive: false },
                    props.color.label,
                    ": ",
                    props.color.value)),
            React.createElement(VerticalSpacer, { size: "xs", responsive: false }),
            React.createElement("div", { className: "feat-confirmation__total" },
                React.createElement(Typography, { size: "xs", responsive: false },
                    props.quantity,
                    "x"),
                React.createElement(Typography, { size: "xs", type: "medium", responsive: false }, props.lineTotal)),
            props.fulfillmentFlags && (React.createElement(React.Fragment, null,
                React.createElement(VerticalSpacer, { size: "xs", responsive: false }),
                React.createElement(ProductFulfillmentData, { fulfillmentFlags: props.fulfillmentFlags, fragileLabel: props.CheckoutSummaryFragileLabel, bulkyLabel: props.CheckoutSummaryBulkyLabel, installationServiceSelectedLabel: props.CheckoutSummaryInstallationServiceSelectedLabel, optionSaved: true, zyseMeLabel: props.CheckoutCustomMeasure, isZyseMeProduct: props.typeOfGood === 'ZyseMe' }))))));
};
