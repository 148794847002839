import classnames from 'classnames';
import React from 'react';
import { Typography } from '../../helpers/Typography/Typography';
import './header-button.scss';
export var HeaderButton = function (_a) {
    var Icon = _a.Icon, onClick = _a.onClick, title = _a.title, children = _a.children, activeIcon = _a.activeIcon;
    return (React.createElement("div", { className: "a-header-button", onClick: onClick },
        activeIcon ? activeIcon : React.createElement(Icon, { height: 20, width: 20, style: { display: 'flex' } }),
        React.createElement("span", { className: "a-header-button__children" }, children),
        title !== undefined && (React.createElement("span", { className: "a-header-button__title" },
            React.createElement(Typography, { type: "medium", size: "xxs", responsive: false, wrap: "nowrap" }, title)))));
};
export var HeaderButtonBadge = function (_a) {
    var children = _a.children;
    return (React.createElement("span", { className: classnames('a-header-button__badge', 'a-header-button__badge--functional-green', !children && 'a-header-button__badge--empty') },
        React.createElement(Typography, { type: "medium", size: "xxxs" }, typeof children === 'number' && children > 99 ? '99+' : children)));
};
export var HeaderButtonCheckMark = function () { return React.createElement("span", { className: "a-header-button__check-mark" }); };
