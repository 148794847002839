import { createGetEndpoint } from 'request-registry';
/**
 * This API retireves address from the database based on id
 * https://wiki.namics.com/pages/viewpage.action?spaceKey=JELMOLI2025&title=205-01-11+Product+Availability+Interfaces
 */
export var getProductAvailability = createGetEndpoint({
    url: function (_a) {
        var id = _a.id;
        return "/jelmoli-api/productavailability/get/" + id;
    },
});
