import { Column, Row } from '@jelmoli/bootstrap-grid';
import React from 'react';
import { Container } from '../../helpers/Container/Container';
import { Typography } from '../../helpers/Typography/Typography';
import { VerticalSpacer } from '../../helpers/VerticalSpacer/VerticalSpacer';
import './seo-text.scss';
export var SeoTextComponent = function (props) {
    var fields = props.fields;
    return (React.createElement(Container, { size: "m", backgroundFill: true },
        React.createElement(Row, { className: "a-seo-text-row" },
            React.createElement(Column, { size: 12 },
                React.createElement(Typography, { size: "xl", type: "medium", tag: "h2" },
                    React.createElement("span", null, fields.popSeoTitle))),
            React.createElement(VerticalSpacer, { size: "l" }),
            React.createElement(Column, { size: 12 },
                React.createElement(Typography, { size: "l", type: "regular", tag: "p" },
                    React.createElement("span", null, fields.popSeoText))),
            React.createElement(VerticalSpacer, { size: "l" }))));
};
