var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Link } from '@sitecore-jss/sitecore-jss-react';
import classnames from 'classnames';
import React from 'react';
import { A } from '../../helpers/A/A';
import { hoverAnchorClassName } from '../../helpers/HoverAnchor/HoverAnchor';
import { hasLinkFieldValue } from '../../helpers/SitecoreTypes/SitecoreTypes';
import './text-link.scss';
var defaultColor = 'outer-space';
export var TextLink = function (props) {
    var underlined = props.underlined, color = props.color, isButton = props.isButton, type = props.type, display = props.display, linkProps = __rest(props, ["underlined", "color", "isButton", "type", "display"]);
    var buttonProps = isButton ? { role: 'button', tabIndex: 0 } : {};
    var href = isButton ? undefined : linkProps.href;
    return (React.createElement(A, __assign({}, buttonProps, linkProps, { href: href, className: classnames("a-text-link", hoverAnchorClassName, "a-text-link--" + (color || defaultColor), {
            'a-text-link--underline': isUnderlined(underlined, href || isButton),
        }, type === 'notification' && "a-text-link--" + type, display && "a-text-link--" + display) }), props.children));
};
export var TextLinkEditable = function (props) {
    return (React.createElement(Link, __assign({}, (props.field.value.rel && { rel: props.field.value.rel }), { editable: props.editable, field: props.field, className: classnames("a-text-link", hoverAnchorClassName, "a-text-link--" + (props.color || defaultColor), {
            'a-text-link--underline': isUnderlined(props.underlined, hasLinkFieldValue(props.field)),
        }) }), props.children));
};
function isUnderlined(underline, href) {
    if (underline === void 0) { underline = 'auto'; }
    return Boolean((underline === 'auto' && href) || underline === true);
}
