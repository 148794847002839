import { Divider } from '@jelmoli/ui-components/src/atoms/Divider/Divider';
import { H1 } from '@jelmoli/ui-components/src/atoms/Headlines/Headlines';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import React from 'react';
export var BrandStageComponent = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement(H1, { size: "xl", type: "bold" }, props.fields.brandTitle),
        React.createElement(VerticalSpacer, { size: "l" }),
        props.fields.brandDescription.length > 0 && (React.createElement(React.Fragment, null,
            React.createElement(Divider, { heightSize: "xxxxs", widthSize: "xs" }),
            React.createElement(VerticalSpacer, { size: "m" }),
            React.createElement(Typography, { size: "s", type: "light", tag: "p" }, props.fields.brandDescription)))));
};
