import { Dropdown } from '@jelmoli/ui-components/src/atoms/Dropdown/Dropdown';
import { NumberInput } from '@jelmoli/ui-components/src/atoms/NumberInput/NumberInput';
import { PDPDropdownItem } from '@jelmoli/ui-components/src/atoms/PDPDropdownItem/PDPDropdownItem';
import { HorizontalSpacer } from '@jelmoli/ui-components/src/helpers/HorizontalSpacer/HorizontalSpacer';
import React from 'react';
/** Size Picker for PDP page contains dropdown with all sizes and as quantity selector */
export var ECommerceBoxSizePicker = function (_a) {
    var sizeIndex = _a.sizeIndex, sizeSwitch = _a.sizeSwitch, selectedSizeType = _a.selectedSizeType, setCurrentSelectedSizeType = _a.setCurrentSelectedSizeType, availableLabel = _a.availableLabel, sizeLabel = _a.sizeLabel, quantityValue = _a.quantityValue, updateQuantityValue = _a.updateQuantityValue, availability = _a.availability;
    var selectedSize = sizeIndex > -1 && sizeSwitch ? sizeSwitch[sizeIndex].size : undefined;
    var maximum = sizeIndex > -1 && sizeSwitch ? sizeSwitch[sizeIndex].availability : Infinity;
    return (React.createElement("div", { className: "o-ecommerce-box__size-wrapper" },
        sizeSwitch && sizeSwitch.length > 0 && sizeSwitch[0].size !== 'One Size' && (React.createElement(React.Fragment, null,
            React.createElement(Dropdown, { noDropdownArrow: sizeSwitch.length === 1, label: sizeLabel, value: selectedSize || '', disableAutoFocusItem: true, onChange: function (event) {
                    var selectedValue = event.target.value;
                    var index = sizeSwitch.findIndex(function (size) { return size.size === selectedValue; });
                    var newSizeSwitch = sizeSwitch[index];
                    if (setCurrentSelectedSizeType) {
                        setCurrentSelectedSizeType(newSizeSwitch.type);
                    }
                    var newSizeUrl = newSizeSwitch.url;
                    if (newSizeUrl) {
                        document.location.href = newSizeUrl;
                    }
                }, type: "fullwidth" }, sizeSwitch.map(function (size, index) { return (React.createElement(PDPDropdownItem, { shirtsize: size.size, vendorsize: size.statisticSize, availability: availableLabel(size.availability), outOfStock: size.availability === 0, value: size.size, key: index, disabled: size.availability === 0 }, size.size)); })),
            React.createElement("div", null,
                React.createElement(HorizontalSpacer, { size: "xxs" })))),
        React.createElement("div", { className: "o-ecommerce-box__number-input-wrapper" },
            React.createElement(NumberInput, { min: 1, max: maximum, value: quantityValue, disabled: availability === 0, onChange: function (quantity) { return updateQuantityValue(quantity); }, disableDecrease: quantityValue === 1, disableIncrease: quantityValue === maximum }))));
};
