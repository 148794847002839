var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useMiniFormik } from '@jelmoli/hooks/src/useMiniFormik/useMiniFormik';
import { bindTranslations } from '@jelmoli/i18n';
import { getCart } from '@jelmoli/sitecore-services/src/Cart/getCart/getCart';
import { getCheckoutLabels } from '@jelmoli/sitecore-services/src/Checkout/getCheckoutLabels/getCheckoutLabels';
import { CheckoutPaymentMethodConstants, getCheckoutPaymentMethods, } from '@jelmoli/sitecore-services/src/Checkout/getCheckoutPaymentMethods/getCheckoutPaymentMethods';
import { getCheckoutProgress } from '@jelmoli/sitecore-services/src/Checkout/getCheckoutProgress';
import { setCheckoutPaymentMethod, } from '@jelmoli/sitecore-services/src/Checkout/setCheckoutPaymentMethod/setCheckoutPaymentMethod';
import { trackError } from '@jelmoli/sitecore-services/src/Tracking/Error/ErrorTesting';
import { CheckboxLabelWithLink, CheckboxValidatable, CheckboxWithFormFieldSetting, } from '@jelmoli/ui-components/src/atoms/CheckboxValidatable/CheckboxValidatable';
import { FormFieldErrorMessage } from '@jelmoli/ui-components/src/atoms/FormFieldErrorMessage/FormFieldErrorMessage';
import { showErrorNotification } from '@jelmoli/ui-components/src/atoms/Notification/Notification';
import { PrimarySubmitButton } from '@jelmoli/ui-components/src/atoms/PrimarySubmitButton/PrimarySubmitButton';
import { RadioButtons } from '@jelmoli/ui-components/src/atoms/RadioButtons/RadioButtons';
import { RadioButtonsValidable } from '@jelmoli/ui-components/src/atoms/RadioButtonsValidatable/RadioButtonsValidatable';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { FormValidationContext } from 'nested-form-validation';
import React, { useEffect } from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
import './checkout-step-payment-method.scss';
export var CheckoutStepPaymentMethod = function (props) {
    var formData = useMiniFormik({
        initialValues: {
            paymentMethod: 'd97ba4e0-de4f-42eb-a3e7-471fca324a1a',
            termsAndConditions: false,
            privacyPolicy: false,
            ageConfirmation: false,
        },
    });
    var stepProgress = useGetEndPointLazy(getCheckoutProgress).value;
    var paymentMethods = useGetEndPointLazy(getCheckoutPaymentMethods).value;
    var cart = useGetEndPointLazy(getCart).value;
    var checkoutLabels = useGetEndPointLazy(getCheckoutLabels).value;
    var requireAgeConfirmation = stepProgress ? stepProgress.showAgeVerification : false;
    useEffect(function () {
        if (!cart || !cart.paymentMethod || !cart.paymentMethod.paymentMethodExternalId) {
            return;
        }
        formData.setValues({
            paymentMethod: cart.paymentMethod.paymentMethodExternalId,
            termsAndConditions: cart.termsAndConditionsAccepted,
            privacyPolicy: cart.privacyPolicyAccepted,
            ageConfirmation: cart.ageConfirmed,
        });
    }, [cart]);
    if (!paymentMethods || !cart || !checkoutLabels) {
        return null;
    }
    var paymentMethodsToShow = paymentMethods.filter(function (paymentMethod) { return paymentMethod.available; });
    var isGiftcardPaymentMethod = paymentMethods.some(function (paymentMethod) {
        return CheckoutPaymentMethodConstants[paymentMethod.externalId] === 'giftcard' && paymentMethod.available;
    });
    var t = bindTranslations(checkoutLabels);
    var onSaveAction = function () { return __awaiter(void 0, void 0, void 0, function () {
        var paymentObject;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    paymentObject = paymentMethods.find(function (paymentMethod) { return paymentMethod.externalId === formData.values.paymentMethod; });
                    if (!paymentObject) return [3 /*break*/, 2];
                    return [4 /*yield*/, setCheckoutPaymentMethod({}, {
                            paymentMethodExternalId: isGiftcardPaymentMethod
                                ? paymentMethodsToShow[0].externalId
                                : paymentObject.externalId,
                            ageConfirmationRequired: formData.values.ageConfirmation,
                            userAgreement: formData.values.termsAndConditions,
                            privacyPolicy: formData.values.privacyPolicy,
                        })
                            .then(function () {
                            props.onComplete();
                        })
                            .catch(function (response) {
                            trackError({
                                page: 'Error occured while saving the payment method on Checkout page',
                                errorMessage: 'Ups, the payment cannot be saved',
                            });
                            showErrorNotification(response.GlobalMessage);
                        })];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(FormValidationContext, null,
        React.createElement(Typography, { type: "medium", responsive: false, size: "xs" }, t('CheckoutPaymentOptionsLabel')),
        React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
        !isGiftcardPaymentMethod && (React.createElement(React.Fragment, null,
            React.createElement(RadioButtonsValidable, __assign({ name: "payment-option", errormsg: "Please select a payment method!" }, formData.fieldProps.paymentMethod), paymentMethodsToShow
                .map(function (paymentMethod) {
                if (CheckoutPaymentMethodConstants[paymentMethod.externalId] === 'applepay') {
                    return __assign(__assign({}, paymentMethod), { showPayment: checkIsApplePayAvailable() });
                }
                return __assign(__assign({}, paymentMethod), { showPayment: true });
            })
                .map(function (paymentMethod) { return (React.createElement(React.Fragment, { key: paymentMethod.externalId }, paymentMethod.showPayment && (React.createElement(RadioButtons, { value: paymentMethod.externalId },
                React.createElement("div", { className: "feat-checkout-step-payment-option--wrapper" },
                    React.createElement(CheckoutPaymentOptionIcon, { icon: CheckoutPaymentMethodConstants[paymentMethod.externalId] }),
                    ' ',
                    React.createElement("span", { className: "feat-checkout-step-payment-option__text" }, paymentMethod.name)))))); })),
            React.createElement(FormFieldErrorMessage, { name: "payment-option", verticalSpaceTop: "xxs" }))),
        isGiftcardPaymentMethod &&
            paymentMethodsToShow.map(function (paymentMethod) { return (React.createElement(Typography, { size: "s", responsive: false, key: paymentMethod.name }, paymentMethod.name)); }),
        React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
        React.createElement(CheckboxWithFormFieldSetting, __assign({ name: "privacy", formSetting: checkoutLabels.PrivacyPolicy, label: React.createElement(CheckboxLabelWithLink, { field: checkoutLabels.PrivacyPolicy, link: checkoutLabels.PrivacyPolicyLink }) }, formData.fieldProps.privacyPolicy)),
        React.createElement(FormFieldErrorMessage, { name: "privacy", verticalSpaceTop: "xxs" }),
        React.createElement(VerticalSpacer, { size: "xs", responsive: false }),
        React.createElement(CheckboxWithFormFieldSetting, __assign({ name: "terms", formSetting: checkoutLabels.TermsAndConditions, label: React.createElement(CheckboxLabelWithLink, { field: checkoutLabels.TermsAndConditions, link: checkoutLabels.TermsAndConditionsLink }) }, formData.fieldProps.termsAndConditions)),
        React.createElement(FormFieldErrorMessage, { name: "terms", verticalSpaceTop: "xxs" }),
        requireAgeConfirmation && (React.createElement(React.Fragment, null,
            React.createElement(VerticalSpacer, { size: "xs", responsive: false }),
            React.createElement(CheckboxValidatable, __assign({ name: "ageConfirmation", label: t('CheckoutOvereighteenLabel') }, formData.fieldProps.ageConfirmation, (requireAgeConfirmation && { errormsg: t('CheckoutOvereighteenError') }))),
            React.createElement(FormFieldErrorMessage, { name: "ageConfirmation", verticalSpaceTop: "xxs" }))),
        React.createElement(VerticalSpacer, { size: "xl", responsive: false }),
        React.createElement(PrimarySubmitButton, { type: "button", text: t('CheckoutStepperPaymentStepCtaButtonText'), onClick: onSaveAction }),
        React.createElement(VerticalSpacer, { size: "mx", responsive: false })));
};
var CheckoutPaymentOptionIcon = function (_a) {
    var icon = _a.icon;
    return (React.createElement("span", { className: "feat-checkout-step-payment-options__icon feat-checkout-step-payment-options__icon--" + icon }));
};
/** This function checks if browser is supporting the Apple Pay option
 *  https://developer.apple.com/documentation/apple_pay_on_the_web/applepaysession
 */
export function checkIsApplePayAvailable() {
    return 'ApplePaySession' in window;
}
