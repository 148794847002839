import { useEffect, useRef } from 'react';
/**
 * This hook triggers an action based on the fact if the click was triggered in the area that's defined
 *
 */
export var useClickOutsideOfElement = function (afterAction) {
    var ref = useRef(null);
    useEffect(function () {
        /**
         * This function creates a path from the passed element to the highest order element
         */
        var createPath = function (event) {
            var createdPath = [];
            var currentElement = event.target;
            while (currentElement) {
                createdPath.push(currentElement);
                currentElement = currentElement.parentElement;
            }
            return createdPath;
        };
        /**
         * This function checkes if the element that si refferenced is in the path built from the
         * element on which the click action was triggered
         */
        var onClickHandler = function (event) {
            var path = createPath(event);
            var insideClickedArea = path.some(function (currentElement) { return currentElement === ref.current; });
            if (!insideClickedArea) {
                afterAction(path);
            }
        };
        document.documentElement.addEventListener('click', onClickHandler);
        return function () {
            document.documentElement.removeEventListener('click', onClickHandler);
        };
    }, []);
    return ref;
};
