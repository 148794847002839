var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useMiniFormik } from '@jelmoli/hooks/src/useMiniFormik/useMiniFormik';
import { bindTranslations } from '@jelmoli/i18n';
import { createAccount } from '@jelmoli/sitecore-services/src/Account/createAccount/createAccount';
import { blacklist, getCaptchaToken } from '@jelmoli/sitecore-services/src/Security/getBlacklistApis/getBlacklistApis';
import { trackRegistration } from '@jelmoli/sitecore-services/src/Tracking/Account/AccountTracking';
import { CheckboxLabelWithLink, CheckboxWithFormFieldSetting, } from '@jelmoli/ui-components/src/atoms/CheckboxValidatable/CheckboxValidatable';
import { FormFieldErrorMessage } from '@jelmoli/ui-components/src/atoms/FormFieldErrorMessage/FormFieldErrorMessage';
import { showErrorNotification } from '@jelmoli/ui-components/src/atoms/Notification/Notification';
import { PrimarySubmitButton } from '@jelmoli/ui-components/src/atoms/PrimarySubmitButton/PrimarySubmitButton';
import { TextLink } from '@jelmoli/ui-components/src/atoms/TextLink/TextLink';
import { hasLinkFieldValue } from '@jelmoli/ui-components/src/helpers/SitecoreTypes/SitecoreTypes';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { Visibility } from '@jelmoli/ui-components/src/helpers/Visibility/Visibility';
import { RegistrationForm } from '@jelmoli/ui-components/src/organism/RegistrationForm/RegistrationForm';
import { observer } from 'mobx-react-lite';
import { FormValidationContext } from 'nested-form-validation';
import React from 'react';
export var RegistrationFirstStep = observer(function (_a) {
    var fields = _a.fields;
    var form = useMiniFormik({
        initialValues: {
            salutation: '',
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            newsletter: true,
            userAgreement: false,
            privacyPolicy: false,
        },
    });
    var t = bindTranslations(fields);
    return (React.createElement(React.Fragment, null,
        React.createElement(Typography, { size: "xl", type: "medium", tag: "h2", field: fields.RegistrationTitle }),
        React.createElement(VerticalSpacer, { size: "m" }),
        React.createElement(Typography, { size: "l", responsive: false, field: fields.RegistrationIntroductionText }),
        React.createElement(VerticalSpacer, { size: "l" }),
        React.createElement(FormValidationContext, null,
            React.createElement(RegistrationForm, { fields: fields, fieldValues: form.fieldProps }),
            React.createElement(CheckboxWithFormFieldSetting, __assign({ name: "Newsletter", formSetting: fields.Newsletter }, form.fieldProps.newsletter)),
            React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
            React.createElement("div", { className: "feat-registration__checkbox" },
                React.createElement(CheckboxWithFormFieldSetting, __assign({ formSetting: fields.PrivacyPolicy, label: React.createElement(CheckboxLabelWithLink, { field: fields.PrivacyPolicy, link: fields.PrivacyPolicyLink }), name: "privacy" }, form.fieldProps.privacyPolicy))),
            React.createElement(FormFieldErrorMessage, { name: "privacy", verticalSpaceTop: "xxs" }),
            React.createElement(VerticalSpacer, { size: "xl", responsive: false }),
            React.createElement(Visibility, { show: ['xs', 'sm'] },
                React.createElement(PrimarySubmitButton, { text: t('RegistrationSaveButtonLabel'), uppercase: true, fontResponsive: false, fullWidth: true, scrollPosition: "center", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, createUser(form)];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); } })),
            React.createElement(Visibility, { hide: ['xs', 'sm'] },
                React.createElement(PrimarySubmitButton, { text: t('RegistrationSaveButtonLabel'), uppercase: true, scrollPosition: "center", fontResponsive: false, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, createUser(form)];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); } }))),
        hasLinkFieldValue(fields.RegistrationLoginLink) && (React.createElement(React.Fragment, null,
            React.createElement(VerticalSpacer, { size: "s", responsive: false }),
            React.createElement(Typography, { size: "s", type: "regular", responsive: false },
                React.createElement(TextLink, __assign({ "aria-label": "login" }, fields.RegistrationLoginLink.value), t('RegistrationLoginLinkLabel')))))));
});
/**
 * If recaptcha is not blacklisted for this feature send it as part of payload
 *  otherwise don't send it
 */
function createUser(form) {
    return __awaiter(this, void 0, void 0, function () {
        var _a, _b;
        var _this = this;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _a = createAccount;
                    _b = {};
                    return [4 /*yield*/, getCaptchaToken(blacklist.createUser)];
                case 1: return [4 /*yield*/, _a.apply(void 0, [(_b.token = _c.sent(), _b), __assign({}, form.values)])
                        .then(function () { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    trackRegistration({ success: true });
                                    return [4 /*yield*/, goToSecondStep()];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); })
                        .catch(function (response) {
                        trackRegistration({ success: false });
                        showErrorNotification(response.GlobalMessage);
                    })];
                case 2:
                    _c.sent();
                    return [2 /*return*/];
            }
        });
    });
}
function goToSecondStep() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            window.location.href = window.location.href.replace(/(\?|#).+$/, '') + "?step=step_2";
            return [2 /*return*/, new Promise(function () {
                    /** Never resolve this promise as we need to wait until the page is loaded */
                })];
        });
    });
}
