var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useIsVisibleInViewport } from '@jelmoli/hooks/src/useKeyboardEffect/useIsVisibleInViewport/useIsVisibleInViewport';
import classnames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { preventOutline } from '../../helpers/A/A';
import { HorizontalScrollWrapper } from '../../helpers/HorizontalScrollWrapper/HorizontalScrollWrapper';
import { HorizontalSpacer } from '../../helpers/HorizontalSpacer/HorizontalSpacer';
import { Typography } from '../../helpers/Typography/Typography';
import { VerticalSpacer } from '../../helpers/VerticalSpacer/VerticalSpacer';
import { ChevronLeftIcon } from '../Icon/ChevronLeftIcon';
import { ChevronRightIcon } from '../Icon/ChevronRightIcon';
import './pdp-color-picker.scss';
export var PDPColorPicker = function (props) {
    var _a;
    var newColorItemsArray = props.colorVariantIdArray.map(function (variantId, index) { return ({
        variantId: variantId,
        hexColor: props.hexColorArray[index],
        colorName: props.colorsNameArray[index],
        nos: props.nosValueArray[index],
        available: props.colorVariantAvailabilityArray[index],
    }); });
    newColorItemsArray = moveAtEnd(newColorItemsArray, 'tranp');
    newColorItemsArray = moveAtEnd(newColorItemsArray, 'multi');
    var colorRef = useRef(null);
    var leftOffset = (_a = colorRef.current) === null || _a === void 0 ? void 0 : _a.offsetLeft;
    var isVisibleInViewport = useIsVisibleInViewport(1, colorRef).isVisibleInViewport;
    useEffect(function () {
        if (isVisibleInViewport) {
            leftOffset = 0;
        }
    }, [isVisibleInViewport]);
    var colorName = props.colorSelectedIndex !== undefined
        ? props.colorsNameArray[props.colorSelectedIndex]
        : props.colorsNameArray[props.colorVariantIdArray.indexOf(props.colorVariantIdArray[props.preselectedId])];
    return (React.createElement(React.Fragment, null,
        !colorName ? (React.createElement("div", { className: "a-pdp-color-picker__no-color-name" })) : (React.createElement(Typography, { size: "xxs" }, colorName)),
        React.createElement(VerticalSpacer, { size: "xxs" }),
        React.createElement("div", { className: "a-pdp-color-picker__wrapper" },
            React.createElement(HorizontalScrollWrapper, __assign({ leftArrow: React.createElement("div", { className: "a-pdp-color-picker__arrow a-pdp-color-picker__arrow--left" },
                    React.createElement("div", { className: "a-pdp-color-icon-wrapper" },
                        React.createElement(ChevronLeftIcon, { height: 14 }))), rightArrow: React.createElement("div", { className: "a-pdp-color-picker__arrow a-pdp-color-picker__arrow--right" },
                    React.createElement("div", { className: "a-pdp-color-icon-wrapper" },
                        React.createElement(ChevronRightIcon, { height: 14 }))), fullWidth: true }, (leftOffset &&
                props.colorSelectedIndex === undefined && {
                scrollTo: leftOffset,
            })), newColorItemsArray.map(function (color) { return (React.createElement("div", { className: "a-pdp-color-picker__color-wrapper", key: color.variantId },
                React.createElement(Color, __assign({ variantId: color.variantId, hexColor: color.hexColor, onColorPicked: props.onColorPicked, colorIsSelected: props.colorSelected === color.variantId, onColorHover: props.onColorHover, nosVariant: color.nos, isAvailable: color.available, indexOfColorInColorsNameArray: props.colorsNameArray.indexOf(color.colorName), indexOfSelectedColorInColorsNameArray: props.colorVariantIdArray.indexOf(props.colorSelected) }, (props.colorVariantIdArray[props.preselectedId] === color.variantId && {
                    ref: colorRef,
                }))),
                React.createElement(HorizontalSpacer, { size: 'xxs' }))); }))),
        React.createElement(VerticalSpacer, { size: "mx" })));
};
var Color = React.memo(React.forwardRef(function (props, ref) {
    var isMulticolor = props.hexColor && props.hexColor.match(new RegExp(/(multi)/i));
    var isTransparent = props.hexColor && props.hexColor.match(new RegExp(/(tranp)/i));
    var displayColorSwatch = !!props.nosVariant && !props.isAvailable;
    return (React.createElement("button", __assign({}, (ref && { ref: ref }), { onClick: function () {
            props.onColorPicked(props.variantId);
        }, onMouseOver: function () { return props.onColorHover(props.indexOfColorInColorsNameArray); }, onMouseEnter: function () { return props.onColorHover(props.indexOfColorInColorsNameArray); }, onMouseLeave: function () { return props.onColorHover(props.indexOfSelectedColorInColorsNameArray); }, style: { backgroundColor: "" + props.hexColor + (displayColorSwatch ? '66' : '') }, className: classnames('a-pdp-color-picker__color', { 'a-pdp-color-picker__color--selected': props.colorIsSelected }, { 'a-pdp-color-picker__color--light-helper': isBright(props.hexColor) }, { 'a-pdp-color-picker__color--multicolor': isMulticolor }, { 'a-pdp-color-picker__color--transparent': isTransparent }, { 'a-pdp-color-picker__color--nos-unavailable': displayColorSwatch }) }, preventOutline()), displayColorSwatch && React.createElement("span", null)));
}));
function isBright(hexColor) {
    var rgbColor = hexToRGB(hexColor);
    if (rgbColor) {
        var brightness = getBrightness(rgbColor.r, rgbColor.g, rgbColor.b);
        // This number (200) is completely aribtrary.
        // If we need the helper to triger when the colors are "brighter",
        // we should just increase it, or decrease it, if vice-versa.
        // The brightness varaible can be between 0 and 255
        return brightness > 200;
    }
    return false;
}
function hexToRGB(hexColor) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
    return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
        }
        : null;
}
// We use the W3C formula for calculating the "perceived brightness" of a color:
// https://www.w3.org/TR/AERT/#color-contrast
function getBrightness(r, g, b) {
    return (r * 299 + g * 587 + b * 114) / 1000;
}
export function moveAtEnd(array, val) {
    var count = 0;
    var nonCount = [];
    for (var i = 0; i < array.length; i++) {
        if (!array[i].hexColor.match(new RegExp(val, 'i'))) {
            array[count++] = array[i];
        }
        else {
            nonCount.push(array[i]);
        }
    }
    for (var i = 0; i < nonCount.length; i++) {
        array.pop();
    }
    return array.concat(nonCount);
}
