var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { bindTranslations, I18N } from '@jelmoli/i18n/index';
import { getProfile } from '@jelmoli/sitecore-services/src/Account/getProfile/getProfile';
import { setForgotPassword } from '@jelmoli/sitecore-services/src/Account/setForgotPassword/setForgotPassword';
import { trackForgotPasswordClick } from '@jelmoli/sitecore-services/src/Tracking/Account/ForgotPasswordTracking';
import { trackError } from '@jelmoli/sitecore-services/src/Tracking/Error/ErrorTesting';
import { Modal } from '@jelmoli/ui-components/src/atoms/Modal/Modal';
import { showErrorNotification, showSuccessNotification, } from '@jelmoli/ui-components/src/atoms/Notification/Notification';
import { SecondaryButton } from '@jelmoli/ui-components/src/atoms/SecondaryButton/SecondaryButton';
import { A } from '@jelmoli/ui-components/src/helpers/A/A';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { Visibility } from '@jelmoli/ui-components/src/helpers/Visibility/Visibility';
import React, { useState } from 'react';
import { MyAccountProfilePasswordModalContent } from './MyAccountProfilePasswordModalContent';
export var MyAccountProfilePassword = function (props) {
    var _a;
    var _b = useState(false), isOverlayOpen = _b[0], setIsOverlayOpen = _b[1];
    var t = bindTranslations(props);
    return (React.createElement(React.Fragment, null,
        React.createElement(Typography, { size: "l", type: "medium" }, t('ChangePasswordTitle')),
        React.createElement(Typography, { tag: "div", size: "l", type: "regular" }, t('ChangePasswordDescription')),
        React.createElement(VerticalSpacer, { size: "l" }),
        React.createElement(Visibility, { show: ['xs', 'sm'] },
            React.createElement(SecondaryButton, { fontResponsive: false, type: "button", size: "tiny", text: t('ChangePasswordButton'), fullWidth: true, onClick: function () { return setIsOverlayOpen(true); } })),
        React.createElement(Visibility, { hide: ['xs', 'sm'] },
            React.createElement(SecondaryButton, { fontResponsive: false, type: "button", size: "tiny", text: t('ChangePasswordButton'), onClick: function () { return setIsOverlayOpen(true); } })),
        React.createElement(Modal, { open: isOverlayOpen, title: t('ChangePassOverlayTitle'), onClose: function () { return setIsOverlayOpen(false); } },
            React.createElement(MyAccountProfilePasswordModalContent, { saveTitle: t('SaveButton'), cancelTitle: t('CancelButton'), onClose: function () { return setIsOverlayOpen(false); }, passwordField: props.OldPassword, newPasswordField: props.NewPassword, passwordHints: {
                    introductionText: props.NewPassword.fields.IntroductionText.value,
                    minimumNumberOfCharactersRule: (_a = props.NewPassword.fields.MinLengthError) === null || _a === void 0 ? void 0 : _a.value,
                    letterRule: props.NewPassword.fields.LetterRule.value,
                    numberRule: props.NewPassword.fields.NumberRule.value,
                    specialCharacterRule: props.NewPassword.fields.SpecialCharacterRule.value,
                }, forgotPasswordHint: React.createElement(I18N, { text: t('OldPasswordInvalidLabel'), replacements: {
                        link: (React.createElement(A, { underline: true, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                var profile;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, getProfile({})];
                                        case 1:
                                            profile = _a.sent();
                                            return [4 /*yield*/, setForgotPassword({}, { username: profile.email })
                                                    .then(function (response) {
                                                    trackForgotPasswordClick(true);
                                                    showSuccessNotification(response.globalMessage);
                                                    setIsOverlayOpen(false);
                                                })
                                                    .catch(function (response) {
                                                    trackForgotPasswordClick(false);
                                                    trackError({
                                                        page: 'Error in sending email for forgot password',
                                                        errorMessage: response.GlobalMessage,
                                                    });
                                                    showErrorNotification(response.GlobalMessage);
                                                })];
                                        case 2:
                                            _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); } }, t('OldPasswordInvalidLink'))),
                    } }) }))));
};
