import { createPostEndpoint } from 'request-registry';
import { getCSRFToken } from '../../Security/getCSRFToken/getCSRFToken';
export var submitService = createPostEndpoint({
    url: function () { return '/jelmoli-api/JelmoliPersonalShopping/SubmitService'; },
    headers: {
        'X-RequestVerificationToken': function () { return getCSRFToken(); },
        'X-Requested-With': 'XMLHttpRequest',
        'jelmoli-RequestToken': function (token) { return token.token || ''; },
    },
});
