import { Column, Row } from '@jelmoli/bootstrap-grid';
import { bindTranslations } from '@jelmoli/i18n';
import { getProfile } from '@jelmoli/sitecore-services/src/Account/getProfile/getProfile';
import { isUserLoggedInLazy } from '@jelmoli/sitecore-services/src/Account/isUserLoggedIn/isUserLoggedInLazy';
import { Separator } from '@jelmoli/ui-components/src/atoms/Separator/Separator';
import { TextLink } from '@jelmoli/ui-components/src/atoms/TextLink/TextLink';
import { Container } from '@jelmoli/ui-components/src/helpers/Container/Container';
import { KeepInView } from '@jelmoli/ui-components/src/helpers/KeepInView/KeepInView';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import React from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
import './confirmation.scss';
import { ConfirmationComponentAddressSection } from './ConfirmationComponentPartials/ConfirmationComponentAddressSection';
import { ConfirmationComponentDeliveryOptionSection } from './ConfirmationComponentPartials/ConfirmationComponentDeliveryOptionSection';
import { ConfirmationComponentSaveMainAddressSection } from './ConfirmationComponentPartials/ConfirmationComponentSaveMainAddressSection';
import { ConfirmationComponentSignInSection } from './ConfirmationComponentPartials/ConfirmationComponentSignInSection';
import { ConfirmationComponentSummaryBox } from './ConfirmationComponentPartials/ConfirmationComponentSummaryBox';
export var ConfirmationComponent = function (_a) {
    var fields = _a.fields;
    var t = bindTranslations(fields.labels);
    var isLoggedIn = isUserLoggedInLazy();
    var getProfileData = useGetEndPointLazy(getProfile, {}).value;
    var noMainAddress = getProfileData && !('address1' in getProfileData.mainAddress);
    // Backend is validating that orderInformation
    // To use the Cart type we have to verify that the order information is present
    if (!fields.order.orderInformation) {
        return null;
    }
    return (React.createElement(Container, { size: "s", backgroundFill: true },
        React.createElement("div", { className: "feat-confirmation" },
            React.createElement(VerticalSpacer, { size: "l" }),
            React.createElement(Row, null,
                React.createElement(Column, { size: { xs: 12, md: 7, xl: 5 }, offset: { lg: 0, xl: 1 } },
                    React.createElement(Typography, { size: "xl", type: "medium" }, fields.labels.CheckoutConfirmationTitleLabel),
                    React.createElement(VerticalSpacer, { size: "m" }),
                    React.createElement(Typography, { size: "s", responsive: false }, t('CheckoutConfirmationDescriptionLabel', { email: fields.order.orderInformation.email })),
                    React.createElement(VerticalSpacer, { size: "m" }),
                    React.createElement("div", { className: "feat-confirmation__info-wrapper" },
                        React.createElement(VerticalSpacer, { size: "s" }),
                        React.createElement(Typography, { size: "s", responsive: false }, fields.labels.CheckoutConfirmationOrderNumberTitleLabel),
                        React.createElement(VerticalSpacer, { size: "xs" }),
                        React.createElement(Typography, { type: "bold", size: "l" }, fields.order.orderConfirmationId),
                        React.createElement(VerticalSpacer, { size: "s" })),
                    React.createElement(VerticalSpacer, { size: "l" }),
                    React.createElement(ConfirmationComponentAddressSection, { order: fields.order, CheckoutAdressBillingAdressLabel: fields.labels.CheckoutAdressBillingAdressLabel, CheckoutAdressShippingAdressLabel: fields.labels.CheckoutAdressShippingAdressLabel }),
                    React.createElement(VerticalSpacer, { size: "s" }),
                    React.createElement(ConfirmationComponentDeliveryOptionSection, { fields: fields }),
                    React.createElement(VerticalSpacer, { size: "l" }),
                    React.createElement(Separator, { shade: "two" }),
                    isLoggedIn && noMainAddress && fields.labels.CheckoutConfirmationSaveMainAddressLabel && (React.createElement(ConfirmationComponentSaveMainAddressSection, { fields: fields })),
                    React.createElement(ConfirmationComponentSignInSection, { order: fields.order, CheckoutConfirmationCreateAccountLabel: fields.labels.CheckoutConfirmationCreateAccountLabel, CheckoutConfirmationCreateAccountDescriptionLabel: fields.labels.CheckoutConfirmationCreateAccountDescriptionLabel, CheckoutConfirmationSetPasswordLabel: fields.labels.CheckoutConfirmationSetPasswordLabel, CheckoutConfirmationAccountSuccessfulDescriptionLabel: fields.labels.CheckoutConfirmationAccountSuccessfulDescriptionLabel, CheckoutConfirmationAccountErrorDescriptionLabel: fields.labels.CheckoutConfirmationAccountErrorDescriptionLabel }),
                    React.createElement(VerticalSpacer, { size: "l" }),
                    React.createElement(Typography, { type: "medium", size: "xs" },
                        React.createElement(TextLink, { "aria-label": "continue shopping", href: fields.labels.CheckoutConfirmationContinueShoppingLink.href }, fields.labels.CheckoutConfirmationContinueShoppingLabel)),
                    React.createElement(VerticalSpacer, { size: "l" })),
                React.createElement(Column, { size: { xs: 12, md: 5, xl: 4 }, offset: { lg: 0, xl: 1 }, verticalAlignment: "justify" },
                    React.createElement(KeepInView, null,
                        React.createElement(ConfirmationComponentSummaryBox, { order: fields.order, CheckoutSummaryShippingLabel: t('CheckoutSummaryShippingLabel'), CheckoutSummaryPackingLabel: t('CheckoutSummaryPackingLabel'), CheckoutSummaryTotalAmountLabel: t('CheckoutSummaryTotalAmountLabel'), CheckoutSummaryFragileLabel: t('CheckoutSummaryFragileLabel'), CheckoutSummaryBulkyLabel: t('CheckoutSummaryBulkyLabel'), CheckoutSummaryInstallationServiceLabel: t('CheckoutSummaryInstallationServiceLabel'), CheckoutSummaryFragilePackingLabel: t('CheckoutSummaryFragilePackingLabel'), CheckoutSummaryInstallationServiceSelectedLabel: t('CheckoutSummaryInstallationServiceSelectedLabel'), GiftCardSummaryLabel: t('GiftCardSummaryLabel'), CheckoutCustomMeasure: t('CheckoutCustomMeasure'), CheckoutZyseMeLabel: t('CheckoutSizeLabel'), GiftSummaryEmptyLabel: t('GiftSummaryEmptyLabel'), GiftSummaryNoTextLabel: t('GiftSummaryNoTextLabel'), GiftSummaryWithTextLabel: t('GiftSummaryWithTextLabel') })))))));
};
