var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useEffect, useState } from 'react';
/**
 * Lazy loader for basicScroll
 */
var useBasicScrollModuleLoader = function () {
    var _a = useState(undefined), basicScrollModule = _a[0], setBasicScrollModule = _a[1];
    useEffect(function () {
        import('basicscroll' /* webpackChunkName: "basicscroll" */).then(function (lazyBasicScrollModule) {
            setBasicScrollModule(lazyBasicScrollModule);
        });
    }, []);
    return { basicScrollModule: basicScrollModule };
};
export var useBasicScroll = function (measureElementRef, directRef, basicScrollOptions) {
    var basicScrollModule = useBasicScrollModuleLoader().basicScrollModule;
    useEffect(function () {
        var wrapper = measureElementRef.current;
        var direct = directRef.current;
        if (!wrapper || !direct || !basicScrollModule) {
            return;
        }
        var basicScrollInstance = basicScrollModule.create(__assign({ elem: wrapper, direct: direct }, basicScrollOptions));
        basicScrollInstance.start();
        return function () { return basicScrollInstance.destroy(); };
    }, [measureElementRef.current, directRef.current, basicScrollOptions, basicScrollModule]);
};
