var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useMiniFormik } from '@jelmoli/hooks/src/useMiniFormik/useMiniFormik';
import { bindTranslations } from '@jelmoli/i18n/index';
import { getProfile } from '@jelmoli/sitecore-services/src/Account/getProfile/getProfile';
import { updateProfile } from '@jelmoli/sitecore-services/src/Account/updateProfile/updateProfile';
import { trackError } from '@jelmoli/sitecore-services/src/Tracking/Error/ErrorTesting';
import { DateInput } from '@jelmoli/ui-components/src/atoms/DateInput/DateInput';
import { Dropdown } from '@jelmoli/ui-components/src/atoms/Dropdown/Dropdown';
import { DropdownItem } from '@jelmoli/ui-components/src/atoms/DropdownItem/DropdownItem';
import { MaskedInput } from '@jelmoli/ui-components/src/atoms/MaskedInput/MaskedInput';
import { showErrorNotification, showSuccessNotification, } from '@jelmoli/ui-components/src/atoms/Notification/Notification';
import { PrimarySubmitButton } from '@jelmoli/ui-components/src/atoms/PrimarySubmitButton/PrimarySubmitButton';
import { SecondaryButton } from '@jelmoli/ui-components/src/atoms/SecondaryButton/SecondaryButton';
import { HorizontalSpacer } from '@jelmoli/ui-components/src/helpers/HorizontalSpacer/HorizontalSpacer';
import { getModalStateErrorMessage } from '@jelmoli/ui-components/src/helpers/SitecoreErrorResponses/SitecoreErrorResponses';
import { getLabelsFromFormFields } from '@jelmoli/ui-components/src/helpers/SitecoreTypes/SitecoreTypes';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { Visibility } from '@jelmoli/ui-components/src/helpers/Visibility/Visibility';
import { NameForm } from '@jelmoli/ui-components/src/organism/NameForm/NameForm';
import { FormValidationContext } from 'nested-form-validation/index';
import React, { useEffect } from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
export var MyAccountProfileOverviewModalContent = function (props) {
    var getProfileData = useGetEndPointLazy(getProfile).value;
    var t = bindTranslations(__assign(__assign({}, props), getLabelsFromFormFields(props)));
    var form = useMiniFormik({
        initialValues: {
            jobTitle: '',
            salutation: '',
            firstName: '',
            lastName: '',
            birthday: '',
            mobile: '',
        },
    });
    useEffect(function () {
        if (getProfileData) {
            form.setValues({
                jobTitle: getProfileData.titleCode,
                salutation: getProfileData.salutationCode,
                firstName: getProfileData.firstName,
                lastName: getProfileData.lastName,
                birthday: getProfileData.birthday,
                mobile: getProfileData.mobile,
            });
        }
    }, [getProfileData]);
    return (React.createElement(FormValidationContext, null,
        React.createElement(Dropdown, __assign({ label: t('JobTitle'), name: "jobTitle", type: "fullwidth" }, form.fieldProps.jobTitle), props.JobTitleSource &&
            props.JobTitleSource.map(function (title) {
                return title.fields.ID.value && (React.createElement(DropdownItem, { value: title.fields.ID.value, key: title.fields.ID.value }, title.fields.Name.value));
            })),
        React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
        React.createElement(Typography, { size: "xxs", type: "medium", responsive: false }, props.Salutation.fields.FieldLabel.value),
        React.createElement(VerticalSpacer, { size: "xxs", responsive: false }),
        React.createElement(NameForm, { fields: {
                FirstName: props.FirstName,
                LastName: props.LastName,
                Salutation: props.Salutation,
                SalutationSource: props.SalutationSource,
            }, fieldValues: form.fieldProps }),
        React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
        React.createElement(DateInput, __assign({ variant: "standard", name: "date", label: props.BirthDate.fields.FieldLabel.value }, form.fieldProps.birthday, { fullWidth: true, disabled: getProfileData && getProfileData.birthday !== '' })),
        React.createElement(VerticalSpacer, { size: "xxs", responsive: false }),
        getProfileData && getProfileData.birthday !== '' && (React.createElement("span", { className: "feat-my-account-profile-modal__birthday-label" },
            React.createElement(Typography, { size: "xxs" }, props.BirthDateHelperText.value))),
        React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
        React.createElement(MaskedInput, __assign({ inputMode: "tel", variant: "standard", name: "mobile", label: props.MobileNumber.fields.FieldLabel.value, mask: ['+', /[1-9]/, /\d/, ' ', /[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/] }, form.fieldProps.mobile, { fullWidth: true })),
        React.createElement(VerticalSpacer, { size: "xxs", responsive: false }),
        React.createElement("span", { className: "feat-my-account-profile-modal__mobile-label" },
            React.createElement(Typography, { size: "xxs" }, props.MobileNumberHelperText.value)),
        React.createElement(VerticalSpacer, { size: "xl", responsive: false }),
        React.createElement(Visibility, { hide: ['xs', 'sm'] },
            React.createElement(SecondaryButton, { type: "button", text: t('CancelButton'), onClick: props.onClose }),
            React.createElement(HorizontalSpacer, { size: "s", responsive: false }),
            React.createElement(PrimarySubmitButton, { text: t('SaveButton'), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, updateProfile({}, formatDataForSubmit(form.values))
                                    .then(function (response) {
                                    showSuccessNotification(response.globalMessage);
                                })
                                    .catch(function (response) {
                                    var modalErrorMessage = getModalStateErrorMessage(response, 'profile.Birthday');
                                    trackError({
                                        page: 'Error while updating profile on My Account profile page',
                                        errorMessage: response.GlobalMessage,
                                    });
                                    if (modalErrorMessage) {
                                        showErrorNotification(modalErrorMessage);
                                    }
                                    else {
                                        showErrorNotification(response.GlobalMessage);
                                    }
                                })];
                            case 1:
                                _a.sent();
                                props.onClose();
                                return [2 /*return*/];
                        }
                    });
                }); } })),
        React.createElement(Visibility, { show: ['xs', 'sm'] },
            React.createElement(PrimarySubmitButton, { text: t('SaveButton'), fullWidth: true, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, updateProfile({}, formatDataForSubmit(form.values))
                                    .then(function (response) {
                                    showSuccessNotification(response.globalMessage);
                                })
                                    .catch(function (response) {
                                    var modalErrorMessage = getModalStateErrorMessage(response, 'profile.Birthday');
                                    trackError({
                                        page: 'Error while updating profile on My Account profile page',
                                        errorMessage: response.GlobalMessage,
                                    });
                                    if (modalErrorMessage) {
                                        showErrorNotification(modalErrorMessage);
                                    }
                                    else {
                                        showErrorNotification(response.GlobalMessage);
                                    }
                                })];
                            case 1:
                                _a.sent();
                                props.onClose();
                                return [2 /*return*/];
                        }
                    });
                }); } }),
            React.createElement(VerticalSpacer, { size: "xxs", responsive: false }),
            React.createElement(SecondaryButton, { type: "button", text: t('CancelButton'), fullWidth: true, onClick: props.onClose }))));
};
function formatDataForSubmit(data) {
    return {
        salutation: data.salutation,
        title: data.jobTitle,
        firstName: data.firstName,
        lastName: data.lastName,
        birthday: data.birthday,
        mobile: data.mobile,
    };
}
