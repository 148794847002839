import React, { useMemo } from 'react';
/**
 * Replace keywords inside a translation key and returns a
 * react component
 *
 * Replacements may be: strings, numbers or react nodes
 *
 * Usage:
 *
 * ```tsx
 * const Notification = () => {
 *   const exampleText = 'You have now ${count} items in your cart'
 * 	 return <I18N text={exampleText} replacements={{'count': <b>4</b>}} />
 * }
 *
 */
export var I18N = function (_a) {
    var text = _a.text, replacements = _a.replacements;
    // Replace ${0} with react component to
    // substitute parts inside translation keys
    // like "Hello ${0} how are you"
    var splitParts = useMemo(function () { return text.split(/\$\{([^\}]+)\}/); }, [text]);
    var parts = splitParts.map(function (entry, i) {
        return i % 2 ? (replacements[entry] !== undefined ? replacements[entry] : "${" + entry + "}") : entry;
    });
    return (React.createElement(React.Fragment, null, parts.map(function (part, i) { return (React.createElement(React.Fragment, { key: i }, part)); })));
};
/**
 * Replace keywords inside a translation key and returns a string
 *
 * Usage:
 *
 * ```tsx
 * const Notification = () => {
 *   const exampleText = 'You have now ${count} items in your cart'
 * 	 return <span>{i18n(exampleText, {'count': 4})}</span>
 * }
 *
 */
export var i18n = function (text, replacements) {
    return text.indexOf('${') === -1
        ? text
        : text.replace(/\$\{([^\}]+)\}/g, function (_, keyword) {
            return replacements[keyword] !== undefined ? String(replacements[keyword]) : "${" + keyword + "}";
        });
};
/**
 * Translate the text using the given keys
 */
var translate = function (keys, translationKey, replacements) {
    var text = getTextForKey(keys, translationKey) || "{" + translationKey + "}";
    return replacements ? i18n(text, replacements) : text;
};
/**
 * Create a translate function with bound keys
 *
 * Usage:
 * ```ts
 * const t = bindTranslations({priceLabel: 'Your price is ${price}.'});
 * console.log(t('priceLabel', {price: 100}));
 * ```
 */
export var bindTranslations = function (keys) {
    var t = keys
        ? // Generate a translation function which returns the value
            // for the given key or the KEY to show missing translations
            translate.bind(null, keys)
        : // If the entire translation set is missing we assume that the
            // data is still loading and return an empty string in the meantime
            function () { return ''; };
    return t;
};
/**
 * Try to read data in two different formats:
 *
 * - key value pairs e.g. {firstname: 'joe'}
 * - sitecore fields e.g. {firstname: {value: 'joe'}}
 *
 */
var getTextForKey = function (translationMap, key) {
    var mapValue = translationMap && translationMap[key];
    // Try to read key value pair e.g.:
    // { firstname: string }
    if (typeof mapValue === 'string') {
        return mapValue;
    }
    // Try to read sitecore field format e.g
    // { firstname: { value: string } }
    if (typeof mapValue === 'object') {
        return getTextForKey(mapValue, 'value');
    }
};
