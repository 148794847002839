var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Typography } from '../../helpers/Typography/Typography';
export var H1 = function (props) { return Typography(__assign(__assign({ size: 'xxl', type: 'bold' }, props), { tag: 'h1' })); };
export var H2 = function (props) { return Typography(__assign(__assign({ size: 'xl', type: 'bold' }, props), { tag: 'h2' })); };
export var H3 = function (props) { return Typography(__assign(__assign({ size: 'xl', type: 'bold' }, props), { tag: 'h3' })); };
export var H4 = function (props) { return Typography(__assign(__assign({ size: 'l', type: 'bold' }, props), { tag: 'h4' })); };
export var H5 = function (props) { return Typography(__assign(__assign({ size: 'm', type: 'bold' }, props), { tag: 'h5' })); };
export var H6 = function (props) { return Typography(__assign(__assign({ size: 's', type: 'bold' }, props), { tag: 'h6' })); };
