var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Column, Row } from '@jelmoli/bootstrap-grid';
import classnames from 'classnames';
import React from 'react';
import { Image } from '../../atoms/Image/Image';
import { TextLink } from '../../atoms/TextLink/TextLink';
import { A } from '../../helpers/A/A';
import { hoverAnchorClassName } from '../../helpers/HoverAnchor/HoverAnchor';
import { getNodeAttributes, onlyTagsWithInnerHTML, RichTextRenderer, } from '../../helpers/RichTextRenderer/RichTextRenderer';
import { hasImageFieldValue, hasTextFieldValue, } from '../../helpers/SitecoreTypes/SitecoreTypes';
import { Typography } from '../../helpers/Typography/Typography';
import { VerticalSpacer } from '../../helpers/VerticalSpacer/VerticalSpacer';
import { Visibility } from '../../helpers/Visibility/Visibility';
import './pop-teaser.scss';
export var POPTeaser = function (props) {
    var coverColor = props.fields.PopTeaserCoverColorOption && props.fields.PopTeaserCoverColorOption.value;
    var textColor = coverColor.includes('darken') ? 'white' : 'outer-space';
    if (!hasTextFieldValue(props.fields.PopTeaserClickableSubtitle)) {
        return React.createElement(POPTeaserOldStyle, __assign({}, props, { coverColor: coverColor, textColor: textColor }));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(A, { className: classnames('feat-pop-teaser__wrapper', hoverAnchorClassName), href: props.fields.PopTeaserClickableLink.value.href },
            hasImageFieldValue(props.fields.PopTeaserImageSmall) && (React.createElement(Visibility, { show: "xs" },
                React.createElement(POPTeaserImage, { imageField: props.fields.PopTeaserImageSmall, overlayColor: coverColor, hoverable: true, children: React.createElement(POPTeaserTextSubtitle, { title: { field: props.fields.PopTeaserTitle, color: textColor }, subtitle: { value: props.fields.PopTeaserClickableSubtitle.value } }) }))),
            hasImageFieldValue(props.fields.PopTeaserImageLarge) && (React.createElement(Visibility, { hide: "xs" },
                React.createElement(POPTeaserImage, { imageField: props.fields.PopTeaserImageLarge, overlayColor: coverColor, hoverable: true, children: React.createElement(POPTeaserTextSubtitle, { title: { field: props.fields.PopTeaserTitle, color: textColor }, subtitle: { value: props.fields.PopTeaserClickableSubtitle.value } }) })))),
        React.createElement(VerticalSpacer, { size: "xxl" })));
};
var POPTeaserTextSubtitle = function (_a) {
    var title = _a.title, subtitle = _a.subtitle;
    return (React.createElement("div", { className: "feat-pop-teaser__text" },
        React.createElement(VerticalSpacer, { show: "xs", size: "s" }),
        React.createElement(Row, null,
            React.createElement(Column, { size: { xs: 10, sm: 8, md: 10, lg: 8, xl: 8 } },
                React.createElement(React.Fragment, null,
                    React.createElement("span", { className: "feat-pop-teaser__text--" + title.color },
                        React.createElement(Typography, { size: "m", type: "bold", field: title.field })),
                    React.createElement(VerticalSpacer, { size: 'xs' }),
                    subtitle && (React.createElement("span", { className: "feat-pop-teaser__text--" + title.color },
                        React.createElement(Typography, { size: "l", type: "medium", tag: "p" }, subtitle.value))),
                    React.createElement(VerticalSpacer, { size: "m" }))))));
};
var POPTeaserImage = function (_a) {
    var overlayColor = _a.overlayColor, imageField = _a.imageField, hoverable = _a.hoverable, children = _a.children;
    var overlayClass = overlayColor && "feat-pop-teaser__cover feat-pop-teaser__cover--" + overlayColor;
    return (React.createElement(Image, { value: imageField.value, hoverable: hoverable },
        React.createElement(React.Fragment, null,
            overlayColor && React.createElement("div", { className: overlayClass }),
            children)));
};
var POPTeaserOldStyle = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "feat-pop-teaser__wrapper" },
            React.createElement(React.Fragment, null,
                hasImageFieldValue(props.fields.PopTeaserImageSmall) && (React.createElement(Visibility, { show: "xs" },
                    React.createElement(POPTeaserImage, { imageField: props.fields.PopTeaserImageSmall, overlayColor: props.coverColor }))),
                hasImageFieldValue(props.fields.PopTeaserImageLarge) && (React.createElement(Visibility, { hide: "xs" },
                    React.createElement(POPTeaserImage, { imageField: props.fields.PopTeaserImageLarge, overlayColor: props.coverColor })))),
            React.createElement("div", { className: "feat-pop-teaser__text" },
                React.createElement(VerticalSpacer, { show: "xs", size: "s" }),
                React.createElement(Row, null,
                    React.createElement(Column, { size: { xs: 10, sm: 8, md: 10, lg: 8, xl: 8 } },
                        React.createElement(POPTeaserTextRichtext, { title: {
                                field: props.fields.PopTeaserTitle,
                                color: props.textColor,
                            }, richtext: props.fields.PopTeaserLinks }))))),
        React.createElement(VerticalSpacer, { size: "xxl" })));
};
var POPTeaserTextRichtext = function (_a) {
    var title = _a.title, richtext = _a.richtext;
    var multimedaTextRichtextRules = {
        P: onlyTagsWithInnerHTML(function (content) { return (React.createElement(Typography, { size: "l", type: "medium", tag: "p" }, content)); }),
        A: onlyTagsWithInnerHTML(function (content, node) { return (React.createElement(TextLink, __assign({ "aria-label": "pop teaser link", color: title.color }, getNodeAttributes(node)), content)); }),
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("span", { className: "feat-pop-teaser__text--" + title.color },
            React.createElement(Typography, { size: "m", type: "bold", field: title.field })),
        React.createElement(VerticalSpacer, { size: 'xs' }),
        richtext && (React.createElement("span", { className: "feat-pop-teaser__text--" + title.color },
            React.createElement(RichTextRenderer, { html: richtext.value || '', replaceRules: multimedaTextRichtextRules }))),
        React.createElement(VerticalSpacer, { size: "m" })));
};
