var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getDrawerByShopper } from '@jelmoli/sitecore-services/src/CustomerService/getDrawerByShopper';
import { FormFieldErrorMessage } from '@jelmoli/ui-components/src/atoms/FormFieldErrorMessage/FormFieldErrorMessage';
import { ChevronLeftIcon } from '@jelmoli/ui-components/src/atoms/Icon/ChevronLeftIcon';
import { ChevronRightIcon } from '@jelmoli/ui-components/src/atoms/Icon/ChevronRightIcon';
import { RadioButtonImage } from '@jelmoli/ui-components/src/atoms/RadioButtonImage/RadioButtonImage';
import { RadioButtonsValidable } from '@jelmoli/ui-components/src/atoms/RadioButtonsValidatable/RadioButtonsValidatable';
import { HorizontalScrollWrapper } from '@jelmoli/ui-components/src/helpers/HorizontalScrollWrapper/HorizontalScrollWrapper';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import classnames from 'classnames';
import { FormValidationContext } from 'nested-form-validation/index';
import React from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
export var PersonalShoppingDrawerServicesSlider = function (props) {
    var servicesApiData = useGetEndPointLazy(getDrawerByShopper, { staffMemberId: props.stafMemberId }).value;
    if (!servicesApiData) {
        return null;
    }
    return (React.createElement(FormValidationContext, null,
        React.createElement("div", { className: classnames('feat-personal-shopping-drawer__slider-wrapper', {
                'feat-personal-shopping-drawer__slider-wrapper--arrows-shown': servicesApiData.services.length > 2,
            }) },
            React.createElement(RadioButtonsValidable, __assign({ row: true, name: "service", errormsg: props.ServiceSelection.fields.IsRequiredError &&
                    props.ServiceSelection.fields.IsRequiredError.value }, props.fieldValues.shoppingServiceId),
                React.createElement(HorizontalScrollWrapper, { fullWidth: true, isSlider: true, leftArrow: React.createElement("div", { className: classnames('feat-personal-shopping-drawer__arrow feat-personal-shopping-drawer__arrow--left', servicesApiData.services.length <= 2 && 'feat-personal-shopping-drawer__arrow--hide') },
                        React.createElement(ChevronLeftIcon, { height: 12 })), rightArrow: React.createElement("div", { className: classnames('feat-personal-shopping-drawer__arrow feat-personal-shopping-drawer__arrow--right', servicesApiData.services.length <= 2 && 'feat-personal-shopping-drawer__arrow--hide') },
                        React.createElement(ChevronRightIcon, { height: 12 })) },
                    React.createElement("div", { className: "feat-personal-shopping-drawer__slider" }, servicesApiData.services.map(function (service, index) { return (React.createElement(RadioButtonImage, __assign({ value: service.id, image: { value: { src: service.image } }, key: "staff-member--" + index }, (index === 0 &&
                        props.fieldValues.shoppingServiceId.value === '' && { autoFocus: true })),
                        React.createElement(Typography, { tag: "p", size: "l", type: "medium", responsive: false }, service.name),
                        React.createElement(Typography, { type: "regular", size: "xs", responsive: false }, service.duration),
                        React.createElement("div", { className: "feat-personal-shopping-drawer__text-gray" },
                            React.createElement(Typography, { tag: "p", size: "xs", type: "regular", responsive: false }, service.cost)))); }))))),
        React.createElement(FormFieldErrorMessage, { name: "service", verticalSpaceTop: "xxs" })));
};
