import React, { useMemo } from 'react';
import './aspect-ratio.scss';
export var AspectRatio = function (_a) {
    var ratio = _a.ratio, children = _a.children;
    var _b = ratio.split('-'), width = _b[0], height = _b[1];
    var styles = useMemo(function () {
        return {
            '--ratio-x': width,
            '--ratio-y': height,
        } /* cast as any so react will accept css variables */;
    }, [width, height]);
    // Don't add a aspect ratio wrapper if the ratio is "native" (as is)
    // or if the ratio is invalid
    if (ratio === 'native' || height === undefined) {
        return React.createElement(React.Fragment, null, children);
    }
    return (React.createElement("span", { className: "h-aspect-ratio", style: styles },
        React.createElement("span", { className: "h-aspect-ratio__children" }, children)));
};
