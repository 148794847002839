import React from 'react';
import { HorizontalSpacer } from '../../helpers/HorizontalSpacer/HorizontalSpacer';
import { Typography } from '../../helpers/Typography/Typography';
import { VerticalSpacer } from '../../helpers/VerticalSpacer/VerticalSpacer';
import './info-message-box.scss';
export var InfoMessageBox = function (_a) {
    var text = _a.text, icon = _a.icon;
    return (React.createElement("div", { className: "a-info-message-box" },
        React.createElement(VerticalSpacer, { size: "m", responsive: false }),
        React.createElement("div", { className: "a-info-message-box__notification-text-wrapper" },
            icon && (React.createElement(React.Fragment, null,
                icon,
                React.createElement(HorizontalSpacer, { size: "s", responsive: false }))),
            React.createElement("div", { className: "a-info-message-box__notification-text" }, typeof text === 'string' ? (React.createElement(Typography, { size: "xs", type: "regular", responsive: false }, text)) : (text))),
        React.createElement(VerticalSpacer, { size: "m", responsive: false })));
};
