import { Column, Row } from '@jelmoli/bootstrap-grid';
import { bindTranslations } from '@jelmoli/i18n';
import { addProductTrackingPDP as addToCartTracking } from '@jelmoli/sitecore-services/src/Tracking/Product/AddProductTracking';
import { proudctDetailImpressionTracking } from '@jelmoli/sitecore-services/src/Tracking/Product/ProductDetailsImpressionTracking';
import { Bag } from '@jelmoli/ui-components/src/atoms/Icon/Bag';
import { Image } from '@jelmoli/ui-components/src/atoms/Image/Image';
import { PrimaryButton } from '@jelmoli/ui-components/src/atoms/PrimaryButton/PrimaryButton';
import { Container } from '@jelmoli/ui-components/src/helpers/Container/Container';
import { KeepInView } from '@jelmoli/ui-components/src/helpers/KeepInView/KeepInView';
import { ScrollIntoView } from '@jelmoli/ui-components/src/helpers/ScrollIntoView/ScrollIntoView';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { AdditionalDetails } from './AdditionalDetails/AdditionalDetails';
import { addGiftcardToCart, addToCart, addZyseMeToCart } from './AddToCartFunctions/AddToCartFunctions';
import { ECommerceBox } from './ECommerceBox/ECommerceBox';
import { ECommerceBoxGiftCard } from './ECommerceBox/ECommerceBoxGiftCard';
import { ECommerceBoxGiftWrappingPaper } from './ECommerceBox/ECommerceBoxGiftWrappingPaper';
import { ECommerceBoxZyseMe } from './ECommerceBox/ECommerceBoxZyseMe';
import './pdp.scss';
import { PDPSpecification } from './PDPSpecification/PDPSpecification';
import { ProductDescription } from './ProductDescription/ProductDescription';
import { ProductImages } from './ProductImages/ProductImages';
import { ProductQuote } from './ProductQuote/ProductQuote';
import { SalesZones } from './SalesZones/SalesZones';
export var PDPProductDetailsComponent = function (props) {
    var _a = props.fields, variant = _a.variant, labels = _a.labels, colorSwitch = _a.colorSwitch, sizeSwitch = _a.sizeSwitch, attributeGroups = _a.attributeGroups, gender = _a.gender, sizeChartUrl = _a.sizeChartUrl;
    var _b = useState(true), panelExpanded = _b[0], setPanelExpanded = _b[1];
    var _c = useState(false), panelVendorExpanded = _c[0], setPanelVendorExpanded = _c[1];
    var _d = useState(true), isEcommerceBoxButtonVisible = _d[0], setIsEcommerceBoxButtonVisible = _d[1];
    var _e = useState(false), scrollDown = _e[0], setScrollDown = _e[1];
    var _f = useState(1), quantityValue = _f[0], setQuantityValue = _f[1];
    var _g = useState(false), addedToCart = _g[0], setAddedToCart = _g[1];
    var _h = useState(50), amountGiftcard = _h[0], setAmountGiftcard = _h[1];
    var _j = useState(), selectedZysemeGarmentUnitId = _j[0], setSelectedZysemeGarmentUnitId = _j[1];
    var sizeIndex = sizeSwitch && sizeSwitch.length > 0 ? sizeSwitch.findIndex(function (size) { return size.variantId === variant.variantId; }) : -1;
    var baseProductAvailability = sizeIndex > -1 && sizeSwitch ? sizeSwitch[sizeIndex].availability : Infinity;
    var availability = selectedZysemeGarmentUnitId && variant.zyseMe ? variant.zyseMe.availability : baseProductAvailability;
    useEffect(function () {
        proudctDetailImpressionTracking({ variant: variant, colorSwitch: colorSwitch, sizeSwitch: sizeSwitch, availability: availability, gender: gender, sizeChartUrl: sizeChartUrl });
    }, []);
    useEffect(function () {
        if (addedToCart) {
            addToCartTracking({
                variant: variant,
                colorSwitch: colorSwitch,
                sizeSwitch: sizeSwitch,
                quantity: quantityValue,
                availability: availability,
                gender: gender,
                sizeChartUrl: sizeChartUrl,
            });
            setAddedToCart(false);
        }
    }, [addedToCart]);
    var t = labels ? bindTranslations(labels) : function () { return ''; };
    return (React.createElement(Container, { size: "m", backgroundFill: true },
        React.createElement(Row, null,
            variant.brandBannerImage && variant.brand.toLowerCase().includes('dior') && (React.createElement(Column, { size: 12 },
                React.createElement(VerticalSpacer, { size: "l" }),
                React.createElement("a", { href: variant.brandUrl },
                    React.createElement("div", { className: "feat-pdp-product-details__brand-banner-image" },
                        React.createElement(Image, { fitObject: true, value: { src: variant.brandBannerImage } }))),
                React.createElement(VerticalSpacer, { size: "m" }))),
            React.createElement(Column, { size: { xs: 12, sm: 8, md: 8 }, offset: { sm: 2, md: 0 } }, variant.images && React.createElement(ProductImages, { images: variant.images, productName: variant.name })),
            React.createElement(Column, { size: { xs: 12, sm: 8, md: 4 }, offset: { sm: 2, md: 0 }, verticalAlignment: "justify" },
                React.createElement(KeepInView, { pageType: "pdp" },
                    variant.pdpType === 'Default' && (React.createElement(ECommerceBox, { data: { variant: variant, sizeSwitch: sizeSwitch, colorSwitch: colorSwitch, labels: labels, gender: gender, sizeChartUrl: sizeChartUrl }, onButtonVisiblityChange: setIsEcommerceBoxButtonVisible, onClickScrollChange: setScrollDown, quantityValue: quantityValue, updateQuantityValue: function (value) { return setQuantityValue(value); }, onAddedToCart: setAddedToCart })),
                    variant.pdpType === 'Giftcard' && (React.createElement(ECommerceBoxGiftCard, { data: { variant: variant, sizeSwitch: sizeSwitch, colorSwitch: colorSwitch, labels: labels, gender: gender, sizeChartUrl: sizeChartUrl }, onButtonVisiblityChange: setIsEcommerceBoxButtonVisible, onClickScrollChange: setScrollDown, quantityValue: quantityValue, updateQuantityValue: function (value) { return setQuantityValue(value); }, onAddedToCart: setAddedToCart, amountSelected: setAmountGiftcard })),
                    variant.pdpType === 'ZyseMe' && (React.createElement(ECommerceBoxZyseMe, { data: { variant: variant, sizeSwitch: sizeSwitch, colorSwitch: colorSwitch, labels: labels, gender: gender, sizeChartUrl: sizeChartUrl }, onButtonVisiblityChange: setIsEcommerceBoxButtonVisible, onClickScrollChange: setScrollDown, quantityValue: quantityValue, updateQuantityValue: function (value) { return setQuantityValue(value); }, onAddedToCart: setAddedToCart, onSelectedGarmentUnitIdChange: setSelectedZysemeGarmentUnitId })),
                    variant.pdpType === 'GiftWrappingPaper' && (React.createElement(ECommerceBoxGiftWrappingPaper, { data: { variant: variant, sizeSwitch: sizeSwitch, colorSwitch: colorSwitch, labels: labels }, onButtonVisiblityChange: setIsEcommerceBoxButtonVisible, onClickScrollChange: setScrollDown, quantityValue: quantityValue, updateQuantityValue: function (value) { return setQuantityValue(value); }, onAddedToCart: setAddedToCart }))))),
        React.createElement(Row, null,
            React.createElement(Column, { size: { md: 12, lg: 10, xl: 8 }, offset: { md: 0, lg: 1, xl: 2 } },
                React.createElement(ScrollIntoView, { smooth: true, inView: scrollDown, onScroll: function () { return setScrollDown(false); } }),
                variant.quote && React.createElement(ProductQuote, { quote: variant.quote }),
                React.createElement(PDPSpecification, { washingLabels: variant.washingLabels, bulletList: variant.bulletList, washingLabelsInstruction: t('ProductDetailWashingInstruction') }),
                React.createElement("div", null,
                    variant.description && (React.createElement(ProductDescription, { description: variant.description, panelLabel: t('ProductDetailDescriptionLabel'), expanded: panelExpanded, onChange: function (event, expanded) { return setPanelExpanded(expanded); } })),
                    React.createElement(AdditionalDetails, { variant: {
                            attributesValue: variant.attributesValue,
                            attributesEnum: variant.attributesEnum,
                            attributesText: variant.attributesText,
                        }, attributeGroups: attributeGroups && attributeGroups, isProductDescriptionDefined: 'description' in variant }),
                    React.createElement(SalesZones, { label: t('ProductDetailSalesZoneLabel'), productId: variant.baseId, variantId: variant.variantId }),
                    variant.vendorItemText && (React.createElement(ProductDescription, { description: variant.vendorItemText, panelLabel: t('ProductDetailVendorDescriptionLabel'), expanded: panelVendorExpanded, onChange: function (event, expanded) { return setPanelVendorExpanded(expanded); } }))),
                React.createElement(VerticalSpacer, { size: "xl" })),
            React.createElement("div", { className: classnames('feat-pdp-product-details__sticky-wrapper', {
                    'feat-pdp-product-details__sticky-wrapper--show': !isEcommerceBoxButtonVisible,
                }) },
                React.createElement(PrimaryButton, { type: "button", uppercase: true, text: t('ProductDetailBasketLabel'), fontResponsive: false, icon: React.createElement(Bag, { width: 16 }), fullWidth: true, disabled: availability === 0, onClick: function () {
                        // GiftCard special product
                        if (variant.pdpType === 'Giftcard') {
                            return addGiftcardToCart(variant.baseId, sizeSwitch[0].variantId, amountGiftcard, setAddedToCart);
                        }
                        // ZyseMe customizable item which is customized
                        if (variant.pdpType === 'ZyseMe' && variant.zyseMe && selectedZysemeGarmentUnitId) {
                            return addZyseMeToCart(variant.zyseMe.productReference, variant.baseId, variant.variantId, variant.zyseMe.garmentModelId, selectedZysemeGarmentUnitId, quantityValue, setAddedToCart);
                        }
                        // Regular non customizable item
                        return addToCart(variant.baseId, variant.variantId, quantityValue, setAddedToCart);
                    } }),
                React.createElement(VerticalSpacer, { size: "m" })))));
};
