var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import MUiAccordion from '@material-ui/core/Accordion';
import MUiAccordionSummary from '@material-ui/core/AccordionSummary';
import classnames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { HorizontalSpacer } from '../../helpers/HorizontalSpacer/HorizontalSpacer';
import { Typography } from '../../helpers/Typography/Typography';
import { VerticalSpacer } from '../../helpers/VerticalSpacer/VerticalSpacer';
import { Badge } from '../Badge/Badge';
import { ChevronDownIcon } from '../Icon/ChevronDownIcon';
import './expansion-panel.scss';
export var ExpansionPanel = function (_a) {
    var summary = _a.summary, _b = _a.type, type = _b === void 0 ? 'default' : _b, _c = _a.focused, focused = _c === void 0 ? false : _c, props = __rest(_a, ["summary", "type", "focused"]);
    var _d = useState(props.expanded), internalExtendedState = _d[0], setIsExpanded = _d[1];
    var isExpanded = useMemo(function () { return (props.expanded === undefined ? internalExtendedState : props.expanded); }, [
        internalExtendedState,
        props.expanded,
    ]);
    var summaryRef = useRef(null);
    useEffect(function () {
        if (summaryRef.current && focused) {
            summaryRef.current.focus();
        }
    }, [focused]);
    return (React.createElement(MUiAccordion, __assign({ classes: {
            root: classnames('a-expansion-panel', { 'a-expansion-panel--footer': type === 'footer' }),
            expanded: 'a-expansion-panel--expanded',
        }, onChange: function (event, expanded) { return setIsExpanded(expanded); }, expanded: isExpanded || false }, props),
        summary.text && (React.createElement(MUiAccordionSummary, { ref: summaryRef, classes: {
                root: 'a-expansion-panel__summary',
                content: 'a-expansion-panel__content',
                expanded: 'a-expansion-panel__content--expanded',
                focused: 'a-expansion-panel--focused',
            }, IconButtonProps: {
                classes: { root: 'a-expansion-panel__icon' },
            }, expandIcon: React.createElement(ChevronDownIcon, { height: 16, className: "a-expansion-panel__chevron-icon" }) },
            React.createElement(VerticalSpacer, { size: type === 'footer' ? 'mx' : 'm', responsive: type !== 'inDrawer' }),
            React.createElement(HorizontalSpacer, { size: "xs" }),
            React.createElement("div", { className: "a-expansion-panel__summary" },
                React.createElement("div", { className: "a-expansion-panel__summary-item" },
                    summary.tag && (React.createElement(Typography, { size: summary.size || 'l', responsive: summary.responsive, uppercase: summary.uppercase, tag: summary.tag === 'h2' ? 'h2' : 'h3', display: "inline", type: isExpanded ? summary.typeExpanded || 'medium' : summary.type || 'regular' }, typeof summary.text === 'function'
                        ? summary.text(Boolean(isExpanded))
                        : summary.text)),
                    !summary.tag && (React.createElement(Typography, { size: summary.size || 'l', responsive: summary.responsive, uppercase: summary.uppercase, type: isExpanded ? summary.typeExpanded || 'medium' : summary.type || 'regular' }, typeof summary.text === 'function'
                        ? summary.text(Boolean(isExpanded))
                        : summary.text)))),
            React.createElement(VerticalSpacer, { size: type === 'footer' ? (isExpanded ? 'm' : 'mx') : 'm', responsive: type !== 'inDrawer' }))),
        props.children,
        React.createElement(VerticalSpacer, { size: "mx" })));
};
export var SummaryWithBadge = function (props) { return (React.createElement("div", { className: "a-expansion-panel__summary-with-badge" },
    React.createElement("div", { className: "a-expansion-panel__summary-item-with-badge" }, props.children),
    props.showBadge && React.createElement(Badge, { size: props.badgeSize || 8 }))); };
