var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { bindTranslations } from '@jelmoli/i18n/index';
import { Checkbox } from '@jelmoli/ui-components/src/atoms/Checkbox/Checkbox';
import { PrimarySubmitButton } from '@jelmoli/ui-components/src/atoms/PrimarySubmitButton/PrimarySubmitButton';
import { SecondaryButton } from '@jelmoli/ui-components/src/atoms/SecondaryButton/SecondaryButton';
import { HorizontalSpacer } from '@jelmoli/ui-components/src/helpers/HorizontalSpacer/HorizontalSpacer';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { AddressForm } from '@jelmoli/ui-components/src/organism/AddressForm/AddressForm';
import { NameForm } from '@jelmoli/ui-components/src/organism/NameForm/NameForm';
import { FormValidationContext } from 'nested-form-validation/index';
import React from 'react';
export var MyAccountAddressForm = function (_a) {
    var form = _a.form, props = __rest(_a, ["form"]);
    var t = bindTranslations(props);
    return (React.createElement(FormValidationContext, null,
        React.createElement(Typography, { type: "medium", size: "xxs", responsive: false, field: props.Salutation.fields.FieldLabel }),
        React.createElement(VerticalSpacer, { size: "xs" }),
        React.createElement(NameForm, { fields: {
                FirstName: props.FirstName,
                LastName: props.LastName,
                Salutation: props.Salutation,
                SalutationSource: props.SalutationSource,
            }, fieldValues: form }),
        React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
        React.createElement(AddressForm, { fields: {
                Company: props.Company,
                Address1: props.Address1,
                Address2: props.Address2,
                Country: props.Country,
                City: props.City,
                ZipPostalCode: props.ZipPostalCode,
                CountryList: props.CountryList,
            }, fieldValues: form }),
        React.createElement(Checkbox, __assign({ label: t('AddressBookStandardBillingAddressLabel') }, form.standardBilling)),
        React.createElement(VerticalSpacer, { size: "xs", responsive: false }),
        React.createElement(Checkbox, __assign({ label: t('AddressBookStandardDeliveryAddressLabel') }, form.standardDelivery)),
        React.createElement(VerticalSpacer, { size: "l", responsive: false }),
        React.createElement(SecondaryButton, { type: "button", text: t('AddressBookCancelButtonLabel'), onClick: props.onCancel }),
        React.createElement(HorizontalSpacer, { size: "s", responsive: false }),
        React.createElement(PrimarySubmitButton, { text: t('AddressBookSaveButtonLabel'), onClick: props.onSubmit })));
};
