var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classnames from 'classnames';
import { useFormValidation } from 'nested-form-validation';
import React from 'react';
import { Typography } from '../../helpers/Typography/Typography';
import { VerticalSpacer } from '../../helpers/VerticalSpacer/VerticalSpacer';
import { FormFieldErrorMessageText } from '../FormFieldErrorMessage/FormFieldErrorMessage';
import { PasswordFieldValidatable } from '../PasswordFieldValidatable/PasswordFieldValidatable';
import './password-strength-field.scss';
export var PasswordStrengthField = function (_a) {
    var children = _a.children, minNumberCharacters = _a.minNumberCharacters, props = __rest(_a, ["children", "minNumberCharacters"]);
    var minNumberOfCarachters = minNumberCharacters ? Number(minNumberCharacters) : 9;
    var validator = function (currentValue) {
        if (!currentValue ||
            currentValue.length < minNumberOfCarachters ||
            !currentValue.match(/\d+/) ||
            !currentValue.match(/[.,!,@,#,$,%,^,&,*,?,_,~,-,(,)]/) ||
            !currentValue.match(/[A-Z]/) ||
            !currentValue.match(/[a-z]/)) {
            return '';
        }
        if (props.validator) {
            var error = props.validator(currentValue);
            if (error !== undefined) {
                return error;
            }
        }
    };
    var _b = useFormValidation(props.name, props.value, validator), errorMessage = _b[0], touched = _b[1];
    var hasVisibleError = touched && errorMessage !== undefined;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classnames('a-password-strength-field', props.fullWidth && 'a-password-strength-field--full-width') },
            React.createElement(PasswordFieldValidatable, __assign({}, props, { validator: function (value) {
                    if (props.validator) {
                        var error = props.validator(value);
                        if (error !== undefined) {
                            return error;
                        }
                    }
                    if (!value ||
                        value.length < minNumberOfCarachters ||
                        !value.match(/\d+/) ||
                        !value.match(/[.,!,@,#,$,%,^,&,*,?,_,~,-,(,)]/) ||
                        !value.match(/[A-Z]/) ||
                        !value.match(/[a-z]/) ||
                        !hasVisibleError) {
                        return '';
                    }
                }, error: !props.value && touched })),
            children && children(errorMessage, touched))));
};
export var PasswordStrengthFieldWithHints = function (_a) {
    var hints = _a.hints, props = __rest(_a, ["hints"]);
    return (React.createElement(PasswordStrengthField, __assign({}, (props.minNumberCharacters && { minNumberCharacters: props.minNumberCharacters }), props), function (errorMessage, touched) { return (React.createElement(PasswrodHints, __assign({ hints: hints, errorMessage: errorMessage, touched: touched }, (props.minNumberCharacters && { minNumberCharacters: props.minNumberCharacters }), props))); }));
};
var PasswrodHints = function (_a) {
    var hints = _a.hints, errorMessage = _a.errorMessage, touched = _a.touched, minNumberCharacters = _a.minNumberCharacters, props = __rest(_a, ["hints", "errorMessage", "touched", "minNumberCharacters"]);
    var minNumberOfCarachters = minNumberCharacters ? Number(minNumberCharacters) : 9;
    var lowerCase = new RegExp(/[a-z]/);
    var upperCase = new RegExp(/[A-Z]/);
    var checkHints = props.value || touched;
    var checkLength = props.value.length > minNumberOfCarachters - 1;
    var checkLowerAndUppercase = lowerCase.test(props.value) && upperCase.test(props.value);
    var checkIfNumber = props.value.match(/\d+/);
    var checkIfSecialCharacter = props.value.match(/[.,!,@,#,$,%,^,&,*,?,_,~,-,(,)]/);
    return (React.createElement("div", { className: "a-password-strength-field__hint-wrapper" },
        React.createElement(VerticalSpacer, { size: "xs" }),
        React.createElement(Typography, { size: "xxs", responsive: false }, hints.introductionText),
        React.createElement("span", { className: classnames('a-password-strength-field__hint', checkHints &&
                (checkLength
                    ? 'a-password-strength-field__hint-fulfilled'
                    : 'a-password-strength-field__hint--error')) },
            React.createElement(Typography, { size: "xxs", responsive: false }, hints.minimumNumberOfCharactersRule)),
        React.createElement("span", { className: classnames('a-password-strength-field__hint', checkHints &&
                (checkLowerAndUppercase
                    ? 'a-password-strength-field__hint-fulfilled'
                    : 'a-password-strength-field__hint--error')) },
            React.createElement(Typography, { size: "xxs", responsive: false }, hints.letterRule)),
        React.createElement("span", { className: classnames('a-password-strength-field__hint', checkHints &&
                (checkIfNumber
                    ? 'a-password-strength-field__hint-fulfilled'
                    : 'a-password-strength-field__hint--error')) },
            React.createElement(Typography, { size: "xxs", responsive: false }, hints.numberRule)),
        React.createElement("span", { className: classnames('a-password-strength-field__hint', checkHints &&
                (checkIfSecialCharacter
                    ? 'a-password-strength-field__hint-fulfilled'
                    : 'a-password-strength-field__hint--error')) },
            React.createElement(Typography, { size: "xxs", responsive: false }, hints.specialCharacterRule)),
        (!checkLength || !checkLowerAndUppercase || !checkIfNumber || !checkIfSecialCharacter) && (React.createElement(FormFieldErrorMessageText, { children: React.createElement(React.Fragment, null), name: "password" }))));
};
