import { ExpansionPanel } from '@jelmoli/ui-components/src/atoms/ExpansionPanel/ExpansionPanel';
import { ExpansionPanelDetails } from '@jelmoli/ui-components/src/atoms/ExpansionPanelDetails/ExpansionPanelDetails';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import classnames from 'classnames';
import React, { useState } from 'react';
import './additional-details.scss';
export var AdditionalDetails = function (props) {
    return (React.createElement(React.Fragment, null, props.attributeGroups &&
        props.attributeGroups.length > 0 &&
        props.attributeGroups.map(function (group, index) { return (React.createElement(React.Fragment, { key: index },
            React.createElement(Panel, { panel: group, variant: props.variant, isProductDescriptionDefined: props.isProductDescriptionDefined }))); })));
};
var Panel = function (_a) {
    var panel = _a.panel, variant = _a.variant, isProductDescriptionDefined = _a.isProductDescriptionDefined;
    var listEnumNames = variant.attributesEnum ? Object.keys(variant.attributesEnum) : [];
    var listTextNames = variant.attributesText ? Object.keys(variant.attributesText) : [];
    var listValueNames = variant.attributesValue ? Object.keys(variant.attributesValue) : [];
    var _b = useState(panel.collapsed), isExpaned = _b[0], setIsExpanded = _b[1];
    var foundEnum = panel.valueKeys.some(function (key) { return listEnumNames.includes(key) || listTextNames.includes(key) || listValueNames.includes(key); });
    var foundValues = panel.valueKeys.some(function (key) {
        if (variant.attributesValue && variant.attributesValue[key] && variant.attributesValue[key].values.length > 0) {
            return variant.attributesValue[key].values.some(function (value) { return value.displayName !== ''; });
        }
        return false;
    });
    var foundTextValues = panel.valueKeys.some(function (key) {
        if (variant.attributesText && variant.attributesText[key] && variant.attributesText[key].values.length > 0) {
            return variant.attributesText[key].values.some(function (value) { return value.displayName !== ''; });
        }
        return false;
    });
    var foundEnumValues = panel.valueKeys.some(function (key) {
        if (variant.attributesEnum && variant.attributesEnum[key] && variant.attributesEnum[key].values.length > 0) {
            return variant.attributesEnum[key].values.some(function (value) { return value.displayName !== ''; });
        }
        return false;
    });
    // Don't show panel if there is no content for it
    if (!foundEnum) {
        return null;
    }
    // Show panel that have mached values and defined displayName
    if (!foundValues && !foundTextValues && !foundEnumValues) {
        return null;
    }
    return (React.createElement(ExpansionPanel, { summary: {
            text: panel.groupTitle,
            type: 'regular',
            size: 'l',
            typeExpanded: 'medium',
            tag: isProductDescriptionDefined ? 'h3' : 'h2',
        }, expanded: isExpaned, onChange: function () { return setIsExpanded(!isExpaned); } },
        React.createElement(ExpansionPanelDetails, null,
            React.createElement("div", { className: classnames('feat-additional-details__wrapper', {
                    'feat-additional-details__wrapper--one': panel.valueKeys.length === 1,
                }) }, panel.valueKeys.map(function (value, i) { return (React.createElement(React.Fragment, { key: i },
                listEnumNames.indexOf(value) !== -1 &&
                    variant.attributesEnum &&
                    variant.attributesEnum[value].values.some(function (item) { return item.displayName.length; }) && (React.createElement(DetailItem, { item: variant.attributesEnum[value] })),
                listTextNames.indexOf(value) !== -1 &&
                    variant.attributesText &&
                    variant.attributesText[value].values.some(function (item) { return item.displayName.length; }) && (React.createElement(DetailItem, { item: variant.attributesText[value] })),
                listValueNames.indexOf(value) !== -1 &&
                    variant.attributesValue &&
                    !!variant.attributesValue[value].values.length &&
                    variant.attributesValue[value].values.some(function (item) { return item.attributeValue; }) && (React.createElement(DetailItem, { item: variant.attributesValue[value] })))); })))));
};
var DetailItem = function (_a) {
    var item = _a.item;
    return (React.createElement("div", { className: "feat-additional-details__item" },
        React.createElement(Typography, { size: "l" },
            item.displayName,
            ": "),
        item.values.map(function (val, index) { return (React.createElement("span", { key: val.displayName },
            React.createElement(Typography, { size: "l" },
                val.attributeValue && " " + val.attributeValue,
                val.attributeUnit,
                val.displayName && " " + val.displayName,
                item.values.length - 1 !== index && ', '))); })));
};
