var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { useIsVisibleInViewportEffect } from '@jelmoli/hooks/src/useKeyboardEffect/useIsVisibleInViewport/useIsVisibleInViewport';
import { bindTranslations } from '@jelmoli/i18n';
import { Bag } from '@jelmoli/ui-components/src/atoms/Icon/Bag';
import { Modal } from '@jelmoli/ui-components/src/atoms/Modal/Modal';
import { PrimaryButton } from '@jelmoli/ui-components/src/atoms/PrimaryButton/PrimaryButton';
import { TextLink } from '@jelmoli/ui-components/src/atoms/TextLink/TextLink';
import { preventOutline } from '@jelmoli/ui-components/src/helpers/A/A';
import { BaseImage } from '@jelmoli/ui-components/src/helpers/BaseImage/BaseImage';
import { HorizontalSpacer } from '@jelmoli/ui-components/src/helpers/HorizontalSpacer/HorizontalSpacer';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { useZyseMeQuestionnaire } from '@jelmoli/ui-components/src/organism/ZyseMe/ZyseMe';
import React, { useEffect, useMemo, useState } from 'react';
import { addZyseMeToCart } from '../AddToCartFunctions/AddToCartFunctions';
import './e-commerce-box.scss';
import { ECommerceBoxSizePicker } from './ECommerceBoxParts/ECommerceBoxSizePicker';
import { ECommerceBoxTop } from './ECommerceBoxParts/ECommerceBoxTop';
import { ECommerceBoxColorPicker } from './ECommerceBoxParts/ECommereceBoxColorPicker';
export var ECommerceBoxZyseMe = function (_a) {
    var product = _a.data, quantityValue = _a.quantityValue, 
    /** handle if the ECommerce PrimaryButton appears/disapears */
    onButtonVisiblityChange = _a.onButtonVisiblityChange, onClickScrollChange = _a.onClickScrollChange, onAddedToCart = _a.onAddedToCart, updateQuantityValue = _a.updateQuantityValue, onSelectedGarmentUnitIdChange = _a.onSelectedGarmentUnitIdChange;
    var sizeSwitch = useComputedSizeSwitch(product);
    var ref = useIsVisibleInViewportEffect(function (isVisible) {
        onButtonVisiblityChange(!isVisible);
    });
    var openZyseMeQuestionnaire = useZyseMeQuestionnaire();
    // Wether the dropdown shows a size
    // undefined - none
    // default - a standard size
    // zyseMe - a customized size
    var _b = useState('Default'), selectedSizeType = _b[0], setSelectedSizeType = _b[1];
    // The id for a customized zyseMe product
    var _c = useState(), customizedGarmentUnitId = _c[0], setCustomoizedGarmentUnitId = _c[1];
    // A product can only be reserved if it is not a zyseMe (customizable size) product
    var sizeIndex = sizeSwitch.findIndex(function (size) {
        return size.variantId === product.variant.variantId && (size.type || 'Default') === (selectedSizeType || 'Default');
    });
    var availability = sizeIndex > -1 ? sizeSwitch[sizeIndex].availability : Infinity;
    var productZyseMeDetails = product.variant.zyseMe;
    useEffect(function () {
        onSelectedGarmentUnitIdChange(selectedSizeType === 'ZyseMe' ? customizedGarmentUnitId : undefined);
    }, [selectedSizeType, customizedGarmentUnitId]);
    if (!product.labels) {
        return null;
    }
    var t = bindTranslations(product.labels);
    var variantPrice = selectedSizeType === 'Default' || !productZyseMeDetails
        ? {
            currentPrice: product.variant.currentPrice,
            originalPrice: product.variant.originalPrice,
        }
        : {
            currentPrice: productZyseMeDetails.price,
            originalPrice: undefined,
        };
    return (React.createElement(React.Fragment, null,
        React.createElement(VerticalSpacer, { size: "xs" }),
        React.createElement(ECommerceBoxTop, { variant: __assign(__assign({}, product.variant), variantPrice) }),
        React.createElement(VerticalSpacer, { size: "mx" }),
        React.createElement(ECommerceBoxColorPicker, { colors: product.colorSwitch, selectedVariant: product.variant.variantId || '' }),
        productZyseMeDetails && productZyseMeDetails.available && (React.createElement(ZyseMeBox, { variantPrice: variantPrice.currentPrice, labels: product.labels, isCustomSizeSelected: selectedSizeType === 'ZyseMe', onCustomizationClick: function () {
                openZyseMeQuestionnaire(productZyseMeDetails.garmentModelId).then(function (id) {
                    // If no id was chosen try to keep the previous
                    if (customizedGarmentUnitId && id === undefined) {
                        return;
                    }
                    // Set the new id (can also be undefined)
                    setCustomoizedGarmentUnitId(id);
                    setSelectedSizeType(id ? 'ZyseMe' : 'Default');
                });
            } })),
        product.sizeChartUrl && (React.createElement(ECommerceBoxSizeTable, { gender: product.gender, sizeChartUrl: product.sizeChartUrl, labels: product.labels })),
        React.createElement(ECommerceBoxSizePicker, { selectedSizeType: selectedSizeType, sizeSwitch: sizeSwitch, sizeIndex: sizeIndex, setCurrentSelectedSizeType: function (newSizeType) {
                setSelectedSizeType(product.variant.pdpType);
                if (newSizeType === 'ZyseMe' && productZyseMeDetails && productZyseMeDetails.available) {
                    // Set back to default until questionaire completed
                    setSelectedSizeType('Default');
                    openZyseMeQuestionnaire(productZyseMeDetails.garmentModelId).then(function (id) {
                        setCustomoizedGarmentUnitId(id);
                        setSelectedSizeType(id ? 'ZyseMe' : 'Default');
                    });
                }
            }, availableLabel: function (availableQuantity) {
                if (availableQuantity > 0) {
                    if (availableQuantity <= 5) {
                        return t('ProductDetailLowInStockLabel', { number: availableQuantity });
                    }
                    return '';
                }
                else {
                    return t('ProductDetailNotAvailableLabel');
                }
            }, sizeLabel: t('ProductDetailSizeLabel'), quantityValue: quantityValue, updateQuantityValue: function (quantity) { return updateQuantityValue(quantity); }, availability: availability }),
        React.createElement(VerticalSpacer, { size: "m" }),
        React.createElement(PrimaryButton, { type: "button", uppercase: true, text: t('ProductDetailBasketLabel'), icon: React.createElement(Bag, { width: 16 }), fullWidth: true, fontResponsive: false, onClick: function () {
                if (quantityValue === 0 || !customizedGarmentUnitId || !productZyseMeDetails) {
                    return;
                }
                return addZyseMeToCart(productZyseMeDetails.productReference, product.variant.baseId, product.variant.variantId, productZyseMeDetails.garmentModelId, customizedGarmentUnitId, quantityValue, onAddedToCart);
            }, disabled: availability === 0 }),
        onButtonVisiblityChange && React.createElement("div", { ref: ref }),
        React.createElement(VerticalSpacer, { size: "m" }),
        React.createElement("div", { className: "o-ecommerce-box__zyseme-label-wrapper" },
            React.createElement("span", { className: "o-ecommerce-box__zyseme-icon--flag" }),
            React.createElement(HorizontalSpacer, { size: "xxs", responsive: false }),
            React.createElement(Typography, { size: "xxs", responsive: false }, t('ZyseMeDeliveryNoteLabel'))),
        React.createElement(VerticalSpacer, { size: "m" }),
        React.createElement("button", __assign({ className: "o-ecommerce-box__more-info", onClick: function () { return onClickScrollChange(true); } }, preventOutline()),
            React.createElement("span", { className: "o-ecommerce-box__more-info-text" },
                React.createElement(Typography, { size: "xs", responsive: false }, t('ProductDetailMoreInformationLabel')))),
        React.createElement(VerticalSpacer, { hide: ['xs', 'sm'], size: "l" }),
        React.createElement(VerticalSpacer, { show: ['xs', 'sm'], size: "xl" })));
};
var ZyseMeBox = function (_a) {
    var onCustomizationClick = _a.onCustomizationClick, isCustomSizeSelected = _a.isCustomSizeSelected, labels = _a.labels, variantPrice = _a.variantPrice;
    var t = bindTranslations(labels);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "o-ecommerce-box__zyseme" },
            React.createElement(Typography, { type: "regular", size: "xxs" }, !isCustomSizeSelected
                ? t('ZyseMeDescription', { price: variantPrice })
                : labels.ZyseMeDescriptionFinished),
            React.createElement(VerticalSpacer, { size: "xxs" }),
            React.createElement(Typography, { type: "medium", size: "xs" },
                React.createElement(TextLink, { "aria-label": "zyseme link", underlined: true, onClick: onCustomizationClick }, !isCustomSizeSelected ? labels.ZyseMeLinkText : labels.ZyseMeLinkTextFinished))),
        React.createElement(VerticalSpacer, { size: "m" })));
};
/**
 * Backend is delivering the real sizes and the size customization options (zyseMe) in two different settings
 * this helper merges both into one sizeSwitch array for easier rendering
 */
var useComputedSizeSwitch = function (product) {
    var initialSizeSwitches = product.sizeSwitch || [];
    var zyseMe = product.variant.zyseMe;
    var initialProductSizeIndex = useMemo(function () { return initialSizeSwitches.findIndex(function (size) { return size.variantId === product.variant.variantId; }); }, [initialSizeSwitches, product.variant.variantId]);
    var initialSelectedSizeSwitch = initialSizeSwitches[initialProductSizeIndex] || initialSizeSwitches[0];
    // If zyseMe is available return a sizeSwitch list with a zyseMeSwitch
    var sizeSwitch = useMemo(function () {
        if (zyseMe && !zyseMe.available) {
            return initialSizeSwitches;
        }
        var zyseMeSizeSwitch = __assign(__assign({ variantId: '' }, (initialSelectedSizeSwitch || {})), { type: 'ZyseMe', url: '', size: product.labels ? product.labels.ZyseMeCustomSizeDropDownLabel : '', statisticSize: '', availability: (zyseMe && zyseMe.availability) || -1 });
        return __spreadArrays([zyseMeSizeSwitch], initialSizeSwitches);
    }, [initialProductSizeIndex, initialSizeSwitches, zyseMe && zyseMe.available]);
    return sizeSwitch;
};
var ECommerceBoxSizeTable = function (_a) {
    var gender = _a.gender, sizeChartUrl = _a.sizeChartUrl, labels = _a.labels;
    var _b = useState(false), modalOpen = _b[0], setModalOpen = _b[1];
    var t = bindTranslations(labels);
    return (React.createElement(React.Fragment, null,
        React.createElement("span", null,
            React.createElement(TextLink, { underlined: true, isButton: true, onClick: function () { return setModalOpen(true); } },
                React.createElement(Typography, { size: "xxs" }, t('ProductDetailSizeTableLabel')))),
        React.createElement(VerticalSpacer, { size: "xs" }),
        React.createElement(Modal, { open: modalOpen, title: t('ProductDetailSizeTableTitleLabel', { gender: gender }), onClose: function () { return setModalOpen(false); }, fullWidth: true },
            React.createElement("div", { className: "o-ecommerce-box__size-table-wrapper" },
                React.createElement(BaseImage, { editable: false, value: {
                        src: sizeChartUrl,
                        alt: t('ProductDetailSizeTableTitleLabel', { gender: gender }) || '',
                    }, className: "o-ecommerce-box__size-table-image" })))));
};
