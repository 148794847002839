var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FormValidationContext } from 'nested-form-validation';
import React, { useMemo } from 'react';
import { FormFieldErrorMessage } from '../../atoms/FormFieldErrorMessage/FormFieldErrorMessage';
import { RadioButtons } from '../../atoms/RadioButtons/RadioButtons';
import { RadioButtonsValidable } from '../../atoms/RadioButtonsValidatable/RadioButtonsValidatable';
import { TextFieldWithFormSetting } from '../../atoms/TextFieldValidatable/TextFieldValidatable';
import { VerticalSpacer } from '../../helpers/VerticalSpacer/VerticalSpacer';
/**
 * This form allows the user to enter:
 * - salutation
 * - firstName
 * - lastName
 */
export var NameForm = function (_a) {
    var fields = _a.fields, fieldValues = _a.fieldValues;
    // If there are 2 radio buttons group with the same name, the keyboard doesnt register second one if first one is selected:
    // "If you choose a radio item (anrede) in the invoice-address section, it's not possible to focus the radio-list in the delivery-address section by keyboard"
    var salutationName = useMemo(randomId, []);
    return (React.createElement(FormValidationContext, null,
        fields.SalutationSource && fields.SalutationSource.length > 0 && (React.createElement(React.Fragment, null,
            React.createElement(RadioButtonsValidable, __assign({ name: salutationName, row: true, errormsg: fields.Salutation.fields.IsRequiredError && fields.Salutation.fields.IsRequiredError.value }, fieldValues.salutation), fields.SalutationSource.map(function (item) { return (React.createElement(RadioButtons, { value: item.fields.ID.value, key: item.fields.ID.value }, item.fields.Name.value)); })),
            React.createElement(FormFieldErrorMessage, { name: salutationName, verticalSpaceTop: "xxs" }),
            React.createElement(VerticalSpacer, { size: "mx", responsive: false }))),
        React.createElement(TextFieldWithFormSetting, __assign({ variant: "standard", name: "firstName", autoComplete: "given-name", formSetting: fields.FirstName }, fieldValues.firstName, { fullWidth: true })),
        React.createElement(FormFieldErrorMessage, { name: "firstName", verticalSpaceTop: "xxs" }),
        React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
        React.createElement(TextFieldWithFormSetting, __assign({ variant: "standard", name: "lastName", autoComplete: "family-name", formSetting: fields.LastName }, fieldValues.lastName, { fullWidth: true })),
        React.createElement(FormFieldErrorMessage, { name: "lastName", verticalSpaceTop: "xxs" })));
};
var randomId = function () {
    return "salutation-" + (typeof window === 'undefined' ? 'BE' : 'FE') + String(Math.ceil(Math.random() * 10000));
};
