var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { bindTranslations } from '@jelmoli/i18n/index';
import { DeliveryOptionConstants, getDeliveryOptions, } from '@jelmoli/sitecore-services/src/Cart/getDeliveryOptions/getDeliveryOptions';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import React from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
export var ConfirmationComponentDeliveryOptionSection = function (_a) {
    var fields = _a.fields;
    var isAnyProductBulky = fields.order.lines.some(function (line) { return line.fulfillmentFlags && line.fulfillmentFlags.bulky; });
    var isInstallationServiceSelected = fields.order.lines.some(function (line) {
        return (line.fulfillmentFlags && line.fulfillmentFlags.installationServiceMandatory) ||
            (line.fulfillmentFlags && line.fulfillmentFlags.installationServiceSelected);
    });
    var isDigital = fields.order.deliveryOption &&
        fields.order.deliveryOption.deliveryOptionExternalId === DeliveryOptionConstants.digital;
    // Trigger Api key to retrieve delivery method name only for saved bulky, installation service or digital as delivery options
    var deliveryOptions = useGetEndPointLazy(getDeliveryOptions, {}, Boolean(isAnyProductBulky || isInstallationServiceSelected || isDigital)).value;
    var homeDeliveryOption = deliveryOptions && deliveryOptions.filter(function (option) { return option.externalId === DeliveryOptionConstants.home; })[0];
    var digitalDeliveryOption = deliveryOptions && deliveryOptions.filter(function (option) { return option.externalId === DeliveryOptionConstants.digital; })[0];
    var email = fields.order.orderInformation && fields.order.orderInformation.email;
    return (React.createElement(React.Fragment, null,
        React.createElement(Typography, { size: "s", type: "medium", tag: "p", responsive: false }, fields.labels.CheckoutStepperDeliveryOptionsStepTitle),
        fields.order.deliveryOption && !isInstallationServiceSelected && !isAnyProductBulky && (React.createElement(DeliveryOption, __assign({ deliveryOption: fields.order.deliveryOption, labels: fields.labels }, (digitalDeliveryOption && {
            digital: {
                label: digitalDeliveryOption.name,
                email: email || '',
            },
        })))),
        (isInstallationServiceSelected || isAnyProductBulky) && (React.createElement(React.Fragment, null,
            homeDeliveryOption && (React.createElement(Typography, { tag: "p", size: "s", responsive: false }, homeDeliveryOption.name)),
            React.createElement(Typography, { tag: "p", size: "s", responsive: false }, fields.order.deliveryOption && 'shippingMethodDisplayName' in fields.order.deliveryOption ? (React.createElement(React.Fragment, null, fields.order.deliveryOption.shippingMethodDisplayName)) : ('')),
            fields.order.deliveryOption &&
                'installationServicePhoneNumber' in fields.order.deliveryOption &&
                fields.order.deliveryOption.installationServicePhoneNumber && (React.createElement(Typography, { tag: "p", size: "s", responsive: false }, fields.order.deliveryOption.installationServicePhoneNumber))))));
};
var DeliveryOption = function (_a) {
    var deliveryOption = _a.deliveryOption, labels = _a.labels, digital = _a.digital;
    var t = bindTranslations(labels);
    switch (deliveryOption.deliveryOptionExternalId) {
        case DeliveryOptionConstants.home:
            return (React.createElement(React.Fragment, null,
                React.createElement(Typography, { tag: "p", size: "s", responsive: false }, deliveryOption && 'shippingMethodDisplayName' in deliveryOption ? (React.createElement(React.Fragment, null,
                    t('CheckoutSummaryShippingLabel'),
                    ": ",
                    deliveryOption.shippingMethodDisplayName)) : ('')),
                deliveryOption.scheduledDeliveryTimeslotId && (React.createElement(Typography, { tag: "p", size: "s", responsive: false },
                    t('CheckoutStepperDeliveryOptionsTimeTitle'),
                    ": ",
                    deliveryOption.scheduledDeliveryDay,
                    ', ',
                    deliveryOption.scheduledDeliveryTimeslotName)),
                React.createElement(Typography, { tag: "p", size: "s", responsive: false }, deliveryOption && 'shippingMethodDisplayName' in deliveryOption ? (React.createElement(React.Fragment, null,
                    t('CheckoutSummaryPackingLabel'),
                    ": ",
                    deliveryOption.packagingOptionDisplayName)) : (''))));
        case DeliveryOptionConstants.post:
            return (React.createElement(React.Fragment, null,
                React.createElement(Typography, { size: "s", responsive: false, tag: "p" },
                    deliveryOption.shippingMethodDisplayName,
                    " ",
                    deliveryOption.userId),
                React.createElement(Typography, { size: "s", responsive: false, tag: "p" },
                    deliveryOption.address.street,
                    ", ",
                    deliveryOption.address.zip,
                    " ",
                    deliveryOption.address.city),
                React.createElement(Typography, { size: "s", responsive: false, tag: "p" },
                    t('CheckoutSummaryPackingLabel'),
                    ": ",
                    deliveryOption.packagingOptionDisplayName)));
        case DeliveryOptionConstants.pickup:
            return (React.createElement(React.Fragment, null,
                React.createElement(Typography, { tag: "p", size: "s", responsive: false }, deliveryOption.shippingMethodDisplayName),
                React.createElement(Typography, { tag: "p", size: "s", responsive: false }, deliveryOption.locationDisplayName)));
        case DeliveryOptionConstants.digital:
            if (!digital) {
                return null;
            }
            return (React.createElement(React.Fragment, null,
                React.createElement(Typography, { tag: "p", size: "s", responsive: false }, digital.label),
                React.createElement(Typography, { tag: "p", size: "s", responsive: false }, digital.email)));
        default:
            return null;
    }
};
