import { getCart } from '@jelmoli/sitecore-services/src/Cart/getCart/getCart';
import { DeliveryOptionConstants, getDeliveryOptions, } from '@jelmoli/sitecore-services/src/Cart/getDeliveryOptions/getDeliveryOptions';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import React from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
import { useCheckoutStepperState } from '../CheckoutUrlStateStore';
export var CheckoutStepDeliveryOptionsSummary = function (_a) {
    var deliveryTitle = _a.deliveryTitle, packagingTitle = _a.packagingTitle, deliveryTimeTitle = _a.deliveryTimeTitle;
    var getCartData = useGetEndPointLazy(getCart).value;
    var isInstallationServiceSellected = getCartData &&
        getCartData.lines.some(function (line) {
            return (line.fulfillmentFlags && line.fulfillmentFlags.installationServiceMandatory) ||
                (line.fulfillmentFlags && line.fulfillmentFlags.installationServiceSelected);
        });
    var isBulky = getCartData && getCartData.lines.some(function (line) { return line.fulfillmentFlags && line.fulfillmentFlags.bulky; });
    var isDigital = getCartData &&
        getCartData.deliveryOption &&
        getCartData.deliveryOption.deliveryOptionExternalId === DeliveryOptionConstants.digital;
    // Trigger Api key to retrieve delivery method name only for saved bulky, installation service or digital as delivery options
    var deliveryOptions = useGetEndPointLazy(getDeliveryOptions, {}, Boolean(isBulky || isInstallationServiceSellected || isDigital)).value;
    var checkoutProgressState = useCheckoutStepperState();
    if (!getCartData ||
        !getCartData.deliveryOption ||
        (checkoutProgressState && checkoutProgressState.stepStates.deliveryOption === false)) {
        return null;
    }
    var deliveryOptionFromData = getCartData.deliveryOption;
    var homeDeliveryOption = deliveryOptions && deliveryOptions.filter(function (option) { return option.externalId === DeliveryOptionConstants.home; })[0];
    var digitalDeliveryOption = deliveryOptions && deliveryOptions.filter(function (option) { return option.externalId === DeliveryOptionConstants.digital; })[0];
    switch (deliveryOptionFromData.deliveryOptionExternalId) {
        case DeliveryOptionConstants.home:
            return (React.createElement("div", { className: "feat-checkout-stepper__delivery-options-summary" },
                !isBulky && !isInstallationServiceSellected && (React.createElement(React.Fragment, null,
                    React.createElement(Typography, { size: "s", responsive: false, tag: "p" },
                        deliveryTitle,
                        ": ",
                        deliveryOptionFromData.shippingMethodDisplayName),
                    deliveryOptionFromData.scheduledDeliveryTimeslotId && (React.createElement(Typography, { size: "s", responsive: false, tag: "p" },
                        deliveryTimeTitle,
                        ": ",
                        deliveryOptionFromData.scheduledDeliveryDay,
                        ', ',
                        deliveryOptionFromData.scheduledDeliveryTimeslotName)),
                    React.createElement(Typography, { size: "s", responsive: false, tag: "p" },
                        packagingTitle,
                        ": ",
                        deliveryOptionFromData.packagingOptionDisplayName))),
                (isBulky || isInstallationServiceSellected) && (React.createElement(React.Fragment, null,
                    homeDeliveryOption && (React.createElement(Typography, { size: "s", responsive: false, tag: "p" }, homeDeliveryOption.name)),
                    React.createElement(Typography, { size: "s", responsive: false, tag: "p" }, deliveryOptionFromData.shippingMethodDisplayName),
                    deliveryOptionFromData.installationServicePhoneNumber && (React.createElement(Typography, { size: "s", responsive: false, tag: "p" }, deliveryOptionFromData.installationServicePhoneNumber)))),
                React.createElement(VerticalSpacer, { size: "l", responsive: false })));
        case DeliveryOptionConstants.post:
            return (React.createElement("div", { className: "feat-checkout-stepper__delivery-options-summary" },
                React.createElement(Typography, { size: "s", responsive: false, tag: "p" },
                    deliveryOptionFromData.shippingMethodDisplayName,
                    " ",
                    deliveryOptionFromData.userId),
                React.createElement(Typography, { size: "s", responsive: false, tag: "p" },
                    deliveryOptionFromData.address.street,
                    ", ",
                    deliveryOptionFromData.address.zip,
                    ' ',
                    deliveryOptionFromData.address.city),
                React.createElement(Typography, { size: "s", responsive: false, tag: "p" },
                    packagingTitle,
                    ": ",
                    deliveryOptionFromData.packagingOptionDisplayName),
                React.createElement(VerticalSpacer, { size: "l", responsive: false })));
        case DeliveryOptionConstants.pickup:
            return (React.createElement("div", { className: "feat-checkout-stepper__delivery-options-summary" },
                React.createElement(Typography, { size: "s", responsive: false, tag: "p" }, deliveryOptionFromData.shippingMethodDisplayName),
                React.createElement(Typography, { size: "s", responsive: false, tag: "p" }, deliveryOptionFromData.locationDisplayName),
                React.createElement(VerticalSpacer, { size: "l", responsive: false })));
        case DeliveryOptionConstants.digital:
            return (React.createElement("div", { className: "feat-checkout-stepper__delivery-options-summary" },
                digitalDeliveryOption && (React.createElement(Typography, { size: "s", responsive: false, tag: "p" }, digitalDeliveryOption.name)),
                getCartData.orderInformation && (React.createElement(Typography, { size: "s", responsive: false, tag: "p" }, getCartData.orderInformation.email)),
                React.createElement(VerticalSpacer, { size: "l", responsive: false })));
        default:
            return null;
    }
};
