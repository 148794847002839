import { getCart } from '@jelmoli/sitecore-services/src/Cart/getCart/getCart';
import { DeliveryOptionConstants, getDeliveryOptions, } from '@jelmoli/sitecore-services/src/Cart/getDeliveryOptions/getDeliveryOptions';
import { Tab } from '@jelmoli/ui-components/src/atoms/Tabs/Tab';
import { Tabs } from '@jelmoli/ui-components/src/atoms/Tabs/Tabs';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import React, { useEffect, useMemo, useState } from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
import './checkout-step-delivery-options.scss';
import { CheckoutDeliveryOptionButton } from './CheckoutDeliveryOptionButton';
import { CheckoutStepDeliveryOptionsDigital } from './CheckoutStepDeliveryOptionsDigital/CheckoutStepDeliveryOptionsDigital';
import { CheckoutStepDeliveryOptionsHome } from './CheckoutStepDeliveryOptionsHome/CheckoutStepDeliveryOptionsHome';
import { CheckoutStepDeliveryOptionsPickup } from './CheckoutStepDeliveryOptionsPickup/CheckoutStepDeliveryOptionsPickup';
import { CheckoutStepDeliveryOptionsPost } from './CheckoutStepDeliveryOptionsPost/CheckoutStepDeliveryOptionsPost';
export var CheckoutStepDeliveryOptions = function (props) {
    var deliveryOptions = useGetEndPointLazy(getDeliveryOptions).value;
    var cartData = useGetEndPointLazy(getCart).value;
    var _a = useState(), tabSelectedByUser = _a[0], setTabSelected = _a[1];
    var firstAvailableOptionId = useMemo(function () {
        var firstOption = (deliveryOptions || []).find(function (option) { return option.available; });
        return firstOption ? firstOption.externalId : undefined;
    }, [deliveryOptions]);
    useEffect(function () {
        if (!cartData || !cartData.deliveryOption) {
            return;
        }
        setTabSelected(cartData.deliveryOption.deliveryOptionExternalId);
    }, [cartData]);
    var tabSelected = tabSelectedByUser || firstAvailableOptionId;
    if (!deliveryOptions) {
        return null;
    }
    var availableDeliveryOptions = deliveryOptions.filter(function (option) { return option.available; });
    return (React.createElement(React.Fragment, null,
        React.createElement(Tabs, { justifyItems: "justified", value: tabSelected, onChange: function (event, value) {
                setTabSelected(value);
            }, variant: "scrollable", singleTab: availableDeliveryOptions.length === 1 }, availableDeliveryOptions.map(function (deliveryOption) { return (React.createElement(Tab, { key: deliveryOption.externalId, value: deliveryOption.externalId, disabled: !deliveryOption.available, label: React.createElement(CheckoutDeliveryOptionButton, { id: deliveryOption.externalId, disabled: !deliveryOption.available, checked: tabSelected === deliveryOption.externalId }, deliveryOption.name) })); })),
        React.createElement(VerticalSpacer, { size: "xl", responsive: false }),
        React.createElement(CheckoutStepDeliveryOptionsHome, { onComplete: props.onComplete, visible: tabSelected === DeliveryOptionConstants.home, zipCode: getZipCode(cartData) }),
        React.createElement(CheckoutStepDeliveryOptionsPost, { onComplete: props.onComplete, visible: tabSelected === DeliveryOptionConstants.post }),
        React.createElement(CheckoutStepDeliveryOptionsPickup, { onComplete: props.onComplete, visible: tabSelected === DeliveryOptionConstants.pickup }),
        React.createElement(CheckoutStepDeliveryOptionsDigital, { onComplete: props.onComplete, visible: tabSelected === DeliveryOptionConstants.digital }),
        React.createElement(VerticalSpacer, { size: "xl", responsive: false })));
};
/** This function returns zip code from the cart based on user's choice from the previous step */
function getZipCode(cartData) {
    if (!cartData || !cartData.orderInformation) {
        return '';
    }
    return !cartData.orderInformation.sameAsBillingAddress && cartData.orderInformation.shippingAddress
        ? cartData.orderInformation.shippingAddress.zipPostalCode
        : cartData.orderInformation.billingAddress.zipPostalCode;
}
