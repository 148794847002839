var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { HeaderLinkOverlay } from '@jelmoli/ui-components/src/organism/HeaderLinkOverlay/HeaderLinkOverlay';
import { Link } from '@sitecore-jss/sitecore-jss-react';
import React, { useState } from 'react';
import './external-link.scss';
export var HeaderLinkComponent = function (props) {
    var _a = useState(false), isOverlayOpen = _a[0], setIsOverlayOpen = _a[1];
    return (React.createElement("div", { className: "feat-header__link" },
        React.createElement(HeaderLinkOverlay, __assign({ open: isOverlayOpen, onClose: function () { return setIsOverlayOpen(false); } }, props.fields)),
        React.createElement("div", { className: "feat-header__link-effect" },
            React.createElement(Typography, { type: "regular", size: "xs", responsive: false },
                React.createElement(Link, __assign({ className: "feat-header__link-item", field: props.fields.HeaderLink }, (props.fields.HeaderLink.value.rel && {
                    rel: "noopener noreferrer " + props.fields.HeaderLink.value.rel,
                }), { editable: false, onClick: function (event) {
                        event.preventDefault();
                        setIsOverlayOpen(true);
                    } }))))));
};
