var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useFormValidation } from 'nested-form-validation';
import React from 'react';
import { RadioButtonsGroup } from '../RadioButtonsGroup/RadioButtonsGroup';
export var RadioButtonsValidable = function (props) {
    var validator = function (value) {
        if (props.errormsg && !value) {
            return props.errormsg;
        }
    };
    var _a = useFormValidation(props.name, props.value, validator), errorMessage = _a[0], touched = _a[1];
    var hasVisibleError = touched && errorMessage !== undefined;
    var children = React.Children.map(props.children, function (child, index) {
        if (child === null) {
            return;
        }
        return React.cloneElement(child, {
            index: index,
            error: hasVisibleError ? 1 : 0,
        });
    });
    return React.createElement(RadioButtonsGroup, __assign({}, props), children);
};
