var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { default as MUIFormControlLabel } from '@material-ui/core/FormControlLabel';
import { default as MUIRadio } from '@material-ui/core/Radio';
import classnames from 'classnames';
import React from 'react';
import './radio-buttons.scss';
export var RadioButtons = function (_a) {
    var children = _a.children, error = _a.error, type = _a.type, props = __rest(_a, ["children", "error", "type"]);
    return (React.createElement(MUIFormControlLabel, { label: children, control: React.createElement(MUIRadio, __assign({ classes: {
                root: 'a-radio-button__input-wrapper',
                checked: 'a-radio-button__input-wrapper--checked',
                disabled: 'a-radio-button__input-wrapper--disabled',
            } }, props, { icon: React.createElement("span", { className: "a-radio-button__input" }), checkedIcon: React.createElement("span", { className: "a-radio-button__input--checked" }), className: classnames(error && 'a-radio-button__input-wrapper--error') })), value: props.value, classes: {
            root: classnames('a-radio-button', type && "a-radio-button--" + type),
            disabled: 'a-radio-button--disabled',
            label: classnames('a-radio-button__label', type && "a-radio-button__label--" + type),
        }, disabled: props.disabled }));
};
