import { track } from '@jelmoli/google-tag-manager';
//There are no 2 separate attributes that would serve as item and category,
//so i am using name on both places in case we need to follow snippet structure line by line
export function trackAddTopic(interest_category, interest_item) {
    track({
        event: 'gaEvent',
        event_name: 'interest_preferences',
        interest_preferences: {
            interest_item: interest_item ? interest_item : undefined,
            interest_category: interest_category,
            status: 'add',
            event_category: 'account',
            event_action: 'interest_preference',
            event_label: 'add',
            brand_name: '',
        },
    });
}
export function trackRemoveTopic(interest_category, interest_item) {
    track({
        event: 'gaEvent',
        event_name: 'interest_preferences',
        interest_preferences: {
            interest_item: interest_item ? interest_item : undefined,
            interest_category: interest_category,
            status: 'remove',
            event_category: 'account',
            event_action: 'interest_preference',
            event_label: 'remove',
            brand_name: undefined,
        },
    });
}
export function trackAddBrand(name) {
    track({
        event: 'gaEvent',
        event_name: 'brand_preferences',
        brand_preferences: {
            brand_name: name,
            status: 'add',
            event_category: 'account',
            event_action: 'brand_preferences',
            event_label: 'add',
        },
    });
}
export function trackRemoveBrand(name) {
    track({
        event: 'gaEvent',
        event_name: 'brand_preferences',
        brand_preferences: {
            brand_name: name,
            status: 'remove',
            event_category: 'account',
            event_action: 'brand_preferences',
            event_label: 'remove',
        },
    });
}
