var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { bindTranslations } from '@jelmoli/i18n';
import { getPackagingOptions, PackagingOptionsConstants, } from '@jelmoli/sitecore-services/src/Cart/getPackagingOptions/getPackagingOptions';
import { getCheckoutLabels } from '@jelmoli/sitecore-services/src/Checkout/getCheckoutLabels/getCheckoutLabels';
import { RadioButtons } from '@jelmoli/ui-components/src/atoms/RadioButtons/RadioButtons';
import { RadioButtonsGroup } from '@jelmoli/ui-components/src/atoms/RadioButtonsGroup/RadioButtonsGroup';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { FormValidationContext } from 'nested-form-validation';
import React from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
export var CheckoutPackagingOptions = function (_a) {
    var fields = _a.fields, isDisabled = _a.isDisabled;
    var packagingOptions = useGetEndPointLazy(getPackagingOptions).value;
    var t = bindTranslations(useGetEndPointLazy(getCheckoutLabels).value);
    if (!packagingOptions) {
        return null;
    }
    return (React.createElement(FormValidationContext, null,
        React.createElement(Typography, { size: "xs", type: "medium", responsive: false }, t('CheckoutStepperDeliveryOptionsStepPackagingTitle')),
        React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
        React.createElement(RadioButtonsGroup, __assign({ name: "packagingOptions", row: false }, fields.packagingOptions),
            packagingOptions
                .filter(function (option) { return option.externalId === PackagingOptionsConstants.standard; })
                .map(function (packagingOption) { return (React.createElement(RadioButtons, { value: packagingOption.externalId, key: packagingOption.externalId, disabled: isDisabled }, packagingOption.name)); }),
            packagingOptions
                .filter(function (option) { return option.externalId !== PackagingOptionsConstants.standard; })
                .map(function (packagingOption) { return (React.createElement(RadioButtons, { value: packagingOption.externalId, key: packagingOption.externalId, disabled: isDisabled }, packagingOption.name)); }))));
};
