var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Image } from '@jelmoli/ui-components/src/atoms/Image/Image';
import { ProductFeature } from '@jelmoli/ui-components/src/atoms/ProductFeature/ProductFeature';
import { A } from '@jelmoli/ui-components/src/helpers/A/A';
import { hoverAnchorClassName } from '@jelmoli/ui-components/src/helpers/HoverAnchor/HoverAnchor';
import { hasImageFieldValue, hasTextFieldValue, } from '@jelmoli/ui-components/src/helpers/SitecoreTypes/SitecoreTypes';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import classnames from 'classnames';
import React from 'react';
import './inspiration-tile-small.scss';
export var InspirationTileSmallComponent = function (props) {
    return props.fields.PageTeaserLink ? (React.createElement(A, { className: classnames('feat-inspiration-tile-s', hoverAnchorClassName), href: props.fields.PageTeaserLink, onClick: function () {
            if (props.savePosition) {
                props.savePosition();
            }
        } },
        React.createElement(InspirationInnerTileSmallComponent, __assign({}, props)))) : (React.createElement("div", { className: classnames('feat-inspiration-tile-s', hoverAnchorClassName) },
        React.createElement(InspirationInnerTileSmallComponent, __assign({}, props))));
};
var InspirationInnerTileSmallComponent = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "feat-inspiration-tile-s__upper-half" }, !props.slider && (React.createElement(React.Fragment, null,
            React.createElement(Typography, { size: "xs", type: "light", uppercase: true }, props.fields.PageTeaserSubtitle.value),
            React.createElement("div", { className: "feat-inspiration-tile-s__title" },
                React.createElement(Typography, { type: "bold", size: "m", field: props.fields.PageTeaserTitle })),
            React.createElement(VerticalSpacer, { size: "mx" })))),
        React.createElement("div", { className: "feat-inspiration-tile-s__lower-half" },
            hasImageFieldValue(props.fields.PageTeaserImage) && (React.createElement(Image, { value: props.fields.PageTeaserImage.value, hoverable: true })),
            props.slider && (React.createElement(React.Fragment, null,
                React.createElement(VerticalSpacer, { size: "s" }),
                React.createElement(Typography, { size: "xs", type: "light", uppercase: true }, props.fields.PageTeaserSubtitle.value),
                React.createElement("div", { className: "feat-inspiration-tile-s__title" },
                    React.createElement(Typography, { type: "bold", size: "m", field: props.fields.PageTeaserTitle })))),
            props.fields.PageTeaserLabel &&
                hasTextFieldValue(props.fields.PageTeaserLabel.fields.InspirationLabelTitle) && (React.createElement(React.Fragment, null,
                React.createElement(VerticalSpacer, { size: "s" }),
                React.createElement(ProductFeature, null, props.fields.PageTeaserLabel.fields.InspirationLabelTitle.value))),
            React.createElement(VerticalSpacer, { size: "xxxl" }))));
};
