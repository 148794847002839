var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import classnames from 'classnames';
import React from 'react';
import './container.scss';
/**
 * A simple Content Block component, with a heading and rich text block.
 * This is the most basic building block of a content site, and the most basic
 * JSS component that's useful.
 */
export var Container = function (props) {
    return props.backgroundFill ? (React.createElement("div", { className: classnames("h-container h-container--size-l h-container__wrapper h-container__wrapper--background-white", {
            'h-container--full-height': props.fullHeight,
        }) },
        React.createElement(Container, __assign({}, props, { backgroundFill: false })))) : (React.createElement("div", { className: classnames("h-container h-container--size-" + props.size, {
            'h-container--full-height': props.fullHeight,
        }) }, props.children));
};
