var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { bindTranslations } from '@jelmoli/i18n';
import { getCartLabels } from '@jelmoli/sitecore-services/src/Cart/getCartLabels/getCartLabels';
import { FormValidationContext } from 'nested-form-validation';
import React from 'react';
import { useGetEndPoint } from 'request-registry-react';
import { Checkbox } from '../../atoms/Checkbox/Checkbox';
import { FormFieldErrorMessage } from '../../atoms/FormFieldErrorMessage/FormFieldErrorMessage';
import { TextFieldWithFormSetting } from '../../atoms/TextFieldValidatable/TextFieldValidatable';
import { getFormFieldSettingsProperty } from '../../helpers/FormFieldValidator/FormFieldValidator';
import { Typography } from '../../helpers/Typography/Typography';
import { VerticalSpacer } from '../../helpers/VerticalSpacer/VerticalSpacer';
export var GiftCardForm = function (props) {
    var cartLabelsData = useGetEndPoint(getCartLabels).value;
    if (!cartLabelsData) {
        return null;
    }
    var maxCharacters = Number(getFormFieldSettingsProperty(cartLabelsData.CartGiftCard, 'MaxLength'));
    var t = bindTranslations(cartLabelsData);
    var isGiftWrappingSelected = props.formFields.giftWrappingSelected.value;
    return (React.createElement("div", null,
        React.createElement(FormValidationContext, null,
            React.createElement(Checkbox, __assign({ label: t('GiftCardAndWrappingCheckboxLabel') }, props.formFields.giftWrappingSelected)),
            React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
            React.createElement(TextFieldWithFormSetting, __assign({ disabled: !isGiftWrappingSelected, fullWidth: true, multiline: true }, props.formFields.greetingMessage, { name: "gift-card-wrapping-message", alwaysShowError: true, formSetting: cartLabelsData.CartGiftCard, helperText: React.createElement(Typography, { responsive: false, size: "xxs", "data-testid": "gift-card-char-count" },
                    isGiftWrappingSelected &&
                        props.formFields.greetingMessage.value.length === 0 &&
                        t('CardMessageMaxCharacterLimitLabel', { characterLimit: maxCharacters }),
                    isGiftWrappingSelected &&
                        props.formFields.greetingMessage.value.length > 0 &&
                        props.formFields.greetingMessage.value.length <= maxCharacters &&
                        t('CardMessageMaxCharacterRemainingLabel', {
                            characterLimit: maxCharacters - props.formFields.greetingMessage.value.length,
                        }),
                    props.formFields.greetingMessage.value.length > maxCharacters &&
                        " " + getFormFieldSettingsProperty(cartLabelsData.CartGiftCard, 'MaxLengthError'),
                    React.createElement(FormFieldErrorMessage, { variant: "plain", name: "gift-card-wrapping-message", showAlsoIfUntouched: true })) })))));
};
