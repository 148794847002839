import { bindTranslations } from '@jelmoli/i18n/index';
import { isUserLoggedInLazy } from '@jelmoli/sitecore-services/src/Account/isUserLoggedIn/isUserLoggedInLazy';
import { getClickAndReserveLabels } from '@jelmoli/sitecore-services/src/ClickAndReserve/getClickAndReserveLabels/getClickAndReserveLabels';
import { getReservationIds } from '@jelmoli/sitecore-services/src/ClickAndReserve/getReservationIds/getReservationIds';
import { getProductAvailability } from '@jelmoli/sitecore-services/src/Products/getProductAvailability/getProductAvailability';
import { HomeIcon } from '@jelmoli/ui-components/src/atoms/Icon/HomeIcon';
import { SecondaryButton } from '@jelmoli/ui-components/src/atoms/SecondaryButton/SecondaryButton';
import { HorizontalSpacer } from '@jelmoli/ui-components/src/helpers/HorizontalSpacer/HorizontalSpacer';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { ClickAndReserveDrawer } from '@jelmoli/ui-components/src/organism/ClickAndReserveDrawer/ClickAndReserveDrawer';
import React, { useEffect, useState } from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
/**
 * Display all reservation related information
 *
 * It contains two components:
 *   1: display the infomrmation regarding reservation status
 *   2: displays the option to make a reservation once size and color are selected
 */
export var ECommerceBoxReservationDetails = function (_a) {
    var productId = _a.productId, brandName = _a.brandName, productVariantId = _a.productVariantId, labels = _a.labels, isSelectedSize = _a.isSelectedSize;
    var _b = useState(false), reservationLocationReceived = _b[0], setReservationLocationReceived = _b[1];
    var clickAndReserveLabels = useGetEndPointLazy(getClickAndReserveLabels).value;
    var reservationLocations = useGetEndPointLazy(getProductAvailability, { id: productId }, isSelectedSize).value;
    var reservationIds = useGetEndPointLazy(getReservationIds, {}, isUserLoggedInLazy()).value;
    // Reservation object for the selected vatiant of the product
    // producId.toUpperCae() is there since BE is sending reservation ids as upper case and products as lower, and wants to keep it that way
    var reserverdProductVariants = (reservationIds && reservationIds.entries[productId.toUpperCase()]) || {};
    var reservationDetails = reserverdProductVariants[productVariantId];
    useEffect(function () {
        if (reservationDetails && 'reservationLocationId' in reservationDetails) {
            setReservationLocationReceived(true);
        }
    }, [reservationDetails]);
    if (!reservationDetails && isSelectedSize) {
        return (React.createElement(ReservationLocations, { productId: productId, brandName: brandName, productVariantId: productVariantId, labels: clickAndReserveLabels, reservationLocations: reservationLocations }));
    }
    var currentVariant = reservationLocations === null || reservationLocations === void 0 ? void 0 : reservationLocations.variants.find(function (variant) { return variant.variantId === productVariantId; });
    if (!reservationDetails || !('reservationZoneCode' in reservationDetails) || !currentVariant) {
        return null;
    }
    var reservationReservedLocation = currentVariant === null || currentVariant === void 0 ? void 0 : currentVariant.locations.find(function (flor) { return flor.reservationZone.reservationZoneCode === reservationDetails.reservationZoneCode; });
    var t = bindTranslations(clickAndReserveLabels);
    if (!reservationReservedLocation) {
        return null;
    }
    return (React.createElement("div", { className: "o-ecommerce-box__reservation" },
        React.createElement("div", { className: "o-ecommerce-box__icon-wrapper" },
            React.createElement(HomeIcon, { width: 14 })),
        React.createElement(HorizontalSpacer, { size: "xxs" }),
        React.createElement("div", { className: "o-ecommerce-box__reservation-locations" },
            reservationDetails.validTo ? (React.createElement(Typography, { size: "xs", type: "medium", responsive: false }, t('ClickAndReserveReservedTitle', { reservationDate: reservationDetails.validTo }))) : (React.createElement(Typography, { size: "xs", type: "medium", responsive: false }, t('ClickAndReservePendingTitle'))),
            React.createElement(Typography, { size: "xs", responsive: false },
                t('ClickAndReserveReservedSubtitle'),
                ' ',
                reservationReservedLocation.reservationZone.reservationZoneTitle),
            React.createElement(VerticalSpacer, { size: "s" }))));
};
var ReservationLocations = function (_a) {
    var productId = _a.productId, productVariantId = _a.productVariantId, labels = _a.labels, brandName = _a.brandName, reservationLocations = _a.reservationLocations;
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var locationVariants = reservationLocations
        ? reservationLocations.variants.find(function (location) { return location.variantId === productVariantId; })
        : undefined;
    if (!locationVariants ||
        locationVariants.locations.filter(function (variant) { return variant.locationInventory === 0; }).length ===
            locationVariants.locations.length ||
        locationVariants.locations.filter(function (variant) { return !('locationName' in variant) || variant.locationName === ''; })
            .length === locationVariants.locations.length) {
        return null;
    }
    var t = bindTranslations(labels);
    return (React.createElement(React.Fragment, null,
        React.createElement(SecondaryButton, { type: "button", uppercase: true, text: t('ClickAndReserveTitle'), fontResponsive: false, icon: React.createElement(HomeIcon, { width: 16 }), fullWidth: true, onClick: function () {
                setIsOpen(true);
            } }),
        React.createElement(VerticalSpacer, { size: "s" }),
        React.createElement(ClickAndReserveDrawer, { variantId: productVariantId, productId: productId, brandName: brandName, locations: locationVariants, isOpen: isOpen, openDrawer: function () { return setIsOpen(false); } })));
};
