var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useFormValidation } from 'nested-form-validation';
import React from 'react';
import { createFormFieldValidator } from '../../helpers/FormFieldValidator/FormFieldValidator';
import { TextField } from '../TextField/TextField';
export var TextFieldValidatable = function (props) {
    var requiredErrorMessage = props.requiredErrorMessage, textFieldProps = __rest(props, ["requiredErrorMessage"]);
    return (React.createElement(TextFieldValidatableUi, __assign({}, textFieldProps, { validator: function (value) {
            if (requiredErrorMessage && !value) {
                return requiredErrorMessage;
            }
            if (props.validator) {
                return props.validator(value);
            }
        } })));
};
export var TextFieldWithFormSetting = function (_a) {
    var formSetting = _a.formSetting, props = __rest(_a, ["formSetting"]);
    return (React.createElement(TextFieldValidatable, __assign({ validator: createFormFieldValidator(formSetting), label: formSetting.fields.FieldLabel.value }, props)));
};
var TextFieldValidatableUi = function (props) {
    var validator = props.validator, onBlur = props.onBlur, alwaysShowError = props.alwaysShowError, textFieldProps = __rest(props, ["validator", "onBlur", "alwaysShowError"]);
    var _a = useFormValidation(props.name, props.value, validator), errorMessage = _a[0], touched = _a[1], onBlurFormValidation = _a[2];
    var hasVisibleError = (alwaysShowError || touched) && errorMessage !== undefined;
    return (React.createElement(TextField, __assign({}, textFieldProps, { error: hasVisibleError || textFieldProps.error, onBlur: function (event) {
            onBlurFormValidation();
            if (onBlur) {
                onBlur(event);
            }
        } })));
};
