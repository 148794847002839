var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Column, Row } from '@jelmoli/bootstrap-grid';
import { useComponentSize } from '@jelmoli/hooks/src/useComponentSize/useComponentSize';
import { Divider } from '@jelmoli/ui-components/src/atoms/Divider/Divider';
import { H1 } from '@jelmoli/ui-components/src/atoms/Headlines/Headlines';
import { Image } from '@jelmoli/ui-components/src/atoms/Image/Image';
import { useImageRatio } from '@jelmoli/ui-components/src/helpers/BaseImage/BaseImage';
import { Container } from '@jelmoli/ui-components/src/helpers/Container/Container';
import { Parallax } from '@jelmoli/ui-components/src/helpers/Parallax/Parallax';
import { ScrollFader } from '@jelmoli/ui-components/src/helpers/ScrollFader/ScrollFader';
import { getFieldTextLength, Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import React from 'react';
export var EditorialStageComponent = function (props) {
    return props.fields.EditorialImage.value.src ? (React.createElement(EditorialStageComponentWithImage, __assign({}, props))) : (React.createElement(EditorialStageComponentWithoutImage, __assign({}, props)));
};
/**
 * The EditorialStageHeading resizes to a smaller font if it has at least
 * 14 characters
 */
function EditorialStageHeading(props) {
    var fontStyleType = props.isBold ? 'bold' : 'light';
    return getFieldTextLength(props.EditorialStageHeading) < 14 ? (React.createElement(H1, { field: props.EditorialStageHeading, size: "xxl", type: fontStyleType })) : (React.createElement(H1, { field: props.EditorialStageHeading, size: "xl", type: fontStyleType }));
}
/**
 * The EditorialStageComponent in case that the editor didn't set an image
 */
var EditorialStageComponentWithoutImage = function (props) {
    var fields = props.fields;
    return (React.createElement(Container, { size: "s", backgroundFill: true },
        React.createElement(Row, null,
            React.createElement(Column, { size: 12 },
                React.createElement(Typography, { uppercase: true, size: "xs", type: "light", tag: "p", field: fields.EditorialSubtitle }),
                React.createElement(VerticalSpacer, { size: "xs" }),
                React.createElement(EditorialStageHeading, { isBold: props.fields.EditorialTitleStyleBold.value, EditorialStageHeading: fields.EditorialTitle })),
            React.createElement(VerticalSpacer, { size: "l" }),
            React.createElement(Column, { size: { xs: 12, md: 10 } },
                React.createElement(Divider, null),
                React.createElement(VerticalSpacer, { size: "m" }),
                React.createElement(Typography, { size: "s", type: "light", field: fields.EditorialLeadText, tag: "p" }),
                React.createElement(VerticalSpacer, { size: "xxxl" })))));
};
/**
 * The EditorialStageComponent in case that the editor did set an image with portrait orientation
 */
var EditorialStageComponentWithImage = function (props) {
    var fields = props.fields;
    var hasHorizontalImage = useImageRatio(fields.EditorialImage) === '16-9' || '3-2' || '2-1' || '1-1';
    if (hasHorizontalImage) {
        return React.createElement(EditorialStageComponentWithHorizontalImage, __assign({}, props));
    }
    var _a = useComponentSize(), ref = _a.ref, height = _a.height;
    return (React.createElement("div", { ref: ref },
        React.createElement(Container, { size: "s", backgroundFill: true },
            React.createElement(ScrollFader, null,
                React.createElement(Row, null,
                    React.createElement(Column, { size: { xs: 12, md: 8 }, offset: { md: 4 }, order: { xs: 2, md: 1 } },
                        React.createElement(Image, { value: fields.EditorialImage.value }),
                        React.createElement(VerticalSpacer, { size: { xs: 'xxxl', sm: 'xxxl', md: 'xxxl', lg: 'xxxxl', xl: 'xxxxl' } })),
                    React.createElement(Column, { overlap: { md: 12 }, size: { xs: 12, md: 8 }, order: { xs: 1, md: 2 } },
                        React.createElement(Parallax, { height: height },
                            React.createElement(Row, null,
                                React.createElement(Column, { size: { xs: 12, md: 9 } },
                                    React.createElement(VerticalSpacer, { size: { md: 'xxxl', lg: 'l', xl: 'xxxl' }, hide: ['xs', 'sm'] }),
                                    React.createElement(Typography, { uppercase: true, size: "xs", type: "light", tag: "p", field: fields.EditorialSubtitle }),
                                    React.createElement(VerticalSpacer, { size: "xs" }),
                                    React.createElement(EditorialStageHeading, { isBold: props.fields.EditorialTitleStyleBold.value, EditorialStageHeading: fields.EditorialTitle })),
                                React.createElement(VerticalSpacer, { size: "l" }),
                                React.createElement(Column, { size: { xs: 12, md: 6 } },
                                    React.createElement(Divider, null),
                                    React.createElement(VerticalSpacer, { size: "m" }),
                                    React.createElement(Typography, { size: "s", type: "light", field: fields.EditorialLeadText, tag: "p" }),
                                    React.createElement(VerticalSpacer, { size: { xs: 'l', sm: 'l', md: 'l', lg: 'xxxl', xl: 'xxxl' } }))))))))));
};
/**
 * The EditorialStageComponent in case that the editor did set an image with landscape orientation
 */
var EditorialStageComponentWithHorizontalImage = function (props) {
    var fields = props.fields;
    return (React.createElement(Container, { size: "s", backgroundFill: true },
        React.createElement(ScrollFader, null,
            React.createElement(Row, null,
                React.createElement(Column, { size: { xs: 12, md: 8 }, offset: { md: 4 }, order: { xs: 2, md: 1 } },
                    React.createElement(Image, { value: fields.EditorialImage.value }),
                    React.createElement(VerticalSpacer, { size: { xs: 'xxxl', sm: 'xxxl', md: 'xxxl', lg: 'xxxxl', xl: 'xxxxl' } })),
                React.createElement(Column, { overlap: { md: 12 }, size: { xs: 12, md: 8 }, order: { xs: 1, md: 2 } },
                    React.createElement(Row, null,
                        React.createElement(Column, { size: { xs: 12, md: 9 } },
                            React.createElement(VerticalSpacer, { size: { xl: 'xxxl' } }),
                            React.createElement(Typography, { uppercase: true, size: "xs", type: "light", tag: "p", field: fields.EditorialSubtitle }),
                            React.createElement(VerticalSpacer, { size: "xs" }),
                            React.createElement(EditorialStageHeading, { isBold: props.fields.EditorialTitleStyleBold.value, EditorialStageHeading: fields.EditorialTitle })),
                        React.createElement(VerticalSpacer, { size: "l" }),
                        React.createElement(Column, { size: { xs: 12, md: 6 } },
                            React.createElement(Divider, null),
                            React.createElement(VerticalSpacer, { size: "m" }),
                            React.createElement(Typography, { size: "s", type: "light", field: fields.EditorialLeadText, tag: "p" }),
                            React.createElement(VerticalSpacer, { size: { xs: 'l', sm: 'l', md: 'l', lg: 'xxxl', xl: 'xxxl' } }))))))));
};
