var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import AppBar from '@material-ui/core/AppBar';
import { default as MUiTabs } from '@material-ui/core/Tabs';
import classnames from 'classnames';
import React from 'react';
import './tabs.scss';
export var Tabs = function (props) {
    var justifyItems = props.justifyItems, singleTab = props.singleTab, tabProps = __rest(props, ["justifyItems", "singleTab"]);
    return (React.createElement(AppBar, { position: "static", color: "inherit", classes: { root: classnames('a-tabs__app-bar', { 'a-tabs__app-bar--single': singleTab }) }, elevation: 0 },
        React.createElement(MUiTabs, __assign({ classes: {
                root: 'a-tabs',
                indicator: 'a-tabs__indicator',
                flexContainer: "a-tabs__flex-container-" + (justifyItems || 'start'),
            }, ScrollButtonComponent: ScrollButtons }, tabProps))));
};
var ScrollButtons = function (props) {
    var leftVisibleClasses = 'a-tabs__scroll-button a-tabs__scroll-button--left';
    var rightVisibleClasses = 'a-tabs__scroll-button a-tabs__scroll-button--right';
    return (React.createElement(React.Fragment, null,
        props.visible && props.direction === 'left' && React.createElement("div", { className: leftVisibleClasses }),
        props.visible && props.direction === 'right' && React.createElement("div", { className: rightVisibleClasses })));
};
