import { createContext, useContext } from 'react';
var EditModeContext = createContext('unknown');
var isBrowser = typeof window !== 'undefined';
var isInEditModeCache;
/**
 * Returns true if the current user is an author
 * who is using the sitecore edit mode
 */
export function readEditModeStateFromDom() {
    // During server side rendering we can not read from the body element
    // so we hardcode the editmode to false
    if (!isBrowser || !document.body) {
        return false;
    }
    if (isInEditModeCache === undefined) {
        isInEditModeCache = document.body.classList.contains('edit-mode');
    }
    return isInEditModeCache;
}
/**
 * Returns true if the current user is an author
 * who is using the sitecore edit mode
 */
export function useIsSitecoreEditMode() {
    var editModeInContext = useContext(EditModeContext);
    if (editModeInContext !== 'unknown') {
        return editModeInContext;
    }
    if (isInEditModeCache === undefined) {
        isInEditModeCache = readEditModeStateFromDom();
    }
    return isInEditModeCache;
}
export var EditModeContextProvider = EditModeContext.Provider;
