/// <reference types="webpack-env" />
import LogoFull from '!!./svg-loader!./assets/logo.svg';
import React from 'react';
import Loadable from 'react-loadable';
import './logo.scss';
/**
 * The main Brand Logo
 *
 * It is inlined as it is part of the initial viewport
 */
export var Logo = function () { return React.createElement(LogoFull, { className: "a-logo--full" }); };
/**
 * Collapsed Header Logo
 *
 * It is lazyloaded as it is never part of the initial viewport
 */
export var CollapsedLogo = Loadable({
    loading: function () { return React.createElement("div", { className: "a-logo--collapsed" }); },
    loader: function () { return import(/* webpackChunkName: "collapsed-logo" */ '!!./svg-loader!./assets/logo-small.svg'); },
    render: function (loadedModule) { return React.createElement(loadedModule.default, { className: "a-logo--collapsed" }); },
});
/**
 * Collapsed Header Logo
 *
 * It is lazyloaded as it is never part of the initial viewport
 */
export var CollapsedLogoAnimated = Loadable({
    loading: function () { return React.createElement("div", { className: "a-logo--collapsed" }); },
    loader: function () { return import(/* webpackChunkName: "collapsed-logo" */ '!!./svg-loader!./assets/logo-small-animated.svg'); },
    render: function (loadedModule) { return React.createElement(loadedModule.default, { className: "a-logo--collapsed" }); },
});
