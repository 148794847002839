import React from 'react';
import { VerticalSpacer } from '../../helpers/VerticalSpacer/VerticalSpacer';
import { CollapsedLogo } from '../Logo/Logo';
import './spinner.scss';
var sizes = {
    small: {
        width: 17,
        height: 17,
    },
    medium: {
        width: 60,
        height: 60,
    },
};
export var Spinner = function (_a) {
    var _b = _a.size, size = _b === void 0 ? 'medium' : _b, _c = _a.color, color = _c === void 0 ? 'black' : _c;
    return (React.createElement(React.Fragment, null,
        React.createElement("span", { className: "a-spinner" },
            React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: sizes[size].width, height: sizes[size].height, viewBox: "25 25 50 50" },
                React.createElement("circle", { className: "a-spinner-outer a-spinner-outer--color-" + color + " a-spinner-outer--" + size, cx: "50", cy: "50", r: "24" },
                    React.createElement("animateTransform", { attributeName: "transform", dur: "2s", from: "0 50 50", repeatCount: "indefinite", to: "360 50 50", type: "rotate" }))),
            size === 'medium' && (React.createElement("span", { className: "a-spinner-logo" },
                React.createElement(CollapsedLogo, null)))),
        size === 'medium' && React.createElement(VerticalSpacer, { size: "xxl" })));
};
