import { H1 } from '@jelmoli/ui-components/src/atoms/Headlines/Headlines';
import { Container } from '@jelmoli/ui-components/src/helpers/Container/Container';
import { hasTextFieldValue } from '@jelmoli/ui-components/src/helpers/SitecoreTypes/SitecoreTypes';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import React from 'react';
import './topic-overview-stage.scss';
export var TopicOverviewStageComponent = function (props) { return (React.createElement(Container, { backgroundFill: true, size: "s" },
    React.createElement(TopicOverviewHeading, { titleField: props.fields.TopicOverviewPageTitle, subtitleField: props.fields.TopicOverviewPageSubtitle }))); };
var TopicOverviewHeading = function (props) { return (React.createElement("div", { className: "feat-topic-overview-stage__container" },
    React.createElement(H1, { size: "xl", type: "bold", field: props.titleField }),
    hasTextFieldValue(props.subtitleField) && (React.createElement(React.Fragment, null,
        React.createElement(VerticalSpacer, { size: "s" }),
        React.createElement(Typography, { size: "l", field: props.subtitleField }))),
    React.createElement(VerticalSpacer, { size: "xxl" }))); };
