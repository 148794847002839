import useComponentSize from '@rehooks/component-size';
import { useEffect, useRef } from 'react';
/**
 * This hook creates global variable and sets it's value to be the height of referenced component
 * */
export var useCssVariable = function (cssPropertyName) {
    var wrapperDivRef = useRef(null);
    var wrapperSize = useComponentSize(wrapperDivRef);
    useEffect(function () {
        if (!document || !wrapperDivRef.current || !cssPropertyName) {
            return;
        }
        document.documentElement.style.setProperty(cssPropertyName, wrapperSize.height + "px");
        return function () {
            document.documentElement.style.removeProperty(cssPropertyName);
        };
    }, [cssPropertyName, wrapperSize.height, wrapperDivRef.current]);
    return wrapperDivRef;
};
