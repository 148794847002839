import { createPostEndpoint } from 'request-registry';
import { getCSRFToken } from '../../Security/getCSRFToken/getCSRFToken';
import { getCart } from '../getCart/getCart';
import { getCartTotalCount } from '../getCartTotalCount/getCartTotalCount';
export var addGiftcardCartLine = createPostEndpoint({
    url: function () { return '/jelmoli-api/JelmoliCart/AddGiftcardCartLine'; },
    headers: {
        'X-RequestVerificationToken': function () { return getCSRFToken(); },
        'X-Requested-With': 'XMLHttpRequest',
    },
    afterSuccess: function () { return Promise.all([getCart.refresh(), getCartTotalCount.refresh()]); },
});
