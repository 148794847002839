import { getCheckoutProgress } from '@jelmoli/sitecore-services/src/Checkout/getCheckoutProgress';
import { urlManager } from '@jelmoli/urlmanager/UrlManager';
import { useMemo } from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
export var checkoutStepNames = ['cart', 'giftCard', 'address', 'deliveryOption', 'payment', 'review'];
function getCurrentStepFromUrl() {
    var currentAddress = urlManager.queryStringFlat.checkoutStep;
    if (checkoutStepNames.indexOf(currentAddress) !== -1) {
        return currentAddress;
    }
}
export function setCurrentStepToUrl(checkoutStep) {
    if (checkoutStep === getCurrentStepFromUrl()) {
        return;
    }
    urlManager.extendQueryString({ checkoutStep: checkoutStep }, { newHistoryEntry: false });
}
/**
 * Get the current Checkout Progress information:
 *  - currentStep
 *  - nextStep
 *  - stepStates
 *
 * this state is a merge from the
 * ?checkoutStep url parameter
 * and the backend getCheckoutProgress data
 *
 * As long as the backend still loading this hook will return null
 */
export var useCheckoutStepperState = function () {
    var checkoutBackendProgressState = useGetEndPointLazy(getCheckoutProgress).value;
    /* Calculate the stepState based on backendState */
    var stepStates = useMemo(function () {
        var backendStepStates = checkoutBackendProgressState && checkoutBackendProgressState.steps;
        return !backendStepStates
            ? {
                cart: false,
                giftCard: false,
                address: false,
                deliveryOption: false,
                payment: false,
                review: false,
            }
            : {
                cart: backendStepStates.cart.isValid,
                giftCard: backendStepStates.giftCard.isValid,
                address: backendStepStates.address.isValid,
                deliveryOption: backendStepStates.deliveryOption.isValid,
                payment: backendStepStates.payment.isValid,
                review: false,
            };
    }, [checkoutBackendProgressState]);
    /**
     * The step which is currently visible based on the current query string
     * If no querystring exists try to go directly to review
     */
    var currentStepFromUrl = getCurrentStepFromUrl() || 'review';
    var firstIncompleteStep = checkoutStepNames[getFirstIncompleteStep(stepStates, currentStepFromUrl)];
    /**
     * The step which is currently visible
     * Goes `currentStepFromUrl` but checks if there is any
     * incomplete step which has to be done first
     */
    var currentExpandedStep = checkoutStepNames.indexOf(currentStepFromUrl) < checkoutStepNames.indexOf(firstIncompleteStep)
        ? currentStepFromUrl
        : firstIncompleteStep;
    /** The next step */
    var nextStep = checkoutStepNames[getFirstIncompleteStep(stepStates, currentExpandedStep)];
    return checkoutBackendProgressState
        ? {
            currentStep: currentExpandedStep,
            stepStates: stepStates,
            nextStep: nextStep,
        }
        : null;
};
function getFirstIncompleteStep(stepStates, currentStep) {
    var index = checkoutStepNames.findIndex(function (stepName) {
        if (stepName === currentStep) {
            return false;
        }
        return stepStates[stepName] === false;
    });
    return index === -1 ? checkoutStepNames.length - 1 : index;
}
