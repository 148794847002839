var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { createAutoCorrectedDatePipe } from 'text-mask-addons';
import { MaskedInput } from '../MaskedInput/MaskedInput';
/**
 * For formating date look into this link:
 * https://github.com/text-mask/text-mask/tree/master/addons#createautocorrecteddatepipe
 */
export var DateInput = function (props) {
    return (React.createElement(MaskedInput, __assign({}, props, { inputMode: "numeric", mask: props.mask || [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/], pipe: createAutoCorrectedDatePipe('dd.mm.yyyy') })));
};
