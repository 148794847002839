var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { bindTranslations } from '@jelmoli/i18n';
import { FormValidationContext } from 'nested-form-validation';
import React from 'react';
import { Dropdown } from '../../atoms/Dropdown/Dropdown';
import { DropdownItem } from '../../atoms/DropdownItem/DropdownItem';
import { FormFieldErrorMessage } from '../../atoms/FormFieldErrorMessage/FormFieldErrorMessage';
import { TextFieldWithFormSetting } from '../../atoms/TextFieldValidatable/TextFieldValidatable';
import { HorizontalSpacer } from '../../helpers/HorizontalSpacer/HorizontalSpacer';
import { getLabelsFromFormFields, } from '../../helpers/SitecoreTypes/SitecoreTypes';
import { VerticalSpacer } from '../../helpers/VerticalSpacer/VerticalSpacer';
import './address-form.scss';
export var AddressForm = function (_a) {
    var fields = _a.fields, fieldValues = _a.fieldValues, removeLastSpacing = _a.removeLastSpacing;
    var t = bindTranslations(getLabelsFromFormFields(fields));
    return (React.createElement(FormValidationContext, null,
        React.createElement(TextFieldWithFormSetting, __assign({ variant: "standard", name: "company", autoComplete: "organization", formSetting: fields.Company }, fieldValues.company, { fullWidth: true })),
        React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
        React.createElement(TextFieldWithFormSetting, __assign({ variant: "standard", name: "address", formSetting: fields.Address1 }, fieldValues.address1, { fullWidth: true })),
        React.createElement(FormFieldErrorMessage, { name: "address", verticalSpaceTop: "xxs" }),
        React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
        React.createElement(TextFieldWithFormSetting, __assign({ variant: "standard", name: "secondAddress", formSetting: fields.Address2 }, fieldValues.address2, { fullWidth: true })),
        React.createElement(FormFieldErrorMessage, { name: "secondAddress", verticalSpaceTop: "xxs" }),
        React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
        React.createElement("div", { className: "o-address-form__postal-code-city-wrapper" },
            React.createElement("div", null,
                React.createElement(TextFieldWithFormSetting, __assign({ variant: "standard", name: "postalCode", formSetting: fields.ZipPostalCode }, fieldValues.zipPostalCode, { fullWidth: true })),
                React.createElement(FormFieldErrorMessage, { name: "postalCode", verticalSpaceTop: "xxs" })),
            React.createElement(HorizontalSpacer, { size: "s", responsive: false }),
            React.createElement("div", { className: "o-address-form__city" },
                React.createElement(TextFieldWithFormSetting, __assign({ variant: "standard", name: "city", formSetting: fields.City }, fieldValues.city, { fullWidth: true })),
                React.createElement(FormFieldErrorMessage, { name: "city", verticalSpaceTop: "xxs" }))),
        React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
        React.createElement(Dropdown, __assign({ label: t('Country'), type: "fullwidth" }, fieldValues.country), fields.CountryList &&
            fields.CountryList.map(function (country) {
                return country.fields['Country Code'].value && (React.createElement(DropdownItem, { value: country.fields['Country Code'].value, key: country.fields['Country Code'].value }, country.fields.Name.value));
            })),
        !removeLastSpacing && React.createElement(VerticalSpacer, { size: "xl", responsive: false })));
};
