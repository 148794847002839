var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import classnames from 'classnames';
import React from 'react';
import { Column as ReactComponentColumn, } from 'react-bootstrap-grid-component/dist/Column';
import './grid.scss';
export { Row } from 'react-bootstrap-grid-component/dist/Row';
var viewportClassPrefix = function (viewport) { return (viewport === 'xs' ? '' : "-" + viewport); };
function getOverlapByViewport(overlap) {
    return typeof overlap === 'number' ? { xs: overlap } : overlap;
}
function generateOverlapClasses(overlap) {
    return Object.keys(overlap)
        .map(function (breakpointName) {
        return "h-column--overlap" + viewportClassPrefix(breakpointName) + "-" + overlap[breakpointName];
    })
        .join(' ');
}
export var Column = function (props) {
    var overlapClassNames = props.overlap !== undefined && generateOverlapClasses(getOverlapByViewport(props.overlap));
    return React.createElement(ReactComponentColumn, __assign({}, props, { className: classnames(props.className, overlapClassNames) }));
};
