var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useLoadNamedOnceOnClientSide } from '@jelmoli/hooks/src/useLoadOnceOnClientSide/useLoadOnceOnClientSide';
import React from 'react';
import './check-card.scss';
export var CheckCard = function (props) {
    var CheckCardLazy = useLoadNamedOnceOnClientSide('CheckCardLazy', function () {
        return import(/* webpackChunkName: "CheckCard" */ './CheckCardLazy');
    });
    return CheckCardLazy ? React.createElement(CheckCardLazy, __assign({}, props)) : React.createElement("div", { className: "a-check-card" });
};
