import { track } from '@jelmoli/google-tag-manager';
export function trackMagazineSignUp(success) {
    track({
        event: 'gaEvent',
        event_name: 'magazine_registration',
        magazine_registration: {
            status: success ? 'success' : 'fail',
            event_category: 'magazine',
            event_action: 'registration',
            event_label: success ? 'success' : 'fail',
        },
    });
}
