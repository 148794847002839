var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { bindTranslations } from '@jelmoli/i18n';
import classnames from 'classnames';
import React, { useState } from 'react';
import { A } from '../../helpers/A/A';
import { HorizontalSpacer } from '../../helpers/HorizontalSpacer/HorizontalSpacer';
import { Typography } from '../../helpers/Typography/Typography';
import { VerticalSpacer } from '../../helpers/VerticalSpacer/VerticalSpacer';
import { Visibility } from '../../helpers/Visibility/Visibility';
import { Badge } from '../Badge/Badge';
import { Image } from '../Image/Image';
import { NumberInput } from '../NumberInput/NumberInput';
import { ProductFeature } from '../ProductFeature/ProductFeature';
import { TextLink } from '../TextLink/TextLink';
import { WishlistHeartButton } from '../WishlistHeartButton/WishlistHeartButton';
import './product-item.scss';
export var ProductItem = function (props) {
    var _a = useState(props.quantity), quantityValue = _a[0], setQuantityValue = _a[1];
    var isCustomizedByZyseMe = props.typeOfGood === 'ZyseMe';
    var isClickable = !isCustomizedByZyseMe;
    var hasSaleLabel = props.labels.some(function (label) { return label.style === 'sale'; });
    return (React.createElement("div", { className: "a-product-item" },
        React.createElement(VerticalSpacer, { size: "xxs", responsive: false }),
        React.createElement("div", { className: "a-product-item__image-wrapper" },
            isClickable ? (React.createElement(A, { "aria-label": props.displayName, href: props.productUrl }, props.imageUrl ? (React.createElement(Image, { value: { src: props.imageUrl, alt: props.displayName } })) : (React.createElement("div", { className: "a-product-item__image-offline" })))) : props.imageUrl ? (React.createElement(Image, { value: { src: props.imageUrl, alt: props.displayName } })) : (React.createElement("div", { className: "a-product-item__image-offline" })),
            React.createElement(VerticalSpacer, { size: "xs", responsive: false })),
        React.createElement("div", { className: "a-product-item__text-wrapper" },
            React.createElement("div", null,
                props.labels.map(function (label) {
                    return label.style === 'sale' && (React.createElement(React.Fragment, { key: label.label },
                        React.createElement(HorizontalSpacer, { size: "xs", responsive: false }),
                        React.createElement(ProductFeature, { border: "red" }, label.label),
                        React.createElement(VerticalSpacer, { size: "s", responsive: false })));
                }),
                React.createElement("div", { className: "a-product-item__link" }, isClickable ? (React.createElement(TextLink, { "aria-label": "product description", underlined: false, href: props.productUrl, display: "inline-block" },
                    React.createElement(ProductDescription, __assign({ isCustomizedByZyseMe: isCustomizedByZyseMe, hasLabels: hasSaleLabel }, props)))) : (React.createElement(ProductDescription, __assign({ isCustomizedByZyseMe: isCustomizedByZyseMe, hasLabels: hasSaleLabel }, props))))),
            React.createElement("div", { className: "a-product-item__buttons-wrapper" },
                React.createElement(HorizontalSpacer, { size: "xs", responsive: false }),
                /** ZyseMe products can't be added to wishlist */ !isCustomizedByZyseMe && (React.createElement(WishlistHeartButton, { variantIds: [props.variantId], productId: props.productId, productName: props.displayName, brandName: props.brandName })),
                React.createElement(HorizontalSpacer, { size: "xs", responsive: false }),
                React.createElement(A, { className: "a-product-item__remove-btn", "aria-label": "remove product", onClick: props.removeItem })),
            React.createElement(VerticalSpacer, { size: "s", responsive: false }),
            props.availability >= 1 && (React.createElement(Visibility, { hide: ['xs', 'sm'] },
                React.createElement("div", { className: "a-product-item__bottom--wrapper" },
                    React.createElement("div", { className: "a-product-item__bottom" },
                        props.availability >= 1 && (React.createElement("div", { className: "a-product-item__quantity" },
                            React.createElement(NumberInput, { min: 1, max: props.availability, type: "small", value: quantityValue, onChange: function (quantity) {
                                    setQuantityValue(quantity);
                                    props.updateCart(quantity);
                                }, disableDecrease: quantityValue === 1, disableIncrease: quantityValue === props.availability }))),
                        React.createElement("div", null,
                            props.originalLineTotal &&
                                parseInt(props.originalLineTotal.replace(/^\D+/g, ''), 10) !== 0 && (React.createElement(React.Fragment, null,
                                React.createElement(Typography, { size: "xs", responsive: false, type: "light" },
                                    React.createElement("span", { className: "a-product-item__old-price" }, props.originalLineTotal)),
                                React.createElement(HorizontalSpacer, { size: 's' }))),
                            React.createElement(Typography, { size: "xs", responsive: false },
                                React.createElement("span", { className: classnames(props.originalLineTotal && 'a-product-item__sale-price') }, props.lineTotal))))),
                props.fulfillmentFlags && (React.createElement(React.Fragment, null,
                    React.createElement(VerticalSpacer, { size: "xs", responsive: false }),
                    React.createElement("div", { className: "a-product-item__fulfillmrntdata-wrapper" },
                        React.createElement(ProductFulfillmentData, { fulfillmentFlags: props.fulfillmentFlags, fragileLabel: props.fragileLabel, bulkyLabel: props.bulkyLabel, optionSaved: props.optionSaved, installationServiceSelectedLabel: props.installationServiceSelectedLabel, installationServiceAvailableLabel: props.installationServiceAvailableLabel, installationServiceMandatoryLabel: props.installationServiceMandatoryLabel, zyseMeLabel: props.customMeasureLabel, isZyseMeProduct: isCustomizedByZyseMe, discounts: props.discounts })))))),
            (props.availability < 1 || props.checkoutError) && (React.createElement("div", { className: "a-product-item__not-in-stock-wrapper" },
                React.createElement(Badge, { color: "functional-red", size: 6 }),
                React.createElement("span", { className: "a-product-item__not-in-stock-text" },
                    React.createElement(Typography, { size: "xs", responsive: false }, props.notAvailableLabel))))),
        props.availability >= 1 && (React.createElement(Visibility, { show: ['xs', 'sm'] },
            React.createElement("div", { className: "a-product-item__bottom--wrapper" },
                React.createElement("div", { className: "a-product-item__bottom" },
                    props.availability >= 1 && (React.createElement("div", { className: "a-product-item__quantity" },
                        React.createElement(NumberInput, { min: 1, max: props.availability, type: "small", value: quantityValue, onChange: function (quantity) {
                                setQuantityValue(quantity);
                                props.updateCart(quantity);
                            }, disableDecrease: quantityValue === 1, disableIncrease: quantityValue === props.availability }))),
                    React.createElement("div", null,
                        props.originalLineTotal && (React.createElement(React.Fragment, null,
                            React.createElement(Typography, { size: "xs", responsive: false, type: "light" },
                                React.createElement("span", { className: "a-product-item__old-price" }, props.originalLineTotal)),
                            React.createElement(HorizontalSpacer, { size: 's' }))),
                        React.createElement(Typography, { size: "xs", responsive: false },
                            React.createElement("span", { className: classnames(props.originalLineTotal && 'a-product-item__sale-price') }, props.lineTotal))))),
            props.fulfillmentFlags && (React.createElement(React.Fragment, null,
                React.createElement(VerticalSpacer, { size: "xs", responsive: false }),
                React.createElement("div", { className: "a-product-item__fulfillmretdata-wrapper" },
                    React.createElement(ProductFulfillmentData, { fulfillmentFlags: props.fulfillmentFlags, fragileLabel: props.fragileLabel, bulkyLabel: props.bulkyLabel, optionSaved: props.optionSaved, installationServiceSelectedLabel: props.installationServiceSelectedLabel, installationServiceAvailableLabel: props.installationServiceAvailableLabel, installationServiceMandatoryLabel: props.installationServiceMandatoryLabel, isZyseMeProduct: isCustomizedByZyseMe, zyseMeLabel: props.customMeasureLabel, discounts: props.discounts }))))))));
};
var ProductDescription = function (props) {
    var _a;
    return (React.createElement(React.Fragment, null,
        React.createElement("span", { className: classnames({ 'a-product-item__description-name': !props.hasLabels }) },
            React.createElement(Typography, { type: "medium", uppercase: true, size: "xs", responsive: false }, props.brandName)),
        React.createElement(VerticalSpacer, { size: "xs", responsive: false }),
        React.createElement(Typography, { size: "xs", responsive: false }, props.displayName),
        React.createElement(VerticalSpacer, { size: "xxs", responsive: false }),
        React.createElement(Typography, { size: "xs", responsive: false },
            props.size.label,
            ": ",
            props.isCustomizedByZyseMe ? props.zyseMeLabel : props.size.value),
        React.createElement(HorizontalSpacer, { size: "xs" }),
        React.createElement(Typography, { size: "xs", responsive: false },
            props.color.label,
            ": ",
            props.color.value),
        ((_a = props.comment) === null || _a === void 0 ? void 0 : _a.value) && (React.createElement(React.Fragment, null,
            React.createElement(HorizontalSpacer, { size: "xs" }),
            React.createElement(Typography, { size: "xs", responsive: false },
                props.comment.label,
                ": ",
                props.comment.value)))));
};
export var ProductFulfillmentData = function (props) {
    if (!props.fulfillmentFlags) {
        return null;
    }
    var listOfData = [{ label: '', optionSelected: false }];
    var t = bindTranslations(props);
    Object.keys(props.fulfillmentFlags).forEach(function (item) {
        switch (item) {
            case 'bulky':
                props.fulfillmentFlags &&
                    props.fulfillmentFlags.bulky &&
                    listOfData.push({ label: t('bulkyLabel'), optionSelected: props.optionSaved || false });
                break;
            case 'fragile':
                props.fulfillmentFlags &&
                    props.fulfillmentFlags.fragile &&
                    listOfData.push({ label: t('fragileLabel'), optionSelected: props.optionSaved || false });
                break;
            case 'installationServiceAvailable':
                props.fulfillmentFlags &&
                    props.fulfillmentFlags.installationServiceAvailable &&
                    !props.fulfillmentFlags.installationServiceSelected &&
                    listOfData.push({
                        label: t('installationServiceAvailableLabel'),
                        optionSelected: false,
                    });
                break;
            case 'installationServiceMandatory':
                props.fulfillmentFlags &&
                    props.fulfillmentFlags.installationServiceMandatory &&
                    !props.fulfillmentFlags.installationServiceSelected &&
                    listOfData.push({
                        label: t('installationServiceMandatoryLabel'),
                        optionSelected: false,
                    });
                break;
            case 'installationServiceSelected':
                props.fulfillmentFlags &&
                    props.fulfillmentFlags.installationServiceSelected &&
                    listOfData.push({
                        label: t('installationServiceSelectedLabel'),
                        optionSelected: true,
                    });
                break;
            default:
                break;
        }
    });
    props.discounts &&
        props.discounts.forEach(function (discount) {
            listOfData.push({
                label: discount.label,
                optionSelected: true,
            });
        });
    if (props.isZyseMeProduct) {
        listOfData.push({
            label: t('zyseMeLabel'),
            optionSelected: true,
        });
    }
    listOfData.shift();
    return (React.createElement(React.Fragment, null, listOfData.map(function (data, index) { return (React.createElement(React.Fragment, { key: "flags_" + index },
        React.createElement("div", { className: "a-product-item__fulfillmentdata-text-wrapper" },
            React.createElement(Badge, { color: data.optionSelected ? 'functional-green' : 'functional-red', size: 6 }),
            React.createElement("span", { className: "a-product-item__fulfillmentdata-text" },
                React.createElement(Typography, { size: "xs", responsive: false }, data.label))),
        React.createElement(VerticalSpacer, { size: props.type && props.type === 'large' ? 'xs' : 'xxs', responsive: false }))); })));
};
