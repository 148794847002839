var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Typography } from '../../helpers/Typography/Typography';
import { ChevronDownIcon } from '../Icon/ChevronDownIcon';
import './dropdown.scss';
export var Dropdown = function (props) { return React.createElement(DropdownBase, __assign({}, props), props.children); };
var DropdownBase = function (props) {
    var inputLabel = useRef(null);
    var _a = useState(0), labelWidth = _a[0], setLabelWidth = _a[1];
    useEffect(function () {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);
    return (React.createElement(FormControl, { variant: "outlined", classes: {
            root: classnames('a-dropdown-wrapper', Boolean(props.type) && "a-dropdown-wrapper--" + props.type, props.error && 'a-dropdown-wrapper--error'),
        } },
        React.createElement(InputLabel, { ref: inputLabel, classes: {
                root: 'a-dropdown__placeholder',
                shrink: 'a-dropdown__placeholder--shrink',
            } },
            React.createElement(Typography, { responsive: false, size: "s" }, props.label)),
        React.createElement(Select, { inputProps: { required: props.required || false }, autoFocus: props.autoFocus, multiple: props.multiple, IconComponent: function () { return (React.createElement(ChevronDownIcon, { className: "a-dropdown__icon", width: props.noDropdownArrow ? 0 : 14 })); }, classes: {
                select: 'a-dropdown__input',
            }, MenuProps: {
                elevation: 1,
                classes: {
                    paper: 'a-dropdown__menu-wrapper',
                },
                marginThreshold: 0,
                disableAutoFocusItem: props.disableAutoFocusItem || false,
            }, "data-name": props.name, value: props.value, disabled: props.disabled, onChange: function (e) { return props.onChange(e); }, input: React.createElement(OutlinedInput, { classes: {
                    root: 'a-dropdown__input-wrapper',
                    notchedOutline: 'a-dropdown__input-outline',
                    focused: 'a-dropdown__input--focused',
                    disabled: 'a-dropdown__input--disabled',
                }, labelWidth: labelWidth }) }, props.children)));
};
