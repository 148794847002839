import { HorizontalSpacer } from '@jelmoli/ui-components/src/helpers/HorizontalSpacer/HorizontalSpacer';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { Link } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';
import './header-language-selector.scss';
export var HeaderLanguageSelectorComponent = function () {
    return (React.createElement("div", { className: "feat-header__language-selector" },
        React.createElement(Typography, { type: "regular", size: "xs", responsive: false },
            React.createElement(Link, { field: { value: { href: '#', text: 'Deutsch' } }, className: "feat-header__language-selector-link" })),
        React.createElement(HorizontalSpacer, { size: "xs" })));
};
