import { Column, Row } from '@jelmoli/bootstrap-grid';
import { VideoPlayer } from '@jelmoli/ui-components/src/atoms/VideoPlayer/VideoPlayer';
import { Container } from '@jelmoli/ui-components/src/helpers/Container/Container';
import { hasTextFieldValue } from '@jelmoli/ui-components/src/helpers/SitecoreTypes/SitecoreTypes';
import { CmsText } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import React from 'react';
export var VideoComponent = function (props) {
    if (!hasTextFieldValue(props.fields.VideoSourceLink)) {
        return null;
    }
    return (React.createElement(SizeWrapper, { size: props.parameters.videoLayoutOption },
        React.createElement(VideoPlayer, { src: props.fields.VideoSourceLink.value }),
        React.createElement(VerticalSpacer, { size: "s" }),
        React.createElement(CmsText, { size: "s", type: "regular", responsive: false, field: props.fields.VideoCaption }),
        React.createElement(VerticalSpacer, { size: "xxl" })));
};
var sizeWrapperSize = { '8': 8, '10': 10, '12': 12 };
var sizeWrapperOffset = { '8': 2, '10': 1, '12': 0 };
/**
 * The size wrapper is a small helper to apply the required size of the component
 */
var SizeWrapper = function (props) {
    return props.size === 'breakout' ? (React.createElement(Container, { size: "l", backgroundFill: true }, props.children)) : (React.createElement(Container, { size: "s", backgroundFill: true },
        React.createElement(Row, null,
            React.createElement(Column, { size: sizeWrapperSize[props.size], offset: sizeWrapperOffset[props.size] }, props.children))));
};
