import { createGetEndpoint } from 'request-registry';
var CheckoutPaymentMethodIdMap = {
    'd97ba4e0-de4f-42eb-a3e7-471fca324a1a': 'amex',
    '12762d8d-f161-4da2-bb99-d85d05e26d96': 'applepay',
    '58ecd0ec-2bca-4d57-9546-4864c8c4db7e': 'diners',
    '920ee262-270f-4ba0-a204-55016e776aa9': 'googlepay',
    '884195b5-0c5f-4c7b-a25e-54798a9069cf': 'mastercard',
    '089c8016-a65e-4ed1-ac57-e268ec382453': 'paypal',
    '36b18294-8bc3-4131-b518-f0ca704b2885': 'postfinance',
    '57a5654b-be2c-4e87-befd-2e6d761e7d51': 'samsungpay',
    'd167f7fd-66f5-46c4-b752-15a3749f443c': 'twint',
    '6047d71b-e70d-49ff-a3b1-4e29a2be44a6': 'visa',
    'd5b9a516-d652-40df-8d7e-1c8926d2390c': 'invoice',
    '07ec35c0-ae41-46ea-b857-0f601844cb30': 'giftcard',
};
export var CheckoutPaymentMethodConstants = CheckoutPaymentMethodIdMap;
export var getCheckoutPaymentMethods = createGetEndpoint({
    url: function () { return '/jelmoli-api/JelmoliCheckout/GetPaymentMethods'; },
});
