var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMiniFormik } from '@jelmoli/hooks/src/useMiniFormik/useMiniFormik';
import { bindTranslations } from '@jelmoli/i18n/index';
import { getCart } from '@jelmoli/sitecore-services/src/Cart/getCart/getCart';
import { DeliveryOptionConstants } from '@jelmoli/sitecore-services/src/Cart/getDeliveryOptions/getDeliveryOptions';
import { getCheckoutLabels } from '@jelmoli/sitecore-services/src/Checkout/getCheckoutLabels/getCheckoutLabels';
import { getClickAndCollectLocations } from '@jelmoli/sitecore-services/src/Checkout/getClickAndCollectLocations/getClickAndCollectLocations';
import { Dropdown } from '@jelmoli/ui-components/src/atoms/Dropdown/Dropdown';
import { DropdownItem } from '@jelmoli/ui-components/src/atoms/DropdownItem/DropdownItem';
import { IconBoxInfo } from '@jelmoli/ui-components/src/atoms/Icon/IconBoxInfo';
import { PrimarySubmitButton } from '@jelmoli/ui-components/src/atoms/PrimarySubmitButton/PrimarySubmitButton';
import { HorizontalSpacer } from '@jelmoli/ui-components/src/helpers/HorizontalSpacer/HorizontalSpacer';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { FormValidationContext } from 'nested-form-validation';
import React, { useEffect } from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
import { setDeliveryOptionPickupUtil } from '../util/setDeliveryOption';
export var CheckoutStepDeliveryOptionsPickup = function (_a) {
    var visible = _a.visible, onComplete = _a.onComplete;
    var labels = useGetEndPointLazy(getCheckoutLabels).value;
    var form = useMiniFormik({
        initialValues: {
            LocationExternalId: '35446bcb-e2d5-4645-ad64-d313c2674dd9',
        },
    });
    var cartData = useGetEndPointLazy(getCart).value;
    var getClickAndCollectLocationsCall = useGetEndPointLazy(getClickAndCollectLocations, {});
    useEffect(function () {
        if (cartData &&
            cartData.deliveryOption &&
            cartData.deliveryOption.deliveryOptionExternalId === DeliveryOptionConstants.pickup) {
            form.setValues({ LocationExternalId: cartData.deliveryOption.locationExternalId });
        }
    }, [cartData]);
    if (!visible || !getClickAndCollectLocationsCall.hasData || !labels) {
        return null;
    }
    var getClickAndCollectLocationsData = getClickAndCollectLocationsCall.value;
    var isAnyProductBulky = cartData &&
        cartData.lines.some(function (line) {
            return line.fulfillmentFlags &&
                (line.fulfillmentFlags.bulky ||
                    line.fulfillmentFlags.installationServiceMandatory ||
                    line.fulfillmentFlags.installationServiceSelected);
        });
    var t = bindTranslations(labels);
    if (isAnyProductBulky) {
        return (React.createElement(FormValidationContext, null,
            React.createElement("div", { className: "feat-checkout__error-notification" },
                React.createElement(VerticalSpacer, { size: "m", responsive: false }),
                React.createElement("div", { className: "feat-checkout__error-notification-text-wrapper" },
                    React.createElement(IconBoxInfo, { width: 38, keepOriginalColors: true }),
                    React.createElement(HorizontalSpacer, { size: "s", responsive: false }),
                    React.createElement("div", { className: "feat-checkout__error-notification-text" },
                        React.createElement(Typography, { size: "xs", responsive: false }, t('CheckoutNotificationClickAndCollectNotAvailable')))),
                React.createElement(VerticalSpacer, { size: "m", responsive: false })),
            React.createElement(VerticalSpacer, { size: "l" }),
            React.createElement(PrimarySubmitButton, { onClick: function () { return null; }, disabled: true, type: "button", size: "tiny", text: t('CheckoutStepperSaveStepCtaButtonText') })));
    }
    return (React.createElement(FormValidationContext, null,
        React.createElement(Dropdown, __assign({ type: "fullwidth", label: "" }, form.fieldProps.LocationExternalId), getClickAndCollectLocationsData.map(function (location) {
            return (React.createElement(DropdownItem, { disabled: !location.available, value: location.externalId, key: location.externalId }, location.name));
        })),
        React.createElement(VerticalSpacer, { size: "xl", responsive: false }),
        React.createElement(PrimarySubmitButton, { onClick: function () {
                return setDeliveryOptionPickupUtil(onComplete, form.values.LocationExternalId);
            }, type: "button", size: "tiny", text: t('CheckoutStepperSaveStepCtaButtonText') })));
};
