var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import ResizeObserverPolyfill from 'resize-observer-polyfill';
/** ServerSideRendering */
var ssr = typeof window === 'undefined';
var ResizeObserver = ssr
    ? undefined
    : window.ResizeObserver || ResizeObserverPolyfill;
function getSize(element) {
    return {
        width: element.offsetWidth,
        height: element.offsetHeight,
    };
}
export function useComponentSize() {
    var ref = useRef(null);
    var _a = useState({
        width: 0,
        height: 0,
    }), componentSize = _a[0], setComponentSize = _a[1];
    var handleResize = useCallback(function () {
        var element = ref.current;
        if (element) {
            var newSize = getSize(element);
            if (newSize.width !== componentSize.width || newSize.height !== componentSize.height) {
                setComponentSize(newSize);
            }
        }
    }, []);
    // Initial size measurement
    useLayoutEffect(function () {
        handleResize();
    }, []);
    // Size watching
    useEffect(function () {
        var element = ref.current;
        if (ssr || !element || !ResizeObserver) {
            return;
        }
        var resizeObserver;
        var animationFrame = requestAnimationFrame(function () {
            resizeObserver = new ResizeObserver(function () { return handleResize(); });
            resizeObserver.observe(element);
        });
        return function () {
            cancelAnimationFrame(animationFrame);
            if (resizeObserver) {
                resizeObserver.disconnect(element);
                resizeObserver = null;
            }
        };
    }, [ref.current]);
    return __assign({ ref: ref }, componentSize);
}
