import { Column, Row } from '@jelmoli/bootstrap-grid';
import { H3 } from '@jelmoli/ui-components/src/atoms/Headlines/Headlines';
import { Image } from '@jelmoli/ui-components/src/atoms/Image/Image';
import { A } from '@jelmoli/ui-components/src/helpers/A/A';
import { Container } from '@jelmoli/ui-components/src/helpers/Container/Container';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { Visibility } from '@jelmoli/ui-components/src/helpers/Visibility/Visibility';
import { ImageOverlaySlider } from '@jelmoli/ui-components/src/organism/ImageOverlaySlider/ImageOverlaySlider';
import React, { useState } from 'react';
import './image-gallery-component.scss';
var maxImagesPerViewport = {
    xs: 4,
    sm: 3,
    md: 4,
    lg: 4,
    xl: 6,
};
export var ImageGalleryComponent = function (props) {
    var _a = useState(false), isModalOpen = _a[0], setIsModalOpen = _a[1];
    var _b = useState(0), imageOverlayIndex = _b[0], setImageOverlayIndex = _b[1];
    var openModal = function (index) {
        setIsModalOpen(true);
        setImageOverlayIndex(index);
    };
    return (React.createElement(Container, { size: "s" },
        React.createElement(ImageOverlaySlider, { titleField: props.fields.ImageGalleryTitle, open: isModalOpen, onClose: function () { return setIsModalOpen(false); }, onIndexChange: setImageOverlayIndex, selectedImageIndex: imageOverlayIndex, images: props.fields.ImageGallerySlides }),
        React.createElement(Row, null,
            React.createElement(Column, null,
                React.createElement(H3, { type: "light", size: "m", field: props.fields.ImageGalleryTitle }),
                React.createElement(VerticalSpacer, { size: 'l' }))),
        React.createElement(Row, null,
            React.createElement(GalleryGridImageLooper, { max: 5, images: props.fields.ImageGallerySlides }, function (image, index) {
                return (React.createElement(Column, { size: imageColumnSizeCalculation(index) },
                    React.createElement(GalleryImage, { index: index, image: image, imageCount: props.fields.ImageGallerySlides.length, onClick: openModal }),
                    index <= 1 && React.createElement(VerticalSpacer, { size: "l", show: "xs" })));
            })),
        React.createElement(VerticalSpacer, { size: "xxl" })));
};
/**
 * Returns the grid column size based on the position in the grid
 */
var imageColumnSizeCalculation = function (index) {
    return {
        xs: index < maxImagesPerViewport.xs ? 6 : 0,
        sm: index < maxImagesPerViewport.sm ? 4 : 0,
        md: index < maxImagesPerViewport.md ? 3 : 0,
        xl: index < maxImagesPerViewport.xl ? 2 : 0,
    };
};
/**
 * Helper to loop over images
 */
var GalleryGridImageLooper = function (props) { return (React.createElement(React.Fragment, null, props.images
    .filter(function (value, i) { return i <= props.max; })
    .map(function (image, index) { return (React.createElement(React.Fragment, { key: index }, props.children(image, index))); }))); };
/**
 * The gallery image is a single image inside the gallery grid
 */
var GalleryImage = function (props) {
    var visibility = Object.keys(maxImagesPerViewport).filter(function (viewportName) { return maxImagesPerViewport[viewportName] - 1 === props.index; });
    var hiddenImages = props.imageCount - props.index - 1;
    return (React.createElement(A, { onClick: function () { return props.onClick(props.index); }, "aria-label": "gallery image " + props.index },
        React.createElement(Image, { hoverable: true, value: props.image.fields.ImageComponentImage.value }, hiddenImages > 0 ? (React.createElement(Visibility, { show: visibility },
            React.createElement("div", { className: "feat-image-gallery-component__additional-image-count" },
                React.createElement("div", { className: "feat-image-gallery-component__additional-image-count__label" },
                    React.createElement(Typography, { size: "xl", type: "light" },
                        "+",
                        hiddenImages))))) : null)));
};
