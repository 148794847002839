import { createPutEndpoint } from 'request-registry';
import { getCart } from '../../Cart/getCart/getCart';
import { getCSRFToken } from '../../Security/getCSRFToken/getCSRFToken';
import { getCheckoutProgress } from '../getCheckoutProgress';
export var setOrderInformation = createPutEndpoint({
    url: function () { return '/jelmoli-api/JelmoliCheckout/SetOrderInformation'; },
    headers: {
        'X-RequestVerificationToken': function () { return getCSRFToken(); },
        'X-Requested-With': 'XMLHttpRequest',
    },
    afterSuccess: function () { return Promise.all([getCart.refresh(), getCheckoutProgress.refresh()]); },
});
