var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useLoadNamedOnceOnClientSide } from '@jelmoli/hooks/src/useLoadOnceOnClientSide/useLoadOnceOnClientSide';
import React from 'react';
import './cookie-disclaimer-banner.scss';
export var CookieDisclaimerBanner = function (props) {
    var CoockieDisclaimerBannerLazy = useLoadNamedOnceOnClientSide('CookieDisclaimerBannerLazy', function () {
        return import(/* webpackChunkName: "CookieDisclaimerBannerLazy" */ './CookieDisclaimerBannerLazy');
    });
    return (React.createElement(React.Fragment, null, CoockieDisclaimerBannerLazy ? (React.createElement(CoockieDisclaimerBannerLazy, __assign({}, props))) : (React.createElement("div", { className: "o-cookie-disclaimer-banner o-cookie-disclaimer-banner--activated" }))));
};
