var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useLoadNamedOnceInViewport } from '@jelmoli/hooks/src/useLoadOnceInViewport/useLoadOnceInViewport';
import React from 'react';
export var ShopMobileMainNavigation = function (props) {
    var _a = useLoadNamedOnceInViewport('ShopMobileMainNavigationLazy', function () {
        return import(
        /* webpackChunkName: "ShopMobileMainNavigationLazy", webpackPrefetch: true */ './ShopMobileMainNavigationLazy');
    }, 
    // Placeholder:
    function () { return React.createElement("div", { ref: ref }); }), ref = _a[0], ShopMobileMainNavigationLazy = _a[1];
    return React.createElement(ShopMobileMainNavigationLazy, __assign({}, props));
};
