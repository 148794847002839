var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useLoadNamedOnceOnClientSide } from '@jelmoli/hooks/src/useLoadOnceOnClientSide/useLoadOnceOnClientSide';
import { bindTranslations } from '@jelmoli/i18n';
import { getCart } from '@jelmoli/sitecore-services/src/Cart/getCart/getCart';
import { removeCartLine } from '@jelmoli/sitecore-services/src/Cart/removeCartLine/removeCartLine';
import { updateCartLineQuantity } from '@jelmoli/sitecore-services/src/Cart/updateCartLineQuantity/updateCartLineQuantity';
import { getCheckoutLabels } from '@jelmoli/sitecore-services/src/Checkout/getCheckoutLabels/getCheckoutLabels';
import { trackError } from '@jelmoli/sitecore-services/src/Tracking/Error/ErrorTesting';
import { addProductTracking } from '@jelmoli/sitecore-services/src/Tracking/Product/AddProductTracking';
import { removeProductTracking } from '@jelmoli/sitecore-services/src/Tracking/Product/RemoveProductTracking';
import { AlertBoxIcon } from '@jelmoli/ui-components/src/atoms/Icon/AlertBoxIcon';
import { showErrorNotification, showSuccessNotification, } from '@jelmoli/ui-components/src/atoms/Notification/Notification';
import { PrimaryButton } from '@jelmoli/ui-components/src/atoms/PrimaryButton/PrimaryButton';
import { Spinner } from '@jelmoli/ui-components/src/atoms/Spinner/Spinner';
import { HorizontalSpacer } from '@jelmoli/ui-components/src/helpers/HorizontalSpacer/HorizontalSpacer';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import React from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
export var CheckoutStepCart = function (props) {
    var ProductItem = useLoadNamedOnceOnClientSide('ProductItem', function () {
        return import(/* webpackChunkName: "ProductItem" */ '@jelmoli/ui-components/src/atoms/ProductItem/ProductItem');
    });
    var getCartItems = useGetEndPointLazy(getCart, {});
    var checkoutLabelsData = useGetEndPointLazy(getCheckoutLabels, {});
    if (!getCartItems.hasData || !checkoutLabelsData.hasData) {
        return null;
    }
    var t = bindTranslations(checkoutLabelsData.value);
    var errorText = '';
    if (getCartItems.value.checkoutCleanup) {
        var cartCleanUp = getCartItems.value.checkoutCleanup;
        if (cartCleanUp.removedLineIds.length > 0) {
            errorText = t('CheckoutStepperCartCleanupRemovedNotification');
        }
        else if (cartCleanUp.changedLineIds.length > 0) {
            errorText = t('CheckoutStepperCartCleanupChangedNotification');
        }
    }
    return (React.createElement("div", null,
        errorText.length > 0 && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "feat-checkout__error-notification" },
                React.createElement(VerticalSpacer, { size: "m", responsive: false }),
                React.createElement("div", { className: "feat-checkout__error-notification-text-wrapper" },
                    React.createElement(AlertBoxIcon, { width: 38, keepOriginalColors: true }),
                    React.createElement(HorizontalSpacer, { size: "s", responsive: false }),
                    React.createElement("div", { className: "feat-checkout__error-notification-text" },
                        React.createElement(Typography, { size: "xs", responsive: false }, errorText))),
                React.createElement(VerticalSpacer, { size: "m", responsive: false })),
            React.createElement(VerticalSpacer, { size: "mx", responsive: false }))),
        getCartItems.value.lines.map(function (product, index) { return (React.createElement("div", { key: product.cartLineId },
            ProductItem ? (React.createElement(ProductItem, __assign({ fragileLabel: t('CheckoutNotificationFragile'), bulkyLabel: t('CheckoutNotificationBulky'), installationServiceAvailableLabel: t('CheckoutNotificationInstallationServiceAvailable'), installationServiceMandatoryLabel: t('CheckoutNotificationInstallationServiceMandatory'), installationServiceSelectedLabel: t('CheckoutNotificationInstallationServiceSelected'), customMeasureLabel: t('CheckoutCustomMeasure'), zyseMeLabel: t('CheckoutSizeLabel'), updateCart: function (quantity) {
                    updateCartLineQuantity({}, { cartLineId: product.cartLineId, quantity: quantity })
                        .then(function () { return trackCartUpdate({ product: product, quantity: quantity }); })
                        .catch(function (response) {
                        trackError({
                            page: 'Error occured while adding product in the cart on Checkout',
                            errorMessage: 'Ups, the product could not be added',
                        });
                        showErrorNotification(response.GlobalMessage);
                    });
                }, removeItem: function () {
                    removeCartLine({}, { cartLineId: product.cartLineId })
                        .then(function (response) {
                        showSuccessNotification(response.globalMessage);
                        removeProductTracking({ product: product });
                    })
                        .catch(function (response) {
                        trackError({
                            page: 'Error occured while removing product form the cart in Checkout',
                            errorMessage: response.GlobalMessage,
                        });
                        showErrorNotification(response.GlobalMessage);
                    });
                }, notAvailableLabel: t('CheckoutStepperCartCleanupChangedLineMarkerText'), checkoutError: getCartItems.value.checkoutCleanup &&
                    getCartItems.value.checkoutCleanup.changedLineIds.find(function (id) { return id === product.cartLineId; }), optionSaved: false }, product))) : (React.createElement(Spinner, null)),
            index === getCartItems.value.lines.length - 1 ? (React.createElement(VerticalSpacer, { size: "xl", responsive: false })) : (React.createElement(VerticalSpacer, { size: "l", responsive: false })))); }),
        React.createElement(PrimaryButton, { onClick: function () {
                props.onComplete();
            }, type: "button", size: "tiny", uppercase: true, text: t('CheckoutStepperSaveStepCtaButtonText') }),
        React.createElement(VerticalSpacer, { size: "xl", responsive: false })));
};
/** This function set approapriate tracking event based on user action */
function trackCartUpdate(_a) {
    var product = _a.product, quantity = _a.quantity;
    if (product.quantity > quantity) {
        removeProductTracking({
            product: product,
            quantity: product.quantity - quantity,
        });
    }
    else {
        addProductTracking({
            product: product,
            quantity: quantity - product.quantity,
        });
    }
}
