var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useMiniFormik } from '@jelmoli/hooks/src/useMiniFormik/useMiniFormik';
import { bindTranslations } from '@jelmoli/i18n/index';
import { newNewsletterRegistration } from '@jelmoli/sitecore-services/src/Account/newNewsletterRegistration/newNewsletterRegistration';
import { trackNewsletterRegistration } from '@jelmoli/sitecore-services/src/Tracking/Account/NewsletterTracking';
import { trackError } from '@jelmoli/sitecore-services/src/Tracking/Error/ErrorTesting';
import { FormValidationContext } from 'nested-form-validation/index';
import React from 'react';
import { CheckboxLabelWithLink, CheckboxWithFormFieldSetting, } from '../../atoms/CheckboxValidatable/CheckboxValidatable';
import { DrawerFooter } from '../../atoms/Drawer/Drawer';
import { FormFieldErrorMessage } from '../../atoms/FormFieldErrorMessage/FormFieldErrorMessage';
import { showErrorNotification } from '../../atoms/Notification/Notification';
import { PrimarySubmitButton } from '../../atoms/PrimarySubmitButton/PrimarySubmitButton';
import { SecondaryButton } from '../../atoms/SecondaryButton/SecondaryButton';
import { TextFieldWithFormSetting } from '../../atoms/TextFieldValidatable/TextFieldValidatable';
import { Typography } from '../../helpers/Typography/Typography';
import { VerticalSpacer } from '../../helpers/VerticalSpacer/VerticalSpacer';
import { Visibility } from '../../helpers/Visibility/Visibility';
import { NameForm } from '../NameForm/NameForm';
export var NewsletterContent = function (_a) {
    var registrationLabelsData = _a.registrationLabelsData, isDrawer = _a.isDrawer, openDrawer = _a.openDrawer, setIsConfirmationDrawerOpen = _a.setIsConfirmationDrawerOpen, setShowSuccessRegText = _a.setShowSuccessRegText;
    var form = useMiniFormik({
        initialValues: {
            email: '',
            salutation: '',
            firstName: '',
            lastName: '',
            terms: false,
        },
    });
    var t = bindTranslations(registrationLabelsData);
    return (React.createElement(React.Fragment, null,
        React.createElement(FormValidationContext, null,
            React.createElement(Typography, { size: "l", type: "regular", responsive: false }, t('NewsletterIntroText')),
            React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
            React.createElement(TextFieldWithFormSetting, __assign({ variant: "standard", name: "email", type: "email", formSetting: registrationLabelsData.Email }, form.fieldProps.email, { fullWidth: true })),
            React.createElement(FormFieldErrorMessage, { name: "email", verticalSpaceTop: "xxs" }),
            React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
            React.createElement(NameForm, { fields: {
                    FirstName: registrationLabelsData.FirstName,
                    LastName: registrationLabelsData.LastName,
                    Salutation: registrationLabelsData.Salutation,
                    SalutationSource: registrationLabelsData.SalutationSource,
                }, fieldValues: form.fieldProps }),
            React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
            React.createElement(CheckboxWithFormFieldSetting, __assign({ label: React.createElement(CheckboxLabelWithLink, { field: registrationLabelsData.PrivacyPolicy, link: registrationLabelsData.PrivacyPolicyLink }), name: "terms", formSetting: registrationLabelsData.PrivacyPolicy }, form.fieldProps.terms)),
            React.createElement(FormFieldErrorMessage, { name: "terms", verticalSpaceTop: "xxs" }),
            React.createElement(VerticalSpacer, { size: "xl", responsive: false }),
            isDrawer ? (React.createElement(NewsletterDrawerBtn, { registrationLabelsData: registrationLabelsData, values: form.values, openDrawer: openDrawer, setIsConfirmationDrawerOpen: setIsConfirmationDrawerOpen })) : (React.createElement(NewsletterBtn, { registrationLabelsData: registrationLabelsData, values: form.values, setShowSuccessRegText: setShowSuccessRegText })))));
};
var NewsletterDrawerBtn = function (_a) {
    var registrationLabelsData = _a.registrationLabelsData, values = _a.values, openDrawer = _a.openDrawer, setIsConfirmationDrawerOpen = _a.setIsConfirmationDrawerOpen;
    var t = bindTranslations(registrationLabelsData);
    return (React.createElement(DrawerFooter, null, function () { return (React.createElement(React.Fragment, null,
        React.createElement(PrimarySubmitButton, { type: "button", text: t('NewsletterSubmitButtonLabel'), fullWidth: true, fontResponsive: false, uppercase: true, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, newNewsletterRegistration({}, {
                                email: values.email,
                                firstName: values.firstName,
                                lastName: values.lastName,
                                privacyPolicy: values.terms,
                                salutation: values.salutation,
                            })
                                .then(function () {
                                trackNewsletterRegistration(true);
                                openDrawer && openDrawer(false);
                                setIsConfirmationDrawerOpen && setIsConfirmationDrawerOpen(true);
                            })
                                .catch(function (response) {
                                trackNewsletterRegistration(false);
                                showErrorNotification(response.GlobalMessage);
                                trackError({
                                    page: 'Erorr occured while newsletter registration for guest user',
                                    errorMessage: response.GlobalMessage,
                                });
                            })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); } }),
        React.createElement(VerticalSpacer, { size: "xs", responsive: false }),
        React.createElement(SecondaryButton, { type: "button", text: t('CancelButtonLabel'), fontResponsive: false, fullWidth: true, uppercase: true, onClick: function () {
                openDrawer && openDrawer(false);
            } }))); }));
};
var NewsletterBtn = function (_a) {
    var registrationLabelsData = _a.registrationLabelsData, values = _a.values, setIsConfirmationDrawerOpen = _a.setIsConfirmationDrawerOpen, setShowSuccessRegText = _a.setShowSuccessRegText;
    var t = bindTranslations(registrationLabelsData);
    var onClickHandler = function () {
        newNewsletterRegistration({}, {
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            privacyPolicy: values.terms,
            salutation: values.salutation,
        })
            .then(function () {
            trackNewsletterRegistration(true);
            setIsConfirmationDrawerOpen && setIsConfirmationDrawerOpen(true);
            setShowSuccessRegText && setShowSuccessRegText(true);
        })
            .catch(function (response) {
            trackNewsletterRegistration(false);
            showErrorNotification(response.GlobalMessage);
            trackError({
                page: 'Erorr occured while newsletter registration for guest user',
                errorMessage: response.GlobalMessage,
            });
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Visibility, { show: ['xs', 'sm'] },
            React.createElement(PrimarySubmitButton, { type: "button", text: t('NewsletterSubmitButtonLabel'), fullWidth: true, fontResponsive: false, uppercase: true, onClick: onClickHandler, scrollPosition: "center" })),
        React.createElement(Visibility, { hide: ['xs', 'sm'] },
            React.createElement(PrimarySubmitButton, { type: "button", text: t('NewsletterSubmitButtonLabel'), scrollPosition: "center", fontResponsive: false, uppercase: true, onClick: onClickHandler }))));
};
