var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Loadable from 'react-loadable';
/**
 * The todo component is a placeholder for backend developers
 * to have a basic mock to start with
 */
export var EditorHintComponent = function (_a) {
    var parameters = _a.parameters;
    return (React.createElement(React.Fragment, null,
        React.createElement("pre", { style: { display: 'none' } }, JSON.stringify(parameters)),
        React.createElement(EditorHint, { title: parameters.title, messageHtml: parameters.message, messageType: parameters.messageType })));
};
// This component is only for content editing so we load it lazy
export var EditorHint = Loadable({
    // Don't show anything during load
    loading: function () { return null; },
    loader: function () { return import(/* webpackChunkName: "EditorHintDetails" */ './EditorHintDetails'); },
    render: function (_a, props) {
        var EditorHintDetails = _a.EditorHintDetails;
        return React.createElement(EditorHintDetails, __assign({}, props));
    },
});
