import { Column, Row } from '@jelmoli/bootstrap-grid';
import { useIsVisibleInViewport } from '@jelmoli/hooks/src/useKeyboardEffect/useIsVisibleInViewport/useIsVisibleInViewport';
import classnames from 'classnames';
import React, { useRef } from 'react';
import { ChevronLeftIcon } from '../../atoms/Icon/ChevronLeftIcon';
import { ChevronRightIcon } from '../../atoms/Icon/ChevronRightIcon';
import { HorizontalScrollWrapper } from '../HorizontalScrollWrapper/HorizontalScrollWrapper';
import { VerticalSpacer } from '../VerticalSpacer/VerticalSpacer';
import { Visibility } from '../Visibility/Visibility';
import './slider.scss';
/* Show viewports where total number of slides is bigger then predefined minimum amount of slides per viewport */
var setSliderVisibility = function (minSlideAmount, slideAmount) {
    var viewportNames = Object.keys(minSlideAmount);
    var visibility = viewportNames.filter(function (viewportName) { return slideAmount > minSlideAmount[viewportName]; });
    return visibility;
};
export var Slider = function (props) {
    var refArray = useRef([]);
    if (!refArray) {
        return null;
    }
    var counter = 0;
    var getRef = function () { return (refArray.current[counter++] = React.createRef()); };
    var slides = props.children(getRef);
    return (React.createElement(React.Fragment, null, props.productSlider ? (React.createElement("div", { className: "h-slider" },
        React.createElement(VerticalSpacer, { size: "mx" }),
        React.createElement(Row, null,
            React.createElement(HorizontalScrollWrapper, { rightArrow: React.createElement(SliderRightButton, { slideAmount: refArray.current.length, minSlideAmount: props.minSlideAmount, productSlider: true }), leftArrow: React.createElement(SliderLeftButton, { slideAmount: refArray.current.length, minSlideAmount: props.minSlideAmount, productSlider: true }), fullWidth: true, isSlider: true, productSlider: true }, slides)),
        React.createElement(Visibility, { show: setSliderVisibility(props.minSlideAmount, refArray.current.length) },
            React.createElement("div", { className: classnames('h-slider__pagination-wrapper', 'h-slider__pagination-wrapper--product-carousel', props.paginationPosition && "h-slider__pagination-wrapper--" + props.paginationPosition) }, refArray.current.map(function (ref, i) { return (React.createElement(SliderPagination, { key: i, slideRef: ref, groupDots: props.minSlideAmount.xl })); })),
            props.productSlider ? (React.createElement(Visibility, { hide: ['lg', 'xl'] },
                React.createElement(VerticalSpacer, { size: "mx" }))) : (React.createElement(React.Fragment, null))))) : (React.createElement("div", { className: "h-slider" },
        React.createElement(VerticalSpacer, { size: "mx" }),
        React.createElement(Visibility, { show: setSliderVisibility(props.minSlideAmount, refArray.current.length) },
            React.createElement("div", { className: classnames('h-slider__pagination-wrapper', props.paginationPosition && "h-slider__pagination-wrapper--" + props.paginationPosition) }, refArray.current.map(function (ref, i) { return (React.createElement(SliderPagination, { key: i, slideRef: ref, groupDots: props.minSlideAmount.xl })); }))),
        React.createElement(VerticalSpacer, { size: 's', show: setSliderVisibility(props.minSlideAmount, refArray.current.length) }),
        React.createElement(Row, null,
            React.createElement(HorizontalScrollWrapper, { rightArrow: React.createElement(SliderRightButton, { slideAmount: refArray.current.length, minSlideAmount: props.minSlideAmount }), leftArrow: React.createElement(SliderLeftButton, { slideAmount: refArray.current.length, minSlideAmount: props.minSlideAmount }), fullWidth: true, isSlider: true }, slides))))));
};
export var Slide = React.forwardRef(function (_a, ref) {
    var children = _a.children, sliderColumnCount = _a.sliderColumnCount;
    return (React.createElement(Column, { size: sliderColumnCount, className: "h-slide" },
        React.createElement("div", { ref: ref }, children)));
});
var SliderLeftButton = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement(Visibility, { show: setSliderVisibility(props.minSlideAmount, props.slideAmount) },
            React.createElement("div", { className: classnames(props.productSlider
                    ? 'h-slider__button h-slider__button-product-slider h-slider__button-product-slider--left'
                    : 'h-slider__button h-slider__button--left') },
                React.createElement(ChevronLeftIcon, { height: 14 })))));
};
var SliderRightButton = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement(Visibility, { show: setSliderVisibility(props.minSlideAmount, props.slideAmount) },
            React.createElement("div", { className: classnames(props.productSlider
                    ? 'h-slider__button h-slider__button-product-slider h-slider__button-product-slider--right'
                    : 'h-slider__button h-slider__button--right') },
                React.createElement(ChevronRightIcon, { height: 14 })))));
};
var SliderPagination = function (props) {
    var isVisibleInViewport = useIsVisibleInViewport(0.1, props.slideRef).isVisibleInViewport;
    return (React.createElement("button", { className: classnames('h-slider__pagination-button', props.groupDots && "h-slider__pagination-button--" + props.groupDots, isVisibleInViewport && 'h-slider__pagination-button--active') }));
};
