import { bindTranslations } from '@jelmoli/i18n';
import { getOrdersLabels } from '@jelmoli/sitecore-services/src/Account/getOrdersLabels/getOrdersLabels';
import { getReservationDetail } from '@jelmoli/sitecore-services/src/Account/getReservationDetail/getReservationDetail';
import { Image } from '@jelmoli/ui-components/src/atoms/Image/Image';
import { Separator } from '@jelmoli/ui-components/src/atoms/Separator/Separator';
import { TextLink } from '@jelmoli/ui-components/src/atoms/TextLink/TextLink';
import { A } from '@jelmoli/ui-components/src/helpers/A/A';
import { HorizontalSpacer } from '@jelmoli/ui-components/src/helpers/HorizontalSpacer/HorizontalSpacer';
import { hoverAnchorClassName } from '@jelmoli/ui-components/src/helpers/HoverAnchor/HoverAnchor';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { Visibility } from '@jelmoli/ui-components/src/helpers/Visibility/Visibility';
import { ReservationTitle } from '@jelmoli/ui-components/src/organism/OrderItem/OrderItem';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
import './myaccount-reservation.scss';
export var MyAccountReservationDetail = function (props) {
    var reservationLabels = useGetEndPointLazy(getOrdersLabels).value;
    var reservationDetail = useGetEndPointLazy(getReservationDetail, { reservationId: props.reservationId }).value;
    var _a = useState(''), orderStatus = _a[0], setOrderStatus = _a[1];
    useEffect(function () {
        if (!reservationLabels || !reservationDetail) {
            return;
        }
        setOrderStatus(getStatus(reservationDetail.status, reservationLabels));
    }, [reservationLabels, reservationDetail]);
    if (!reservationLabels || !reservationDetail) {
        return null;
    }
    var t = bindTranslations(reservationLabels);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "feat-myaccount-reservation__link-wrapper", onClick: props.onClose },
            React.createElement(A, { className: "feat-myaccount-reservation__link", "aria-label": t('OrderBackButtonLabel') },
                React.createElement(Typography, { size: "s", type: "medium", responsive: false }, t('OrderBackButtonLabel')))),
        React.createElement(VerticalSpacer, { size: "m" }),
        React.createElement(Separator, { shade: "two" }),
        React.createElement(VerticalSpacer, { size: "mx" }),
        React.createElement(Typography, { size: "xl", type: "medium" },
            React.createElement(ReservationTitle, { reservedUntilDays: reservationDetail.reservedUntilDays, status: reservationDetail.status })),
        React.createElement(VerticalSpacer, { size: "mx" }),
        React.createElement("div", { className: "feat-myaccount-reservation__detail" },
            React.createElement("div", null,
                React.createElement(Typography, { size: "xs", type: "medium", uppercase: true, tag: "p" },
                    React.createElement("span", { className: "feat-myaccount-reservation__detail-title" }, t('ReservationStatusLabel'))),
                React.createElement(Typography, { size: "l", type: "medium" }, orderStatus)),
            React.createElement("div", null,
                React.createElement(HorizontalSpacer, { size: "xl", hide: ['xs', 'sm'] }),
                React.createElement(VerticalSpacer, { size: "xs", show: ['xs', 'sm'] })),
            React.createElement("div", null,
                React.createElement(Typography, { size: "xs", type: "medium", uppercase: true, tag: "p" },
                    React.createElement("span", { className: "feat-myaccount-reservation__detail-title" }, t('ReservationDate'))),
                React.createElement(Typography, { size: "l" }, reservationDetail.date)),
            React.createElement("div", null,
                React.createElement(HorizontalSpacer, { size: "xl", hide: ['xs', 'sm'] }),
                React.createElement(VerticalSpacer, { size: "xs", show: ['xs', 'sm'] })),
            React.createElement("div", null,
                React.createElement(Typography, { size: "xs", type: "medium", uppercase: true, tag: "p" },
                    React.createElement("span", { className: "feat-myaccount-reservation__detail-title" }, t('OrderNumber'))),
                React.createElement(Typography, { size: "l" }, reservationDetail.confirmationId))),
        React.createElement(VerticalSpacer, { size: "xl" }),
        reservationDetail.lines.map(function (reservationLine) { return (React.createElement(React.Fragment, { key: reservationLine.productId },
            React.createElement(Separator, { shade: "two" }),
            React.createElement(VerticalSpacer, { size: "l" }),
            React.createElement(A, { href: reservationLine.productUrl, className: classnames('feat-myaccount-reservation__article', {
                    'feat-myaccount-reservation__article-offline': !reservationLine.imageUrl,
                }) },
                React.createElement("div", { className: "feat-myaccount-reservation__image-wrapper" }, reservationLine.imageUrl ? (React.createElement(Image, { value: { src: reservationLine.imageUrl, alt: reservationLine.productName } })) : (React.createElement("div", { className: "feat-myaccount-order-detail__image-offline" }))),
                React.createElement(HorizontalSpacer, { size: "s", responsive: false }),
                React.createElement("div", { className: "feat-myaccount-reservation__article-info" },
                    React.createElement(Typography, { type: "medium", size: "xs", responsive: false, uppercase: true }, reservationLine.brandName),
                    React.createElement(VerticalSpacer, { size: "xs", responsive: false }),
                    React.createElement(Typography, { size: "xs", responsive: false }, reservationLine.productName),
                    (reservationLine.size || reservationLine.color) && (React.createElement(React.Fragment, null,
                        React.createElement(VerticalSpacer, { size: "xxs", responsive: false }),
                        React.createElement("div", null,
                            reservationLine.size && (React.createElement(React.Fragment, null,
                                React.createElement(Typography, { size: "xs", responsive: false },
                                    t('OrderSize'),
                                    " ",
                                    reservationLine.size),
                                React.createElement(HorizontalSpacer, { size: "xs", responsive: false }))),
                            reservationLine.color && (React.createElement(Typography, { size: "xs", responsive: false },
                                t('OrderColor'),
                                " ",
                                reservationLine.color))))),
                    React.createElement(VerticalSpacer, { size: "xs", responsive: false }),
                    React.createElement("div", { className: "feat-myaccount-reservation__text-alignement" },
                        React.createElement(Typography, { size: "xs", responsive: false },
                            reservationLine.quantity,
                            "x"),
                        React.createElement(Typography, { size: "xs", type: "medium", responsive: false }, reservationLine.price)))),
            React.createElement(VerticalSpacer, { size: "l" }))); }),
        React.createElement(Separator, { shade: "two" }),
        React.createElement(VerticalSpacer, { size: "l" }),
        React.createElement(Typography, { type: "light", size: "m" }, t('PickupSummaryTitle')),
        React.createElement(VerticalSpacer, { size: "mx" }),
        React.createElement(Typography, { size: "l", type: "medium", tag: "p" }, reservationDetail.locationName),
        React.createElement(Typography, { size: "l", tag: "p" },
            reservationDetail.locationStreet,
            reservationDetail.locationHouseNumber && " " + reservationDetail.locationHouseNumber,
            ",",
            ' ',
            reservationDetail.locationPostalCode,
            " ",
            reservationDetail.locationCity),
        React.createElement(Typography, { size: "l", tag: "p" }, reservationDetail.zoneTitle),
        React.createElement(VerticalSpacer, { size: "m" }),
        React.createElement(Typography, { size: "l", type: "medium" }, t('OpeningHours')),
        React.createElement("div", { className: "feat-myaccount-reservation__opening-hours" },
            React.createElement(Typography, { size: "l" }, reservationDetail.locationOpeningHours)),
        React.createElement(VerticalSpacer, { size: "m" }),
        React.createElement(Typography, { size: "l", type: "medium" }, t('InformationContact')),
        React.createElement(VerticalSpacer, { size: "m" }),
        React.createElement(Visibility, { show: ['xs', 'sm'] },
            React.createElement("span", { className: classnames('feat-myaccount-reservation__phone', hoverAnchorClassName) },
                React.createElement(TextLink, { "aria-label": "call location", type: "tel", underlined: false, href: "tel:" + reservationDetail.locationTelephoneNumber },
                    React.createElement(Typography, { size: "m" }, reservationDetail.locationTelephoneNumber)))),
        React.createElement(Visibility, { hide: ['xs', 'sm'] },
            React.createElement("span", { className: "feat-myaccount-reservation__phone" },
                React.createElement(Typography, { size: "m" }, reservationDetail.locationTelephoneNumber))),
        React.createElement(VerticalSpacer, { size: "l" })));
};
function getStatus(status, reservationLabels) {
    var t = bindTranslations(reservationLabels.ReservationStatusList.value);
    if (status === 'Pending') {
        return t('Pending');
    }
    if (status === 'PickedUp') {
        return t('PickedUp');
    }
    if (status === 'Expired') {
        return t('Expired');
    }
    if (status === 'Cancelled') {
        return t('Cancelled');
    }
    if (status === 'Confirmed') {
        return t('Confirmed');
    }
    return t('ReadyForPickup');
}
