var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useDelayUntilMouseUp } from '@jelmoli/hooks/src/useDelayUntilMouseup/useDelayUntilMouseUp';
import { useLoadNamedOnceOnClientSide } from '@jelmoli/hooks/src/useLoadOnceOnClientSide/useLoadOnceOnClientSide';
import React from 'react';
import { TextField } from '../TextField/TextField';
/**
 * Text masks help users to input values but
 * are not needed for SSR
 *
 * The MaskedInput will lazy load the clientside
 * code
 *
 * @see https://text-mask.github.io/text-mask/
 * @see https://github.com/text-mask/text-mask/tree/master/react#readme
 * @see https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md#readme
 */
export var MaskedInput = function (props) {
    var MaskedInputLazy = useLoadNamedOnceOnClientSide('MaskedInputLazy', function () {
        return import(/* webpackChunkName: "MaskedInputLazy" */ './MaskedInputLazy');
    });
    var onBlur = useDelayUntilMouseUp(props.onBlur || (function () { }));
    var mask = props.mask, guide = props.guide, pipe = props.pipe, textFieldProps = __rest(props, ["mask", "guide", "pipe"]);
    return MaskedInputLazy ? (React.createElement(MaskedInputLazy, __assign({}, props, { onBlur: onBlur }))) : (React.createElement(TextField, __assign({}, textFieldProps, { onBlur: onBlur })));
};
