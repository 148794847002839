var cache = new WeakMap();
var noValidation = function () { return undefined; };
/**
 * Returns a function which returns an error message if any validation failed
 *
 * Usage:
 *
 * const validator = createFormFieldValidator(fields.EmailFieldSetting);
 * const error = validator('joe@doe.test");
 * // will return void or an error text message
 * console.log(error);
 *
 *
 * The returned function will always be the same for a given fieldItem so ensure
 * to not mutate a fieldItem
 */
export var createFormFieldValidator = function (fieldItem) {
    if (!fieldItem) {
        return noValidation;
    }
    var fromCache = cache.get(fieldItem);
    if (!fromCache) {
        var validator = newFormFieldValidator(fieldItem);
        cache.set(fieldItem, validator);
        return validator;
    }
    return fromCache;
};
var validationErrorMapping = {
    IsRequired: 'IsRequiredError',
    MinLength: 'MinLengthError',
    MinValue: 'MinValueError',
    Regexp1: 'Regexp1Error',
    MaxLength: 'MaxLengthError',
    MaxValue: 'MaxValueError',
};
var validationTypes = Object.keys(validationErrorMapping);
var validations = {
    IsRequired: function (value) { return value.length > 0; },
    MinValue: function (value, fields) { return fields.MinValue !== undefined && Number(value) >= Number(fields.MinValue.value); },
    MaxValue: function (value, fields) { return fields.MaxValue !== undefined && Number(value) <= Number(fields.MaxValue.value); },
    Regexp1: function (value, fields) { return fields.Regexp1 !== undefined && new RegExp(fields.Regexp1.value).test(value); },
    MinLength: function (value, fields) { return fields.MinLength !== undefined && value.length >= Number(fields.MinLength.value); },
    MaxLength: function (value, fields) { return fields.MaxLength !== undefined && value.length <= Number(fields.MaxLength.value); },
};
/**
 * Gets the value for the given field property
 */
export var getFormFieldSettingsProperty = function (_a, propertyName) {
    var fields = _a.fields;
    var wrappedValue = fields[propertyName];
    return wrappedValue ? wrappedValue.value : undefined;
};
var newFormFieldValidator = function (_a) {
    var fields = _a.fields;
    return function (value) {
        var trimedValue = (value || '').trim();
        for (var _i = 0, validationTypes_1 = validationTypes; _i < validationTypes_1.length; _i++) {
            var validation = validationTypes_1[_i];
            var field = fields[validation];
            // If error
            if (field && field.value && !validations[validation](trimedValue, fields)) {
                // Return the error message
                return (fields[validationErrorMapping[validation]] || { value: '' }).value;
            }
        }
    };
};
