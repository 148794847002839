/** Please don't use this value directly - call `getUserLanguage()` instead */
export var SITE_LANGUAGE_DEFAULT_VALUE = 'de';
/**
 * Returns the current language e.g. 'de'
 *
 * This function should only be used on client side as it will
 * always return the default language on server side
 */
export var getUserLanguage = (function () {
    if (typeof document === 'undefined') {
        return SITE_LANGUAGE_DEFAULT_VALUE;
    }
    if (getUserLanguage.cache !== undefined) {
        return getUserLanguage.cache;
    }
    var htmlTag = document.documentElement;
    var userLanguage = (htmlTag.getAttribute('lang') || SITE_LANGUAGE_DEFAULT_VALUE);
    getUserLanguage.cache = userLanguage;
    return getUserLanguage.cache;
});
