//
// Global Sitecore Styles
//
// Please don't touch (really)
//
//     ,-------,  ,  ,   ,-------,
//      )  ,' /(  |\/|   )\ ',  (
//       )'  /  \ (qp)  /  \  '(
//        ) /___ \_\/(_/ ___\ (
//         '    '-(   )-'    '
//                )w^w(
//                (W_W)
//                 ((
//                  ))
//                 ((
//                  )
//
import '@jelmoli/normalize-css';
// Sitecore has no component for body gradients
// therefore we have to import them globally
import '@jelmoli/ui-components/src/helpers/BodyGradient/body-gradient.scss';
// Browser detection
import '@jelmoli/ui-components/src/helpers/BrowserDetection/BrowserDetection';
import { readEditModeStateFromDom } from '@jelmoli/ui-components/src/helpers/EditMode/editMode';
// Needed to fix issues that are occuring globaly, like repeating of backgorund gradient
import '@jelmoli/ui-components/src/helpers/GlobalStyle/global-style.scss';
// Load EditModeHelpers if edit mode is detected
if (readEditModeStateFromDom()) {
    import('@jelmoli/ui-components/src/helpers/EditModeHelpers/EditModeHelpers' /* webpackChunkName: "EditModeHelpers" */);
}
