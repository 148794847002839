import { useEffect, useRef, useState } from 'react';
var isThenable = function (input) {
    return input && typeof input.then === 'function';
};
/**
 * If an ui event like a onClick returns a promise
 * this utility hook will create a uiEvent listener which
 * ignores further incomming clicks until the promise
 * is resolved or rejected
 *
 * The delay allows to improve the perceived performance
 * by showing the "loading" or "disabled" state after a delay
 *
 * Returns the callback and a isBusy flag
 *
 * Usage:
 *
 * ```ts
 * const [callback, isBusy] = useAsyncUiEvent(async () => {
 *  //...
 * });
 * ```
 */
export function useAsyncUiEvent(uiEventCallback, delay, blockDuringBusy) {
    if (delay === void 0) { delay = 300; }
    var isMountedRef = useIsMountedRef();
    var _a = useState(false), isBusy = _a[0], setIsBusy = _a[1];
    var _b = useState(false), isPromiseRunning = _b[0], setIsPromiseRunning = _b[1];
    var timerRef = useRef();
    // Create a new callback whenever isBusy changes
    var wrappedUiEvent = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (!uiEventCallback || (isBusy && blockDuringBusy !== false)) {
            return;
        }
        // eslint-disable-next-line prefer-spread
        var returnValue = uiEventCallback.apply(null, args);
        if (isThenable(returnValue)) {
            setIsBusy(true);
            timerRef.current = window.setTimeout(function () {
                // Only update state if still mounted
                // to prevent state has changed after unmounted errors
                if (isMountedRef.current) {
                    setIsPromiseRunning(true);
                }
            }, delay);
            var onDone = function () {
                // Only update state if still mounted
                // to prevent state has changed after unmounted errors
                if (isMountedRef.current) {
                    setIsBusy(false);
                    setIsPromiseRunning(false);
                }
                clearTimeout(timerRef.current);
            };
            returnValue.then(onDone, onDone).then(function () { return returnValue; });
        }
    };
    return [wrappedUiEvent, isPromiseRunning];
}
/** Helper to check if the component is still mounted */
function useIsMountedRef() {
    var ref = useRef(true);
    useEffect(function () {
        ref.current = true;
        return function () {
            ref.current = false;
        };
    });
    return ref;
}
