import { Column, Row } from '@jelmoli/bootstrap-grid';
import { useLoadNamedOnceOnClientSide } from '@jelmoli/hooks/src/useLoadOnceOnClientSide/useLoadOnceOnClientSide';
import { bindTranslations } from '@jelmoli/i18n';
import { getCartTotalCount } from '@jelmoli/sitecore-services/src/Cart/getCartTotalCount/getCartTotalCount';
import { getCheckoutLabels } from '@jelmoli/sitecore-services/src/Checkout/getCheckoutLabels/getCheckoutLabels';
import { Spinner } from '@jelmoli/ui-components/src/atoms/Spinner/Spinner';
import { Container } from '@jelmoli/ui-components/src/helpers/Container/Container';
import { KeepInView } from '@jelmoli/ui-components/src/helpers/KeepInView/KeepInView';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import React from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
import './checkout.scss';
import { CheckoutEmptyState } from './CheckoutEmptyState/CheckoutEmptyState';
import { CheckoutStepper } from './CheckoutStepper/CheckoutStepper';
export var CheckoutComponent = function () {
    var CheckoutSummaryBox = useLoadNamedOnceOnClientSide('CheckoutSummaryBox', function () {
        return import(/* webpackChunkName: "CheckoutSummaryBox" */ './CheckoutSummaryBox/CheckoutSummaryBox');
    });
    var getCartLines = useGetEndPointLazy(getCartTotalCount, {}).value;
    var checkoutLabelsData = useGetEndPointLazy(getCheckoutLabels, {}).value;
    if (!getCartLines || !checkoutLabelsData) {
        return null;
    }
    var t = bindTranslations(checkoutLabelsData);
    return (React.createElement(Container, { size: "s", backgroundFill: true },
        React.createElement(VerticalSpacer, { size: "l" }),
        React.createElement(Row, null,
            getCartLines.productTotalCount !== 0 && (React.createElement(React.Fragment, null,
                React.createElement(Column, { size: { xs: 12, md: 6, lg: 6, xl: 5 }, offset: { xs: 0, lg: 0, xl: 1 } },
                    React.createElement(CheckoutStepper, null),
                    React.createElement(VerticalSpacer, { size: "l", responsive: false, show: ['xs', 'sm'] })),
                React.createElement(Column, { size: { xs: 12, md: 6, lg: 5, xl: 4 }, offset: { xs: 0, lg: 1, xl: 1 }, verticalAlignment: "justify" },
                    React.createElement(KeepInView, null, CheckoutSummaryBox ? React.createElement(CheckoutSummaryBox, null) : React.createElement(Spinner, null))))),
            getCartLines.productTotalCount === 0 && (React.createElement(Column, { size: { xs: 12, md: 8, xl: 6 }, offset: { xs: 0, md: 2, xl: 3 } },
                React.createElement(CheckoutEmptyState, null)))),
        React.createElement(VerticalSpacer, { size: "l" })));
};
