var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { usePath } from '@jelmoli/hooks/src/usePath/usePath';
import { bindTranslations } from '@jelmoli/i18n';
import { getLoginLabels } from '@jelmoli/sitecore-services/src/Account/getLoginLabels/getLoginLabels';
import { isUserLoggedInLazy } from '@jelmoli/sitecore-services/src/Account/isUserLoggedIn/isUserLoggedInLazy';
import { HeaderButton, HeaderButtonCheckMark } from '@jelmoli/ui-components/src/atoms/HeaderButton/HeaderButton';
import { Profile } from '@jelmoli/ui-components/src/atoms/Icon/Profile';
import { LoginDrawer } from '@jelmoli/ui-components/src/organism/LoginDrawer/LoginDrawer';
import React, { useEffect, useState } from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
import './header-account-button.scss';
export var HeaderAccountButtonComponent = function () {
    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var _b = useState(false), isInMyAccountSection = _b[0], setIsInMyAccountSection = _b[1];
    var isLoggedIn = isUserLoggedInLazy();
    var getLoginLabelsData = useGetEndPointLazy(getLoginLabels, {}).value;
    var t = bindTranslations(getLoginLabelsData);
    var path = usePath();
    useEffect(function () {
        var foundArray = /account/g.test(path.toLowerCase());
        if (foundArray) {
            return setIsInMyAccountSection(true);
        }
        return setIsInMyAccountSection(false);
    }, [path]);
    return (React.createElement(React.Fragment, null,
        React.createElement(HeaderButton, __assign({ Icon: Profile }, (isInMyAccountSection && { activeIcon: React.createElement("span", { className: "feat-header-account-btn--active" }) }), { title: t('HeaderAccountButtonTitle'), onClick: function () {
                setTimeout(function () {
                    if (isLoggedIn && getLoginLabelsData) {
                        document.location.href = getLoginLabelsData.LoginMyAccountLink.value.href || '/';
                    }
                    else {
                        setIsOpen(!isOpen);
                    }
                }, 800);
            } }), isLoggedIn && React.createElement(HeaderButtonCheckMark, null)),
        React.createElement(LoginDrawer, { isOpen: isOpen, openDrawer: setIsOpen })));
};
