var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import MenuItem from '@material-ui/core/MenuItem';
import classnames from 'classnames';
import React from 'react';
import { Typography } from '../../helpers/Typography/Typography';
import './dropdown-item.scss';
export var DropdownItem = React.forwardRef(function (props, ref) { return (React.createElement(MenuItem, __assign({ ref: ref }, props, { selected: props.selected, disabled: props.disabled, classes: {
        root: classnames('a-dropdown-item', { 'a-dropdown-item--headline': props.headline }, props.className && props.className),
        selected: 'a-dropdown-item--selected',
    }, value: props.value }), typeof props.children === 'string' ? (React.createElement(TypographyMenuItem, { disabled: props.disabled, selected: props.selected, label: props.children, headline: props.headline })) : (props.children))); });
var TypographyMenuItem = function (props) { return (React.createElement(Typography, { size: "s", responsive: false, type: props.selected || props.headline ? 'medium' : 'regular' }, props.label)); };
