import { useEffect, useRef } from 'react';
/**
 * 	timestamp of the last time the mouse was pressed down
 *  is always reset to 0 after mouse up
 */
var lastMouseDown = 0;
/**
 *  the amount of mounted components using useBlurOnMouseRelease
 */
var listeners = 0;
/** Empty Function */
var noop = function () { };
var cleanup = noop;
function registerEvents() {
    // Don't register any events during ssr
    if (typeof document === 'undefined') {
        return noop;
    }
    var html = document.documentElement;
    cleanup();
    lastMouseDown = 0;
    var onMouseDown = function () {
        lastMouseDown = Date.now();
    };
    var onMouseUp = function () {
        lastMouseDown = 0;
    };
    html.addEventListener('mousedown', onMouseDown);
    html.addEventListener('mouseup', onMouseUp);
    return function () {
        html.removeEventListener('mousedown', onMouseDown);
        html.removeEventListener('mouseup', onMouseUp);
    };
}
export var useDelayUntilMouseUp = function (callback) {
    var callbackRef = useRef(callback);
    callbackRef.current = callback;
    // Track wether this component is mounted
    var isMountedRef = useIsMountedRef();
    // For server side rendering return the callback right away
    if (typeof document === 'undefined') {
        return callback;
    }
    // start / stop listening for mouse events:
    useEffect(function () {
        listeners++;
        if (listeners === 1) {
            cleanup = registerEvents();
        }
        return function () {
            listeners--;
            if (listeners === 0) {
                cleanup();
                cleanup = noop;
            }
        };
    }, []);
    var delayedCallback = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (Date.now() - lastMouseDown > 200) {
            return callbackRef.current.apply(callbackRef, args);
        }
        var timer;
        var onMouseUp = function () {
            clearTimeout(timer);
            document.documentElement.removeEventListener('mouseup', onMouseUp);
            if (isMountedRef.isMounted) {
                return callbackRef.current.apply(callbackRef, args);
            }
        };
        timer = setTimeout(onMouseUp, 2000);
        document.documentElement.addEventListener('mouseup', onMouseUp);
    };
    return delayedCallback;
};
var useIsMountedRef = function () {
    var mountedRef = useRef({ isMounted: false });
    useEffect(function () {
        mountedRef.current.isMounted = true;
        return function () {
            mountedRef.current.isMounted = false;
        };
    }, []);
    return mountedRef.current;
};
