import { isUserLoggedInLazy } from '../../Account/isUserLoggedIn/isUserLoggedInLazy';
import { trackPageMeta, trackPageMetaLogedIn } from '../PageMeta/PageMetaAPI';
export function initialPageTracking() {
    var isLoggedIn = isUserLoggedInLazy();
    if (isLoggedIn) {
        trackPageMetaLogedIn();
    }
    else {
        trackPageMeta();
    }
}
