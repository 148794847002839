var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { addCartLine } from '@jelmoli/sitecore-services/src/Cart/addCartLine/addCartLine';
import { addGiftcardCartLine } from '@jelmoli/sitecore-services/src/Cart/addGiftcardCartLine/addGiftcardCartLine';
import { addGiftWrappingPaperProduct } from '@jelmoli/sitecore-services/src/Cart/addGiftWrappingPaperProduct/addGiftWrappingPaperProduct';
import { addZyseMeCartLine } from '@jelmoli/sitecore-services/src/Cart/addZyseMeCartLine/addZyseMeCartLine';
import { trackError } from '@jelmoli/sitecore-services/src/Tracking/Error/ErrorTesting';
import { showErrorNotification, showSuccessNotification, } from '@jelmoli/ui-components/src/atoms/Notification/Notification';
export function addToCart(productId, variantId, quantity, addedToCart, text) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!text) return [3 /*break*/, 2];
                    return [4 /*yield*/, addGiftWrappingPaperProduct({}, {
                            productId: productId,
                            variantId: variantId,
                            quantity: quantity,
                            text: text,
                        })
                            .then(function (response) {
                            showSuccessNotification(response.globalMessage);
                            addedToCart(true);
                        })
                            .catch(function (response) {
                            trackError({
                                page: 'Error occured while adding product to car on Product Details Page',
                                errorMessage: response.GlobalMessage || 'Technical error',
                            });
                            showErrorNotification(response.GlobalMessage);
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, addCartLine({}, {
                        productId: productId,
                        variantId: variantId,
                        quantity: quantity,
                    })
                        .then(function (response) {
                        showSuccessNotification(response.globalMessage);
                        addedToCart(true);
                    })
                        .catch(function (response) {
                        trackError({
                            page: 'Error occured while adding product to car on Product Details Page',
                            errorMessage: response.GlobalMessage || 'Technical error',
                        });
                        showErrorNotification(response.GlobalMessage);
                    })];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    });
}
/**
 *
 * @param productId - ZyseMe Product Reference
 * @param initialProductId - Initial Base Product Id
 * @param initialVariantId - Initial Base Product Variant
 * @param garmentModelId - ZyseMe Model Id
 * @param garmentUnitId - ZyseMe Unit Id
 * @param quantity - Amount
 * @param addedToCart
 */
// eslint-disable-next-line max-params
export function addZyseMeToCart(productId, initialProductId, initialVariantId, garmentModelId, garmentUnitId, quantity, addedToCart) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, addZyseMeCartLine({}, {
                        productId: productId,
                        initialProductId: initialProductId,
                        initialVariantId: initialVariantId,
                        garmentModelId: garmentModelId,
                        garmentUnitId: garmentUnitId,
                        quantity: quantity,
                    })
                        .then(function (response) {
                        showSuccessNotification(response.globalMessage);
                        addedToCart(true);
                    })
                        .catch(function (response) {
                        trackError({
                            page: 'Error occured while adding ZyseMe product to car on Product Details Page',
                            errorMessage: response.GlobalMessage || 'Technical error',
                        });
                        showErrorNotification(response.GlobalMessage);
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
export function addGiftcardToCart(productId, variantId, dynamicPrice, addedToCart) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, addGiftcardCartLine({}, {
                        productId: productId,
                        variantId: variantId,
                        dynamicPrice: dynamicPrice * 100,
                    })
                        .then(function (response) {
                        showSuccessNotification(response.globalMessage);
                        addedToCart(true);
                    })
                        .catch(function (response) {
                        trackError({
                            page: 'Error occured while adding ZyseMe product to car on Product Details Page',
                            errorMessage: response.GlobalMessage || 'Technical error',
                        });
                        showErrorNotification(response.GlobalMessage);
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
