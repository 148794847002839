import classnames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { HorizontalSpacer } from '../../helpers/HorizontalSpacer/HorizontalSpacer';
import { Typography } from '../../helpers/Typography/Typography';
import { CheckmarkMaterialIcon } from '../Icon/CheckmarkMaterialIcon';
import { MinusMaterialIcon } from '../Icon/MinusMaterialIcon';
import './checkbox.scss';
export var Checkbox = function (props) {
    var divElementRef = useRef(null);
    useEffect(function () {
        if (props.autoFocus && divElementRef.current) {
            divElementRef.current.focus();
        }
    }, [props.autoFocus]);
    return (React.createElement("div", { className: classnames('a-checkbox', props.disabled && 'a-checkbox--disabled'), ref: divElementRef, tabIndex: 0, "data-name": props.name, "aria-checked": props.intermediate ? 'mixed' : props.value, role: "checkbox", "aria-disabled": props.disabled, onClick: function () {
            if (props.disabled) {
                return;
            }
            props.onChange(!props.value);
        }, onKeyDown: function (event) {
            if (event.keyCode === 32 && !props.disabled) {
                // Prevent scrolling of the page when user toggles checkbox presses key 'space'
                event.preventDefault();
                props.onChange(!props.value);
            }
        } },
        React.createElement("span", { className: classnames('a-checkbox__checkmark-wrappper', {
                'a-checkbox__checkmark-wrappper--error': props.error,
            }) },
            React.createElement("span", { className: classnames('a-checkbox__checkmark', {
                    'a-checkbox__checkmark--checked': props.value,
                }) }, props.intermediate ? (React.createElement(MinusMaterialIcon, { height: 10, width: 12 })) : (React.createElement(CheckmarkMaterialIcon, { height: 10, width: 12 })))),
        React.createElement(HorizontalSpacer, { size: "xxs" }),
        React.createElement("span", { className: "a-checkbox__label-text" },
            React.createElement(Typography, { size: props.fontSize || 's', type: props.fontType || 'regular', responsive: false }, props.label))));
};
