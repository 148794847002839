/**
 * This function will always return false on server side
 * because of server side caching
 */
export var isUserLoggedInLazy = (function () {
    if (typeof document === 'undefined') {
        return false;
    }
    if (isUserLoggedInLazy.cache !== undefined) {
        return isUserLoggedInLazy.cache;
    }
    var script = document.querySelector('#userLoggedIn');
    var userLoginState = script && JSON.parse(script.innerHTML);
    // If no login information were found asume that the login failed
    if (!userLoginState) {
        return false;
    }
    isUserLoggedInLazy.cache = userLoginState.isLoggedIn;
    return isUserLoggedInLazy.cache;
});
