var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useLoadNamedOnceOnClientSide } from '@jelmoli/hooks/src/useLoadOnceOnClientSide/useLoadOnceOnClientSide';
import { bindTranslations } from '@jelmoli/i18n';
import { isUserLoggedInLazy } from '@jelmoli/sitecore-services/src/Account/isUserLoggedIn/isUserLoggedInLazy';
import { getCart } from '@jelmoli/sitecore-services/src/Cart/getCart/getCart';
import { getCartTotalCount } from '@jelmoli/sitecore-services/src/Cart/getCartTotalCount/getCartTotalCount';
import { DeliveryOptionConstantMap } from '@jelmoli/sitecore-services/src/Cart/getDeliveryOptions/getDeliveryOptions';
import { getCheckoutLabels } from '@jelmoli/sitecore-services/src/Checkout/getCheckoutLabels/getCheckoutLabels';
import { getCheckoutProgress } from '@jelmoli/sitecore-services/src/Checkout/getCheckoutProgress';
import { trackCheckoutStepComplete } from '@jelmoli/sitecore-services/src/Tracking/Checkout/CheckoutTracking';
import { Spinner } from '@jelmoli/ui-components/src/atoms/Spinner/Spinner';
import { Step, StepContent, StepLabel, Stepper, StepSummary } from '@jelmoli/ui-components/src/atoms/Stepper/Stepper';
import { TextLink } from '@jelmoli/ui-components/src/atoms/TextLink/TextLink';
import { HorizontalSpacer } from '@jelmoli/ui-components/src/helpers/HorizontalSpacer/HorizontalSpacer';
import { ScrollIntoView } from '@jelmoli/ui-components/src/helpers/ScrollIntoView/ScrollIntoView';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { Visibility } from '@jelmoli/ui-components/src/helpers/Visibility/Visibility';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
import { CheckoutStepOrderAddress } from '../CheckoutStepAddress/CheckoutStepAddress';
import { CheckoutStepAddressSummary } from '../CheckoutStepAddressSummary/CheckoutStepAddressSummary';
import { CheckoutStepCart } from '../CheckoutStepCart/CheckoutStepCart';
import { CheckoutStepDeliveryOptions } from '../CheckoutStepDeliveryOptions/CheckoutStepDeliveryOptions';
import { CheckoutStepDeliveryOptionsSummary } from '../CheckoutStepDeliveryOptionsSummary/CheckoutStepDeliveryOptionsSummary';
import { CheckoutStepGiftOptions } from '../CheckoutStepGiftOptions/CheckoutStepGiftOptions';
import { checkIsApplePayAvailable, CheckoutStepPaymentMethod, } from '../CheckoutStepPaymentMethod/CheckoutStepPaymentMethod';
import { checkoutStepNames, setCurrentStepToUrl, useCheckoutStepperState, } from '../CheckoutUrlStateStore';
import './checkout-stepper.scss';
export var CheckoutStepper = observer(function () {
    var CheckoutStepAddressLoggedIn = useLoadNamedOnceOnClientSide('CheckoutStepAddressLoggedIn', function () {
        return import(
        /* webpackChunkName: "CheckoutStepAddressLoggedIn" */ '../CheckoutStepAddress/CheckoutStepAddressLoggedIn');
    });
    var checkoutLabelsData = useGetEndPointLazy(getCheckoutLabels).value;
    var getCartLines = useGetEndPointLazy(getCartTotalCount).value;
    var getCartData = useGetEndPointLazy(getCart).value;
    var checkoutProgressState = useCheckoutStepperState();
    var editableSteps = useEditableSteps(checkoutProgressState);
    if (!getCartLines || !checkoutLabelsData || !getCartData || !checkoutProgressState || !editableSteps) {
        return null;
    }
    var currentExpandedStep = checkoutProgressState.currentStep, nextStep = checkoutProgressState.nextStep, stepStates = checkoutProgressState.stepStates;
    var addressSettings = getCartData.orderInformation;
    var deliveryOptions = getCartData.deliveryOption;
    var paymentOptions = getCartData.paymentMethod;
    var t = bindTranslations(checkoutLabelsData);
    var isCartDigital = getCartData.deliveryOption &&
        getCartData.deliveryOption.deliveryOptionExternalId === DeliveryOptionConstantMap.digital;
    var checkIfPaymentMethodIsValid = function () {
        if (paymentOptions && paymentOptions.paymentMethodDisplayName === 'Apple Pay') {
            return checkIsApplePayAvailable();
        }
        else {
            return true;
        }
    };
    return (React.createElement(Stepper, { classes: { root: 'feat-checkout-stepper' }, activeStep: checkoutStepNames.indexOf(currentExpandedStep), orientation: "vertical" },
        React.createElement(Step, { completed: stepStates.cart && currentExpandedStep !== 'cart' },
            React.createElement(StepLabel, null,
                React.createElement(Visibility, { show: ['xs', 'sm', 'md'] },
                    React.createElement(ScrollIntoView, { smooth: true, inView: currentExpandedStep === 'cart' })),
                React.createElement(Typography, { type: "medium", size: "l", responsive: false }, t('CheckoutStepperCartStepTitle')),
                React.createElement(StepperEditStepLink, { stepName: "cart", currentStep: currentExpandedStep, editableSteps: editableSteps }, t('CheckoutStepperEditStepLinkText'))),
            React.createElement(StepContent, null,
                React.createElement(CheckoutStepCart, { onComplete: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, getCheckoutProgress.refresh()];
                                case 1:
                                    _a.sent();
                                    trackCheckoutStepComplete({ name: 'cart', step: 1 });
                                    setCurrentStepToUrl(nextStep);
                                    return [2 /*return*/];
                            }
                        });
                    }); } })),
            React.createElement(StepSummary, { visible: currentExpandedStep !== 'cart' },
                React.createElement(React.Fragment, null,
                    React.createElement(Typography, { size: "s", responsive: false },
                        getCartLines.productTotalCount,
                        " ",
                        t('CheckoutStepperCartArticleLabel')),
                    React.createElement(VerticalSpacer, { size: "l", responsive: false })))),
        React.createElement(Step, { completed: stepStates.giftCard && currentExpandedStep !== 'giftCard' },
            React.createElement(StepLabel, null,
                React.createElement(Visibility, { show: ['xs', 'sm', 'md'] },
                    React.createElement(ScrollIntoView, { smooth: true, inView: currentExpandedStep === 'giftCard' })),
                React.createElement(Typography, { type: "medium", size: "l", responsive: false }, t('CheckoutStepperGiftWrappingStepTitle')),
                !isCartDigital && (React.createElement(StepperEditStepLink, { stepName: "giftCard", currentStep: currentExpandedStep, editableSteps: editableSteps }, t('CheckoutStepperEditStepLinkText')))),
            React.createElement(StepContent, null,
                React.createElement(CheckoutStepGiftOptions, { onComplete: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, getCheckoutProgress.refresh()];
                                case 1:
                                    _a.sent();
                                    trackCheckoutStepComplete({ name: 'giftCard', step: 2 });
                                    setCurrentStepToUrl(nextStep);
                                    return [2 /*return*/];
                            }
                        });
                    }); }, saveButtonLabel: t('CheckoutStepperSaveStepCtaButtonText') })),
            React.createElement(StepSummary, { visible: currentExpandedStep !== 'giftCard' },
                !isCartDigital && (React.createElement(React.Fragment, null,
                    React.createElement(Typography, { size: "s", responsive: false },
                        !getCartData.giftWrapping && t('GiftSummaryEmptyLabel'),
                        getCartData.giftWrapping &&
                            getCartData.giftWrapping.greetingMessage &&
                            t('GiftSummaryWithTextLabel'),
                        getCartData.giftWrapping &&
                            !getCartData.giftWrapping.greetingMessage &&
                            t('GiftSummaryNoTextLabel')),
                    getCartData.giftWrapping && getCartData.giftWrapping.greetingMessage && (React.createElement(React.Fragment, null,
                        React.createElement(VerticalSpacer, { size: "s", responsive: false }),
                        React.createElement(Typography, { size: "s", responsive: false }, getCartData.giftWrapping.greetingMessage))))),
                isCartDigital && (React.createElement(Typography, { size: "s", responsive: false }, t('GiftSummaryNotAvailable'))),
                React.createElement(VerticalSpacer, { size: "l", responsive: false }))),
        React.createElement(Step, { completed: stepStates.address && currentExpandedStep !== 'address' },
            React.createElement(StepLabel, null,
                React.createElement(Visibility, { show: ['xs', 'sm', 'md'] },
                    React.createElement(ScrollIntoView, { smooth: true, inView: currentExpandedStep === 'address' })),
                React.createElement(Typography, { type: "medium", size: "l", responsive: false }, t('CheckoutStepperAddressStepTitle')),
                React.createElement(StepperEditStepLink, { stepName: "address", currentStep: currentExpandedStep, editableSteps: editableSteps }, t('CheckoutStepperEditStepLinkText'))),
            React.createElement(StepContent, null, isUserLoggedInLazy() ? (CheckoutStepAddressLoggedIn ? (React.createElement(CheckoutStepAddressLoggedIn, { onComplete: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, getCheckoutProgress.refresh()];
                            case 1:
                                _a.sent();
                                trackCheckoutStepComplete({ name: 'address', step: 4 });
                                setCurrentStepToUrl(nextStep);
                                return [2 /*return*/];
                        }
                    });
                }); } })) : (React.createElement(Spinner, null))) : (React.createElement(CheckoutStepOrderAddress, { onComplete: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, getCheckoutProgress.refresh()];
                            case 1:
                                _a.sent();
                                trackCheckoutStepComplete({ name: 'address', step: 4 });
                                setCurrentStepToUrl(nextStep);
                                return [2 /*return*/];
                        }
                    });
                }); } }))),
            React.createElement(StepSummary, { visible: currentExpandedStep !== 'address' }, addressSettings && React.createElement(CheckoutStepAddressSummary, null))),
        React.createElement(Step, { completed: stepStates.deliveryOption && currentExpandedStep !== 'deliveryOption' },
            React.createElement(StepLabel, null,
                React.createElement(Visibility, { show: ['xs', 'sm', 'md'] },
                    React.createElement(ScrollIntoView, { smooth: true, inView: currentExpandedStep === 'deliveryOption' })),
                React.createElement(Typography, { type: "medium", size: "l", responsive: false }, t('CheckoutStepperDeliveryOptionsStepTitle')),
                React.createElement(StepperEditStepLink, { stepName: "deliveryOption", currentStep: currentExpandedStep, editableSteps: editableSteps }, t('CheckoutStepperEditStepLinkText'))),
            React.createElement(StepContent, null,
                React.createElement(CheckoutStepDeliveryOptions, { onComplete: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, getCheckoutProgress.refresh()];
                                case 1:
                                    _a.sent();
                                    trackCheckoutStepComplete({ name: 'deliveryOptions', step: 5 });
                                    setCurrentStepToUrl(nextStep);
                                    return [2 /*return*/];
                            }
                        });
                    }); } })),
            React.createElement(StepSummary, { visible: currentExpandedStep !== 'deliveryOption' }, deliveryOptions && (React.createElement(CheckoutStepDeliveryOptionsSummary, { packagingTitle: t('CheckoutSummaryPackingLabel'), deliveryTitle: t('CheckoutSummaryShippingLabel'), deliveryTimeTitle: t('CheckoutStepperDeliveryOptionsTimeTitle') })))),
        React.createElement(Step, { completed: stepStates.payment && checkIfPaymentMethodIsValid() && currentExpandedStep !== 'payment' },
            React.createElement(StepLabel, null,
                React.createElement(Visibility, { show: ['xs', 'sm', 'md'] },
                    React.createElement(ScrollIntoView, { smooth: true, inView: currentExpandedStep === 'payment' })),
                React.createElement(Typography, { type: "medium", size: "l", responsive: false }, t('CheckoutStepperPaymentStepTitle')),
                React.createElement(StepperEditStepLink, { stepName: "payment", currentStep: currentExpandedStep, editableSteps: editableSteps }, t('CheckoutStepperEditStepLinkText'))),
            React.createElement(StepContent, null,
                React.createElement(CheckoutStepPaymentMethod, { onComplete: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, getCheckoutProgress.refresh()];
                                case 1:
                                    _a.sent();
                                    trackCheckoutStepComplete({ name: 'payment', step: 6 });
                                    setCurrentStepToUrl(nextStep);
                                    return [2 /*return*/];
                            }
                        });
                    }); } })),
            React.createElement(StepSummary, { visible: currentExpandedStep !== 'payment' }, paymentOptions && checkIfPaymentMethodIsValid() && (React.createElement(React.Fragment, null,
                React.createElement(Typography, { responsive: false, size: "s" }, paymentOptions.paymentMethodDisplayName),
                React.createElement(VerticalSpacer, { size: "m", responsive: false })))))));
});
function useEditableSteps(stepProgress) {
    return useMemo(function () {
        if (!stepProgress) {
            return null;
        }
        var currentStep = stepProgress.currentStep, nextStep = stepProgress.nextStep, stepStates = stepProgress.stepStates;
        var editableSteps = {};
        var stepNames = Object.keys(stepStates);
        var isCurrentStepValid = stepStates[currentStep];
        var currentIndex = stepNames.indexOf(currentStep);
        stepNames.forEach(function (stepName, stepIndex) {
            editableSteps[stepName] =
                // If the step is the next step (possibly invalid) and the current one is valid
                (stepName === nextStep && isCurrentStepValid) ||
                    // If the step is valid and the current one is valid
                    (stepStates[stepName] && isCurrentStepValid) ||
                    // If the step is before the current step
                    currentIndex > stepIndex;
        });
        return editableSteps;
    }, [stepProgress]);
}
var StepperEditStepLink = observer(function (_a) {
    var stepName = _a.stepName, currentStep = _a.currentStep, editableSteps = _a.editableSteps, children = _a.children;
    return ((editableSteps[stepName] && stepName !== currentStep && (React.createElement(React.Fragment, null,
        React.createElement(HorizontalSpacer, { size: "xs", responsive: false }),
        React.createElement(Typography, { size: "xs", responsive: false },
            React.createElement(TextLink, { "aria-label": "edit checkout step " + stepName, isButton: true, onClick: function () { return setCurrentStepToUrl(stepName); } }, children))))) ||
        null);
});
