import { useEffect, useRef, useState } from 'react';
/**
 * A small util which returns wether the current ref
 * is scrolled into view and is not set to `display: none`
 *
 * Optional argument: treshold - How much of the ref has
 * to be inside the viewport (from `0` = 1px to `1` = 100%)
 * -1 means don't watch at all
 *
 * `isVisibleInViewport` will be undefined until it is known wether it
 * is inview or not
 */
export var useIsVisibleInViewport = function (threshold, customRef) {
    if (threshold === void 0) { threshold = 0; }
    var ownRef = useRef(null);
    var _a = useState(null), observer = _a[0], setObserver = _a[1];
    var _b = useState(undefined), isVisibleInViewport = _b[0], setIsVisible = _b[1];
    var ref = customRef || ownRef;
    // Create one shared IntersectionObserver for the hook
    useEffect(function () {
        if (observer === null && threshold !== -1 && typeof window !== undefined && 'IntersectionObserver' in window) {
            setObserver(new IntersectionObserver(function (entry) {
                var isVisible = entry[0] && entry[0].isIntersecting;
                setIsVisible(isVisible);
            }, { threshold: threshold }));
        }
        return function () { return setObserver(null); };
    }, [threshold]);
    // Start observing the ref once ref and observer are ready
    useEffect(function () {
        var currentElement = ref.current;
        if (!observer || !(currentElement instanceof Element)) {
            return;
        }
        observer.observe(currentElement);
        return function () {
            observer.unobserve(currentElement);
        };
    }, [ref.current, observer]);
    return { isVisibleInViewport: isVisibleInViewport, ref: ref };
};
/**
 * Allows to act as soon as it is known wether an element is visible or visible
 * Usage:
 *
 * ```
 * const ref = useIsVisibleInViewportEffect((isVisible) => console.log('Element is now visible'));
 * return <div ref={ref}>Scroll me into view</div>
 * ```
 */
export var useIsVisibleInViewportEffect = function (callback, treshold, customRef) {
    var _a = useIsVisibleInViewport(treshold, customRef), isVisibleInViewport = _a.isVisibleInViewport, ref = _a.ref;
    var _b = useState(undefined), wasVisible = _b[0], setIsVisible = _b[1];
    useEffect(function () {
        var element = ref.current;
        if (!element) {
            return;
        }
        if (isVisibleInViewport !== undefined && isVisibleInViewport !== wasVisible) {
            setIsVisible(isVisibleInViewport);
            return callback(isVisibleInViewport, element);
        }
    }, [isVisibleInViewport, ref.current]);
    return ref;
};
export var hasEverBeenVisibleInViewport = function (treshold, customRef) {
    var _a = useState(false), hasBeenVisible = _a[0], setIsVisible = _a[1];
    var ref = useIsVisibleInViewportEffect(function (isVisible) {
        if (isVisible) {
            setIsVisible(true);
        }
    }, hasBeenVisible ? -1 : treshold, customRef);
    return { hasBeenVisible: hasBeenVisible, ref: ref };
};
