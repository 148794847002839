import { ExpansionPanel } from '@jelmoli/ui-components/src/atoms/ExpansionPanel/ExpansionPanel';
import { ExpansionPanelDetails } from '@jelmoli/ui-components/src/atoms/ExpansionPanelDetails/ExpansionPanelDetails';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import React from 'react';
export var ProductDescription = function (props) {
    return (React.createElement(ExpansionPanel, { summary: {
            text: props.panelLabel,
            type: 'regular',
            size: 'l',
            typeExpanded: 'medium',
            tag: 'h2',
        }, expanded: props.expanded, onChange: props.onChange },
        React.createElement(ExpansionPanelDetails, null,
            React.createElement(Typography, { size: "l" }, props.description))));
};
