import { isUserLoggedInLazy } from '@jelmoli/sitecore-services/src/Account/isUserLoggedIn/isUserLoggedInLazy';
import { getWishlistIds } from '@jelmoli/sitecore-services/src/Wishlist/getWishlist/getWishlist';
import { Menu } from '@jelmoli/ui-components/src/atoms/Icon/Menu';
import { Visibility } from '@jelmoli/ui-components/src/helpers/Visibility/Visibility';
import { ShopMobileMainNavigation } from '@jelmoli/ui-components/src/organism/ShopMobileMainNavigation/ShopMobileMainNavigation';
import React, { useState } from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
import { ShopHeaderCollapsedSearchComponent } from '../../Website/ShopHeaderSearchComponent/ShopHeaderSearchComponent';
import './shop-header-mobile-navigation.scss';
export var ShopHeaderMobileNavigationComponent = function (props) {
    var _a = useState(false), isOpen = _a[0], openMobileNavigation = _a[1];
    var wishlistIds = useGetEndPointLazy(getWishlistIds, {}, isUserLoggedInLazy());
    var hasWishlistEntries = wishlistIds.hasData && wishlistIds.value.entriesCount > 0;
    return (React.createElement("div", { className: "feat-shop-header__mobile-navigation" },
        React.createElement("button", { "aria-label": "Menu button", className: "feat-shop-header__menu", onClick: function () {
                openMobileNavigation(!isOpen);
            } },
            React.createElement(Menu, { width: 20, height: 20 }),
            React.createElement(Visibility, { show: "xs" }, hasWishlistEntries && React.createElement("span", { className: "feat-shop-header__menu-item--active" }))),
        React.createElement(ShopHeaderCollapsedSearchComponent, { searchText: props.fields.searchText }),
        React.createElement(Visibility, { hide: ['lg', 'xl'] },
            React.createElement(ShopMobileMainNavigation, { isOpen: isOpen, items: props.fields.items, openMobileNavigation: openMobileNavigation, headerLocation: props.fields.headerLocation }))));
};
