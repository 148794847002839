import { createMuiTheme } from '@material-ui/core/styles';
export { MuiThemeProvider } from '@material-ui/core/styles';
var invalidShadow = '0px 11px 15px -7px rgba(255,0,0,0.5),0px 24px 38px 3px rgba(255,0,0,0.5),0px 9px 46px 8px rgba(255,0,0,0.5)';
export var jelmoliTheme = createMuiTheme({
    props: {
        MuiButtonBase: {
            disableRipple: true,
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200,
        },
    },
    palette: {
        primary: {
            main: '#7F8C00',
            light: '#7F8C00',
            dark: '#7F8C00',
        },
        secondary: {
            main: '#D2E143',
            light: '#D2E143',
            dark: '#D2E143',
        },
        text: {
            primary: '#202624',
            secondary: '#202624',
        },
        error: {
            light: '#EE1254',
            main: '#EE1254',
            dark: '#EE1254',
        },
    },
    typography: {
        fontFamily: 'National',
    },
    shape: {
        borderRadius: 0,
    },
    shadows: [
        'none',
        '0px 15px 25px rgba(127, 140, 0, 0.06)',
        '25px 0px 25px rgba(127, 140, 0, 0.06)',
        '0px 0px 1px rgba(0, 0, 0, 0.5)',
        '0px 10px 10px rgba(0, 0, 0, 0.05)',
        '0px 20px 20px rgba(0, 0, 0, 0.05)',
        '0px 4px 8px rgba(32, 38, 36, 0.05)',
        '0px -4px 8px rgba(32, 38, 36, 0.05)',
        // The following shadows should not be used so they are free for further requirements
        invalidShadow,
        invalidShadow,
        invalidShadow,
        invalidShadow,
        invalidShadow,
        invalidShadow,
        invalidShadow,
        invalidShadow,
        invalidShadow,
        invalidShadow,
        invalidShadow,
        invalidShadow,
        invalidShadow,
        invalidShadow,
        invalidShadow,
        invalidShadow,
        invalidShadow,
    ],
});
