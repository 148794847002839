import { useEffect, useState } from 'react';
export function useLoadOnceOnClientSide(loader, enableLoad, fallback) {
    if (enableLoad === void 0) { enableLoad = true; }
    var _a = useState(fallback), loadModule = _a[0], setModule = _a[1];
    useEffect(function () {
        if (!enableLoad) {
            return;
        }
        if (typeof document === 'undefined') {
            return;
        }
        var isLoading = true;
        loader().then(function (lazyModule) {
            if (isLoading) {
                setModule(lazyModule);
            }
        });
        return function () {
            isLoading = false;
        };
    }, [enableLoad]);
    return loadModule;
}
export function useLoadNamedOnceOnClientSide(key, loader, enableLoad, fallback) {
    var loadModule = useLoadOnceOnClientSide(loader, enableLoad);
    var loadNamed = loadModule === undefined ? fallback : loadModule[key];
    return loadNamed;
}
