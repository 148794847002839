var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useLoadNamedOnceOnClientSide } from '@jelmoli/hooks/src/useLoadOnceOnClientSide/useLoadOnceOnClientSide';
import { useMiniFormik } from '@jelmoli/hooks/src/useMiniFormik/useMiniFormik';
import { bindTranslations } from '@jelmoli/i18n';
import { getCart } from '@jelmoli/sitecore-services/src/Cart/getCart/getCart';
import { DeliveryOptionConstants } from '@jelmoli/sitecore-services/src/Cart/getDeliveryOptions/getDeliveryOptions';
import { getPackagingOptions, PackagingOptionsConstants, } from '@jelmoli/sitecore-services/src/Cart/getPackagingOptions/getPackagingOptions';
import { getCheckoutLabels } from '@jelmoli/sitecore-services/src/Checkout/getCheckoutLabels/getCheckoutLabels';
import { FormFieldErrorMessage } from '@jelmoli/ui-components/src/atoms/FormFieldErrorMessage/FormFieldErrorMessage';
import { IconBoxInfo } from '@jelmoli/ui-components/src/atoms/Icon/IconBoxInfo';
import { PrimarySubmitButton } from '@jelmoli/ui-components/src/atoms/PrimarySubmitButton/PrimarySubmitButton';
import { TextLink } from '@jelmoli/ui-components/src/atoms/TextLink/TextLink';
import { HorizontalSpacer } from '@jelmoli/ui-components/src/helpers/HorizontalSpacer/HorizontalSpacer';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { FormValidationContext, useFormValidation } from 'nested-form-validation';
import React, { useEffect, useState } from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
import { CheckoutPackagingOptions } from '../CheckoutPackagingOptions/CheckoutPackagingOptions';
import { setDeliveryOptionPostUtil } from '../util/setDeliveryOption';
export var CheckoutStepDeliveryOptionsPost = function (_a) {
    var visible = _a.visible, onComplete = _a.onComplete;
    var _b = useState(), post24Address = _b[0], setPost24Address = _b[1];
    var _c = useState(undefined), shipingPriceTotal = _c[0], setShipingPriceTotal = _c[1];
    var packagingOptions = useGetEndPointLazy(getPackagingOptions).value;
    var labels = useGetEndPointLazy(getCheckoutLabels).value;
    var cartData = useGetEndPointLazy(getCart).value;
    var form = useMiniFormik({
        initialValues: {
            packagingOptions: PackagingOptionsConstants.standard,
        },
    });
    useEffect(function () {
        if (!cartData) {
            return;
        }
        var shippingValue = cartData.shippingTotal.match(/\d+/);
        if (shippingValue) {
            setShipingPriceTotal(parseInt(shippingValue[0], 10));
        }
        var deliveryOption = cartData.deliveryOption;
        if (!deliveryOption || deliveryOption.deliveryOptionExternalId !== DeliveryOptionConstants.post) {
            return;
        }
        form.setValues({
            packagingOptions: deliveryOption.packagingOptionExternalId,
        });
        setPost24Address(convertBackendAddressToPost24(deliveryOption.address));
    }, [cartData]);
    if (!visible || !packagingOptions || !labels) {
        return null;
    }
    var t = bindTranslations(labels);
    var isAnyProductBulky = cartData &&
        cartData.lines.some(function (line) {
            return line.fulfillmentFlags &&
                (line.fulfillmentFlags.bulky ||
                    line.fulfillmentFlags.installationServiceMandatory ||
                    line.fulfillmentFlags.installationServiceSelected);
        });
    if (isAnyProductBulky) {
        return (React.createElement(FormValidationContext, null,
            React.createElement("div", { className: "feat-checkout__error-notification" },
                React.createElement(VerticalSpacer, { size: "m", responsive: false }),
                React.createElement("div", { className: "feat-checkout__error-notification-text-wrapper" },
                    React.createElement(IconBoxInfo, { width: 38, keepOriginalColors: true }),
                    React.createElement(HorizontalSpacer, { size: "s", responsive: false }),
                    React.createElement("div", { className: "feat-checkout__error-notification-text" },
                        React.createElement(Typography, { size: "xs", responsive: false, wrap: "regular" }, t('CheckoutNotificationPost24NotAvailable')))),
                React.createElement(VerticalSpacer, { size: "m", responsive: false })),
            React.createElement(VerticalSpacer, { size: "l" }),
            React.createElement(PrimarySubmitButton, { onClick: function () { return null; }, disabled: true, type: "button", size: "tiny", text: t('CheckoutStepperSaveStepCtaButtonText') })));
    }
    return (React.createElement(FormValidationContext, null,
        post24Address && (React.createElement(React.Fragment, null,
            React.createElement(Typography, { type: "medium", responsive: false, size: "xs" }, t('CheckoutPost24Location')),
            React.createElement(VerticalSpacer, { size: "xxs", responsive: false }),
            React.createElement(Typography, { type: "regular", responsive: false, size: "s" }, post24Address.Street),
            React.createElement(VerticalSpacer, { size: "xxs", responsive: false }),
            React.createElement(Typography, { type: "regular", responsive: false, size: "s" },
                post24Address.Zip,
                " ",
                post24Address.City),
            React.createElement(VerticalSpacer, { size: "s", responsive: false }))),
        React.createElement(Post24Picker, { name: "post24", onChange: setPost24Address, value: post24Address, requiredErrorMessage: 'Required' },
            React.createElement(Typography, { type: "regular", responsive: false, size: "s" }, !post24Address ? t('CheckoutSetPost24Location') : t('CheckoutResetPost24Location'))),
        React.createElement(FormFieldErrorMessage, { name: "post24", verticalSpaceTop: "xxs" }),
        shipingPriceTotal && shipingPriceTotal > 0 && (React.createElement(React.Fragment, null,
            React.createElement(VerticalSpacer, { size: "xs" }),
            React.createElement("span", { className: "feat-checkout-step-delivery-option__text feat-checkout-step-delivery-option__text--small" }, t('FreeShippingThresholdLabel')))),
        React.createElement(VerticalSpacer, { size: "xl", responsive: false }),
        React.createElement(CheckoutPackagingOptions, { fields: form.fieldProps }),
        React.createElement(VerticalSpacer, { size: "xl", responsive: false }),
        React.createElement(PrimarySubmitButton, { onClick: function () {
                if (!post24Address) {
                    return;
                }
                return setDeliveryOptionPostUtil(onComplete, form.values.packagingOptions, convertPost24AddressToBackendAddress(post24Address));
            }, type: "button", size: "tiny", text: t('CheckoutStepperSaveStepCtaButtonText') })));
};
var CheckoutStepDeliveryOptionsPostOverlay = function (props) {
    var CheckoutStepDeliveryOptionsPostOverlayLazy = useLoadNamedOnceOnClientSide('CheckoutStepDeliveryOptionsPostOverlayLazy', function () {
        return import(
        /* webpackChunkName: "CheckoutStepDeliveryOptionsPostOverlayLazy" */ './CheckoutStepDeliveryOptionsPostOverlayLazy');
    }, props.isOpen);
    return CheckoutStepDeliveryOptionsPostOverlayLazy ? (React.createElement(CheckoutStepDeliveryOptionsPostOverlayLazy, __assign({}, props))) : (React.createElement(React.Fragment, null));
};
var Post24Picker = function (_a) {
    var name = _a.name, value = _a.value, onChange = _a.onChange, requiredErrorMessage = _a.requiredErrorMessage, children = _a.children;
    var _b = useFormValidation(name, value ? 'set' : '', function (currentValue) {
        if (!currentValue) {
            return requiredErrorMessage;
        }
    }), errorMessage = _b[0], touched = _b[1], onBlurFormValidation = _b[2];
    var _c = useState(false), isOpen = _c[0], setIsOpen = _c[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(CheckoutStepDeliveryOptionsPostOverlay, { isOpen: isOpen, onClose: function () {
                onBlurFormValidation();
                setIsOpen(false);
            }, onPointOfInterestChange: onChange }),
        React.createElement(TextLink, { "aria-label": "delivery mypost24", isButton: true, onClick: function () { return setIsOpen(true); } }, children)));
};
/**
 * Backend and Post24 have 2 different formats
 * this function converts the format from the backend format to the post24 format
 */
function convertBackendAddressToPost24(backendAddress) {
    return {
        Type: backendAddress.type,
        PoiTypeName: backendAddress.poiTypeName,
        PoiId: backendAddress.poiId,
        PoiTypeId: backendAddress.poiTypeId,
        AddressTypeId: backendAddress.addressTypeId,
        AddressTypeName: backendAddress.addressTypeName,
        Street: backendAddress.street,
        City: backendAddress.city,
        Zip: backendAddress.zip,
        GemeindeId: backendAddress.gemeindeId,
        KantonCode: backendAddress.kantonCode,
        CountryCode: backendAddress.countryCode,
        CountryName: backendAddress.countryName,
        TechnicalPlz6: backendAddress.technicalPlz6,
    };
}
/**
 * Backend and Post24 have 2 different formats
 * this function converts the format from the post24 format to the backend format
 */
function convertPost24AddressToBackendAddress(post24Address) {
    return {
        type: post24Address.Type,
        poiTypeName: post24Address.PoiTypeName,
        poiId: post24Address.PoiId,
        poiTypeId: post24Address.PoiTypeId,
        addressTypeId: post24Address.AddressTypeId,
        addressTypeName: post24Address.AddressTypeName,
        street: post24Address.Street,
        city: post24Address.City,
        zip: post24Address.Zip,
        gemeindeId: post24Address.GemeindeId,
        kantonCode: post24Address.KantonCode,
        countryCode: post24Address.CountryCode,
        countryName: post24Address.CountryName,
        technicalPlz6: post24Address.TechnicalPlz6,
    };
}
