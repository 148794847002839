import { useIsVisibleInViewportEffect } from '@jelmoli/hooks/src/useKeyboardEffect/useIsVisibleInViewport/useIsVisibleInViewport';
import classnames from 'classnames';
import React from 'react';
import './autoplay-video.scss';
export var AutoplayVideo = function (_a) {
    var src = _a.src, fitObject = _a.fitObject;
    var ref = useIsVisibleInViewportEffect(function (isVisible, video) {
        isVisible ? video.play() : video.pause();
    });
    return (React.createElement("video", { className: classnames('a-video', { 'a-video--fit-object': fitObject }), ref: ref, muted: true, loop: true, preload: "none" },
        React.createElement("source", { src: src })));
};
