import { DeliveryOptionConstants } from '@jelmoli/sitecore-services/src/Cart/getDeliveryOptions/getDeliveryOptions';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import React from 'react';
export var ConfirmationComponentAddressSection = function (props) {
    if (!props.order.orderInformation) {
        return null;
    }
    var deliveryOptionAddress = props.order.deliveryOption &&
        props.order.deliveryOption.deliveryOptionExternalId !== DeliveryOptionConstants.home;
    return (React.createElement("div", null,
        React.createElement(Typography, { type: "medium", size: "s", tag: "p", responsive: false },
            React.createElement(React.Fragment, null,
                props.CheckoutAdressBillingAdressLabel,
                props.order.orderInformation.sameAsBillingAddress && !deliveryOptionAddress && (React.createElement(React.Fragment, null,
                    ", ",
                    props.CheckoutAdressShippingAdressLabel)))),
        React.createElement(Typography, { size: "s", responsive: false },
            props.order.orderInformation.billingAddress.firstName,
            ' ',
            props.order.orderInformation.billingAddress.lastName),
        props.order.orderInformation.billingAddress.company && (React.createElement(Typography, { size: "s", responsive: false },
            ", ",
            props.order.orderInformation.billingAddress.company)),
        React.createElement(Typography, { size: "s", responsive: false },
            ", ",
            props.order.orderInformation.billingAddress.address1,
            ",",
            ' ',
            props.order.orderInformation.billingAddress.address2 && (React.createElement(React.Fragment, null,
                props.order.orderInformation.billingAddress.address2,
                ", ")),
            props.order.orderInformation.billingAddress.zipPostalCode,
            ' ',
            props.order.orderInformation.billingAddress.city),
        !props.order.orderInformation.sameAsBillingAddress &&
            props.order.orderInformation.shippingAddress &&
            !deliveryOptionAddress && (React.createElement(React.Fragment, null,
            React.createElement(VerticalSpacer, { size: "s", responsive: false }),
            React.createElement(Typography, { type: "medium", size: "s", tag: "p", responsive: false }, props.CheckoutAdressShippingAdressLabel),
            React.createElement(Typography, { size: "s", responsive: false },
                props.order.orderInformation.shippingAddress.firstName,
                ' ',
                props.order.orderInformation.shippingAddress.lastName),
            props.order.orderInformation.shippingAddress.company && (React.createElement(Typography, { size: "s", responsive: false },
                ", ",
                props.order.orderInformation.shippingAddress.company)),
            React.createElement(Typography, { size: "s", responsive: false },
                ", ",
                props.order.orderInformation.shippingAddress.address1,
                ",",
                ' ',
                props.order.orderInformation.shippingAddress.address2 ? (React.createElement(React.Fragment, null,
                    props.order.orderInformation.shippingAddress.address2,
                    ", ")) : (' '),
                props.order.orderInformation.shippingAddress.zipPostalCode,
                ' ',
                props.order.orderInformation.shippingAddress.city))),
        deliveryOptionAddress && props.order.deliveryOption && props.order.orderInformation.shippingAddress && (React.createElement(React.Fragment, null,
            React.createElement(VerticalSpacer, { size: "s", responsive: false }),
            React.createElement(Typography, { type: "medium", size: "s", tag: "p", responsive: false }, props.CheckoutAdressShippingAdressLabel),
            React.createElement(DeliveryAddress, { deliveryOption: props.order.deliveryOption, email: props.order.orderInformation.email })))));
};
//Show delivery address when user selects MyPost24 and Click&Collect
var DeliveryAddress = function (_a) {
    var deliveryOption = _a.deliveryOption, email = _a.email;
    switch (deliveryOption.deliveryOptionExternalId) {
        case DeliveryOptionConstants.post:
            return (React.createElement(Typography, { size: "s", responsive: false },
                deliveryOption.deliveryAddress.company,
                ", ",
                deliveryOption.address.street,
                ",",
                ' ',
                deliveryOption.address.zip,
                " ",
                deliveryOption.address.city));
        case DeliveryOptionConstants.pickup:
            return (React.createElement(Typography, { size: "s", responsive: false },
                deliveryOption.deliveryAddress.company,
                ", ",
                deliveryOption.deliveryAddress.address1));
        case DeliveryOptionConstants.digital:
            return (React.createElement(Typography, { size: "s", responsive: false },
                deliveryOption.deliveryAddress.company,
                ", ",
                email));
        default:
            return null;
    }
};
