import React, { useEffect, useState } from 'react';
import { Dropdown } from '../../atoms/Dropdown/Dropdown';
import { DropdownItem } from '../../atoms/DropdownItem/DropdownItem';
import { MaskedInput } from '../../atoms/MaskedInput/MaskedInput';
import { HorizontalSpacer } from '../../helpers/HorizontalSpacer/HorizontalSpacer';
import './telephone-field.scss';
export var TelephoneField = function (props) {
    var _a = useState((props.CountryListTelephone &&
        props.CountryListTelephone[0] &&
        props.CountryListTelephone[0].fields.TelephonePrefix.value) ||
        ''), prefixValue = _a[0], setPrefixValue = _a[1];
    var _b = useState(''), phoneValue = _b[0], setPhoneValue = _b[1];
    var _c = useState(true), initialPhoneValue = _c[0], setInitialPhoneValue = _c[1];
    var _d = useState([/[1-9]/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/]), mask = _d[0], setMask = _d[1];
    //masked provided in the ticket comments:
    //https://jira.namics.com/browse/JELMOLI2025-5391
    //+41 11 111 11 11
    var swissMask = [/[1-9]/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
    //+49 xxxxxxxxxxx
    var germanMask = [/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
    //+423 xxxxxxx
    var lichtensteinMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
    useEffect(function () {
        if (prefixValue === '+49') {
            setMask(germanMask);
        }
        if (prefixValue === '+423') {
            setMask(lichtensteinMask);
        }
        if (prefixValue === '+41') {
            setMask(swissMask);
        }
    }, [prefixValue]);
    useEffect(function () {
        if (props.FieldValue.mobile.value && initialPhoneValue) {
            var _a = props.FieldValue.mobile.value.split(' '), prefix = _a[0], phoneNumber = _a[1];
            if (prefix && phoneNumber) {
                setPrefixValue(prefix);
                setPhoneValue(phoneNumber);
            }
            setInitialPhoneValue(false);
        }
    }, [props.FieldValue.mobile.value]);
    if (!props.CountryListTelephone) {
        return null;
    }
    return (React.createElement("div", { className: "o-telephone-field" },
        React.createElement("div", null,
            React.createElement(Dropdown, { label: props.PrefixLabel.fields.FieldLabel.value || '', value: prefixValue, onChange: function (event) { return setPrefixValue(event.target.value); } }, props.CountryListTelephone.map(function (country) {
                if (!country.fields.TelephonePrefix.value) {
                    return null;
                }
                return (React.createElement(DropdownItem, { key: country.fields.TelephonePrefix.value, value: country.fields.TelephonePrefix.value, className: 'o-telephone-field__dropdown-item-wrapper' },
                    React.createElement("div", { className: "o-telephone-field__dropdown-item" },
                        country.fields.SvgFlag.value ? (React.createElement("div", { className: "o-telephone-field__dropdown-item-flag", dangerouslySetInnerHTML: { __html: country.fields.SvgFlag.value } })) : (country.fields.Name.value),
                        React.createElement(HorizontalSpacer, { size: "xxs" }),
                        React.createElement("span", { className: "o-telephone-field__dropdown-item-text" }, country.fields.TelephonePrefix.value),
                        React.createElement(HorizontalSpacer, { size: "xxs" }))));
            }))),
        React.createElement(HorizontalSpacer, { size: "xs", responsive: false }),
        React.createElement("div", { className: "o-telephone-field__input" },
            React.createElement(MaskedInput, { inputMode: "tel", variant: "standard", placeholder: "Telefonnummer", mask: mask, fullWidth: true, value: phoneValue, onChange: function (e) {
                    props.FieldValue.mobile.onChange(prefixValue + " " + e.target.value.replace(/\s/g, ''));
                    setPhoneValue(e.target.value);
                } }))));
};
