var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { bindTranslations } from '@jelmoli/i18n/index';
import { getSettingsPersonalShoppingDrawer } from '@jelmoli/sitecore-services/src/CustomerService/getSettingsPersonalShoppingDrawer';
import { Checkbox } from '@jelmoli/ui-components/src/atoms/Checkbox/Checkbox';
import { CheckboxLabelWithLink, CheckboxWithFormFieldSetting, } from '@jelmoli/ui-components/src/atoms/CheckboxValidatable/CheckboxValidatable';
import { Dropdown } from '@jelmoli/ui-components/src/atoms/Dropdown/Dropdown';
import { DropdownItem } from '@jelmoli/ui-components/src/atoms/DropdownItem/DropdownItem';
import { FormFieldErrorMessage } from '@jelmoli/ui-components/src/atoms/FormFieldErrorMessage/FormFieldErrorMessage';
import { MaskedInput } from '@jelmoli/ui-components/src/atoms/MaskedInput/MaskedInput';
import { TextFieldWithFormSetting } from '@jelmoli/ui-components/src/atoms/TextFieldValidatable/TextFieldValidatable';
import { getLabelsFromFormFields, } from '@jelmoli/ui-components/src/helpers/SitecoreTypes/SitecoreTypes';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { FormValidationContext } from 'nested-form-validation/index';
import React, { useEffect, useMemo, useState } from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
export var PersonalShoppingDrawerFormComponent = function (_a) {
    var fields = _a.fields, fieldValues = _a.fieldValues, isAutoFocused = _a.isAutoFocused;
    var personalShoppingDrawerSettingsData = useGetEndPointLazy(getSettingsPersonalShoppingDrawer).value;
    var _b = useState(false), reserveDate = _b[0], setReserveDate = _b[1];
    if (!personalShoppingDrawerSettingsData) {
        return null;
    }
    var t = bindTranslations(__assign(__assign({}, personalShoppingDrawerSettingsData), getLabelsFromFormFields(personalShoppingDrawerSettingsData)));
    return (React.createElement(FormValidationContext, null,
        React.createElement(Checkbox, { label: t('PreferredDateAndTimeCheckbox'), value: reserveDate, onChange: function () {
                setReserveDate(!reserveDate);
            }, autoFocus: isAutoFocused }),
        reserveDate && (React.createElement(ReserveDateAndTimeFields, { reservationTimeOptions: fields, PreferredDate: personalShoppingDrawerSettingsData.PreferredDate, PreferredTimeHourLabel: personalShoppingDrawerSettingsData.PreferredTimeHourLabel, fieldValues: fieldValues })),
        React.createElement(VerticalSpacer, { size: "xl", responsive: false }),
        React.createElement(Typography, { size: "xs", responsive: false, type: "medium" }, t('UserDataTitle')),
        React.createElement(VerticalSpacer, { size: "s", responsive: false }),
        React.createElement(TextFieldWithFormSetting, __assign({ name: "firstname", fullWidth: true, variant: "standard" }, fieldValues.firstName, { formSetting: personalShoppingDrawerSettingsData.FirstName })),
        React.createElement(FormFieldErrorMessage, { name: "firstname", verticalSpaceTop: "xxs" }),
        React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
        React.createElement(TextFieldWithFormSetting, __assign({ name: "lastname", fullWidth: true, variant: "standard", formSetting: personalShoppingDrawerSettingsData.LastName }, fieldValues.lastName)),
        React.createElement(FormFieldErrorMessage, { name: "lastname", verticalSpaceTop: "xxs" }),
        React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
        React.createElement(TextFieldWithFormSetting, __assign({ name: "email", fullWidth: true, variant: "standard", formSetting: personalShoppingDrawerSettingsData.Email }, fieldValues.email)),
        React.createElement(FormFieldErrorMessage, { name: "email", verticalSpaceTop: "xxs" }),
        React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
        React.createElement(MaskedInput, __assign({ inputMode: "tel", variant: "standard", name: "mobile", label: personalShoppingDrawerSettingsData.Mobile.fields.FieldLabel.value, mask: ['+', /[1-9]/, /\d/, ' ', /[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/] }, fieldValues.phoneNumber, { fullWidth: true })),
        React.createElement(VerticalSpacer, { size: 'xl', responsive: false }),
        React.createElement(CheckboxWithFormFieldSetting, __assign({ name: "privacy", formSetting: personalShoppingDrawerSettingsData.PrivacyPolicy, label: React.createElement(CheckboxLabelWithLink, { field: personalShoppingDrawerSettingsData.PrivacyPolicy, link: personalShoppingDrawerSettingsData.PrivacyPolicyLink }) }, fieldValues.privacyPolicy)),
        React.createElement(FormFieldErrorMessage, { name: "privacy", verticalSpaceTop: "xxs" }),
        React.createElement(VerticalSpacer, { size: "xl", responsive: false }),
        React.createElement(TextFieldWithFormSetting, __assign({ name: "comment", multiline: true, fullWidth: true, variant: "standard", formSetting: personalShoppingDrawerSettingsData.Comment }, fieldValues.comment)),
        React.createElement(VerticalSpacer, { size: "xl", responsive: false }),
        React.createElement(Typography, { size: "xs", responsive: false, type: "medium" }, t('BookingConfirmationTitle')),
        React.createElement(VerticalSpacer, { size: "xs", responsive: false }),
        React.createElement(Typography, { tag: 'p', size: "xs", responsive: false, type: "regular" }, t('BookingConfirmationDescription'))));
};
var ReserveDateAndTimeFields = function (props) {
    var timeSlots = useMemo(function () {
        var seletedDate = props.reservationTimeOptions.dateSlots.find(function (item) { return item.date === props.fieldValues.preferredDate.value; });
        if (!seletedDate) {
            return [];
        }
        return seletedDate.workday
            ? props.reservationTimeOptions.timeSlotsWorkday
            : props.reservationTimeOptions.timeSlotsSaturday;
    }, [
        props.fieldValues.preferredDate.value,
        props.reservationTimeOptions.timeSlotsWorkday,
        props.reservationTimeOptions.timeSlotsSaturday,
    ]);
    useEffect(function () {
        if (!props.fieldValues.preferredTime.value) {
            return;
        }
        if (timeSlots.indexOf(props.fieldValues.preferredTime.value) === -1) {
            props.fieldValues.preferredTime.onChange('');
        }
    }, [timeSlots, props.fieldValues.preferredTime.value]);
    return (React.createElement(React.Fragment, null,
        React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
        React.createElement(Dropdown, __assign({ type: "fullwidth", label: props.PreferredDate.fields.FieldLabel.value }, props.fieldValues.preferredDate), props.reservationTimeOptions.dateSlots.map(function (dateSlot, key) {
            return (React.createElement(DropdownItem, { key: key, value: dateSlot.date }, dateSlot.displayName));
        })),
        React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
        React.createElement(Dropdown, __assign({ type: "fullwidth", label: props.PreferredTimeHourLabel.value || '', disabled: !props.fieldValues.preferredDate.value }, props.fieldValues.preferredTime), timeSlots.map(function (timeSlot, key) { return (React.createElement(DropdownItem, { key: key, value: timeSlot }, timeSlot)); }))));
};
