var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { observable } from 'mobx';
import { useMemo, useReducer, useRef } from 'react';
export function useMiniFormik(props) {
    var forceUpdate = useForceUpdate();
    var propsRef = useRef(props);
    propsRef.current = props;
    var formik = useMemo(function () {
        var currentProps = propsRef.current;
        var initialValues = __assign({}, props.initialValues);
        var observableFieldState = observable({
            values: props.initialValues,
            touched: {},
        });
        var fieldProps = {};
        var handleChange = function (fieldName) { return function (event) {
            var value;
            // Manual setter
            if (typeof event === 'string' || typeof event === 'boolean') {
                value = event;
            }
            else if (typeof event === 'object' && 'target' in event) {
                var target = event.target;
                var type = target.type;
                if (type === 'checkbox' && 'checked' in target) {
                    // Checkbox
                    value = target.checked;
                }
                else if ('value' in target) {
                    // Input Field, Textarea, Radio, Select
                    value = target.value;
                }
            }
            if (value !== undefined) {
                observableFieldState.values[fieldName] = value;
            }
            // Trigger the form change event
            if (currentProps.onChange) {
                currentProps.onChange(fieldName, observableFieldState.values[fieldName], observableFieldState.values);
            }
            forceUpdate();
        }; };
        var handleBlur = function (fieldName) { return function () {
            observableFieldState.touched[fieldName] = true;
            forceUpdate();
        }; };
        Object.keys(props.initialValues).forEach(function (initialValueName) {
            fieldProps[initialValueName] = {
                onChange: handleChange(initialValueName),
                onBlur: handleBlur(initialValueName),
            };
            Object.defineProperty(fieldProps[initialValueName], 'value', {
                enumerable: true,
                get: function () {
                    return observableFieldState.values[initialValueName];
                },
            });
        });
        return __assign(__assign({ fieldProps: fieldProps }, observableFieldState), { 
            /** Overwrite current values - missing values will fallback to the default value */
            setValues: function (newValues) {
                if (newValues === void 0) { newValues = {}; }
                Object.keys(initialValues).forEach(function (fieldName) {
                    // The check for undefined is needed so that values that are false get set otherwise
                    // we won't set them event thought that's users choice
                    return (observableFieldState.values[fieldName] = (newValues[fieldName] !== undefined
                        ? newValues[fieldName]
                        : initialValues[fieldName]));
                });
                forceUpdate();
            } });
    }, []);
    return __assign({ form: {
            action: '#',
            onSubmit: function (e) {
                e.preventDefault();
                if (props.onSubmit) {
                    props.onSubmit(e);
                }
            },
        } }, formik);
}
var useForceUpdate = function () {
    var _a = useReducer(function (state) { return ({ _: !state._ }); }, { _: false }), _state = _a[0], dispatch = _a[1]; //eslint-disable-line @typescript-eslint/naming-convention
    return dispatch.bind(null, {});
};
