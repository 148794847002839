import React, { useState } from 'react';
import { FilterDropdown, FilterDropdownItem } from '../../../atoms/FilterDropdown/FilterDropdown';
import { VerticalSpacer } from '../../../helpers/VerticalSpacer/VerticalSpacer';
import { ServiceTeaserItem } from './../ServiceTeaserItem/ServiceTeaserItem';
import './serviceTeaserList.scss';
export var ServiceTeaserList = function (props) {
    var _a = useState('all'), currentCategory = _a[0], setCurrentCategory = _a[1];
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "o-service-teaser-list__filter" },
            React.createElement(FilterDropdown, { value: currentCategory, onChange: function (event) {
                    setCurrentCategory(event.target.value);
                } },
                React.createElement(FilterDropdownItem, { value: 'all' }, props.AllCategoriesLabel.value),
                props.CategoriesList.map(function (item, index) { return (React.createElement(FilterDropdownItem, { key: index, value: item.id }, item.fields.CategoryName.value)); }))),
        React.createElement(VerticalSpacer, { size: 'l' }),
        props.ServiceTeaserList &&
            props.ServiceTeaserList.map(function (item, index) {
                if (currentCategory === 'all' ||
                    (item.fields.ServiceCategory &&
                        item.fields.ServiceCategory.find(function (category) { return category.id === currentCategory; }))) {
                    return (React.createElement(React.Fragment, { key: index },
                        React.createElement(ServiceTeaserItem, { ServiceItem: item, ServiceTeaserBookingButton: props.ServiceTeaserBookingButton }),
                        React.createElement(VerticalSpacer, { size: 'xxl' })));
                }
                return null;
            })));
};
