var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import './video-player.scss';
/**
 * The video allows embedding Youtube and Vimeo players
 */
export var VideoPlayer = function (props) { return (React.createElement("div", { className: "a-video-player" }, isYoutubeLink(props.src) ? React.createElement(YoutubePlayer, __assign({}, props)) : React.createElement(VimeoPlayer, __assign({}, props)))); };
// https://developers.google.com/youtube/player_parameters?hl=en
var youtubeOptions = {
    /** This parameter specifies the color that will be used in the player's video progress bar to highlight the amount of the video that the viewer has already seen. Valid parameter values are red and white, and, by default, the player uses the color red in the video progress bar. See the YouTube API blog for more information about color options. */
    color: 'white',
    /** Sets the player's interface language. The parameter value is an ISO 639-1 two-letter language code or a fully specified locale. For example, fr and fr-ca are both valid values. Other language input codes, such as IETF language tags (BCP 47) might also be handled properly. */
    hl: 'en',
    /** Setting the parameter's value to 1 causes video annotations to be shown by default, whereas setting to 3 causes video annotations to not be shown by default. The default value is 1. */
    iv_load_policy: 3,
    loop: 0,
    /** This parameter lets you use a YouTube player that does not show a YouTube logo. Set the parameter value to 1 to prevent the YouTube logo from displaying in the control bar. Note that a small YouTube text label will still display in the upper-right corner of a paused video when the user's mouse pointer hovers over the player. */
    modestbranding: 1,
    /** If the parameter's value is set to 0, then the player does not show related videos. */
    rel: 0,
    /** Youtube player version */
    version: 3,
};
var YoutubePlayer = function (props) {
    // Extract v parmeter from youtube url e.g. https://www.youtube.com/watch?time_continue=1&v=A3O6pqnR3ww
    var videoId = new URL(props.src).searchParams.get('v');
    var videoUrl = "//www.youtube.com/embed/" + videoId + "?" + Object.keys(youtubeOptions)
        .map(function (option) { return option + "=" + youtubeOptions[option]; })
        .join('&');
    return (React.createElement("iframe", { className: "a-video-player__iframe", src: videoUrl, allow: "autoplay; encrypted-media", allowFullScreen: true }));
};
// https://developer.vimeo.com/player/sdk/embed
var vimeoOptions = {
    /** Whether to display the video owner's name. */
    byline: 0,
    /** The hexadecimal color value of the playback controls, which is normally 00ADEF. The embed settings of the video might override this value. */
    color: 'ffffff',
    /** Whether the player displays the title overlay. */
    title: 0,
};
var VimeoPlayer = function (props) {
    // Get the last path part from
    // https://vimeo.com/253965038
    // https://player.vimeo.com/video/253965038
    var urlParts = new URL(props.src).pathname.split('/');
    var videoId = urlParts[urlParts.length - 1];
    var videoUrl = "//player.vimeo.com/video/" + videoId + "?" + Object.keys(vimeoOptions)
        .map(function (option) { return option + "=" + vimeoOptions[option]; })
        .join('&');
    return React.createElement("iframe", { className: "a-video-player__iframe", src: videoUrl, allowFullScreen: true });
};
function isYoutubeLink(link) {
    return link.indexOf('youtube') !== -1;
}
