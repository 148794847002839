var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { useMiniFormik } from '@jelmoli/hooks/src/useMiniFormik/useMiniFormik';
import { bindTranslations } from '@jelmoli/i18n';
import { loginUser } from '@jelmoli/sitecore-services/src/Account/loginUser/loginUser';
import { trackLogin } from '@jelmoli/sitecore-services/src/Tracking/Account/AccountTracking';
import { urlManager } from '@jelmoli/urlmanager/UrlManager';
import { observer } from 'mobx-react-lite';
import { FormValidationContext } from 'nested-form-validation';
import React from 'react';
import { showErrorNotification, showNotificationAndGotoPage, showNotificationAndReload, } from '../../atoms/Notification/Notification';
import { PrimarySubmitButton } from '../../atoms/PrimarySubmitButton/PrimarySubmitButton';
import { SecondaryButton } from '../../atoms/SecondaryButton/SecondaryButton';
import { TextLink } from '../../atoms/TextLink/TextLink';
import { Typography } from '../../helpers/Typography/Typography';
import { VerticalSpacer } from '../../helpers/VerticalSpacer/VerticalSpacer';
import { Visibility } from '../../helpers/Visibility/Visibility';
import { LoginForm } from '../LoginForm/LoginForm';
export var LoginContent = observer(function (_a) {
    var fields = _a.fields, inDrawer = _a.inDrawer, onForgotPasswordClick = _a.onForgotPasswordClick, clickAndReserveLogin = _a.clickAndReserveLogin;
    var form = useMiniFormik({
        initialValues: {
            username: '',
            password: '',
        },
    });
    var t = bindTranslations(fields);
    return (React.createElement(React.Fragment, null,
        !inDrawer && React.createElement(VerticalSpacer, { size: "m" }),
        inDrawer ? (React.createElement(Typography, { size: "l", responsive: false }, t('LoginIntrotext'))) : (React.createElement(Typography, { size: "l", responsive: false, field: fields.LoginIntrotext })),
        React.createElement(VerticalSpacer, { size: "l", responsive: false }),
        React.createElement(FormValidationContext, null,
            React.createElement(LoginForm, { fields: fields, fieldValues: {
                    inputUsername: form.fieldProps.username,
                    inputPassword: form.fieldProps.password,
                }, focusInput: inDrawer }),
            inDrawer ? (React.createElement(DrawerLoginBtn, { text: t('LoginButtonLabel'), values: form.values, successNotification: t('LoginSuccessNotificationDefault'), notLoggedInNotification: {
                    message: t('EmailNotConfirmedMessage'),
                    linkText: t('EmailNotConfirmedLinkText'),
                }, clickAndReserveLogin: clickAndReserveLogin })) : (React.createElement(LoginBtn, { text: t('LoginButtonLabel'), values: form.values, successNotification: t('LoginSuccessNotificationDefault'), href: fields.LoginMyAccountLink.value.href, notLoggedInNotification: {
                    message: t('EmailNotConfirmedMessage'),
                    linkText: t('EmailNotConfirmedLinkText'),
                } }))),
        React.createElement(VerticalSpacer, { size: "s", responsive: false }),
        React.createElement(Typography, { size: "s", responsive: false },
            React.createElement(TextLink, { "aria-label": "forgot password link", isButton: true, onClick: function () { return onForgotPasswordClick(); } }, t('LoginForgotPasswordLink'))),
        React.createElement(VerticalSpacer, { size: "xxl", responsive: false }),
        React.createElement(Typography, { size: "xs", type: "medium", responsive: false }, t('LoginExistingAccountTitle')),
        React.createElement(VerticalSpacer, { size: "m", responsive: false }),
        inDrawer ? (React.createElement(DrawerRegistrationBtn, { href: fields.LoginRegistrationButtonLink.value.href, label: t('LoginRegistrationButtonLabel') })) : (React.createElement(RegistrationBtn, { href: fields.LoginRegistrationButtonLink.value.href, label: t('LoginRegistrationButtonLabel') }))));
});
var DrawerLoginBtn = function (_a) {
    var text = _a.text, values = _a.values, successNotification = _a.successNotification, notLoggedInNotification = _a.notLoggedInNotification, clickAndReserveLogin = _a.clickAndReserveLogin;
    return (React.createElement(PrimarySubmitButton, { text: text, uppercase: true, fullWidth: true, fontResponsive: false, onClick: function () {
            return loginUser({}, {
                username: values.username,
                password: values.password,
                clickAndReserveLogin: clickAndReserveLogin ? clickAndReserveLogin : false,
            })
                .then(function (data) {
                trackLogin({
                    success: true,
                    loginMethod: values.username.indexOf('@') !== -1 ? 'email' : 'jelmolicard',
                });
                var errorMessageList = __spreadArrays(data.errorMessages);
                if (!data.isEmailConfirmed) {
                    window.localStorage.setItem('email', values.username);
                    errorMessageList.push(notLoggedInNotification.message + "/" + notLoggedInNotification.linkText);
                }
                showNotificationAndReload(['success', 'error'], __spreadArrays(data.successMessages, ['error'], errorMessageList));
            })
                .catch(function (response) {
                trackLogin({
                    success: false,
                    loginMethod: values.username.indexOf('@') !== -1 ? 'email' : 'jelmolicard',
                });
                showErrorNotification(response.GlobalMessage);
            });
        } }));
};
var LoginBtn = function (props) {
    var onLoginClick = function () {
        return loginUser({}, {
            username: props.values.username,
            password: props.values.password,
        })
            .then(function (response) {
            trackLogin({
                success: true,
                loginMethod: props.values.username.indexOf('@') !== -1 ? 'email' : 'jelmolicard',
            });
            // If user got navigated to login page from anotherone, that page is stashed
            // in the url, and after successful login user should be navigated back to that page unless that page is external
            // in all the rest cases should go back to the page received from BE
            var hostname = window.location.hostname;
            var isFromCurrentDomain = !!(urlManager.queryStringFlat.returnUrl && urlManager.queryStringFlat.returnUrl.indexOf(hostname));
            var targetUrl = isFromCurrentDomain ? urlManager.queryStringFlat.returnUrl : props.href;
            var errorMessageList = __spreadArrays(response.errorMessages);
            if (!response.isEmailConfirmed) {
                window.localStorage.setItem('email', props.values.username);
                errorMessageList.push(props.notLoggedInNotification.message + "/" + props.notLoggedInNotification.linkText);
            }
            showNotificationAndGotoPage(['success', 'error'], __spreadArrays(response.successMessages, ['error'], errorMessageList), targetUrl, {
                newHistoryEntry: true,
            });
        })
            .catch(function () {
            trackLogin({
                success: false,
                loginMethod: props.values.username.indexOf('@') !== -1 ? 'email' : 'jelmolicard',
            });
            showErrorNotification('Username or password not corrected');
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Visibility, { show: ['xs', 'sm'] },
            React.createElement(PrimarySubmitButton, { text: props.text, uppercase: true, fontResponsive: false, fullWidth: true, onClick: onLoginClick })),
        React.createElement(Visibility, { hide: ['xs', 'sm'] },
            React.createElement(PrimarySubmitButton, { text: props.text, uppercase: true, fontResponsive: false, onClick: onLoginClick }))));
};
var DrawerRegistrationBtn = function (_a) {
    var label = _a.label, href = _a.href;
    return React.createElement(SecondaryButton, { type: "link", uppercase: true, fontResponsive: false, text: label, href: href, fullWidth: true });
};
var RegistrationBtn = function (_a) {
    var label = _a.label, href = _a.href;
    return (React.createElement(React.Fragment, null,
        React.createElement(Visibility, { show: ['xs', 'sm'] },
            React.createElement(SecondaryButton, { type: "link", uppercase: true, fontResponsive: false, text: label, href: href, fullWidth: true })),
        React.createElement(Visibility, { hide: ['xs', 'sm'] },
            React.createElement(SecondaryButton, { type: "link", uppercase: true, fontResponsive: false, text: label, href: href }))));
};
