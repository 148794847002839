import { Column, Row } from '@jelmoli/bootstrap-grid';
import { Avatar } from '@jelmoli/ui-components/src/atoms/Avatar/Avatar';
import { Container } from '@jelmoli/ui-components/src/helpers/Container/Container';
import { useIsSitecoreEditMode } from '@jelmoli/ui-components/src/helpers/EditMode/editMode';
import { HorizontalSpacer } from '@jelmoli/ui-components/src/helpers/HorizontalSpacer/HorizontalSpacer';
import { hasImageFieldValue, hasTextFieldValue, } from '@jelmoli/ui-components/src/helpers/SitecoreTypes/SitecoreTypes';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import React from 'react';
import './quote.scss';
export var QuoteComponent = function (_a) {
    var fields = _a.fields;
    var editMode = useIsSitecoreEditMode();
    return (React.createElement(Container, { size: "s", backgroundFill: true },
        React.createElement(Row, null,
            React.createElement(Column, { size: 12 },
                React.createElement(QuoteDecoration, { QuoteDecoratedLine: fields.QuoteDecoratedLine, QuoteColorOptions: fields.QuoteColorOptions.value }),
                fields.QuoteDecoratedLine.value && React.createElement(VerticalSpacer, { size: "l" }),
                hasTextFieldValue(fields.QuoteText) && (React.createElement("div", { className: "feat-quote__text feat-quote__text--" + fields.QuoteColorOptions.value + " " + (editMode ? 'feat-quote__text--edit-mode' : '') },
                    React.createElement(Typography, { type: "light", size: "l" }, "\u201C"),
                    React.createElement(Typography, { type: "light", size: "l", field: fields.QuoteText }),
                    React.createElement(Typography, { type: "light", size: "l" }, "\u201D"))),
                fields.QuoteDecoratedLine.value ? React.createElement(VerticalSpacer, { size: "mx" }) : React.createElement(VerticalSpacer, { size: "l" }),
                fields.QuoteAuthor ? (React.createElement(Author, { QuoteAvatar: fields.QuoteAuthor.fields.StaffMemberAvatar, QuoteAuthorName: fields.QuoteAuthor.fields.StaffMemberName, QuoteJobDescription: fields.QuoteAuthor.fields.StaffMemberJobDescription })) : (React.createElement(Author, { QuoteAuthorName: fields.QuoteAuthorName, QuoteJobDescription: fields.QuoteAuthorTitle })),
                React.createElement(QuoteDecoration, { QuoteDecoratedLine: fields.QuoteDecoratedLine, QuoteColorOptions: fields.QuoteColorOptions.value }),
                React.createElement(VerticalSpacer, { size: "xxxl" })))));
};
var Author = function (props) { return (React.createElement("div", { className: "feat-quote__author" },
    props.QuoteAvatar && hasImageFieldValue(props.QuoteAvatar) && (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "feat-quote__avatar-wrapper" },
            React.createElement(Avatar, { editable: false, className: "feat-quote__avatar", field: props.QuoteAvatar })),
        React.createElement(HorizontalSpacer, { size: "s" }))),
    React.createElement("div", { className: "feat-quote__author-text" },
        React.createElement("div", { className: "feat-quote__author-item" },
            React.createElement(Typography, { editable: false, size: "l", type: "medium", field: props.QuoteAuthorName })),
        React.createElement(HorizontalSpacer, { size: "xxs", hide: "xs" }),
        React.createElement("div", { className: "feat-quote__author-item" },
            React.createElement(Typography, { editable: false, size: "l", field: props.QuoteJobDescription }))))); };
var QuoteDecoration = function (_a) {
    var QuoteDecoratedLine = _a.QuoteDecoratedLine, QuoteColorOptions = _a.QuoteColorOptions;
    return QuoteDecoratedLine.value ? (React.createElement(React.Fragment, null,
        React.createElement(VerticalSpacer, { size: "l" }),
        React.createElement("div", { className: "feat-quote__decoration feat-quote__decoration--" + QuoteColorOptions }))) : null;
};
