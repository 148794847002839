var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { bindTranslations } from '@jelmoli/i18n/index';
import { getProfile } from '@jelmoli/sitecore-services/src/Account/getProfile/getProfile';
import { isUserLoggedInLazy } from '@jelmoli/sitecore-services/src/Account/isUserLoggedIn/isUserLoggedInLazy';
import { Modal } from '@jelmoli/ui-components/src/atoms/Modal/Modal';
import { SecondaryButton } from '@jelmoli/ui-components/src/atoms/SecondaryButton/SecondaryButton';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { Visibility } from '@jelmoli/ui-components/src/helpers/Visibility/Visibility';
import React, { useState } from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
import { MyAccountProfileMainAddressModalContent } from './MyAccountProfileMainAddressModalContent';
export var MyAccountProfileMainAddress = function (props) {
    var _a = useState(false), isOverlayOpen = _a[0], setIsOverlayOpen = _a[1];
    var isLoggedIn = isUserLoggedInLazy();
    var getProfileData = useGetEndPointLazy(getProfile, {}, isLoggedIn).value;
    if (!getProfileData) {
        return null;
    }
    var t = bindTranslations(props);
    var country = 'countryCode' in getProfileData.mainAddress ? getProfileData.mainAddress.countryCode : '';
    var countryName = props.CountryList &&
        'countryCode' in getProfileData.mainAddress &&
        props.CountryList.find(function (item) { return item.fields['Country Code'].value === country; }).fields.Name.value;
    return (React.createElement(React.Fragment, null,
        React.createElement(Typography, { size: "l", type: "medium" }, t('MainAddressTitle')),
        React.createElement(VerticalSpacer, { size: "xs" }),
        'address1' in getProfileData.mainAddress ? (React.createElement(React.Fragment, null,
            React.createElement(Typography, { size: "l", type: "regular" }, t('MainAddressDescription')),
            React.createElement(Typography, { tag: "p", size: "l", type: "regular" },
                getProfileData.mainAddress.company && getProfileData.mainAddress.company + ", ",
                getProfileData.mainAddress.address1,
                ",",
                ' ',
                getProfileData.mainAddress.address2 && getProfileData.mainAddress.address2 + ", ",
                getProfileData.mainAddress.zipPostalCode,
                " ",
                getProfileData.mainAddress.city,
                ", ",
                countryName,
                ",",
                ' ',
                getProfileData.mainAddress.mobile && " " + getProfileData.mainAddress.mobile + " "),
            React.createElement(VerticalSpacer, { size: "l" }),
            React.createElement(Visibility, { show: ['xs', 'sm'] },
                React.createElement(SecondaryButton, { fontResponsive: false, type: "button", text: t('ChangeMainAddress'), fullWidth: true, size: "tiny", onClick: function () { return setIsOverlayOpen(true); } })),
            React.createElement(Visibility, { hide: ['xs', 'sm'] },
                React.createElement(SecondaryButton, { fontResponsive: false, type: "button", size: "tiny", text: t('ChangeMainAddress'), onClick: function () { return setIsOverlayOpen(true); } })))) : (React.createElement(React.Fragment, null,
            React.createElement(Typography, { tag: "p", size: "l", type: "regular" }, t('MainAddressEmptyState')),
            React.createElement(VerticalSpacer, { size: "l" }),
            React.createElement(Visibility, { show: ['xs', 'sm'] },
                React.createElement(SecondaryButton, { fontResponsive: false, type: "button", text: t('AddMainAddress'), fullWidth: true, size: "tiny", onClick: function () { return setIsOverlayOpen(true); } })),
            React.createElement(Visibility, { hide: ['xs', 'sm'] },
                React.createElement(SecondaryButton, { fontResponsive: false, type: "button", size: "tiny", text: t('AddMainAddress'), onClick: function () { return setIsOverlayOpen(true); } })))),
        React.createElement(Modal, { open: isOverlayOpen, title: t('ChangeMainAddress'), onClose: function () { return setIsOverlayOpen(false); } },
            React.createElement(MyAccountProfileMainAddressModalContent, __assign({ onClose: function () { return setIsOverlayOpen(false); } }, props)))));
};
