var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { I18N } from '@jelmoli/i18n';
import { useFormValidation } from 'nested-form-validation';
import React from 'react';
import { createFormFieldValidator } from '../../helpers/FormFieldValidator/FormFieldValidator';
import { Typography } from '../../helpers/Typography/Typography';
import { Checkbox } from '../Checkbox/Checkbox';
import { TextLink } from '../TextLink/TextLink';
export var CheckboxValidatable = function (props) {
    return (React.createElement(CheckboxValidatableUI, __assign({ validator: function (value) {
            if (props.errormsg && !value) {
                return props.errormsg;
            }
        } }, props)));
};
export var CheckboxWithFormFieldSetting = function (_a) {
    var formSetting = _a.formSetting, label = _a.label, props = __rest(_a, ["formSetting", "label"]);
    return (React.createElement(CheckboxValidatableUI, __assign({ validator: createFormFieldValidator(formSetting) }, props, { label: label || formSetting.fields.FieldLabel.value })));
};
var CheckboxValidatableUI = function (props) {
    var val = props.value ? 'val' : '';
    var _a = useFormValidation(props.name, val, props.validator), errorMessage = _a[0], touched = _a[1];
    var hasVisibleError = touched && errorMessage !== undefined;
    return React.createElement(Checkbox, __assign({}, props, { error: hasVisibleError }));
};
export var CheckboxLabelWithLink = function (_a) {
    var field = _a.field, link = _a.link;
    return (React.createElement(I18N, { text: field.fields.FieldLabel.value, replacements: {
            link: (React.createElement(Typography, { size: "s", responsive: false },
                React.createElement(TextLink, __assign({ "aria-label": 'got to ${link.value.text}', target: link.value.target, href: link.value.href }, (link.value.rel && { rel: link.value.rel })), link.value.text))),
        } }));
};
