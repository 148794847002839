var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useAsyncUiEvent } from '@jelmoli/hooks/src/useAsyncUiEvent/useAsyncUiEvent';
import classnames from 'classnames';
import React from 'react';
import { hoverAnchorClassName } from '../HoverAnchor/HoverAnchor';
import './a.scss';
/**
 * Util to render a HTML like <a>text</a> as a clickable anchor similar to <a href="javascript:;">text</a>
 *
 * Can be used with or without href
 *
 * Will show a wait cursor if the onClick event returns a promise with a duration over 300ms
 */
export var A = function (_a) {
    var tabIndex = _a.tabIndex, role = _a.role, rel = _a.rel, disabled = _a.disabled, className = _a.className, underline = _a.underline, hoverAnchor = _a.hoverAnchor, onMouseDown = _a.onMouseDown, onClick = _a.onClick, props = __rest(_a, ["tabIndex", "role", "rel", "disabled", "className", "underline", "hoverAnchor", "onMouseDown", "onClick"]);
    var _b = useAsyncUiEvent(onClick, 300), onClickHandler = _b[0], isWaitingForAsyncOperation = _b[1];
    var isDisabled = Boolean(disabled) || isWaitingForAsyncOperation;
    return (React.createElement("a", __assign({}, props, (props.target && props.target === '_blank'
        ? {
            rel: "noopener noreferrer " + rel,
        }
        : { rel: rel }), { role: role || 'button', onClick: isDisabled ? preventDefault : onClickHandler, onKeyUp: function (e) {
            //If there is no href set we have to manually trigger the on click becouse the browser wont do it automaticly.
            if (!props.href && !isDisabled && (e.key === 'Enter' || e.key === ' ')) {
                onClickHandler(e);
            }
        } }, preventOutline(onMouseDown), { tabIndex: tabIndex || 0, className: classnames(className, 'h-a', disabled && 'h-a--disabled', isWaitingForAsyncOperation && 'h-a--wait', underline && 'h-a--underline', hoverAnchor && hoverAnchorClassName) })));
};
/**
 * This function generates onMouseDown handler that prevents appearance of the element ouline
 * Advantage over css solution is that outline is still present on tab press
 */
export var preventOutline = function (onMouseDown) { return ({
    onMouseDown: function (event) {
        /* Prevent focus from clicks similar to original a-tag */
        preventDefault(event);
        if (onMouseDown) {
            return onMouseDown(event);
        }
    },
}); };
/** Prevents the default behaviour */
var preventDefault = function (event) {
    event.preventDefault();
};
