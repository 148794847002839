import { Column, Row } from '@jelmoli/bootstrap-grid';
import { Image } from '@jelmoli/ui-components/src/atoms/Image/Image';
import { SecondaryButton } from '@jelmoli/ui-components/src/atoms/SecondaryButton/SecondaryButton';
import { Container } from '@jelmoli/ui-components/src/helpers/Container/Container';
import { hoverAnchorClassName } from '@jelmoli/ui-components/src/helpers/HoverAnchor/HoverAnchor';
import { RichTextRenderer } from '@jelmoli/ui-components/src/helpers/RichTextRenderer/RichTextRenderer';
import { hasImageFieldValue, hasTextFieldValue, } from '@jelmoli/ui-components/src/helpers/SitecoreTypes/SitecoreTypes';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { Visibility } from '@jelmoli/ui-components/src/helpers/Visibility/Visibility';
import classnames from 'classnames';
import React from 'react';
import './location-teaser.scss';
export var LocationTeaserComponent = function (_a) {
    var fields = _a.fields;
    var LocationArea = fields.LocationAreasItems ? fields.LocationAreasItems : null;
    return (React.createElement(Container, { size: "s", backgroundFill: true },
        React.createElement(Row, null,
            React.createElement(Column, { size: { xs: 12, sm: 6 } },
                fields.Url && (React.createElement("a", { className: classnames('feat-location-teaser__image-wrapper', hoverAnchorClassName), href: fields.Url }, hasImageFieldValue(fields.LocationTeaserImage) && (React.createElement(Image, { value: fields.LocationTeaserImage.value, hoverable: true, editable: false })))),
                React.createElement(VerticalSpacer, { size: "xxl", hide: "xs" })),
            React.createElement(Column, { size: { xs: 12, sm: 6 } },
                fields.LocationItemFields && (React.createElement(React.Fragment, null,
                    React.createElement(VerticalSpacer, { show: "xs", size: "s" }),
                    React.createElement(Typography, { tag: "h2", type: "light", size: "m" }, fields.LocationItemFields.fields.LocationName.value),
                    React.createElement(VerticalSpacer, { size: "s" }),
                    React.createElement(Typography, { size: "l" },
                        fields.LocationItemFields.fields.LocationAddressStreet.value,
                        ' ',
                        fields.LocationItemFields.fields.LocationAddressHouseNumber.value),
                    React.createElement(Typography, { size: "l" },
                        fields.LocationItemFields.fields.LocationAddressPostalCode.value,
                        ' ',
                        fields.LocationItemFields.fields.LocationAddressCity.value),
                    React.createElement(VerticalSpacer, { size: "s" }))),
                hasTextFieldValue(fields.LocationTeaserDescription) && (React.createElement(RichTextRenderer, { html: fields.LocationTeaserDescription.value })),
                LocationArea && LocationArea[0] && (React.createElement(React.Fragment, null,
                    React.createElement(Typography, { field: LocationArea[0].fields.AreaName, type: "medium", size: "l", editable: false }),
                    React.createElement(Typography, { field: LocationArea[0].fields.AreaDescription, size: "l", editable: false }))),
                React.createElement(VerticalSpacer, { size: "m" }),
                hasTextFieldValue(fields.LocationTeaserCTALinkText) && (React.createElement(React.Fragment, null,
                    React.createElement(Visibility, { hide: 'xs' },
                        React.createElement(SecondaryButton, { type: "link", href: fields.Url, text: fields.LocationTeaserCTALinkText.value, fontResponsive: false, uppercase: true })),
                    React.createElement(Visibility, { show: 'xs' },
                        React.createElement(SecondaryButton, { type: "link", href: fields.Url, text: fields.LocationTeaserCTALinkText.value, fontResponsive: false, uppercase: true, fullWidth: true })),
                    React.createElement(VerticalSpacer, { size: "xxl" })))))));
};
