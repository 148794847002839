import { Column, Row } from '@jelmoli/bootstrap-grid';
import { Container } from '@jelmoli/ui-components/src/helpers/Container/Container';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { Visibility } from '@jelmoli/ui-components/src/helpers/Visibility/Visibility';
import { NewsletterContent } from '@jelmoli/ui-components/src/organism/NewsletterContent/NewsletterContent';
import React, { useState } from 'react';
export var NewsletterRegistrationComponent = function (_a) {
    var fields = _a.fields;
    var _b = useState(false), showSuccessRegText = _b[0], setShowSuccessRegText = _b[1];
    return (React.createElement(Container, { size: "s", backgroundFill: true },
        React.createElement(Row, null,
            React.createElement(Column, { size: { xs: 12, sm: 8, lg: 6 }, offset: { xs: 0, sm: 2, lg: 3 } }, !showSuccessRegText ? (React.createElement(React.Fragment, null,
                React.createElement(Visibility, { hide: ['xs', 'sm'] },
                    React.createElement(VerticalSpacer, { size: "xl" })),
                React.createElement(Typography, { type: "medium", size: "xl", tag: "h2" }, fields.NewsletterTitle.value),
                React.createElement(VerticalSpacer, { size: "m" }),
                React.createElement(NewsletterContent, { registrationLabelsData: fields, setShowSuccessRegText: setShowSuccessRegText }),
                React.createElement(VerticalSpacer, { size: "xxxl" }))) : (React.createElement(React.Fragment, null,
                React.createElement(Visibility, { hide: ['xs', 'sm'] },
                    React.createElement(VerticalSpacer, { size: "xl" })),
                React.createElement(Typography, { tag: "h2", size: "xl", type: "medium", responsive: false }, fields.NewsletterSuccess.value),
                React.createElement(VerticalSpacer, { size: "l" }),
                React.createElement(Typography, { tag: 'p', size: "l", type: "regular", responsive: false }, fields.NewsletterConfirmation.value),
                React.createElement(VerticalSpacer, { size: "xxxl" })))))));
};
