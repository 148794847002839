var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useRef } from 'react';
import './scroll-anchor.scss';
/**
 * Util to render a HTML like <a id="anchor1"></a> as a scrollable anchor
 */
export var ScrollAnchor = function (props) {
    // Use ignore all props but id and name:
    var anchorProps = {
        id: props.id,
        name: props.name,
    };
    var ref = useRef(null);
    useEffect(function () {
        if (!ref.current || typeof document === 'undefined') {
            return;
        }
        var hash = document.location.hash.substring(1);
        if (hash === anchorProps.id || hash === anchorProps.name) {
            ref.current.scrollIntoView();
        }
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("a", __assign({ ref: ref }, anchorProps, { "aria-label": "scroll to anchor", className: "h-scroll-anchor" })),
        props.children));
};
