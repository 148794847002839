"use strict";
// Detect the browser based on the userAgent
//
// This technique should only used for cases where
// feature detection is not possible
//
// For example buggy vendor css implementation
//
// Add the browser name directly to the <html> tag
document.documentElement.classList.add(getBrowserName());
function getBrowserName() {
    var userAgent = navigator.userAgent.toLowerCase();
    // Firefox
    if (userAgent.indexOf('firefox') > -1) {
        return 'firefox';
    }
    // Internet Explorer Edge
    var edgeVersion = userAgent.match(/\sedge?\/(\d+)\./);
    if (edgeVersion && edgeVersion[1]) {
        var edgeMajor = Number(edgeVersion[1]);
        // All Edge Browsers with version from 2020
        // https://caniuse.com/usage-table
        return edgeMajor >= 79 ? 'edge' : 'edge-legacy';
    }
    // Safari
    if (/^((?!chrome|android).)*safari/.test(userAgent)) {
        return 'safari';
    }
    // Chrome
    var chromeVersion = userAgent.match(/\schrome?\/(\d+)\./);
    if (chromeVersion && chromeVersion[1]) {
        var chromeMajor = Number(chromeVersion[1]);
        // All Chrome Browsers with version from 2020
        // https://caniuse.com/usage-table
        return chromeMajor >= 75 ? 'chrome' : 'chrome-legacy';
    }
    return 'unknown';
}
