var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classnames from 'classnames';
import React from 'react';
import { BaseImage, useImageRatio } from '../../helpers/BaseImage/BaseImage';
import './image.scss';
export var Image = function (props) {
    if ('borderStyle' in props) {
        return BorderImage(props);
    }
    var children = props.children, hoverable = props.hoverable, baseImageProps = __rest(props, ["children", "hoverable"]);
    var nativeImage = useImageRatio({ value: props.value }) === 'native';
    return (React.createElement("div", { className: classnames('a-image__wrapper', { 'a-image__wrapper--ratio': !nativeImage }) },
        React.createElement(BaseImage, __assign({}, baseImageProps, { className: classnames('a-image', {
                'a-image--fit-object': props.fitObject,
                'a-image--native': nativeImage,
            }) })),
        (props.children || props.hoverable) && (React.createElement("div", { className: "a-image__children" },
            props.children,
            props.hoverable && React.createElement(HoverEffect, { type: "solid", color: "jelmoli-green" })))));
};
var BorderImage = function (props) {
    var children = props.children, hoverable = props.hoverable, borderStyle = props.borderStyle, borderColor = props.borderColor, baseImageProps = __rest(props, ["children", "hoverable", "borderStyle", "borderColor"]);
    var borderStyleType = borderStyle === 'solid' ? 'solid' : 'blendmode';
    var solidBorderClasses = props.borderStyle === 'solid'
        ? classnames('a-image__solid-border', "a-image__solid--" + props.borderColor)
        : '';
    return (React.createElement("div", { className: classnames('a-image__wrapper', solidBorderClasses, "a-image--" + borderStyleType) },
        React.createElement(BaseImage, __assign({}, baseImageProps, { className: classnames('a-image', {
                'a-image--fit-object': props.fitObject,
            }) })),
        props.borderStyle !== 'solid' && (React.createElement("div", { className: "a-image__blendmode-border a-image__blendmode-border--" + props.borderColor + " a-image__blendmode-border--" + props.borderStyle })),
        (children || hoverable) && (React.createElement("div", { className: "a-image__children" },
            children,
            hoverable && React.createElement(HoverEffect, { type: borderStyle, color: borderColor })))));
};
export var HoverEffect = function (_a) {
    var type = _a.type, color = _a.color, isAvatar = _a.isAvatar;
    return (React.createElement("div", { className: classnames("a-image__hover a-image__hover--" + type, "a-image__hover--" + color, {
            'a-image--avatar': isAvatar,
        }) }));
};
