import { track } from '@jelmoli/google-tag-manager';
export function trackSocialMedia(socialMedia) {
    track({
        event: 'gaEvent',
        event_name: 'outbound_click',
        outbound_click: {
            click_url: socialMedia,
            event_category: 'outbound_click',
            event_action: 'social',
            event_label: socialMedia,
        },
    });
}
