import { track } from '@jelmoli/google-tag-manager';
export function trackVersandClick(url) {
    track({
        event: 'gaEvent',
        event_name: 'outbound_click',
        outbound_click: {
            click_url: url || undefined,
            event_category: 'outbound_click',
            event_action: 'jelmoli versand',
            event_label: url || undefined,
        },
    });
}
