var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { bindTranslations } from '@jelmoli/i18n/index';
import { trackFileDownload } from '@jelmoli/sitecore-services/src/Tracking/Download/FileDownload';
import { SecondaryButton } from '@jelmoli/ui-components/src/atoms/SecondaryButton/SecondaryButton';
import { hasLinkFieldValue, hasTextFieldValue, } from '@jelmoli/ui-components/src/helpers/SitecoreTypes/SitecoreTypes';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { Visibility } from '@jelmoli/ui-components/src/helpers/Visibility/Visibility';
import classnames from 'classnames';
import React from 'react';
import { EditorHintComponent } from '../../Website/EditorHintComponent/EditorHintComponent';
import './button-component.scss';
export var ButtonComponent = function (props) {
    if (!hasLinkFieldValue(props.fields.ButtonLink) &&
        props.fields.ButtonLinkDam &&
        !hasLinkFieldValue(props.fields.ButtonLinkDam) &&
        !hasTextFieldValue(props.fields.ButtonOnClick)) {
        return (React.createElement(EditorHintComponent, { parameters: {
                title: 'Button component - please add link',
                message: '',
                messageType: 'info',
            } }));
    }
    var t = bindTranslations(props.fields);
    var link = hasLinkFieldValue(props.fields.ButtonLink) ? props.fields.ButtonLink : props.fields.ButtonLinkDam;
    return (React.createElement(React.Fragment, null,
        React.createElement(Visibility, { show: ['xs'] },
            React.createElement(SecondaryButton, __assign({ type: "link", fontSize: "xs", fontType: "medium", fontResponsive: false, fullWidth: true, uppercase: true, href: link.value.href, text: t('ButtonText'), target: link.value.target }, (link.value.rel && { rel: link.value.rel }), (props.fields.DisablePrefetching.value && {
                onClick: function (event) {
                    if (event && hasTextFieldValue(props.fields.ButtonOnClick)) {
                        var onClickValues = props.fields.ButtonOnClick.value.replace(/'/g, '').split(', ');
                        window.open.apply(window, onClickValues);
                    }
                    if (event && link.value.href) {
                        event.preventDefault();
                        if (props.fields.ButtonLinkDam && props.fields.ButtonLinkDam.value.name) {
                            trackFileDownload(props.fields.ButtonLinkDam.value.type, props.fields.ButtonLinkDam.value.name);
                        }
                        var href = link.value.linktype && link.value.linktype !== 'internal'
                            ? link.value.href
                            : "" + window.location.origin.replace(/(\?|#).+$/, '') + link.value.href;
                        if (event.metaKey || event.ctrlKey || link.value.target === '_blank') {
                            window.open(href);
                            return;
                        }
                        window.location.href = href;
                    }
                },
            })))),
        React.createElement(Visibility, { hide: ['xs'] },
            React.createElement("div", { className: classnames('feat-button-component', {
                    'feat-button-component--centered': props.fields.ButtonCentered && props.fields.ButtonCentered.value,
                }) },
                React.createElement(SecondaryButton, __assign({ type: "link", fontSize: "xs", fontType: "medium", uppercase: true, fullWidth: props.fields.ButtonFullWidth ? props.fields.ButtonFullWidth.value : false, fontResponsive: false, href: link.value.href, text: t('ButtonText'), target: link.value.target }, (link.value.rel && { rel: link.value.rel }), (props.fields.DisablePrefetching.value && {
                    onClick: function (event) {
                        if (event && hasTextFieldValue(props.fields.ButtonOnClick)) {
                            var onClickValues = props.fields.ButtonOnClick.value
                                .replace(/'/g, '')
                                .split(', ');
                            window.open.apply(window, onClickValues);
                        }
                        if (event && link.value.href) {
                            event.preventDefault();
                            if (link.value.name) {
                                trackFileDownload(link.value.type, link.value.name);
                            }
                            var href = link.value.linktype && link.value.linktype !== 'internal'
                                ? link.value.href
                                : "" + window.location.origin.replace(/(\?|#).+$/, '') + link.value.href;
                            if (event.metaKey || event.ctrlKey || link.value.target === '_blank') {
                                window.open(href);
                                return;
                            }
                            window.location.href = href;
                        }
                    },
                }))))),
        React.createElement(VerticalSpacer, { size: "xl" })));
};
