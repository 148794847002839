var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Column, Row } from '@jelmoli/bootstrap-grid';
import { useMiniFormik } from '@jelmoli/hooks/src/useMiniFormik/useMiniFormik';
import { bindTranslations } from '@jelmoli/i18n/index';
import { getProfile } from '@jelmoli/sitecore-services/src/Account/getProfile/getProfile';
import { isUserLoggedInLazy } from '@jelmoli/sitecore-services/src/Account/isUserLoggedIn/isUserLoggedInLazy';
import { submitContactForm } from '@jelmoli/sitecore-services/src/ContactForm/submitContactForm';
import { CheckboxLabelWithLink, CheckboxWithFormFieldSetting, } from '@jelmoli/ui-components/src/atoms/CheckboxValidatable/CheckboxValidatable';
import { DropdownItem } from '@jelmoli/ui-components/src/atoms/DropdownItem/DropdownItem';
import { DropdownValidatable } from '@jelmoli/ui-components/src/atoms/DropdownValidatable/DropdownValidatable';
import { FormFieldErrorMessage } from '@jelmoli/ui-components/src/atoms/FormFieldErrorMessage/FormFieldErrorMessage';
import { showErrorNotification, showSuccessNotification, } from '@jelmoli/ui-components/src/atoms/Notification/Notification';
import { PrimarySubmitButton } from '@jelmoli/ui-components/src/atoms/PrimarySubmitButton/PrimarySubmitButton';
import { RadioButtons } from '@jelmoli/ui-components/src/atoms/RadioButtons/RadioButtons';
import { RadioButtonsValidable } from '@jelmoli/ui-components/src/atoms/RadioButtonsValidatable/RadioButtonsValidatable';
import { TextFieldWithFormSetting } from '@jelmoli/ui-components/src/atoms/TextFieldValidatable/TextFieldValidatable';
import { getFormFieldSettingsProperty } from '@jelmoli/ui-components/src/helpers/FormFieldValidator/FormFieldValidator';
import { HorizontalSpacer } from '@jelmoli/ui-components/src/helpers/HorizontalSpacer/HorizontalSpacer';
import { RichTextRenderer } from '@jelmoli/ui-components/src/helpers/RichTextRenderer/RichTextRenderer';
import { getLabelsFromFormFields, } from '@jelmoli/ui-components/src/helpers/SitecoreTypes/SitecoreTypes';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { Visibility } from '@jelmoli/ui-components/src/helpers/Visibility/Visibility';
import { NameForm } from '@jelmoli/ui-components/src/organism/NameForm/NameForm';
import { FormValidationContext } from 'nested-form-validation/index';
import React, { useEffect, useState } from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
import './contact-form.scss';
export var ContactFormComponent = function (_a) {
    var fields = _a.fields;
    var _b = useState(false), successState = _b[0], setSuccessState = _b[1];
    var t = bindTranslations(__assign(__assign({}, fields), getLabelsFromFormFields(fields)));
    var isLoggedIn = isUserLoggedInLazy();
    var userProfile = useGetEndPointLazy(getProfile, {}, isLoggedIn).value;
    var _c = useState(''), selectedRadioButton = _c[0], setSelectedRadioButton = _c[1];
    var form = useMiniFormik({
        initialValues: {
            salutation: '',
            firstName: '',
            lastName: '',
            orderNumber: '',
            message: (fields.FreeTextField.fields.HelpText && fields.FreeTextField.fields.HelpText.value) || '',
            phone: '',
            email: '',
            subject: '',
            privacyPolicy: false,
        },
    });
    useEffect(function () {
        if (!userProfile) {
            return;
        }
        form.setValues(__assign(__assign({}, form.values), { salutation: userProfile.salutationCode, firstName: userProfile.firstName, lastName: userProfile.lastName, email: userProfile.email, phone: userProfile.mobile, privacyPolicy: true }));
    }, [userProfile]);
    useEffect(function () {
        if (selectedRadioButton === 'phone') {
            fields.EmailField.fields.IsRequired = { value: false };
            fields.PhoneNumberField.fields.IsRequired = { value: true };
        }
        if (selectedRadioButton === 'email') {
            fields.EmailField.fields.IsRequired = { value: true };
            fields.PhoneNumberField.fields.IsRequired = { value: false };
        }
    }, [selectedRadioButton]);
    if (successState) {
        return (React.createElement(React.Fragment, null,
            React.createElement(Typography, { size: "xl", type: "medium" }, t('SuccessTitle')),
            React.createElement(VerticalSpacer, { size: "m" }),
            React.createElement(RichTextRenderer, { html: t('SuccessDescription') })));
    }
    var subjectsArray = Object.keys(fields.Subject.value).map(function (subject) { return [subject, fields.Subject.value[subject]]; });
    var maxCharacters = Number(getFormFieldSettingsProperty(fields.FreeTextField, 'MaxLength'));
    return (React.createElement(React.Fragment, null,
        React.createElement(Typography, { size: "xl", type: "medium" }, t('MainTitle')),
        React.createElement(VerticalSpacer, { size: "m" }),
        React.createElement(Typography, { size: "s", type: "medium", responsive: false }, t('TitleCopy')),
        React.createElement(Typography, { size: "s", tag: "p", type: "regular", responsive: false }, t('Copy')),
        React.createElement(VerticalSpacer, { size: "m", hide: ['lg', 'xl'] }),
        React.createElement(VerticalSpacer, { size: "l", show: ['lg', 'xl'] }),
        React.createElement(Typography, { size: "s", tag: "p", type: "regular", responsive: false }, t('IntroductionContactForm')),
        React.createElement(VerticalSpacer, { size: "l", hide: ['lg', 'xl'] }),
        React.createElement(VerticalSpacer, { size: "m", show: ['lg', 'xl'] }),
        React.createElement(Typography, { size: "xxs", type: "medium", responsive: false }, t('Salutation')),
        React.createElement(VerticalSpacer, { size: "xxs" }),
        React.createElement(FormValidationContext, null,
            React.createElement(NameForm, { fields: {
                    FirstName: fields.Name,
                    LastName: fields.Surname,
                    Salutation: fields.Salutation,
                    SalutationSource: fields.SalutationSource,
                }, fieldValues: form.fieldProps }),
            React.createElement(VerticalSpacer, { size: 'mx', responsive: false }),
            React.createElement(DropdownValidatable, __assign({ label: t('SubjectField'), errormsg: fields.SubjectField.fields.IsRequiredError
                    ? fields.SubjectField.fields.IsRequiredError.value
                    : 'Error Message', name: "subject" }, form.fieldProps.subject, { type: "fullwidth" }),
                fields.SubjectInitialText.value && (React.createElement(DropdownItem, { headline: true, disabled: true, value: "-1" }, t('SubjectInitialText'))),
                subjectsArray.map(function (subject) {
                    return (React.createElement(DropdownItem, { value: subject[0], key: subject[0] }, subject[1]));
                })),
            React.createElement(FormFieldErrorMessage, { name: "subject", verticalSpaceTop: "xxs" }),
            React.createElement(VerticalSpacer, { size: 'mx', responsive: false }),
            form.values.subject === '01' && (React.createElement(React.Fragment, null,
                React.createElement(TextFieldWithFormSetting, __assign({ variant: "standard", name: "orderId", formSetting: fields.OrderNumber }, form.fieldProps.orderNumber, { fullWidth: true, helperText: React.createElement(Typography, { size: 'xxs', responsive: false },
                        React.createElement("span", { className: 'feat-contact-form--helper-text' }, getFormFieldSettingsProperty(fields.OrderNumber, 'HelpText'))) })),
                React.createElement(FormFieldErrorMessage, { name: "orderId", verticalSpaceTop: "xxs" }),
                React.createElement(VerticalSpacer, { size: 'mx', responsive: false }))),
            React.createElement(TextFieldWithFormSetting, __assign({ fullWidth: true, multiline: true }, form.fieldProps.message, { name: "customerMessage", alwaysShowError: form.values.message.length > maxCharacters, formSetting: fields.FreeTextField, FormHelperTextProps: {
                    // @ts-ignore Ignoring ts rules in order to change element type of Helper Text node
                    component: 'div',
                }, helperText: React.createElement(Row, { horizontalAlignment: "end" },
                    React.createElement(Column, { size: 12 }, form.values.message.length <= maxCharacters ? (React.createElement("div", { className: "feat-contact-form__error-messages" },
                        React.createElement("div", null,
                            React.createElement(FormFieldErrorMessage, { name: "customerMessage" })),
                        React.createElement("div", { className: "feat-contact-form--helper-text" },
                            React.createElement(Typography, { responsive: false, size: "xxs" },
                                form.values.message.length,
                                " /",
                                ' ',
                                getFormFieldSettingsProperty(fields.FreeTextField, 'MaxLength'))))) : (React.createElement(FormFieldErrorMessage, { variant: "plain", name: "customerMessage", showAlsoIfUntouched: true })))) })),
            React.createElement(VerticalSpacer, { size: 'mx', responsive: false, hide: ['xs', 'sm'] }),
            React.createElement(VerticalSpacer, { size: 'm', responsive: true, show: ['xs', 'sm'] }),
            React.createElement(Typography, { size: "s", type: "medium", responsive: false }, t('ContactTitle')),
            React.createElement(VerticalSpacer, { size: 's', responsive: false, hide: ['xs', 'sm'] }),
            React.createElement(VerticalSpacer, { size: 'm', responsive: true, show: ['xs', 'sm'] }),
            React.createElement(RadioButtonsValidable, { name: "contactOption", errormsg: t('ErrorMessageContactSelection'), onChange: function (event) {
                    setSelectedRadioButton(event.target.value);
                }, value: selectedRadioButton },
                React.createElement(RadioButtons, { value: 'email', key: fields.Email.value }, t('Email')),
                selectedRadioButton === 'email' && (React.createElement(React.Fragment, null,
                    isLoggedIn && (React.createElement("div", { className: "feat-contect-form__email-label" },
                        React.createElement(HorizontalSpacer, { size: "m", responsive: false }),
                        React.createElement(Typography, { size: "s", tag: "p", responsive: false }, form.values.email))),
                    !isLoggedIn && (React.createElement(React.Fragment, null,
                        React.createElement(TextFieldWithFormSetting, __assign({ variant: "standard", name: "email", fullWidth: true, formSetting: fields.EmailField }, form.fieldProps.email)),
                        React.createElement(FormFieldErrorMessage, { name: "email", verticalSpaceTop: "xxs" }))),
                    React.createElement(VerticalSpacer, { size: "xs", responsive: false }))),
                React.createElement(RadioButtons, { value: 'phone', key: fields.PhoneNumber.value }, t('PhoneNumber')),
                selectedRadioButton === 'phone' && (React.createElement(React.Fragment, null,
                    React.createElement(TextFieldWithFormSetting, __assign({ variant: "standard", name: "phone", formSetting: fields.PhoneNumberField }, form.fieldProps.phone, { fullWidth: true })),
                    React.createElement(FormFieldErrorMessage, { name: "phone", verticalSpaceTop: "xxs" }),
                    React.createElement(VerticalSpacer, { size: "xs", responsive: false })))),
            React.createElement(FormFieldErrorMessage, { name: "contactOption", verticalSpaceTop: "xxs" }),
            !isLoggedIn && (React.createElement(React.Fragment, null,
                React.createElement(VerticalSpacer, { size: 'xl', responsive: false }),
                React.createElement(CheckboxWithFormFieldSetting, __assign({ name: "privacy", formSetting: fields.PrivacyPolicy, label: React.createElement(CheckboxLabelWithLink, { field: fields.PrivacyPolicy, link: fields.PrivacyPolicyLink }) }, form.fieldProps.privacyPolicy)),
                React.createElement(FormFieldErrorMessage, { name: "privacy", verticalSpaceTop: "xxs" }))),
            React.createElement(VerticalSpacer, { size: 'xl', responsive: false }),
            React.createElement(Visibility, { show: ['xs', 'sm'] },
                React.createElement(PrimarySubmitButton, { fullWidth: true, text: t('SendButton'), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, submitContactForm({}, {
                                        salutation: form.values.salutation,
                                        name: form.values.firstName,
                                        emailField: form.values.email,
                                        freeTextField: form.values.message,
                                        orderNumber: form.values.orderNumber,
                                        phoneNumberField: form.values.phone,
                                        subject: fields.Subject.value[form.values.subject],
                                        surname: form.values.lastName,
                                        privacyPolicy: form.values.privacyPolicy,
                                    })
                                        .then(function (response) {
                                        showSuccessNotification(t('SuccessTitle'));
                                        setSuccessState(true);
                                    })
                                        .catch(function (response) {
                                        showErrorNotification(response.GlobalMessage);
                                    })];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); } })),
            React.createElement(Visibility, { hide: ['xs', 'sm'] },
                React.createElement(PrimarySubmitButton, { fullWidth: true, text: t('SendButton'), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, submitContactForm({}, {
                                        salutation: form.values.salutation,
                                        name: form.values.firstName,
                                        emailField: form.values.email,
                                        freeTextField: form.values.message,
                                        orderNumber: form.values.orderNumber,
                                        phoneNumberField: form.values.phone,
                                        subject: fields.Subject.value[form.values.subject],
                                        surname: form.values.lastName,
                                        privacyPolicy: form.values.privacyPolicy,
                                    })
                                        .then(function (response) {
                                        showSuccessNotification(t('SuccessTitle'));
                                        setSuccessState(true);
                                    })
                                        .catch(function (response) {
                                        showErrorNotification(response.GlobalMessage);
                                    })];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); } }))),
        React.createElement(VerticalSpacer, { size: 'xxxl' })));
};
