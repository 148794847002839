import { createPostEndpoint } from 'request-registry';
import { getCSRFToken } from '../../Security/getCSRFToken/getCSRFToken';
import { getCart } from '../getCart/getCart';
/**
 * Add product to cart
 * https://wiki.namics.com/display/JELMOLI2025/205-01-05+Cart+Interfaces#id-205-01-05CartInterfaces-RemoveGiftWrapping
 */
export var removeGiftWrapping = createPostEndpoint({
    url: function () { return '/jelmoli-api/JelmoliCart/RemoveGiftWrapping'; },
    headers: {
        'X-RequestVerificationToken': function () { return getCSRFToken(); },
        'X-Requested-With': 'XMLHttpRequest',
    },
    afterSuccess: function () { return getCart.refresh(); },
});
