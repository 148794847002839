import { Column, Row } from '@jelmoli/bootstrap-grid';
import { Container } from '@jelmoli/ui-components/src/helpers/Container/Container';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import React from 'react';
import { BrandCategoriesComponent } from './BrandCategoriesComponent/BrandCategoriesComponent';
import { BrandStageComponent } from './BrandStageComponent/BrandStageComponent';
export var BrandDetailsComponent = function (props) {
    return (React.createElement(Container, { size: "s", backgroundFill: true },
        React.createElement(Row, null,
            React.createElement(Column, { size: 10 },
                React.createElement(BrandStageComponent, { fields: {
                        brandTitle: props.fields.brandName,
                        brandDescription: props.fields.brandDescription,
                    } })),
            React.createElement(VerticalSpacer, { size: "xxxl" }),
            React.createElement(BrandCategoriesComponent, { fields: { brandCategories: props.fields.brandCategories } }))));
};
