export function callApi(url) {
    (function loadRiskidentSnippetCode(id) {
        if (document.getElementById(id))
            return;
        var js = document.createElement('script');
        js.id = id;
        js.src = "//" + url;
        var fs = document.getElementsByTagName('script')[0];
        fs.parentNode && fs.parentNode.insertBefore(js, fs);
        // Passing on itemID to report product view. Relpace it to test on your store. ItemID should match the value listed in the Product Catalog
        // Firing the Scarab. Should be the last call on the page, called only once.
    })('scarab-js-api');
}
export function scarabPushes() {
    var myScript = document.createElement('script');
    myScript.type = 'text/javascript';
    myScript.insertAdjacentHTML('afterbegin', "\n\t\tvar ScarabQueue = ScarabQueue || [];\n\t\t" + scarabQueueConfig() + "\n\n\t\tScarabQueue.push(['go']);\n\n\t\t");
    var body = document.getElementsByTagName('body')[0];
    body.insertBefore(myScript, body.firstChild);
}
function formatPrice(price) {
    var priceStr = price.toString();
    var splittedPrice = priceStr.split('.')[0].split('');
    var formattedPrice;
    if (splittedPrice.length > 3) {
        var spliceDistance = splittedPrice.length - 3;
        var splicedArray = splittedPrice.splice(0, spliceDistance);
        formattedPrice = splicedArray.join('') + "'" + splittedPrice.join('') + "." + priceStr.split('.')[1];
    }
    else {
        formattedPrice = price;
    }
    return formattedPrice;
}
export function formatRecoDataToMetchProductTileData(products) {
    return products.map(function (product) {
        var id = product.id, brand = product.brand, title = product.title, price = product.price, c_color = product.c_color, available = product.available, image = product.image, link = product.link;
        var formattedPrice = formatPrice(price.toFixed(2));
        var spitId = id.split('_');
        return {
            id: spitId[0] ? spitId[0] : id,
            name: title,
            brand: brand,
            sizeInformation: 'sizeInfo',
            currentPriceFrom: "CHF " + formattedPrice,
            previewVariantId: id,
            variants: [
                {
                    colorName: c_color,
                    nos: false,
                    labels: [],
                    sizes: [
                        {
                            id: spitId[1] ? spitId[1] : id,
                            size: 'One Size',
                            available: available,
                            url: link,
                            image: image,
                        },
                    ],
                },
            ],
        };
    });
}
function scarabQueueConfig() {
    switch (window.recoEngineConfig.recoOption) {
        case 'Category':
        case 'Popular':
            return "\n\t\t\t\t" + categoryConfig() + "\n\t\t\t\t" + baseConfig() + "\n\t\t\t";
        default:
            return "" + baseConfig();
    }
}
function categoryConfig() {
    return "ScarabQueue.push(['category', '" + window.recoEngineConfig.productCategory + "']);";
}
function baseConfig() {
    return "\n\t\tScarabQueue.push([\n\t\t\t'recommend',\n\t\t\t{\n\t\t\t\tlogic: '" + window.recoEngineConfig.recoOption.toUpperCase() + "',\n\t\t\t\tcontainerId: 'personal-recs',\n\t\t\t\tsuccess: " + function (SC, render) { return window.getProductsFromRecoEngine && window.getProductsFromRecoEngine(SC); } + ",\n\t\t\t\tlimit: " + window.recoEngineConfig.limit + "\n\n\t\t\t},\n\t\t]);";
}
