var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { action, computed, decorate, observable, onBecomeObserved, onBecomeUnobserved } from 'mobx';
import { parseQueryString, toQueryString } from '../querystring';
var UrlManager = /** @class */ (function () {
    function UrlManager() {
        var _this = this;
        this.queryString = {};
        if (typeof window !== 'undefined') {
            this.updateStoreQueryString();
            var callback_1 = function () {
                _this.updateStoreQueryString();
            };
            onBecomeObserved(this, 'queryString', function () {
                _this.updateStoreQueryString();
                window.addEventListener('popstate', callback_1);
            });
            onBecomeUnobserved(this, 'queryString', function () {
                window.removeEventListener('popstate', callback_1);
            });
        }
    }
    /**
     * Add the given data to the current query string
     */
    UrlManager.prototype.extendQueryString = function (data, options) {
        writeDataToQueryString(data, __assign(__assign({}, options), { extend: true }));
        this.updateStoreQueryString();
    };
    /**
     * Replace the current querystring with the given data
     */
    UrlManager.prototype.replaceQueryString = function (data, options) {
        writeDataToQueryString(data, __assign(__assign({}, options), { extend: false }));
        this.updateStoreQueryString();
    };
    /**
     * Remove one or multiple keys from the query string
     */
    UrlManager.prototype.removeFromQueryString = function (keys, options) {
        var queryString = __assign({}, this.queryString);
        keys.forEach(function (key) {
            delete queryString[key];
        });
        this.replaceQueryString(queryString, options);
    };
    Object.defineProperty(UrlManager.prototype, "queryStringArray", {
        /**
         * Return all parameters as array
         */
        get: function () {
            var _this = this;
            var result = {};
            Object.keys(this.queryString).forEach(function (key) {
                var value = _this.queryString[key];
                result[key] = Array.isArray(value) ? value : [value];
            });
            return result;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UrlManager.prototype, "queryStringFlat", {
        /**
         * Returns the first entry for each key e.g.:
         *
         * ?color=orange&color=red&width=5
         * -> {color: 'orange', width: '5'}
         */
        get: function () {
            var result = {};
            var queryStringArray = this.queryStringArray;
            Object.keys(this.queryString).forEach(function (key) {
                result[key] = queryStringArray[key][0];
            });
            return result;
        },
        enumerable: false,
        configurable: true
    });
    UrlManager.prototype.updateStoreQueryString = function () {
        this.queryString = parseQueryString();
    };
    return UrlManager;
}());
decorate(UrlManager, { queryString: observable, queryStringArray: computed, queryStringFlat: computed });
// Decorate private methode as any
decorate(UrlManager, { updateStoreQueryString: action });
export var urlManager = new UrlManager();
function writeDataToQueryString(data, _a) {
    var extend = _a.extend, newHistoryEntry = _a.newHistoryEntry;
    // https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
    // Supported by all browsers but IE11
    if (typeof URLSearchParams === 'undefined') {
        return;
    }
    var queryString = toQueryString(data, extend ? location.search : '');
    var newSearch = queryString ? "?" + queryString : '';
    if (location.search !== newSearch) {
        var urlWithoutSearch = document.location.protocol + "//" + document.location.host + document.location.pathname;
        history[newHistoryEntry ? 'pushState' : 'replaceState'](null, document.title, newSearch || urlWithoutSearch);
    }
}
