import { track } from '@jelmoli/google-tag-manager';
export function trackAddToWishlist(productId, productName, brandName) {
    track({
        event: 'gaEvent',
        event_name: 'add_to_wishlist',
        add_to_wishlist: {
            product_id: productId,
            brand_name: brandName,
            event_category: 'wishlist',
            event_action: 'add',
            event_label: productId ? productId : productName,
        },
    });
}
export function trackRemoveFromWishlist(productId, productName, brandName) {
    track({
        event: 'gaEvent',
        event_name: 'add_to_wishlist',
        add_to_wishlist: {
            product_id: productId,
            brand_name: brandName,
            event_category: 'wishlist',
            event_action: 'remove',
            event_label: productId ? productId : productName,
        },
    });
}
export function trackOpenWishlistDrawer(productId, productName, brandName) {
    track({
        event: 'gaEvent',
        event_name: 'add_to_wishlist',
        add_to_wishlist: {
            product_id: productId || undefined,
            brand_name: brandName || undefined,
            event_category: 'wishlist',
            event_action: 'open',
            event_label: productId || productName || undefined,
        },
    });
}
export function trackGoToPDPWishlist(productId, productName, brandName) {
    track({
        event: 'gaEvent',
        event_name: 'add_to_wishlist',
        add_to_wishlist: {
            product_id: productId,
            brand_name: brandName,
            event_category: 'wishlist',
            event_action: 'go_to_pdp',
            event_label: productId ? productId : productName,
        },
    });
}
