var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useIsVisibleInViewportEffect } from '@jelmoli/hooks/src/useKeyboardEffect/useIsVisibleInViewport/useIsVisibleInViewport';
import { bindTranslations } from '@jelmoli/i18n';
import { Dropdown } from '@jelmoli/ui-components/src/atoms/Dropdown/Dropdown';
import { DropdownItem } from '@jelmoli/ui-components/src/atoms/DropdownItem/DropdownItem';
import { Bag } from '@jelmoli/ui-components/src/atoms/Icon/Bag';
import { PrimaryButton } from '@jelmoli/ui-components/src/atoms/PrimaryButton/PrimaryButton';
import { preventOutline } from '@jelmoli/ui-components/src/helpers/A/A';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import React, { useEffect, useState } from 'react';
import { addGiftcardToCart } from '../AddToCartFunctions/AddToCartFunctions';
import './e-commerce-box.scss';
import { ECommerceBoxTopGiftCard } from './ECommerceBoxParts/ECommerceBoxTopGiftcard';
var giftCardPrices = [
    {
        label: 'CHF 50.00',
        amount: 50,
    },
    {
        label: 'CHF 100.00',
        amount: 100,
    },
    {
        label: 'CHF 150.00',
        amount: 150,
    },
    {
        label: 'CHF 200.00',
        amount: 200,
    },
    {
        label: 'CHF 250.00',
        amount: 250,
    },
];
export var ECommerceBoxGiftCard = function (_a) {
    var product = _a.data, quantityValue = _a.quantityValue, 
    /** handle if the ECommerce PrimaryButton appears/disapears */
    onButtonVisiblityChange = _a.onButtonVisiblityChange, onClickScrollChange = _a.onClickScrollChange, onAddedToCart = _a.onAddedToCart, amountSelected = _a.amountSelected;
    var ref = useIsVisibleInViewportEffect(function (isVisible) {
        onButtonVisiblityChange(isVisible);
    });
    var _b = useState(giftCardPrices[0]), selectedAmount = _b[0], setSelectedAmount = _b[1];
    useEffect(function () {
        amountSelected(selectedAmount.amount);
    }, [selectedAmount]);
    if (!product.labels) {
        return null;
    }
    var t = bindTranslations(product.labels);
    return (React.createElement(React.Fragment, null,
        React.createElement(VerticalSpacer, { size: "xs" }),
        React.createElement(ECommerceBoxTopGiftCard, __assign({ amount: selectedAmount.label }, product)),
        React.createElement(VerticalSpacer, { size: "mx" }),
        React.createElement("div", { className: "o-ecommerce-box__size-wrapper" },
            React.createElement(Dropdown, { label: t('ProductDetailGiftcardAmount'), value: selectedAmount.amount, disableAutoFocusItem: true, onChange: function (event) {
                    setSelectedAmount(giftCardPrices.filter(function (giftCardPrice) { return giftCardPrice.amount === Number(event.target.value); })[0]);
                }, type: "fullwidth" }, giftCardPrices.map(function (giftCardPrice) { return (React.createElement(DropdownItem, { value: giftCardPrice.amount, key: giftCardPrice.label }, giftCardPrice.label)); }))),
        React.createElement(VerticalSpacer, { size: "m" }),
        React.createElement(PrimaryButton, { type: "button", uppercase: true, text: t('ProductDetailBasketLabel'), icon: React.createElement(Bag, { width: 16 }), fullWidth: true, fontResponsive: false, onClick: function () {
                if (quantityValue === 0) {
                    return;
                }
                return addGiftcardToCart(product.variant.baseId, product.sizeSwitch[0].variantId, selectedAmount.amount, onAddedToCart);
            } }),
        onButtonVisiblityChange && React.createElement("div", { ref: ref }),
        React.createElement(VerticalSpacer, { size: "m" }),
        React.createElement("button", __assign({ className: "o-ecommerce-box__more-info", onClick: function () { return onClickScrollChange(true); } }, preventOutline()),
            React.createElement("span", { className: "o-ecommerce-box__more-info-text" },
                React.createElement(Typography, { size: "xs", responsive: false }, t('ProductDetailMoreInformationLabel')))),
        React.createElement(VerticalSpacer, { hide: ['xs', 'sm'], size: "l" }),
        React.createElement(VerticalSpacer, { show: ['xs', 'sm'], size: "xl" })));
};
