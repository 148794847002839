import { createPutEndpoint } from 'request-registry';
import { getCSRFToken } from '../../Security/getCSRFToken/getCSRFToken';
import { getFavoritesEndpoint } from '../getFavorites/getFavorites';
export var addBrandToFavoritesEndpoint = createPutEndpoint({
    url: function () { return '/jelmoli-api/JelmoliMyAccountPreference/AddBrand'; },
    headers: {
        'X-RequestVerificationToken': function () { return getCSRFToken(); },
        'X-Requested-With': 'XMLHttpRequest',
    },
    afterSuccess: function () { return Promise.all([getFavoritesEndpoint.refresh()]); },
});
