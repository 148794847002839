import { bindTranslations } from '@jelmoli/i18n';
import { isUserLoggedInLazy } from '@jelmoli/sitecore-services/src/Account/isUserLoggedIn/isUserLoggedInLazy';
import { trackOpenWishlistDrawer } from '@jelmoli/sitecore-services/src/Tracking/Wishlist/Wishlist';
import { getWishlistIds } from '@jelmoli/sitecore-services/src/Wishlist/getWishlist/getWishlist';
import { getWishlistLabels } from '@jelmoli/sitecore-services/src/Wishlist/getWishlistLabels/getWishlistLabels';
import { HeaderButton, HeaderButtonBadge } from '@jelmoli/ui-components/src/atoms/HeaderButton/HeaderButton';
import { Heart } from '@jelmoli/ui-components/src/atoms/Icon/Heart';
import { waitForLogin } from '@jelmoli/ui-components/src/organism/LoginDrawer/LoginDrawer';
import { Wishlist } from '@jelmoli/ui-components/src/organism/Wishlist/Wishlist';
import React, { useState } from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
export var HeaderWishlistButtonComponent = function () {
    var isLoggedIn = isUserLoggedInLazy();
    var wishlistLabelsData = useGetEndPointLazy(getWishlistLabels, {});
    var wishlistIdData = useGetEndPointLazy(getWishlistIds, {}, isLoggedIn);
    var _a = useState(false), wishlistOpen = _a[0], setWishlistOpen = _a[1];
    var wishlistAmount = wishlistIdData.hasData ? wishlistIdData.value.entriesCount : 0;
    var itemsInWishlist = wishlistAmount > 0 ? wishlistAmount : '';
    var t = bindTranslations(wishlistLabelsData.value);
    return (React.createElement(React.Fragment, null,
        React.createElement(HeaderButton, { Icon: Heart, title: t('WishlistIconHoverText'), onClick: function () {
                trackOpenWishlistDrawer();
                return isLoggedIn ? setWishlistOpen(true) : waitForLogin();
            } },
            React.createElement(HeaderButtonBadge, null, itemsInWishlist)),
        React.createElement(Wishlist, { isOpen: wishlistOpen, openDrawer: setWishlistOpen })));
};
