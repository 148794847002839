import { createPostEndpoint } from 'request-registry';
import { getCSRFToken } from '../../Security/getCSRFToken/getCSRFToken';
export var registerUserToken = createPostEndpoint({
    url: function () { return '/jelmoli-api/jelmoliuser/RegisterUserToken'; },
    headers: {
        'X-RequestVerificationToken': function () { return getCSRFToken(); },
        'X-Requested-With': 'XMLHttpRequest',
        'jelmoli-RequestToken': function (token) { return token.token || ''; },
    },
});
