import { useFormValidationFieldErrorMessage } from 'nested-form-validation';
import React from 'react';
import { Typography } from '../../helpers/Typography/Typography';
import { VerticalSpacer } from '../../helpers/VerticalSpacer/VerticalSpacer';
import './form-field-error-message.scss';
export var FormFieldErrorMessage = function (props) {
    var variant = props.variant || 'inline';
    var errorMessage = useFormValidationFieldErrorMessage(props.name, props.showAlsoIfUntouched);
    if (!errorMessage) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        props.verticalSpaceTop && React.createElement(VerticalSpacer, { size: props.verticalSpaceTop }),
        variant === 'plain' ? (React.createElement("span", null, errorMessage)) : (React.createElement(FormFieldErrorMessageText, { name: props.name }, errorMessage))));
};
export var FormFieldErrorMessageText = function (_a) {
    var children = _a.children, name = _a.name;
    return (React.createElement(Typography, { size: "xxs", type: "regular", responsive: false },
        React.createElement("span", { className: "a-form-field-error-message--inline", "data-name": name }, children)));
};
