import useComponentSize from '@rehooks/component-size';
import React, { useEffect, useRef } from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import './scroll-into-view.scss';
/**
 * Allows to scroll to a certain element
 */
export function ScrollIntoView(props) {
    return (React.createElement(React.Fragment, null,
        props.inView && React.createElement(ScrollToAnchor, { onScroll: props.onScroll, smooth: props.smooth }),
        props.children));
}
/**
 * The actual target which is scrolled to
 *
 * It should only be in dom if needed as watching its
 * visibility comes with some performance costs
 */
var ScrollToAnchor = function (props) {
    var _a = useIsVisible(), ref = _a.ref, isVisible = _a.isVisible;
    useEffect(function () {
        if (isVisible && ref.current) {
            ref.current.scrollIntoView({ block: 'start', behavior: props.smooth ? 'smooth' : 'auto' });
            if (props.onScroll) {
                props.onScroll();
            }
        }
    }, [isVisible, ref.current]);
    smoothscroll.polyfill();
    return (React.createElement("div", { className: "h-scroll-to-anchor__wrapper" },
        React.createElement("div", { ref: ref, className: "h-scroll-to-anchor" })));
};
/**
 * Returns `true` if the element has dimensions.
 *
 * It uses the ResizeObserver polyfill which comes
 * with performance costs and should not be overused
 */
var useIsVisible = function () {
    var ref = useRef(null);
    var _a = useComponentSize(ref), width = _a.width, height = _a.height;
    var isVisible = Math.max(width, height) > 0;
    return {
        ref: ref,
        isVisible: isVisible,
    };
};
