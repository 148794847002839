import { track } from '@jelmoli/google-tag-manager';
export function trackNewsletterRegistration(success) {
    track({
        event: 'gaEvent',
        event_name: 'newsletter_sign_up',
        newsletter_sign_up: {
            status: success ? 'success' : 'fail',
            event_category: 'newsletter',
            event_action: 'sign_up',
            event_label: success ? 'success' : 'fail',
        },
    });
}
export function trackNewsletterUnsubscribe(success) {
    track({
        event: 'gaEvent',
        event_name: 'newsletter_unsubscribe',
        newsletter_unsubscribe: {
            event_category: 'newsletter',
            event_action: 'unsubscribe',
            event_label: success ? 'success' : 'fail',
        },
    });
}
