var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Column, Row } from '@jelmoli/bootstrap-grid/index';
import { trackNavigation } from '@jelmoli/sitecore-services/src/Tracking/Navigation/NavigationTracking';
import { trackSocialMedia } from '@jelmoli/sitecore-services/src/Tracking/SocialMedia/SocialMediaTracking';
import { ExpansionPanel } from '@jelmoli/ui-components/src/atoms/ExpansionPanel/ExpansionPanel';
import { ExpansionPanelDetails } from '@jelmoli/ui-components/src/atoms/ExpansionPanelDetails/ExpansionPanelDetails';
import { A } from '@jelmoli/ui-components/src/helpers/A/A';
import { HorizontalSpacer } from '@jelmoli/ui-components/src/helpers/HorizontalSpacer/HorizontalSpacer';
import { hasTextFieldValue, } from '@jelmoli/ui-components/src/helpers/SitecoreTypes/SitecoreTypes';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { Visibility } from '@jelmoli/ui-components/src/helpers/Visibility/Visibility';
import React, { useState } from 'react';
import './footer-links.scss';
export var FooterLinks = function (_a) {
    var fields = _a.fields;
    var _b = useState(), panelOpen = _b[0], setPanelOpen = _b[1];
    var handlePanelChange = function (panelIndex) {
        setPanelOpen(panelOpen === panelIndex ? -1 : panelIndex);
    };
    var columnsArray = [
        {
            title: fields.FooterDirectContactTitle,
            links: fields.FooterDirectContactLinks,
            footerHelpCenter: {
                FooterHelpCenterButtonLink: fields.FooterHelpCenterButtonLink,
                FooterHelpCenterButtonText: fields.FooterHelpCenterButtonText,
                FooterHelpCenterDescription: fields.FooterHelpCenterDescription,
                FooterHelpCenterTitle: fields.FooterHelpCenterTitle,
            },
        },
        {
            title: fields.FooterSocialMediaTitle,
            links: fields.FooterSocialMediaLinks,
            trackingFunction: function (linkName) {
                trackSocialMedia(linkName);
            },
        },
        {
            title: fields.FooterAboutJelmoliTitle,
            links: fields.FooterAboutJelmoliLinks,
            trackingFunction: function (linkName) {
                trackNavigation({
                    currentLevel: 'Footer',
                    trackLabel: linkName,
                });
            },
        },
        {
            title: fields.FooterSecurityandOrdersTitle,
            links: fields.FooterSecurityandOrdersLinks,
            trackingFunction: function (linkName) {
                trackNavigation({
                    currentLevel: 'Footer',
                    trackLabel: linkName,
                });
            },
        },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(Visibility, { show: ['xs', 'sm'] }, columnsArray.map(function (column, index) {
            return (React.createElement(ExpansionPanel, { key: index, type: "footer", summary: {
                    text: column.title.value,
                    size: 'xxs',
                    responsive: false,
                    type: 'medium',
                    typeExpanded: 'medium',
                    uppercase: true,
                }, onChange: function () { return handlePanelChange(index); }, expanded: panelOpen === index },
                React.createElement(ExpansionPanelDetails, null,
                    React.createElement(FooterLinkSegment, { links: column.links, title: column.title, trackingFunction: column.trackingFunction }),
                    column.footerHelpCenter && (React.createElement(FooterHelpCenter, { FooterHelpCenterTitle: column.footerHelpCenter.FooterHelpCenterTitle, FooterHelpCenterDescription: column.footerHelpCenter.FooterHelpCenterDescription, FooterHelpCenterButtonText: column.footerHelpCenter.FooterHelpCenterButtonText, FooterHelpCenterButtonLink: column.footerHelpCenter.FooterHelpCenterButtonLink })))));
        })),
        React.createElement(Visibility, { hide: ['xs', 'sm'] },
            React.createElement(Row, null, columnsArray.map(function (column, index) {
                return (React.createElement(Column, { size: { xs: 12, md: 3 }, key: index },
                    React.createElement(FooterLinkSegment, { links: column.links, title: column.title, trackingFunction: column.trackingFunction }),
                    column.footerHelpCenter && (React.createElement(FooterHelpCenter, { FooterHelpCenterTitle: column.footerHelpCenter.FooterHelpCenterTitle, FooterHelpCenterDescription: column.footerHelpCenter.FooterHelpCenterDescription, FooterHelpCenterButtonText: column.footerHelpCenter.FooterHelpCenterButtonText, FooterHelpCenterButtonLink: column.footerHelpCenter.FooterHelpCenterButtonLink }))));
            })))));
};
var FooterHelpCenter = function (props) {
    return (React.createElement("div", { className: "feat-footer-column__help-center" },
        React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
        React.createElement(VerticalSpacer, { size: "m", responsive: false }),
        React.createElement(Typography, { size: "xxs", uppercase: true, responsive: false, type: "medium", field: props.FooterHelpCenterTitle }),
        React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
        React.createElement(Typography, { size: "xs", responsive: false, field: props.FooterHelpCenterDescription }),
        React.createElement(VerticalSpacer, { size: "m", responsive: false })));
};
var FooterLinkSegment = function (_a) {
    var title = _a.title, links = _a.links, trackingFunction = _a.trackingFunction;
    return (React.createElement(React.Fragment, null,
        React.createElement(Visibility, { hide: ['xs', 'sm'] },
            React.createElement(Typography, { size: "xxs", responsive: false, type: "medium", uppercase: true }, title.value),
            React.createElement(VerticalSpacer, { size: "m" }),
            React.createElement(FooterLinkSegmentList, { links: links, trackingFunction: trackingFunction })),
        React.createElement(Visibility, { show: ['xs', 'sm'] },
            React.createElement(FooterLinkSegmentList, { links: links, trackingFunction: trackingFunction }))));
};
var FooterLinkSegmentList = function (_a) {
    var links = _a.links, trackingFunction = _a.trackingFunction;
    return (React.createElement(React.Fragment, null, links.map(function (link, index) {
        return (React.createElement("div", { className: "feat-footer-column__item", key: link.fields.CTALink.value.href, onClick: function () {
                if (trackingFunction && link.fields.CTALinkTitle.value) {
                    trackingFunction(link.fields.CTALink.value.href);
                }
            } },
            hasTextFieldValue(link.fields.CTALinkSVGIcon) && (React.createElement(React.Fragment, null,
                React.createElement("div", { dangerouslySetInnerHTML: { __html: link.fields.CTALinkSVGIcon.value }, className: "feat-footer-column__icon" }),
                React.createElement(HorizontalSpacer, { size: "xxs" }))),
            hasTextFieldValue(link.fields.CTALinkTitle) && (React.createElement(React.Fragment, null,
                React.createElement(Visibility, { show: ['lg', 'xl'] },
                    React.createElement(Typography, { responsive: false, size: "s" },
                        React.createElement(A, __assign({ "aria-label": link.fields.CTALinkTitle.value, className: "feat-footer-column__link", href: link.fields.CTALink.value.href }, (link.fields.CTALink.value.target && {
                            target: link.fields.CTALink.value.target,
                        }), (link.fields.CTALink.value.rel && {
                            rel: link.fields.CTALink.value.rel,
                        })), link.fields.CTALinkTitle.value))),
                React.createElement(Visibility, { hide: ['lg', 'xl'] },
                    React.createElement(Typography, { responsive: false, size: "xs" },
                        React.createElement(A, __assign({ "aria-label": link.fields.CTALinkTitle.value, className: "feat-footer-column__link", href: link.fields.CTALink.value.href }, (link.fields.CTALink.value.target && {
                            target: link.fields.CTALink.value.target,
                        }), (link.fields.CTALink.value.rel && {
                            rel: link.fields.CTALink.value.rel,
                        })), link.fields.CTALinkTitle.value))))),
            index !== links.length - 1 && (React.createElement(VerticalSpacer, { size: { xs: 'm', sm: 'm', md: 'xs', lg: 'xs', xl: 'xs' } }))));
    })));
};
