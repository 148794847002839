import { retriggerConfirmEmail } from '@jelmoli/sitecore-services/src/Account/retriggerConfirmEmail/retriggerConfirmEmail';
import { showNotification } from '@jelmoli/ui-components/src/atoms/Notification/Notification';
import { TextLink } from '@jelmoli/ui-components/src/atoms/TextLink/TextLink';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import React from 'react';
export var CustomMessage = function (props) {
    var messages = props.message.split('/');
    return (React.createElement(Typography, { responsive: false, size: "s" },
        messages[0],
        ' ',
        React.createElement(TextLink, { "aria-label": "notification message", color: "white", type: "notification", underlined: true, onClick: function () {
                customAction(props.type);
            } }, messages[1])));
};
function customAction(type) {
    switch (type) {
        case 'retriggerEmail': {
            var email = window.localStorage.getItem('email');
            window.localStorage.removeItem('email');
            retriggerConfirmEmail({}, { newEmail: email || '' })
                .then(function (response) { return showNotification('success', response.globalMessage); })
                .catch(function (response) { return showNotification('error', response.globalMessage); });
            break;
        }
        default: {
            var email = window.localStorage.getItem('email');
            window.localStorage.removeItem('email');
            retriggerConfirmEmail({}, { newEmail: email || '' })
                .then(function (response) { return showNotification('success', response.globalMessage); })
                .catch(function (response) { return showNotification('error', response.globalMessage); });
            break;
        }
    }
}
