var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useIsVisibleInViewport } from '@jelmoli/hooks/src/useKeyboardEffect/useIsVisibleInViewport/useIsVisibleInViewport';
import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import smoothscroll from 'smoothscroll-polyfill';
import { A } from '../A/A';
import './horizontal-scroll.scss';
/**
 * A wrapper util which will hide the horizontal scrollbar and allows
 * to add custom scroll icons on the side
 */
export var HorizontalScrollWrapper = function (props) {
    var _a = useState(false), isScrollDisabled = _a[0], setIsScrollDisabled = _a[1];
    var _b = useState({ left: false, right: false }), showArrows = _b[0], setShowArrows = _b[1];
    var _c = useState(false), isLastLetterVisibilityChecked = _c[0], setIsLastLetterVisibilityChecked = _c[1];
    var wrapperRef = useRef(null);
    var firstLetter = useIsVisibleInViewport(0);
    var lastLetter = useIsVisibleInViewport(0);
    var showArrowLeft = firstLetter.isVisibleInViewport === false;
    var showArrowRight = lastLetter.isVisibleInViewport === false;
    var scrollableWrapper = wrapperRef.current;
    useEffect(function () {
        if (!props.scrollTo || !scrollableWrapper) {
            return;
        }
        if (scrollableWrapper.clientWidth < props.scrollTo) {
            scrollableWrapper.scrollBy({ left: props.scrollTo, behavior: 'smooth' });
        }
    }, [props.scrollTo, scrollableWrapper]);
    useEffect(function () {
        smoothscroll.polyfill();
        setShowArrows({
            left: firstLetter.isVisibleInViewport === false,
            right: lastLetter.isVisibleInViewport === false,
        });
    }, []);
    useEffect(function () {
        if (lastLetter.isVisibleInViewport !== undefined && !isLastLetterVisibilityChecked) {
            setIsScrollDisabled(lastLetter.isVisibleInViewport);
            setIsLastLetterVisibilityChecked(true);
        }
    }, [lastLetter]);
    var showLeftArrow = props.checkArrowsOnlyOnce ? showArrows.left : showArrowLeft;
    var showRightArrow = props.checkArrowsOnlyOnce ? showArrows.right : showArrowRight;
    return (React.createElement("div", { className: classnames('h-horizontal-scroll__wrapper', props.fullWidth && 'h-horizontal-scroll__wrapper--fullwidth') },
        React.createElement("div", { className: classnames('h-horizontal-scroll', isScrollDisabled && 'h-horizontal-scroll__wrapper--disable-scroll'), ref: wrapperRef },
            React.createElement("div", { className: "h-horizontal-scroll__items-wrapper" },
                React.createElement("div", { className: classnames('h-horizontal-scroll__ref-wrapper', props.isSlider && 'h-horizontal-scroll__slider-ref-wrapper') },
                    React.createElement("div", { ref: firstLetter.ref, className: "h-horizontal-scroll__ref" })),
                props.children,
                React.createElement("div", { className: classnames('h-horizontal-scroll__ref-wrapper', 'h-horizontal-scroll__ref-wrapper--right', props.isSlider && 'h-horizontal-scroll__slider-ref-wrapper--right') },
                    React.createElement("div", { ref: lastLetter.ref, className: "h-horizontal-scroll__ref" })))),
        React.createElement(ScrollButton, { scrollableWrapper: scrollableWrapper, showArrow: showLeftArrow, arrow: props.leftArrow, direction: "left", isSlider: props.isSlider, productSlider: props.productSlider }),
        React.createElement(ScrollButton, { scrollableWrapper: scrollableWrapper, showArrow: showRightArrow, arrow: props.rightArrow, direction: "right", isSlider: props.isSlider, productSlider: props.productSlider })));
};
var ScrollButton = function (props) {
    return (React.createElement(CSSTransition, __assign({ in: props.showArrow, timeout: 500 }, (!props.isSlider ? { mountOnEnter: true, unmountOnExit: true } : ''), { classNames: {
            appear: "h-horizontal-scroll__button--enter",
            enter: "h-horizontal-scroll__button--enter",
            enterActive: "h-horizontal-scroll__button--enter-active",
            enterDone: "h-horizontal-scroll__button--enter-done",
            exit: "h-horizontal-scroll__button--exit",
            exitActive: "h-horizontal-scroll__button--exit-active",
        } }),
        React.createElement(A, { "aria-label": "Horizontal scroll button " + props.direction, className: classnames("h-horizontal-scroll__button-" + props.direction, props.isSlider && "h-horizontal-scroll__" + props.direction + "-button-wrapper", props.productSlider && 'h-horizontal-scroll__button-norelative'), onClick: function () {
                var wrapper = props.scrollableWrapper;
                var direction = props.direction === 'left' ? -1 : 1;
                if (!wrapper) {
                    return;
                }
                var leftOffset = props.isSlider
                    ? direction * wrapper.clientWidth
                    : (direction * wrapper.clientWidth) / 2;
                wrapper.scrollBy({ left: leftOffset, behavior: 'smooth' });
            } }, props.arrow)));
};
