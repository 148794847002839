import { useEffect, useRef, useState } from 'react';
export var useZyseMeApi = function (load) {
    var _a = useState({}), zysmeApi = _a[0], setApi = _a[1];
    useEffect(function () {
        if (!load)
            return;
        var mounted = true;
        import('./zyseMeApi')
            .then(function (_a) {
            var loadZyseMeApi = _a.loadZyseMeApi;
            return loadZyseMeApi();
        })
            .then(function (api) {
            if (mounted) {
                setApi({ api: api });
            }
        });
        return function () {
            mounted = false;
        };
    }, [load]);
    return zysmeApi.api;
};
/**
 * Hook to wrap the zyseMe api
 */
export var useZyseMeQuestionnaire = function () {
    var _a = useState(), garmentModelId = _a[0], setGamentModelId = _a[1];
    var resultRef = useRef();
    var listenerRef = useRef([]);
    // Only load the api if we have an garmentModelId
    var ZyseMe = useZyseMeApi(garmentModelId !== undefined);
    useEffect(function () {
        if (!garmentModelId || !ZyseMe) {
            return;
        }
        var zyseMe = new ZyseMe({
            schemaId: 'a2968229-47db-4c67-a3ab-eaeead2b8041',
            retailerId: 'ae7f440d-5e2e-4d57-a79d-ac526d031dbb',
        });
        zyseMe.questionnaire.open(garmentModelId);
        var notifyListeners = function (zyseMeId) {
            var listeners = listenerRef.current;
            while (listeners.length) {
                listeners.shift()(zyseMeId);
            }
        };
        /** Store ZyseMe response until the questionnaire is closed */
        var handleNewZyseMeResponse = function (event) {
            resultRef.current = event.data;
        };
        /** Handle if the X of the questionnaire is clicked */
        var handleQuestionnaireClosed = function () {
            // Reset overlay
            setGamentModelId(undefined);
            notifyListeners(undefined);
        };
        /** Handle if the questionnaire was finished successfully */
        var handleQuestionnaireFinished = function () {
            var latestEventResult = resultRef.current;
            notifyListeners(latestEventResult ? latestEventResult.id : undefined);
            // Reset overlay
            setGamentModelId(undefined);
        };
        document.addEventListener('questionnaireClosed', handleQuestionnaireClosed);
        document.addEventListener('questionnaireFinished', handleQuestionnaireFinished);
        document.addEventListener('garmentUnitChanged', handleNewZyseMeResponse);
        return function () {
            document.removeEventListener('questionnaireClosed', handleQuestionnaireClosed);
            document.removeEventListener('questionnaireFinished', handleQuestionnaireFinished);
            document.removeEventListener('garmentUnitChanged', handleNewZyseMeResponse);
            zyseMe.questionnaire.close();
        };
    }, [garmentModelId, ZyseMe]);
    /**
     * Opens the Questionnaire for the given product and returns the
     * customized product id
     */
    var openQuestionnaire = function (questionnaireGarmentModelId) {
        setGamentModelId(questionnaireGarmentModelId);
        return new Promise(function (resolve) {
            listenerRef.current.push(resolve);
        });
    };
    return openQuestionnaire;
};
