import { IconList } from '@jelmoli/ui-components/src/atoms/IconList/IconList';
import { useIsSitecoreEditMode } from '@jelmoli/ui-components/src/helpers/EditMode/editMode';
import React from 'react';
import { EditorHintComponent } from '../../Website/EditorHintComponent/EditorHintComponent';
export var IconListComponent = function (props) {
    var editMode = useIsSitecoreEditMode();
    if (editMode && props.fields.IconLinks.length === 0) {
        return (React.createElement(EditorHintComponent, { parameters: {
                title: 'C2036 Icon List - please select links',
                message: '',
                messageType: 'warning',
            } }));
    }
    else {
        return React.createElement(IconList, { list: props.fields.IconLinks });
    }
};
