import { Container } from '@jelmoli/ui-components/src/helpers/Container/Container';
import { ShopTheLookOverlayLoader } from '@jelmoli/ui-components/src/organism/ShopTheLookOverlayContent/ShopTheLookOverlayLoader';
import { ShopTheLookTeaser, } from '@jelmoli/ui-components/src/organism/ShopTheLookTeaser/ShopTheLookTeaser';
import React, { useState } from 'react';
export var ShopTheLookComponent = function (_a) {
    var fields = _a.fields, savePosition = _a.savePosition;
    var _b = useState(false), isOpen = _b[0], setOpen = _b[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, { size: "l" },
            React.createElement(ShopTheLookTeaser, { teaserImage: fields.TopicOverviewTeaserImage, teaserImageFrame: fields.ShopTheLookTeaserImageFrame.value, teaserImageSolidFrameColor: fields.ShopTheLookTeaserImageSolidFrameColor.value, teaserImageBlendFrameStyle: fields.ShopTheLookTeaserImageBlendFrameStyle.value, teaserImageBlendFrameColor: fields.ShopTheLookTeaserImageBlendFrameColor.value, teaserLinkLabel: fields.ShopTheLookTeaserLinkLabel, teaserLinkTextColour: fields.ShopTheLookTeaserLinkTextColour.value, teaserCaption: fields.ShopTheLookTeaserCaption, teaserStaffMemberLink: fields.ShopTheLookContentStaffMemberLink, teaserStaffPickLabel: fields.ShopTheLookContentStaffPickLabel, teaserUrl: fields.url, onClick: function () {
                    if (savePosition) {
                        savePosition();
                    }
                    setOpen(true);
                } })),
        React.createElement(ShopTheLookOverlayLoader, { contentTitle: fields.ShopTheLookContentTitle, contentStaffPickLabel: fields.ShopTheLookContentStaffPickLabel, contentQuoteText: fields.ShopTheLookContentQuoteText, contentStaffMemberLink: fields.ShopTheLookContentStaffMemberLink, contentImage: fields.ShopTheLookContentImage, contentImageCaption: fields.ShopTheLookContentImageCaption, contentDownload: fields.ShopTheLookContentDownload, contentBrandLink1: fields.ShopTheLookContentBrandLink1, contentBrandLink2: fields.ShopTheLookContentBrandLink2, contentBrandLink3: fields.ShopTheLookContentBrandLink3, inTheCartText: fields.InTheCartText, giftCardPrice: fields.GiftcardPrice, contentShopTheLookProducts: fields.ShopTheLookProducts, teaserUrl: fields.url, open: isOpen, onClose: function () { return setOpen(false); } })));
};
