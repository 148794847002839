import { trackNavigation } from '@jelmoli/sitecore-services/src/Tracking/Navigation/NavigationTracking';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { Visibility } from '@jelmoli/ui-components/src/helpers/Visibility/Visibility';
import { SearchDrawer } from '@jelmoli/ui-components/src/organism/Search/Search';
import React, { useState } from 'react';
import './shop-header-search.scss';
export var ShopHeaderSearchComponent = function (props) {
    var _a = useState(false), searchOpen = _a[0], setSearchOpen = _a[1];
    var handleSearchClick = function () {
        trackNavigation({
            currentLevel: 'Main Navigation',
            trackLabel: props.searchText,
        });
        props.closeFlyout && props.closeFlyout();
        setSearchOpen(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "feat-shop-header__search" },
            React.createElement("div", { className: "feat-shop-header__search-text", onClick: handleSearchClick },
                React.createElement(Visibility, { show: ['lg', 'xl'] },
                    React.createElement(Typography, { uppercase: true, size: "xs", responsive: false, type: "medium" }, props.searchText && props.searchText)),
                React.createElement(Visibility, { show: ['xs', 'sm', 'md'] },
                    React.createElement(Typography, { uppercase: true, size: "s", responsive: false, type: "medium" }, props.searchText && props.searchText))),
            React.createElement(SearchDrawer, { title: props.searchText, isOpen: searchOpen, openDrawer: setSearchOpen }))));
};
export var ShopHeaderCollapsedSearchComponent = function (props) {
    var _a = useState(false), searchOpen = _a[0], setSearchOpen = _a[1];
    var handleSearchClick = function () {
        props.closeFlyout && props.closeFlyout();
        setSearchOpen(false);
    };
    return (React.createElement("div", { className: "feat-shop-header__search-collapsed-container" },
        React.createElement("span", { className: "feat-shop-header__search-collapsed ", onClick: handleSearchClick }),
        React.createElement(SearchDrawer, { title: props.searchText, isOpen: searchOpen, openDrawer: setSearchOpen })));
};
