import { useEffect, useState } from 'react';
import { hasEverBeenVisibleInViewport } from '../useKeyboardEffect/useIsVisibleInViewport/useIsVisibleInViewport';
export function useLoadOnceInViewport(loader, fallback) {
    var _a = hasEverBeenVisibleInViewport(), ref = _a.ref, hasBeenVisible = _a.hasBeenVisible;
    var _b = useState(fallback), loadModule = _b[0], setModule = _b[1];
    useEffect(function () {
        if (!hasBeenVisible) {
            return;
        }
        var isLoading = true;
        loader().then(function (lazyModule) {
            if (isLoading) {
                setModule(lazyModule);
            }
        });
        return function () {
            isLoading = false;
        };
    }, [hasBeenVisible]);
    return [ref, loadModule];
}
export function useLoadNamedOnceInViewport(key, loader, fallback) {
    var _a = useLoadOnceInViewport(loader), ref = _a[0], loadModule = _a[1];
    var loadNamed = loadModule === undefined ? fallback : loadModule[key];
    return [ref, loadNamed];
}
