import { createDeleteEndpoint } from 'request-registry';
import { getCSRFToken } from '../../Security/getCSRFToken/getCSRFToken';
import { getFavoritesEndpoint } from '../getFavorites/getFavorites';
export var removeBrandFromFavoritesEndpoint = createDeleteEndpoint({
    url: function (_a) {
        var id = _a.id;
        return "/jelmoli-api/JelmoliMyAccountPreference/RemoveBrand/" + id;
    },
    headers: {
        'X-RequestVerificationToken': function () { return getCSRFToken(); },
        'X-Requested-With': 'XMLHttpRequest',
    },
    afterSuccess: function () { return Promise.all([getFavoritesEndpoint.refresh()]); },
});
