var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { bindTranslations } from '@jelmoli/i18n';
import { getNotificationLabels } from '@jelmoli/sitecore-services/src/Notifications/getNotificationLabels/getNotificationLabels';
import { showSuccessNotification } from '../../atoms/Notification/Notification';
import './disconnected-overlay.scss';
var rootOverlayClass = 'h-disconnected-overlay';
var ovarlayTextClass = rootOverlayClass + "__text";
var ovarlayHideTextClass = rootOverlayClass + "__text--hide";
var hideRootOverlayClass = rootOverlayClass + "--hide";
var offlineErrorMessageLabel = 'OfflineNotificationErrorMessage';
var backOfflineMessageLabel = 'OfflineNotificationSuccessMessage';
var fallbackOfflineErrorMessage = 'Sie sind zur Zeit offline und können nicht weiterfahren. Sobald Sie wieder online sind können Sie weiter shoppen';
var fallbackOfflineSuccessMessage = 'Sie sind wieder online und können weiter shoppen';
export var createDisconnectedOverlay = function () {
    var offlineNotificationErrorMessage = sessionStorage.getItem(offlineErrorMessageLabel);
    var overlay = document.createElement('div');
    var overlayText = document.createElement('p');
    overlayText.classList.add(ovarlayTextClass);
    overlayText.innerText = offlineNotificationErrorMessage;
    var body = document.querySelector('body');
    overlay.classList.add(rootOverlayClass);
    body.appendChild(overlay);
    body.appendChild(overlayText);
};
export var closeDisconnectedOverlay = function () {
    var disconnectedOverlayCn = document.querySelector("." + rootOverlayClass);
    var disconnectedOverlayTextCn = document.querySelector("." + ovarlayTextClass);
    var offlineNotificationSuccessMessage = sessionStorage.getItem(backOfflineMessageLabel);
    if (!disconnectedOverlayCn.classList.contains(hideRootOverlayClass) &&
        !disconnectedOverlayTextCn.classList.contains(ovarlayHideTextClass)) {
        disconnectedOverlayTextCn.classList.add(ovarlayHideTextClass);
        disconnectedOverlayCn.classList.add(hideRootOverlayClass);
        showSuccessNotification(offlineNotificationSuccessMessage);
    }
};
export var openDisconnectedOverlay = function () {
    var disconnectedOverlayCn = document.querySelector("." + rootOverlayClass);
    var disconnectedOverlayTextCn = document.querySelector("." + ovarlayTextClass);
    if (disconnectedOverlayCn && disconnectedOverlayTextCn) {
        disconnectedOverlayTextCn.classList.remove(ovarlayHideTextClass);
        disconnectedOverlayCn.classList.remove(hideRootOverlayClass);
    }
    else {
        createDisconnectedOverlay();
    }
};
export var setOfflineNotificationsToSessionStorage = function () { return __awaiter(void 0, void 0, void 0, function () {
    var offlineNotificationSuccessMessage, offlineNotificationErrorMessage, offlineLabels, t;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                offlineNotificationSuccessMessage = sessionStorage.getItem(backOfflineMessageLabel);
                offlineNotificationErrorMessage = sessionStorage.getItem(offlineErrorMessageLabel);
                if (!(!offlineNotificationErrorMessage && !offlineNotificationSuccessMessage)) return [3 /*break*/, 2];
                return [4 /*yield*/, getNotificationLabels({})];
            case 1:
                offlineLabels = _a.sent();
                t = bindTranslations(offlineLabels);
                sessionStorage.setItem(offlineErrorMessageLabel, offlineLabels.OfflineNotificationErrorMessage ? t(offlineErrorMessageLabel) : fallbackOfflineErrorMessage);
                sessionStorage.setItem(backOfflineMessageLabel, offlineLabels.OfflineNotificationSuccessMessage ? t(backOfflineMessageLabel) : fallbackOfflineSuccessMessage);
                _a.label = 2;
            case 2: return [2 /*return*/];
        }
    });
}); };
