var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { action, computed, decorate } from 'mobx';
import { createObservableEndpoint } from 'request-registry-mobx';
import { showErrorNotification } from '../../../../ui-components/src/atoms/Notification/Notification';
import { trackError } from '../../Tracking/Error/ErrorTesting';
import { addCategory } from '../addCategory/addCategory';
import { getAllCategories } from '../getAllCategories/getAllCategories';
import { getSavedCategories } from '../getSavedCategories/getSavedCategories';
import { removeAllSubcategories } from '../removeAllSubcategories/removeAllSubcategories';
import { removeCategory } from '../removeCategory/removeCatgory';
import { removeRootCategory } from '../removeRootCategory/removeRootCategory';
export var CheckedType;
(function (CheckedType) {
    CheckedType[CheckedType["checked"] = 0] = "checked";
    CheckedType[CheckedType["intermediate"] = 1] = "intermediate";
    CheckedType[CheckedType["unchecked"] = 2] = "unchecked";
})(CheckedType || (CheckedType = {}));
var CategoryStore = /** @class */ (function () {
    function CategoryStore() {
        this.allCategoriesBackend = createObservableEndpoint(getAllCategories, {});
        this.allSavedCategoriesBackend = createObservableEndpoint(getSavedCategories, {});
    }
    Object.defineProperty(CategoryStore.prototype, "allCategories", {
        /** Return categories from API call */
        get: function () {
            return this.allCategoriesBackend.hasData ? this.allCategoriesBackend.value.hierarchy : {};
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CategoryStore.prototype, "allCategoryLabels", {
        /** Return all labels */
        get: function () {
            var allData = this.allCategoriesBackend;
            return allData.hasData ? allData.value.labels : {};
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CategoryStore.prototype, "allSavedCategories", {
        /** Return all categories which are saved by the user */
        get: function () {
            return this.allSavedCategoriesBackend.hasData ? this.allSavedCategoriesBackend.value.entries : {};
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CategoryStore.prototype, "rootCategories", {
        /** Get all root categories based on hierarchy */
        get: function () {
            var _this = this;
            var allLabels = this.allCategoryLabels;
            var allLabelKeys = Object.keys(allLabels);
            var allRootCategories = [];
            allLabelKeys.map(function (key) {
                if (_this.allCategories[key]) {
                    allRootCategories.push({
                        label: allLabels[key],
                        value: key,
                    });
                }
            });
            return allRootCategories;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CategoryStore.prototype, "allChips", {
        /** Return all saved categies and it's subcategories for the page display */
        get: function () {
            var _this = this;
            return this.rootCategories.map(function (rootValue) {
                return {
                    value: _this.allSavedCategories[rootValue.value] ? rootValue.value : '',
                    children: _this._allSavedSubcategories(rootValue.value),
                };
            });
        },
        enumerable: false,
        configurable: true
    });
    /** Return all Subcategories for the drawer */
    CategoryStore.prototype.subCategories = function (rootCategoryValue) {
        var firstLevelSubcategory = this.allCategories[rootCategoryValue];
        return firstLevelSubcategory ? this._populateArray(firstLevelSubcategory, rootCategoryValue) : [];
    };
    /** Adds a category, either Root category or Subcategory to the list of saved categories */
    CategoryStore.prototype.saveCategory = function (category) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, addCategory({}, category).catch(function (response) {
                            trackError({
                                page: 'Error occured during saving of the category on my account preference page',
                                errorMessage: response.GlobalMessage,
                            });
                            showErrorNotification(response.GlobalMessage);
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /** Removes a Subcategory - either by removing chip or from the drawer */
    CategoryStore.prototype.removeSubCategory = function (category) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, removeCategory(category).catch(function (response) {
                            trackError({
                                page: 'Error occured during removal of the category on my account preference page',
                                errorMessage: response.GlobalMessage,
                            });
                            showErrorNotification(response.GlobalMessage);
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /** Removes all Subcategories */
    CategoryStore.prototype.removeAllSubCategory = function (category) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, removeAllSubcategories(category).catch(function (response) {
                            trackError({
                                page: 'Error occured during removal of all subcategories on my account preference page',
                                errorMessage: response.GlobalMessage,
                            });
                            showErrorNotification(response.GlobalMessage);
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /** Removes Root Category from the list of saved categorys */
    CategoryStore.prototype.removeRootCategory = function (category) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, removeRootCategory(category).catch(function (response) {
                            trackError({
                                page: 'Error occured during removal of the root category on my account preference page',
                                errorMessage: response.GlobalMessage,
                            });
                            showErrorNotification(response.GlobalMessage);
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /** Return all saved subcategories */
    CategoryStore.prototype._allSavedSubcategories = function (rootCategory) {
        var _this = this;
        var savedSubcategoriesIDs = this.allSavedCategories[rootCategory];
        var savedSubcategories = [];
        if (savedSubcategoriesIDs) {
            savedSubcategoriesIDs.forEach(function (id) {
                savedSubcategories.push({
                    label: _this.allCategoryLabels[id],
                    value: id,
                });
            });
        }
        return savedSubcategories;
    };
    /** Create a list of Subcategories that maches the hierarchy from getAllCategories */
    CategoryStore.prototype._populateArray = function (refferenceList, rootCategory) {
        var _this = this;
        var subCategoryList = [];
        var allLabels = this.allCategoryLabels;
        var allLabelKeys = Object.keys(allLabels);
        allLabelKeys.map(function (key) {
            if (refferenceList[key]) {
                subCategoryList.push({
                    label: allLabels[key],
                    value: key,
                    selected: _this._checkedState(rootCategory, key, refferenceList[key]),
                    children: _this._populateArray(refferenceList[key], rootCategory),
                });
            }
        });
        return subCategoryList;
    };
    /**
     * Example of the structure and checked states
     * [-]
     *   [-]
     *     [ ]
     *     [X]
     *
     * Set a state of the category based on the users choice
     * and based on the position of the category in the structure
     * */
    CategoryStore.prototype._checkedState = function (rootCategory, currentCategoryValue, currentList) {
        var isSelected = this._allSavedSubcategories(rootCategory).filter(function (subcategory) { return subcategory.value === currentCategoryValue; }).length > 0;
        var hasChildren = Object.entries(currentList).length > 0;
        if (hasChildren && isSelected) {
            return CheckedType.intermediate;
        }
        if (!hasChildren && isSelected) {
            return CheckedType.checked;
        }
        return CheckedType.unchecked;
    };
    return CategoryStore;
}());
export { CategoryStore };
decorate(CategoryStore, {
    allSavedCategories: computed,
    allCategories: computed,
    allCategoryLabels: computed,
    allChips: computed,
    rootCategories: computed,
    subCategories: action,
    saveCategory: action,
    removeSubCategory: action,
    removeAllSubCategory: action,
    removeRootCategory: action,
});
