var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useLoadNamedOnceOnClientSide } from '@jelmoli/hooks/src/useLoadOnceOnClientSide/useLoadOnceOnClientSide';
import React from 'react';
import { useIsSitecoreEditMode } from '../EditMode/editMode';
/**
 * Lazy loader for inline field editing
 */
export var FieldEditFrame = function (props) {
    var editMode = useIsSitecoreEditMode();
    var FieldEditFrameLazy = useLoadNamedOnceOnClientSide('FieldEditFrameLazy', function () { return import(/* webpackChunkName: "FieldEditFrame" */ './FieldEditFrameLazy'); }, editMode);
    if (!FieldEditFrameLazy || editMode) {
        return React.createElement(React.Fragment, null, props.children);
    }
    return React.createElement(FieldEditFrameLazy, __assign({}, props));
};
