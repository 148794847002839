import { Column, Row } from '@jelmoli/bootstrap-grid/index';
import { bindTranslations } from '@jelmoli/i18n/index';
import { isUserLoggedInLazy } from '@jelmoli/sitecore-services/src/Account/isUserLoggedIn/isUserLoggedInLazy';
import { getFooterSettings } from '@jelmoli/sitecore-services/src/Navigation/getFooterSettings/getFooterSettings';
import { Image } from '@jelmoli/ui-components/src/atoms/Image/Image';
import { PrimaryButton } from '@jelmoli/ui-components/src/atoms/PrimaryButton/PrimaryButton';
import { Container } from '@jelmoli/ui-components/src/helpers/Container/Container';
import { ScreenSizeDetector } from '@jelmoli/ui-components/src/helpers/ScreenSizeDetector/ScreenSizeDetector';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { NewsletterSignupDrawer } from '@jelmoli/ui-components/src/organism/NewsletterSignupDrawer/NewsletterSignupDrawer';
import React, { useState } from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
import './newsletter-teaser-component.scss';
export var NewsletterTeaserComponent = function (_a) {
    var fields = _a.fields;
    var isLoggedIn = isUserLoggedInLazy();
    var getFooterSettingsData = useGetEndPointLazy(getFooterSettings).value;
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var _c = useState(false), isSmallScreen = _c[0], setIsSmallScreen = _c[1];
    var t = bindTranslations(fields);
    if (!getFooterSettingsData || (isLoggedIn && fields.Newsletter)) {
        return null;
    }
    var linkToPage = fields.Newsletter
        ? getFooterSettingsData.MyAccountPreferenceLink
        : getFooterSettingsData.MyAccountProfileLink;
    var StageImage = function () { return (fields.Image ? React.createElement(Image, { value: fields.Image.value }) : null); };
    return (React.createElement(React.Fragment, null,
        React.createElement(ScreenSizeDetector, { viewports: ['xs', 'sm'], onViewportChange: function (isSmallerScreen) {
                setIsSmallScreen(isSmallerScreen);
            } }),
        React.createElement(NewsletterSignupDrawer, { isOpen: isOpen, openDrawer: function () { return setIsOpen(false); } }),
        React.createElement(Container, { size: "l" },
            React.createElement(Row, null,
                React.createElement(Column, { size: 12 },
                    React.createElement(StageImage, null)),
                React.createElement(ContentBox, { buttonLink: linkToPage.value.href || '', buttonText: t('CtaButton'), subtitle: t('Subtitle'), copy: t('Copy'), isLoggedIn: isLoggedIn, isSmallScreen: isSmallScreen, setIsOpen: setIsOpen })))));
};
var ContentBox = function (props) {
    return (React.createElement(Column, { size: { xs: 10, sm: 10, md: 7 }, offset: { xs: 2, sm: 2, md: 5 } },
        React.createElement("div", { className: "feat-newsletter-teaser-component__text-box-content" },
            React.createElement(VerticalSpacer, { size: "m" }),
            React.createElement(Typography, { uppercase: true, size: "xxs", type: "regular" }, props.subtitle),
            React.createElement(VerticalSpacer, { size: "xs" }),
            React.createElement(Typography, { size: "m", type: "light" }, props.copy),
            React.createElement(VerticalSpacer, { size: "m" }),
            props.isLoggedIn ? (React.createElement(PrimaryButton, { fullWidth: props.isSmallScreen, size: "tiny", fontResponsive: false, type: "link", text: props.buttonText, href: props.buttonLink })) : (React.createElement(PrimaryButton, { fullWidth: props.isSmallScreen, size: "tiny", fontResponsive: false, type: "button", text: props.buttonText, onClick: function () {
                    props.setIsOpen(true);
                } })),
            React.createElement(VerticalSpacer, { size: "xxxl" }))));
};
