import { Search } from '@jelmoli/ui-components/src/atoms/Icon/Search';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { Visibility } from '@jelmoli/ui-components/src/helpers/Visibility/Visibility';
import { SearchOverlayButton } from '@jelmoli/ui-components/src/organism/SearchOverlay/SearchOverlay';
import classnames from 'classnames';
import React from 'react';
import './header-search.scss';
export var HeaderSearchComponent = function (props) { return (React.createElement("div", { className: classnames('feat-header__search', {
        'feat-header__search--active': props.searchLink && props.searchLink.isActive,
    }) },
    React.createElement(Visibility, { hide: ['lg', 'xl'] },
        React.createElement(SearchOverlayButton, { className: "feat-header__search-icon-button" },
            React.createElement(Search, { height: 20, width: 20, style: { display: 'flex' } }))),
    React.createElement(Visibility, { hide: ['xs', 'sm', 'md'] },
        React.createElement(SearchOverlayButton, { className: "feat-header__search-icon-button", href: props.searchLink && props.searchLink.href },
            React.createElement("div", { className: "feat-header__search-effect" },
                React.createElement("div", { className: "feat-header__search-text" },
                    React.createElement(Typography, { uppercase: true, size: "s", responsive: false, type: "medium" }, props.searchLink && props.searchLink.text))))))); };
export var HeaderCollapsedSearchComponent = function () {
    return (React.createElement("div", { className: "feat-header__search" },
        React.createElement(SearchOverlayButton, { className: "feat-header__search-icon-button" },
            React.createElement("span", { className: "feat-header__search-collapsed" }))));
};
