import { useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
export var useCreatePortalInBody = function (className) {
    return useCreatePortal(typeof document === 'undefined' ? null : document.body, className);
};
export var useCreatePortal = function (target, className) {
    var portalWrapper = useMemo(function () {
        if (!target || typeof document === 'undefined') {
            return;
        }
        var div = document.createElement('div');
        div.setAttribute('data-portal', '');
        if (className) {
            div.setAttribute('class', className);
        }
        target.appendChild(div);
        return div;
    }, [target]);
    useEffect(function () {
        if (!portalWrapper || typeof document === 'undefined') {
            return;
        }
        return function () {
            var parent = portalWrapper.parentElement;
            if (parent) {
                parent.removeChild(portalWrapper);
            }
        };
    }, [portalWrapper]);
    useEffect(function () {
        if (!portalWrapper) {
            return;
        }
        if (className) {
            portalWrapper.setAttribute('class', className);
        }
        else {
            portalWrapper.removeAttribute('class');
        }
    }, [className]);
    return function (children) { return (portalWrapper ? createPortal(children, portalWrapper) : null); };
};
