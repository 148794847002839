import { useCreatePortal } from '@jelmoli/hooks/src/useCreatePortalInBody/useCreatePortalInBody';
import Slide from '@material-ui/core/Slide';
import React, { useContext, useMemo, useState } from 'react';
import { A } from '../../helpers/A/A';
import { BaseDrawer } from '../../helpers/BaseDrawer/BaseDrawer';
import { Typography } from '../../helpers/Typography/Typography';
import './drawer.scss';
var DrawerContext = React.createContext({
    closeDrawer: function () {
        // Context Default Function
    },
    refs: {
        header: null,
        content: null,
        footer: null,
    },
});
export var Drawer = function (props) {
    var _a = useState(null), wrapper = _a[0], setWrapper = _a[1];
    // The following useMemo approach is neccessary over a useRef approach
    // as the children would not render into the portals correctly otherwise
    var refs = useMemo(function () { return ({
        header: wrapper ? wrapper.querySelector('.a-drawer__header-slot') : null,
        content: wrapper ? wrapper.querySelector('.a-drawer__content-slot') : null,
        footer: wrapper ? wrapper.querySelector('.a-drawer__footer-slot') : null,
    }); }, [wrapper]);
    return (React.createElement(DrawerContext.Provider, { value: {
            closeDrawer: function () { return props.openDrawer(false); },
            refs: refs,
        } },
        React.createElement(BaseDrawer, { open: props.isOpen, anchor: props.drawerPosition ? props.drawerPosition : 'right', onClose: function () { return props.openDrawer(false); }, elevation: props.elevation, ref: function (elem) { return setWrapper(elem); } },
            React.createElement("div", { className: "a-drawer__header-slot" }),
            React.createElement("div", { className: "a-drawer__content-slot" }),
            React.createElement("div", { className: "a-drawer__footer-slot" }),
            props.children)));
};
export var DrawerHeader = function (props) {
    var _a = useContext(DrawerContext), closeDrawer = _a.closeDrawer, refs = _a.refs;
    var portalRender = useCreatePortal(refs.header, 'a-drawer__header');
    return portalRender(React.createElement(React.Fragment, null,
        typeof props.children === 'string' ? (React.createElement("div", null,
            React.createElement(Typography, { type: "medium", size: "m" }, props.children))) : (props.children),
        React.createElement(A, { className: "a-drawer__close-btn", "aria-label": "close drawer", onClick: closeDrawer })));
};
export var DrawerContent = function (_a) {
    var slideDirection = _a.slideDirection, visible = _a.visible, children = _a.children;
    var refs = useContext(DrawerContext).refs;
    // Visible is true by default:
    var showContent = visible !== false;
    var portalRender = useCreatePortal(refs.content, "a-drawer__content" + (showContent ? ' a-drawer__content--visible' : ''));
    return portalRender(slideDirection ? (React.createElement(Slide, { direction: slideDirection, in: showContent, mountOnEnter: true, unmountOnExit: true, appear: false },
        React.createElement("div", { className: "a-drawer__content-animation-wrapper" }, children))) : visible !== false ? (children) : null);
};
export var DrawerFooter = function (_a) {
    var slideAnimation = _a.slideAnimation, visible = _a.visible, children = _a.children;
    var _b = useContext(DrawerContext), closeDrawer = _b.closeDrawer, refs = _b.refs;
    var portalRender = useCreatePortal(refs.footer, 'a-drawer__footer-portal');
    var renderedChildren = React.createElement("div", { className: "a-drawer__footer" }, children(closeDrawer));
    return portalRender(slideAnimation ? (React.createElement(Slide, { direction: 'up', in: visible !== false, mountOnEnter: true, unmountOnExit: true, appear: false }, renderedChildren)) : visible !== false ? (renderedChildren) : null);
};
