var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import classnames from 'classnames';
import React from 'react';
import { HorizontalSpacer } from '../../helpers/HorizontalSpacer/HorizontalSpacer';
import { Typography } from '../../helpers/Typography/Typography';
import './button-light.scss';
export var LightButton = function (props) {
    var Tag = props.type === 'link' || props.type === 'downloadLink' ? 'a' : 'button';
    return (React.createElement(Tag, __assign({}, (props.type !== 'link' && props.type !== 'downloadLink'
        ? { type: props.type, disabled: props.disabled, value: props.text }
        : { href: props.href }), { className: classnames('a-button-light', props.disabled && 'a-button-light--disabled', props.fullWidth && 'a-button-light--fullwidth', props.icon && 'a-button-light--icon', props.href && 'a-button-light--link', props.classNames) }, (props.rel && { rel: props.rel }), { onClick: props.onClick }, (props.type === 'downloadLink' && { download: true, target: '_blank' })),
        props.icon && React.createElement("span", { className: "a-button-light__icon" }, props.icon),
        props.icon && props.text.length > 0 && React.createElement(HorizontalSpacer, { size: "xxs" }),
        React.createElement(Typography, { size: props.fontSize || 's', type: props.fontType || 'regular', uppercase: props.uppercase, responsive: Boolean(props.fontResponsive) }, props.text)));
};
