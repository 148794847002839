var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jelmoliTheme, MuiThemeProvider } from '@jelmoli/material-ui';
import { loadAllSitecoreFeatureComponents } from '@jelmoli/sitecore-features';
import { useIsSitecoreEditMode } from '@jelmoli/ui-components/src/helpers/EditMode/editMode';
import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
var clientRouterApi;
/**
 * Use ajax based client side routing to speed up page navigations
 */
export var initializeClientSideRouting = function (options) {
    // Don't do anything during ssr:
    if (typeof document === 'undefined') {
        return;
    }
    clientRouterApi = clientSideRouting(options);
    // Initial page load
    options.onPageLoad();
};
export var navigateToUrlWithAjax = function (url, addHistoryEntry) {
    if (clientRouterApi) {
        clientRouterApi.navigateToUrlWithAjax(url, addHistoryEntry);
    }
    document.location.href = url;
};
/**
 * Replace the default routing with client site routing
 */
function clientSideRouting(options) {
    var currentPage = stripQueryStringFromUrl(document.location.href);
    var cache = {};
    function fetchPage(url) {
        if (cache[url]) {
            return cache[url];
        }
        // Cache the promise for 15 seconds
        setTimeout(function () {
            delete cache[url];
        }, 15000);
        cache[url] = fetch(url).then(function (request) { return request.text(); });
        return cache[url];
    }
    /**
     * Return the current page without querystring or hash
     */
    function stripQueryStringFromUrl(url) {
        return url.replace(/(\?|\#).+$/, '');
    }
    /**
     * Navigates to the new url using Ajax
     * returns true on success
     */
    function navigateToUrlWithAjax(url, addHistoryEntry) {
        var fullUrl = getFullLocalUrl(url);
        currentPage = stripQueryStringFromUrl(url);
        if (!fullUrl) {
            return false;
        }
        // Block further clicks using a modal overlay
        var modal = document.createElement('div');
        Object.assign(modal.style, {
            zIndex: '2147483647',
            position: 'fixed',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
        });
        document.body.appendChild(modal);
        // Use a direct navigation after 3 seconds timeout
        var ajaxLoadTimeout = setTimeout(function () {
            document.location.href = fullUrl;
        }, 3000);
        // Take over routing
        fetchPage(fullUrl)
            .then(function (html) {
            // Stop the fallback timeout:
            clearTimeout(ajaxLoadTimeout);
            // Scroll to top:
            window.scrollTo(0, 0);
            var newPageDom = new DOMParser().parseFromString(html, 'text/html');
            replacePageWithNewBody(newPageDom);
            if (addHistoryEntry) {
                history.pushState({ ajaxRoute: fullUrl }, newPageDom.title, fullUrl);
            }
        })
            .catch(function (e) {
            console.error(e);
            document.location.href = fullUrl;
        })
            .then(function () { return options.onPageLoad(); });
        return true;
    }
    function replacePageWithNewBody(newPageDom) {
        var newPageHead = newPageDom.querySelector('head');
        var newPageBody = newPageDom.querySelector('body');
        if (!newPageBody || !newPageHead) {
            throw new Error('document has no body or head');
        }
        // Retrieve all information to render the react apps
        // of the new page
        var rootScriptNodes = Array.from(newPageBody.querySelectorAll('script[data-react-component]'));
        var newReactApps = rootScriptNodes.map(function (scriptNode, index) {
            var hydrationId = 'quicklink-hydrate-' + index;
            var name = scriptNode.getAttribute('data-react-component');
            var rootNode = scriptNode.nextElementSibling;
            if (!rootNode) {
                return;
            }
            var data = JSON.parse(scriptNode.innerHTML);
            var styleNode = scriptNode.previousSibling;
            rootNode.setAttribute('id', hydrationId);
            // Remove ssr html
            rootNode.innerHTML = '';
            // Remove ssr data
            scriptNode.parentElement.removeChild(scriptNode);
            // Remove ssr styles
            if (styleNode && styleNode.tagName === 'STYLE') {
                styleNode.parentElement.removeChild(styleNode);
            }
            return {
                id: hydrationId,
                data: data,
                name: name,
            };
        });
        // remove all executable scripts and prerendered styles
        // as they will be replaced by the client side routing
        //
        // it is important to keep <script type="text/json"> or similar
        // scripts which hold data & templates
        Array.from(newPageBody.querySelectorAll([
            // Default script tags e.g. <script>
            'script:not([type])',
            'script[type="text/javascript"]',
            'script[type="application/javascript"]',
            // Module script tags e.g. <script type="module">
            'script[type="module"]',
            'script[type="nomodule"]',
            // CSS
            'link',
            'style',
        ].join(','))).forEach(function (element) {
            element.parentElement.removeChild(element);
        });
        // Destroy all currently running react apps:
        document.querySelectorAll('div[data-react-component]').forEach(function (element) {
            unmountComponentAtNode(element);
        });
        switchElements(document.head, newPageHead, 'meta');
        switchElements(document.head, newPageHead, 'title');
        switchElements(document.head, newPageHead, 'script[type="application/ld+json"]');
        switchElements(document.head, newPageHead, 'link[rel="canonical"]');
        document.head.setAttribute('prefetch', 'enabled');
        document.body.innerHTML = newPageBody.innerHTML;
        document.body.className = newPageBody.className;
        // Render all new apps on the page
        var components = loadAllSitecoreFeatureComponents();
        newReactApps.forEach(function (app) {
            if (!app) {
                return;
            }
            var rootNode = document.getElementById(app.id);
            var Component = components[app.name];
            if (!rootNode || !Component) {
                return;
            }
            render(React.createElement(MuiThemeProvider, { theme: jelmoliTheme },
                React.createElement(Component, __assign({}, app.data))), rootNode);
        });
    }
    // This function switches content of the passsed tag from prefetched tag
    function switchElements(rootElement, contentRootElement, tagName) {
        var elementsForRemoval = rootElement.querySelectorAll(tagName);
        if (elementsForRemoval.length > 0) {
            for (var i = elementsForRemoval.length - 1; i >= 0; i--) {
                rootElement.removeChild(elementsForRemoval[i]);
            }
        }
        var felementsForSwitch = contentRootElement.querySelectorAll(tagName);
        for (var i = felementsForSwitch.length - 1; i >= 0; i--) {
            rootElement.appendChild(felementsForSwitch[i]);
        }
    }
    function getFullLocalUrl(url) {
        var baseUrl = document.location.protocol + '//' + document.location.host;
        var absoluteUrl = url.indexOf('/') === 0 ? baseUrl + url : url;
        // Return the url if its running on the same domain:
        if (absoluteUrl.indexOf(baseUrl) === 0) {
            return absoluteUrl;
        }
    }
    // Preload page
    var preloadPage = function (_a) {
        var target = _a.target;
        var aTag = target instanceof HTMLElement && target.closest('a');
        if (aTag instanceof HTMLAnchorElement && aTag.href) {
            var fullUrl = getFullLocalUrl(aTag.href);
            var relValue = aTag.getAttribute('rel');
            if (fullUrl && relValue && relValue.indexOf('nofollow') === -1 && !useIsSitecoreEditMode()) {
                fetchPage(fullUrl);
            }
        }
    };
    // Prefetch on hover/touchstart/mousedown
    document.documentElement.addEventListener('mousedown', preloadPage);
    document.documentElement.addEventListener('mouseover', preloadPage);
    document.documentElement.addEventListener('touchstart', preloadPage);
    window.onpopstate = function (event) {
        if (currentPage !== stripQueryStringFromUrl(document.location.href)) {
            navigateToUrlWithAjax(document.location.href, false);
        }
    };
    // Start the ajax loading on click:
    addEventListenerWithLowPriority('click', function (e) {
        if (e.defaultPrevented || e.ctrlKey || e.altKey || e.metaKey) {
            return;
        }
        var aTag = e.target instanceof Element && e.target.closest('a');
        if (aTag instanceof HTMLAnchorElement && aTag.href) {
            var fullUrl = getFullLocalUrl(aTag.href);
            var relValue = aTag.getAttribute('rel');
            // Don't prefetch links when links are external or user is in experience edit mode
            if (useIsSitecoreEditMode()) {
                e.preventDefault();
                return;
            }
            if (relValue && relValue.indexOf('nofollow') !== -1) {
                e.preventDefault();
                return;
            }
            if (fullUrl && navigateToUrlWithAjax(fullUrl, true)) {
                e.preventDefault();
            }
        }
    });
    return {
        navigateToUrlWithAjax: navigateToUrlWithAjax,
    };
}
/**
 * Attach an event to the <body> tag -
 * once fired it attaches the real event event to the <html> tag
 *
 * this way it will be one of the very last registered events and will be
 * called with a very low priority allowing other handlers to execute
 * e.preventDefault() or similar functions before
 *
 * Returns a function to unbind the event
 */
var addEventListenerWithLowPriority = function (type, listener) {
    var eventAttacher = function () {
        // Bind once to the html element
        // it will pick handle the exact same event because of bubbling
        var handleEvent = function (e) {
            window.removeEventListener(type, handleEvent);
            listener.call(this, e);
        };
        window.addEventListener(type, handleEvent);
    };
    // Attach the event to the <body> as it is the direct child of <html>
    document.body.addEventListener(type, eventAttacher);
    return function () { return document.body.removeEventListener(type, eventAttacher); };
};
