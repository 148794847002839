import { bindTranslations, I18N } from '@jelmoli/i18n';
import { getProfile } from '@jelmoli/sitecore-services/src/Account/getProfile/getProfile';
import { TextSkewBackgroundHighlight } from '@jelmoli/ui-components/src/atoms/TextSkewBackgroundHighlight/TextSkewBackgroundHighlight';
import { hasTextFieldValue } from '@jelmoli/ui-components/src/helpers/SitecoreTypes/SitecoreTypes';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import React from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
export var MyAccountTitle = function (_a) {
    var fields = _a.fields, getProfileData = _a.getProfileData;
    var t = bindTranslations(fields);
    if (!getProfileData) {
        return (React.createElement(React.Fragment, null,
            React.createElement(Typography, { type: "medium", size: "xl", tag: "h1" },
                React.createElement(I18N, { text: t('MyAccountTitle'), replacements: {} })),
            hasTextFieldValue(fields.MyAccountDescription) && (React.createElement(React.Fragment, null,
                React.createElement(VerticalSpacer, { size: "m" }),
                React.createElement(Typography, { size: "l", field: fields.MyAccountDescription }))),
            React.createElement(VerticalSpacer, { size: "xl" })));
    }
    var userProfile = useGetEndPointLazy(getProfile).value;
    if (!userProfile || !hasTextFieldValue(fields.MyAccountTitle)) {
        return null;
    }
    var firstName = userProfile.firstName, lastName = userProfile.lastName, loyalty = userProfile.loyalty;
    return (React.createElement(React.Fragment, null,
        React.createElement(Typography, { type: "medium", size: "xl", tag: "h1" },
            React.createElement(I18N, { text: t('MyAccountTitle'), replacements: {
                    firstname: firstName,
                    lastname: lastName,
                    loyaltyStatus: React.createElement(TextSkewBackgroundHighlight, null, loyalty.statusTitle),
                } })),
        hasTextFieldValue(fields.MyAccountDescription) && (React.createElement(React.Fragment, null,
            React.createElement(VerticalSpacer, { size: "m" }),
            React.createElement(Typography, { size: "l", field: fields.MyAccountDescription }))),
        React.createElement(VerticalSpacer, { size: "xl" })));
};
