import { trackLogout } from '@jelmoli/sitecore-services/src/Tracking/Account/AccountTracking';
import { SignoutIcon } from '@jelmoli/ui-components/src/atoms/Icon/SignoutIcon';
import { Separator } from '@jelmoli/ui-components/src/atoms/Separator/Separator';
import { TextLink } from '@jelmoli/ui-components/src/atoms/TextLink/TextLink';
import { HorizontalSpacer } from '@jelmoli/ui-components/src/helpers/HorizontalSpacer/HorizontalSpacer';
import { KeepInView } from '@jelmoli/ui-components/src/helpers/KeepInView/KeepInView';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import React from 'react';
import './my-account-linkbox-component.scss';
export var MyAccountLinkboxComponent = function (_a) {
    var fields = _a.fields;
    return (React.createElement(KeepInView, null,
        React.createElement("div", { className: "feat-my-account-linkbox-component" },
            React.createElement(VerticalSpacer, { size: "mx", responsive: false }),
            fields.links.map(function (link, index) { return (React.createElement(React.Fragment, { key: index + " " + link.url },
                React.createElement(Typography, { size: "s", type: link.selected ? 'medium' : 'regular', responsive: false },
                    React.createElement(TextLink, { underlined: false, href: link.url, 
                        // Prevent prefatching in of the page content in this case
                        onClick: function (event) {
                            event.preventDefault();
                            window.location.href = "" + window.location.origin.replace(/(\?|#).+$/, '') + link.url;
                        } }, link.text)),
                React.createElement(VerticalSpacer, { size: "s", responsive: false }))); }),
            React.createElement(Separator, null),
            React.createElement(VerticalSpacer, { size: "s", responsive: false }),
            React.createElement(Typography, { size: "s", type: "medium", responsive: false },
                React.createElement(TextLink, { underlined: false, onClick: function () {
                        trackLogout();
                        window.location.href = window.location.origin.replace(/(\?|#).+$/, '') + "/api/cxa/account/logoff";
                    } },
                    React.createElement("span", { className: "feat-my-account-linkbox-component__logout-icon-wrapper" },
                        React.createElement(SignoutIcon, { width: 14 })),
                    React.createElement(HorizontalSpacer, { size: "xxs", responsive: false }),
                    React.createElement("span", null, fields.logoutLinkText))),
            React.createElement(VerticalSpacer, { size: "mx", responsive: false }))));
};
