import { track } from '@jelmoli/google-tag-manager';
export function trackError(props) {
    track({
        event: 'gaEvent',
        event_name: 'error_message',
        error_message: {
            message: 'error_message',
            event_category: 'error_message',
            event_action: props.page,
            event_label: props.errorMessage,
        },
    });
}
export function track404Page(props) {
    track({
        event: 'gaEvent',
        event_name: '404_page',
        error_message: {
            event_category: 'error_message',
            event_action: '404_page',
            event_label: props.url,
        },
    });
}
