var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import createWindow from 'isomorphic-window';
import React from 'react';
import { renderToString } from 'react-dom/server';
import { Divider } from '../../atoms/Divider/Divider';
import { OrderedList, OrderedListItem } from '../../atoms/OrderedList/OrderedList';
import { TextLink } from '../../atoms/TextLink/TextLink';
import { UnorderedList, UnorderedListItem } from '../../atoms/UnorderedList/UnorderedList';
import { ScrollAnchor } from '../ScrollAnchor/ScrollAnchor';
import { Typography } from '../Typography/Typography';
import { VerticalSpacer } from '../VerticalSpacer/VerticalSpacer';
export var RichTextRenderer = React.memo(function (props) {
    var html = props.html, replaceRules = props.replaceRules, divProps = __rest(props, ["html", "replaceRules"]);
    return React.createElement("div", __assign({}, divProps, { dangerouslySetInnerHTML: { __html: processRichTextHtml(html, replaceRules) } }));
});
export var processRichTextHtml = function (html, replaceRules) {
    var body = createBodyElement();
    body.innerHTML = html;
    convertTopTextNodes(body);
    // Manipulate dom
    var nodes = getAllNodes(body);
    var replacements = replaceRules || defaultReplacements;
    replaceNodesWithReactComponents(nodes, replacements);
    // Remove helper markers
    removeRenderingHelpers(body);
    return body.innerHTML;
};
function createBodyElement() {
    // Create a nodejs and browser compatible body element:
    var body = createWindow().document.createElement('body');
    return body;
}
var DangerousHtmlRenderMarker = function (_a) {
    var children = _a.children, trim = _a.trim;
    return (React.createElement("span", { "data-dangerous-inner-html-render-marker": true, dangerouslySetInnerHTML: { __html: !trim ? children : children.replace(/^[\s\n\r]+/, '') } }));
};
/**
 * This util will call the render callback only if the tag has innerHTML
 */
export var onlyTagsWithInnerHTML = function (callback) {
    return function (content, node, innerHtml) {
        return innerHtml.trim() === '' ? null : callback(content, node, innerHtml);
    };
};
/**
 * Extract anchors without href
 * E.g. <a id="anchor_1589218345982" name="Cookie">Cookie Part&nbsp;</a>
 */
export var onlyTagsWithoutAnchors = function (callback) {
    return function (content, node, innerHtml) {
        var attributes = getNodeAttributes(node);
        var name = attributes.name;
        var id = attributes.id;
        // If it is not an anchor let the custom handler do the replacement
        // e.g. a <a href="/link">Link</a>
        if (attributes.href || (!id && !name)) {
            return callback(content, node, innerHtml);
        }
        var html = innerHtml.trim() === '' ? null : React.createElement("span", { dangerouslySetInnerHTML: { __html: innerHtml } });
        return (React.createElement(React.Fragment, null,
            React.createElement(ScrollAnchor, __assign({ id: id || name }, attributes)),
            html));
    };
};
export var defaultReplacements = {
    H1: onlyTagsWithInnerHTML(function (content) { return (React.createElement(Typography, { wrap: "regular", size: "xl", type: "medium", tag: "h1" },
        content,
        React.createElement(VerticalSpacer, { size: "m" }))); }),
    H2: onlyTagsWithInnerHTML(function (content) { return (React.createElement(Typography, { wrap: "regular", size: "xl", type: "medium", tag: "h2" },
        content,
        React.createElement(VerticalSpacer, { size: "m" }))); }),
    H3: onlyTagsWithInnerHTML(function (content) { return (React.createElement(React.Fragment, null,
        React.createElement(Typography, { wrap: "regular", size: "m", type: "light", tag: "h3" }, content),
        React.createElement(VerticalSpacer, { size: "m" }))); }),
    H4: onlyTagsWithInnerHTML(function (content) { return (React.createElement(Typography, { wrap: "regular", size: "m", type: "medium", tag: "h4" },
        content,
        React.createElement(VerticalSpacer, { size: "s" }))); }),
    HR: function () { return (React.createElement(React.Fragment, null,
        React.createElement(Divider, { display: "inline-block" }),
        React.createElement(VerticalSpacer, { size: "l" }))); },
    A: onlyTagsWithoutAnchors(onlyTagsWithInnerHTML(function (content, node) { return React.createElement(TextLink, __assign({}, getNodeAttributes(node)), content); })),
    B: onlyTagsWithInnerHTML(function (content) { return (React.createElement(Typography, { wrap: "regular", size: "l", type: "medium", tag: "span" }, content)); }),
    STRONG: onlyTagsWithInnerHTML(function (content) { return (React.createElement(Typography, { wrap: "regular", size: "l", type: "medium", tag: "span" }, content)); }),
    P: onlyTagsWithInnerHTML(function (content) { return (React.createElement(Typography, { wrap: "regular", size: "l", tag: "p" },
        content,
        React.createElement(VerticalSpacer, { size: "l" }))); }),
    DIV: onlyTagsWithInnerHTML(function (content) { return (React.createElement(React.Fragment, null,
        React.createElement(Typography, { wrap: "regular", size: "l", tag: "p" },
            content,
            React.createElement(VerticalSpacer, { size: "l" })))); }),
    BR: function () { return React.createElement("br", null); },
    OL: function (content) { return (React.createElement(React.Fragment, null,
        React.createElement(OrderedList, null, content),
        React.createElement(VerticalSpacer, { size: "l" }))); },
    UL: function (content) { return (React.createElement(React.Fragment, null,
        React.createElement(UnorderedList, null, content),
        React.createElement(VerticalSpacer, { size: "l" }))); },
    LI: onlyTagsWithInnerHTML(function (content, node, html) {
        // Ordered list item
        return node.parentElement.nodeName === 'OL' ? (React.createElement(OrderedListItem, null,
            React.createElement(DangerousHtmlRenderMarker, { trim: true }, html),
            !isLastOfType(node) && React.createElement(VerticalSpacer, { size: "xxs" }))) : // Unordered list item
            node.parentElement.nodeName === 'UL' ? (React.createElement(UnorderedListItem, null,
                React.createElement(DangerousHtmlRenderMarker, { trim: true }, html),
                !isLastOfType(node) && React.createElement(VerticalSpacer, { size: "xxs" }))) : (
            // Unknown list item
            React.createElement("li", null, content));
    }),
};
var replaceNodesWithReactComponents = function (nodes, replacements) {
    nodes.forEach(function (node) {
        var nodeRule = replacements[node.nodeName];
        var element = getHtmlElementOfNode(node);
        if (nodeRule) {
            var html = getHtmlFromNode(node);
            var Result = nodeRule(React.createElement(DangerousHtmlRenderMarker, null, html), node, html);
            if (Result && element) {
                element.insertAdjacentHTML('afterend', renderToString(Result));
            }
            node.parentElement.removeChild(node);
        }
        else if (element) {
            // Remove unknown elements and keep only the text
            var span = element.ownerDocument.createElement('span');
            if (element.innerText) {
                span.innerText = element.innerText;
                element.insertAdjacentElement('afterend', span);
            }
            element.parentElement.removeChild(element);
        }
    });
};
var getHtmlFromNode = function (node) {
    if ('innerHTML' in node) {
        return node.innerHTML;
    }
    var span = node.ownerDocument.createElement('span');
    span.appendChild(node.cloneNode());
    return span.innerHTML;
};
/**
 * Returns all nodes sorted by their depth in the dom
 * Deepest children first
 */
function getAllNodes(rootNode) {
    function addAllNodes(element, index, target) {
        Object.defineProperty(element, 'parentCount', {
            value: index,
        });
        target.push(element);
        if (element.childNodes.length === 0) {
            return;
        }
        element.childNodes.forEach(function (child) {
            addAllNodes(child, index + 1, nodes);
        });
    }
    var nodes = [];
    if (!rootNode.children) {
        return nodes;
    }
    rootNode.childNodes.forEach(function (child) {
        addAllNodes(child, 0, nodes);
    });
    nodes.sort(function (a, b) { return (a.parentCount > b.parentCount ? -1 : a.parentCount < b.parentCount ? 1 : 0); });
    return nodes;
}
function isLastOfType(node) {
    var tagName = node.nodeValue;
    if (!tagName) {
        return true;
    }
    for (var nextNode = node.nextSibling; nextNode; nextNode = nextNode.nextSibling) {
        if (nextNode.nodeValue === tagName) {
            return false;
        }
    }
    return true;
}
export function getNodeAttributes(node) {
    var result = {};
    // Ignore nodes which are not an element
    if (node.nodeType !== 1) {
        return result;
    }
    var attributes = node.attributes;
    for (var i = attributes.length - 1; i >= 0; i--) {
        result[attributes[i].name] = attributes[i].value;
    }
    return result;
}
function removeRenderingHelpers(body) {
    for (var marker = body.querySelector('[data-dangerous-inner-html-render-marker]'); marker; marker = body.querySelector('[data-dangerous-inner-html-render-marker]')) {
        if (marker) {
            unwrapNode(marker);
        }
    }
    // Clean up react root nodes
    body.querySelectorAll('[data-reactroot]').forEach(function (elem) { return elem.removeAttribute('data-reactroot'); });
}
/**
 * Move all nodes children to the parent and remove the node
 */
function unwrapNode(marker) {
    var parentElement = marker.parentElement;
    var childNodes = marker.childNodes;
    for (var childNode = childNodes.item(0); childNode; childNode = childNodes.item(0)) {
        parentElement.insertBefore(childNode, marker);
    }
    parentElement.removeChild(marker);
}
/**
 * Top level text nodes have to converted to p tags to be styleable
 */
function convertTopTextNodes(body) {
    var textNodes = [];
    body.childNodes.forEach(function (childNode) {
        if (childNode.nodeType === 3 && String(childNode.nodeValue || '').trim() !== '') {
            textNodes.push(childNode);
        }
    });
    textNodes.forEach(function (textNode) {
        var p = body.ownerDocument.createElement('p');
        body.insertBefore(p, textNode);
        p.appendChild(textNode);
    });
}
function getHtmlElementOfNode(node) {
    return node.nodeType === 1 ? node : undefined;
}
