import { createPostEndpoint } from 'request-registry';
import { getCSRFToken } from '../../Security/getCSRFToken/getCSRFToken';
import { getReservationIds } from '../getReservationIds/getReservationIds';
export var submitReservation = createPostEndpoint({
    url: function () { return '/jelmoli-api/JelmoliReservation/SubmitReservation'; },
    headers: {
        'X-RequestVerificationToken': function () { return getCSRFToken(); },
        'X-Requested-With': 'XMLHttpRequest',
    },
    afterSuccess: function () { return getReservationIds.refresh(); },
});
