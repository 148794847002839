var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { bindTranslations, I18N } from '@jelmoli/i18n/index';
import { getProfile } from '@jelmoli/sitecore-services/src/Account/getProfile/getProfile';
import { isUserLoggedInLazy } from '@jelmoli/sitecore-services/src/Account/isUserLoggedIn/isUserLoggedInLazy';
import { retriggerChangeEmail } from '@jelmoli/sitecore-services/src/Account/retriggerChangeEmail/retriggerChangeEmail';
import { trackError } from '@jelmoli/sitecore-services/src/Tracking/Error/ErrorTesting';
import { IconBoxInfo } from '@jelmoli/ui-components/src/atoms/Icon/IconBoxInfo';
import { Modal } from '@jelmoli/ui-components/src/atoms/Modal/Modal';
import { showErrorNotification, showSuccessNotification, } from '@jelmoli/ui-components/src/atoms/Notification/Notification';
import { SecondaryButton } from '@jelmoli/ui-components/src/atoms/SecondaryButton/SecondaryButton';
import { TextLink } from '@jelmoli/ui-components/src/atoms/TextLink/TextLink';
import { HorizontalSpacer } from '@jelmoli/ui-components/src/helpers/HorizontalSpacer/HorizontalSpacer';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { Visibility } from '@jelmoli/ui-components/src/helpers/Visibility/Visibility';
import React, { useState } from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
import './my-account-profile-email.scss';
import { MyAccountProfileEmailModalContent } from './MyAccountProfileEmailModalContent';
export var MyAccountProfileEmail = function (props) {
    var _a = useState(false), isOverlayOpen = _a[0], setIsOverlayOpen = _a[1];
    var isLoggedIn = isUserLoggedInLazy();
    var getProfileData = useGetEndPointLazy(getProfile, {}, isLoggedIn).value;
    if (!getProfileData) {
        return null;
    }
    var t = bindTranslations(props);
    return (React.createElement(React.Fragment, null,
        !getProfileData.newEmail ? (React.createElement(React.Fragment, null,
            React.createElement(Typography, { size: "l", type: "medium" }, t('ChangeEmailTitle')),
            React.createElement(VerticalSpacer, { size: "xs" }),
            React.createElement(Typography, { size: "l", type: "regular" }, t('ChangeEmailDescription', { email: getProfileData.email })),
            React.createElement(VerticalSpacer, { size: "l" }))) : (React.createElement(React.Fragment, null,
            React.createElement(Typography, { size: "l", type: "medium" },
                t('ChangeEmailOverlayTitle'),
                React.createElement(VerticalSpacer, { size: "xs" }),
                React.createElement("div", { className: "o-myaccount-email__change-email-notification" },
                    React.createElement(VerticalSpacer, { size: "m", responsive: false }),
                    React.createElement("div", { className: "o-myaccount-email__change-email-notification-text-wrapper" },
                        React.createElement("div", null,
                            React.createElement(IconBoxInfo, { width: 42, keepOriginalColors: true })),
                        React.createElement(HorizontalSpacer, { size: "s", responsive: false }),
                        React.createElement("div", { className: "o-myaccount-email__change-email-notification-text" },
                            React.createElement(Typography, { size: "xs", responsive: false },
                                React.createElement(I18N, { text: t('ChangeEmailNotConfirmed'), replacements: {
                                        email: getProfileData.newEmail,
                                    } })),
                            React.createElement(Typography, { tag: "div", size: "xs", type: "regular", responsive: false },
                                React.createElement(TextLink, { underlined: true, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0: return [4 /*yield*/, retriggerChangeEmail({}, { newEmail: getProfileData.newEmail })
                                                        .then(function (response) {
                                                        showSuccessNotification(response.globalMessage);
                                                    })
                                                        .catch(function (response) {
                                                        trackError({
                                                            page: 'Error in retriggering email change on MyAccount Profile',
                                                            errorMessage: response.GlobalMessage,
                                                        });
                                                        showErrorNotification(response.GlobalMessage);
                                                    })];
                                                case 1:
                                                    _a.sent();
                                                    return [2 /*return*/];
                                            }
                                        });
                                    }); } }, t('ChangeEmailLinkLabel'))))),
                    React.createElement(VerticalSpacer, { size: "m", responsive: false }))),
            React.createElement(VerticalSpacer, { size: "mx", responsive: false }))),
        React.createElement(Visibility, { show: ['xs', 'sm'] },
            React.createElement(SecondaryButton, { fontResponsive: false, type: "button", size: "tiny", text: t('ChangeEmailButton'), fullWidth: true, onClick: function () { return setIsOverlayOpen(true); } })),
        React.createElement(Visibility, { hide: ['xs', 'sm'] },
            React.createElement(SecondaryButton, { fontResponsive: false, type: "button", size: "tiny", text: t('ChangeEmailButton'), onClick: function () { return setIsOverlayOpen(true); } })),
        React.createElement(Modal, { open: isOverlayOpen, title: t('ChangeEmailOverlayTitle'), onClose: function () { return setIsOverlayOpen(false); } },
            React.createElement(MyAccountProfileEmailModalContent, __assign({ onClose: function () { return setIsOverlayOpen(false); }, currentEmailValue: getProfileData.email }, props)))));
};
