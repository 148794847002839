var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jelmoliTheme, MuiThemeProvider } from '@jelmoli/material-ui';
import { loadAllSitecoreFeatureComponents } from '@jelmoli/sitecore-features';
import '@jelmoli/sitecore-layouts/src/helpers/Global/Global';
import { createGenerateClassName, StylesProvider } from '@material-ui/core/styles';
import * as React from 'react';
import { hydrate } from 'react-dom';
import { initialPageTracking } from '../../../packages/sitecore-services/src/Tracking/InitialPageTracking/InitialPageTracking';
import { closeDisconnectedOverlay, openDisconnectedOverlay, setOfflineNotificationsToSessionStorage, } from '../../../packages/ui-components/src/helpers/DisconnectedOverlay/DisconnectedOverlay';
import { initializeClientSideRouting } from './ajax-routing';
/**
 * Search and rehydrate all cms component on the current page
 */
function rehydrateAllComponents() {
    var rootScriptNodes = document.querySelectorAll('script[data-react-component]');
    var componentMap = loadAllSitecoreFeatureComponents();
    rootScriptNodes.forEach(function (scriptNode) {
        var rootNode = scriptNode.nextElementSibling;
        var sitecoreData = JSON.parse(scriptNode.innerHTML);
        var name = scriptNode.getAttribute('data-react-component');
        if (!rootNode || !name || !componentMap[name]) {
            // To continue with the rest of the components on the page
            // we won't throw an error here - but just ignore the current component
            // and log an error message
            // tslint:disable-next-line
            console.error('Could not boot component "' + name + '"');
            return;
        }
        // Styles are always in front of the js
        var styleNode = scriptNode.previousSibling;
        // Remove scripts
        scriptNode.parentElement.removeChild(scriptNode);
        // Render component
        var Component = componentMap[name];
        // Cloning the theme will destroy the cache
        // and force material ui to generate all classes again
        //
        // Without this hack Material UI will refuse to execute
        // generateClassName and therefore will not prefix the classes
        // with the seed.
        //
        // This will lead to a limitation inside the react hydration logic:
        // https://github.com/facebook/react/issues/13260#issuecomment-407872208
        //
        // ⛳THIS WILL DUPLICATE ALL CSS CLASSES ⛳
        // Right now this is a known issue because of the caching
        // logic inside https://github.com/mui-org/material-ui/blob/e25b7d54f0f0eda6abdd50acb489d6e861cb89ce/packages/material-ui-styles/src/makeStyles/makeStyles.js#L56
        //
        var forkedTheme = __assign({}, jelmoliTheme);
        // Material UI JSS Configuration
        // @see https://material-ui.com/styles/api/#creategenerateclassname-options-class-name-generator
        var generateClassName = createGenerateClassName({
            productionPrefix: name,
        });
        var Main = function () {
            React.useEffect(function () {
                // Remove styles
                if (styleNode && styleNode.tagName === 'STYLE') {
                    styleNode.parentElement.removeChild(styleNode);
                }
                setOfflineNotificationsToSessionStorage();
                window.addEventListener('online', function () {
                    closeDisconnectedOverlay();
                });
                window.addEventListener('offline', function () {
                    openDisconnectedOverlay();
                });
                return function () {
                    window.removeEventListener('online', function () {
                        closeDisconnectedOverlay();
                    });
                    window.removeEventListener('offline', function () {
                        openDisconnectedOverlay();
                    });
                };
            }, []);
            return (React.createElement(StylesProvider, { generateClassName: generateClassName },
                React.createElement(MuiThemeProvider, { theme: forkedTheme },
                    React.createElement(Component, __assign({}, sitecoreData)))));
        };
        hydrate(React.createElement(Main, null), rootNode);
    });
}
// Start the bootstrap
rehydrateAllComponents();
// Initialize client side routing for faster page switching
initializeClientSideRouting({
    onPageLoad: function () {
        initialPageTracking();
    },
});
