import { H3 } from '@jelmoli/ui-components/src/atoms/Headlines/Headlines';
import { SecondaryButton } from '@jelmoli/ui-components/src/atoms/SecondaryButton/SecondaryButton';
import { hasImageFieldValue } from '@jelmoli/ui-components/src/helpers/SitecoreTypes/SitecoreTypes';
import { Slide, Slider } from '@jelmoli/ui-components/src/helpers/Slider/Slider';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import React from 'react';
import { InspirationTileSmallComponent } from '../InspirationTileSmallComponent/InspirationTileSmallComponent';
import { InspirationTileTextOnlyComponent } from '../InspirationTileTextOnlyComponent/InspirationTileTextOnlyComponent';
import './inspiration-carousel.scss';
export var InspirationCarouselComponent = function (props) {
    return (React.createElement(React.Fragment, null,
        props.fields.InspirationCarouselTitle.value && (React.createElement("div", { className: "feat-inspiration-carousel__title-wrapper" },
            React.createElement(H3, { type: "light", size: "m", field: props.fields.InspirationCarouselTitle }))),
        React.createElement(Slider, { minSlideAmount: { xl: 3, lg: 3, md: 3, sm: 2, xs: 1 } }, function (getRef) { return (React.createElement(React.Fragment, null, props.fields.InspirationCarouselTeasers.map(function (item, index) { return (React.createElement(Slide, { ref: getRef(), sliderColumnCount: { xs: 12, sm: 6, md: 4 }, key: index }, hasImageFieldValue(item.fields.PageCarouselImage) ? (React.createElement(InspirationTileSmallComponent, { fields: {
                PageTeaserSubtitle: item.fields.PageTeaserSubtitle,
                PageTeaserTitle: item.fields.PageTeaserTitle,
                PageTeaserImage: item.fields.PageCarouselImage,
                PageTeaserLabel: item.fields.PageTeaserLabel
                    ? item.fields.PageTeaserLabel
                    : { fields: { InspirationLabelTitle: { value: '' } } },
                PageTeaserLink: item.url ? item.url : '',
            }, slider: true })) : (React.createElement(InspirationTileTextOnlyComponent, { fields: {
                PageTeaserSubtitle: item.fields.PageTeaserSubtitle,
                PageTeaserTitle: item.fields.PageTeaserTitle,
                PageTeaserLabel: item.fields.PageTeaserLabel
                    ? item.fields.PageTeaserLabel
                    : { fields: { InspirationLabelTitle: { value: '' } } },
                PageTeaserLink: item.url ? item.url : '',
            } })))); }))); }),
        props.fields.InspirationCarouselCtaLinkText.value && props.fields.InspirationCarouselCtaLink.value.href && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "feat-inspiration-carousel__button-wrapper" },
                React.createElement(SecondaryButton, { text: props.fields.InspirationCarouselCtaLinkText.value, type: "link", href: props.fields.InspirationCarouselCtaLink.value.href, uppercase: true, fontResponsive: false })),
            React.createElement(VerticalSpacer, { size: "xxxl" })))));
};
