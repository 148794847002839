var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { bindTranslations } from '@jelmoli/i18n';
import { Container } from '@jelmoli/ui-components/src/helpers/Container/Container';
import { hasTextFieldValue } from '@jelmoli/ui-components/src/helpers/SitecoreTypes/SitecoreTypes';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { PersonalShopperStage } from '@jelmoli/ui-components/src/organism/PersonalShopperStage/PersonalShopperStage';
import { ServiceTeaserItem, } from '@jelmoli/ui-components/src/organism/ServiceTeaser/ServiceTeaserItem/ServiceTeaserItem';
import React from 'react';
export var PersonalShoppingProfileComponent = function (props) {
    var t = bindTranslations(props.fields);
    return (React.createElement(Container, { size: "s", backgroundFill: true },
        React.createElement(PersonalShopperStage, __assign({}, props.fields)),
        React.createElement(VerticalSpacer, { size: "xxxxl" }),
        React.createElement("div", { style: { textAlign: 'center' } },
            React.createElement(Typography, { type: "light", size: "m", tag: "h2" }, hasTextFieldValue(props.fields.StaffMember.StaffMemberName) &&
                t('ServiceTeaserSectionTitle', {
                    staffMemberName: props.fields.StaffMember.StaffMemberName.value,
                })),
            React.createElement(Typography, { size: "l", tag: "p" }, t('ServiceTeaserSectionTitleDesctripion'))),
        React.createElement(VerticalSpacer, { size: "l" }),
        props.fields.StaffMember.StaffMemberService.map(function (item, index) { return (React.createElement(React.Fragment, { key: index },
            React.createElement(ServiceTeaserItem, { ServiceItem: item, ServiceTeaserBookingButton: props.fields.ServiceTeaserBookingButton, serviceStaffMemberId: props.fields.StaffMember.StaffMemberId }),
            React.createElement(VerticalSpacer, { size: 'xxl' }))); })));
};
