import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
export var portalFactory = function (slots) {
    var portals = {};
    var portalExits = {};
    slots.forEach(function (slot) {
        var div = typeof document !== 'undefined' ? document.createElement('div') : undefined;
        portals[slot] = createPortalEntry(div);
        portalExits[slot] = createPortalExit(slot, div);
    });
    return {
        portals: portals,
        portalExits: portalExits,
    };
};
var createPortalExit = function (slotName, div) {
    return function PortalExit() {
        var ref = useRef(null);
        useEffect(function () {
            var target = ref.current;
            if (target && div) {
                target.appendChild(div);
                return function () {
                    if (div && div.parentElement === target) {
                        target.removeChild(div);
                    }
                };
            }
        }, [ref.current]);
        return React.createElement("div", { "data-portal": slotName, ref: ref });
    };
};
var createPortalEntry = function (div) {
    return function PortalExit(_a) {
        var children = _a.children;
        return div ? createPortal(children, div) : null;
    };
};
