var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useDelayUntilMouseUp } from '@jelmoli/hooks/src/useDelayUntilMouseup/useDelayUntilMouseUp';
import { default as MUITextField } from '@material-ui/core/TextField';
import React from 'react';
import './text-field.scss';
export var TextField = function (props) {
    var onBlur = useDelayUntilMouseUp(props.onBlur || (function () { }));
    return (React.createElement(MUITextField, __assign({}, props, { classes: {
            root: 'a-text-field',
        }, onBlur: onBlur, InputProps: __assign({ classes: {
                focused: 'a-text-field--focused',
                notchedOutline: 'a-text-field__outline',
                error: 'a-text-field--error',
                input: 'a-text-field__input',
                multiline: 'a-text-field__multiline',
                disabled: 'a-text-field__disabled',
            } }, props.InputProps), InputLabelProps: {
            classes: {
                outlined: 'a-text-field__label',
                focused: 'a-text-field__label--focused',
                error: 'a-text-field__label--error',
                disabled: 'a-text-field__label--disabled',
                filled: 'a-text-field__label--filled',
            },
        }, id: props.name, variant: "outlined", type: props.type, "data-name": props.name })));
};
