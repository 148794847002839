var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { default as MUIFormControlLabel } from '@material-ui/core/FormControlLabel';
import { default as MUIRadio } from '@material-ui/core/Radio';
import classnames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { AspectRatio } from '../../helpers/AspectRatio/AspectRatio';
import { focusAnchorClassName, hoverAnchorClassName } from '../../helpers/HoverAnchor/HoverAnchor';
import { hasImageFieldValue } from '../../helpers/SitecoreTypes/SitecoreTypes';
import { Image } from '../Image/Image';
import './radio-button-image.scss';
export var RadioButtonImage = function (_a) {
    var children = _a.children, image = _a.image, circle = _a.circle, props = __rest(_a, ["children", "image", "circle"]);
    var inputElementRef = useRef(null);
    useEffect(function () {
        if (inputElementRef.current && props.autoFocus) {
            inputElementRef.current.setAttribute('tabIndex', '0');
            inputElementRef.current.focus();
        }
    }, [inputElementRef.current]);
    return (React.createElement(React.Fragment, null,
        React.createElement(MUIFormControlLabel, { label: children, control: React.createElement(MUIRadio, __assign({ classes: {
                    root: classnames('a-radio-button-image__btn', hoverAnchorClassName),
                    checked: 'a-radio-button-image__btn--checked',
                }, ref: inputElementRef, focusVisibleClassName: classnames('a-radio-button-image__btn--focused', focusAnchorClassName) }, props, { icon: React.createElement("div", { className: "a-radio-button-image__btn" },
                    React.createElement(RadioImage, { circle: circle, image: image }),
                    React.createElement("div", { className: "a-radio-button-image__input-wrapper" },
                        React.createElement("span", { className: "a-radio-button-image__input" }))), checkedIcon: React.createElement("div", { className: "a-radio-button-image__btn" },
                    React.createElement(RadioImage, { circle: circle, image: image }),
                    React.createElement("div", { className: "a-radio-button-image__input-wrapper a-radio-button-image__input-wrapper--checked" },
                        React.createElement("span", { className: "a-radio-button-image__input--checked" }))), disableRipple: true })), value: props.value, classes: {
                root: 'a-radio-button-image',
                label: 'a-radio-button-image__label',
            } })));
};
var RadioImage = function (_a) {
    var circle = _a.circle, image = _a.image;
    return (React.createElement("div", { className: classnames('a-radio-button-image__image-wrapper', circle && 'a-radio-button-image__image-circle') },
        React.createElement(AspectRatio, { ratio: "1-1" }, hasImageFieldValue(image) && React.createElement(Image, { value: image.value, hoverable: true }))));
};
