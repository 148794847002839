var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { track } from '@jelmoli/google-tag-manager';
import { getCart } from '../../Cart/getCart/getCart';
/** This function starts tracking of the data once product gets removed from the cart */
export function removeProductTracking(_a) {
    var product = _a.product, quantity = _a.quantity;
    return __awaiter(this, void 0, void 0, function () {
        var getCartItems, cartId, deliveryOption, paymentMethod, vouchers;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, getCart({})];
                case 1:
                    getCartItems = _b.sent();
                    cartId = getCartItems.uniqueId;
                    deliveryOption = getCartItems.deliveryOption;
                    paymentMethod = getCartItems.paymentMethod;
                    vouchers = getCartItems.vouchers;
                    track({
                        event: 'EECremoveFromCart',
                        ecommerce: {
                            currencyCode: ('lineTotal' in product && product.lineTotal.split(' ')[0]) || '',
                            remove: {
                                actionField: {
                                    list: ('labels' in product && product.labels.map(function (item) { return item.label; }).join('/')) || '',
                                },
                                products: [
                                    {
                                        id: product.productId,
                                        name: product.displayName,
                                        brand: product.brandName || '',
                                        price: ('lineTotal' in product && parseFloat(product.lineTotal.split(' ')[1].replace("'", ''))) ||
                                            undefined,
                                        quantity: quantity || product.quantity,
                                        category: product.category,
                                        variant: product.variantId,
                                        dimension33: deliveryOption ? getDeliveryMethod(deliveryOption) : '',
                                        dimension34: deliveryOption ? deliveryOption.deliveryOptionExternalId : '',
                                        dimension35: paymentMethod ? paymentMethod.paymentMethodDisplayName : '',
                                        dimension36: vouchers.map(function (item) { return item.voucherCode; })[0] || '',
                                        dimension37: vouchers.map(function (item) { return item.label; })[0] || '',
                                        dimension38: '',
                                        dimension40: cartId || '',
                                        dimension55: (!!product.originalLineTotal).toString(),
                                        dimension58: ('color' in product && product.color.value) || '',
                                        dimension59: ('size' in product && product.size.value) || '',
                                        dimension60: product.materials.length > 0 ? product.materials.map(function (item) { return item; }).join('/') : '',
                                        dimension61: ('labels' in product && product.labels.map(function (item) { return item.label; }).join('/')) || '',
                                    },
                                ],
                            },
                        },
                    });
                    return [2 /*return*/];
            }
        });
    });
}
function getDeliveryMethod(deliveryMethod) {
    return 'shippingMethodDisplayName' in deliveryMethod ? deliveryMethod.shippingMethodDisplayName : '';
}
