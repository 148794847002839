var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useLoadNamedOnceInViewport } from '@jelmoli/hooks/src/useLoadOnceInViewport/useLoadOnceInViewport';
import { getSearchLabels } from '@jelmoli/sitecore-services/src/Search/getSearchLabels/getSearchLabels';
import { trackNavigation } from '@jelmoli/sitecore-services/src/Tracking/Navigation/NavigationTracking';
import React, { useState } from 'react';
import { A } from '../../helpers/A/A';
import { ScreenSizeDetector } from '../../helpers/ScreenSizeDetector/ScreenSizeDetector';
export var SearchOverlayButton = function (props) {
    var _a = useState(false), isOpen = _a[0], setIsOpenState = _a[1];
    var _b = useState(false), isSmallScreen = _b[0], setIsSmallScreen = _b[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(ScreenSizeDetector, { viewports: ['xs', 'sm'], onViewportChange: function (isSmallerScreen) {
                setIsSmallScreen(isSmallerScreen);
            } }),
        React.createElement(A, { "aria-label": "Search button", href: props.href, className: props.className, onClick: function (event) {
                event.preventDefault();
                setIsOpenState(true);
                trackNavigation({
                    currentLevel: 'Main Navigation',
                    trackLabel: 'Search',
                });
            } }, props.children),
        React.createElement(SearchOverlay, { isOpen: isOpen, onClose: function () {
                setIsOpenState(false);
            }, smallScreen: isSmallScreen })));
};
export var SearchOverlay = function (props) {
    var _a = useLoadNamedOnceInViewport('SearchOverlayLazy', function () {
        // Preload search labels
        getSearchLabels({});
        // Download Component
        return import(/* webpackChunkName: "SearchOverlay", webpackPrefetch: true */ './SearchOverlayLazy');
    }, 
    // Placeholder:
    function () { return React.createElement("div", { ref: ref }); }), ref = _a[0], SearchOverlayLazy = _a[1];
    return React.createElement(SearchOverlayLazy, __assign({}, props));
};
