import classnames from 'classnames';
import React from 'react';
import { BaseImage } from '../../helpers/BaseImage/BaseImage';
import './avatar.scss';
export var Avatar = function (props) {
    var _a;
    return (React.createElement("div", { className: classnames('a-avatar__wrapper', props.display && "a-avatar__wrapper--" + props.display, "a-avatar--size-" + (props.size ? props.size : 'm'), (_a = {}, _a["a-avatar--" + props.verticalAlignment] = !!props.verticalAlignment, _a)) },
        React.createElement(BaseImage, { editable: props.editable, className: classnames('a-avatar', props.className), value: props.field.value }),
        props.hoverable && (React.createElement("div", { className: "a-avatar__children" }, props.hoverable && React.createElement(HoverEffect, { type: "solid", color: "jelmoli-green" })))));
};
export var HoverEffect = function (_a) {
    var type = _a.type, color = _a.color;
    return React.createElement("div", { className: "a-avatar__hover a-avatar__hover--" + type + " a-avatar__hover--" + color });
};
