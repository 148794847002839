var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import './todo.scss';
/**
 * The todo component is a placeholder for backend developers
 * to have a basic mock to start with
 */
export var Todo = function (_a) {
    var children = _a.children, fields = _a.fields;
    return (React.createElement("div", null,
        React.createElement("output", { className: "h-todo", title: "Mock placeholder" },
            children,
            fields && React.createElement(ListFields, { fields: fields }),
            fields && (React.createElement("details", null,
                ' ',
                React.createElement("summary", null, "JSON"),
                " ",
                React.createElement("pre", null, JSON.stringify(fields, null, 2)))))));
};
var ListFields = function (_a) {
    var fields = _a.fields;
    return (React.createElement("ul", null, Object.keys(fields).map(function (field) { return (React.createElement("li", { key: field }, field)); })));
};
export var createTodoMock = function (OriginalComponent, defaults) {
    function mergeDeep() {
        var objects = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            objects[_i] = arguments[_i];
        }
        var isObject = function (obj) { return obj && typeof obj === 'object'; };
        return objects.reduce(function (prev, obj) {
            Object.keys(obj).forEach(function (key) {
                var pVal = prev[key];
                var oVal = obj[key];
                if (Array.isArray(pVal) && Array.isArray(oVal)) {
                    prev[key] = oVal;
                }
                else if (isObject(pVal) && isObject(oVal)) {
                    prev[key] = mergeDeep(pVal, oVal);
                }
                else {
                    prev[key] = oVal;
                }
            });
            return prev;
        }, {});
    }
    return function (props) {
        var mergedProps = mergeDeep(defaults, props);
        if (JSON.stringify(props) === JSON.stringify(mergedProps)) {
            return (React.createElement(Todo, null, "\u26A0\uFE0F Component \"" + OriginalComponent.name + "\" is using a mock although all props are populated. Please remove the WIP component"));
        }
        return (React.createElement("div", { style: { outline: '2px solid rgba(255, 203, 5, 0.2)' }, title: "Mocked" },
            React.createElement(OriginalComponent, __assign({}, mergedProps))));
    };
};
