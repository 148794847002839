import { Column, Row } from '@jelmoli/bootstrap-grid';
import React, { useState } from 'react';
import { Avatar } from '../../atoms/Avatar/Avatar';
import { FilterDropdown, FilterDropdownItem } from '../../atoms/FilterDropdown/FilterDropdown';
import { TextLink } from '../../atoms/TextLink/TextLink';
import { hasImageFieldValue, hasTextFieldValue, } from '../../helpers/SitecoreTypes/SitecoreTypes';
import { Typography } from '../../helpers/Typography/Typography';
import { VerticalSpacer } from '../../helpers/VerticalSpacer/VerticalSpacer';
import './staff-member-list.scss';
export var StaffMemberList = function (props) {
    var _a = useState('all'), selectedCategory = _a[0], setSelectedCategory = _a[1];
    return (React.createElement(React.Fragment, null,
        hasTextFieldValue(props.AllCategoriesLabel) && (React.createElement(StaffMemberFilter, { allCategoriesLabel: props.AllCategoriesLabel.value, categoriesList: props.CategoriesList, selectedCategory: selectedCategory, setSelectedCategory: setSelectedCategory })),
        React.createElement(VerticalSpacer, { size: "l" }),
        React.createElement(Row, null, props.StaffMemberArray.map(function (staffMember, index) {
            if (selectedCategory === 'all' ||
                (staffMember.fields.StaffMemberCategory &&
                    staffMember.fields.StaffMemberCategory.find(function (category) { return category.id === selectedCategory; }))) {
                return (React.createElement(Column, { key: "staff-member--" + index, size: { xs: 6, sm: 4, md: 3, xl: 2 }, className: "o-staff-member" },
                    React.createElement(StaffMemberOverviewItem, { avatar: staffMember.fields.StaffMemberAvatar, categories: staffMember.fields.StaffMemberCategory, name: staffMember.fields.StaffMemberName, link: staffMember.fields.StaffMemberStageUrl })));
            }
            else {
                return null;
            }
        }))));
};
var StaffMemberFilter = function (props) { return (React.createElement("div", { className: "o-staff-member__filter" },
    React.createElement(FilterDropdown, { value: props.selectedCategory, onChange: function (event) { return props.setSelectedCategory(event.target.value); } },
        React.createElement(FilterDropdownItem, { value: "all" }, props.allCategoriesLabel),
        props.categoriesList.map(function (category, index) { return (React.createElement(FilterDropdownItem, { key: "category-filter-" + index, value: category.id }, category.fields.CategoryName.value)); })))); };
export var StaffMemberOverviewItem = function (props) { return (React.createElement(TextLink, { "aria-label": "staff member", href: props.link, underlined: false },
    hasImageFieldValue(props.avatar) && React.createElement(Avatar, { hoverable: true, field: props.avatar, size: "flexible" }),
    hasTextFieldValue(props.name) && (React.createElement(React.Fragment, null,
        React.createElement(VerticalSpacer, { size: "xs", responsive: false }),
        React.createElement(Typography, { size: "l", type: "medium", responsive: false, field: props.name }))),
    props.categories && (React.createElement(React.Fragment, null,
        React.createElement(VerticalSpacer, { size: "xxs", responsive: false }),
        React.createElement(Typography, { size: "xs", uppercase: true, responsive: false },
            props.categories[0] && props.categories[0].fields.CategoryName.value,
            props.categories.slice(1).map(function (category, index) {
                if (index < props.categories.length - 2) {
                    return ", " + category.fields.CategoryName.value;
                }
                return " & " + category.fields.CategoryName.value;
            })))),
    React.createElement(VerticalSpacer, { size: "xl", responsive: false }))); };
