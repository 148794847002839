import { default as MUIChip } from '@material-ui/core/Chip';
import React from 'react';
import './chip.scss';
export var Chip = function (props) {
    return (React.createElement(MUIChip, { classes: {
            root: 'a-chip',
            label: 'a-chip__label',
            disabled: 'a-chip__disabled',
        }, disabled: props.isDisabled, deleteIcon: React.createElement("div", { className: "a-chip__icon-wrapper" }), onClick: props.handleDelete, onDelete: props.handleDelete, label: props.label }));
};
