import { createPostEndpoint } from 'request-registry';
import { getCSRFToken } from '../../Security/getCSRFToken/getCSRFToken';
import { getWishlist, getWishlistIds } from '../getWishlist/getWishlist';
export var removeProductFromWishlist = createPostEndpoint({
    url: function () { return '/jelmoli-api/JelmoliWishlist/RemoveProduct'; },
    headers: {
        'X-RequestVerificationToken': function () { return getCSRFToken(); },
        'X-Requested-With': 'XMLHttpRequest',
    },
    afterSuccess: function () { return Promise.all([getWishlistIds.refresh(), getWishlist.refresh()]); },
});
