var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import MUiDialog from '@material-ui/core/Dialog';
import MUiDialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
export var Dialog = function (props) { return (React.createElement(MUiDialog, __assign({}, props),
    props.title && React.createElement(MUiDialogTitle, null, props.title),
    props.children)); };
