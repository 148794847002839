import { getProductAvailability } from '@jelmoli/sitecore-services/src/Products/getProductAvailability/getProductAvailability';
import { ExpansionPanel } from '@jelmoli/ui-components/src/atoms/ExpansionPanel/ExpansionPanel';
import { ExpansionPanelDetails } from '@jelmoli/ui-components/src/atoms/ExpansionPanelDetails/ExpansionPanelDetails';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import React, { useState } from 'react';
import { useGetEndPointLazy } from 'request-registry-react';
export var SalesZones = function (_a) {
    var label = _a.label, productId = _a.productId, variantId = _a.variantId;
    var _b = useState(false), isExpaned = _b[0], setIsExpanded = _b[1];
    var getSalesZones = useGetEndPointLazy(getProductAvailability, { id: productId }).value;
    if (!getSalesZones || getSalesZones.variants.length === 0) {
        return null;
    }
    var variant = getSalesZones.variants.find(function (variantProduct) {
        if (variantProduct.variantId === variantId) {
            return variantProduct.locations.find(function (location) {
                if (location !== null && !location.locationInventory) {
                    return location.salesZones.find(function (saleZone) {
                        if (saleZone.salesZoneName !== '') {
                            return variantProduct;
                        }
                    });
                }
            });
        }
    });
    if (!variant) {
        return null;
    }
    return (React.createElement(ExpansionPanel, { summary: {
            text: label,
            type: 'regular',
            size: 'l',
            typeExpanded: 'medium',
            tag: 'h3',
        }, expanded: isExpaned, onChange: function () { return setIsExpanded(!isExpaned); } },
        React.createElement(ExpansionPanelDetails, null, variant.locations.map(function (location) { return (React.createElement(React.Fragment, { key: location.locationId }, location.salesZones.map(function (saleZone, index) { return (React.createElement("div", { key: index },
            React.createElement(Typography, { size: "l" },
                location.locationName,
                ", ",
                saleZone.salesZoneFloor))); }))); }))));
};
