import React from 'react';
import { HorizontalSpacer } from '../../helpers/HorizontalSpacer/HorizontalSpacer';
import { hasLinkFieldValue } from '../../helpers/SitecoreTypes/SitecoreTypes';
import { Typography } from '../../helpers/Typography/Typography';
import { VerticalSpacer } from '../../helpers/VerticalSpacer/VerticalSpacer';
import { TextLinkEditable } from '../TextLink/TextLink';
import './icon-list.scss';
export var IconList = function (props) { return (React.createElement(React.Fragment, null,
    props.list.map(function (listItem, index) { return (React.createElement(React.Fragment, { key: index },
        hasLinkFieldValue(listItem.fields.CTALink) ? (React.createElement(TextLinkEditable, { field: listItem.fields.CTALink, underlined: false },
            React.createElement("span", { className: 'a-icon-list__link-icon-dynamic', dangerouslySetInnerHTML: { __html: listItem.fields.CTALinkSVGIcon.value } }),
            React.createElement(HorizontalSpacer, { size: "xxs" }),
            React.createElement(Typography, { size: "m" }, listItem.fields.CTALinkTitle.value))) : (React.createElement(React.Fragment, null,
            React.createElement("span", { className: 'a-icon-list__link-icon-dynamic', dangerouslySetInnerHTML: { __html: listItem.fields.CTALinkSVGIcon.value } }),
            React.createElement(HorizontalSpacer, { size: "xxs" }),
            React.createElement(Typography, { size: "m" }, listItem.fields.CTALinkTitle.value))),
        index < props.list.length - 1 && React.createElement(VerticalSpacer, { size: "xs" }))); }),
    !props.noEndSpacer && React.createElement(VerticalSpacer, { size: "xxl" }))); };
