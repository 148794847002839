/**
 *  Scroll to the element whose selector was passed as argument
 */
export function scrollToFirstElement(selector, element, scrollPosition) {
    if (!element) {
        return;
    }
    var selectorElement = element.querySelector(selector);
    var selectorElementName = selectorElement && selectorElement.getAttribute('data-name');
    var scrollToElement = element.querySelector("[data-name='" + selectorElementName + "']");
    if (scrollToElement) {
        scrollToElement.scrollIntoView({
            behavior: 'smooth',
            block: scrollPosition ? scrollPosition : 'start',
            inline: 'start',
        });
        var inputElement = scrollToElement.querySelector('input');
        // Try to set focus to the first input element
        if (inputElement) {
            inputElement.focus();
        }
    }
}
