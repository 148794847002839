import { Column, Row } from '@jelmoli/bootstrap-grid';
import { bindTranslations } from '@jelmoli/i18n';
import React, { useState } from 'react';
import { PersonalShoppingDrawerComponent } from '../../../../sitecore-features/src/CustomerService/PersonalShoppingDrawerComponent/PersonalShoppingDrawerComponent';
import { Divider } from '../../atoms/Divider/Divider';
import { H1 } from '../../atoms/Headlines/Headlines';
import { CalendarIcon } from '../../atoms/Icon/CalendarIcon';
import { Image } from '../../atoms/Image/Image';
import { PrimaryButton } from '../../atoms/PrimaryButton/PrimaryButton';
import { UnorderedList, UnorderedListItem } from '../../atoms/UnorderedList/UnorderedList';
import { Container } from '../../helpers/Container/Container';
import { hasImageFieldValue, hasTextFieldValue } from '../../helpers/SitecoreTypes/SitecoreTypes';
import { Typography } from '../../helpers/Typography/Typography';
import { VerticalSpacer } from '../../helpers/VerticalSpacer/VerticalSpacer';
import { Visibility } from '../../helpers/Visibility/Visibility';
import './personal-shopper-stage.scss';
export var PersonalShopperStage = function (props) {
    var t = bindTranslations(props.BookingButton);
    var _a = useState(false), isDrawerOpen = _a[0], setIsDrawerOpen = _a[1];
    var staffTranslations = bindTranslations(props.StaffMember.StaffMemberDescription);
    return (React.createElement(Container, { size: "s", backgroundFill: true },
        React.createElement(PersonalShoppingDrawerComponent, { isOpen: isDrawerOpen, openDrawer: function () { return setIsDrawerOpen(false); }, staffMemberId: props.StaffMember.StaffMemberId }),
        React.createElement(Row, null,
            React.createElement(Column, { size: { xs: 12, md: 8 }, offset: { md: 4 }, order: { xs: 2, md: 1 } },
                hasImageFieldValue(props.StaffMember.StaffMemberStageImage) && (React.createElement(Image, { value: props.StaffMember.StaffMemberStageImage.value })),
                React.createElement(VerticalSpacer, { size: "l" })),
            React.createElement(Column, { overlap: { md: 12 }, size: { xs: 12, md: 6 }, order: { xs: 1, md: 2 } },
                React.createElement(Visibility, { hide: ['xs', 'sm'] },
                    React.createElement(VerticalSpacer, { size: "l" })),
                React.createElement(Typography, { type: "light", size: "xs", uppercase: true, field: props.StaffMember.StaffMemberJobDescription }),
                React.createElement(VerticalSpacer, { size: "xs" }),
                hasTextFieldValue(props.StaffMember.StaffMemberName) && (React.createElement(H1, { size: "xl", type: "bold", field: props.StaffMember.StaffMemberName })),
                React.createElement(VerticalSpacer, { size: "l" }),
                React.createElement(Divider, null),
                React.createElement(VerticalSpacer, { size: "m" }),
                React.createElement(Typography, { type: "light", size: "s", field: props.StaffMember.StaffMemberQuote }),
                React.createElement(VerticalSpacer, { size: "s" }),
                React.createElement(UnorderedList, null, props.StaffMember.StaffMemberCategory.map(function (category) { return (React.createElement(UnorderedListItem, { key: category.id }, category.fields.CategoryName.value)); })),
                React.createElement(Visibility, { show: ['xs', 'sm'] },
                    React.createElement(VerticalSpacer, { size: "l" })))),
        React.createElement(Row, null,
            React.createElement(Column, { size: 12 },
                React.createElement("div", { className: "o-personal-shopper-stage__booking" },
                    hasTextFieldValue(props.BookingTitle) && (React.createElement(Typography, { type: "light", size: "m", tag: "h2", field: props.BookingTitle })),
                    React.createElement(VerticalSpacer, { size: "xs" }),
                    hasTextFieldValue(props.StaffMember.StaffMemberDescription) && (React.createElement(Typography, { size: "l" }, staffTranslations('value', {
                        staffMemberName: props.StaffMember.StaffMemberName.value || '',
                    }))),
                    React.createElement(VerticalSpacer, { size: "l" }),
                    React.createElement(PrimaryButton, { type: "button", text: t('value', { staffMemberName: props.StaffMember.StaffMemberName.value || '' }), icon: React.createElement(CalendarIcon, { width: 14 }), onClick: function () {
                            setIsDrawerOpen(true);
                        } }))))));
};
