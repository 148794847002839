var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Column, Row } from '@jelmoli/bootstrap-grid';
import { Image } from '@jelmoli/ui-components/src/atoms/Image/Image';
import { Link } from '@jelmoli/ui-components/src/atoms/Link/Link';
import { TextLink } from '@jelmoli/ui-components/src/atoms/TextLink/TextLink';
import { Container } from '@jelmoli/ui-components/src/helpers/Container/Container';
import { getNodeAttributes, onlyTagsWithInnerHTML, RichTextRenderer, } from '@jelmoli/ui-components/src/helpers/RichTextRenderer/RichTextRenderer';
import { hasLinkFieldValue, hasTextFieldValue, } from '@jelmoli/ui-components/src/helpers/SitecoreTypes/SitecoreTypes';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import classnames from 'classnames';
import React from 'react';
import './imageComponent.scss';
export var ImageComponent = function (props) {
    var imageLayoutOption = props.parameters.imageLayoutOption;
    var gridLayout = imageLayoutOption === 'breakout' ? 'l' : 's';
    var colSize = imageLayoutOption === 'breakout' ? 12 : Number(imageLayoutOption);
    return (React.createElement(Container, { size: gridLayout, backgroundFill: true },
        React.createElement(Row, { horizontalAlignment: "center" },
            React.createElement(Column, { size: colSize },
                React.createElement(Img, __assign({}, props)),
                hasTextFieldValue(props.fields.ImageCaption) && (React.createElement(Caption, { imageCaption: props.fields.ImageCaption })),
                React.createElement(VerticalSpacer, { size: "xxl" })))));
};
/**
 * Image Component when author has defined the Link
 */
var Img = function (props) {
    var isLink = hasLinkFieldValue(props.fields.CtaLink);
    return (React.createElement(Link, { uppercase: true, size: "xxs", type: "regular", field: props.fields.CtaLink }, props.fields.ImageFrame.value !== 'none' ? (React.createElement(Image, { value: props.fields.ImageComponentImage.value, borderColor: props.fields.ImageFrame.value === 'solid'
            ? props.fields.ImageSolidFrameColor.value
            : props.fields.ImageBlendFrameColor.value, borderStyle: props.fields.ImageFrame.value === 'solid' ? 'solid' : props.fields.ImageBlendFrameStyle.value, hoverable: isLink }, hasLinkFieldValue(props.fields.CtaLink) && (React.createElement(ImageCaption, { textColor: props.fields.ImageLinkTextColor.value, textValue: props.fields.CtaLink.value.text })))) : (React.createElement(Image, { value: props.fields.ImageComponentImage.value, hoverable: isLink }, hasLinkFieldValue(props.fields.CtaLink) && (React.createElement(ImageCaption, { textColor: props.fields.ImageLinkTextColor.value, textValue: props.fields.CtaLink.value.text }))))));
};
/**
 * Caption placed bellow the Image Component when author has defined it
 */
var ImageCaption = function (_a) {
    var textColor = _a.textColor, textValue = _a.textValue;
    return (React.createElement("div", { className: "m-image-component__link-wrapper" },
        React.createElement(Typography, { uppercase: true, size: "xs", type: "medium" },
            React.createElement("p", { className: classnames('m-image-component__caption', "m-image-component__caption--" + textColor) }, textValue)),
        React.createElement(VerticalSpacer, { size: "xs" })));
};
/**
 * Link text
 */
var Caption = function (_a) {
    var imageCaption = _a.imageCaption;
    return (React.createElement(React.Fragment, null,
        React.createElement(VerticalSpacer, { size: "xs" }),
        React.createElement(RichTextRenderer, { html: imageCaption.value, replaceRules: defaultNoSpacerReplacements })));
};
var defaultNoSpacerReplacements = {
    A: onlyTagsWithInnerHTML(function (content, node) { return (React.createElement(TextLink, __assign({ "aria-label": "image link" }, getNodeAttributes(node)), content)); }),
    B: onlyTagsWithInnerHTML(function (content) { return (React.createElement(Typography, { wrap: "regular", responsive: false, size: "s", type: "medium", tag: "span" }, content)); }),
    STRONG: onlyTagsWithInnerHTML(function (content) { return (React.createElement(Typography, { wrap: "regular", responsive: false, size: "s", type: "medium", tag: "span" }, content)); }),
    P: onlyTagsWithInnerHTML(function (content) { return (React.createElement(Typography, { wrap: "regular", responsive: false, size: "s", tag: "p" }, content)); }),
};
