import { PDPColorPicker } from '@jelmoli/ui-components/src/atoms/PDPColorPicker/PDPColorPicker';
import React, { useState } from 'react';
/** Color Picker for PDP page */
export var ECommerceBoxColorPicker = function (_a) {
    var colors = _a.colors, selectedVariant = _a.selectedVariant, sizes = _a.sizes;
    var colorsNameArray = [];
    var colorsHexArray = [];
    var colorsURLArray = [];
    var colorsVariantIdArray = [];
    var colorVariantAvailabilityArray = [];
    var nosValueArray = [];
    var preselectedId = 0;
    if (!colors || colors.length === 0) {
        return null;
    }
    colors.forEach(function (color, index) {
        if (selectedVariant && selectedVariant === color.variantId) {
            preselectedId = index;
        }
        colorsHexArray.push("#" + color.colorHex);
        colorsNameArray.push(color.colorName);
        colorsURLArray.push(color.url);
        colorsVariantIdArray.push(color.variantId);
        colorVariantAvailabilityArray.push(color.isAvailable);
        nosValueArray.push(color.isNos);
    });
    var _b = useState(colorsVariantIdArray[preselectedId]), selectedColor = _b[0], setSelectedColor = _b[1];
    var _c = useState(undefined), selectedColorIndex = _c[0], setSelectedColorIndex = _c[1];
    return (React.createElement(PDPColorPicker, { colorVariantIdArray: colorsVariantIdArray, hexColorArray: colorsHexArray, onColorPicked: function (colorVariantId) {
            setSelectedColor(colorVariantId);
            var index = colorsVariantIdArray.findIndex(function (variantID) { return variantID === colorVariantId; });
            if (index > -1) {
                var urlToGoTo = colorsURLArray[index];
                document.location.href = urlToGoTo;
            }
        }, preselectedId: preselectedId, colorVariantAvailabilityArray: colorVariantAvailabilityArray, nosValueArray: nosValueArray, colorSelected: selectedColor, colorsNameArray: colorsNameArray, colorSelectedIndex: selectedColorIndex, onColorHover: function (colorIndex) { return setSelectedColorIndex(colorIndex); } }));
};
