var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { default as MUIDialog } from '@material-ui/core/Dialog';
import React, { useState } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { HorizontalSpacer } from '../../helpers/HorizontalSpacer/HorizontalSpacer';
import { Typography } from '../../helpers/Typography/Typography';
import { VerticalSpacer } from '../../helpers/VerticalSpacer/VerticalSpacer';
import { PrimaryButton } from '../PrimaryButton/PrimaryButton';
import { SecondaryButton } from '../SecondaryButton/SecondaryButton';
import './dialog.scss';
export var Dialog = function (props) {
    return (React.createElement(MUIDialog, { onClose: props.onClose, open: props.isOpen, transitionDuration: props.transitionDuration || 0, maxWidth: "xs", PaperProps: { elevation: 0, square: true }, classes: { paper: 'a-dialog', paperWidthXs: 'a-dialog--xs' } },
        typeof props.dialogTitle === 'string' ? (React.createElement(Typography, { type: "medium", size: "l", responsive: false }, props.dialogTitle)) : (props.dialogTitle),
        props.dialogDescription && typeof props.dialogDescription === 'string' ? (React.createElement(React.Fragment, null,
            React.createElement(VerticalSpacer, { size: "xs", responsive: false }),
            React.createElement(Typography, { size: "s", responsive: false }, props.dialogDescription))) : (React.createElement(React.Fragment, null,
            React.createElement(VerticalSpacer, { size: "xs", responsive: false }),
            props.dialogDescription)),
        React.createElement(VerticalSpacer, { size: "l", responsive: false }),
        React.createElement("div", { className: "a-dialog__bottom" },
            React.createElement("div", { className: "a-dialog__button-wrapper" },
                React.createElement(SecondaryButton, { type: "button", text: props.dialogCancel, uppercase: true, fontResponsive: false, size: "tiny", fullWidth: true, onClick: props.onClose })),
            React.createElement(HorizontalSpacer, { size: "s", responsive: false }),
            React.createElement("div", { className: "a-dialog__button-wrapper" },
                React.createElement(PrimaryButton, { type: "button", text: props.dialogConfirm, uppercase: true, fontResponsive: false, fullWidth: true, size: "tiny", onClick: props.onSuccess })))));
};
export var confirmDialog = function (dialogTitle, dialogConfirmBtn, dialogCancelBtn, dialogDescription) { return __awaiter(void 0, void 0, void 0, function () {
    var div, confirmDialogPromise, returnValue;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                div = document.createElement('div');
                document.body.appendChild(div);
                confirmDialogPromise = new Promise(function (resolve) {
                    render(React.createElement(function () {
                        var _a = useState(true), isOpen = _a[0], setIsOpen = _a[1];
                        return (React.createElement(Dialog, { dialogTitle: dialogTitle, dialogDescription: dialogDescription, dialogCancel: dialogCancelBtn, dialogConfirm: dialogConfirmBtn, transitionDuration: 200, isOpen: isOpen, onClose: function () {
                                setIsOpen(false);
                                resolve(false);
                            }, onSuccess: function () {
                                setIsOpen(false);
                                resolve(true);
                            } }));
                    }), div);
                });
                return [4 /*yield*/, confirmDialogPromise];
            case 1:
                returnValue = _a.sent();
                unmountComponentAtNode(div);
                document.body.removeChild(div);
                return [2 /*return*/, returnValue];
        }
    });
}); };
