var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Column, Row } from '@jelmoli/bootstrap-grid/index';
import { useMiniFormik } from '@jelmoli/hooks/src/useMiniFormik/useMiniFormik';
import { bindTranslations } from '@jelmoli/i18n/index';
import { unsubscribeNewsletter } from '@jelmoli/sitecore-services/src/Account/unsubscribeNewsletter/unsubscribeNewsletter';
import { trackNewsletterUnsubscribe } from '@jelmoli/sitecore-services/src/Tracking/Account/NewsletterTracking';
import { FormFieldErrorMessage } from '@jelmoli/ui-components/src/atoms/FormFieldErrorMessage/FormFieldErrorMessage';
import { Image } from '@jelmoli/ui-components/src/atoms/Image/Image';
import { showErrorNotification } from '@jelmoli/ui-components/src/atoms/Notification/Notification';
import { PrimarySubmitButton } from '@jelmoli/ui-components/src/atoms/PrimarySubmitButton/PrimarySubmitButton';
import { RadioButtons } from '@jelmoli/ui-components/src/atoms/RadioButtons/RadioButtons';
import { RadioButtonsValidable } from '@jelmoli/ui-components/src/atoms/RadioButtonsValidatable/RadioButtonsValidatable';
import { Container } from '@jelmoli/ui-components/src/helpers/Container/Container';
import { defaultReplacements, onlyTagsWithInnerHTML, RichTextRenderer, } from '@jelmoli/ui-components/src/helpers/RichTextRenderer/RichTextRenderer';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { urlManager } from '@jelmoli/urlmanager/UrlManager';
import { FormValidationContext } from 'nested-form-validation/index';
import React from 'react';
export var NewsletterUnsubscribeComponent = function (_a) {
    var fields = _a.fields;
    var newsletterUnsubscribeRichtextRules = {
        P: onlyTagsWithInnerHTML(function (content) { return (React.createElement(Typography, { responsive: true, size: "s", type: "regular", tag: "p" }, content)); }),
        A: defaultReplacements.A,
    };
    var form = useMiniFormik({
        initialValues: {
            reason: '',
        },
    });
    var t = bindTranslations(fields);
    var reasonsArray = Object.keys(fields.NewsletterUnsubscribeReasons.value).map(function (subject) { return [
        subject,
        fields.NewsletterUnsubscribeReasons.value[subject],
    ]; });
    return (React.createElement(Container, { size: "s", backgroundFill: true },
        React.createElement(Row, null,
            React.createElement(Column, { size: { xs: 12, md: 7, lg: 6 } },
                React.createElement(Image, { value: fields.NewsletterUnsubscribeIntroImage.value })),
            React.createElement(Column, { size: { xs: 12, md: 5 } },
                React.createElement(VerticalSpacer, { size: { xs: 'mx', sm: 'mx', md: 'xxl', lg: 'xxl', xl: 'xxl' } }),
                React.createElement(Typography, { size: "m", type: "bold" }, t('NewsletterUnsubscribeIntroTitle')),
                React.createElement(VerticalSpacer, { size: "s" }),
                React.createElement(Typography, { size: "xs" }, t('NewsletterUnsubscribeIntroBody')))),
        React.createElement(Row, null,
            React.createElement(Column, { size: { xs: 12, lg: 6 }, offset: { lg: 3 } },
                React.createElement(VerticalSpacer, { size: "l", show: ['xs', 'sm'], responsive: true }),
                React.createElement(VerticalSpacer, { size: "xl", hide: ['xs', 'sm'], responsive: true }),
                React.createElement(Typography, { size: "xl", type: "medium" }, t('NewsletterUnsubscribeTitle')),
                React.createElement(VerticalSpacer, { size: "s" }),
                React.createElement(Typography, { responsive: true, size: "s", type: "regular" }, t('NewsletterUnsubscribeBody')),
                React.createElement(VerticalSpacer, { size: "l", responsive: true }),
                React.createElement(Typography, { size: "xs", type: "medium" }, t('NewsletterUnsubscribeReasonsTitle')),
                React.createElement(VerticalSpacer, { size: "m", responsive: true }),
                React.createElement(FormValidationContext, null,
                    React.createElement(RadioButtonsValidable, __assign({ name: "reason", errormsg: fields.NewsletterUnsubscribeReasonSelection.fields.IsRequiredError &&
                            fields.NewsletterUnsubscribeReasonSelection.fields.IsRequiredError.value }, form.fieldProps.reason), reasonsArray.map(function (subject) {
                        return (React.createElement(RadioButtons, { value: subject[0], key: subject[0] }, subject[1]));
                    })),
                    React.createElement(FormFieldErrorMessage, { name: "reason", verticalSpaceTop: "m" }),
                    React.createElement(VerticalSpacer, { size: { xs: 'xl', lg: 'l', xl: 'mx' } }),
                    React.createElement(RichTextRenderer, { replaceRules: newsletterUnsubscribeRichtextRules, html: fields.newsletterOnlyUser
                            ? t('NewsletterUnsubscribeNewsletterUserHint')
                            : t('NewsletterUnsubscribeRegularUserHint') }),
                    React.createElement(VerticalSpacer, { size: { xs: 'xl', lg: 'l', xl: 'mx' } }),
                    React.createElement(PrimarySubmitButton, { type: "submit", text: t('NewsletterUnsubscribeButton'), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                            var queryString;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        queryString = __assign({}, urlManager.queryString);
                                        return [4 /*yield*/, unsubscribeNewsletter({}, {
                                                customerId: fields.CustomerId,
                                                unsubscribeReason: form.values.reason,
                                                uid: typeof queryString.uid === 'string' ? queryString.uid : '',
                                                cid: Number(queryString.cid),
                                                lLid: Number(queryString.llid),
                                            })
                                                .then(function () {
                                                trackNewsletterUnsubscribe(true);
                                                var redirectUrl = fields.NewsletterUnsubscribeSuccessPageLink.value.url;
                                                if (fields.NewsletterUnsubscribeSuccessPageLink.value.url) {
                                                    document.location.replace(redirectUrl);
                                                }
                                            })
                                                .catch(function (response) {
                                                trackNewsletterUnsubscribe(false);
                                                showErrorNotification(response.GlobalMessage);
                                            })];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); } })),
                React.createElement(VerticalSpacer, { size: "xxl" })))));
};
