var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Row } from '@jelmoli/bootstrap-grid';
import { bindTranslations } from '@jelmoli/i18n';
import { deleteAddress } from '@jelmoli/sitecore-services/src/Account/deleteAddress/deleteAddress';
import { getAddressbook } from '@jelmoli/sitecore-services/src/Account/getAddressbook/getAddressbook';
import { getProfile } from '@jelmoli/sitecore-services/src/Account/getProfile/getProfile';
import { trackError } from '@jelmoli/sitecore-services/src/Tracking/Error/ErrorTesting';
import { confirmDialog } from '@jelmoli/ui-components/src/atoms/Dialog/Dialog';
import { PlusIcon } from '@jelmoli/ui-components/src/atoms/Icon/PlusIcon';
import { Modal } from '@jelmoli/ui-components/src/atoms/Modal/Modal';
import { showErrorNotification, showSuccessNotification, } from '@jelmoli/ui-components/src/atoms/Notification/Notification';
import { PrimaryButton } from '@jelmoli/ui-components/src/atoms/PrimaryButton/PrimaryButton';
import { SecondaryButton } from '@jelmoli/ui-components/src/atoms/SecondaryButton/SecondaryButton';
import { Separator } from '@jelmoli/ui-components/src/atoms/Separator/Separator';
import { Container } from '@jelmoli/ui-components/src/helpers/Container/Container';
import { Typography } from '@jelmoli/ui-components/src/helpers/Typography/Typography';
import { VerticalSpacer } from '@jelmoli/ui-components/src/helpers/VerticalSpacer/VerticalSpacer';
import { Visibility } from '@jelmoli/ui-components/src/helpers/Visibility/Visibility';
import React, { useState } from 'react';
import { Column } from 'react-bootstrap-grid-component/dist/Column';
import { useGetEndPointLazy } from 'request-registry-react';
import { MyAccountProfileMainAddressModalContent } from '../MyAccountProfileComponent/MyAccountProfileMainAddress/MyAccountProfileMainAddressModalContent';
import './my-account-address.scss';
import { MyAccountAddressComponentModalContent } from './MyAccountAddressComponentModalContent';
export var MyAccountAddressComponent = function (_a) {
    var fields = _a.fields;
    var getAddressBookData = useGetEndPointLazy(getAddressbook, {});
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var _c = useState(), addressID = _c[0], setAddressID = _c[1];
    if (!getAddressBookData.hasData) {
        return null;
    }
    var addressBook = getAddressBookData.value;
    var t = bindTranslations(fields);
    return (React.createElement(Container, { size: "s" },
        React.createElement(Row, null,
            React.createElement(Column, { size: 12 },
                React.createElement(MainAddress, { fields: fields }),
                addressBook.length > 0 &&
                    addressBook.map(function (item, index) { return (React.createElement(React.Fragment, { key: item.id },
                        React.createElement(VerticalSpacer, { size: "xl" }),
                        React.createElement("div", { className: "feat-myaccount-addresses__item" },
                            React.createElement(Typography, { size: "m", tag: "p" },
                                item.firstName,
                                " ",
                                item.lastName),
                            item.company && (React.createElement(Typography, { size: "m", tag: "p" }, item.company)),
                            React.createElement(Typography, { size: "m", tag: "p" }, item.address1),
                            item.address2 && (React.createElement(Typography, { size: "m", tag: "p" }, item.address2)),
                            React.createElement(Typography, { size: "m", tag: "p" },
                                item.zipPostalCode,
                                " ",
                                item.city),
                            React.createElement(Typography, { size: "m", tag: "p" }, item.country),
                            React.createElement(VerticalSpacer, { size: "m" }),
                            item.standardBilling && (React.createElement("div", { className: "feat-myaccount-address__checkmark" },
                                React.createElement(Typography, { type: "medium", size: "xs", responsive: false, uppercase: true, field: fields.AddressBookStandardBillingAddressLabel }))),
                            item.standardBilling && item.standardDelivery && React.createElement(VerticalSpacer, { size: "xxs" }),
                            item.standardDelivery && (React.createElement("div", { className: "feat-myaccount-address__checkmark" },
                                React.createElement(Typography, { type: "medium", size: "xs", responsive: false, uppercase: true, field: fields.AddressBookStandardDeliveryAddressLabel }))),
                            (item.standardBilling || item.standardDelivery) && React.createElement(VerticalSpacer, { size: "mx" }),
                            React.createElement("button", { "aria-label": "Delete button", className: "feat-myaccount-addresses__delete-btn", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, confirmDialog(t('AddressBookDeletionConfirmationText'), t('AddressBookDeleteButtonLabel'), t('AddressBookCancelButtonLabel'))];
                                            case 1:
                                                if (!_a.sent()) return [3 /*break*/, 3];
                                                return [4 /*yield*/, deleteAddress({ id: item.id })
                                                        .then(function (response) {
                                                        showSuccessNotification(response.globalMessage);
                                                    })
                                                        .catch(function (response) {
                                                        trackError({
                                                            page: 'Error in removing the address from MyAccount Addresses',
                                                            errorMessage: response.GlobalMessage,
                                                        });
                                                        showErrorNotification(response.GlobalMessage);
                                                    })];
                                            case 2:
                                                _a.sent();
                                                _a.label = 3;
                                            case 3: return [2 /*return*/];
                                        }
                                    });
                                }); } })),
                        React.createElement(Visibility, { hide: ['xs', 'sm'] },
                            React.createElement(SecondaryButton, { text: t('AddressBookEditButtonLabel'), type: "button", size: "tiny", onClick: function () {
                                    setAddressID(item.id);
                                    setIsOpen(true);
                                } })),
                        React.createElement(Visibility, { show: ['xs', 'sm'] },
                            React.createElement(SecondaryButton, { text: t('AddressBookEditButtonLabel'), type: "button", size: "tiny", fullWidth: true, onClick: function () {
                                    setAddressID(item.id);
                                    setIsOpen(true);
                                } })),
                        React.createElement(VerticalSpacer, { size: "xl" }),
                        React.createElement(Separator, null))); }),
                React.createElement(VerticalSpacer, { size: "xl" }),
                React.createElement(Typography, { size: "l", field: fields.AddressBookIntrotext }),
                React.createElement(VerticalSpacer, { size: "l" }),
                React.createElement(Visibility, { hide: ['xs', 'sm'] },
                    React.createElement(PrimaryButton, { type: "button", text: t('AddressBookAddAddressButtonLabel'), icon: React.createElement(PlusIcon, { width: 14 }), onClick: function () {
                            setAddressID(undefined);
                            setIsOpen(true);
                        } })),
                React.createElement(Visibility, { show: ['xs', 'sm'] },
                    React.createElement(PrimaryButton, { type: "button", text: t('AddressBookAddAddressButtonLabel'), icon: React.createElement(PlusIcon, { width: 14 }), onClick: function () {
                            setAddressID(undefined);
                            setIsOpen(true);
                        }, fullWidth: true })))),
        React.createElement(Modal, { open: isOpen, title: typeof addressID === 'string'
                ? fields.AddressBookEditAddressOverlayTitle.value
                : fields.AddressBookAddAddressOverlayTitle.value, onClose: function () { return setIsOpen(false); } },
            React.createElement(MyAccountAddressComponentModalContent, { FirstName: fields.FirstName, LastName: fields.LastName, Salutation: fields.Salutation, SalutationSource: fields.SalutationSource, Company: fields.Company, Address1: fields.Address1, Address2: fields.Address2, Country: fields.Country, City: fields.City, ZipPostalCode: fields.ZipPostalCode, CountryList: fields.CountryList, AddressBookSaveButtonLabel: fields.AddressBookSaveButtonLabel, AddressBookCancelButtonLabel: fields.AddressBookCancelButtonLabel, AddressBookStandardBillingAddressLabel: fields.AddressBookStandardBillingAddressLabel, AddressBookStandardDeliveryAddressLabel: fields.AddressBookStandardDeliveryAddressLabel, id: addressID, onClose: function () { return setIsOpen(false); } }))));
};
var MainAddress = function (_a) {
    var fields = _a.fields;
    var getProfileData = useGetEndPointLazy(getProfile, {}).value;
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    if (!getProfileData) {
        return null;
    }
    var t = bindTranslations(fields);
    return (React.createElement(React.Fragment, null,
        'address1' in getProfileData.mainAddress ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "feat-myaccount-addresses__item" },
                React.createElement(Typography, { size: "m", tag: "p" },
                    getProfileData.firstName,
                    " ",
                    getProfileData.lastName),
                getProfileData.mainAddress.company && (React.createElement(Typography, { size: "m", tag: "p" }, getProfileData.mainAddress.company)),
                React.createElement(Typography, { size: "m", tag: "p" }, getProfileData.mainAddress.address1),
                getProfileData.mainAddress.address2 && (React.createElement(Typography, { size: "m", tag: "p" }, getProfileData.mainAddress.address2)),
                React.createElement(Typography, { size: "m", tag: "p" },
                    getProfileData.mainAddress.zipPostalCode,
                    " ",
                    getProfileData.mainAddress.city),
                getProfileData.mainAddress.mobile && (React.createElement(Typography, { size: "m", tag: "p" }, getProfileData.mainAddress.mobile)),
                React.createElement(Typography, { size: "m", tag: "p" }),
                React.createElement(VerticalSpacer, { size: "m" }),
                React.createElement("div", { className: "feat-myaccount-address__checkmark" },
                    React.createElement(Typography, { type: "medium", size: "xs", responsive: false, uppercase: true }, t('AddressBookMainAddressLabel'))),
                React.createElement(VerticalSpacer, { size: "mx" })),
            React.createElement(Visibility, { hide: ['xs', 'sm'] },
                React.createElement(SecondaryButton, { text: t('AddressBookEditButtonLabel'), type: "button", size: "tiny", onClick: function () {
                        setIsOpen(true);
                    } })),
            React.createElement(Visibility, { show: ['xs', 'sm'] },
                React.createElement(SecondaryButton, { text: t('AddressBookEditButtonLabel'), type: "button", size: "tiny", fullWidth: true, onClick: function () {
                        setIsOpen(true);
                    } })))) : (React.createElement(React.Fragment, null,
            React.createElement(Typography, { tag: "p", size: "l", type: "regular" }, t('AddressBookMainAddressEmptyState')),
            React.createElement(VerticalSpacer, { size: "l" }),
            React.createElement(Visibility, { hide: ['xs', 'sm'] },
                React.createElement(SecondaryButton, { text: t('AddressBookAddAddressButtonLabel'), type: "button", size: "tiny", onClick: function () {
                        setIsOpen(true);
                    } })),
            React.createElement(Visibility, { show: ['xs', 'sm'] },
                React.createElement(SecondaryButton, { text: t('AddressBookAddAddressButtonLabel'), type: "button", size: "tiny", fullWidth: true, onClick: function () {
                        setIsOpen(true);
                    } })))),
        React.createElement(VerticalSpacer, { size: "xl" }),
        React.createElement(Separator, null),
        React.createElement(Modal, { open: isOpen, title: fields.AddressBookEditAddressOverlayTitle.value, onClose: function () { return setIsOpen(false); } },
            React.createElement(MyAccountProfileMainAddressModalContent, { onClose: function () { return setIsOpen(false); }, ChangeMainAddress: fields.AddressBookSaveButtonLabel, MainAddressTitle: fields.AddressBookEditAddressOverlayTitle, CancelButton: fields.AddressBookCancelButtonLabel, SaveButton: fields.AddressBookSaveButtonLabel, Company: fields.Company, Address1: fields.Address1, Address2: fields.Address2, Country: fields.Country, City: fields.City, ZipPostalCode: fields.ZipPostalCode, CountryList: fields.CountryListMainAddress, MobilePrefix: fields.MobilePrefix, MobilePrefixList: fields.MobilePrefixList }))));
};
