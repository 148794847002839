import { useBasicScroll } from '@jelmoli/hooks/src/useBasicScroll/useBasicScroll';
import React, { useMemo, useRef } from 'react';
import { useIsSitecoreEditMode } from '../EditMode/editMode';
import './parallax.scss';
export var Parallax = function (_a) {
    var children = _a.children, enableInEditMode = _a.enableInEditMode, height = _a.height;
    var inEditMode = useIsSitecoreEditMode();
    var isEnabled = enableInEditMode || !inEditMode;
    return !isEnabled ? React.createElement(React.Fragment, null, children) : React.createElement(ParallaxBasicScroll, { height: height }, children);
};
var ParallaxBasicScroll = function (props) {
    var wrapperRef = useRef(null);
    var options = useMemo(function () { return ({
        // The first part of the value describes the element position,
        // the last part describes the viewport position: <element>-<viewport>.
        // middle-bottom in from specifies that the animation starts when the middle of the element reaches
        // the bottom of the viewport.
        // Known relative values:
        // top-top, top-middle, top-bottom, middle-top, middle-middle,
        // middle-bottom, bottom-top, bottom-middle, bottom-bottom
        from: 'top-bottom',
        to: 'bottom-top',
        track: false,
        props: {
            '--y': {
                from: props.height ? Math.floor(props.height / 5) + "px" : '0px',
                to: props.height ? Math.floor((props.height / 5) * -1) + "px" : '-300px',
            },
        },
    }); }, [props.height]);
    useBasicScroll(wrapperRef, wrapperRef, options);
    return (React.createElement("div", { className: "h-parallax__wrapper", ref: wrapperRef },
        React.createElement("div", { className: "h-parallax" }, props.children)));
};
