var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { hasEverBeenVisibleInViewport } from '@jelmoli/hooks/src/useKeyboardEffect/useIsVisibleInViewport/useIsVisibleInViewport';
import React, { useMemo, useState } from 'react';
import { Spinner } from '../Spinner/Spinner';
import './infinity-scroll.scss';
export var InfinityScroll = function (props) {
    // Track wether the preload div (top: -80vh) is in viewport
    var _a = hasEverBeenVisibleInViewport(), refPreloadRef = _a.ref, hasBeenVisible = _a.hasBeenVisible;
    // As a fallback if the user did somehow manage to scroll directly to the
    // content provide a second div right at the top of the content as fallback ref
    var _b = hasEverBeenVisibleInViewport(), refFallback = _b.ref, hasFallbackBeenVisible = _b.hasBeenVisible;
    var isOutsideView = !hasBeenVisible && !hasFallbackBeenVisible;
    return (React.createElement(React.Fragment, null, isOutsideView && !props.forceLoad ? (React.createElement("div", { className: "a-infinity-scroll__ref-wrapper" },
        React.createElement("div", { ref: refPreloadRef, className: "a-infinity-scroll__ref" }),
        React.createElement("div", { ref: refFallback, className: "a-infinity-scroll__ref-fallback" }))) : (React.createElement(LoadEndpoint, __assign({}, props)))));
};
var EMPTY_LOADER_RESULT = {};
/** Helper which displays the spinner until the given endpoint is load */
export var LoadEndpoint = function (_a) {
    var loader = _a.loader, children = _a.children;
    var _b = useState(EMPTY_LOADER_RESULT), loaderResult = _b[0], setLoaderResult = _b[1];
    useMemo(function () {
        loader().then(function (result) { return setLoaderResult(result); });
    }, []);
    if (loaderResult !== EMPTY_LOADER_RESULT) {
        return React.createElement(React.Fragment, null, children(loaderResult));
    }
    return (React.createElement("div", { className: "a-infinity-scroll__spinner-wrapper" },
        React.createElement(Spinner, null)));
};
